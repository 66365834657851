import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-subscribe2-section',
  templateUrl: './subscribe2-section.component.html',
  styleUrls: ['./subscribe2-section.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class Subscribe2Component implements OnInit {
  @Input() subscribeData;
  @Input() colorList;
  @Input() pageName;
  sub2SuccessMessage: string = "";
  sub2ErrorMessage: string = "";
  sub2ApiErrorMessage: string = "";
  buttonColorClass: Map<string, string>;
  showSub2bMsg: boolean = false;
  constructor(private _sharedService: SharedService) {
    this.buttonColorClass = new Map([
      ["nmacc", "color-pink"],
      ["garden", "color-green"]
    ]);    
    this._sharedService.getCommonService().showSubMsg.subscribe(
      (data)=>{
        this.showSub2bMsg = data;
      }
    );
  }

  ngOnInit() {
  }
  onSubscribeToWorld(subscribe2Form) {
    if (!subscribe2Form.value.email) {
      this.sub2SuccessMessage = '';
      this.sub2ErrorMessage = "Email is required";
    } else if (!subscribe2Form.valid) {
      this.sub2SuccessMessage = '';
      this.sub2ErrorMessage = "Email must be a valid email address";
    } else {
      let eventObj = {
        Page_Source: this.pageName,
        Register_Option: "Social",
        Platform: "Web",
        Status: "Pending",
        Opt_in: "Y",
        Act: "N",
        Email_address: subscribe2Form.value.email
      };
      this._sharedService.getCleverTapService().recordSubscriptionEvent("Newsletter subscription", eventObj);
      // console.log("Clevertap: ", (<any>window).clevertap.event);

      this._sharedService.getCommonService().getSubscriptionDetails(subscribe2Form.value.email, this.pageName).subscribe(
        (data) => {
          this.sub2ErrorMessage = '';
          this.sub2SuccessMessage = data['message'];
          this._sharedService.getCommonService().setSubscriptionTimer();
        },
        (error) => {
          this.sub2ErrorMessage = "";
          this.sub2ApiErrorMessage = "Try after some time..";
          this._sharedService.getCommonService().setSubscriptionTimer();
         }
      );
      subscribe2Form.resetForm();
    }
  }

}
