<section class="event_services pb-0" data-aos="fade-up"
  [attr.style]="'--data-experiences-titleColor: ' + colorList?.dataExperiencesTitleColor + '; --data-dataMobileLineBg: ' + colorList?.experiencesDataTitleColor | safe: 'style'">
  <div class="container">
    <div class="section_heading section_heading--title section_heading--text"
      [attr.style]="'--data-titleColor: ' + colorList?.dataTitleColor + '; --data-lineColor: ' + colorList?.dataLineColor | safe: 'style'">
      <div class="">
        <h2 [innerHTML]="venueDetailsHighlightsInfoData?.ComponentHeaderTitle?.data"></h2>
        <p [innerHTML]="venueDetailsHighlightsInfoData?.ComponentHeaderSubTitle?.data"></p>
      </div>
    </div>
    <div class="row services">
      <div class="col-md-4 col-6 services_block" *ngFor="let item of venueDetailsHighlightsInfoData.cards">
        <div class="service_icon"> <img src="{{ staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl}}"
            class="img-fluid" alt="{{item?.ImageAltText?.data}}"></div>
        <p class="service_txt">{{item?.InfographicTitle?.data}}</p>
      </div>
    </div>
    <div class="cta-holder text-center">
      <a *ngIf="venueDetailsHighlightsInfoData?.HideCTAText?.data =='false'" href="{{venueDetailsHighlightsInfoData?.CTALink?.data}}"
        (click)="callEventServicesCTEvent(); _sharedService.checkCTALink($event, venueDetailsHighlightsInfoData?.CTALink?.data, venueDetailsHighlightsInfoData?.CTALinkWindowPreference?.data, venueDetailsHighlightsInfoData?.TypeOfCTALink?.data)" class="cta-prim color-blue "><span
          class="cta-prim-txt">{{venueDetailsHighlightsInfoData?.CTAText?.data}}</span></a>
    </div>
  </div>
</section>