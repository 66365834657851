<section class="explorevenues_wrapper inside_explorevenues_wrapper" [attr.style]="'--data-explore-bgColor1:#FFFFFF; --data-explore-bgColor2:#FFFFFF' | safe: 'style'" *ngIf="cecVenueDetailsExploreData">

  <div class="container">
    <div class="section_heading section_heading--title section_heading--text" [attr.style]="'--data-titleColor:#4B4847;--data-lineColor:#004EA8' | safe: 'style'">
        <h2 [innerHTML]="cecVenueDetailsExploreData[0] ? cecVenueDetailsExploreData[0].ComponentHeaderTitle.data : cecVenueDetailsExploreData[1].ComponentHeaderTitle.data"></h2>
        <p [innerHTML]="cecVenueDetailsExploreData[0] ? cecVenueDetailsExploreData[0].ComponentSubHeaderText.data : cecVenueDetailsExploreData[1].ComponentSubHeaderText.data"></p>
    </div>
  </div>


  <div class="container load-more-data">
    <div class="row explorevenues_wrapper_each" [ngClass]="{'load-more-data-hide': last && flag && !isMeetingRoom}" [attr.style]="'--data-explore-boxbgColor:#F2F5FA' | safe: 'style'" *ngFor="let item of hallExploreData; let i = index; let last = last">
      <div class="col-lg-5 col-md-5 p-0">
        <div class="explorevenues_wrapper_each--img"> <img
            src="{{staticUrlForImgNew + item?.HallsImage?.image?.contentUrl}}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">

          <!-- <a href="javascript:void(0);" class="galleryIcon" (click)="getObjAndIndex(item, (i+1))"
            [attr.data-target]="'#gallery'+(i+1)" data-toggle="modal"></a> -->

        </div>
      </div>
      <div class="col-lg-7 col-md-7">
        <div class="explorevenues_wrapper_each_copy">
          <h4>{{item?.HallsHeaderText?.data}}</h4>
          <p class="explorevenues_wrapper_each_copy--text content3">{{item?.HallsSubHeaderText?.data}}</p>
          <div class="icons_block">
            <div class="icons_block_wrapper">
              <div class="container">
                <div class="row">
                  <div class="col-md-4 pl-0">
                    <div class="icons_block_wrapper_each">
                      <div class="icons_block_wrapper_each--icon"> <img
                          src="{{staticUrlForImgNew + item?.FirstInfographicImage?.image?.contentUrl}}" alt="icon1">
                      </div>
                      <div class="icons_block_wrapper_each_copy">
                        <h4 class="icons_block_wrapper_each_copy--number">{{item?.FirstInfographicHeaderText?.data}}
                        </h4>
                        <span
                          class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.FirstInfographicShortText?.data"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0">
                    <div class="icons_block_wrapper_each">
                      <div class="icons_block_wrapper_each--icon"> <img
                          src="{{staticUrlForImgNew + item?.SecondInfographicImage?.image?.contentUrl}}" alt="icon2">
                      </div>
                      <div class="icons_block_wrapper_each_copy">
                        <h4 class="icons_block_wrapper_each_copy--number">{{item?.SecondInfographicHeaderText?.data}}
                        </h4>
                        <span
                          class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.SecondInfographicShortText?.data"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4  pl-0">
                    <div class="icons_block_wrapper_each">
                      <div class="icons_block_wrapper_each--icon"> <img
                          src="{{staticUrlForImgNew + item?.ThirdInfographicImage?.image?.contentUrl}}" alt="icon3">
                      </div>
                      <div class="icons_block_wrapper_each_copy">
                        <h4 class="icons_block_wrapper_each_copy--number">{{item?.ThirdInfographicHeaderText?.data}}
                        </h4>
                        <span
                          class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.ThirdInfographicShortText?.data"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="explorevenues_wrapper_each--btns">
            <a href="{{item?.FirstCTALink?.data}}" *ngIf="item?.HideFirstCTAText?.data =='false'" class="cta-prim color-blue"
              (click)="callCecVDExploreSectionCTEvent('pri', item?.HallsHeaderText?.data);_sharedService.checkCTALink($event, item?.FirstCTALink?.data, item?.FirstCTALinkWindowPreference?.data, item?.TypeOfFirstCTALink?.data)">
              <span class="cta-prim-txt">{{item?.FirstCTAText?.data}}</span></a>
            <a href="{{item?.SecondCTALink?.data}}" *ngIf="item?.HideSecondCTAText?.data =='false'"
              class="cta-sec icon rh icon-download-icon color-blue"
              (click)="callCecVDExploreSectionCTEvent('download', item?.HallsHeaderText?.data, item?.SecondCTALink?.data); _sharedService.checkCTALink($event, item?.SecondCTALink?.data, '_self', 'PDF', pdfName)">
              <span class="cta-sec-txt">{{item?.SecondCTAText?.data}}</span></a>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-container swiper-tab"> -->
        <!-- <div class="explorevenues_expand">
          <div class="container">
            <div class="row">
              <h4 class="explorevenues_expand--title">{{item?.SubHallsMainHeader?.data}}</h4>
              <div class="swiper-pagination"></div>
              <a href="javascript:void(0);" class="cta-sec icon icon-expand icon-arrow-down" data-toggle="collapse"
                [attr.data-target]="'#collapseBox_'+(i+1)"><span class="cta-prim-txt">Expand</span></a>
            </div>
          </div>
        </div>
        <div class="swiper-arrows">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div id="collapseBox_{{i+1}}" class="swiper-wrapper expandcard collapse">
          <div class="swiper-slide" [attr.data-title]="innerItem?.TabHeaderName?.data"
            *ngFor="let innerItem of item?.cards; let j = index">
            <div class="col-md-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 p-0">
                  <div class="explorevenues_wrapper_each--img"> <img
                      src="{{staticUrlForImgNew + innerItem?.SubHallsImage?.image?.contentUrl}}"
                      class="img-fluid" alt="Venue 1"> </div>
                </div>
                <div class="col-lg-6 col-md-6 p-0">
                  <div class="explorevenues_wrapper_each_copy">
                    <h4>{{innerItem?.TabHeaderName?.data}}</h4>
                    <div class="icons_block">
                      <div class="icons_block_wrapper">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-12 col-lg-6 pl-0">
                              <div class="icons_block_wrapper_each">
                                <div class="icons_block_wrapper_each--icon"> <img
                                    src="{{staticUrlForImgNew + innerItem?.SubHallsFirstInfographicImage?.image?.contentUrl}}"
                                    alt="icon1"> </div>
                                <div class="icons_block_wrapper_each_copy">
                                  <h4 class="icons_block_wrapper_each_copy--number">
                                    {{innerItem?.SubHallsFirstInfographicHeaderText?.data}}</h4>
                                  <span
                                    class="icons_block_wrapper_each_copy--name">{{innerItem?.SubHallsFirstInfographicShortText?.data}}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pl-0">
                              <div class="icons_block_wrapper_each">
                                <div class="icons_block_wrapper_each--icon"> <img
                                    src="{{staticUrlForImgNew + innerItem?.SubHallsSecondInfographicImage?.image?.contentUrl}}"
                                    alt="icon2"> </div>
                                <div class="icons_block_wrapper_each_copy">
                                  <h4 class="icons_block_wrapper_each_copy--number">
                                    {{innerItem?.SubHallsSecondInfographicHeaderText?.data}}</h4>
                                  <span
                                    class="icons_block_wrapper_each_copy--name">{{innerItem?.SubHallsSecondInfographicShortText?.data}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="cta-holder">
                      <a href="javascript:void(0)" *ngIf="innerItem?.SubHallsHideFirstCTAText?.data =='false'"
                        class="cta-prim color-blue emp-btn"
                        (click)="_sharedService.checkCTALink($event, innerItem?.SubHallsFirstCTALink?.data, innerItem?.SubHallsFirstCTALinkWindowPreference?.data, innerItem?.SubHallsTypeOfFirstCTALink?.data)">
                        <span class="cta-prim-txt">{{innerItem?.SubHallsFirstCTAText?.data}}</span>
                      </a>
                      <a href="javascript:void(0)" *ngIf="innerItem?.SubHallsHideSecondCTAText?.data =='false'"
                        class="cta-sec color-blue"
                        (click)="_sharedService.checkCTALink($event, innerItem?.SubHallsSecondCTALink?.data, innerItem?.SubHallsSecondCTALinkWindowPreference?.data, innerItem?.SubHallsTypeOfSecondCTALink?.data)">
                        <span class="cta-sec-txt">{{innerItem?.SubHallsSecondCTAText?.data}}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
    </div>
    <div class="row explorevenues_wrapper_each" [ngClass]="{'load-more-data-hide': flag}" [attr.style]="'--data-explore-boxbgColor:#F2F5FA' | safe: 'style'" *ngIf="isMeetingRoom">
      <div class="col-lg-5 col-md-5 p-0">
        <div class="explorevenues_wrapper_each--img"> <img
            src="{{staticUrlForImgNew + meetingRoomExploreData[0]?.HallsImage?.image?.contentUrl}}" class="img-fluid"
            alt="{{meetingRoomExploreData[0]?.ImageAltText?.data}}"> </div>
      </div>
      <div class="col-lg-7 col-md-7">
        <div class="explorevenues_wrapper_each_copy">
          <h4>{{meetingRoomExploreData[0]?.HallsHeaderText?.data}}</h4>
          <p class="explorevenues_wrapper_each_copy--text content3">
            {{meetingRoomExploreData[0]?.HallsSubHeaderText?.data}}</p>
          <div class="icons_block">
            <div class="icons_block_wrapper">
              <div class="container">
                <div class="row">
                  <div class="col-md-4 pl-0">
                    <div class="icons_block_wrapper_each">
                      <div class="icons_block_wrapper_each--icon"> <img
                          src="{{staticUrlForImgNew + meetingRoomExploreData[0]?.FirstInfographicImage?.image?.contentUrl}}"
                          alt="icon1">
                      </div>
                      <div class="icons_block_wrapper_each_copy">
                        <h4 class="icons_block_wrapper_each_copy--number">
                          {{meetingRoomExploreData[0]?.FirstInfographicHeaderText?.data}}</h4>
                        <span
                          class="icons_block_wrapper_each_copy--name" [innerHTML]="meetingRoomExploreData[0]?.FirstInfographicShortText?.data"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0">
                    <div class="icons_block_wrapper_each">
                      <div class="icons_block_wrapper_each--icon"> <img
                          src="{{staticUrlForImgNew + meetingRoomExploreData[0]?.SecondInfographicImage?.image?.contentUrl}}"
                          alt="icon2"> </div>
                      <div class="icons_block_wrapper_each_copy">
                        <h4 class="icons_block_wrapper_each_copy--number">
                          {{meetingRoomExploreData[0]?.SecondInfographicHeaderText?.data}}</h4>
                        <span
                          class="icons_block_wrapper_each_copy--name" [innerHTML]="meetingRoomExploreData[0]?.SecondInfographicShortText?.data"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="explorevenues_wrapper_each--btns">

            <a href="{{meetingRoomExploreData[0]?.FirstCTALink?.data}}" *ngIf="meetingRoomExploreData[0]?.HideFirstCTAText?.data =='false'"
              class="cta-prim color-blue"
              (click)="callCecVDExploreSectionCTEvent('pri', meetingRoomExploreData[0]?.HallsHeaderText?.data);_sharedService.checkCTALink($event, meetingRoomExploreData[0]?.FirstCTALink?.data, meetingRoomExploreData[0]?.FirstCTALinkWindowPreference?.data, meetingRoomExploreData[0]?.TypeOfFirstCTALink?.data)">
              <span class="cta-prim-txt">{{meetingRoomExploreData[0]?.FirstCTAText?.data}}</span></a>
            <a href="{{meetingRoomExploreData[0]?.SecondCTALink?.data}}" *ngIf="meetingRoomExploreData[0]?.HideSecondCTAText?.data =='false'"
              class="cta-sec icon rh icon-download-icon color-blue"
              (click)="callCecVDExploreSectionCTEvent('download', meetingRoomExploreData[0]?.HallsHeaderText?.data, meetingRoomExploreData[0]?.SecondCTALink?.data); _sharedService.checkCTALink($event, meetingRoomExploreData[0]?.SecondCTALink?.data, '_self', 'PDF', pdfName); ">
              <span class="cta-prim-txt">{{meetingRoomExploreData[0]?.SecondCTAText?.data}}</span></a>
          </div>
        </div>
      </div>
      <!-- <div class="explorevenues_expand"> -->
        <!-- <div class="container">
          <div class="row">
            <h4 class="explorevenues_expand--title">{{meetingRoomExploreData[0]?.SubHallsMainHeader?.data}}</h4>
            <ul class="nav nav-tabs explore_expand_list" role="tablist">
              <li class="nav-item" *ngFor="let item of meetingRoomExploreData[0].cards; let i = index"> <a
                  class="nav-link" data-toggle="tab" [attr.href]="'#'+ (i+1)" role="tab"
                  (click)="getObjAndIndex(item, (i+1))">{{item?.TabHeaderText?.data}}</a> </li>
            </ul>
            <a (click)="getObjAndIndex(meetingRoomExploreData[0].cards[0], 1)"
              class="cta-sec icon icon-expand icon-arrow-down" data-toggle="collapse"
              data-target="#collapseBox_fouth"><span class="cta-prim-txt">Expand</span></a>
          </div>
        </div> -->
      <!-- </div> -->
      <!-- <div id="collapseBox_fouth" class="collapse expandcard"> -->
        <!-- <div class="tab-content">

          <div class="tab-pane active" [id]="roomsId" role="tabpanel">
            <div class="tabslider" data-space="30" data-mobile-space="20" data-slides="2.7" data-mobile-slides="1.1">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" *ngFor="let innerItem of roomsObj.AddCardsDetails; let k = index">
                    <div class="explorevenues_wrapper_each_copy cardSlider_card">
                      <h4>{{innerItem?.CardHeaderText?.data}}</h4>
                      <div class="icons_block">
                        <div class="icons_block_wrapper">
                          <div class="container">
                            <div class="row">
                              <div class="col-md-12 col-lg-6 pl-0">
                                <div class="icons_block_wrapper_each">
                                  <div class="icons_block_wrapper_each--icon"> <img
                                      src="{{staticUrlForImgNew + innerItem?.SubHallsFirstInfographicImage?.image?.contentUrl}}"
                                      alt="icon1"> </div>
                                  <div class="icons_block_wrapper_each_copy">
                                    <h4 class="icons_block_wrapper_each_copy--number">
                                      {{innerItem?.SubHallsFirstInfographicHeaderText?.data}}</h4>
                                    <span
                                      class="icons_block_wrapper_each_copy--name" [innerHTML]="innerItem?.SubHallsFirstInfographicShortText?.data"></span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-6 pl-0">
                                <div class="icons_block_wrapper_each">
                                  <div class="icons_block_wrapper_each--icon"> <img
                                      src="{{staticUrlForImgNew + innerItem?.SubHallsSecondInfographicImage?.image?.contentUrl}}"
                                      alt="icon2"> </div>
                                  <div class="icons_block_wrapper_each_copy">
                                    <h4 class="icons_block_wrapper_each_copy--number">
                                      {{innerItem?.SubHallsSecondInfographicHeaderText?.data}}</h4>
                                    <span
                                      class="icons_block_wrapper_each_copy--name" [innerHTML]="innerItem?.SubHallsSecondInfographicShortText?.data"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cta-holder">
                        <a href="{{innerItem?.SubHallsFirstCTALink?.data}}" *ngIf="innerItem?.SubHallsHideFirstCTAText?.data =='false'"
                          class="cta-prim color-blue emp-btn"
                          (click)="_sharedService.checkCTALink($event, innerItem?.SubHallsFirstCTALink?.data, innerItem?.SubHallsFirstCTALinkWindowPreference?.data, innerItem?.SubHallsTypeOfFirstCTALink?.data)">
                          <span class="cta-prim-txt">{{innerItem?.SubHallsFirstCTAText?.data}}</span>
                        </a>
                        <a href="{{innerItem?.SubHallsSecondCTALink?.data}}" *ngIf="innerItem?.SubHallsHideSecondCTAText?.data =='false'"
                          class="cta-sec color-blue"
                          (click)="_sharedService.checkCTALink($event, innerItem?.SubHallsSecondCTALink?.data, innerItem?.SubHallsSecondCTALinkWindowPreference?.data, innerItem?.SubHallsTypeOfSecondCTALink?.data)">
                          <span class="cta-sec-txt">{{innerItem?.SubHallsSecondCTAText?.data}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-arrows">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
              </div>
            </div>

          </div>

        </div> -->
      <!-- </div> -->
    </div>

    <div class="text-center load-more load-more-button" *ngIf="!hideLoadMore"> <a href="javascript:void(0);"
        class="cta-sec color-blue" (click)="loadMore()"><span class="cta-sec-txt">load more</span></a> </div>
  </div>
</section>
<div class="modal fade image_gallery_popup" [id]="modalId" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="modalObj">

  <div class="wrapper modal-dialog" role="document">
    <div class="modal-content">
      <a href="javascript:void(0);" class="close" data-dismiss="modal"></a>
      <div class="jwc_image_slider gallery_popup_slider popup_slider single_image" data-space="0" data-slides="1">

        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="jwc_image_slider_each">
                <img src="{{staticUrlForImgNew + modalObj.PopupImage?.image?.contentUrl}}" class="img-fluid" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-arrows">
          <div class="swiper-button-next swiper-button-disabled"></div>
          <div class="swiper-button-prev swiper-button-disabled"></div>
        </div>
      </div>
    </div>
  </div>

</div>