<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <section class="jwc_inquiry_kma jwc_inquiry_inside">
    <div class="breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <strong>Enquiries</strong>
      </div>
    </div>
    <div class="container" *ngIf="headlineData">
      <div class="section_heading section_heading--title section_heading--text text-center fullwidth" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
          <h2 [innerHTML]="headlineData?.sectionHeadline"></h2>
          <p [innerHTML]="headlineData?.sectionSubHeadline"></p>
      </div>
    </div>
  </section>

  <section class="jwc_inquiry_form">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
          <h1 class="custom-seo-heading">Let’s Connect</h1>
          <p> With just a few details, we’ll be able to assist you</p>
      </div>
      <div *ngIf="inquiryData">
        <app-general-jwc [inquiryData]="inquiryData" (inquiryTypeValue)="changeInquiryType($event)"
          [captchaSiteKey]="captchaSiteKey" [selectedBrand]="brand" (brandValue)="changeBrandType($event)"
          [disableSubmitBtn]="disableSubmitBtn"
          *ngIf="(selectedBrand == 'DAS'||selectedBrand == 'JWC' || selectedBrand == 'TCC') && inquiryType== 'General'">
        </app-general-jwc>
        <app-general-cec [inquiryData]="inquiryData" (inquiryTypeValue)="changeInquiryType($event)"
          [captchaSiteKey]="captchaSiteKey" (brandValue)="changeBrandType($event)" [disableSubmitBtn]="disableSubmitBtn"
          *ngIf="selectedBrand == 'CEC' && inquiryType== 'General'" [typeOfHall]="space"></app-general-cec>
        <app-general-garden [inquiryData]="inquiryData" (inquiryTypeValue)="changeInquiryType($event)"
          [captchaSiteKey]="captchaSiteKey" (brandValue)="changeBrandType($event)" [disableSubmitBtn]="disableSubmitBtn"
          *ngIf="selectedBrand == 'JWG' && inquiryType== 'General'">
        </app-general-garden>
        <app-venue-hire-cec [inquiryData]="inquiryData" (inquiryTypeValue)="changeInquiryType($event)"
          [captchaSiteKey]="captchaSiteKey" (brandValue)="changeBrandType($event)" [disableSubmitBtn]="disableSubmitBtn"
          *ngIf="selectedBrand == 'CEC' && inquiryType== 'Venue Hire'" [typeOfHall]="space"></app-venue-hire-cec>
        <app-venue-hire-garden [inquiryData]="inquiryData" (inquiryTypeValue)="changeInquiryType($event)"
          [captchaSiteKey]="captchaSiteKey" (brandValue)="changeBrandType($event)" [disableSubmitBtn]="disableSubmitBtn"
          *ngIf="selectedBrand == 'JWG' && inquiryType == 'Venue Hire'">
        </app-venue-hire-garden>
        <app-inquiry-careers [inquiryData]="inquiryData" (inquiryTypeValue)="changeInquiryType($event)"
          [captchaSiteKey]="captchaSiteKey" [selectedBrand]="brand" (brandValue)="changeBrandType($event)"
          [disableSubmitBtn]="disableSubmitBtn" *ngIf="inquiryType == 'Careers'" [AllRoles]="AllRoles" [selectedRole]='selectedRole'>
        </app-inquiry-careers>
        <app-logistics-cec [inquiryData]="inquiryData" (inquiryTypeValue)="changeInquiryType($event)"
          [captchaSiteKey]="captchaSiteKey" [selectedBrand]="brand" (brandValue)="changeBrandType($event)"
          [disableSubmitBtn]="disableSubmitBtn" *ngIf="inquiryType == 'Logistics Services'">
        </app-logistics-cec>
      </div>
    </div>
  </section>
  <section class="jwc_inquiry_kma pt-5 pb-5" *ngIf="headlineData?.inquiryLinks && (this.inquiryType == 'General' || this.inquiryType == 'Venue Hire')">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text text-center fullwidth">
          <h2></h2>
          <p> For any other queries, please write to us at:</p>
      </div>
      <div class="jwc_inquiry_kma_links">
        <div class="jwc_inquiry_kma_links--each" *ngFor="let item of headlineData?.inquiryLinks"><span *ngIf="item?.linkName">{{item?.linkName}}: </span><a href="{{'mailto:'+item?.linkUrl}}">{{item?.linkUrl}}</a></div>
      </div>
    </div>
  </section>


</main>
<!-- <app-footer [colorList]="inquiryColorList"></app-footer> -->
<div class="overlay"></div>