<app-sub-header [colorList]="cecColorList" [subHeaderList]="cecSubHeaderList" [pageName]="'cec'"
    *ngIf="cecSubHeaderList" class="hide-in-mobile"></app-sub-header>
<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>

    <div class="kma_with_thumbnails  ">
        <div class="breadcrumbs mb-0 kma_breadcrumbs">
            <div class="breadcrumbs_container">
                <a href='/'>Jio World Centre </a> / <a href='/jio-world-convention-centre'>Jio World Convention
                    Centre</a> / <strong>{{currentEvent?.EventTitle?.data}}</strong>
            </div>
        </div> <!-- Swiper -->
        <div class="swiper-container kma_with_thumbnails_big_img"
            *ngIf="currentEvent.HideBannerSlider.data != 'true'">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let eventItem of currentEvent?.AddBannerSlider; let i=index;">
                    <ng-container *ngIf="eventItem?.BackgroundImageVideo?.data=='image'">
                        <img src="{{ staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl }}"
                             alt="{{currentEvent?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.UploadMobileKMAImageVideo?.document?.contentUrl }}" alt="{{currentEvent?.ImageAltText?.data}}" class="big_img--mobile" />
                   </ng-container>
                   <ng-container *ngIf="eventItem?.BackgroundImageVideo?.data=='video'">
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}" alt="{{currentEvent?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}" alt="{{currentEvent?.ImageAltText?.data}}" class="big_img--mobile" />
                        <a href="javascript:;" class="play_href watchVideoClick blue" [attr.data-url]=" staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl"
                             data-target="#video_box" data-toggle="modal">
                             <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
                        </a>
                   </ng-container>
                   <ng-container
                        *ngIf="eventItem?.BackgroundImageVideo?.data=='360 video' || eventItem?.BackgroundImageVideo?.data=='360 image'">
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}" alt="{{currentEvent?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}" alt="{{currentEvent?.ImageAltText?.data}}" class="big_img--mobile" />
                        <a *ngIf="eventItem?.BackgroundImageVideo?.data=='360 video'" href="javascript:;"
                             class="jwc_360tour_btn play360" [attr.videosrc]="staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl">
                             <div class="jwc_360tour_btn--icon mb-0">
                                  <img src="./assets/images/360/360.svg" class="img-fluid" alt="360 icon">
                             </div>
                        </a>
                        <a *ngIf="eventItem?.BackgroundImageVideo?.data=='360 image'" href="javascript:;"
                             class="jwc_360tour_btn play360" [attr.imgsrc]="staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl">
                             <div class="jwc_360tour_btn--icon mb-0">
                                  <img src="./assets/images/360/360.svg" class="img-fluid" alt="360 icon">
                             </div>
                        </a>
                   </ng-container>
                </div>
            </div>
            <!-- Add pagination -->
            <div class="swiper-pagination"></div>
        </div>
       
    </div>

    <app-video-popup></app-video-popup>

    <section class="event_short_details" [attr.style]="('--data-details-bgColor:#004EA8') | safe: 'style'">
        <div class="container">
            <!-- <div class="event_short_details_img">
                <picture>
                    <source media="(max-width:767px)" srcset="./assets/images/cec-event-details-banner-small.jpg">
                    <img src="./assets/images/details-thumbnail.jpg" alt="details-thumbnail" />
                </picture>
            </div> -->
            <div class="event_short_details_content">
                <div class="event_short_details_title">
                    <h4>{{currentEvent?.EventTitle?.data}}</h4>
                </div>
                <!-- <div class="event_short_details_link">
                    <a href="javascript:void(0)" class="cta-prim color-white emp-btn mb-0"><span class="cta-prim-txt">VISIT
                            WEBSITE</span></a>
                </div> -->
            </div>
        </div>
    </section>
    <!-- <section class="covid2">
        <div class="container">
            <div class="section_heading">
                <div class="row">
                    <div class="col-md-3">
                        <h4 class="section_heading--title">Visit Safely</h4>
                    </div>
                    <div class="col-md-7">
                        <p class="section_heading--text content3">Safe, socially distanced experiences for all.</p>
                    </div>
                    <div class="col-md-2" data-aos="fade-up" data-aos-delay="300">
                        <div class="buttons">
                            <a href="{{'/health-and-covid-safety-measures'}}"
                                (click)="_sharedService.checkCTALink($event, '/health-and-covid-safety-measures')"
                                class="cta-sec color-white"><span class="cta-sec-txt">LEARN
                                    MORE</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="cec_event_details">
        <div class="container">
            <div class="row justify-content-between ">
                <div class="cec_event_details_title col-lg-7 col-md-7">
                    <div class="section_heading section_heading--title section_heading--text    "
                        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
                        <h2>{{currentEvent?.EventDetailsHeader?.data}}</h2>
                    </div>

                </div>
                <div class="clear"></div>
                <div class="col-lg-7 col-md-7 cec_event_details_text">
                    <p [innerHtml]="currentEvent?.EventDetailsContent?.data"></p>
                    <!-- <p class="content3">As one of the most significant events on India’s fashion calendar, the phygital edition of the FDCI X Lakmé Fashion Week with digital and on-ground shows starts on October 7th, and is presented by Lakmé, Fashion Design Council of India & RISE Worldwide. The on-ground venue is the Jio World Convention Centre, an epicentre for ideas, innovation and culture.
                    </p>
                    <p class="content3">The iconic show will not only showcase the most innovative and exciting designers in the country but also give some of the newest and brightest creative talents an opportunity to pave the way to successful careers in fashion. Some of the showstoppers in this edition include designers like Anamika Khanna, Pankaj & Nidhi, and MXS by Monisha Jaising and Shweta Bachchan; the Gen Next winners; FDCI will present designers Troy Costa, JJ Valaya and The Pot Plant; R|Elan Circular Design Challenge winner; AJIO LUXE See Now Buy Now experience AJIO LUXE; and the Lakmé Absolute Grand Finale. This is just a glimpse of what’s in store. Stay tuned for more updates.
                    </p> -->
                </div>
                <div class="col-lg-4 col-md-4 cec_event_details_data">
                    <div class="cec_event_details_data_each" *ngIf="currentEvent?.EventVenue?.data && currentEvent?.EventVenue?.data!=''">
                        <h4>Venue</h4>
                        <p class="content3">{{currentEvent?.EventVenue?.data}} </p>
                        <a href="{{'/get-directions'}}" (click)="_sharedService.checkCTALink($event, '/get-directions')"
                            class="cta-sec color-blue "><span class="cta-sec-txt">GET DIRECTIONS</span></a>
                    </div>
                    <div class="cec_event_details_data_each" *ngIf="currentEvent?.EventDate?.data && currentEvent?.EventDate?.data!=''">
                        <h4>{{currentEvent?.EventDateLabel?.data}}</h4>
                        <p class="content2" [innerHtml]="currentEvent?.EventDate?.data"></p>
                    </div>
                    <div class="cec_event_details_data_each" *ngIf="currentEvent?.EventTime?.data && currentEvent?.EventDate?.data!=''">
                        <h4>{{currentEvent?.EventTimeLabel?.data}}</h4>
                        <div class="time">
                            <ul>
                                <li>
                                    <p class="content2" [innerHtml]="currentEvent?.EventTime?.data"></p>
                                </li>
                                <!-- <li>
                                    <p class="content2">16:00 - 20:00</p>
                                </li> -->
                                <li>
                                    <p class="content2">&nbsp;&nbsp;</p>
                                </li>
                            </ul>
                        </div>
                        <!-- <a href="{{'/assets/images/lfw-2021-schedule.jpg'}}" (click)="_sharedService.checkCTALink($event, './assets/images/lfw-2021-schedule.jpg', '_blank', 'External')" class="cta-sec icon rh icon-download-icon color-blue mt-2"><span
                                class="cta-sec-txt">DOWNLOAD SCHEDULE</span></a>
                        <div class="clear"></div>
                        <a href="{{'https://lakmefashionweek.co.in/'}}" (click)="_sharedService.checkCTALink($event, 'https://lakmefashionweek.co.in/', '_blank', 'External')" class="cta-prim color-blue emp-btn mt-5"><span
                                class="cta-prim-txt">Register</span></a> -->

                        <a href="{{currentEvent?.DownloadCTALink?.data}}"  class="cta-sec icon rh icon-download-icon color-blue mt-2" *ngIf="currentEvent?.HideDownloadCTAText?.data=='false'"
                            (click)="downloadFile('download', '', currentEvent?.DownloadCTALink?.data, ''); _sharedService.checkCTALink($event, currentEvent?.DownloadCTALink?.data, '_self', 'PDF', pdfName)">
                            <span class="cta-sec-txt">{{currentEvent?.DownloadCTAText?.data}}</span>
                        </a>
                            <div class="clear"></div>
                        <a href="{{currentEvent?.RegisterCTALink?.data}}" class="cta-prim color-blue emp-btn mt-5" *ngIf="currentEvent?.HideRegisterCTAText?.data=='false'"
                            (click)="_sharedService.checkCTALink($event, currentEvent?.RegisterCTALink?.data, currentEvent?.RegisterCTALinkWindowPreference?.data)">
                            <span class="cta-prim-txt">{{currentEvent?.RegisterCTAText?.data}}</span>
                        </a>        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div [innerHTML]="jsonLDForEvent" *ngIf="jsonLDForEvent && jsonLDForEvent!=''"></div>
</main>