<!-- <app-header [colorList]="culturalCentreColorList" [pageName]="'tcc'"></app-header> -->
<app-sub-header [colorList]="culturalCentreColorList" [subHeaderList]="culturalCentreSubHeaderList" *ngIf="culturalCentreSubHeaderList" [pageName]="'tcc'">
</app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <app-thank-you-page [brandName]="'Nita Mukesh Ambani Cultural Centre'" [subscriptionFlag]="subscriptionFlag" *ngIf="successfulSubscription"></app-thank-you-page>
  <!-- <app-banner [landingBannerData]="tccBannerData" [bannerPage]="'tcc'" *ngIf="tccBannerData" [sectionClass]="'screen_height_inside'" [fragmentId]="'performing-arts'"> -->
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <strong>Nita Mukesh Ambani Cultural Centre</strong>
      </div>
    </div>
    <!-- </app-banner> -->
    <div *ngIf="tccBannerData?.HideComponentOnSite?.data==='false'">
      <app-banner-new *ngIf="tccBannerData" [newBannerData]="tccBannerData"></app-banner-new>
    </div>

  <!-- <section class="art" *ngIf="performingArtsData" data-mobile-header="Performing Arts" id="performing-arts" [attr.style]="('--data-art-bglayer: ' + performingArtsData?.ComponentBackgroundColor?.data ) | safe: 'style'">
    <div class="container">
      <div class="row align-items-center justify-content-between ">
        <div class="art_img col-lg-8 col-md-8 pr-5">
          <img src="{{ staticUrlForImgNew + performingArtsData.cards[0]?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{performingArtsData?.cards[0]?.ImageAltText?.data}}">
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C5299B') | safe: 'style'">
              <h2 [innerHTML]="performingArtsData?.ComponentHeaderTitle?.data"></h2>
              <p *ngIf="performingArtsData?.ComponentHeaderSubTitle?.data" [innerHTML]="performingArtsData?.ComponentHeaderSubTitle?.data"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="art " *ngIf="visualArtsData" data-mobile-header="Visual Arts" id="visual-arts" [attr.style]="('--data-art-bglayer: ' + visualArtsData?.ComponentBackgroundColor?.data ) | safe: 'style'">
    <div class="container">
      <div class="row align-items-center justify-content-between flex-row-reverse">
        <div class="art_img col-lg-8 col-md-8 pl-5">
          <img src="{{ staticUrlForImgNew + visualArtsData.cards[0]?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{visualArtsData?.cards[0]?.ImageAltText?.data}}">
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C5299B') | safe: 'style'">
              <h2 [innerHTML]="visualArtsData?.ComponentHeaderTitle?.data"></h2>
              <p *ngIf="visualArtsData" [innerHTML]="visualArtsData?.ComponentHeaderSubTitle?.data"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="art " data-mobile-header="Our Vision" id="our-vision" *ngIf="ourVisionData" [attr.style]="('--data-art-bglayer: ' + ourVisionData?.ComponentBackgroundColor?.data ) | safe: 'style'">
    <div class="container">
      <div class="row align-items-center justify-content-between ">
        <div class="art_img col-lg-8 col-md-8 pr-5">
          <img src="{{ staticUrlForImgNew + ourVisionData.cards[0]?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{visualArtsData?.cards[0]?.ImageAltText?.data}}">
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C5299B') | safe: 'style'">
              <h2 [innerHTML]="ourVisionData?.ComponentHeaderTitle?.data"></h2>
              <p *ngIf="ourVisionData?.ComponentHeaderSubTitle?.data" [innerHTML]="ourVisionData?.ComponentHeaderSubTitle?.data"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div data-mobile-header="The Fountain" *ngIf="dasData" [id]="dasId">
    <app-benefits-wrapper [benefitsWrapperData]="dasData" [colorList]="culturalCentreColorList" [rowReverseClass]="true"></app-benefits-wrapper>
  </div> -->
  <!-- <section class="news_letter_4 small_space"
    style="--data-newsletter4-bgColor:#FFFFFF; --data-titleColor:#4B4847; --data-lineColor:#A67D54"> -->
    <!-- <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-7 col-md-6">
          <h4 class="section_heading--title">The Best of Our World, Right in Your Inbox</h4>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="input-box">
            <form #tccSubscriptionForm="ngForm" novalidate>
              <label for="subscribe-field" class="sr-only">Enter Email Address</label>
              <input type="text" id="subscribe-field" placeholder="Email address" class="input-field gold_color"
                name="email" #email="ngModel" ngModel email required>
              <button class="cta-sec color-gold emp-btn" (click)="onSubscribeClick(tccSubscriptionForm)"><span
                  class="cta-sec-txt">SIGN UP</span></button>
              <div [ngClass]="(!showSubMsg) ? 'd-none': ''" *ngIf="tccSubSuccessMessage" class="error-txt success-txt">
                {{tccSubSuccessMessage}}</div>
              <div [ngClass]="(!showSubMsg) ? 'd-none': ''" *ngIf="tccSubApiErrorMessage" class="error-txt">
                {{tccSubApiErrorMessage}}</div>
              <div *ngIf="tccSubErrorMessage" class="error-txt">{{tccSubErrorMessage}}</div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
  <!-- </section> -->
  <app-subscribe-section *ngIf="subscribeData" [subscribeData]="subscribeData.data" [colorList]="culturalCentreColorList" [pageName]="'tcc'" [sectionClass]="'small_space'"></app-subscribe-section>
  <!-- <div data-mobile-header="Dining" *ngIf="diningTccData" id="dining">
    <app-benefits-wrapper [benefitsWrapperData]="diningTccData" [colorList]="culturalCentreColorList"></app-benefits-wrapper>
  </div> -->
</main>
<!-- <app-footer [colorList]="culturalCentreColorList"></app-footer> -->
<div class="overlay"></div>