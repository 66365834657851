import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-cec-venue-listing',
  templateUrl: './cec-venue-listing.component.html',
  styleUrls: ['./cec-venue-listing.component.css']
})
export class CecVenueListingComponent implements OnInit {
  private subscription;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  ourVenueData: any;
  faqData: any;
  planNextEventData: any;
  hallsRoomsData: any[];
  filteredHallsRoomsData: any = [];
  cecVlHeaderList;
  roomsObj = {};
  modalObj: any;
  clickedId: any;
  modalId: any;
  flag: boolean = false;
  spacesList = ['Spaces', 'Exhibition Halls', 'Convention Halls', 'Meeting Rooms', 'Banquet Hall'];
  cecVLColorList = {
    footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', headerDataHoverColor: "#1E478E", primaryColor: '#1E478E', dataPlanBgColor: '#F5F2ED', dataNewsletter2LineColor: '#1E478E', dataPlanLineColor: '#1E478E'
  }
  pdfName;
  planYourEveId: string;
  exploreVenId: string;

  constructor(public _sharedService: SharedService, private titleService: Title) { 
  this.cecVlHeaderList = (window.innerWidth > 991) ? ["Plan your event", "Explore Our Venues"] : ["About", "Explore", "Plan", 'FAQs'];
  this.exploreVenId = (window.innerWidth > 991) ? 'explore-our-venues' : 'explore';
  this.planYourEveId = (window.innerWidth > 991) ? 'plan-your-event' : 'plan';
  }

  ngOnInit() {
    this.getCecVenueListingContent();
    let self = this;
    jQuery('#peopleRangeSlider').on("slideStop", function (number) {
      self.filterList('people', number.value[0], number.value[1]);
    });
  }

  getCecVenueListingContent() {
    let keyList = [
      'CEC-VENUE-LISTING-OUR-VENUES-YOUR-PLATFORM',
      'CEC-VENUE-LISTING-FAQS',
      'CEC-VENUE-LISTING-PLAN-YOUR-NEXT-BIG-EVENT',
      60723, // Halls and Rooms (Exhibition Halls)
      60764, // Halls and Rooms (Convention Halls)
      60802, // Halls and Rooms (Banquet Halls)
      60889, // Halls and Rooms (Meeting Rooms)
      'CEC-VENUE-LISTING-EXPLORE-MORE-VENUES-CONCOURSES' // Halls and Rooms (Concourses)
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
      (data: any) => {
        this.ourVenueData = data[0] ? data[0].data : data[0];
        this.faqData = data[1] ? data[1].data : data[1];
        this.planNextEventData = data[2] ? data[2].data : data[2];
        this.hallsRoomsData = [
          data[3] ? data[3].data : data[3],
          data[4] ? data[4].data : data[4],
          data[5] ? data[5].data : data[5],
          data[6] ? data[6].data : data[6],
          data[7] ? data[7].data : data[7]
        ];
        this._sharedService.initializeCustomJs();
        this._sharedService.callCustomDropdown();
        this._sharedService.callPeopleRangeSlider();
        setTimeout(() => {
          this._sharedService.callAccordianWithSlider();
        }, 800);        
        this.cecVlHeaderList = this._sharedService.subHeaderItems(this.cecVlHeaderList);
        this.getValue("Spaces");
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  getObjAndIndex(obj, id) {
    this.roomsObj = obj;
    this.clickedId = id;
    this.modalObj = obj;
    this.modalId = 'gallery' + id;
  }

  resetSpaces() {
    this.hallsRoomsData.forEach(element => {
      if(element){
        this.filteredHallsRoomsData.push(element);
      }
    });
  }

  getValue(selectedValue) {
    this.filteredHallsRoomsData = [];
    selectedValue = (selectedValue == 'Banquet Hall')? 'ballroom' : selectedValue;
    setTimeout(() => {
      if (selectedValue != 'Spaces') {
        this.filteredHallsRoomsData = this.hallsRoomsData.filter(element => {
          if (element) {
            return element.HallsHeaderText.data.toLowerCase().includes(selectedValue.toLowerCase());
          }
        });
      } else {
       this.resetSpaces();
      }
      this._sharedService.callSwipertab();
      this._sharedService.callNormalSlider3();
      this._sharedService.callExpandcollapse();
    }, 100);
  }

  filterList(type, from?, to?) {
    jQuery(".spaces-dropdown").text('Spaces');
    this.filteredHallsRoomsData = [];
    this.resetSpaces();
    if(type == 'people') {
      jQuery(".sort-dropdown").text('Sort Type');
      this.filteredHallsRoomsData = this.hallsRoomsData.filter(element => {
        if (element) {
          let ele = element.ThirdInfographicHeaderText.data.replace(/[^\d-]/g, '');
          if(ele>= from && ele <= to) {
            return element;
          }
        }
      });
      return;
    }
    let sortedData = this.filteredHallsRoomsData.sort((a,b) => {
      let res = 0;
      if(type == 'size') {
        let n1 = (a.HallsHeaderText.data =='Concourses')? +a.FirstInfographicHeaderText.data.replace(/[^\d-]/g, '') : +a.SecondInfographicHeaderText.data.replace(/[^\d-]/g, '');
        let n2 = (b.HallsHeaderText.data =='Concourses')? +b.FirstInfographicHeaderText.data.replace(/[^\d-]/g, '') : +b.SecondInfographicHeaderText.data.replace(/[^\d-]/g, '');
        res = (n1 == n2)? 0: (!n1)? 1: (!n2)? -1: (n1>n2)? 1: (n1<n2)? -1: 0;
      } else if(type == 'capacity'){
        let n1 = +a.ThirdInfographicHeaderText.data.replace(/[^\d-]/g, '');
        let n2 = +b.ThirdInfographicHeaderText.data.replace(/[^\d-]/g, '');
        res = (n1 == n2)?0:(!n1)?1:(!n2)?-1:(n1>n2)?1 : (n1<n2)? -1 : 0;
      }
      return res;
    });
    this.filteredHallsRoomsData = sortedData;
  }
  callCecVenueCTEvent(btn, sectionTitle, pdfName?, staticLink?) {
    switch (btn){
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle, '', staticLink);
        break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(pdfName);
        this._sharedService.callCTEvent('Download_Links', sectionTitle, this.pdfName);
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
