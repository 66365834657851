<!-- <app-header [colorList]="cecColorList" [pageName]="'cec'"></app-header> -->
<app-sub-header [colorList]="cecColorList" [subHeaderList]="cecSubHeaderList" [pageName]="'cec'"
    *ngIf="cecSubHeaderList"></app-sub-header>
<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>
    <app-sticky-menu></app-sticky-menu>
    <app-thank-you-page [brandName]="'Jio World Convention Center'" *ngIf="successfulSubscription"></app-thank-you-page>
    <app-banner [landingBannerData]="cecBannerData" [bannerPage]="'cec'" *ngIf="cecBannerData" [fragmentId]="'about'"
        [sectionClass]="'convention_centre screen_height_inside'" [blackTint]="true"></app-banner>
    <app-covid2-section *ngIf="cecBannerData" [watchVideo]="true"
        [watchVideoUrl]="covidKeyHighlightsData?.cards[0]?.UploadKMAImageVideo?.document?.contentUrl">
    </app-covid2-section>
    <div id="about" data-mobile-header="About">
        <section class="benefits_wrapper " *ngIf="eventsData">
            <div class="container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-lg-5 col-md-5">
                        <div class="section_heading section_heading--title section_heading--text content2"
                            [attr.style]="('--data-titleColor:#212529;--data-lineColor:#1E478E') | safe: 'style'">
                            <h2 [innerHTML]="eventsData?.ComponentHeaderTitle?.data"></h2>
                            <p>{{eventsData?.ComponentHeaderSubTitle?.data}}</p>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#004EA8') | safe: 'style'">
                            <div class="benefits_slider jwc_swiper_benefits" data-interval="4"
                                [ngClass]="{'single_image': eventsData?.cards?.length == 1}">
                                <div class="swiper-container">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" *ngFor="let item of eventsData?.cards; let i=index">
                                            <div class="benefits_slider_each">
                                                <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}"
                                                    class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Add Pagination -->
                                <div class="swiper-pagination" *ngIf="eventsData.cards.length > 1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-infographics-section *ngIf="infographicsData" [infographicsData]="infographicsData" [isIconsBlockClass]="true">
    </app-infographics-section>
    <div [id]="exploreId" data-mobile-header="Explore">
        <app-venues-wrapper [enquireData]="enquireData" [planId]="planId" [exploreVenuesData]="exploreVenuesData"
            *ngIf="enquireData"></app-venues-wrapper>
    </div>
    <div id="getting-here" data-mobile-header="Getting Here">
        <app-map *ngIf="accessWithEaseData" [accessWithEaseData]="accessWithEaseData" [colorList]="cecColorList"
            [sectionClass]="'pb-0'">
        </app-map>
    </div>

    <section class="events_wrapper plr-0 pb-0"
        [attr.style]="('--data-events-bgColor1:#FFFFFF;--data-events-lineColor:#004EA8;--data-event-arrowColor:#4B4847') | safe: 'style'" *ngIf="cecUpcomingEventData && (!cecUpcomingEventData?.HideComponentOnSite || cecUpcomingEventData?.HideComponentOnSite?.data !== 'true')">
        <div class="container">
            <div class="section_heading section_heading--title section_heading--text text-center "
                [attr.style]="('--data-lineColor:#004EA8') | safe: 'style'">
                <h2 [innerHTML]="cecUpcomingEventData?.ComponentHeaderTitle?.data"></h2>
                <p *ngIf="cecUpcomingEventData?.ComponentHeaderSubTitle?.data && cecUpcomingEventData?.ComponentHeaderSubTitle?.data!=''">{{cecUpcomingEventData.data?.ComponentHeaderSubTitle?.data}}</p>
            </div>

        </div>
        <div class="events_slider jwc_swiper_normal" data-interval="8" data-space="0" data-slides="2" data-ipad-slides="1.65" data-ipad-space="20" data-mobile-slides="1.2" data-mobile-space="20" [attr.data-loop]="cecUpcomingEventData?.cards?.length > 1" [attr.data-drag]="cecUpcomingEventData?.cards?.length > 1">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" [attr.data-swiper-autoplay]="getAutoSliderTime(cecUpcomingEventData?.AutomaticSlideshowInterval)" *ngFor="let item of cecUpcomingEventData?.cards">
                        <div class="events_slider_each">
                            <div class="events_slider_each--img">
                                <img src="{{ staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid" alt="{{item?.ImageAltText?.data}}" *ngIf="item?.CardImage?.image?.contentUrl && item?.CardImage?.image?.contentUrl!=''">
                            </div>
                            <div class="events_slider_each_copy border_btm_blue border_color" [attr.style]="('--data-line-bgColor:' + item?.SlideTransitionColor?.data) | safe: 'style'">
                                <div class="events_slider_each_copy--box">
                                    <span class="events_slider_each_copy--category">{{item?.EventType?.data}}</span>
                                    <h4 class="events_slider_each_copy--title" [innerHtml]="item?.EventTitle?.data"></h4>
                                    <p class="events_slider_each_copy--date content3" [innerHtml]="item?.EventDate?.data" *ngIf="item?.EventDate?.data"></p>
                                    <p class="events_slider_each_copy--time content3" [innerHtml]="item?.EventTime?.data" *ngIf="item?.EventTime?.data"></p>
                                    <div class="events_slider_each_copy--btns">
                                        <a *ngIf="item?.HideCTAText?.data =='false'" class="cta-prim color-blue" href="{{item?.CTALink?.data}}"
                                        (click)=" _sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTALinkWindowPreference?.data)">
                                            <span class="cta-prim-txt">{{item?.CTAText?.data}}</span>
                                        </a>        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-arrows" *ngIf="cecUpcomingEventData?.cards?.length>1">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </div>
    </section>
    <div data-mobile-header="Going Beyond" id="going-beyond">
        <app-experiences-section *ngIf="beyondConventionalData" [experiencesData]="beyondConventionalData"
            [colorList]="cecColorList" [experiencesSwiperData]='cecExpSwiperData' [multipleCalls]='true'>
        </app-experiences-section>
    </div>
    <app-organisers-speak *ngIf="organiserData" [organiserData]="organiserData" [colorList]="cecColorList"
        data-mobile-header="Testimonials" id="testimonials">
    </app-organisers-speak>
    <!-- <app-subscribe-section *ngIf="subscribeData" [subscribeData]="subscribeData" [colorList]="cecColorList"
        [pageName]="'cec'" [sectionClass]="'pb-0'"></app-subscribe-section> -->
    <div id="discover-mumbai" data-mobile-header="Discover Mumbai">
        <app-experiences-section *ngIf="discoverMumbaiData" [experiencesData]="discoverMumbaiData"
            [colorList]="cecColorList" [experiencesSwiperData]='cecExpSwiperData2' [sectionClass]="'pb-0'">
        </app-experiences-section>
    </div>
    <!-- <app-get-in-touch [getInTouchData]="buildItineraryData" *ngIf="buildItineraryData"
         [colorList]="cecColorList" [sectionClass]="'pb-0'">
    </app-get-in-touch> -->
    <!-- <section class="more_centre_wrapper plr-0"
        style="--data-more-centre-bgColor1:#F5F2ED;--data-more-centre-bgColor2: transparent;--data-more-centre-titleColor:#4B4847">
        <div class="container">
            <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#004EA8">
                <div class="">
                    <h2 class="section_heading--title">Explore our world</h2>
                </div>
            </div>
            <div class="more_centre_slider jwc_swiper_normal" data-interval="5" data-space="24" data-slides="3"
                data-mob="false" data-ipad-slides="2.2" data-ipad-space="20" data-mobile-slides="1.15"
                data-mobile-space="20">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="more_centre_slider_each">
                                <div class="more_centre_slider_each--img">
                                    <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                                    <span class="more_centre_slider_each_copy--category">Food Festival</span>
                                    <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                                    <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                    <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                                    <div class="more_centre_slider_each_copy--btns">
                                        <a href="#" class="cta-prim color-blue icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="more_centre_slider_each">
                                <div class="more_centre_slider_each--img">
                                    <img src="./assets/images/more/2.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                                    <span class="more_centre_slider_each_copy--category">Performing Arts</span>
                                    <h4 class="more_centre_slider_each_copy--title">Kalasri - Bharatnatyam</h4>
                                    <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                    <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                                    <div class="more_centre_slider_each_copy--btns">
                                        <a href="#" class="cta-prim color-blue icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="more_centre_slider_each">
                                <div class="more_centre_slider_each--img">
                                    <img src="./assets/images/more/3.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                                    <span class="more_centre_slider_each_copy--category">Visual Arts</span>
                                    <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                                    <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                    <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                                    <div class="more_centre_slider_each_copy--btns">
                                        <a href="#" class="cta-prim color-blue icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-arrows">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
            </div>
        </div>
    </section> -->
    <div>
        <app-faq-section id="faqs" data-mobile-header="FAQs" *ngIf="faqData" [faqData]="faqData" [colorList]="cecColorList"></app-faq-section>
        <div [innerHTML]="jsonLDforCecGeneral"></div>
        <div [innerHTML]="jsonLDforCecBooking"></div>
        <!-- <div [innerHTML]="jsonLDforCecAas"></div> -->
        <div [innerHTML]="jsonLDforCecPlanAVisit"></div>
    </div>
    <!-- <app-news-section *ngIf="inNewsData" [newsData]="inNewsData" [colorList]="cecColorList"></app-news-section> -->
    <!-- <app-jwc-moments-section *ngIf="momentsData" [momentsData]="momentsData" [colorList]="cecColorList">
    </app-jwc-moments-section> -->
    <!-- <section class="moments  pb-0" *ngIf="cecInstaData.length!=0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="section_heading text-center" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
            <p class="section_heading--text"><span class="icon icon-instagram" [attr.style]="('--data-icon-color:#A67D54;') | safe: 'style'">@dhirubhai_ambani_square</span></p>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 px-2 px-md-3" *ngFor="let item of cecInstaData; let i=index">
          <a href="{{item?.instagramLink}}" (click)="_sharedService.checkCTALink($event, item?.instagramLink, '_blank', 'External')">
            <img src="{{item?.mediaLink}}" class="img-fluid" alt="{{item?.instagramLink}}" />
          </a>
        </div>
        <div class="clearfix"></div>
        <div class="col-lg-12">
          <div class="moments_slider--follow text-center">
            <a href="https://www.instagram.com/dhirubhai_ambani_square/" (click)="callDasCTEvents('Section_Secondary_Links', 'Instagram', '', 'https://www.instagram.com/dhirubhai_ambani_square'); _sharedService.checkCTALink($event, 'https://www.instagram.com/dhirubhai_ambani_square/', '_blank', 'External')" class="cta-sec"><span class="cta-sec-txt">FOLLOW US</span></a>
          </div>
        </div>
      </div>
    </div>
  </section> -->
    <app-instagram2-section *ngIf="cecInstagramData" [instagramData]="cecInstagramData"
        [instagramHandleName]="'jioworldconventioncentre'" [followUsLink]="'jioworldconventioncentre'">
    </app-instagram2-section>
    <!-- <app-footer [colorList]="cecColorList"></app-footer> -->
</main>
<div class="overlay"></div>