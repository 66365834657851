import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-das-footer',
  templateUrl: './das-footer.component.html',
  styleUrls: ['./das-footer.component.css']
})
export class DasFooterComponent implements OnInit {
  currentYear
  articleData: any;
  reqOptions: { headers: any; };
  subscription: any;
  encryptedId;
  constructor(public _sharedService: SharedService, private http: HttpClient, public router: Router) {
    this.currentYear = new Date().getFullYear()
   }

  ngOnInit() {
    this.getArticlePageContent()
  }

  callCTFooterPrimEvent(link?) {
    link ? this._sharedService.callCTEvent('Footer_Primary_Links', '', '', link) : this._sharedService.callCTEvent('Footer_Primary_Links');
  } 
  getArticlePageContent() {
    this.subscription = this._sharedService.getCommonService().getSingleNewsArticlesData(2).subscribe(
      (data: any) => {
        // console.log("data: ", data)
        this.articleData = data ? data : [];
        if(data) this.encryptedId = btoa(this.articleData.newsId)
        this._sharedService.initializeCustomJs();
        // this._sharedService.load360Function();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  passArticleData(name) {
    this.router.navigate([name], {state: { articleJson: this.articleData }});
    return false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
