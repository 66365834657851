import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-sticky-menu',
  templateUrl: './sticky-menu.component.html',
  styleUrls: ['./sticky-menu.component.css']
})
export class StickyMenuComponent implements OnInit {
  decodedUrl;
  constructor(public _sharedService: SharedService, private router: Router) { }

  ngOnInit() {
    this.decodedUrl = decodeURIComponent('/enquiries?type=vh&brand=cec')
  }
  callCTStickyMenuEvent(linkName) {
    this._sharedService.callCTEvent('Sticky_Menu_Links', '', '', linkName);
  }
  goToEnguiry(){
    this.router.navigateByUrl(this.decodedUrl)
  }

}
