import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Directive } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SafePipe } from "./pipes/safe.pipe";
import { SharedInterceptor } from './interceptor/shared.interceptor';
import { AllowedInputDirective } from './directives/allowed-input.directive';
import { InvalidControlScrollDirective } from './directives/invalid-control-scroll.directive';
import { MultiListFilterPipe } from './pipes/multi-list-filter.pipe';

@NgModule({
  declarations: [SafePipe, AllowedInputDirective, InvalidControlScrollDirective, MultiListFilterPipe],

  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SharedInterceptor,
      multi: true
    },
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  entryComponents: [],

  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    SafePipe,
    AllowedInputDirective,
    InvalidControlScrollDirective,
    MultiListFilterPipe
  ]
})
export class SharedModule {}
