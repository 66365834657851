import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-press-and-media',
  templateUrl: './press-and-media.component.html',
  styleUrls: ['./press-and-media.component.css']
})
export class PressAndMediaComponent implements OnInit {
  pressMediaKeyArray: any[];
  subscription: any;
  pressMediaBannerData: any;
  pressMediaShowcaseData: any;
  pressMediaFaqData: any;
  pressMediaJwcFactsData: any;
  pressMediaEnquiryData: any;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  pressMediaColorList = {
    dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#004EA8', dataPlanBgColor: '#FFFFFF', dataNewsletter2LineColor: '#A67D54', dataPlanLineColor: '#A67D54'
  }
  pressMediaEnquiryColorList = {
    dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#A67D54', dataPlanBgColor: '#F5F2ED'
  }
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this.getPressMediaPageContent();
  }
  getPressMediaPageContent() {
    this.pressMediaKeyArray = [
      'PRESS-AND-MEDIA',
      'PRESS-MEDIA-SHOWCASE',
      'PRESS-MEDIA-FAQ',
      'PRESS-MEDIA-JIOWORLD-CENTRE-FAST-FACTS',
      'PRESS-MEDIA-INQUIRE-NOW'
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.pressMediaKeyArray).subscribe(
      (data: any) => {
        this.pressMediaBannerData = data[0] ? data[0].data : data[0];
        this.pressMediaShowcaseData = data[1] ? data[1].data : data[1];
        this.pressMediaFaqData = data[2] ? data[2].data : data[2];
        this.pressMediaJwcFactsData = data[3] ? data[3].data : data[3];
        this.pressMediaEnquiryData = data[4] ? data[4].data : data[4];
        this._sharedService.initializeCustomJs();   
        this._sharedService.callCustomDropdown();
        setTimeout(() => {
          this._sharedService.callAccordianWithSlider();
        }, 800);
        console.log(this.pressMediaEnquiryData);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  callPMCTEvent(type, sectionName) {
    switch (type){
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionName);
      break;
      case 'sec':
        this._sharedService.callCTEvent('Section_Secondary_Links', sectionName);
      break;
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
