import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-venue-details-showcase',
  templateUrl: './venue-details-showcase.component.html',
  styleUrls: ['./venue-details-showcase.component.css']
})
export class VenueDetailsShowcaseComponent implements OnInit {
  @Input() venueDetailsShowcaseData;
  @Input() colorList;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this._sharedService.load360Function();
  }

}
