import { Injectable } from '@angular/core';
import { SharedHttpService } from './shared-http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpService:SharedHttpService) { 

  }
  

  notificationLogin(payload) {
    let body = JSON.stringify(payload);
    return this.httpService.postLogin('getSubscriptionUrl', 'notification-signin', body);
  }

  Notification(passobj) {
    return this.httpService.postLogin('getSubscriptionUrl', 'notification', passobj)
  }
}
