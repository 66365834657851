import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../../../../shared/services/shared.service';
import { environment } from '../../../../../../environments/environment';
import { SafePipe } from '../../../../../shared/pipes/safe.pipe';


@Component({
  selector: 'app-cec-venue-details-meeting-package',
  templateUrl: './cec-venue-details-meeting-package.component.html',
  styleUrls: ['./cec-venue-details-meeting-package.component.css']
})
export class CecVenueDetailsMeetingPackageComponent implements OnInit {
  subHeaderText;
  meetingRoomPackageData;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  @Input('meetingRoomPackageData') set setInputData(value) {
    this.meetingRoomPackageData = value;
    //this.subHeaderText = this.getSubHeaderText(this.meetingRoomPackageData?.ComponentHeaderSubTitle?.data);
    this.subHeaderText = this.meetingRoomPackageData?.ComponentHeaderSubTitle?.data;
  }
  @ViewChild('readMore') readMore: ElementRef;
  staticText = {
    readMore: '...View More',
    readLess: '...View Less'
  }
  showWordCount = 300;
 
  constructor(public _sharedService: SharedService,
    private safePipe: SafePipe) { }

  ngOnInit(): void {
  }

  callJwgHomeCTEvent(btn, sectionName) {
    // this._sharedService.callCTEvent('Section_Secondary_Links', sectionName);
    switch (btn) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionName);
        break;
      case 'sec':
        this._sharedService.callCTEvent('Section_Secondary_Links', sectionName);
        break;
    }
  }

  getSubHeaderText(str, showFullText = false) {
    return !showFullText ? str?.slice(0, this.showWordCount) : str;
  }

  showHideReadMoreBtn() {
    const readMoreElement = this.readMore.nativeElement;
    if(readMoreElement.innerText === this.staticText.readMore) {
      readMoreElement.innerText = this.staticText.readLess;
      this.subHeaderText = this.getSubHeaderText(this.meetingRoomPackageData?.ComponentHeaderSubTitle?.data, true);
    } else {
      readMoreElement.innerText = this.staticText.readMore;
      this.subHeaderText = this.getSubHeaderText(this.meetingRoomPackageData?.ComponentHeaderSubTitle?.data);
    }
  }

  callPackageCTEventForCTA(event, type='cta') {
    const { eventObj } = this._sharedService.getCleverTapKeysAndObject(event, type);
    const eventName = eventObj.eventName || 'Section_Secondary_Links';
    this._sharedService.callCTEventNew(eventName, eventObj);
  }

}
