export const InterceptorSkipHeader = "X-Skip-Interceptor";
export class constants {
  static readonly IN_DATE_FMT = "yyyy-mm-dd";
  static readonly IN_DATE_DELIMETER = "-";
  static readonly IN_DATE_TIME_FMT = `${constants.IN_DATE_FMT} hh:mm:ss`;
  static readonly OUT_DATE_FMT = "dd/MM/yyyy";
  static readonly OUT_DATE_DELIMETER = "/";
  static readonly EMAIL_REGEX = "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  static URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  static countryCodes = [ "+0","+1","+1242","+1246","+1264","+1268","+1284","+1340","+1345","+1441","+1473","+1649","+1664","+1670","+1671","+1684","+1758","+1767","+1784","+1787","+1809","+1868","+1869","+1876","+20","+212","+212","+213","+216","+218","+220","+221","+222","+223","+224","+225","+226","+227","+228","+229","+230","+231","+232","+233","+234","+235","+236","+237","+238","+239","+240","+241","+242","+242","+244","+245","+246","+248","+249","+250","+251","+252","+253","+254","+255","+256","+257","+258","+260","+261","+262","+263","+264","+265","+266","+267","+268","+269","+27","+290","+291","+297","+298","+299","+30","+31","+32","+33","+34","+350","+351","+352","+353","+354","+355","+356","+357","+358","+359","+36","+370","+371","+372","+373","+374","+375","+376","+377","+378","+380","+381","+385","+386","+387","+389","+39","+39","+40","+41","+420","+421","+423","+43","+44","+45","+46","+47","+48","+49","+500","+501","+502","+503","+504","+505","+506","+507","+508","+509","+51","+52","+53","+54","+55","+56","+57","+58","+590","+591","+592","+593","+594","+595","+596","+597","+598","+599","+60","+61","+62","+63","+64","+65","+66","+670","+672","+672","+673","+674","+675","+676","+677","+678","+679","+680","+681","+682","+683","+684","+686","+687","+688","+689","+690","+691","+692","+7","+70","+7370","+81","+82","+84","+850","+852","+853","+855","+856","+86","+880","+886","+90","+91","+92","+93","+94","+95","+960","+961","+962","+963","+964","+965","+966","+967","+968","+970","+971","+972","+973","+974","+975","+976","+977","+98","+992","+994","+995","+996","+998"]
}

let apiEndPoint = {
  getLocationApi: "/geolocation/loc-by-ip",
  getredis: "/CU_SB_Redis/1.0.0/getRedisObject",
  getCache: "/EDC_NC_Business/1.0.0/GetSPdatafromCache",
  gettoken: "/gentoken/fetchToken",
  getrefreshtoken: "/gentoken/refreshToken",
  notification:'notification',
  login: "/login",
  logout: "/mylogout",
  // userImage: "/CU_NC_EmployeeInfo/1.0.0/UserImageSelf"
  userImage: "/CU_NC_EmployeeInfo/1.0.0/api/UserImage",
  addPoll: "/CI_NC_SPData/1.0.0/AddPollResults",
  calendarPost: '/CI_NC_SPData/1.0.0/AddEventParticipants',
  getDateWiseCalendarData:'/CI_NC_SPData/1.0.0/GetDateWiseCalendarData',
  corptest:'/CI_NC_SPData/1.0.0/AddPollResults',
  'getComponent': 'getComponent',
  'getSubscription': 'getSubscription',
  'setSubscription': 'setSubscription',
  'setUnSubscribe' : 'unSubscribe',
  'saveEnquiry' : 'saveEnquiry',
  'getNewsLetter': 'getNewsLetter',
  'getAllNewsLetters': 'getAllNewsLetters',
  'fetchPosition':'fetchPosition',
  'ActivePositions':'ActivePositions',
  encryptToken: '/nmacc/genTokenGeneric',
};

export let CONST: Object = {
  apiEndPoint: apiEndPoint
};
export interface metaKeyType {
  name: string;
  route: string,
  metaKey: string
}

export const MetaPageKeys: Array<metaKeyType> = [
  {
    name: 'newsroom',
    route: '/newsroom',
    metaKey: 'NEWSROOM-META-TAGS'
  },
  {
    name: 'contactUs',
    route: '/contact-us',
    metaKey: 'CONTACT-US-META-TAGS'
  },
  {
    name: 'getDirections',
    route: '/get-directions',
    metaKey: 'GET-DIRECTIONS-META-TAGS'
  },
  {
    name: 'healthAndCovidSafety',
    route: '/health-and-covid-safety-measures',
    metaKey: 'COVID-SAFETY-META-TAGS'
  },
  {
    name: 'cecVenue',
    route: '/jio-world-convention-centre/venues',
    metaKey: 'CEC-VENUES-META-TAGS'
  },
  {
    name: 'home',
    route: '/',
    metaKey: 'JWC-META-TAGS'
  },
  {
    name: 'cec',
    route: '/jio-world-convention-centre',
    metaKey: 'CEC-META-TAGS'
  },
  {
    name: 'cecExhibitHalls',
    route: '/jio-world-convention-centre/venues/exhibit-halls',
    metaKey: 'CEC-EXHIBITION-HALL-META-TAGS'
  },
  {
    name: 'cecExhibitHallsWalkthrough',
    route: '/jio-world-convention-centre/venues/exhibit-halls/walkthrough',
    metaKey: 'CEC-EXHIBITION-HALL-WALKTHROUGH-META-TAGS'
  },
  {
    name: 'cecConventionHalls',
    route: '/jio-world-convention-centre/venues/convention-halls',
    metaKey: 'CEC-CONVENTION-HALL-META-TAGS'
  },
  {
    name: 'cecConventionHallsWalkthrough',
    route: '/jio-world-convention-centre/venues/convention-halls/walkthrough',
    metaKey: 'CEC-CONVENTION-HALL-WALKTHROUGH-META-TAGS'
  },
  {
    name: 'cecbanquetHalls',
    route: '/jio-world-convention-centre/venues/banquet-halls',
    metaKey: 'CEC-BANQUET-HALL-META-TAGS'
  },
  {
    name: 'cecbanquetHallsWalkthrough',
    route: '/jio-world-convention-centre/venues/banquet-halls/walkthrough',
    metaKey: 'CEC-BANQUET-HALL-WALKTHROUGH-META-TAGS'
  },
  {
    name: 'cecMettingRooms',
    route: '/jio-world-convention-centre/venues/meeting-rooms',
    metaKey: 'CEC-MEETING-ROOMS-META-TAGS'
  },
  {
    name: 'cecMettingRoomsWalkthrough',
    route: '/jio-world-convention-centre/venues/meeting-rooms/walkthrough',
    metaKey: 'CEC-MEETING-ROOMS-WALKTHROUGH-META-TAGS'
  },
  {
    name: 'das',
    route: '/dhirubhai-ambani-square',
    metaKey: 'DAS-META-TAGS'
  },
  {
    name: 'logisticsServices',
    route: '/logistics-services',
    metaKey: 'LOGISTICS-SERVICES-META-TAGS'
  },
  {
    name: 'about',
    route: '/about',
    metaKey: 'JWC-ABOUT-META-TAGS'
  },
  {
    name: 'dasVisitSafely',
    route: '/dhirubhai-ambani-square/visit-safely',
    metaKey: 'DAS-VISIT-SAFELY-META-TAGS'
  },
  {
    name: 'faq',
    route: '/frequently-asked-questions',
    metaKey: 'JWC-FAQS-META-TAGS'
  },
  {
    name: 'cecDocumnetDownload',
    route: '/jio-world-convention-centre/documents-and-downloads',
    metaKey: 'CEC-DOCUMENTS-DOWNLOADS-META-TAGS'
  },
  {
    name: 'cecExhibitorOrgSevices',
    route: '/jio-world-convention-centre/exhibitor-organiser-services',
    metaKey: 'CEC-EXHIBITOR-ORGANISER-SERVICES-META-TAGS'
  },
  {
    name: 'jwg',
    route: '/jio-world-garden',
    metaKey: 'JWG-META-TAGS'
  },
  {
    name: 'jwgAbount',
    route: '/jio-world-garden/about',
    metaKey: 'JWG-ABOUT-META-TAGS'
  },
  {
    name: 'jwgEventArchive',
    route: '/jio-world-garden/events-archive',
    metaKey: 'JWG-EVENTS-ARCHIVE-META-TAGS'
  },
  {
    name: 'jwgFacilities',
    route: '/jio-world-garden/facilities',
    metaKey: 'JWG-FACILITIES-META-TAGS'
  },
  {
    name: 'jwgVisitSafely',
    route: '/jio-world-garden/visit-safely',
    metaKey: 'JWG-VISIT-SAFELY-META-TAGS'
  },
  {
    name: 'legal',
    route: '/legal',
    metaKey: 'JWC-LEGAL-META-TAGS'
  },
  {
    name: 'legalTabTerms',
    route: '/legal?tab=terms',
    metaKey: 'JWC-LEGAL-TAB-TERMS-META-TAGS'
  },
  {
    name: 'tcc',
    route: '/nita-mukesh-ambani-cultural-centre',
    metaKey: 'JWC-CULTURAL-CENTRE-META-TAGS'
  },{
    name: 'careers',
    route: '/careers',
    metaKey: 'JWC-CAREERS-META-TAGS'
  },{
    name: 'cecWeddings',
    route: '/jio-world-convention-centre/weddings',
    metaKey: 'CEC-WEDDINGS-META-TAGS'
  },
  {
    name: 'cecOrgYourEvent',
    route: '/jio-world-convention-centre/organise-your-next-event',
    metaKey: 'CEC-ORGANISE-EVENT-META-TAGS'
  },
  {
    name: 'cecMeetingPods',
    route: '/jio-world-convention-centre/venues/meeting-rooms/meeting-pods',
    metaKey: 'CEC-MEETING-ROOMS-MEETING-PODS-META-TAGS'
  },
  {
    name: 'cecWeddingConcierge',
    route: '/jio-world-convention-centre/weddings/Wedding-Concierge',
    metaKey: 'CEC-WEDDING-CONCIERGE-META-TAGS'
  }
  // {
  //   name: 'careers',
  //   route: '/careers',
  //   metaKey: 'JWG-VENUE-META-TAGS'
  // },{
  //   name: 'careers',
  //   route: '/careers',
  //   metaKey: 'JWG-DOWNLOADABLE-SPECS-META-TAGS'
  // }
]
