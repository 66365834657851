<section class="benefits_wrapper ">

  <div class="container">
    <div class="row align-items-center flex-row-reverse">
      <div class="col-lg-5 col-md-5">
        <div class="section_heading section_heading--title section_heading--text" [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor: ' + colorList.primaryColor) | safe: 'style'">
            <h2 [innerHTML]="benefitsData?.ComponentHeaderTitle?.data"></h2>
            <p [innerHTML]="benefitsData?.ComponentHeaderSubTitle?.data"></p>
        </div>
        <!-- <div data-aos="fade-up">
          <a href="{{benefitsData?.CTALink?.data}}" *ngIf="benefitsData?.HideCTAText?.data =='false'" class="cta-prim" [ngClass]="colorClass.get(colorList.primaryColor)"
           (click)="_sharedService.checkCTALink($event, benefitsData?.CTALink?.data, benefitsData?.CTALinkWindowPreference?.data, benefitsData?.TypeOfCTALink?.data)">
            <span class="cta-prim-txt">{{benefitsData?.CTAText?.data}}</span>
          </a>
        </div> -->
      </div>
      <div class="col-lg-7 col-md-7">
        <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#F5F2ED') | safe: 'style'">
          <div class="benefits_slider jwc_swiper_benefits" data-interval="4" data-aos="fade-up">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of benefitsData?.cards; let i = index;">
                  <div class="benefits_slider_each">
                    <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid" alt="slider{{i+1}}">
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>

  </div>

</section>