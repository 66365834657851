<div class="thankyou_component" [attr.style]="('--data-thankyou-bglayer:#F5F2ED;') | safe: 'style'">
  <div class="container">
    <div class=" col-lg-12">
      <a class="icon icon-xbtn">X</a>
      <p class="content3" *ngIf="subscriptionFlag; else unsubscriptionTemplate">Your subscription is confirmed! You will now receive exclusive updates from {{brandName}}.</p>
      <ng-template #unsubscriptionTemplate>
        You have been unsubscribed from our emails. We are sorry to see you go. You can opt back in at any point.
      </ng-template>
    </div>
  </div>
</div>