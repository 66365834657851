import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-legal-policy-test',
  templateUrl: './legal-policy-test.component.html',
  styleUrls: ['./legal-policy-test.component.css']
})
export class LegalPolicyTestComponent implements OnInit {

  legalPolicyColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor: "#976841"
  }

  tabName: any;
  routeState: any;
  subscription: any;
  legalPageData: any;
  isActive: boolean =false;

  constructor(private router: Router, public _sharedService: SharedService, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.getCurrentNavigation().extras.state) {
          this.routeState = this.router.getCurrentNavigation().extras.state;
          if (this.routeState) {
            this.tabName = this.routeState.tabName ? this.routeState.tabName : '';
            // console.log("tab: ", this.tabName);
          }
        } else if (this.route.snapshot.queryParams['tab']) {
          this.route.queryParams.subscribe((queryParams: any) => {
            let tab = this.route.snapshot.queryParams['tab'];
            this.tabName = (tab == 'terms') ? 'terms' : (tab == 'privacy') ? 'privacy' : (tab == 'rules') ? 'rules' : (tab == 'jwg-parking') ? 'jwg-parking' : 'terms';
          });
        } else {
          this.tabName = 'terms';
        }
      }
    })
  }

  ngOnInit() {
    this.getLegalPageContent();
  }

  
  getLegalPageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingData(185071).subscribe(
      (data: any) => {
        this.legalPageData = data ? data.data.cards : data;
        this._sharedService.initializeCustomJs();
        this._sharedService.callsidebarDrop();
        // console.log("this.legalPageData: ", this.legalPageData);
        
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

}
