<app-sub-header [colorList]="cecServiceColorList" [subHeaderList]="cecServiceSubHeaderList" [pageName]="'cec'"
  *ngIf="cecServiceSubHeaderList"></app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <div class="banner_small black_tint" [attr.style]="('--data-lineColor:#FFFFFF; --data-titleColor:#FFFFFF') | safe: 'style'">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-convention-centre">Jio World Convention
          Centre</a>
        / <strong>Exhibitor Services</strong>
      </div>
    </div>
    <picture *ngIf="cecServicebannerData">
      <source media="(min-width: 768px)"
        srcset="{{ staticUrlForImgNew + cecServicebannerData?.UploadImageVideo?.document?.contentUrl }}">
      <source media="(max-width: 767px)" *ngIf="cecServicebannerData?.UploadMobileKMAImageVideo?.document?.contentUrl"
        srcset="{{ staticUrlForImgNew + cecServicebannerData?.UploadMobileKMAImageVideo?.document?.contentUrl }}">
      <img src="{{ staticUrlForImgNew + cecServicebannerData?.UploadImageVideo?.document?.contentUrl }}"
        class="img-fluid" alt="{{cecServicebannerData?.ImageAltText?.data}}">
    </picture>
    <h1>{{cecServicebannerData?.Headline?.data}}</h1>
  </div>
  <app-covid2-section *ngIf="cecServicebannerData"></app-covid2-section>
  <div id="dining">
    <app-experiences-garden-slider *ngIf="cecServiceDiningData" [experiencesGardenData]="cecServiceDiningData"
      [colorList]="cecServiceColorList"></app-experiences-garden-slider>
  </div>
  <div id="technology">
    <app-experiences-garden-slider *ngIf="cecServiceTechnologyData" [experiencesGardenData]="cecServiceTechnologyData"
      [colorList]="cecServiceColorList"></app-experiences-garden-slider>
  </div>

  <section class="explorevenues_wrapper pb-0" id="signature-experiences">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text text-center" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
          <h2 [innerHTML]="cecServiceExperiencesData?.ComponentHeaderTitle?.data"></h2>
          <p [innerHTML]="cecServiceExperiencesData?.ComponentSubTitle?.data"></p>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="container">
      <div class="row explorevenues_wrapper_each" [attr.style]="('--data-explore-boxbgColor:#F5F2ED') | safe: 'style'" [ngClass]="{'mt-0': i==0}"
        *ngFor="let item of cecServiceExperiencesData?.cards; let i = index">
        <div class="col-lg-5 col-md-5 p-0">
          <div class="explorevenues_wrapper_each--img">
            <img src="{{ staticUrlForImgNew + item?.CardImage?.image?.contentUrl }}" class="img-fluid"
              alt="{{item?.ImageAltText?.data}}">
          </div>
        </div>
        <div class="col-lg-7 col-md-7">
          <div class="explorevenues_wrapper_each_copy">
            <h4>{{item?.CardHeader?.data}}</h4>
            <p class="explorevenues_wrapper_each_copy--text content3">{{item?.CardShortText?.data}}</p>
            <ul class="explorevenues_wrapper_each_list">
              <li *ngFor="let bullet of item?.AddBulletPoint">{{bullet?.BulletPoint?.data}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-get-in-touch *ngIf="cecServiceContactData" [getInTouchData]="cecServiceContactData"
    [colorList]="cecServiceColorList" [pageName]="'cec'"></app-get-in-touch>
</main>
<!-- <app-footer></app-footer> -->