import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NotificationService } from '../../../shared/services/notification.service';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  NotificationForm: FormGroup;
  public Editor;
  //public Editor = {};
  notification = null
  private subscription;
  checkBrowser: boolean = false;
  ckEditorLoaded = false;

  componentArr = [
    {
      type: 'DAS',
      value: 199325
    },
    {
      type: 'CEC',
      value: 261839
    }
  ]

  constructor(private fb: FormBuilder,
    private notificationService: NotificationService,
    public _sharedService: SharedService,
    @Inject(PLATFORM_ID) public platformId: string) {
    this.NotificationForm = this.fb.group({

      notificationDesc: [null, Validators.required,],
      componentId: [null,]

    });
    if(isPlatformBrowser(platformId)){
      this.checkBrowser = true;
    }
  }

  ngOnInit() {
    if(this.checkBrowser){
      console.log('########### log:');
      import('@ckeditor/ckeditor5-build-classic').then(i => {
        this.Editor = i.default;
        this.ckEditorLoaded = true;
      });
      //this.Editor = ClassicEditor;
      //this.loadCkEditor();
    }
    this.NotificationForm.patchValue({
      componentId: this.componentArr[0]?.value
    })
    this.getCurrentNotification(this.NotificationForm.value.componentId)
  }

  loadCkEditor() {
    const jsElmCK = document.createElement('script');
    jsElmCK.type = 'application/javascript';
    jsElmCK.src = 'https://cdn.ckeditor.com/ckeditor5/11.2.0/classic/ckeditor.js';
    document.body.appendChild(jsElmCK);
    jsElmCK.onload = () => {
      this.Editor = (window as any).ClassicEditor;
      this.ckEditorLoaded = true;
    };
  }

  getCurrentNotification(val: any) {
    let keyList = [val]
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
      (data: any) => {
        let currNotification = data[0]?.data['NotificationText']?.data
        this.NotificationForm.patchValue({
          notificationDesc: currNotification
        })
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  removeNotification() {
    setTimeout(() => {
      this.notification = null
    }, 3000);
  }

  onReady($event) {
    console.log($event);
  }

  activate() {
    let payload = {
      "siteType": "jwc",
      "notificationDesc": this.NotificationForm.value.notificationDesc,
      "componentId": this.NotificationForm.value.componentId,
      "isActivate": "true"
    }
    this.notificationService.Notification(payload).subscribe((data: any) => {

      this.notification = data?.data
      this.removeNotification()
    }, error => {
      this.notification = error?.error?.data;
      this.removeNotification()
    })
  }

  deactivate() {
    let payload = {
      "siteType": "jwc",
      "notificationDesc": this.NotificationForm.value.notificationDesc,
      "componentId": this.NotificationForm.value.componentId,
      "isActivate": "false"
    }
    this.notificationService.Notification(payload).subscribe((data: any) => {

      this.notification = data?.data
      this.removeNotification()
    }, error => {
      this.notification = error?.error?.data;
      this.removeNotification()
    })
  }


}
