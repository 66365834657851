import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-plan-big-event',
  templateUrl: './plan-big-event.component.html',
  styleUrls: ['./plan-big-event.component.css']
})
export class PlanBigEventComponent implements OnInit {
  @Input() venueDetailsEventsData;
  @Input() colorList;
  @Input() pageName;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  buttonColorClass: Map<string, string>;
  constructor(public _sharedService: SharedService, private titleService: Title) {
    this.buttonColorClass = new Map([
      ["home", "color-gold"],
      ["cec", "color-blue"],
      ["garden", "color-green"]
    ]);
  }

  ngOnInit() {
  }

  callPlanEventCTEvent(sectionTitle) {
    this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle);
  }

}
