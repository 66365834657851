<section class="highlights_wrapper plr-0 pattern"
    [attr.style]="('--data-highlights-bgColor1: ' + highlightsData?.ComponentBackgroundColor?.data + ';--data-highlights-bgColor2: ' + highlightsData?.ComponentBackgroundColor?.data) | safe: 'style'">
    <div class="container">
        <div class="section_heading section_heading--title text-center text-white"
            [attr.style]="('--data-lineColor:#FFFFFF; --data-titleColor:#FFFFFF') | safe: 'style'">
            <h2 [innerHTML]="highlightsData?.ComponentHeaderTitle?.data"></h2>
        </div>
    </div>
    <div data-aos="fade-up">
        <div class="container">
            <div class="news_slider jwc_swiper_normal" data-interval="8" data-space="24" data-slides="3"
                data-mob="false" data-ipad-slides="3" data-ipad-space="20" data-mobile-slides="1.15"
                data-mobile-space="20">
                <div class="swiper-container">
                    <!-- Add Arrows -->
                    <div class="swiper-arrows">
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of highlightsData.cards; let i = index">
                            <div class="highlights2_wrapper_each">
                                <div class="highlights2_wrapper_each--img">
                                    <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}"
                                        class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                                </div>
                                <!-- [ngClass]="borderBottom.get(i%3)" -->
                                <div class="highlights2_wrapper_each_copy">
                                    <!-- <span class="highlights_wrapper_each_copy--date">{{item?.Date?.data | date: 'MMM d / y'}}</span> -->
                                    <h4 class="highlights2_wrapper_each_copy--title">{{item?.CardHLText?.data}}</h4>
                                    <p class="highlights2_wrapper_each_copy--text">{{item?.CardShortText?.data}}</p>
                                    <!-- <div class="highlights2_wrapper_each_copy--link">
                                        <a *ngIf="item?.HideCTAText?.data =='false'" class="cta-sec color-gold" href="{{item?.CTALink?.data}}"
                                            (click)="_sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTALinkWindowPreference?.data, item?.TypeOfCTALink?.data)">
                                            <span class="cta-sec-txt">{{item?.CTAText?.data}}</span>
                                        </a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="highlights_wrapper--all" data-aos="fade-up">
                <a href="javascript:void(0)" *ngIf="highlightsData?.HideViewAllCTAText?.data=='false'" class="cta-sec on-bg"
                    (click)="_sharedService.checkCTALink($event, highlightsData?.ViewAllCTALink?.data, highlightsData?.ViewAllWindowPreference?.data, highlightsData?.ViewAllCTAText?.data)">
                    <span class="cta-sec-txt">{{highlightsData?.ViewAllCTAText?.data}}</span>
                </a>
            </div> -->
        </div>

    </div>
</section>