import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
declare function windowNotificationClose();

@Component({
  selector: 'app-covid19-page',
  templateUrl: './covid19-page.component.html',
  styleUrls: ['./covid19-page.component.css']
})
export class Covid19PageComponent implements OnInit {
  subscription;
  covidPageData: any;
  safetyBannerData;
  staySafeData;
  keyHighlightsData;
  kHCard1;
  kHCard2;
  kHCard3;
  kHCard4;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  keyList: any
  pageName: any;
  covidPageColorList = { dataExploreBoxBgColor: '#F5F2ED' };
  constructor(private _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.pageName = (this.activatedRoute.snapshot.data["page"]) ? (this.activatedRoute.snapshot.data["page"]) : '';
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getCovidPageKeys();
          setTimeout(() => {
            windowNotificationClose();
          }, 500)
      }
    });
   }

  ngOnInit() {
    this.getSafteyPageContent();
  }
  getCovidPageKeys() {
    switch (this.activatedRoute.snapshot.data["page"]) {
      case 'das':
      case 'new-das':
        // this.pageName = 'das';
        return this.keyList = [
          198327, // DAS Covid Safety - Banner
          198352, // DAS Covid Safety - Stay Safe Together
          198376, // Key Highlights
          198397, // DAS Covid Safety - Minimising Physical Contact
          198421, // DAS Covid Safety - Safety and Sanitisation
          198469, // DAS Covid Safety - Emergency Medical Assistance
          198445 // DAS Covid Safety - Workforce Safety
        ];
      default:
        // this.pageName = '';
        return this.keyList = [
          'SAFETY-BANNER', // Hero Banner
          'SAFETY-STAY-SAFE-TOGETHER', // Stay safe
          'SAFETY-KEY-HIGHLIGHTS', // Key Highlights
          'SAFETY-CARD-COMMON-COMPONENT-ONE', // card 1
          'SAFETY-CARD-COMMON-COMPONENT-TWO', // card 2
          'SAFETY-CARD-COMMON-COMPONENT-THREE', // card 3
          'SAFETY-CARD-COMMON-COMPONENT-FOUR' // card 4
        ];
    }
  }
  getSafteyPageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.keyList).subscribe(
      (data: any) => {
        this.safetyBannerData = data[0] ? data[0].data : data[0];
        this.staySafeData = data[1] ? data[1].data : data[1];
        this.keyHighlightsData = data[2] ? data[2].data : data[2];
        this.kHCard1 = data[3] ? data[3].data : data[3];
        this.kHCard2 = data[4] ? data[4].data : data[4];
        this.kHCard3 = data[5] ? data[5].data : data[5];
        this.kHCard4 = data[6] ? data[6].data : data[6];
        this._sharedService.initializeCustomJs();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
