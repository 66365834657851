import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-das-notifications',
  templateUrl: './das-notifications.component.html',
  styleUrls: ['./das-notifications.component.css']
})
export class DasNotificationsComponent implements OnInit {
  notificationSignInForm:FormGroup=this.createNotificationSignInForm();
  errorText = 'Field is required';
  showLoginErr = '';
  isCaptchaSelected;
  submitted = false;
  captchaSiteKey2;
  grcToken =  "XXXXXXXXXXX";
 
   constructor(private router:Router,private fb: FormBuilder, private notificationService:NotificationService) { }

  ngOnInit(): void {
    this.captchaSiteKey2 = environment.recaptchaSiteKey;
    //this.createNotificationSignInForm();
  }
  createNotificationSignInForm():FormGroup {
    return this.fb.group({
      userName: ['', [Validators.required]],
      password: ['', [ Validators.required]],
      
    })
  }
  get notificationSignInFormControls() { return this.notificationSignInForm.controls; }
  captchaRes2($event, flag?) {
    if(!$event) return this.isCaptchaSelected = false;
    if(flag) return console.log('Something went long when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true
    this.grcToken = $event;
  }
  notificationSubmit(form) {
  this.submitted = true;
    
    if (form.invalid || !this.isCaptchaSelected) {
      return;
    }
    let payload = {...form.value,siteType:1,grcToken : this.grcToken};
    this.notificationService.notificationLogin(payload).subscribe(
      (data: any) => {if(data?.data=="Success"){this.router.navigate(['/notification']);} },
      (error:any)=>{this.showLoginErr=error},
    )}
}
