<!-- <app-header [colorList]="cecWalkthroughColorList" [pageName]="'cec'"></app-header> -->
<app-sub-header [colorList]="cecWalkthroughColorList" [subHeaderList]="cecWalkthroughHeaderList" [pageName]="'cec'">
</app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <!-- <div class="jwc_360tour screen_height_inside" *ngIf="walkthroughBannerData">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-convention-centre">Jio World Convention
          Centre</a> / <a routerLink="/jio-world-convention-centre/venues">Venues</a> / <a
          routerLink="/jio-world-convention-centre/venues/{{urlForHall}}">{{breadcrumbName}}</a> / <strong>Walkthrough</strong>
      </div>
    </div>
    <div class="jwc_360tour--banner">
      <picture>
        <source media="(min-width: 768px)" srcset="{{ staticUrlForImgNew + walkthroughBannerData?.UploadBackgroundImageVideo?.document?.contentUrl }}">
        <source media="(max-width: 767px)" *ngIf="walkthroughBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl"
            srcset="{{ staticUrlForImgNew + walkthroughBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl }}">
        <img src="{{ staticUrlForImgNew + walkthroughBannerData?.UploadBackgroundImageVideo?.document?.contentUrl }}" class="img-fluid kma_picture_wrapper--img" alt="Banner">
      </picture>
    </div>
    <div class="jwc_360tour_btn">
      <div class="jwc_360tour_btn--icon">
        <img src="./assets/images/360/360.svg" class="img-fluid" alt="">
      </div>
      <button class="cta-prim color-white emp-btn"><span class="cta-prim-txt">START TOUR</span></button>
    </div>
  </div> -->
  <app-banner [landingBannerData]="walkthroughBannerData" [sectionClass]="'screen_height_inside'" [bannerPage]="'cec'"
    *ngIf="walkthroughBannerData">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-convention-centre">Jio World Convention
          Centre</a> / <a routerLink="/jio-world-convention-centre/venues">Venues</a> / <a
          routerLink="/jio-world-convention-centre/venues/{{urlForHall}}">{{breadcrumbName}}</a> /
        <strong>Walkthrough</strong>
      </div>
    </div>
  </app-banner>
  <section [attr.data-mobile-header]="breadcrumbName" class="" [id]="exploreHallsId" *ngIf="walkthroughPavilionData">
    <div class="container">
      <div class="row" data-aos="fade-up">
        <div class="col-lg-12">
          <div class="section_heading fullwidth section_heading--title section_heading--text"
             [attr.style]="('--data-lineColor:#004EA8') | safe: 'style'">
            <h1 class="custom-seo-heading" [innerHTML]="walkthroughPavilionData?.ComponentHeaderTitle?.data"></h1>
            <p [innerHTML]="walkthroughPavilionData?.ComponentHeaderSubTitle?.data"> </p>
          </div>
          <div class="jwc_multibtns">
            <!-- <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/inquiry')" class="cta-prim color-blue"><span class="cta-prim-txt">ENQUIRE NOW</span></a>
            <a (click)="_sharedService.checkCTALink($event, './assets/pdfs/dummy_pdf.pdf', '_self', 'PDF', 'Pavilion Tour')" class="cta-sec icon rh icon-download-icon color-blue"><span class="cta-prim-txt">Download
                PDF</span></a> -->
            <a href="{{walkthroughPavilionData?.CTALinkForEnquireNow?.data}}"
              *ngIf="walkthroughPavilionData?.HideCTATextForEnquireNow1?.data =='false'" class="cta-prim color-blue"
              (click)="callCecWlkthruExploreSectionCTEvent('pri', walkthroughPavilionData?.ComponentHeaderTitle?.data);_sharedService.checkCTALink($event, walkthroughPavilionData?.CTALinkForEnquireNow?.data, walkthroughPavilionData?.CTALinkWindowPreferenceForEnquireNow?.data, walkthroughPavilionData?.TypeOfCTALinkForEnquireNow1?.data)">
              <span class="cta-prim-txt">{{walkthroughPavilionData?.CTATextForEnquireNow?.data}}</span></a>

            <a href="{{walkthroughPavilionData?.CTALinkForDownload?.data}}"
              *ngIf="walkthroughPavilionData?.HideCTATextForDownload?.data =='false'"
              class="cta-sec icon rh icon-download-icon color-blue"
              (click)="callCecWlkthruExploreSectionCTEvent('download', walkthroughPavilionData?.ComponentHeaderTitle?.data);_sharedService.checkCTALink($event, walkthroughPavilionData?.CTALinkForDownload?.data, '_self', 'PDF', pdfName)">
              <span class="cta-prim-txt">{{walkthroughPavilionData?.CTATextForDownload?.data}}</span></a>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section data-aos="fade-up" class="pt-0" *ngIf="walkthroughInfographicsData">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="jwc_icons_text">
            <div class="jwc_icons_text_each" *ngFor="let item of walkthroughInfographicsData?.cards">
              <div class="jwc_icons_text_each--icon">
                <img src="{{staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl}}" class="img-fluid"
                  alt="{{item?.ImageAltText?.data}}">
              </div>
              <div class="jwc_icons_text_each_copy">
                <h4 class="jwc_icons_text_each_copy--title">{{item?.InfographicTitle?.data}}</h4>
                <p class="jwc_icons_text_each_copy--text content2">{{item?.InfographicSubTitle?.data}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="walkthroughSliderData">
    <div class="jwc_image_slider jwc_swiper_normal"
      [ngClass]="{'single_image': walkthroughSliderData?.cards?.length == 1}" data-aos="fade-up" data-interval="0"
      data-space="120" data-slides="1" data-ipad-slides="1" data-ipad-space="10" data-mobile-slides="1"
      data-mobile-space="10">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of walkthroughSliderData?.cards">
            <div class="jwc_image_slider_each">
              <img *ngIf="item?.BackgroundImageVideo?.data=='image'"
                src="{{ staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
              <!-- <div *ngIf="item?.BackgroundImageVideo?.data=='video'" class="col-md-12 video">
                <div class="video_wrapper">
                  <video controls="" controlslist="nodownload" class="video_wrapper--video">
                    <source src="{{ staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl }}"
                      type="video/mp4">
                    Your browser does not support HTML5 video.
                  </video>
                </div>
                <div class="img_box">
                  <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
                    class="img-fluid" alt="">
                  <a href="javascript:;" class="play_href default"><img src="./assets/images/playbutton.svg"
                      class="img-fluid play_btn" alt="play button"></a>
                </div>
              </div> -->
              <div *ngIf="item?.BackgroundImageVideo?.data=='video'">
                <a href="javascript:;" class="video watchVideoClick" data-target="#video_box"
                [attr.data-url]="staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl"
                data-toggle="modal">
                <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
                  class="img-fluid" alt="{{item?.PlaceholderImageForVideoUploaded?.image?.title}}">
                <div class="play_href white">
                  <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
                </div>
              </a>
              </div>
              <ng-container *ngIf="item?.BackgroundImageVideo?.data=='360 video' || item?.BackgroundImageVideo?.data=='360 image'">
                <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}" class="img-fluid" alt="">
                <a *ngIf="item?.BackgroundImageVideo?.data=='360 video'" href="javascript:;" class="jwc_360tour_btn play360" [attr.videosrc]="staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl">
                  <div class="jwc_360tour_btn--icon mb-0">
                    <img src="./assets/images/360/360.svg" class="img-fluid" alt="">
                  </div>
                </a>
                <a *ngIf="item?.BackgroundImageVideo?.data=='360 image'" href="javascript:;" class="jwc_360tour_btn play360" [attr.imgsrc]="staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl">
                  <div class="jwc_360tour_btn--icon mb-0">
                    <img src="./assets/images/360/360.svg" class="img-fluid" alt="">
                  </div>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Arrows -->
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </div>

  <section data-mobile-header="Event Services" id="event-services" class="jwc_hightlights_icons" data-aos="fade-up"
    *ngIf="walkthroughHighlightsData">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section_heading section_heading--title section_heading--text"
            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
            <h2 [innerHTML]="walkthroughHighlightsData?.ComponentHeaderTitle?.data"></h2>
            <p [innerHTML]="walkthroughHighlightsData?.ComponentHeaderSubTitle?.data"></p>
          </div>
          <div class="jwc_hightlights_icons_list">
            <div class="jwc_hightlights_icons_list_each" *ngFor="let item of walkthroughHighlightsData?.cards">
              <div class="jwc_hightlights_icons_list_each--img">
                <img src="{{ staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
              </div>
              <div class="jwc_hightlights_icons_list_each--title content3">{{item?.InfographicTitle?.data}}</div>
            </div>
          </div>
          <div class="jwc_hightlights_icons--btn text-center">
            <a *ngIf="walkthroughHighlightsData?.HideCTAText?.data =='false'"
              href="{{walkthroughHighlightsData?.CTALink?.data}}"
              (click)="_sharedService.checkCTALink($event, walkthroughHighlightsData?.CTALink?.data, walkthroughHighlightsData?.CTALinkWindowPreference?.data, walkthroughHighlightsData?.TypeOfCTALink?.data)"
              class="cta-prim color-blue "><span
                class="cta-prim-txt">{{walkthroughHighlightsData?.CTAText?.data}}</span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div data-mobile-header="More Venues" [id]="exploreVenuesId">
    <section class="jwc_exploremorevenues plr-0"
      [attr.style]="('--data-explorevenues-bgColor: ' + walkthroughExploreData?.ComponentBackgroundColor?.data ) | safe: 'style'"
      data-aos="fade-up" *ngIf="walkthroughExploreData">
      <div class="container">
        <div class="section_heading section_heading--title section_heading--text"
       [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
          <h2 [innerHTML]="walkthroughExploreData?.ComponentHeaderTitle?.data"></h2>
          <p [innerHTML]="walkthroughExploreData?.ComponentHeaderSubTitle?.data"></p>
        </div>
        <div class="news_slider jwc_swiper_normal4 jwc_exploremorevenues_list" data-space="25" data-interval="5"
          data-slides="3" data-ipad-slides="3.2" data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let item of walkthroughExploreData?.cards">
                <div class="jwc_exploremorevenues_list_each">
                  <div class="jwc_exploremorevenues_list_each--img">
                    <img src="{{ staticUrlForImgNew + item?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                  </div>
                  <div class="jwc_exploremorevenues_list_each_copy">
                    <h5 class="jwc_exploremorevenues_list_each_copy--title">{{item?.CardHLText?.data}}</h5>
                    <p class="jwc_exploremorevenues_list_each_copy--text content3">{{item?.CardShortText?.data}}</p>
                    <div class="jwc_exploremorevenues_list_each_copy--btn">
                      <a href="{{item?.CTALink?.data}}" *ngIf="item?.HideCTAText?.data =='false'"
                        class="cta-prim color-blue"
                        (click)="callCecWlkthruExploreSectionCTEvent('pri', item?.CardHLText?.data);_sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTALinkWindowPreference?.data, item?.TypeOfCTALink?.data)">
                        <span class="cta-prim-txt">{{item?.CTAText?.data}}</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div data-mobile-header="Contact" [id]="planEveId">
    <app-get-in-touch *ngIf="walkthroughAskUsData" [getInTouchData]="walkthroughAskUsData"
      [colorList]="cecWalkthroughColorList" [pageName]="'cec'"></app-get-in-touch>
  </div>
</main>
<!-- <app-footer [colorList]="cecWalkthroughColorList"></app-footer> -->
<app-video-popup></app-video-popup>
<div class="overlay"></div>