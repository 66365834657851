import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-event-services',
  templateUrl: './event-services.component.html',
  styleUrls: ['./event-services.component.css']
})
export class EventServicesComponent implements OnInit {
  @Input() venueDetailsHighlightsInfoData;
  @Input() colorList;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService, private titleService: Title) { }

  ngOnInit() {
  }
  callEventServicesCTEvent() {
    this._sharedService.callCTEvent('Section_Primary_Links', this.venueDetailsHighlightsInfoData.ComponentHeaderTitle.data);
  }

}
