<div class="loader">
    <div class="loader_spin"></div>
</div>

<app-back-to-wrapper></app-back-to-wrapper>

<!-- <app-sub-header [colorList]="cecVenueDetailsColorList" [subHeaderList]="cecSubHeaderList" [pageName]="'cec'"
    *ngIf="cecSubHeaderList"></app-sub-header> -->

<div class="small-meeting-room-class">

    <!-- HERO BANNER -->
    <app-banner-new *ngIf="bannerData?.HideComponentOnSite?.data === 'false'"
        [newBannerData]="bannerData"></app-banner-new>


    <!-- MEETING PODS -->
    <section class="meeting_pod_wrapper">
        <app-benefits-wrapper id="meeting-pods" *ngIf="quotesData?.HideComponentOnSite?.data === 'false'"
            [benefitsWrapperData]="quotesData" [sectionClass]="'py-0'"
            [colorList]="cecVenueDetailsColorList"></app-benefits-wrapper>

        <div class="area_wrapper">
            <div class="area_subwrapper" *ngFor="let item of infographicsData?.cards">
                <img class="area_icon" [src]="staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl"
                    [alt]="item?.ImageAltText?.data" />
                <div class="area_details">
                    <p class="area_title">{{item?.InfographicTitle?.data}}</p>
                    <p class="area_subtitle">{{item?.InfographicSubTitle?.data}}</p>
                </div>
            </div>
        </div>
    </section>

    <!-- DURATION AND COMMERCIAL -->
    <section class="duration_wrapper" id="packages" *ngIf="packageData?.HideComponentOnSite?.data === 'false'">
        <div class="duration_header">
            <div class="line"></div>
            <p class="duration_title">{{packageData?.ComponentHeaderTitle?.data}}</p>
        </div>
        <div class="duration_image_wrapper">
            <img [src]="staticUrlForImgNew + packageData?.UploadImage?.document?.contentUrl"
                [alt]="packageData?.ImageAltText?.data" class="duration_image" />

            <div class="duration_details_wrapper">
                <div class="duration_details" *ngFor="let item of packageData?.cards">
                    <p class="duration_detail_title">{{item?.CardTitle?.data}}</p>
                    <div class="taxes">
                        <p class="tax_lable" *ngFor="let detail of item?.AddSubCardDetails">
                            {{detail.SubCardDetails?.data}}</p>
                    </div>
                    <a [href]="item?.CTALink?.data" *ngIf="item?.HideCTAText?.data === 'false'"
                        (click)="_sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTALinkWindowPreference?.data)">
                        <button class="main_button other_btn">
                            {{item?.CTAText?.data}}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </section>

    <!-- INCLUSION -->
    <section class="inclusion_wrapper" id="packages-include"
        *ngIf="packageIncludeData?.HideComponentOnSite?.data === 'false'">
        <div class="inclusion_subwrapper">
            <div class="pod_image_wrapper inclusion_image_wrapper">
                <img [src]="staticUrlForImgNew + packageIncludeData?.CardImage?.document?.contentUrl"
                    [alt]="[packageIncludeData?.ImageAltText?.data]" class="inclusion_image" />
                <div class="inclusion_img_border"></div>
            </div>
            <div class="inclusion_details">
                <div class="inc_header_wrapper">
                    <div class="line"></div>
                    <p class="inclusion_title">{{packageIncludeData?.ComponentHeaderTitle?.data}}</p>
                </div>

                <p class="list_wrapper" [innerHTML]="packageIncludeData?.ComponentHeaderSubTitle?.data"></p>
            </div>
        </div>
    </section>

    <!-- ASK US ANYTHING -->
    <app-get-in-touch id="ask-us-anything" [getInTouchData]="bookMeetingData" [colorList]="cecVenueDetailsColorList"
        [pageName]="'cec'" *ngIf="bookMeetingData?.HideComponentOnSite?.data === 'false'">
    </app-get-in-touch>

</div>