// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env:"dev",
  getComponentUrl: "https://jwcdv.ril.com/fetchinforedis/",
  staticUrlForImgNew: "https://jwcdv.ril.com",
  getSubscriptionUrl: "http://jwcdv.ril.com/fetchinfo/",
  getOtpUrl: "http://jwcdv.ril.com/userprofile/",
  instaFeedUrl: "https://www.instagram.com/p/",
  exhibitorLogin: "http://scmpfdev.ril.com/eventexhibitor/login",
  recaptchaSiteKey: "6LcBTMseAAAAAGU0I2ohJ2ER4U4UJ_yRFQ2N_P_S",
  tccUrl: "http://tccdev.ril.com",
  checkEnv: "TS-NA",
  certificateURL : [
  "https://drive.google.com/file/d/18z7Wxj8iWtqcR1oTxV17AQ7W4c3HUpVg/view?usp=sharing",
  "https://drive.google.com/file/d/1mcF_kZkP1U_THyI7DoekMobLqmK_wokl/view?usp=sharing",
  "https://drive.google.com/file/d/1pDiu_rtM1TW7E4FsgMSmXZseNxzYOXZK/view?usp=sharing",
  "https://drive.google.com/file/d/1FsN9I_HUoJsSMrHrM8EBX3cvGDioUuRb/view?usp=sharin",
  "https://drive.google.com/file/d/15IehBf43XnoeRMiuum-Ws4K-l6oMqWf2/view?usp=sharing",
  "https://drive.google.com/file/d/1grlhHt0QqQ5C01ngm-VpXE_YjQzTYmSZ/view?usp=sharing"]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
