import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-benefits-wrapper',
  templateUrl: './benefits-wrapper.component.html',
  styleUrls: ['./benefits-wrapper.component.css']
})
export class BenefitsWrapperComponent implements OnInit {
  @Input() benefitsWrapperData;
  @Input() colorList;
  @Input() sectionClass?;
  @Input() rowReverseClass?;
  staticUrlForImgNew = environment.staticUrlForImgNew;
  constructor() { }

  ngOnInit(): void {
  }

}
