import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-experiences-section',
  templateUrl: './experiences-section.component.html',
  styleUrls: ['./experiences-section.component.css']
})
export class ExperiencesSectionComponent implements OnInit {
  @Input() experiencesData;
  @Input() colorList;
  @Input() isNMACC?: boolean;
  @Input() experiencesSwiperData;
  @Input() sectionClass?;
  @Input() multipleCalls?: boolean;
  currentUrl;
  staticUrlForImgNew: string = environment.staticUrlForImgNew; 

  todayDate = new Date().toLocaleDateString();
  todayTime = new Date().toLocaleTimeString();
  isPublished: boolean = true;
  constructor(public _sharedService: SharedService, private router: Router, private titleService: Title) { }

  ngOnInit() {
    if(!this.multipleCalls) this._sharedService.callFractionSlider();
    this.currentUrl = this.router.url;
  }

  getExperiencesDetails() { 

    //       //Getting Published and Expiry date 
    //       let exPublishedDate = new Date((exCardSchedule[0].nestedContentFields[0].value.data).split('T')[0]).toLocaleDateString();
    //       let exExpiryDate = new Date((exCardSchedule[0].nestedContentFields[2].value.data).split('T')[0]).toLocaleDateString();

    //       //Getting Published and Expiry Time
    //       let exPublishedTime = JSON.parse(exCardSchedule[0].nestedContentFields[1].value.data)[0];
    //       let exExpiryTime = JSON.parse(exCardSchedule[0].nestedContentFields[3].value.data)[0];

    //       //Getting Published slides
    //       if ((exPublishedDate <= this.todayDate && exPublishedTime < this.todayTime) && (exExpiryDate >= this.todayDate && exExpiryTime < this.todayTime)) {
    //         this.isPublished = true;
    //       } else if ((exPublishedDate > this.todayDate) || (exPublishedDate < this.todayDate && exExpiryDate < this.todayDate)) {
    //         this.isPublished = false;
    //       }
    //       // if (this.isPublished) {
    //         this.experiencesCardContent.push({
    //           experiencesCardImgSrc: environment.staticUrlForImg + exImgList[0].value.image.contentUrl,
    //           experiencesCardTitle: exTitleList[0].value.data,
    //           experiencesCardSubTitle: exSubTitleList[0].value.data,
    //           experienceCtaText: exCtaTextList[0].nestedContentFields[0].value.data,
    //           experienceCtaInternalLink: exCtaTextList[0].nestedContentFields[1].value.link,
    //           experienceCtaExternalLink: exCtaTextList[0].nestedContentFields[2].value.data,
    //           experienceCtaHidden: exCtaTextList[0].nestedContentFields[3].value.data,
    //           experienceHrefTarget: JSON.parse(exCtaTextList[0].nestedContentFields[4].value.data)
    //         });
    //       // }
  }
  callCtaSecondaryLinkEvent() {
    this._sharedService.callCTEvent('Section_Secondary_Links', this.experiencesData.ComponentHeaderTitle.data);
  }

}
