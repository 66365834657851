import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-chatbot',
  templateUrl: './campaign-chatbot.component.html',
  styleUrls: ['./campaign-chatbot.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignChatbotComponent implements OnInit {
  chatbotScript;
  chatbotLaunchMsgScript;
  clientId: string;
  baseUrl: string;
  constructor() {  }

  ngOnInit(){
    this.envSpecificSettings();
  }
  envSpecificSettings(){
    if((environment.env == "prod")){
      this.clientId = "8d62be0ef0f36e6da34a7be116addbd6aa6001c9";
      this.baseUrl = "https://jio-api.haptikapi.com/";
    } else {
      this.clientId = "1ecc51269f36ecb0f15542bcbfd9e93712b8267b";
      this.baseUrl = "https://jio-api.hellohaptik.com/";
    }
  }
  loadChatbotFunction() {
    let chatbotSetting = document.createElement('script');
    chatbotSetting.id= 'haptikchatbotSetting';
    chatbotSetting.text = `
        window.haptikInitSettings = {
          // 'mode': 'fsm',          
          'ignore-storage': true,
          'custom-button': true,
          'widget-positioning': 'center',
          "business-id": "1143",
          'business-via-name': 'jioworlcentrebuisnesschannel',
          "client-id": "${this.clientId}",
          "base-url": "${this.baseUrl}",
          "bot-prompt": "{ 'timeout': 0, 'message': '', 'option': '' }"
      }
      `;
    this.chatbotScript = document.createElement('script');
    this.chatbotScript.src = "https://toolassets.haptikapi.com/platform/javascript-xdk/production/loader.js";
    this.chatbotScript.type = 'text/javascript';
    this.chatbotScript.id= 'haptikchatbotScript';
    this.chatbotScript.async = false;
    this.chatbotLaunchMsgScript = document.createElement('script');
    this.chatbotLaunchMsgScript.text = `
    var dragged = '';
    document.addEventListener('haptik_sdk', function() {
      HaptikSDK.launchMessage('Launch Conversation Message', ()=>{}, true);
      document.getElementById('hap6').style.display = 'block';
      document.getElementById('hap6').addEventListener
      ('click', function(e) {
        if (dragged == ''){
          HaptikSDK.expandWidget();
          // if (this.getAttribute('data-launced')) {
          //   HaptikSDK.expandWidget();
          // }
          if(!this.getAttribute('data-launced')) {
            this.setAttribute('data-launced', 'true');
            // HaptikSDK.launchMessage('Launch Conversation Message', ()=>{}, true);
            // HaptikSDK.expandWidget();
          }
        }
        else {
          dragged = '';
        }
      });
    });`

    let ishaptikchatbotSetting = document.getElementById('haptikchatbotSetting');
    if (ishaptikchatbotSetting !== null) {
      return; //script already exists in head!
    } else { 
      document.getElementsByTagName('body')[0].appendChild(chatbotSetting); 
    }
    document.getElementsByTagName('body')[0].appendChild(this.chatbotScript);
    document.getElementsByTagName('body')[0].appendChild(this.chatbotLaunchMsgScript);
  }
}
