import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() colorList;
  @Input() islandingPage;
  exhibitorLogin: string = environment.exhibitorLogin;
  currentYear: number;
  subscription: any;
  articleData: any;
  encryptedId: string;
  showExhibitorLogin:boolean = true;
  tccUrl = environment.tccUrl;
  constructor(public _sharedService: SharedService, private router: Router, private titleService: Title) {
    this.currentYear = new Date().getFullYear();
   }

  ngOnInit() {
    // this.showExhibitorLogin = (environment.env == 'prod')? false : true;
    this.getArticleContent();
  }

  getArticleContent() {
    this.subscription = this._sharedService.getCommonService().getSingleNewsArticlesData(2).subscribe(
      (data: any) => {
        this.articleData = data ? data : [];
        if(data) this.encryptedId = btoa(this.articleData.newsId)
        this._sharedService.initializeCustomJs();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  // sendRouteObject(name) {
  //   this.router.navigate(['/legal'], {
  //     state: { tabName: name }
  //   });
  //   return false; // necessary to ignore href value
  // }

  callCTFooterEvent(link?) {
    link ? this._sharedService.callCTEvent('Footer_Primary_Links', '', '', link) : this._sharedService.callCTEvent('Footer_Primary_Links');
  }

}
