<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <section *ngIf="articleData?.desktopBannerImage != '' && articleData?.mobileBannerImage!=''" class="kma_medium  mobileExtraSpace"
    [attr.style]="('--data-techspecs-textColor:#fff;--data-kma-medium-desktop-img:url(' + staticUrlForImgNew + articleData?.desktopBannerImage + '); --data-kma-medium-mobile-img:url(' + staticUrlForImgNew + articleData?.mobileBannerImage + ')') | safe: 'style'">
    <!-- <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a *ngIf="pageName ==='new-das'; else otherLink" routerLink="/das">Dhirubhai Ambani Square</a>
        <ng-template #otherLink><a routerLink="/">Jio World Centre</a></ng-template> / <strong>Article</strong>
      </div>
    </div> -->
  </section>
  <div role="main" [innerHtml]="(articleData?.newsDetailTemplateHtml)  | safe: 'html'">
  </div>
</main>
<!-- <div id="player360">
  <div id="content360"></div>
  <div id="close360"></div>
</div> -->