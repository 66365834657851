import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CookieService {

  constructor() {}

  setPreferences(preferences: any) {
    const expires = this.getCookieExpiryDate(14); // Set expiry for 14 day
    document.cookie = `cookiePreferences=${JSON.stringify(preferences)}; expires=${expires}; path=/`;
    this.loadScripts(preferences);
  }

  private getCookieExpiryDate(days: number): string {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    return date.toUTCString();
  }

  getPreferences() {
    const match = document.cookie.match(new RegExp('(^| )cookiePreferences=([^;]+)'));
    return match ? JSON.parse(match[2]) : null;
  }

  loadScripts(preferences: any) {
    let isProd = environment.env === 'prod' ? true : false;

    if (preferences?.statistics) {
        // this.addGoogleAnalyticsScript('G-PR0HBY54J6');
        // this.addGtmScript('GTM-WGW5XGF');
        if (isProd) this.addMicrosoftClarity('7j14vk6oqb');
    }
    if (preferences?.marketing) {
        this.addFacebookPixelScript('198052794735735');
        this.addCleverTapScript(isProd ? 'WWW-R95-895Z' : 'TEST-ZWW-R95-895Z');
        this.addLinkedInInsightTagScript('4041204');
    }
}

  addGoogleAnalyticsScript(gaId: string): void {
    // Append the Google Analytics async script tag
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    document.head.appendChild(script1);

    // Append the second script for Google Analytics configuration
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${gaId}');
    `;
    document.head.appendChild(script2);
  }

  addFacebookPixelScript(fbId: string): void {
    // Append the <script> tag
    const script = document.createElement('script');
    script.innerHTML = `
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${fbId}');
    `;
    document.head.appendChild(script);

    // Append the <noscript> tag
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<img height="1" width="1" src="https://www.facebook.com/tr?id=${fbId}&ev=PageView&noscript=1" />`;
    document.head.appendChild(noscript);
  }

  addCleverTapScript(ctId: string): void {
    // Create and initialize the CleverTap object
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.innerHTML = `
      var clevertap = { event: [], profile: [], region: 'in1', account: [], onUserLogin: [], notifications: [], privacy: [] };
      function callCleverTap(ctId) {
        clevertap.account.push({ "id": ctId });
        clevertap.privacy.push({ optOut: false });
        clevertap.privacy.push({ useIP: false });

        var wzrk = document.createElement('script');
        wzrk.type = 'text/javascript';
        wzrk.async = true;
        wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(wzrk, s);
      }
    `;
    document.head.appendChild(script1);

    // Call the CleverTap initialization function with the provided ctId
    const script2 = document.createElement('script');
    script2.innerHTML = `callCleverTap('${ctId}');`;
    document.head.appendChild(script2);
  }

  addGtmScript(gtmId: string): void {
    // Create and append the GTM script tag dynamically
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
        j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

     // Create the noscript tag with the GTM iframe
     const noscript = document.createElement('noscript');
     noscript.innerHTML = `
       <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
         height="0" width="0" style="display:none;visibility:hidden" 
         title="Google Tag Manager"></iframe>
     `;
     document.body.appendChild(noscript);

  }

  addLinkedInInsightTagScript(partnerId: string): void {
    const head = document.head;

    // Create and append the first script for initializing the partner ID
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.innerHTML = `
      _linkedin_partner_id = "${partnerId}";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;
    head.appendChild(script1);

    // Create and append the second script to load the LinkedIn Insight tag
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.innerHTML = `
      (function(l) {
        if (!l) { window.lintrk = function(a,b) { window.lintrk.q.push([a,b]) }; }
        window.lintrk.q = [];
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript"; b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `;
    head.appendChild(script2);

    // Create and append the noscript tag for users without JavaScript enabled
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none;" alt="" 
      src="https://px.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif" />
    `;
    head.appendChild(noscript);
  }

  addMicrosoftClarity(Id: string): void {
    const script = document.createElement('script');
    script.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${Id}");`;
    document.head.appendChild(script);
  }

}
