import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../../../../shared/services/shared.service';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cec-wedding-details',
  templateUrl: './cec-wedding-details.component.html',
  styleUrls: ['./cec-wedding-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CecWeddingDetailsComponent implements OnInit {
  subscription;
  slugUrl;
  currentEvent;
  staticUrlForImgNew = environment.staticUrlForImgNew
  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.slugUrl = this.activatedRoute?.snapshot?.paramMap?.get('slug')
  }

  ngOnInit(): void {
    this.getPageContent();
  }

  private getPageContent(): void {
    let key = [
      // 'CEC-WEDDINGS-CARDS'
      "CEC-WEDDINGS-DETAILS"
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(key).subscribe(
      (data: any) => {
        this.getcurrentEvent(data[0].data)
        this._sharedService.initializeCustomJs();
        this._sharedService.callNormalSlider();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  private getcurrentEvent(array): void {
    const currentEvent = array?.cards?.find(ele => ele?.SlugURL?.data === this.slugUrl);

    if (!currentEvent) {
      this.router.navigate(['**']);
      return;
    }

    const banner = [];
    const gallery = [];
    const keyOfferings = [];

    currentEvent?.AddBannerSlider?.forEach(element => {
      const section = element?.BannerSection?.data || element?.KeyOfferingsSection?.data;
      switch (section) {
        case '["KMA"]':
          banner.push(element);
          break;
        case '["Sliding Banner"]':
          gallery.push(element);
          break;
        case '["Key Offerings"]':
          keyOfferings.push(element);
          break;
      }
    });

    this.currentEvent = {
      ...currentEvent,
      banner,
      gallery,
      keyOfferings
    };
  }

}
