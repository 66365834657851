import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-cec-event',
  templateUrl: './cec-event.component.html',
  styleUrls: ['./cec-event.component.css']
})
export class CecEventComponent implements OnInit, OnDestroy {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  cecSubHeaderList = []
  cecColorList = { newsDataTitleColor: "#4B4847", newsDataLineColor: "#004EA8", newsDateColor: "#004EA8", newsCopyColor: "#FFFFFF", newsViewAllLineColor: "#004EA8", primaryColor: '#1E478E', experiencesDataLineColor: '#1E478E', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', subscribeDataBgColor: "#FFFFFF", subscribeDataTitleColor: "#4B4847", subscribeDataLineColor: "#004EA8", subscribeDotColor: "#004EA8", subscribeLineColor: "#004EA8", subscribeTextColor: "#4B4847", momentsIconColor: "#004EA8", dataSpeakerItemColor: "#004EA8", organiserTitleColor: "#4B4847", organiserLineColor: "#004EA8", headerDataHoverColor: "#1E478E", dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#004EA8' };
  exploreId
  planId
  enquireData
  successfulSubscription: boolean = false;
  subscription: any;
  bannerData;
  selectEventType
  eventTypes
  askUsAnything

  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router: Router) {
    let showFlag = true
    this.cecSubHeaderList =  ["Event Type", "Ask Us"]
  }

  ngOnInit() {
    this.getCecEventsContent();
    this.callCecCTEvent("Page_Load_JWCC", "", "Jio World Convention Centre");
  }

  getCecEventsContent() {
    let keyList = [
      "ORGANISE-EVENT-HERO-BANNER",
      "ORGANISE-EVENT-TYPE-SELECT",
      "ORGANISE-EVENT-TYPES",
      "ORGANISE-EVENT-ASK-US",
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
      (data: any) => {
        this.bannerData = data[0] ? data[0].data : data[0];
        this.selectEventType = data[1] ? data[1].data : data[1];
        this.eventTypes = data[2] ? data[2].data : data[2];
        this.askUsAnything = data[3] ? data[3].data : data[3];
        this._sharedService.initializeCustomJs();
        this.cecSubHeaderList = this._sharedService.subHeaderItems(this.cecSubHeaderList);
        this.eventTypes?.cards.forEach(element => {
          this.sortItems(element)
        });
        console.log(this.eventTypes);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  sortItems(item) {
    item.AddIcons = []
    item.AddCTA = []
    item?.AddIconsCTA.forEach(element => {
      if (element.Type?.data == '["icon"]') {
        item.AddIcons.push(element)
      } else {
        item.AddCTA.push(element)
      }
    });
  }

  getAutoSliderTime(input: any) {
    if (input && input.data)
      return input.data.match(/(\d+)/)[0] * 1000 || 4000;
    else
      return 4000;
  }

  callCecCTEvent(eveName, secName, linkClicked) {
    this._sharedService.callCTEvent(eveName, secName, '', linkClicked);
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }
  filterdTypes = []
  selectedTypes = []
  itemSelected(item, index) {
    let selctedItem = this.selectEventType?.cards[index]?.EventType?.data
    if (item.selected) {
      item.selected = false
      let itemIndex = this.selectedTypes.findIndex(i => i == selctedItem)
      this.selectedTypes.splice(itemIndex, 1)
      this.filter()
    } else {
      item.selected = true
      this.selectedTypes.push(selctedItem)
      this.filter()
    }
    console.log(this.selectedTypes);

  }

  filter() {
    this.filterdTypes = []
    this.selectedTypes.forEach((item, index) => {
      let tempArray = this.eventTypes?.cards.filter(i => i?.TypeOfEvent?.data.toUpperCase() == item.toUpperCase())
      this.filterdTypes = [...this.filterdTypes, ...tempArray]
      console.log(this.filterdTypes);
    })
  }
}