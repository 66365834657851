import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cec-wedding-event',
  templateUrl: './cec-wedding-event.component.html',
  styleUrls: ['./cec-wedding-event.component.css']
})
export class CecWeddingEventComponent implements OnInit {
  weddingColorList = { dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#A67D54', benefitsLineColor: "#A67D54", dataExploreBoxBgColor: '#ffffff' };
  pdfName;
  weddingKeyArray;
  subscription;
  weddingBannerData;
  weddingGetInTouchData;
  weddingGettingStartedData;
  weddingServicesData;
  weddingServicesDownloadsList;
  organiserData;
  cecVenueDetailsShowcaseData;
  cecSubHeaderList
  cecColorList = {
    newsDataTitleColor: "#4B4847", newsDataLineColor: "#004EA8", newsDateColor: "#004EA8", newsCopyColor: "#FFFFFF", newsViewAllLineColor: "#004EA8", primaryColor: '#1E478E', experiencesDataLineColor: '#1E478E', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', subscribeDataBgColor: "#FFFFFF", subscribeDataTitleColor: "#4B4847", subscribeDataLineColor: "#004EA8", subscribeDotColor: "#004EA8", subscribeLineColor: "#004EA8", subscribeTextColor: "#4B4847", momentsIconColor: "#004EA8", dataSpeakerItemColor: "#004EA8", organiserTitleColor: "#4B4847", organiserLineColor: "#A67D54", headerDataHoverColor: "#1E478E", dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#004EA8',
    textContentColor: '#2C2C2C'
  };
  cecVenueDetailsColorList = {
    footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', headerDataHoverColor: "#1E478E", dataExperiencesTitleColor: '#4B4847', dataMobileLineBg: 'transparent', dataTitleColor: '#4B4847', dataLineColor: '#1E478E', dataTabTxtColor: '#4B4847', dataTabTxtHighlightColor: '#6CC24A', dataVenuesTitleColor: '#4B4847', dataVenuesLineColor: '#004EA8', dataSpeakerItemColor: '#004EA8', organiserTitleColor: '#4B4847', organiserLineColor: '#004EA8', dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#004EA8', dataPlanBgColor: '#F5F2ED', dataNewsletter2LineColor: '#004EA8', dataPlanLineColor: '#004EA8'
  };

  staticUrlForImgNew = environment.staticUrlForImgNew
  constructor(public _sharedService: SharedService) {
    this.cecSubHeaderList = ['The Experience', 'Downloads'];
  }

  ngOnInit(): void {
    this.getLandingPageContent();
  }

  getLandingPageContent() {
    this.weddingKeyArray = [
      'CEC-WEDDINGS-BANNER', // Banner
      'CEC-WEDDINGS-GETTING-STARTED',  // Getting Started
      'CEC-WEDDINGS-SERVICES', // Services
      'CEC-WEDDINGS-PRICING-INFORMATION', // Pricing Information
      'CEC-WEDDINGS-DOWNLOADS', // Downloads
      'CEC-WEDDINGS-TESTIMONIALS',
      'CEC-WEDDINGS-SHOWCASE'
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.weddingKeyArray).subscribe(
      (data: any) => {
        this.weddingBannerData = data[0] ? data[0].data : data[0];
        this.weddingGettingStartedData = data[1] ? data[1].data : data[1];
        this.weddingServicesData = data[2] ? data[2].data : data[2];
        this.weddingGetInTouchData = data[3] ? data[3].data : data[3];
        this.weddingServicesDownloadsList = data[4] ? data[4].data : data[4];
        this.organiserData = data[5] ? data[5].data : data[5];
        this.cecColorList.dataSpeakerItemColor = this.organiserData?.CardBackgroundColor?.data;
        this.cecVenueDetailsShowcaseData = data[6] ? data[6].data : data[6];
        this._sharedService.initializeCustomJs();
        this.cecSubHeaderList = this._sharedService.subHeaderItems(this.cecSubHeaderList)
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  weddingServicesCTEvent(btn, sectionTitle, pdfValue?, linkClicked?) {
    switch (btn) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle, '', linkClicked);
        break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(pdfValue);
        this._sharedService.callCTEvent('Download_Links', sectionTitle, this.pdfName);
        break;
      case 'sticky':
        this._sharedService.callCTEvent('Sticky_Menu_Links', '', '', linkClicked);
    }
  }

}
