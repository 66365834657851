import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-inquiry-home',
  templateUrl: './inquiry-home.component.html',
  styleUrls: ['./inquiry-home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InquiryHomeComponent implements OnInit {
  pageName;
  inquiryColorList;
  inquiryHomeColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor: "#976841"
  };
  inquiryCecColorList = {
    footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', headerDataHoverColor: "#1E478E"
  }
  inquiryGardenColorList = {
    footerIconColor: '#43B02A', footerHoverColor: '#EDFDE8', footerFillColor: '#FFFFFF', headerDataHoverColor: "#43B02A"
  };
  previousRouteData;
  selectedBrand;
  selectedRole
  inquiryType = 'General';
  inquiryTypeArr = ['ge', 'vh', 'ca', 'ls'];
  inquiryMap = { 'ge': 'General', 'vh': 'Venue Hire', 'ca': 'Careers', 'ls': 'Logistics Services' };
  spaceMap = { 'eh': 'Exhibition Halls', 'bh': 'Ballroom', 'ch': 'Convention Halls', 'mr': 'Meeting Rooms' }
  brandArr = ['jwc', 'cec', 'jwg', 'tcc', 'das'];
  spaceArr = ['eh', 'bh', 'ch', 'mr'];
  dataLoaded: boolean = false;
  inquiryData;
  space;
  headlineData;
  captchaSiteKey;
  brand;
  disableSubmitBtn;

  constructor(public _sharedService: SharedService, private route: ActivatedRoute) {
    if (this.route.snapshot.queryParams['type']) {
      let it = (this.inquiryTypeArr.indexOf(this.route.snapshot.queryParams['type']) > -1) ?
        this.route.snapshot.queryParams['type'] : 'ge';
      if (it == 'vh' || it == 'ls') this.selectedBrand = 'CEC';
      this.inquiryType = this.inquiryMap[it];
      // console.log('type: ', this.inquiryType);
    }
    if (this.route.snapshot.queryParams['brand']) {
      let br = this.route.snapshot.queryParams['brand']
      let sb = (this.brandArr.indexOf(br) > -1) ?
        (((this.inquiryType == 'Venue Hire' || this.inquiryType == 'Logistics Services') && (br == 'jwc' || br == 'tcc')) ? 'cec' : this.route.snapshot.queryParams['brand']) : (this.inquiryType == 'Venue Hire' || this.inquiryType == 'Logistics Services') ? 'cec' : 'jwc';
      // this.selectedBrand = sb.toUpperCase();
      this.selectedBrand = br.toUpperCase();
      switch (this.selectedBrand) {
        case 'DAS':
          this.brand = 'Fountain of Joy - Dhirubhai Ambani Square';
          break;
        case 'CEC':
          this.brand = 'Jio World Convention Centre';
          break;
        case 'JWG':
          this.brand = 'Jio World Garden';
          break;
        case 'TCC':
          this.brand = 'Nita Mukesh Ambani Cultural Centre';
          break;
        default:
          this.brand = 'Jio World Centre';
      }
      // console.log('brand: ', this.selectedBrand);
    }
    if (this.route.snapshot.queryParams['space']) {
      let space = (this.spaceArr.indexOf(this.route.snapshot.queryParams['space']) > -1) ?
        this.route.snapshot.queryParams['space'] : 'eh';
      this.space = this.spaceMap[space];
      // console.log('space: ', this.space);
    }
    this.previousRouteData = this._sharedService.getPreviousRouteData();
  }
  
  ngOnInit() {
    this.getAllRoles()
    this._sharedService.resetInquiryFormValues();
    this.captchaSiteKey = environment.recaptchaSiteKey;
    this._sharedService.disableSubmitBtn.subscribe(
      (data) => {
        this.disableSubmitBtn = data;
      });
    this._sharedService.getCommonService().getInquiryData().subscribe(
      (data: any) => {
        this.inquiryData = data.data;
        console.log(" this.inquiryData ", this.inquiryData);
        this.changeHeadlines();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
    this._sharedService.callLoader();
    this._sharedService.callBackToTop();
    this.setDefaultValues();
  }

  AllRoles: any[] = []
  getAllRoles() {
    this._sharedService.getCommonService().getAllActivePositions().subscribe((data: any) => {
      this.AllRoles = data;
      if (this.route?.snapshot?.queryParams['jobId']) {
      let jobId= this.route?.snapshot?.queryParams['jobId']
      let index= this.AllRoles?.findIndex(r=>r?.jobId==jobId)
      this.selectedRole=this.AllRoles[index]?.position
      }
    })
  }

  setDefaultValues() {
    switch (this.previousRouteData) {
      case 'cec':
        this.selectedBrand = (this.selectedBrand) ? this.selectedBrand : 'CEC';
        return this.inquiryColorList = this.inquiryCecColorList;
      case 'garden':
        this.selectedBrand = (this.selectedBrand) ? this.selectedBrand : 'JWG';
        return this.inquiryColorList = this.inquiryGardenColorList;
      case 'tcc':
        this.selectedBrand = (this.selectedBrand) ? this.selectedBrand : 'TCC';
        return this.inquiryColorList = this.inquiryHomeColorList;
      case 'das':
        this.selectedBrand = (this.selectedBrand) ? this.selectedBrand : 'DAS';
        return this.inquiryColorList = this.inquiryHomeColorList;
      default:
        this.selectedBrand = (this.selectedBrand) ? this.selectedBrand : 'JWC';
        return this.inquiryColorList = this.inquiryHomeColorList;
    }
  }

  changeInquiryType(value) {
    if (this.inquiryType !== value) {
      this.inquiryType = value;
      this.selectedBrand = (value == 'General' || value == 'Careers') ? 'JWC' : 'CEC';
      this.changeHeadlines();
      this._sharedService.disableSubmitBtn.next(false);
    }
  }

  changeBrandType(value) {
    if (this.selectedBrand !== value) {
      this.selectedBrand = value;
      this.changeHeadlines();
      this._sharedService.disableSubmitBtn.next(false);
    }
  }

  changeHeadlines() {
    if (this.selectedBrand == 'JWC' && this.inquiryType == 'General') {
      this.headlineData = this.inquiryData.cards[0].jwc;
    } else if (this.selectedBrand == 'TCC' && this.inquiryType == 'General') {
      this.headlineData = this.inquiryData.cards[0].jwc;
    } else if (this.selectedBrand == 'CEC' && this.inquiryType == 'General') {
      this.headlineData = this.inquiryData.cards[0].cec;
    } else if (this.selectedBrand == 'JWG' && this.inquiryType == 'General') {
      this.headlineData = this.inquiryData.cards[0].garden;
    } else if (this.selectedBrand == 'DAS' && this.inquiryType == 'General') {
      this.headlineData = this.inquiryData.cards[0].das;
    } else if (this.selectedBrand == 'CEC' && (this.inquiryType == 'Venue Hire' || this.inquiryType == 'Logistics Services')) {
      this.headlineData = this.inquiryData.cards[1].cec;
    } else if (this.selectedBrand == 'JWG' && this.inquiryType == 'Venue Hire') {
      this.headlineData = this.inquiryData.cards[1].garden;
    } else if (this.selectedBrand == 'CEC' && this.inquiryType == 'Careers') {
      this.headlineData = this.inquiryData.cards[2].cec;
      // this.headlineData = this.inquiryData.cards[0].cec;
    } else if (this.selectedBrand == 'JWG' && this.inquiryType == 'Careers') {
      this.headlineData = this.inquiryData.cards[2].jwg;
      // this.headlineData = this.inquiryData.cards[0].garden;
    } else if (this.selectedBrand == 'JWC' && this.inquiryType == 'Careers') {
      this.headlineData = this.inquiryData.cards[2].jwc;
    } else if (this.selectedBrand == 'TCC' && this.inquiryType == 'Careers') {
      this.headlineData = this.inquiryData.cards[2].tcc;
      // this.headlineData = this.inquiryData.cards[0].jwc;
    } else if (this.selectedBrand == 'DAS' && this.inquiryType == 'Careers') {
      this.headlineData = this.inquiryData.cards[2].das;
    }
    // console.log("headline: ", this.headlineData);
  }

}
