import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-small-meeting-rooms',
  templateUrl: './small-meeting-rooms.component.html',
  styleUrls: ['./small-meeting-rooms.component.scss']
})
export class SmallMeetingRoomsComponent implements OnInit {
  private subscription;
  cecVenueDetailsColorList = {
    dataGetintouchLineColor: '#004EA8', benefitsLineColor: '#004EA8', headerDataHoverColor:"#1E478E"
  };
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  
  cecSubHeaderList = ["Meeting Pods", "Packages", "Packages Include", "Ask Us Anything"];
  bannerData;
  quotesData;
  infographicsData;
  packageData;
  packageIncludeData;
  bookMeetingData;

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
    this.getPageData()
  }

  getPageData() {
    let keyList = [
      'SMALL-MEETING-ROOM-BANNER',
      'SMALL-MEETING-ROOM-QUOTE-SECTION',
      'SMALL-MEETING-ROOM-INFOGRAPHICS',
      'SMALL-MEETING-ROOM-PACKAGES',
      'SMALL-MEETING-ROOM-PACKAGES-INCLUDE',
      'SMALL-MEETING-ROOM-BOOK-MEETING'
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
      (data: any) => {
        this.bannerData = data[0] ? data[0].data : data[0];
        this.quotesData = data[1] ? data[1].data : data[1];
        this.infographicsData = data[2] ? data[2].data : data[2];
        this.packageData = data[3] ? data[3].data : data[3];
        this.packageIncludeData = data[4] ? data[4].data : data[4];
        this.bookMeetingData = data[5] ? data[5].data : data[5];

        this._sharedService.initializeCustomJs();
        this.cecSubHeaderList = this._sharedService.subHeaderItems(this.cecSubHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
}

