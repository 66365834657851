<div class="video_popup modal fade" id="video_box" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="wrapper modal-dialog" role="document">
    <div class="modal-content">
      <a href="javascript:void(0);" class="close" data-dismiss="modal"></a>
      <video controls controlsList="nodownload">
        <source src="" type="video/mp4">
        <!-- <source *ngIf="videoUrl" [src]="staticUrlForImgNew + videoUrl" type="video/mp4"> -->
        <!-- <source *ngIf="internalVideoUrl" src="{{internalVideoUrl}}" type="video/mp4"> -->
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>
</div>