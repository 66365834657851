<!-- <app-header [colorList]="gardenArchiveColorList" [pageName]="'garden'"></app-header> -->
<app-sub-header [colorList]="gardenArchiveColorList" [subHeaderList]="gardenArchiveSubHeaderList" [pageName]="'garden'"
  *ngIf="gardenArchiveSubHeaderList"></app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <app-banner [landingBannerData]="archiveBannerData" [bannerPage]="'garden'" [sectionClass]="'screen_height'"  [blackTint]="true" *ngIf="archiveBannerData">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-garden">Jio World Garden</a> / <strong>Events
          Archive</strong>
      </div>
    </div>
  </app-banner>
  <section class="archive filter_view" id="section_2">
    <div class="archive_filters filter_view_tab">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- <div class="archive_filters_icon" style="display: flex; border: none;"> -->
            <!-- <div class="archive_filters_icon1" style="display: flex; border: none;"> -->
            <!-- <a href="javascript:void(0);" data-target="calendarView" class="archive_filters_icon--calendar active"></a>
              <a href="javascript:void(0);" data-target="gridView" class="archive_filters_icon--grid"></a> -->
            <!-- </div> -->
            <!-- <div class="archive_filters_type"> -->
            <!-- <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">EVENT</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Popularity</li>
                    <li tabindex="0">Price - Low to High</li>
                    <li tabindex="0">Price - High to Low</li>
                    <li tabindex="0">User Rating - High to Low</li>
                  </ul>
                </div>
              </div>
              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">Sort By</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Popularity</li>
                    <li tabindex="0">Price - Low to High</li>
                    <li tabindex="0">Price - High to Low</li>
                    <li tabindex="0">User Rating - High to Low</li>
                  </ul>
                </div>
              </div> -->
            <!-- </div> -->
            <div style="display: flex;">
              <a href="javascript:void(0);" data-target="gridView" class=""></a>
            </div>

            <div class="archive_filters_search">
              <div class="input-box">
                <input type="text" placeholder="SEARCH BY EVENT" class="input-field" [(ngModel)]="searchModel">
                <button class="search-button icon icon-search"></button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="archive_tabs  filter_view_tab_details">
      <div class="archive_tabs_calendar views" id="calendarView">
        <div id="calendar"></div>
      </div>

      <div class="archive_tabs_grid views" id="gridView">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-6 archive_tabs_grid_each"
              *ngFor="let item of archiveEventsData | listFilter: searchModel ; let i = index">
              <a href="javascript:void(0)" (click)="openModal(i)" [attr.data-target]="'#gallery_popup_'+i"
                data-toggle="modal">
                <div class="archive_tabs_grid_each--img">
                  <img src="{{ staticUrlForImgNew + item?.EventThumbnailImage?.document?.contentUrl }}"
                    class="img-fluid" [alt]="item?.ImageAltTextFirst?.data">
                  <!-- <div class="icon tour"><strong>New!</strong> Virtual Tour</div> -->
                </div>
                <h4>{{item?.EventName?.data}}</h4>
                <p class="content3" *ngIf="(item?.EventName?.data =='The Lil Flea')">
                  {{item?.EventStartDate?.data | date: 'yyyy'}}
                </p>
                <p class="content3" *ngIf="(item?.EventStartDate?.data && item?.EventEndDate?.data && item?.EventName?.data !='The Lil Flea')">
                  {{(item?.EventStartDate?.data | date: "d") | dateSuffix}} {{item?.EventStartDate?.data | date: '
                  MMMM'}}
                  <span>- {{(item?.EventEndDate?.data | date: "d") | dateSuffix}}
                    {{item?.EventEndDate?.data | date: ' MMMM yyyy'}}</span>
                </p>
                <p class="content3" *ngIf="(item?.EventStartDate?.data && !item?.EventEndDate?.data)">
                  {{(item?.EventStartDate?.data | date: "d") | dateSuffix}} {{item?.EventStartDate?.data | date: ' MMMM yyyy'}}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>


  </section>
  <div class="modal fade image_gallery_popup" [id]="modalId" tabindex="-1" role="dialog" aria-hidden="true">

    <div class="wrapper modal-dialog" role="document" *ngIf="galleryImages">
      <div class="modal-content">
        <a href="javascript:;" class="close" data-dismiss="modal"></a>
        <div class="jwc_image_slider gallery_popup_slider popup_slider"
          [ngClass]="{'single_image': galleryImages.length == 1}" data-space="0" data-slides="1">

          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let item of galleryImages">
                <div class="jwc_image_slider_each">
                  <img src="{{ staticUrlForImgNew + item?.EventImage?.document?.contentUrl }}" class="img-fluid" [alt]="item?.ImageAltTextSecond?.data">
                </div>
              </div>
            </div>
          </div>
          <!-- Add Arrows -->
          <div class="swiper-arrows">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="jwc_image_slider_text">
      <p class="content3">{{galleryDesc ? (galleryTitle + ': ' + galleryDesc) : galleryTitle}}</p>
    </div>

  </div>
  <div id="plan-your-event">
    <app-get-in-touch *ngIf="archiveInquireData" [getInTouchData]="archiveInquireData"
      [colorList]="gardenArchiveColorList" [pageName]="'garden'" [sectionClass]="''">
    </app-get-in-touch>
  </div>
  <!-- <section class="more_centre_wrapper plr-0" style="--data-more-centre-bgColor1:#F5F2ED;--data-more-centre-bgColor2:;--data-more-centre-titleColor:#4B4847;
      --data-more_centre_txt_bgColor:#FFFFFF;">
    <div class="container">
      <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#43B02A">
        <div class="">
          <h2 class="section_heading--title">More At Jio World Centre</h2>
        </div>
      </div>
      <div class="more_centre_slider jwc_swiper_normal" data-interval="8" data-space="24" data-slides="3"
        data-mob="false" data-ipad-slides="2.2" data-ipad-space="20" data-mobile-slides="1.15" data-mobile-space="20">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                  <span class="more_centre_slider_each_copy--category">Food Festival</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/2.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                  <span class="more_centre_slider_each_copy--category">Performing Arts</span>
                  <h4 class="more_centre_slider_each_copy--title">Kalasri - Bharatnatyam</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/3.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                  <span class="more_centre_slider_each_copy--category">Visual Arts</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>


    </div>
  </section> -->
</main>
<!-- <app-footer [colorList]="gardenArchiveColorList"></app-footer> -->
<div class="overlay"></div>