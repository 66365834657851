import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.css']
})
export class GetInTouchComponent implements OnInit {
  @Input() getInTouchData;
  @Input() colorList;
  @Input() pageName;
  @Input() sectionClass?;
  @Input() downloadCta?;
  buttonColorClass: Map<string, string>;
  pdfName;
  constructor(public _sharedService: SharedService, private titleService: Title, private activatedRoute: ActivatedRoute) {
    this.buttonColorClass = new Map([
      ["home", "color-gold emp-btn"],
      ["cec", "color-blue emp-btn"],
      ["garden", "color-green emp-btn"],
      ["homeWithoutEmpClass", "color-gold"]
    ]);
  }

  ngOnInit() {
  }

  callPdfName(string) {
    this.pdfName = this._sharedService.getPdfName(string);
    return this.pdfName;
  }

  callGetInTouchCTEvent(type) {
    switch (type) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', this.getInTouchData.ComponentTitle.data);
        break;
      case 'sec':
        this._sharedService.callCTEvent('Section_Secondary_Links', this.getInTouchData.ComponentTitle.data);
        break;
      case 'download':
        this.callPdfName(this.getInTouchData.ContactUsCTALink.data);
        this._sharedService.callCTEvent('Download_Links', this.getInTouchData.ComponentTitle.data, this.pdfName);
        break;
    }
  }

}
