<!-- <app-header [colorList]="gardenColorList" [pageName]="'garden'"></app-header> -->
<app-sub-header [colorList]="gardenColorList" [subHeaderList]="gardenSubHeaderList" [pageName]="'garden'"
    *ngIf="gardenSubHeaderList"></app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>

<main role="main">  
  <app-back-to-wrapper></app-back-to-wrapper>
  <app-thank-you-page [brandName]="'Jio World Garden'" *ngIf="successfulSubscription"></app-thank-you-page>
  <app-banner [landingBannerData]="gardenBannerData" [bannerPage]="'garden'" *ngIf="gardenBannerData" [sectionClass]="'screen_height_inside'" [fragmentId]="aboutId" [blackTint]="true"></app-banner>
  <app-covid2-section [page]="'garden'" *ngIf="gardenBannerData"></app-covid2-section>
  <section class="benefits_wrapper multilineText mb-30" [id]="aboutId" data-mobile-header="About">
    <div class="container">
      <div class="row align-items-center" *ngIf="gardenJWGData">
        <div class="col-lg-6 col-md-6">
          <div class="section_heading multilineText mb-30 section_heading--title section_heading--text content2" [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor:#43B02A') | safe: 'style'">
            <div class="">
              <h2 [innerHTML]="gardenJWGData?.ComponentHeaderTitle?.data"></h2>
              <p  [innerHTML]="gardenJWGData?.ComponentHeaderSubTitle?.data"></p>
              <a (click)="callJwgHomeCTEvent('pri', gardenJWGData.ComponentHeaderTitle.data); _sharedService.checkCTALink($event, gardenJWGData?.CTALink?.data, gardenJWGData?.CTALinkWindowPreference?.data, gardenJWGData?.TypeOfCTALink?.data)"
                class="cta-prim color-green icon icon-ticket" href="{{gardenJWGData?.CTALink?.data}}"
                *ngIf="gardenJWGData?.HideCTAText.data == 'false'">
                <span class="cta-prim-txt">{{gardenJWGData?.CTAText?.data}}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="bg_before_right" [attr.style]="('--data-bglayer-color:#66C553') | safe: 'style'">
            <img src="{{staticUrlForImgNew + gardenJWGData?.cards[0]?.CardImage?.image?.contentUrl}}" class="img-fluid"
              alt="{{gardenJWGData?.cards[0]?.ImageAltText?.data}}">
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-infographics-section *ngIf="gardenInfographicsData" [infographicsData]="gardenInfographicsData">
  </app-infographics-section>
  <!-- <section class="garden_categories plr-0" style="--data-gcategory-bgColor:#F5F2ED">
    <div class="section_heading text-center text_bold" style="--data-titleColor:#4B4847;--data-lineColor:#66C553">
      <div class="">
        <h2 class="section_heading--title">What’s On</h2>
      </div>
    </div>
    <div class="garden_categories_wrapper filter_view">
      <div class="container-fluid">
        <div class="row filter_view_tab">
          <div class="garden_categories_wrapper_list listFilter">

            <div class="col-sm-12 filters">
              <a href="javascript:;" class="filter">Filters</a>
            </div>

            <div class="garden_categories_wrapper_list_each">
              <div class="garden_categories_wrapper_list_each--icons">
                <a href="javascript:;" data-target="calendarView"><img src="./assets/images/garden/group.svg" alt=""></a>
                <a href="javascript:;" data-target="gridView"><img src="./assets/images/garden/list.svg" alt=""></a>
              </div>
            </div>
            <div class="garden_categories_wrapper_list_each">
              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">Event Type</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Event Type</li>
                    <li tabindex="0">Performing Arts</li>
                    <li tabindex="0">Visual arts</li>
                    <li tabindex="0">Exhibitions</li>
                    <li tabindex="0">Live shows</li>
                    <li tabindex="0">events</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="garden_categories_wrapper_list_each">
              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">Location</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Location</li>
                    <li tabindex="0">Andheri</li>
                    <li tabindex="0">Mira-Bhayandar</li>
                    <li tabindex="0">Bandra</li>
                    <li tabindex="0">Borivali</li>
                    <li tabindex="0">Dahisar</li>
                    <li tabindex="0">Goregaon</li>
                    <li tabindex="0">Jogeshwari</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="garden_categories_wrapper_list_each">
              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">Genre</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Genre</li>
                    <li tabindex="0">Comedy</li>
                    <li tabindex="0">Crime</li>
                    <li tabindex="0">Historical</li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="garden_categories_wrapper_list_each">

              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">Date</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Date</li>
                    <li tabindex="0">January</li>
                    <li tabindex="0">February</li>
                    <li tabindex="0">March</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="garden_categories_wrapper_list_each">

              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">Sort By</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Popularity</li>
                    <li tabindex="0">Price - Low to High</li>
                    <li tabindex="0">Price - High to Low</li>
                    <li tabindex="0">User Rating - High to Low</li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="garden_categories_wrapper_list_each">
              <div class="custom-control custom-checkbox checkbox">
                <input type="checkbox" class="custom-control-input" id="dontmiss" name="dontmiss">
                <label class="custom-control-label" for="dontmiss">Dont Miss</label>
              </div>
            </div>
          </div>
        </div>
        <div class="filter_view_tab_details">
          <div class="views" id="calendarView">
            <div id="calendar"></div>
          </div>
  
          <div class="garden_categories_wrapper_result views" id="gridView">
            <div class="row">
              <div class="col-md-2">
                <div class="garden_categories_wrapper_date">
                  <div class="garden_categories_wrapper_date--day">SUNDAY</div>
                  <div class="garden_categories_wrapper_date--month">Nov
                    22</div>
                  <div class="garden_categories_wrapper_date--year">2020</div>
                </div>
              </div>
              <div class="col-md-10">
                <div class="garden_categories_wrapper_item">
                  <div class="row">
                    <div class="col-sm-12 col-lg-4">
                      <div class="garden_categories_wrapper_item--img">
                        <img src="./assets/images/garden/whats-on/1.jpg" class="img-fluid" alt="">
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-5">
                      <div class="garden_categories_wrapper_item_copy">
                        <div class="garden_categories_wrapper_item_copy--label">Concert</div>
                        <div class="garden_categories_wrapper_item_copy--subtitle">Lorem Ipsum Presents
                        </div>
                        <div class="garden_categories_wrapper_item_copy--title">Sunburn City Festival feat. Wiz Khalifa</div>
                        <div class="garden_categories_wrapper_item_copy--time">5:00 PM - 8:00 PM</div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-3">
                      <div class="garden_categories_wrapper_item--btns">
                        <a href="javascript:void(0)" class="cta-prim
                                                          color-green"><span class="cta-prim-txt">READ
                            MORE</span></a>
                        <a href="javascript:void(0)" class="cta-prim color-green icon icon-ticket emp-btn"><span class="cta-prim-txt">BUY
                            TICKETS</span></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="garden_categories_wrapper_item">
                  <div class="row">
                    <div class="col-sm-12 col-lg-4">
                      <div class="garden_categories_wrapper_item--img">
                        <img src="./assets/images/garden/whats-on/2.jpg" class="img-fluid" alt="">
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-5">
                      <div class="garden_categories_wrapper_item_copy">
                        <div class="garden_categories_wrapper_item_copy--label">Exhibition</div>
                        <div class="garden_categories_wrapper_item_copy--subtitle">Priyanka Punjabi and Alankar Jain Presents
                        </div>
                        <div class="garden_categories_wrapper_item_copy--title">The Lil’ Flea, Mumbai</div>
                        <div class="garden_categories_wrapper_item_copy--time">5:00 PM - 8:00 PM</div>
                        <div class="garden_categories_wrapper_item_copy_tour">
                          <div class="garden_categories_wrapper_item_copy_tour--img">
                            <img src="../assets/images/garden/tour.svg" class="img-fluid" alt="">
                          </div>
                          <div class="garden_categories_wrapper_item_copy_tour--text">
                            <span>New! </span>Virtual
                            Tour
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-3">
                      <div class="garden_categories_wrapper_item--btns">
                        <a href="javascript:void(0)" class="cta-prim color-green"><span class="cta-prim-txt">READ
                            MORE</span></a>
                        <a href="javascript:void(0)" class="cta-prim color-green icon icon-ticket emp-btn"><span class="cta-prim-txt">BUY
                            TICKETS</span></a>
  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="garden_categories_wrapper_date">
                  <div class="garden_categories_wrapper_date--day">MONDAY</div>
                  <div class="garden_categories_wrapper_date--month">Apr 23</div>
                  <div class="garden_categories_wrapper_date--year">2020</div>
                </div>
              </div>
              <div class="col-md-10">
                <div class="garden_categories_wrapper_item">
                  <div class="row">
                    <div class="col-sm-12 col-lg-4">
                      <div class="garden_categories_wrapper_item--img">
                        <img src="./assets/images/garden/whats-on/3.jpg" class="img-fluid" alt="">
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-5">
                      <div class="garden_categories_wrapper_item_copy">
                        <div class="garden_categories_wrapper_item_copy--label">Exhibition</div>
                        <div class="garden_categories_wrapper_item_copy--subtitle">Jio World Centre presents
                        </div>
                        <div class="garden_categories_wrapper_item_copy--title">Jio
                          Wonderland
                        </div>
                        <div class="garden_categories_wrapper_item_copy--time">9:00
                          PM - 11:00 PM
                        </div>
                        <div class="garden_categories_wrapper_item_copy_tour">
                          <div class="garden_categories_wrapper_item_copy_tour--img">
                            <img src="../assets/images/garden/tour.svg" class="img-fluid" alt="">
                          </div>
                          <div class="garden_categories_wrapper_item_copy_tour--text">
                            <span>New! </span>Virtual
                            Tour
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-3">
                      <div class="garden_categories_wrapper_item--btns">
                        <a href="javascript:void(0)" class="cta-prim color-green"><span class="cta-prim-txt">READ
                            MORE</span></a>
                        <a href="javascript:void(0)" class="cta-prim color-green icon icon-ticket emp-btn"><span class="cta-prim-txt">BUY
                            TICKETS</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center garden_categories_wrapper--btn">
              <a href="javascript:void(0)" class="cta-prim color-green"><span class="cta-prim-txt">VIEW ALL</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <div [id]="eventsId" data-mobile-header="Events">
    <app-experiences-section *ngIf="gardenExperiencesData" [experiencesData]="gardenExperiencesData"
      [colorList]="gardenColorList" [experiencesSwiperData]='gardenExpSwiperData' [sectionClass]="'pb-0'"></app-experiences-section>
  </div>
  <app-organisers-speak id="testimonials" data-mobile-header="Testimonials" *ngIf="gardenOrganiserData" [organiserData]="gardenOrganiserData" [colorList]="gardenColorList">
  </app-organisers-speak>
  <!-- <section class="getintouch " [attr.style]="('--data-getintouch-bgColor: ' + gardenItineraryData?.ComponentBackgroundColor?.data ) | safe: 'style'" data-aos="fade-up" *ngIf="gardenItineraryData">
    <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#6CC24A">
        <div class="">
            <h2 class="section_heading--title"> {{gardenItineraryData?.ComponentTitle?.data}} </h2>
            <p class="section_heading--text"> {{gardenItineraryData?.ComponentShortText?.data}} </p>
        </div>
    </div>
    <div class="text-center">
        <a href="{{gardenItineraryData?.cards[0]?.CTALink?.data}}" *ngIf="gardenItineraryData?.cards[0]?.HideCTA?.data =='false'" class="cta-prim color-green emp-btn"
        (click)="_sharedService.checkCTALink($event, gardenItineraryData?.cards[0]?.CTALink?.data, gardenItineraryData?.cards[0]?.CTAWindowPreference?.data, gardenItineraryData?.cards[0]?.TypeOfCTALink1?.data)">
          <span class="cta-prim-txt">{{gardenItineraryData?.cards[0]?.CTAText?.data}}</span></a>

        <a href="{{gardenItineraryData?.cards[1]?.CTALink?.data}}" *ngIf="gardenItineraryData?.cards[1]?.HideCTA?.data =='false'" class="cta-sec color-green emp-btn"
        (click)="_sharedService.checkCTALink($event, gardenItineraryData?.cards[1]?.CTALink?.data, gardenItineraryData?.cards[1]?.CTAWindowPreference?.data, gardenItineraryData?.cards[1]?.TypeOfCTALink1?.data)">
          <span class="cta-sec-txt">{{gardenItineraryData?.cards[1]?.CTAText?.data}}</span></a>
    </div>
  </section> -->

  <section class="our_story" id="venue-showcase" *ngIf="gardenShowcaseData" data-mobile-header="Venue Showcase" [attr.style]="('--data-story-bgColor:#F5F2ED;--data-story-fisrtColor:#6CC24A;--data-story-secondColor:#ffffff;') | safe: 'style'">
    <div class="container">
      <div class="section_heading text-center section_heading--title" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#6CC24A') | safe: 'style'">
          <h2 [innerHTML]="gardenShowcaseData?.ComponentHeaderTitle?.data"></h2>
      </div>
      <div class="our_story_wrapper">
        <div class="our_story_wrapper_col_1">
          <div class="our_story_wrapper_img_box">
            <div class="our_story_wrapper_img_box--bg" 
            [attr.style]="('background-image: url(' +staticUrlForImgNew+ gardenShowcaseData?.ShowcaseImage1?.image?.contentUrl +')' ) | safe: 'style'">
            </div>
            <!-- <div class="our_story_wrapper_video_box--360Icon"></div> -->
          </div>
        </div>
        <div class="our_story_wrapper_col_2 our_story_wrapper_verticle_box">
          <div class="our_story_wrapper_img_box">
            <div class="our_story_wrapper_img_box--bg" 
            [attr.style]="('background-image: url(' +staticUrlForImgNew+ gardenShowcaseData?.ShowcaseImage2?.image?.contentUrl +')' ) | safe: 'style'">
            </div>
          </div>
          <div class="our_story_wrapper_verticle_box_copy">
            <div class="our_story_wrapper_verticle_box_copy--text">
              <p class="content3" [innerHTML]="gardenShowcaseData?.ShowcaseText2?.data"></p>
            </div>
          </div>
        </div>
        <div class="our_story_wrapper_col_3">
          <div class="our_story_wrapper_img_box">
            <div class="our_story_wrapper_img_box--bg" 
            [attr.style]="('background-image: url(' +staticUrlForImgNew+ gardenShowcaseData?.ShowcaseImage3?.image?.contentUrl +')' ) | safe: 'style'">
            </div>
          </div>
        </div>
        <div class="our_story_wrapper_col_4 our_story_wrapper_horizontal_box">
          <div class="our_story_wrapper_horizontal_box_copy">
            <div class="our_story_wrapper_horizontal_box_copy--text">
              <p [innerHTML]="gardenShowcaseData?.ShowcaseText3?.data"></p>
            </div>
          </div>
          <div class="our_story_wrapper_img_box">
            <div class="our_story_wrapper_img_box--bg" 
            [attr.style]="('background-image: url(' +staticUrlForImgNew+ gardenShowcaseData?.ShowcaseImage4?.image?.contentUrl +')' ) | safe: 'style'">
            </div>
          </div>
        </div>
  
        <div class="our_story_wrapper_col_5">
          <div class="our_story_wrapper_img_box">
            <div class="our_story_wrapper_img_box--bg" 
            [attr.style]="('background-image: url(' +staticUrlForImgNew+ gardenShowcaseData?.ShowcaseImage5?.image?.contentUrl +')' ) | safe: 'style'">
            </div>
          </div>
        </div>
  
        <div class="our_story_wrapper_col_6">
          <div class="our_story_wrapper_img_box--bg" 
          [attr.style]="('background-image: url(' +staticUrlForImgNew+ gardenShowcaseData?.ShowcaseImage6?.image?.contentUrl +')' ) | safe: 'style'">
          </div>
        </div>
      </div>
  
      <div class="our_story_buttons_link">
        <a (click)="callJwgHomeCTEvent('sec', gardenShowcaseData.ComponentHeaderTitle.data); _sharedService.checkCTALink($event, gardenShowcaseData?.ViewAllCTALink?.data, gardenShowcaseData?.ViewAllWindowPreference?.data)"
            class="cta-sec color-green" *ngIf="gardenShowcaseData?.HideViewAllCTAText?.data == 'false'" href="{{gardenShowcaseData?.ViewAllCTALink?.data}}">
            <span class="cta-sec-txt">{{gardenShowcaseData?.ViewAllCTAText?.data}}</span>
        </a>
      </div>
  
    </div>
  </section>

  <section class="experiences_slider garden_wrapper full_width" [id]="highlightsId" data-mobile-header="Highlights"
    [attr.style]="('--data-garden-bgColor: ' + gardenAboutData?.ComponentBackgroundColor?.data ) | safe: 'style'"
    *ngIf="gardenAboutData">
    <div class="container"></div>
    <div class="section_heading section_heading--title" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#6CC24A') | safe: 'style'">
        <h2 [innerHTML]="gardenAboutData?.ComponentHeaderTitle?.data"></h2>
    </div>
    <div class="garden_slider swiper_fraction jwc_swiper_fraction" [ngClass]="{'auto_height': !isBtnPresent}" data-interval="7" data-space="24" data-slides="4" data-ipad-slides="2.5">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of gardenAboutData.cards; let i = index">
            <div class="garden_slider_each">
              <div class="garden_slider_each--img">
                <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                  alt="{{item?.ImageAltText?.data}}">
              </div>
              <div class="garden_slider_each_copy">
                <h4 class="garden_slider_each_copy--title">{{item?.CardHLText?.data}}</h4>
                <p class="content3 garden_slider_each_copy--text">{{item?.CardShortText?.data}}</p>
                <div class="garden_slider_each_copy--btn">
                  <a (click)="_sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTALinkWindowPreference?.data, item?.TypeOfCTALink?.data)"
                    *ngIf="item?.HideCTAText?.data == 'false'" class="cta-prim color-green" href="{{item?.CTALink?.data}}">
                    <span class="cta-prim-txt">{{item?.CTAText?.data}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
      <!-- Add Arrows -->
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <div class="text-center">
      <!-- Kepping static for character key testing -->
      <a (click)="callJwgHomeCTEvent(gardenAboutData.ComponentHeaderTitle.data); _sharedService.checkCTALink($event, gardenAboutData?.ViewAllCTALink?.data, gardenAboutData?.ViewAllWindowPreference?.data, gardenAboutData?.TypeOfViewAllCTALink?.data)"
        class="cta-sec color-green" href="{{gardenAboutData?.ViewAllCTALink?.data}}">
        <span class="cta-sec-txt">{{gardenAboutData?.ViewAllCTAText?.data}}</span>
      </a>
    </div>
  </section>
  <div [id]="planId" data-mobile-header="Contact">
    <app-get-in-touch *ngIf="gardenGetInTouchData" [getInTouchData]="gardenGetInTouchData"
    [colorList]="gardenColorList" [pageName]="'garden'"></app-get-in-touch>
  </div>
  <div data-mobile-header="Getting Here" id="getting-here">
    <app-map *ngIf="gardenAccessData" [accessWithEaseData]="gardenAccessData" [colorList]="gardenColorList" [sectionClass]="'pb-0'"></app-map>
  </div>
  
  <!-- <section class="more_centre_wrapper plr-0"
    style="--data-more-centre-bgColor1:#F5F2ED;--data-more-centre-bgColor2: transparent;--data-more-centre-titleColor:#4B4847">
    <div class="container">
      <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#43B02A">
        <div class="">
          <h2 class="section_heading--title">Other Activities</h2>
        </div>
      </div>
      <div class="more_centre_slider jwc_swiper_normal" data-interval="5" data-space="24" data-slides="3"
        data-mob="false" data-ipad-slides="2.2" data-ipad-space="20" data-mobile-slides="1.15" data-mobile-space="20">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                  <span class="more_centre_slider_each_copy--category">Food Festival</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="#" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/2.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                  <span class="more_centre_slider_each_copy--category">Performing Arts</span>
                  <h4 class="more_centre_slider_each_copy--title">Kalasri - Bharatnatyam</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="#" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/3.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                  <span class="more_centre_slider_each_copy--category">Visual Arts</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="#" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <app-news-section *ngIf="gardenNewsData" [newsData]="gardenNewsData" [colorList]="gardenColorList"></app-news-section> -->
  <div>
    <app-faq-section data-mobile-header="FAQs" id="faqs" *ngIf="gardenFaqData" [faqData]="gardenFaqData" [colorList]="gardenColorList" [sectionClass]="''"></app-faq-section>
    <div [innerHTML]="jsonLDforJwgGeneral"></div>
        <div [innerHTML]="jsonLDforJwgBooking"></div>
        <!-- <div [innerHTML]="jsonLDforJwgAas"></div> -->
        <div [innerHTML]="jsonLDforJwgPlanAVisit"></div>
  </div>
 <!-- <app-jwc-moments-section *ngIf="gardenMomentsData" [momentsData]="gardenMomentsData" [colorList]="gardenColorList">
  </app-jwc-moments-section> -->
  <!-- <app-subscribe-section [subscribeData]="gardenSubscribe2Data" *ngIf="gardenSubscribe2Data"
    [colorList]="gardenColorList" [pageName]="'garden'" [sectionClass]="'small_space'"></app-subscribe-section> -->
  <!-- <app-footer [colorList]="gardenColorList"></app-footer> -->
</main>