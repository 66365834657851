<div class="loader">
  <div class="loader_spin"></div>
</div>


<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <section class="sub_page_header">
    <div class="breadcrumbs ">
      <div class="breadcrumbs_container">
        <a *ngIf="page ==='new-das'; else otherLink" routerLink="/das">Dhirubhai Ambani Square</a> <ng-template #otherLink><a routerLink="/">Jio World Centre</a></ng-template> / <strong>Legal</strong>
      </div>
    </div>
    <div class="container">
      <div class="section_heading section_heading--title text-center" [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
          <h2>Legal Policies</h2>
          <!-- <p class="section_heading--text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </p> -->
      </div>
    </div>
  </section>
  <section class="sub_page_content"
    [attr.style]="('--data-subpage-bgColor:#F5F2ED; --data-subpage-textColor:#4B4847; --data-subpage-hoverColor:#A67D54; --data-subpage-submenulineColor:#4B4847;') | safe: 'style'">
    <div class="container leftsidenav">
      <div class="row">
        <div class="col-md-3 sticky-top">
          <div class="sidebarlist legal-sidebar-list sticky-top">
            <div class="col-sm-12 list-btn "> <a href="javascript:void(0);" [innerHTML]="mobileTabname"></a> </div>
            <div class="sidebarnav">
              <ul class="flex-column nav nav-tabs">
                <!-- <li> <a href="javascript:void(0)" class="sidebarnav_link"><span class="nav-link-txt">Overview</span></a> </li>
                <li> <a href="javascript:void(0)" class="sidebarnav_link"><span class="nav-link-txt">Support</span></a></li>
                <li> <a href="javascript:void(0)" class="sidebarnav_link"><span class="nav-link-txt">Sitemap</span></a></li>
                <li> <a href="javascript:void(0)" class="sidebarnav_link"><span class="nav-link-txt">Safety</span></a></li> -->
                <!-- <li> <a class="submenus" href="#submenu1" data-toggle="collapse" data-target="#submenu1"><span
                      class="nav-link-txt">Policies</span></a>
                  <div class="collapse show submenubox" id="submenu1" aria-expanded="true">
                    <ul class="flex-column">
                      <li> <a href="javascript:void(0)" class="nav-link active"><span class="nav-link-txt">Legal Policy</span></a> </li>
                      <li> <a href="javascript:void(0)"><span class="nav-link-txt">Privacy</span></a> </li>
                    </ul>
                  </div>
                </li> -->
                <!-- <li> <a href="javascript:void(0)" class="sidebarnav_link"><span class="nav-link-txt">Jio World offices</span></a> </li> -->
                <ng-container *ngFor="let item of legalPageData; let i=index">
                  <li *ngIf="item?.HideTab?.data==='false'">
                    <a href="#{{item?.TabIdentifier?.data}}" data-toggle="tab" (click)="callLegalCTEvent(item?.LegalTabName?.data);changeTabName(item?.TabIdentifier?.data, item?.LegalTabName?.data)" class="sidebarnav_link nav-link" [attr.style]="('--data-subpage-activeColor: #A67D54' ) | safe: 'style'" [ngClass]="{'active': item?.TabIdentifier?.data=== tabName}">
                      <span class="nav-link-txt" [innerHTML]="item?.LegalTabName?.data"></span>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-9 content">
          <div class="tab-content content3 content-list">
            <ng-container *ngFor="let item of legalPageData; let i=index">
              <div *ngIf="item?.HideTab?.data==='false'" class="tab-pane fade" [ngClass]="{'active show': item?.TabIdentifier?.data=== tabName}" id="{{item?.TabIdentifier?.data}}" [innerHTML]="item?.LegalTabContent?.data"></div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="full_width experiences_wrapper "
    style="--data-experiences-bgColor1:#F5F2ED;--data-experiences-bgColor2:#F5F2ED;--data-experiences-titleColor:#4B4847; --data-mobileLineBg:#A67D54">
    <div class="container"></div>
    <div class="section_heading " style="--data-titleColor:#4B4847;--data-lineColor:#004EA8">
      <div class="">
        <h2 class="section_heading--title">Brands At Jio World Centre</h2>
        <p class="section_heading--text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamc
        </p>
      </div>
    </div>
    <div class="swiper_fraction experiences_fraction experiences_slider jwc_swiper_fraction" data-interval="5"
      data-space="80" data-slides="1.8" data-ipad-slides="1.2">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="experiences_slider_each" style="--data-opacity: 0.7;">
              <div class="experiences_slider_each--img">
                <img src="./assets/images/policy/centre/1.jpg" class="img-fluid" alt="slider 1">
              </div>
              <div class="experiences_slider_each_copy">
                <div class="experiences_slider_each_copy--center">
                  <h4>Brands at Jio World Centre</h4>
                  <p class="content3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p>
                  <a href="javascript:void(0)" class="cta-sec on-bg"><span class="cta-sec-txt">KNOW MORE</span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="experiences_slider_each" style="--data-opacity: 0.7;">
              <div class="experiences_slider_each--img">
                <img src="./assets/images/policy/centre/2.jpg" class="img-fluid" alt="slider 2">
              </div>
              <div class="experiences_slider_each_copy">
                <div class="experiences_slider_each_copy--center">
                  <h4>Brands at Jio World Centre</h4>
                  <p class="content3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p>
                  <a href="javascript:void(0)" class="cta-sec on-bg"><span class="cta-sec-txt">KNOW MORE</span></a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>

  </section> -->
  <!-- <section class="full_width experiences_wrapper pbottom-0 "
    style="--data-experiences-bgColor1: transparent;--data-experiences-bgColor2: transparent;--data-experiences-titleColor:#2C2C2C; --data-mobileLineBg:#A67D54">
    <div class="container"></div>
    <div class="section_heading " style="--data-titleColor:#2C2C2C;--data-lineColor:#C69E67">
      <div class="">
        <h2 class="section_heading--title">Experiences</h2>
        <p class="section_heading--text"> At Jio World Centre, every journey will take you places.
        </p>
      </div>
    </div>
    <div class="experiences_slider swiper_fraction experiences_fraction jwc_swiper_fraction" data-interval="5"
      data-aos="fade-up" data-space="80" data-slides="1.8" data-ipad-slides="1.2">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="experiences_slider_each" style="--data-opacity: 0.5;">
              <div class="experiences_slider_each--img">
                <img src="./assets/images/experiences/1.jpg" class="img-fluid" alt="slider 1">
              </div>
              <div class="experiences_slider_each_copy">
                <div class="experiences_slider_each_copy--center">
                  <h4>Melting Memories</h4>
                  <p class="content3">Immerse yourself in a never-seen-before Greek sculptureshow that is inspired by
                    the early 1800s.</p>
                  <a href="javascript:void(0)" class="cta-sec on-bg"><span class="cta-sec-txt">KNOW MORE
                    </span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="experiences_slider_each" style="--data-opacity: 0.4;">
              <div class="experiences_slider_each--img">
                <img src="./assets/images/experiences/2.jpg" class="img-fluid" alt="slider 2">
              </div>
              <div class="experiences_slider_each_copy">
                <div class="experiences_slider_each_copy--center">
                  <h4>Dhirubhai Ambani Square </h4>
                  <p class="content3">Witness a beautiful symphony of dancing water jets and spectacular lights,
                    paired with an orchestral soundtrack - right here at the Dhirubhai Ambani
                    Square.</p>
                  <a href="javascript:void(0)" class="cta-sec on-bg"><span class="cta-sec-txt">KNOW MORE
                    </span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="experiences_slider_each" style="--data-opacity: 0.5;">
              <div class="experiences_slider_each--img">
                <img src="./assets/images/experiences/3.jpg" class="img-fluid" alt="slider 3">
              </div>
              <div class="experiences_slider_each_copy">
                <div class="experiences_slider_each_copy--center">
                  <h4>Dine at Nobu</h4>
                  <p class="content3">Indulge at the world’s most-loved Japanese restaurant, now in Mumbai. </p>
                  <a href="javascript:void(0)" class="cta-sec on-bg"><span class="cta-sec-txt">KNOW MORE
                    </span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="swiper-pagination"></div>
      
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </section> -->

</main>
<!-- <app-footer [colorList]="legalPolicyColorList"></app-footer> -->
<div class="overlay"></div>