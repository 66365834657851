<ng-container *ngFor="let item of addressData?.cards; let i=index">
    <div class="card" *ngIf="item?.HideAccordianSection?.data==='false'">
        <div class="card-header" [attr.id]="'cua_heading_' + i">
            <h6 class="mb-0">
                <ng-container *ngIf="currentPage ==='new-das'; else otherButton">
                    <button style="pointer-events: none;" class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#cua_collapse_' + i" aria-expanded="true" [attr.aria-controls]="'cua_collapse_' + i">
                        {{item?.AccordianHeader?.data}}
                    </button>
                </ng-container>
                <ng-template #otherButton>
                    <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#cua_collapse_' + i"
                        [attr.aria-expanded]="item?.isExpanded" [attr.aria-controls]="'cua_collapse_' + i">
                        {{item?.AccordianHeader?.data}}
                        <span class="icon-arrow-down"></span>
                    </button>
                </ng-template>
            </h6>
        </div>
        <div [attr.id]="'cua_collapse_' + i" class="collapse" [ngClass]="{'show': item?.isExpanded}"
            [attr.aria-labelledby]="'cua_heading_' + i" data-parent="#accordion">
            <div class="card-body">
                <div class="card-body_copy">
                    <p class="content3" [innerHTML]="item?.Address?.data"></p>
                    <p class="content3" *ngIf="item?.AddEmail[0]?.Email?.data">
                        <span *ngFor="let email of item?.AddEmail; let last=last">{{email?.Email?.label}}:
                            <a href="{{'mailto:'+email?.Email?.value}}">{{email?.Email?.value}}</a><br
                                *ngIf="!last"></span>
                    </p>
                    <!-- Email : <a href="mailto:contact@jioworldcentre.com">{{item?.Email?.data}}</a> -->
                    <p class="content3" *ngIf="item?.Website?.data"> Website : <a
                            href="https://jioworldcentre.com/">{{item?.Website?.data}}</a> </p>
                    <p class="content3" *ngIf="item?.Whatsapp?.data"> Whatsapp : <a
                            href="tel:91 XXXXXXXXXX">{{item?.Whatsapp?.data}}</a></p>
                    <!-- <p class="content3">
                <a href="javascript:void(0)"  (click)="_sharedService.checkCTALink($event, item?.ChatBotUrl?.data)" class="color-gold-txt">
                   Click here</a> to start
                  conversation with our chatbot
              </p> -->
                    <!-- <ul class="social--UL">
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, item?.FacebookLink?.data)" class="icon icon-facebook"></a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, item?.TwitterLink?.data)" class="icon icon-twitter"></a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, item?.InstagramLink?.data)" class="icon icon-instagram"></a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, item?.YoutubeLink?.data)" class="icon icon-youtube"></a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, item?.LinkedinLink?.data)" class="icon icon-linkedin"></a></li>
              </ul> -->
                    <div class="cta-holder" [hidden]="item?.HideFirstCTA?.data == 'true' && item?.HideSecondCTA?.data == 'true' &&
                item?.HideThirdCTA?.data == 'true'">
                        <a href="{{item?.FirstCTALink?.data}}" class="cta-prim color-gold"
                            *ngIf="item?.HideFirstCTA?.data !== 'true'"
                            (click)="callContactAddCardCTEvent(item?.FirstCTAText?.data, item?.AccordianHeader?.data); _sharedService.checkCTALink($event, item?.FirstCTALink?.data, item?.FirstCTALinkWindowPreference?.data, item?.TypeOfFirstCTALink?.data)">
                            <span class="cta-prim-txt">{{item?.FirstCTAText?.data}}</span>
                        </a>
                        <a href="{{item?.SecondCTALink?.data}}" class="cta-prim color-gold"
                            *ngIf="item?.HideSecondCTA?.data !== 'true'"
                            (click)="callContactAddCardCTEvent(item?.SecondCTAText?.data, item?.AccordianHeader?.data);_sharedService.checkCTALink($event, item?.SecondCTALink?.data, item?.SecondCTALinkWindowPreference?.data, item?.TypeOfSecondCTALink?.data)">
                            <span class="cta-prim-txt">{{item?.SecondCTAText?.data}}</span>
                        </a>
                        <a href="{{item?.ThirdCTALink?.data}}" class="cta-prim color-gold"
                            *ngIf="item?.HideThirdCTA?.data !== 'true'"
                            (click)="callContactAddCardCTEvent(item?.ThirdCTAText?.data, item?.AccordianHeader?.data);_sharedService.checkCTALink($event, item?.ThirdCTALink?.data, item?.ThirdCTALinkWindowPreference?.data, item?.TypeOfThirdCTALink?.data)">
                            <span class="cta-prim-txt">{{item?.ThirdCTAText?.data}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>