
<div class="kma_with_dots " [ngClass]="{'single_image': newBannerData?.cards?.length == 1}">
     <div class="swiper-container kma_with_dots_big_img screen_height">
          <div class="swiper-wrapper">
               <div class="swiper-slide" [attr.data-swiper-autoplay]="AutomaticSlideshowInterval" *ngFor="let item of newBannerData?.cards; let i=index;" [ngClass]="item?.ImageTintOn?.data == 'true' ? 'black_tint' : ''">                 
                    <ng-container *ngIf="item?.BackgroundImageVideo?.data=='image'">
                         <img src="{{ staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl }}"
                              alt="{{item?.ImageAltText?.data}}" class="big_img--deskop" *ngIf="item?.UploadImageVideo?.document?.contentUrl"/>
                         <img src="{{ staticUrlForImgNew + item?.UploadMobileKMAImageVideo?.document?.contentUrl }}" alt="{{item?.ImageAltText?.data}}" class="big_img--mobile" *ngIf="item?.UploadMobileKMAImageVideo?.document?.contentUrl"/>
                    </ng-container>
                    <ng-container *ngIf="item?.BackgroundImageVideo?.data=='video'">
                         <video muted loop playsInline controlsList="nodownload" #video (canplay)="video.play()"
                              (loadedmetadata)="video.muted = true" class="kma_video_wrapper--video" *ngIf="screenWidth > 767"  poster="{{staticUrlForImgNew + getPlaceHolderImageForVideo()}}" autoplay>
                              <source src="{{getImageVideoUrl(item)}}" type="video/mp4">
                              Your browser does not support HTML5 video.
                         </video>
                         <video muted loop playsInline controlsList="nodownload" #video (canplay)="video.play()"
                              (loadedmetadata)="video.muted = true" class="kma_video_wrapper--video" *ngIf="screenWidth <= 767"  poster="{{staticUrlForImgNew + getPlaceHolderImageForVideo()}}" autoplay>
                              <source src="{{getMobileImageVideoUrl(item)}}" type="video/mp4">
                              Your browser does not support HTML5 video.
                         </video>
                         <!-- <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}" alt="{{item?.ImageAltText?.data}}" class="big_img--deskop" />
                         <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}" alt="{{item?.ImageAltText?.data}}" class="big_img--mobile" />
                         <div
                         class="section_heading section_heading--title section_heading--text text-center kma_text_wrapper ">
                         <a href="javascript:;" class="play_href watchVideoClick blue" [attr.data-url]=" staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl"
                              data-target="#video_box" data-toggle="modal">
                              <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
                         </a>
                              <h2 *ngIf="item?.Headline?.data" [innerHTML]="item?.Headline?.data" [ngClass]="{'mb-3':!item?.SubTitle?.data}"></h2>
                              <p *ngIf="item?.SubTitle?.data" class="mb-4" [innerHTML]="item?.SubTitle?.data"></p>     
                         </div> -->
                    </ng-container>
                    <ng-container
                         *ngIf="item?.BackgroundImageVideo?.data=='360 video' || item?.BackgroundImageVideo?.data=='360 image'">
                         <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}" alt="{{item?.ImageAltText?.data}}" class="big_img--deskop" />
                         <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}" alt="{{item?.ImageAltText?.data}}" class="big_img--mobile" />
                         <a *ngIf="item?.BackgroundImageVideo?.data=='360 video'" href="javascript:;"
                              class="jwc_360tour_btn play360" [attr.videosrc]="staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl">
                              <div class="jwc_360tour_btn--icon mb-0">
                                   <img src="./assets/images/360/360.svg" class="img-fluid" alt="360 icon">
                              </div>
                         </a>
                         <a *ngIf="item?.BackgroundImageVideo?.data=='360 image'" href="javascript:;"
                              class="jwc_360tour_btn play360" [attr.imgsrc]="staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl">
                              <div class="jwc_360tour_btn--icon mb-0">
                                   <img src="./assets/images/360/360.svg" class="img-fluid" alt="360 icon">
                              </div>
                         </a>
                    </ng-container>
                    <div class="section_heading section_heading--title section_heading--text text-center kma_text_wrapper ">
                         <h2 *ngIf="item?.Headline?.data" [innerHTML]="item?.Headline?.data" [ngClass]="{'mb-3':!item?.SubTitle?.data}"></h2>
                         <p *ngIf="item?.SubTitle?.data" class="mb-4" [innerHTML]="item?.SubTitle?.data"></p>
                         <div>
                              <ng-container *ngIf="item?.FirstHideCTA?.data =='false'">
                                   <a *ngIf="item?.FirstCTAType?.data =='link'" [href]="item?.FirstCTALink?.data" class="cta-prim color-gold dark mr-3 banner-btn" (click)="callNewBannerCTEvent('Section_Primary_Links', item?.FirstCTAText?.data); _sharedService.checkCTALink($event, item?.FirstCTALink?.data);">
                                        <span class="cta-prim-txt">{{item?.FirstCTAText?.data}}</span></a>
                                   <a *ngIf="item?.FirstCTAType?.data =='video'" [href]="item?.FirstCTALink?.data" class="watchVideoClick cta-prim color-gold dark banner-btn" data-target="#video_box" [attr.data-url]="staticUrlForImgNew + item?.FirstCTALink?.data" data-toggle="modal" (click)="callNewBannerCTEvent('Media_Links', item?.FirstCTAText?.data)">
                                        <span class="cta-prim-txt">{{item?.FirstCTAText?.data}}</span></a>
                              </ng-container>
                              <ng-container *ngIf="item?.SecondHideCTA?.data =='false'">
                                   <a *ngIf="item?.SecondCTAType?.data =='link'" [href]="item?.SecondCTALink?.data" class="cta-prim color-gold dark mr-3 banner-btn" (click)="callNewBannerCTEvent('Section_Primary_Links', item?.SecondCTAText?.data); _sharedService.checkCTALink($event, item?.SecondCTALink?.data);">
                                        <span class="cta-prim-txt">{{item?.SecondCTAText?.data}}</span></a>
                                   <a *ngIf="item?.SecondCTAType?.data =='video'" [href]="item?.SecondCTALink?.data" class="watchVideoClick cta-prim color-gold dark banner-btn" data-target="#video_box" [attr.data-url]="staticUrlForImgNew + item?.SecondCTALink?.data" data-toggle="modal" (click)="callNewBannerCTEvent('Media_Links', item?.SecondCTAText?.data)">
                                        <span class="cta-prim-txt">{{item?.SecondCTAText?.data}}</span></a>
                              </ng-container>
                         </div>
                    </div>   
               </div>
          </div>
          <div class="swiper-pagination"></div>
     </div>
</div>
<app-video-popup></app-video-popup>