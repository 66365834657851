import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { SharedService } from './shared.service';

interface LinkDefination {
   charset?: string;
   href?: string;
   media?: string,
   rel?: string,
   target?: string;
   [key: string]: string;
}


@Injectable({
  providedIn: 'root'
})
export class MetaService {

  schemaScript: any;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(private titleService: Title,
    private metaService: Meta,
    private domSanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document,
    private rendererFactory: RendererFactory2,
    private _sharedService: SharedService) { }

  updatePageTitle(title: string){
    this.titleService.setTitle(title);
  }

  updatePageTag(keyName: string, value: string){
    this.metaService.addTag({
      property: keyName,
      content: value
    })
    this.metaService.addTag({
      name: keyName,
      content: value
    })
  }

  updateCanonicalLink(tag: LinkDefination, forceCreation?: boolean){
    try {
       const renderer = this.rendererFactory.createRenderer(this.document, {
         id: '-1',
         encapsulation: ViewEncapsulation.None,
         styles: [],
         data: {}
       });
       const link = renderer.createElement('link');
       const head = this.document.head;
       const selector = this._parseSelector(tag);

       if(head === null){
         throw new Error('<head> tag is not found');
       }

       Object.keys(tag).forEach((prop: string) => {
         return renderer.setAttribute(link, prop, tag[prop]);
       })
       renderer.appendChild(head, link);

    } catch (err){
      console.log(err);
    }
  }
  
  _parseSelector(tag: LinkDefination){
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    return `${attr}="${tag[attr]}"`;
  }

  updatePageMeta(metaObj: {
    metaTitle: string,
    metaDesc?: string,
    script?: string,
    metaLogo?: string,
    canonicalUrl?: string
  }) {
    const metaLogo = this.staticUrlForImgNew + (metaObj.metaLogo ? metaObj?.metaLogo : '/assets/images/jio-logo.png');
    // console.log({
    //   metaObj: { ...metaObj, metaLogo}
    // });
    if (metaObj.metaTitle != '') {
      // console.log({metaTitle: metaObj.metaTitle});
      this.updatePageTag('title', metaObj.metaTitle);
      this.updatePageTag('og:title', metaObj.metaTitle);
      this.updatePageTitle(metaObj.metaTitle);
    }
    if (metaObj.metaDesc != '') {
      this.updatePageTag('description', metaObj.metaDesc);
      this.updatePageTag('og:description', metaObj.metaDesc);
    }
    if (metaObj.canonicalUrl != '') {
      this.updateCanonicalLink({ rel: 'canonical', href: metaObj.canonicalUrl });
    }
    if(metaObj.script && metaObj.script!= '')  
      this.schemaScript = this._sharedService.getSafeHTML(metaObj.script, false);
    this.updatePageTag('image', metaLogo);
    this.updatePageTag('og:image', metaLogo);
    this.updatePageTag('og:image:secure_url', metaLogo);
    this.updatePageTag('og:image:width', "256");
    this.updatePageTag('og:image:height', "256");
    this.updatePageTag('og:updated_time', (new Date()).getTime().toString());
  }
}
