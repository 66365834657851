import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-website-notification',
  templateUrl: './website-notification.component.html',
  styleUrls: ['./website-notification.component.css']
})
export class WebsiteNotificationComponent implements OnInit {
  @Input() notificationData;
  @Output() closeNotification = new EventEmitter<boolean>()
  @Output() closeExpiredNotification = new EventEmitter<boolean>()
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    // console.log("notificationData: ", this.notificationData)
    const datepipe: DatePipe = new DatePipe('en-US')
    let today=Date.now();
    let expiryDate = this.notificationData.data.ExpirationDate.data
    let formattedExpDate = datepipe.transform(expiryDate, 'YYYY-MM-dd')
    let expiryTime = this.notificationData.data.ExpirationTime.data
    let newformattedExpDateTime = new Date(formattedExpDate +' ' + expiryTime).getTime()
    if(newformattedExpDateTime < today) {
      this.closeExpiredNotification.emit(true)
    }
  }
  
  closeNotificationClicked(){
    this.closeNotification.emit(true)
  }
  callWebNotificationCTEvent() {
    this._sharedService.callCTEvent('Header_Notification', '', '', 'DAS-BE')
  }
}
