<section class="dhirubhai_ambani_wrapper" [ngStyle]="{'background-color': dasData?.ComponentBackgroundColor?.data}">

  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-4">
        <div class="section_heading " style="--data-titleColor:#212529;--data-lineColor:#C92C99">
          <div data-aos="fade-up" class="" *ngIf="dasData">
            <h2 class="section_heading--title">{{dasData?.ComponentHeaderTitle?.data}}</h2>
            <h5 class="section_heading--text"> {{dasData?.ComponentHeaderSubTitle?.data}}</h5>
          </div>
        </div>
        <a href="{{dasData?.CTALink?.data}}" class="cta-prim color-pink" *ngIf="dasData?.HideCTAText.data == 'false'" data-aos="fade-up"
        (click)="_sharedService.checkCTALink($event, dasData?.CTALink?.data, dasData?.CTALinkWindowPreference?.data, dasData?.TypeOfCTALink?.data)">
          <span class="cta-prim-txt">{{dasData?.CTAText?.data}}</span>
        </a>
      </div>
      <div class="col-md-8">
        <div class="bg_before_right" data-aos="fade-up" *ngIf="dasData">
          <img src="{{ staticUrlForImgNew + dasData.cards[0]?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="Dhirubhai Ambani Square">
        </div>
      </div>
    </div>
  </div>

</section>