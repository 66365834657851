<!-- <section class="covid2">
  <div class="container">
    <div class="section_heading">
      <div class="row">
        <div class="col-md-3">
          <h4 class="section_heading--title">{{covidPageData ? covidPageData?.Heading1?.data : 'Visit Safely'}}</h4>
        </div>
        <div class="col-md-7">
          <p *ngIf="page==='new-das'; else nonDasDesc" class="section_heading--text content3">Adults are required to carry proof of full vaccination.</p>
          <ng-template #nonDasDesc>
            <p class="section_heading--text content3">{{covidPageData ? covidPageData?.Heading2?.data : 'Safe, socially distanced experiences for all.'}} </p>
          </ng-template>          
        </div>
        <div class="col-md-2" data-aos="fade-up" [attr.data-aos-delay]="this.aosDelay ? this.aosDelay : 300">
          <div class="buttons">
            <a [href]="staticUrlForImgNew + watchVideoUrl" *ngIf="watchVideo; else goToCovidPage" class="cta-sec color-white watchVideoClick" data-target="#video_box" data-toggle="modal" [attr.data-url]="staticUrlForImgNew + watchVideoUrl"><span class="cta-sec-txt">LEARN MORE</span></a>
            <ng-template #goToCovidPage>
              <a routerLink="{{routerUrl}}" (click)="callCTVisitSafelyEvent()" class="cta-sec color-white"><span class="cta-sec-txt">LEARN MORE</span></a>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<app-video-popup></app-video-popup>
  