import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-da-square',
  templateUrl: './da-square.component.html',
  styleUrls: ['./da-square.component.css']
})
export class DaSquareComponent implements OnInit {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  @Input() dasData;
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {}

}
