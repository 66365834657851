<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <div class="downloadable_techspecs black_tint" *ngIf="aboutBannerData"
  [attr.style]="'--data-downloadable-desktop-img:url( ' + staticUrlForImgNew + aboutBannerData?.UploadImageVideo?.document?.contentUrl + '); --data-downloadable-mobile-img:url( ' +staticUrlForImgNew+ aboutBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl + ');--data-techspecs-textColor:#fff' | safe: 'style'">
    <section class="downloadable_techspecs_head">
      <div class="container">
        <div class="section_heading section_heading--title section_heading--text text-center fullwidth pb-0"
        [attr.style]="('--data-lineColor:#FFFFFF; --data-titleColor:#FFFFFF') | safe: 'style'">
            <h2 [innerHTML]="aboutBannerData?.Headline?.data"></h2>
            <p [innerHTML]="aboutBannerData?.SubTitle?.data"></p>
        </div>
      </div>
    </section>
  </div>

  <app-covid2-section *ngIf="aboutBannerData" [aosDelay]="450"></app-covid2-section>
  <section class="benefits_wrapper " *ngIf="globalDestinationData">
    <div class="container">
      <div class="row align-items-center flex-row-reverse">
        <div class="col-lg-5 col-md-5">
          <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
              <h2 [innerHTML]="globalDestinationData?.ComponentHeaderTitle?.data"></h2>
              <p [innerHTML]="globalDestinationData?.ComponentHeaderSubTitle?.data"></p>
          </div>
        </div>
        <div class="col-lg-7 col-md-7">
          <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#A67D54') | safe: 'style'">
            <img src="{{ staticUrlForImgNew + globalDestinationData.cards[0]?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{globalDestinationData?.cards[0]?.ImageAltText?.data}}">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="icons_block pt-0 mt-55" data-aos="fade-up">
      <div class="icons_block_wrapper">
          <div class="container">
              <div class="row">
                  <div class="col-md-4">
                      <div class="icons_block_wrapper_each">
                          <div class="icons_block_wrapper_each--icon">
                              <img src="./assets/images/about-icon-1.svg" class="img-fluid" alt="icon2">
                          </div>
                          <div class="icons_block_wrapper_each_copy">
                              <h2 class="icons_block_wrapper_each_copy--number">18.5 Acres</h2>
                              <p class="icons_block_wrapper_each_copy--name content2">Total Development Area</p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="icons_block_wrapper_each">
                          <div class="icons_block_wrapper_each--icon">
                              <img src="./assets/images/about-icon-3.svg" class="img-fluid" alt="icon1">
                          </div>
                          <div class="icons_block_wrapper_each_copy">
                              <h2 class="icons_block_wrapper_each_copy--number">5</h2>
                              <p class="icons_block_wrapper_each_copy--name content2">World-class Precincts </p>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="icons_block_wrapper_each">
                          <div class="icons_block_wrapper_each--icon">
                              <img src="./assets/images/about-icon-2.svg" class="img-fluid" alt="icon3">
                          </div>
                          <div class="icons_block_wrapper_each_copy">
                              <h2 class="icons_block_wrapper_each_copy--number">40,000</h2>
                              <p class="icons_block_wrapper_each_copy--name content2">Daily Estimated Visitors</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section> -->

  <section class="background_color" [attr.style]="('--data-background-color: ' + ourStoryData?.ComponentBackgroundColor?.data ) | safe: 'style'">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section_heading section_heading--title section_heading--text mw-100 fullwidth pb-4" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
            <h2 [innerHTML]="ourStoryData?.ComponentHeaderTitle?.data"></h2>
          </div>
          <p [innerHTML]="ourStoryData?.MainContent?.data"></p>
        </div>
      </div>
    </div>

  </section>


  <section class="awards" [attr.style]="('--data-awards-bg-color: ' + aboutAwardsData?.ComponentBackgroundColor?.data ) | safe: 'style'" *ngIf="aboutAwardsData">
    <div class="container">
      <div class="section_heading section_heading--title text-center" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
          <h2 [innerHTML]="aboutAwardsData?.ComponentHeaderTitle?.data"></h2>
      </div>
      <div class="awards_content">
        <div class="row">
          <div class="col-md-4" *ngFor="let item of aboutAwardsData?.cards">
            <div class="awards_content_each">
              <div class="awards_content_each--img">
                <img src="{{ staticUrlForImgNew + item?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
              </div>
              <h5 [innerHTML]="item?.CardHLText?.data" *ngIf="item?.CardHLText?.data"></h5>
              <p class="content3" [innerHTML]="item?.CardShortText?.data" *ngIf="item?.CardShortText?.data"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  <app-plan-big-event *ngIf="platinumData" [venueDetailsEventsData]="platinumData"
  [colorList]="aboutColorList" [pageName]="'home'"></app-plan-big-event>

  <!-- <section class="plan-your-next-big-event" style="--data-plan-bgColor:#F5F2ED; ">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div data-aos="fade-up">
            <div class="section_heading " style="--data-lineColor:#A67D54">
              <div class="">
                <h2 class="section_heading--title ">LEED Platinum Pre-certified </h2>


              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="accordian_with_slider">
            <div id="sidebar_accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link " data-toggle="collapse" data-target="#sidebar_collapseOne"
                      aria-expanded="true" aria-controls="sidebar_collapseOne">Landscape<span
                        class="icon-arrow-down"></span>
                    </button>
                  </h5>
                </div>

                <div id="sidebar_collapseOne" class="collapse" aria-labelledby="headingOne"
                  data-parent="#sidebar_accordion">
                  <div class="card-slider">
                    <div class="accordian_slider" data-interval="4">
                      <div class="swiper-container">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide">
                            <div class="accordian_slider_each">
                              <img src="./assets/images/leed-platinum-pre-certified-landscape.jpg" class="img-fluid" alt="slider 1">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-pagination"></div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="card-body_copy">
                      <p class="content3">We have secured the world’s leading green building rating,
                        and in-line with the government’s smart city mission, 20% of our total
                        building area is landscaped with over 700 native trees.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#sidebar_collapseTwo"
                      aria-expanded="false" aria-controls="sidebar_collapseTwo">
                      Recycling <span class="icon-arrow-down"></span>
                    </button>
                  </h5>
                </div>
                <div id="sidebar_collapseTwo" class="collapse" aria-labelledby="headingTwo"
                  data-parent="#sidebar_accordion">

                  <div class="card-slider">
                    <div class="accordian_slider" data-interval="4">
                      <div class="swiper-container">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide">
                            <div class="accordian_slider_each">
                              <img src="./assets/images/leed-platinum-pre-certified-recycling.jpg" class="img-fluid" alt="slider 1">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-pagination"></div>
                    </div>
                  </div>


                  <div class="card-body">
                    <div class="card-body_copy">
                      <p class="content3">Our building recycles almost 98% of our sewage water, uses
                        solar panels for hot water, and features an efficient waste management
                        system to reduce, reuse, recycle and recover waste.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#sidebar_collapseThree"
                      aria-expanded="false" aria-controls="sidebar_collapseThree"> Energy <span
                        class="icon-arrow-down"></span>
                    </button>
                  </h5>
                </div>
                <div id="sidebar_collapseThree" class="collapse" aria-labelledby="headingThree"
                  data-parent="#sidebar_accordion">
                  <div class="card-slider">
                    <div class="accordian_slider" data-interval="4">
                      <div class="swiper-container">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide">
                            <div class="accordian_slider_each">
                              <img src="./assets/images/leed-platinum-pre-certified-energy.jpg" class="img-fluid" alt="slider 1">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-pagination"></div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="card-body_copy">
                      <p class="content3">Our building is designed to allow 75% of all regularly
                        occupied areas to receive natural daylight, resulting in saving 40% energy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="benefits_wrapper pb-0" *ngIf="carersData">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-7">
          <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#A67D54') | safe: 'style'">
            <img src="{{ staticUrlForImgNew + carersData.cards[0]?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{globalDestinationData?.cards[0]?.ImageAltText?.data}}">
          </div>
        </div>
        <div class="col-lg-5 col-md-5">
          <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
              <h2 [innerHTML]="carersData?.ComponentHeaderTitle?.data"></h2>
              <p [innerHTML]="carersData?.ComponentHeaderSubTitle?.data"></p>
          </div>
          <div>
            <a *ngIf="carersData?.HideCTAText?.data =='false'" class="cta-prim color-gold" href="{{carersData?.CTALink?.data}}"
            (click)="callCtaPrimaryLinkEvent(); _sharedService.checkCTALink($event, carersData?.CTALink?.data, carersData?.CTALinkWindowPreference?.data)">
              <span class="cta-prim-txt">{{carersData?.CTAText?.data}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>


  </section>
  <div *ngIf="ourWorldData" data-mobile-header="Our World" id="section-2">
    <app-our-world [ourWorldData]="ourWorldData"></app-our-world>
  </div>
  <!-- <app-subscribe-section *ngIf="subscribeData" [subscribeData]="subscribeData.data" [colorList]="aboutColorList"
        [pageName]="'about-us'" [sectionClass]="'small_space'"></app-subscribe-section> -->
</main>
<!-- <app-footer></app-footer> -->