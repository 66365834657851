<form [formGroup]="careersForm" InvalidControlScroll>
  <div class="row">
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{inquiryType}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="inquiryTypeValue.emit(item)" *ngFor="let item of inquiryData?.inquiryType">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12"></div>
    <!-- <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{ selectedBrand ? selectedBrand : 'Jio World Centre' }}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('brand', item.value);brandValue.emit(item.value);" *ngFor="let item of inquiryData?.cards[0]?.brand">{{item.label}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">{{selectedRole? selectedRole :'Role'}}</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValueRole(Item.position,Item.jobId)" *ngFor="let Item of AllRoles">{{Item?.position}}
              </li>
              <li tabindex="0" (click)="OtherRoleSelected()">Other
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.role.errors">{{errorText}}</div>
    </div>
    <div class="col-md-6" *ngIf="careersForm.value.role=='Other'">
      <div class="jwc_form_group">
        <input type="email" name="" value="" placeholder="Enter the Role" class="form-control jwc_input"
        formControlName="others">
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.others.errors">{{errorText}}</div>
    </div>
    <div class="col-md-12">
      <div class="jwc_form_title">
        A few more details and you’ll be all set.
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="Name" class="form-control jwc_input"
          formControlName="nameInput" appAllowedInput [alphabetsOnly]="true">
      </div>
      <div *ngIf="(careersFormControls.nameInput.touched || submitted) && careersFormControls.nameInput.errors">
        <div *ngIf="careersFormControls.nameInput.errors.required" class="error-text">{{errorText}}</div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="email" name="" value="" placeholder="Email" class="form-control jwc_input"
          formControlName="emailInput">
      </div>
      <div *ngIf="(careersFormControls.emailInput.touched || submitted) && careersFormControls.emailInput.errors">
        <div *ngIf="careersFormControls.emailInput.errors.required" class="error-text">{{errorText}}</div>
        <div *ngIf="careersFormControls.emailInput.errors.pattern" class="error-text">Email must be a valid email
          address
        </div>
      </div>
    </div>
    <!-- Phone No -->
    <div class="col-md-6">
      <div class="jwc_form_group jwc_form_phn">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">+91</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="setDropdownValue('countryCode', item)" *ngFor="let item of generalArrData?.countryCode">
                {{item}}</li>
            </ul>
          </div>
        </div>
        <input type="text" name="" value="" placeholder="Phone Number" class="form-control jwc_input" id=""
          formControlName="phoneNoInput" maxlength="10" appAllowedInput [numbersOnly]="true">
      </div>
      <div class="error-text"
        *ngIf="(careersFormControls.phoneNoInput.touched || submitted) && careersFormControls.phoneNoInput.errors">
        Enter 10 digit Mobile Number</div>
    </div>
    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="text" name="" value="" placeholder="LinkedIn URL" class="form-control jwc_input"
          formControlName="urlInput">
      </div>
      <div *ngIf="(careersFormControls.urlInput.touched || submitted) && careersFormControls.urlInput.errors">
        <div *ngIf="careersFormControls.urlInput.errors.pattern" class="error-text">Enter valid url</div>
      </div>
    </div>



    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">Age</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="this.careersForm.get('age').setValue(Item)" *ngFor="let Item of AgeDropdown">
                {{Item}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.age.errors">{{errorText}}</div>
    </div>

    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">Gender</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="this.careersForm.get('gender').setValue(Item)" *ngFor="let Item of GenderDropdown">
                {{Item}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.gender.errors">{{errorText}}</div>
    </div>

    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">Qualification</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="this.careersForm.get('qualification').setValue(Item)" *ngFor="let Item of EducationDropdown">
                {{Item}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.qualification.errors">{{errorText}}</div>
    </div>

    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">Experience</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <ul>
              <li tabindex="0" (click)="this.careersForm.get('experience').setValue(Item)" *ngFor="let Item of ExperienceDropdown">
                {{Item}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.experience.errors">{{errorText}}</div>
    </div>

    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select">State</div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <input class="col-md-12 serchText" placeholder="Search" type="text" [(ngModel)]="serchText" [ngModelOptions]="{standalone: true}" (ngModelChange)="filterLocation('state')">
            <ul>
              <span *ngIf="filterdState?.length==0">
                <li tabindex="0" (click)="setState('state',Item.state)" *ngFor="let Item of LocationDropdown">
                  {{Item.state}}
                </li>
              </span>
             <span *ngIf="filterdState?.length!=0">
              <li tabindex="0" (click)="setState('state',Item.state)" *ngFor="let Item of filterdState">
                {{Item.state}}
              </li>
             </span>
            </ul>
          </div>
        </div>
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.currentState.errors">{{errorText}}</div>
    </div>

    <div class="col-md-6">
      <div class="jwc_form_group">
        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
          <div class="dropdown--select" [innerHTML]="careersForm.value.currentCity ? careersForm.value.currentCity :'City'"></div>
          <div class="dropdown--list" aria-expanded="false" role="list">
            <input class="col-md-12 serchText" placeholder="Search" type="text" [(ngModel)]="serchText" [ngModelOptions]="{standalone: true}" (ngModelChange)="filterLocation('city')">
            <ul>
              <span *ngIf="filterdCity?.length==0">
                <li tabindex="0" (click)="setState('city',Item)" *ngFor="let Item of LocationDropdown[index]?.cities">
                  {{Item}}
                </li>
              </span>
              <span *ngIf="filterdCity?.length!=0">
                <li tabindex="0" (click)="setState('city',Item)" *ngFor="let Item of filterdCity">
                  {{Item}}
                </li>
              </span>
            </ul>
          </div>
        </div>
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.currentCity.errors">{{errorText}}</div>
    </div>

    <div class="col-md-6">
      <div class="jwc_form_group">
        <input type="email" name="" value="" placeholder="Current Organisation" class="form-control jwc_input"
        formControlName="currentOrganisation">
      </div>
    </div>

    
    <div class="col-md-12">
      <div class="jwc_form_title">
        Attach Resume (docx/doc/pdf)(Size 5MB)
      </div>
    </div>
    <div class="col-lg-6">
      <div class="jwc_form_group jwc_file_group">
        <!-- <label for="file">Choose your file</label> -->
        <!-- <input type="file" id="file" class="jwc_file form-control"> -->
        <input type="file" formControlName="fileInput" (change)="handleFileInput($event.target.files)">
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.fileInput.errors">{{errorText}}</div>
      <div class="error-text" *ngIf="fileTypeError">{{fileTypeError}}</div>
      <div class="error-text" *ngIf="fileSizeError">{{fileSizeError}}</div>
    </div>

    <div class="col-md-12">
      <div class="jwc_form_group">
        <div class="jwc_form_title">Cover Letter</div>
        <textarea name="" class="form-control jwc_textarea" placeholder="Message" cols="30" rows="10"
          formControlName="messageInput"></textarea>
      </div>
    </div>
    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input type="checkbox" class="custom-control-input" id="dontmiss" name="dontmiss" formControlName="firstConsent">
        <label class="custom-control-label consent" for="dontmiss">
          <span class="text-danger">* </span>
          <span class="ml-1">
            I have read, understood, and accept the Jio World Centre <a href="{{'/legal?tab=privacy'}}" 
            (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=privacy', '_blank', 'External')">
            Privacy Policy </a>and consent to the sharing of my personal data for the purpose of applying for jobs at JWC or NMACC. 
            I agree that my data is shared with hiring managers considering for potential future purposes.
          </span>
        </label>
      </div>
      <div class="error-text" *ngIf="submitted && careersFormControls.firstConsent.errors">{{errorText}}</div>
    </div>

    <div class="col-md-12">
      <div class="custom-control custom-checkbox checkbox jwc_form_group">
        <input class="custom-control-input" type="checkbox" id="gridCheckForSecondConsent" formControlName="secondConsent">
        <label class="custom-control-label" for="gridCheckForSecondConsent">
            I agree to receive communications regarding personalized services and other relevant 
            information about Jio World Centre and its affiliated services.
        </label>
      </div>
    </div>
    <div class="col-md-12 captcha">
      <re-captcha (resolved)="captchaRes($event)" [siteKey]="captchaSiteKey" (error)="captchaRes($event, true)"
        errorMode="handled"></re-captcha>
      <div class="error-text" style="margin-top:5px" *ngIf="submitted && !isCaptchaSelected">{{errorText}}</div>
    </div>
  </div>
  <div class="jwc_form_group--btn">
    <button type = "submit" [ngClass]="{'disabled':disableBtn}" [disabled] = "disableBtn" class="cta-prim color-gold" (click)="careersFormSubmit(careersForm)"><span
        class="cta-prim-txt">Submit</span></button>
  </div>
  <div class="error-text" style="margin-top:5px" *ngIf="apiErrorMsg">{{apiErrorMsg}}</div>
</form>