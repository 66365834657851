import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-jwc-moments-section',
  templateUrl: './jwc-moments-section.component.html',
  styleUrls: ['./jwc-moments-section.component.css']
})
export class JwcMomentsSectionComponent implements OnInit {
  @Input() momentsData;
  @Input() colorList;
  instaFeedUrl: string = environment.instaFeedUrl;
  instaFeedData;
  dataLoaded: boolean = false;
  colorClass: Map<string, string>;
  handleName;
  hashTag;
  constructor(public _sharedService: SharedService) {
    this.colorClass = new Map([
      ["#1E478E", "color-blue"],
      ["#C69E67", "color-gold"],
      ["#C92C99", "color-pink"],
      ["#43B02A", "color-green"]
    ]);
  }

  ngOnInit() {
    if (this.momentsData) {
      this.handleName = this.momentsData.InstagramHandleName.data;
      this.hashTag = this.momentsData.InstagramHandleHashtag.data;
      this.getInstaFeedData();
    }
  }

  getInstaFeedData() { 
    jQuery.instagramFeed({
      'username': this.handleName,
      'tag': this.hashTag,
      'display_profile': false,
      'display_gallery': true,
      'styling': false,
      'lazy_load': true,
      'callback': (data) => {
        jQuery().html(JSON.stringify(data));
        this.instaFeedData = data.edge_owner_to_timeline_media.edges;
        this.dataLoaded = true;
        this._sharedService.callFractionSlider();
      }
    });
  }
}
