<section class="news_letter_3"
  [attr.style]="('--data-newsletter-bgColor1: ' + subscribeData?.ComponentBgColor?.data + ';--data-newsletter-bgColor2: ' + subscribeData?.ComponentBgColor?.data + ';--data-newsletter-dotColor:' + colorList?.subscribe2DotColor + '; --data-newsletter-borderColor:' + colorList?.subscribe2BorderColor) | safe: 'style'">
  <div class="container">
    <div class="section_heading text-center section_heading--title"
      [attr.style]="('--data-titleColor: ' + colorList?.subscribe2TitleColor + ';--data-lineColor: ' + colorList?.subscribe2LineColor) | safe: 'style'">
      <div class="">
        <h2 [innerHTML]="subscribeData?.ComponentHeaderTitle?.data"></h2>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="input-box">
      <form #subscriptionForm="ngForm" novalidate>
        <label for="subscribe-field" *ngIf="!subscribeData?.HideEmailInputField?.data"
          class="sr-only text-white">{{subscribeData?.EmailInputField?.data}}</label>
        <input id="subscribe-field" type="text" placeholder="{{subscribeData?.EmailInputField?.data}}"
          class="input-field" name="email" #email="ngModel" ngModel email required>
        <button class="cta-prim emp-btn" (click)="onSubscribeToWorld(subscriptionForm)"
          [ngClass]="buttonColorClass.get(pageName)">
          <!-- [attr.style]="('--data-button-color: ' + colorList?.primaryColor) | safe: 'style'" -->
          <span class="cta-prim-txt">{{subscribeData?.CTAText?.data}}
          </span>
        </button>
        <div [ngClass]="(!showSub2bMsg) ? 'd-none': ''" *ngIf="sub2SuccessMessage" class="error-txt success-txt">
          {{sub2SuccessMessage}}</div>
        <div [ngClass]="(!showSub2bMsg) ? 'd-none': ''" *ngIf="sub2ApiErrorMessage" class="error-txt">
          {{sub2ApiErrorMessage}}</div>
        <div *ngIf="sub2ErrorMessage" class="error-txt">{{sub2ErrorMessage}}</div>
      </form>
    </div>
  </div>

</section>