<div id="website_notification" class="open">
  <div class="container">
      <div class="row">
          <div class="col-lg-12 content3">
              <p> 
                  <span innerHTML="{{notificationData?.data?.NotificationText?.data}}"></span>
                  <a *ngIf="notificationData?.data?.HideCTA?.data==='false'" href="{{notificationData?.data?.CTALink?.data}}" (click)="callWebNotificationCTEvent(); _sharedService.checkCTALink($event, notificationData?.data?.CTALink?.data, notificationData?.data?.CTAWindowPreference?.data)" class="cta-sec color-white"><span class="cta-sec-txt">&nbsp;{{notificationData?.data?.CTAText?.data}}</span></a> 
              </p>
          </div>
      </div>
  </div>
  <a href="javascript:;" (click)="closeNotificationClicked()" class="close_website_notification"></a>
</div>