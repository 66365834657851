<section class="news_wrapper plr-0 pbottom-0 pb-0"
  [attr.style]="('--data-news-bgColor1: ' + newsData?.ComponentBackgroundColor?.data + ';--data-news-bgColor2: ' + newsData?.ComponentBackgroundColor?.data + '; --data-news-dateColor: ' + colorList?.newsDateColor + '; --data-news-copyColor: ' + colorList?.newsCopyColor) | safe: 'style'"
  *ngIf="newsData">
  <div class="container">
    <div class="section_heading section_heading--title text-center"
      [attr.style]="'--data-lineColor: ' + colorList?.newsDataLineColor + '; --data-titleColor: ' + colorList?.newsDataTitleColor | safe: 'style'">
        <h2 [innerHTML]="newsData?.ComponentHeaderTitle?.data"></h2>
    </div>
    <div class="news_slider jwc_swiper_normal4" data-space="24" data-slides="4" data-ipad-slides="3.2"
      data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20" data-interval="5">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of newsData?.cards; let i = index">
            <div class="news_wrapper_each">
              <div class="news_wrapper_each--img">
                <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                  alt="highlight {{i+1}}">
              </div>
              <div class="news_wrapper_each_copy">
                <span class="news_wrapper_each_copy--date">{{item?.Date1otb?.data | date: 'MMM d / y'}}</span>
                <h5 class="news_wrapper_each_copy--text">{{item?.CardShortText?.data}}</h5>
                <div class="news_wrapper_each_copy--link">
                  <a *ngIf="item?.HideCTAText?.data =='false'" class="cta-sec"
                    [ngClass]="colorClass.get(colorList?.newsDateColor)" href="{{item?.CTALink?.data}}"
                    (click)="_sharedService.checkCTALink($event, item?.CTALink?.data, item?.WindowPreference?.data, item?.TypeOfCTALink?.data)">
                    <span class="cta-sec-txt">{{item?.CTAText?.data}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="news_wrapper--all">
      <a *ngIf="newsData?.HideViewAllCTAText?.data=='false'" class="cta-sec" href="{{newsData?.ViewAllCTALink?.data}}"
        [ngStyle]="{'color': colorList?.newsDataTitleColor}" [ngClass]="colorClass.get(colorList?.newsViewAllLineColor)"
        (click)="_sharedService.checkCTALink($event, newsData?.ViewAllCTALink?.data, newsData?.ViewAllWindowPreference?.data, newsData?.TypeOfViewAllCTALink?.data)">
        <span class="cta-sec-txt">{{newsData?.ViewAllCTAText?.data}}</span>
      </a>
    </div>
  </div>
</section>