import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-guest-guide',
  templateUrl: './guest-guide.component.html',
  styleUrls: ['./guest-guide.component.css']
})
export class GuestGuideComponent implements OnInit {

  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
  }

}
