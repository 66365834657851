import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-garden-safety',
  templateUrl: './garden-safety.component.html',
  styleUrls: ['./garden-safety.component.css']
})
export class GardenSafetyComponent implements OnInit {
  subscription;
  gardenSafetyData;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this.getGardenSafteyPageContent();
  }

  getGardenSafteyPageContent() {
    let keyList = [
      'JWG-COVID-JIOWORLD-GARDEN-SAFELY', // Hero Banner
      
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
      (data: any) => {
        // console.log("All Data: ",data)
        this.gardenSafetyData = data[0] ? data[0].data : data[0];
        this._sharedService.initializeCustomJs();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )

  }

}
