import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-downloadable-tech-specs',
  templateUrl: './downloadable-tech-specs.component.html',
  styleUrls: ['./downloadable-tech-specs.component.css']
})
export class DownloadableTechSpecsComponent implements OnInit {
  private subscription;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  techSpecEventPlanningData: any;
  techSpecPlanEventData: any;
  techSpecDocsData: any;
  techSpecDocsJsonData: any;
  downloadableSpecsColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor: "#976841"
  }
  downloadableTechSpecKeyArray = [];
  pdfName: any;
  downloadableSpecsSubHeaderList: string[];
  pageName;

  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getDownloadsKeys();
      }
    });
  }

  ngOnInit() {
    this.getArchivePageContent();
    this.getCecDownloadsJson();
  }
  getDownloadsKeys() {
    switch (this.activatedRoute.snapshot.paramMap.get('brand')) {
      case 'jio-world-convention-centre':
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'CEC Downloads';
        this.pageName = 'cec';
        return this.downloadableTechSpecKeyArray = [
          'DOWNLOADABLE-TECH-SPECS-EVENT-PLANNING-TOOLS',
          'DOWNLOADABLE-TECH-SPECS-PLAN-YOUR-EVENT',
          'DOWNLOADABLE-TECH-SPECS-DOCUMENTS'
        ];
      case 'jio-world-garden':
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'JWG Downloads';
        this.pageName = 'garden';
        return this.downloadableTechSpecKeyArray = [
          174604, //JWG - Downloadable Tech Specs - Event Planning Tools
          174638, //JWG - Downloadable Tech Specs - Plan your Event
          174648 //JWG - Downloadable Tech Specs - Documents
        ];
    }
  }
  getArchivePageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.downloadableTechSpecKeyArray).subscribe(
      (data: any) => {
        console.log("All Data: ",data)
        this.techSpecEventPlanningData = data[0] ? data[0].data : data[0];
        // console.log(this.techSpecEventPlanningData)
        this.techSpecPlanEventData = data[1] ? data[1].data : data[1];
        this.techSpecDocsData = data[2] ? data[2].data : data[2];
        this._sharedService.initializeCustomJs();
        this.downloadableSpecsSubHeaderList = (this.techSpecPlanEventData.HideComponent.data == 'false') ? ["Downloads", "Unique Features"] : ["Downloads"]
        this.downloadableSpecsSubHeaderList = this._sharedService.subHeaderItems(this.downloadableSpecsSubHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )

  }
  getCecDownloadsJson() {
    this._sharedService.getCommonService().getCecDownloadsData().subscribe(
      (data: any) => {
        this.techSpecDocsJsonData = data.data;
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  downloadTechCTEvent(btn, sectionTitle, pdfValue?, linkClicked?) {
    switch (btn) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle, '', linkClicked);
        break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(pdfValue);
        this._sharedService.callCTEvent('Download_Links', sectionTitle, this.pdfName);
        break;
    }
  }

}
