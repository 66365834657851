import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-nacc-plan-itinerary',
  templateUrl: './nacc-plan-itinerary.component.html',
  styleUrls: ['./nacc-plan-itinerary.component.css']
})
export class NaccPlanItineraryComponent implements OnInit {
  @Input() planItinerary;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
  }

}
