import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment.qa';


@Component({
  selector: 'app-news-section',
  templateUrl: './news-section.component.html',
  styleUrls: ['./news-section.component.css']
})
export class NewsSectionComponent implements OnInit {
  @Input() newsData;
  @Input() colorList;
  staticUrlForImgNew: string = environment.staticUrlForImgNew; 
  colorClass: Map<string, string>;
  constructor(public _sharedService: SharedService) {
    this.colorClass = new Map([
      ["#004EA8", "color-blue"],
      ["#AF8F63", "color-gold"],
      ["#F4F4F4", "color-white"],
      ["#43B02A", "color-green"]
    ]);
  }

  ngOnInit() {
  }
}
