<!-- <app-header [colorList]="inquiryColorList"></app-header> -->
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <section class="thankyou " [attr.style]="('--data-getintouch-bgColor:#F5F2ED') | safe: 'style'" data-aos="fade-up">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#AF8F63') | safe: 'style'">
          <h2>Thank you for submitting your enquiry.</h2>
          <p>Your request ID is {{inqId}}. Our team will get back to you shortly.</p>
          <!-- <p>For any other queries, please write to:</p>
          <p><b>Exhibitor Services: 99999@99999</b></p>
          <p><b>Press & Media: 88888@88888</b></p>
          <p><b>Careers: 77777@77777</b></p> -->
      </div>
      <div>
        <a [routerLink]="(previousPage ==='new-das') ? '/das'  : '/'" class="cta-prim color-gold"><span class="cta-prim-txt">Back to home</span></a>
      </div>
    </div>
  </section>
</main>
<!-- <app-footer [colorList]="inquiryColorList"></app-footer> -->
<div class="overlay"></div>