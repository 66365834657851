import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { constants } from 'src/app/shared/shared.constant';
import { environment } from 'src/environments/environment';
declare function hideSecnavbar(): any;
declare function removeSecnavbarClass(): any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent implements OnInit, OnDestroy {
  signinForm: FormGroup;
  confirmSigninForm: FormGroup;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  isMobile: boolean = true;
  isEmail: boolean = false;
  isFirstLoginScreen: boolean = true;
  isMobileOtp: boolean = false;
  isEmailOtp: boolean = false;
  isMobilePassword: boolean = false;
  isEmailPassword: boolean = false;
  isRegisterScreen: boolean = false;
  // isEditable: boolean = false;
  signInColorList = { primaryColor: '#A67D54', subscribeLineColor: "#A67D54", subscribeTextColor: "#4B4847", headerDataHoverColor: "#A67D54" };
  signInSubHeaderList = [];
  pageName: any;
  signInSliderImgArray: any = [];
  subscription: any;
  countryCode = constants.countryCodes;
  errorText = 'Field is required';
  showLoginErr = false;
  timerSubscribtion: any;
  timeLeft: number = 10;
  min;
  sec;
  showLoginErrMessage: any;
  otpResent = true;
  submitted = false;
  loginTimerData: any = { 'min': '00', 'sec': '00' };
  prevUrl: any;
  isSticky = true;
  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private router: Router) {
    this.pageName = this._sharedService.getPreviousRouteData();
    let url = this._sharedService.getPreviousUrl();
    this.prevUrl = url ? url.url : '';
    if (url) this.isSticky = url.state.root.firstChild.data.sticky ? url.state.root.firstChild.data.sticky : false;
    this._sharedService.getCommonService().loginTimerData.subscribe(
      (data) => {
        this.loginTimerData = data;
        if (this.loginTimerData.min === '00' && this.loginTimerData.sec === '00') this.otpResent = false;
      }
    );
  }
  
  ngOnInit(): void {
    this.getSliderImages();
    // this.isEditable = false;
    this.createSigninForm();
  }
  createSigninForm() {
    this.signinForm = this.fb.group({
      emailInput: ['', [Validators.pattern(constants.EMAIL_REGEX)]],
      mobileInput: ['', [Validators.required, Validators.minLength(10)]],
      countryCode: ['+91']
    })
  }
  get signinFormControls() { return this.signinForm.controls; }

  createConfirmSigninForm() {
    this.confirmSigninForm = this.fb.group({
      emailInput: [this.signinFormControls.emailInput.value ? this.signinFormControls.emailInput.value : '', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      mobileInput: [this.signinFormControls.mobileInput.value ? this.signinFormControls.mobileInput.value : '', [Validators.required, Validators.minLength(10)]],
      countryCode: ['+91'],
      otpInput: ['', [Validators.required, Validators.minLength(6)]]
    })
  }
  get confirmSigninFormControls() { return this.confirmSigninForm.controls; }
  setDropdownValue(formControlname, value) {
    this.signinForm.get(formControlname).setValue(value);
  }
  getSliderImages() {
    this.subscription = this._sharedService.getCommonService().getlandingData(292931).subscribe(
      (data: any) => {
        let tempArr;
        tempArr = data ? data.data ? data.data.cards : '' : '';
        if (tempArr.length != 0) tempArr.forEach((element) => {
          let newEl = element.SelectBrand.data.substring(
            element.SelectBrand.data.indexOf("\"") + 1,
            element.SelectBrand.data.lastIndexOf("\"")
          );
          if (newEl === this.pageName) {
            this.signInSliderImgArray.push(element);
          } else {
            if (newEl === 'cec') {
              this.signInSliderImgArray.push(element);
            }
          }
        });
        this._sharedService.initializeCustomJs();
        this._sharedService.callCustomDropdown();
        hideSecnavbar();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  showEmailInput() {
    this.isEmail = true;
    this.isMobile = false;
    this.showLoginErr = false;
    this.loginTimerData.min = this.loginTimerData.sec = '00';
    this.resetValidatorsForEmail(this.signinForm);
    this.signinForm.updateValueAndValidity();//update validation
  }
  showMobileInput() {
    this.isEmail = false;
    this.isMobile = true;
    this.showLoginErr = false;
    this.loginTimerData.min = this.loginTimerData.sec = '00';
    this._sharedService.callCustomDropdown();
    this.resetValidatorsForMobile(this.signinForm);
    this.signinForm.updateValueAndValidity();//update validation
  }
  showOtpScreen(signinForm, formName) {
    this.submitted = true;
    this.otpResent = true;
    // console.log("signinForm: ", signinForm);
    if (formName === 'signinForm') {
      // alert("in signinForm")
      if (signinForm.invalid) return;
      else {
        this.sendMobileDetails(signinForm);
        // alert("in signinForm sendMobileDetails")
      }
    } else {
      // alert("in confirmSigninFormfewfwefwe")
      if (this.isMobileOtp && this.isMobile) {
        this.resetValidatorsForMobile(this.confirmSigninForm);
      } else {
        this.resetValidatorsForEmail(this.confirmSigninForm);
      }
      this.confirmSigninForm.get("otpInput").setValidators(Validators.minLength(6));//remove required validation
      this.confirmSigninForm.get("otpInput").setErrors(null);//updating error message
      this.confirmSigninForm.updateValueAndValidity();//update validation
      if (signinForm.invalid) return;
      else {
        this.sendMobileDetails(signinForm);
      }
    }
  }
  sendMobileDetails(form) {
    if (this._sharedService.getCommonService().timerSub) this._sharedService.getCommonService().timerSub.unsubscribe();
    let obj = {
      "emilId": (this.isEmail) ? form.controls.emailInput.value : '',
      "mobileNumber": (this.isMobile) ? form.controls.mobileInput.value : ''
    }
    this._sharedService.getCommonService().submitMobileDetails(obj).subscribe((data: any) => {
      this.isMobileOtp = true;
      this.isEmailOtp = true;
      this.isFirstLoginScreen = false;
      this.showLoginErr = false;
      this._sharedService.callCustomDropdown();
      this._sharedService.getCommonService().startTimer();
      // alert("startTimer started");
      // this.startTimer();
      this.createConfirmSigninForm();
    },
      (error) => {
        this.otpResent = false;
        this.showLoginErr = true;
        this.showLoginErrMessage = error.error.message ? error.error.message : `Server Error. Try again after some time..`;
        console.log("Try after some time..");
      }
    )
  }
  // startSignInTimer() {
  //   const sourceT = timer(1000, 1000);
  //   let timerValT;
  //   this.timerSubscribtion = sourceT.subscribe(time => {
  //     timerValT = this.timeLeft - time;
  //     if (time < 11) {
  //       this.min = (Math.floor(timerValT / 60) >= 10) ? Math.floor(timerValT / 60) : '0' + Math.floor(timerValT / 60);
  //       this.sec = (timerValT % 60 >= 10) ? timerValT % 60 : '0' + timerValT % 60;
  //     } else {
  //       this.otpResent = false;
  //       this.timerSubscribtion.unsubscribe();
  //     }
  //   });
  // }
  resendOtp() {
    this.otpResent = true;
    this.showLoginErr = false;
    if (this.confirmSigninFormControls.mobileInput.value || this.confirmSigninFormControls.emailInput.value) this.showOtpScreen(this.confirmSigninForm, 'confirmSigninForm');
  }
  showRegisterScreen() {
    this.isRegisterScreen = true;
    this.isMobileOtp = false;
    this.isEmailOtp = false;
    this.isFirstLoginScreen = false;
    // this.isEditable = false;
    this.showLoginErr = false;
    if (this._sharedService.getCommonService().timerSub) this._sharedService.getCommonService().timerSub.unsubscribe()
  }
  onInputEdit() {
    this.isFirstLoginScreen = true;
    this.isMobileOtp = false;
    this.isEmailOtp = false;
    if (this.isMobile) {
      this.isMobile = true;
      this.isEmail = false;
    } else {
      this.isMobile = false;
      this.isEmail = true;
    }
  }
  signInClicked() {
    this.isFirstLoginScreen = true;
    this.isRegisterScreen = false;
    this._sharedService.callCustomDropdown();
  }
  onSignIn() {
    this.submitted = true;
    this.showLoginErr = false;
    if (this.isMobileOtp && this.isMobile) {
      this.resetValidatorsForMobile(this.confirmSigninForm);
    } else {
      this.resetValidatorsForEmail(this.confirmSigninForm);
    }
    this.confirmSigninForm.get("otpInput").setValidators([Validators.required, Validators.minLength(6)]);//add required validation    
    if (this.confirmSigninForm.get("otpInput").value.length == 0) {
      this.confirmSigninForm.get("otpInput").setErrors({ required: true });//updating error message
    } else if (this.confirmSigninForm.get("otpInput").value.length > 0 && this.confirmSigninForm.get("otpInput").value.length < 6) {
      this.confirmSigninForm.get("otpInput").setErrors({ minlength: true });//updating error message
    } else {
      this.confirmSigninForm.get("otpInput").setErrors(null);//updating error message
    }
    this.confirmSigninForm.updateValueAndValidity();//update validation
    if (this.confirmSigninForm.invalid) return;
    else {
      let obj = {
        // "countryCode": this.confirmSigninFormControls.countryCode.value,
        "emailId": (this.isEmail) ? this.confirmSigninFormControls.emailInput.value : '',
        "mobileNumber": (this.isMobile) ? this.confirmSigninFormControls.mobileInput.value : '',
        "otp": +this.confirmSigninFormControls.otpInput.value
      }
      this._sharedService.getCommonService().validateOtp(obj).subscribe((data: any) => {
        this.showLoginErr = true;
        this.showLoginErrMessage = data ? data.message : `Signed in successfully`;
        let userDetails = {
          firstName: data.first_name ? data.first_name : 'Test',
          lastName: data.last_name ? data.last_name : 'User',
          email: (this.isEmail) ? this.confirmSigninFormControls.emailInput.value : '',
          mobileNo: (this.isMobile) ? this.confirmSigninFormControls.mobileInput.value : ''
        }
        this.router.navigate([this.prevUrl]);
        this._sharedService.getStorageService().setSessionData('isLoggedIn', true);
        this._sharedService.getStorageService().setSessionData('userDetails', JSON.stringify(userDetails));
        // if (this._sharedService.getCommonService().timerSub) this._sharedService.getCommonService().timerSub.unsubscribe();
      },
        (error) => {
          this.showLoginErr = true;
          this.showLoginErrMessage = error ? error.error.message : `Server Error. Try again after some time..`;
          console.log('Try after some time');
        }
      )
    }
  }
  socialLoginTabClick() {
    this.signinForm.reset();
  }
  resetValidatorsForMobile(form) {
    form.get("mobileInput").setValidators([Validators.required, Validators.minLength(10)]);
    form.get("emailInput").setValidators(Validators.pattern(constants.EMAIL_REGEX));//remove required validation
    form.get("emailInput").setErrors(null);//updating error message
  }
  resetValidatorsForEmail(form) {
    form.get("emailInput").setValidators([Validators.required, Validators.pattern(constants.EMAIL_REGEX)]);
    form.get("mobileInput").setValidators(Validators.minLength(10));//remove required validation
    form.get("mobileInput").setErrors(null);//updating error message
  }
  ngOnDestroy() {
    removeSecnavbarClass();
    if (this._sharedService.getCommonService().timerSub) this._sharedService.getCommonService().timerSub.unsubscribe()
  }
}

