<section class="benefits_wrapper multilineText pt-0" [id]="meetingRoomPackageData?.LinkID?.data" data-mobile-header="About" *ngIf="meetingRoomPackageData?.HideComponent?.data !== 'true'">
    <div class="container">
      <div class="row" *ngIf="meetingRoomPackageData">
        <div class="col-lg-6 col-md-6">
          <div class="section_heading multilineText mb-30 section_heading--title section_heading--text content2" [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor:#004EA8') | safe: 'style'">
            <div class="">
              <h2 [innerHTML]="meetingRoomPackageData?.ComponentHeaderTitle?.data"></h2>
              <p class="para-subheader"><span [innerHtml]="subHeaderText"></span>
                <!-- <span *ngIf="meetingRoomPackageData?.ComponentHeaderSubTitle?.data?.length > showWordCount" #readMore (click)="showHideReadMoreBtn()" class="readMore">{{staticText.readMore}}</span> -->
              </p>
              <div class="btns">
                <a (click)="callPackageCTEventForCTA(meetingRoomPackageData, 'cta'); _sharedService.checkCTALink($event, meetingRoomPackageData?.CTALink?.data, meetingRoomPackageData?.CTALinkWindowPreference?.data, meetingRoomPackageData?.TypeOfCTALink?.data);"
                        class="cta-prim color-blue" href="{{meetingRoomPackageData?.CTALink?.data}}"
                        *ngIf="meetingRoomPackageData?.HideCTAText.data == 'false'">
                        <span class="cta-prim-txt">{{meetingRoomPackageData?.CTAText?.data}}</span>
                </a>
                <a (click)="callPackageCTEventForCTA(meetingRoomPackageData, 'cta2'); _sharedService.checkCTALink($event, meetingRoomPackageData?.CTA2Link?.data, meetingRoomPackageData?.CTA2LinkWindowPreference?.data, meetingRoomPackageData?.TypeOfCTA2Link?.data)"
                  class="cta-prim color-blue" href="{{meetingRoomPackageData?.CTA2Link?.data}}"
                  *ngIf="meetingRoomPackageData?.HideCTA2Text.data == 'false'">
                  <span class="cta-prim-txt">{{meetingRoomPackageData?.CTA2Text?.data}}</span>
                </a>
              </div>
              
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="bg_before_right" [attr.style]="('--data-bglayer-color:#004EA8') | safe: 'style'">
            <img src="{{staticUrlForImgNew + meetingRoomPackageData?.cards[0]?.CardImage?.image?.contentUrl}}" class="img-fluid"
              alt="{{meetingRoomPackageData?.cards[0]?.ImageAltText?.data}}">
          </div>
        </div>
      </div>
      
    </div>
  </section>