<section class="full_width experiences_wrapper" [ngClass]="sectionClass" [attr.style]="('--data-experiences-bgColor1: ' + experiencesData?.ComponentBackgroundColor?.data + ';--data-experiences-bgColor2: ' + experiencesData?.ComponentBackgroundColor?.data  + ';--data-experiences-titleColor: ' + colorList?.experiencesDataTitleColor) | safe: 'style'" *ngIf="experiencesData">
  <div class="container"></div>
  <div class="section_heading section_heading--title section_heading--text" [attr.style]="'--data-lineColor: ' + colorList?.experiencesDataLineColor + '; --data-titleColor: ' + colorList?.experiencesDataTitleColor | safe: 'style'" [ngClass]="{'join_community': isNMACC}">
          <h2 [innerHTML]="experiencesData?.ComponentHeaderTitle?.data"></h2>
          <p [innerHTML]="experiencesData?.ComponentHeaderSubTitle?.data" role="heading"></p>
  </div>
  <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink join_community--view-calendar" data-aos="fade-up" *ngIf="isNMACC">
    <span class="cta-sec-txt">VIEW CALENDAR</span>
  </a>
  <div class="experiences_slider swiper_fraction jwc_swiper_fraction" [ngClass]="experiencesSwiperData?.experiencesSliderName" data-interval="7" data-space="90" data-slides="1.8" data-ipad-slides="1.2">
      <div class="swiper-container">
          <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let item of experiencesData?.cards; let i=index">
                  <div class="experiences_slider_each" [attr.style]="('--data-opacity: 0.5;') | safe: 'style'">
                    <div class="experiences_slider_each--video" *ngIf="item?.UploadVideoInMP4Format?.document?.contentUrl; else showImage">
                        <video autoplay muted loop playsInline #video (canplay)="video.play()" (loadedmetadata)="video.muted = true">
                            <source src="{{ staticUrlForImgNew + item?.UploadVideoInMP4Format?.document?.contentUrl }}" type="video/mp4">
                            <source *ngIf="item?.UploadVideoInOggFormat?.document?.contentUrl" src="{{ staticUrlForImgNew + item?.UploadVideoInOggFormat?.document?.contentUrl }}" type="video/ogg">
                            Your browser does not support HTML5 video.
                        </video>
                    </div>
                    <ng-template #showImage>
                      <div class="experiences_slider_each--img">
                          <img src="{{ staticUrlForImgNew + item?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                      </div>
                    </ng-template>
                      <div class="experiences_slider_each_copy">
                          <div class="experiences_slider_each_copy--center">
                              <h4>{{item?.CardHLText?.data}}</h4>
                              <p class="content3">{{ item?.CardShortText?.data }}</p>
                              <!-- <a href="{{item?.CTALink?.data}}" *ngIf="item?.HideCTAText?.data =='false'" class="cta-sec on-bg"
                              (click)="_sharedService.checkCTALink($event, item?.CTALink?.data, item?.WindowPreference?.data, item?.TypeOfCTALink?.data)">
                                  <span class="cta-sec-txt">{{item?.CTAText?.data}}
                                  </span>
                              </a> -->
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"*ngIf="experiencesData"></div>
      <!-- Add Arrows -->
      <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
      </div>
  </div>
  <div class="experiences_buttons_link text-center" *ngIf="currentUrl.indexOf('/jio-world-garden') > -1">
        <a (click)="callCtaSecondaryLinkEvent(); _sharedService.checkCTALink($event, experiencesData?.ViewAllCTALink?.data, experiencesData?.ViewAllWindowPreference?.data)"
            class="cta-sec color-green" *ngIf="experiencesData?.HideViewAllCTAText?.data == 'false'" href="{{experiencesData?.ViewAllCTALink?.data}}">
            <span class="cta-sec-txt">{{experiencesData?.ViewAllCTAText?.data}}</span>
        </a>
    </div>
</section>