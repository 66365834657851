<!-- <app-header [colorList]="cecVenueDetailsColorList" [pageName]="'cec'"></app-header> --> 
<app-sub-header *ngIf="cecVenueDetailsHeaderList" [colorList]="cecVenueDetailsColorList"
  [subHeaderList]="cecVenueDetailsHeaderList" [pageName]="'cec'">
</app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main"> 
  <app-back-to-wrapper></app-back-to-wrapper>
  <!-- <app-banner [landingBannerData]="cecVenueDetailsBannerData" [sectionClass]="'screen_height_inside'" [bannerPage]="'cec'" *ngIf="cecVenueDetailsBannerData">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-convention-centre">Jio World Convention
          Centre</a> / <a routerLink="/jio-world-convention-centre/venues">Venues</a> /
        <strong>{{breadcrumbName}}</strong>
      </div>
    </div>
  </app-banner> -->
  <app-banner-new *ngIf="cecVenueDetailsBannerData" [newBannerData]="cecVenueDetailsBannerData"></app-banner-new>
  <div [attr.data-mobile-header]="breadcrumbName" [id]="venueDetailsId">
    <app-cec-venue-details-halls *ngIf="cecVenueDetailsHallsData" [cecVenueDetailsHallsData]="cecVenueDetailsHallsData">
    </app-cec-venue-details-halls>
  </div>
  <div data-mobile-header="Full Metting Package">
    <app-cec-venue-details-meeting-package [meetingRoomPackageData]="fullMeetingRoomPackageData"></app-cec-venue-details-meeting-package>
  </div>
  <div  data-mobile-header="Meeting Package">
    <app-cec-venue-details-meeting-package [meetingRoomPackageData]="meetingRoomPackageData"></app-cec-venue-details-meeting-package>
  </div>

  <div  data-mobile-header="Meeting Package" *ngIf="smallMeetingRoomPackageData">
    <app-cec-venue-details-meeting-package [meetingRoomPackageData]="smallMeetingRoomPackageData"></app-cec-venue-details-meeting-package>
  </div>

  <div data-mobile-header="Floor Plan" id="floor-plan">
    <app-cec-venue-details-floor-plan *ngIf="cecVenueDetailsFloorPlansData"
      [cecVenueDetailsFloorPlansData]="cecVenueDetailsFloorPlansData">
    </app-cec-venue-details-floor-plan>
  </div>
  
  <div data-mobile-header="Event Services" id="event-services">
    <app-event-services *ngIf="cecVenueDetailsHighlightsInfoData"
      [venueDetailsHighlightsInfoData]="cecVenueDetailsHighlightsInfoData" [colorList]="cecVenueDetailsColorList">
    </app-event-services>
  </div>
  <div data-mobile-header="Plan" [id]="planEventId">
    <app-get-in-touch *ngIf="cecVenueDetailsPlanEventData" [getInTouchData]="cecVenueDetailsPlanEventData"
      [colorList]="cecVenueDetailsColorList" [pageName]="'cec'">
    </app-get-in-touch>
  </div>
  <!-- <section class="planning_tools_wrapper plr-0" id="planning-tools" style="--data-tab-txt-Color:#4B4847;--data-tab-txt-hover-Color:#004EA8; 
--data-swiper-slide-Background:#F5F2ED;--data-swiper-slide-heading-color:#004EA8">
    <div class="container" data-aos="fade-up">
      <div class="section_heading " style="--data-titleColor:#4B4847;--data-lineColor:#004EA8">
        <div class="">
          <h2 class="section_heading--title">Planning Tools</h2>
          <p class="section_heading--text"> From trade shows and exhibitions, to seminars and conventions - our exhibit
            halls will adapt to suit your event requirements. Choose from a set of world-class services to customise
            your event to your taste and that of your attendees.
          </p>
        </div>
      </div>
      <div class="container planning_tools_lists" data-aos="fade-up">
        <div class="tabs">
          <div class="tab_mob icon-arrow-down">Category 01</div>
          <ul class="nav nav-tabs">
            <li><a href="#tab1" class="active" data-toggle="tab">Category 01</a></li>
            <li><a href="#tab2" data-toggle="tab">Category 02</a></li>
            <li><a href="#tab3" data-toggle="tab">Category 03</a></li>
            <li><a href="#tab4" data-toggle="tab">Category 04</a></li>
          </ul>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade active show" id="tab1">
            <div class="jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="3" data-ipad-slides="2.2"
              data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tab2">
            <div class="jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="3" data-ipad-slides="2.2"
              data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tab3">
            <div class="jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="3" data-ipad-slides="2.2"
              data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tab4">
            <div class="jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="3" data-ipad-slides="2.2"
              data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                          eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                          voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                          amet. Lorem ipsum dolor sit amet.</p>
                        <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                          class="cta-sec icon rh icon-download-icon color-blue "><span class="cta-sec-txt">Download
                            pdf</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="cta-holder">
          <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/downloadable-tech-specs')" class="cta-prim color-blue"><span
              class="cta-prim-txt">View All</span></a>
          <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/inquiry')" class="cta-sec color-blue"><span
              class="cta-sec-txt">Enquire now</span></a>
        </div>
      </div>
    </div>
  </section> -->
  <div data-mobile-header="Events We Host" id="events-we-host">
    <app-plan-big-event *ngIf="cecVenueDetailsEventsData" [venueDetailsEventsData]="cecVenueDetailsEventsData"
      [colorList]="cecVenueDetailsColorList" [pageName]="'cec'"></app-plan-big-event>
  </div>
  <div data-mobile-header="Venue Showcase" id="venue-showcase">
    <app-venue-details-showcase *ngIf="cecVenueDetailsShowcaseData"
      [venueDetailsShowcaseData]="cecVenueDetailsShowcaseData" [colorList]="cecVenueDetailsColorList">
    </app-venue-details-showcase>
  </div>
  <div data-mobile-header="Testimonials" [id]="testimonialsId">
    <app-organisers-speak *ngIf="cecVenueDetailsOrgSpeakData" [organiserData]="cecVenueDetailsOrgSpeakData"
      [colorList]="cecVenueDetailsColorList">
    </app-organisers-speak>
  </div>
  <div data-mobile-header="Contact" id="contact">
    <app-get-in-touch *ngIf="cecVenueDetailsAskUsData" [getInTouchData]="cecVenueDetailsAskUsData"
      [colorList]="cecVenueDetailsColorList" [pageName]="'cec'" [sectionClass]="'pb-0'">
    </app-get-in-touch>
  </div>
  <div data-mobile-header="More Venues" id="more-venues">
    <app-explore-venues-wrapper *ngIf="cecVenueDetailsExploreData" [exploreData]="cecVenueDetailsExploreData">
    </app-explore-venues-wrapper>
  </div>
  <!-- <section class="more_centre_wrapper plr-0" style="--data-more-centre-bgColor1:#FFFFFF;--data-more-centre-bgColor2: transparent;--data-more-centre-titleColor:#4B4847;
      --data-more_centre_txt_bgColor:#F5F2ED;">
    <div class="container">
      <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#43B02A">
        <div class="">
          <h2 class="section_heading--title">More at Jio World Centre</h2>
        </div>
      </div>
      <div class="more_centre_slider jwc_swiper_normal" data-interval="8" data-space="24" data-slides="3"
        data-mob="false" data-ipad-slides="2.2" data-ipad-space="20" data-mobile-slides="1.15" data-mobile-space="20">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                  <span class="more_centre_slider_each_copy--category">Food Festival</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" class="cta-prim color-blue icon icon-ticket"><span
                        class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/2.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                  <span class="more_centre_slider_each_copy--category">Performing Arts</span>
                  <h4 class="more_centre_slider_each_copy--title">Kalasri - Bharatnatyam</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" class="cta-prim color-blue icon icon-ticket"><span
                        class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/3.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                  <span class="more_centre_slider_each_copy--category">Visual Arts</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">The Arts Centre</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" class="cta-prim color-blue icon icon-ticket"><span
                        class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </section> -->
</main>
<!-- <app-footer [colorList]="cecVenueDetailsColorList"></app-footer> -->
<div class="overlay"></div>