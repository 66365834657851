import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { constants } from '../../../shared/shared.constant';
declare var jQuery: any;
@Component({
  selector: 'app-venue-hire-cec',
  templateUrl: './venue-hire-cec.component.html',
  styleUrls: ['./venue-hire-cec.component.css']
})
export class VenueHireCecComponent implements OnInit, OnDestroy {
  inquiryType = 'Venue Hire';
  brand = 'Jio World Convention Centre';
  VenueHireCecInquiryForm: FormGroup;
  @Input() typeOfHall;
  typeOfEvent;
  countryCode;
  submitted = false;
  errorText = 'Field is required';
  @Output() inquiryTypeValue = new EventEmitter();
  @Output() brandValue = new EventEmitter();
  @Input() inquiryData;
  @Input() captchaSiteKey;
  disableBtn;
  @Input() set disableSubmitBtn(value) {
    this.disableBtn = value;
  }
  venueCecData;
  venueCecArrData;
  jArr = ['#eventFromDate', "#eventToDate", "#setupFromDate", "#setupToDate", "#dismantleFromDate", "#dismantleToDate"];
  @ViewChild("eventFromDate", { static: true }) eventFromDate: any;
  // @ViewChild("eventFromTime", { static: true }) eventFromTime: any;
  @ViewChild("eventToDate", { static: true }) eventToDate: any;
  // @ViewChild("eventToTime", { static: true }) eventToTime: any;
  @ViewChild("setupFromDate", { static: true }) setupFromDate: any;
  // @ViewChild("setupFromTime", { static: true }) setupFromTime: any;
  @ViewChild("setupToDate", { static: true }) setupToDate: any;
  // @ViewChild("setupToTime", { static: true }) setupToTime: any;
  @ViewChild("dismantleFromDate", { static: true }) dismantleFromDate: any;
  // @ViewChild("dismantleFromTime", { static: true }) dismantleFromTime: any;
  @ViewChild("dismantleToDate", { static: true }) dismantleToDate: any;
  // @ViewChild("dismantleToTime", { static: true }) dismantleToTime: any;
  @ViewChild('areaRequired', { static: true }) areaRequired: any;
  eventDateError = '';
  dismantleDateError = '';
  setupDateError = '';
  isCaptchaSelected = false;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;  
  apiErrorMsg="";

  grcToken =  "XXXXXXXXXXX";

  constructor(private formBuilder: FormBuilder, public _sharedService: SharedService, private router: Router) {
    this._sharedService.inquiryApiErrorMsg.subscribe(
      (data) => {
        if (data['error'].message) {
          this.apiErrorMsg = "Server error. Please try after some time...";
          // this.apiErrorMsg = data['error'].message;
        }
      }
    );
   }

  ngOnInit() {
    this.venueCecData = this.inquiryData.cards[1];
    this.venueCecArrData = {...this.venueCecData.cec};
    this.createVenueHireCecForm();
    if(this.typeOfHall) this.onLayoutCheckboxClick('typeOfHall', null, true, this.typeOfHall);
    this._sharedService.callCustomDateTimePicker();
    this._sharedService.callInquiryPeopleRangeSlider();
    this._sharedService.callCustomDropdown();
    this.jArr.forEach(item => {
      this.dynamicjQueryFn(item);
    });
  }

  createVenueHireCecForm() {
    let prefilledData = this._sharedService.getInquiryFormValues();
    this.VenueHireCecInquiryForm = this.formBuilder.group({
      inquiryType: ['Venue Hire'],
      brand: ['CEC'],
      typeOfHall: this.formBuilder.array([]),
      typeOfEvent: [this._sharedService.configData.default_enquiries_dropdown_value || 'Did not say'],
      eventNameInput: [''],
      eventDescInput: [''],
      artistDetailInput: [''],
      nameInput: [(prefilledData.name) ? prefilledData.name : '', Validators.required],
      companyInput: [(prefilledData.company) ? prefilledData.company : ''],
      designationInput: [''],
      emailInput: [(prefilledData.email) ? prefilledData.email : '', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      countryCode: ['+91'],
      phoneNoInput: [(prefilledData.phoneNo) ? prefilledData.phoneNo : '', [Validators.required, Validators.minLength(10)]],
      clientCountryCode: ['+91'],
      clientPhoneNoInput: [''],
      // clientPhoneNoInput: ['', [Validators.required, Validators.minLength(10)]],
      clientCompanyInput: [''],
      messageInput: [(prefilledData.message) ? prefilledData.message : ''],
      firstConsent: [false, Validators.requiredTrue],
      secondConsent: [false],
      tentativeLayout: this.formBuilder.array([]),
      selectedMiscList: this.formBuilder.array([]),
      otherLayoutInput: [''],
      numberOfPeopleInput: [''],
      eventDateTime: this.formBuilder.group({
        fromDateInput: [''],
        endDateInput: ['']
      }),
      setupDateTime: this.formBuilder.group({
        fromDateInput: [''],
        endDateInput: ['']
      }),
      dismantleDateTime: this.formBuilder.group({
        fromDateInput: [''],
        endDateInput: ['']
      })
    });
  }

  dynamicjQueryFn(id) {
    jQuery('body').on('change', id, function () {
      jQuery(id).trigger('click');
    });
  }

  updateFromDate(value, formName, controlName) {
    let tmp = this.VenueHireCecInquiryForm.get([formName, controlName]).value;
    if (tmp == value) return;
    this.checkDateValidation(value, formName, controlName);
    // if (this.eventDateError || this.dismantleDateError || this.setupDateError) return;

    this.VenueHireCecInquiryForm.patchValue({
      [formName]: {
        [controlName]: value
      }
    });
    // console.log("value", value);
  }

  checkDateValidation(value, formName, controlName) {
    let err = '';
    if (controlName == 'endDateInput') {
      err = (this.VenueHireCecInquiryForm.get([formName, 'fromDateInput']).value) ?
        ((value < this.VenueHireCecInquiryForm.get([formName, 'fromDateInput']).value) ?
          'End date must be greater than start date' : '')
        : 'Please select Start Date';
    } else if (controlName == 'fromDateInput' && this.VenueHireCecInquiryForm.get([formName, 'endDateInput']).value) {
      err = (value > this.VenueHireCecInquiryForm.get([formName, 'endDateInput']).value) ?
        'End date must be greater than start date' : '';
    }
    if (controlName == 'endDateInput' || controlName == 'fromDateInput') {
      if (formName == 'dismantleDateTime') {
        this.dismantleDateError = err;
      } else if (formName == 'setupDateTime') {
        this.setupDateError = err;
      } else if (formName == 'eventDateTime') {
        this.eventDateError = err;
      }
    }
  }

  get venueHireCecFormControls() { return this.VenueHireCecInquiryForm.controls; }

  setDropdownValue(formControlname, value) {
    this.VenueHireCecInquiryForm.get(formControlname).setValue(value);
  }

  get spacesArray(): FormArray {
    return this.VenueHireCecInquiryForm.get('typeOfHall') as FormArray;
  }

  onLayoutCheckboxClick(formArrName, event?, inputEventChecked?, inputEventValue?) {
    let checkedVal = event?.target?.value || inputEventValue
    this.spacesArray.clear()
    this.spacesArray.push(new FormControl(checkedVal));
    this.setDropdownOptions(this.spacesArray)
  }

  setDropdownOptions(tentativeLayoutArray) {
    const typeOfEventsForMeetingRooms = ['Convene - Day Package', 'Connect - Extended Day Page', 'Meeting Pods', 'Meeting - Others']
    if (tentativeLayoutArray.value.includes('Meeting Rooms')) {
      this.venueCecArrData.typeOfEvents = typeOfEventsForMeetingRooms
    } else {
      this.venueCecArrData.typeOfEvents = this.venueCecData.cec.typeOfEvents
    }

    const typeOfEventExists = this.venueCecArrData.typeOfEvents.includes(this.venueHireCecFormControls?.typeOfEvent?.value)
    if (!typeOfEventExists) {
      this.setDropdownValue('typeOfEvent', this._sharedService.configData.default_enquiries_dropdown_value || 'Did not say');
    } 
  }

  onMiscRadioClick(event, item) {
    let selectedMiscArray: FormArray = this.VenueHireCecInquiryForm.get('selectedMiscList') as FormArray;
    if (event.target.checked && event.target.value == 'Yes') {
      selectedMiscArray.push(new FormControl(item.key));
    } else {
      selectedMiscArray.controls.forEach((miscArr: FormControl, index) => {
        if (miscArr.value == item.key) {
          selectedMiscArray.removeAt(index);
          return;
        }
      });
    }
  }
  sendVhRouteObj(name) {
    this.router.navigate(['/legal'], {
      state: { tabName: name }
    });
    return false;
  }
  venueHireCecSubmit(form) {
    this.submitted = true;
    // if (!this.isCaptchaSelected) return;
    // console.log("form values:", form.value);
    if (form.invalid || !this.isCaptchaSelected || this.eventDateError || this.dismantleDateError || this.setupDateError) {
      return;
    }
    
    this.disableBtn = true;
    let payload = form.value;
    payload.grcToken = this.grcToken;
    payload.areaRequired = this.areaRequired.nativeElement.value.replace(';', '-');
    let venueHireCecEveObj = {
      Enquiry_Type: payload.inquiryType,
      Brand_Name: payload.brand,
      Event_Space_Exbh: (payload.typeOfHall.indexOf('Exhibition Halls') >= 0) ? 'Yes' : 'No',
      Event_Space_Conv: (payload.typeOfHall.indexOf('Convention Halls') >= 0) ? 'Yes' : 'No',
      Event_Space_Meet: (payload.typeOfHall.indexOf('Meeting Rooms') >= 0) ? 'Yes' : 'No',
      Event_Space_Banq: (payload.typeOfHall.indexOf('Ballroom') >= 0) ? 'Yes' : 'No',
      Event_Type: payload.typeOfEvent,
      Event_Name: payload.eventNameInput,
      Event_From_Date: this.VenueHireCecInquiryForm.get(['eventDateTime', 'fromDateInput']).value,
      Event_End_Date: this.VenueHireCecInquiryForm.get(['eventDateTime','endDateInput']).value,
      Seating_Type_Theatre: (payload.tentativeLayout.indexOf('Theatre') >= 0) ? 'Yes' : 'No',
      Seating_Type_Classroom: (payload.tentativeLayout.indexOf('Classroom') >= 0) ? 'Yes' : 'No',
      Seating_Type_Round_Table: (payload.tentativeLayout.indexOf('Round Table') >= 0) ? 'Yes' : 'No',
      Seating_Type_U_shape: (payload.tentativeLayout.indexOf('U-shape') >= 0) ? 'Yes' : 'No',
      Guests_Count: payload.numberOfPeopleInput,
      Area_Required: payload.areaRequired,
      Organiser_Designation: payload.designationInput,
      Organiser_Phone: payload.countryCode + payload.phoneNoInput,
      Organiser_Name: payload.nameInput,
      Organiser_Email: payload.emailInput,
      Organiser_Company_Name:payload.companyInput,
      Client_Company_Name: payload.clientCompanyInput,
      Client_Phone: payload.clientCountryCode + payload.clientPhoneNoInput
      // Event_Temp_Name: 'Enquiry_Forms'
    };
    let venuHireCecCTProfile = {
      Organiser_Phone: payload.countryCode + payload.phoneNoInput,
      Organiser_Name: payload.nameInput,
      Organiser_Email: payload.emailInput,
      Organiser_Company_Name:payload.companyInput
    };
    this._sharedService.submitInquiryForm(payload, venueHireCecEveObj, venuHireCecCTProfile);
  }

  vCecCaptchaRes($event, flag?) {
    if (!$event) return this.isCaptchaSelected = false;
    if (flag) return console.log('Something went long when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true
    this.grcToken = $event;
  }

  ngOnDestroy() {
    let obj = {
      name: (this.venueHireCecFormControls.nameInput.value) ? this.venueHireCecFormControls.nameInput.value : '',
      email: (this.venueHireCecFormControls.emailInput.value) ? this.venueHireCecFormControls.emailInput.value : '',
      company: (this.venueHireCecFormControls.companyInput.value) ? this.venueHireCecFormControls.companyInput.value : '',
      message: (this.venueHireCecFormControls.messageInput.value) ? this.venueHireCecFormControls.messageInput.value : '',
      phoneNo: (this.venueHireCecFormControls.phoneNoInput.value) ? this.venueHireCecFormControls.phoneNoInput.value : ''
    }
    this._sharedService.setInquiryFormValues(obj.name, obj.email, obj.company, obj.message, obj.phoneNo);
  }

}
