import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment.qa';

@Component({
  selector: 'app-art-store-section',
  templateUrl: './art-store-section.component.html',
  styleUrls: ['./art-store-section.component.css']
})
export class ArtStoreSectionComponent implements OnInit {
  @Input() artStoreData;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
  }

}
