import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from '../../../../shared/services/shared.service';
import { constants } from '../../../../shared/shared.constant';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  submitted = false;
  regErr;
  userDetails;
  @ViewChild('closebutton', { static: true }) closebutton;
  errorText = 'Field is required';
  vehicleDetailsErr;
  registeredNumbers = [];
  
  constructor(private router: Router, private formBuilder: FormBuilder, private _sharedService: SharedService) { }

  ngOnInit() {
    this.userDetails = JSON.parse(this._sharedService.getStorageService().getSessionData('userDetails'));
    this.registrationForm = this.formBuilder.group({
      firstNameInput: [(this.userDetails)?this.userDetails.firstName: '', Validators.required],
      lastNameInput: [(this.userDetails)?this.userDetails.lastName:'', Validators.required, ],
      phoneNoInput: [{value: (this.userDetails)?this.userDetails.mobileNo: '', disabled: true}, Validators.minLength(10)],
      emailInput: [(this.userDetails)?this.userDetails.email:'', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      registrationNumber: [''],
      vehicleInfo: ['Car']
    });
    this.registeredNumbers = (this.userDetails.vehicleDetails)?this.userDetails.vehicleDetails : [];

  }

  get registrationFormControls() { return this.registrationForm.controls; }

  registrationSubmit(form) {
    this.submitted = true;
    if (form.invalid) {
      return;
    }
    var obj = {
      first_name: (this.registrationFormControls.firstNameInput.value).toUpperCase(),
      last_name: (this.registrationFormControls.lastNameInput.value).toUpperCase(),
      email_id: this.registrationFormControls.emailInput.value,
      mobile_number: this.registrationFormControls.phoneNoInput.value,
      vehicle_details: this.registeredNumbers
    }
    this._sharedService.getCommonService().saveUserDetails(obj).subscribe((data: any) => {
      this.regErr = '';
      this.closebutton.nativeElement.click();
      this.router.navigate(['/'])
    },
    (error) => {
      this.regErr = 'Server Error. Try after some time..';
      console.log("Try after some time..");
    }
  )
  }
  addRegistrationNumber() {
    if(!this.registrationFormControls.registrationNumber.value) {
      this.vehicleDetailsErr = 'Please add Vehicle Number'
      return
    }
    // console.log(this.registrationFormControls.registrationNumber.value, this.registrationFormControls.vehicleInfo.value);
    let obj = {type: this.registrationFormControls.vehicleInfo.value, regn_no: (this.registrationFormControls.registrationNumber.value).toUpperCase()};
    this.registeredNumbers.push(obj);
  }

  removeRegistrationNumber(i) {
    this.registeredNumbers.splice(i, 1);
  }

  skipRegForm() {
    this.closebutton.nativeElement.click();
  }

}
