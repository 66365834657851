import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CONST, InterceptorSkipHeader } from "../shared.constant";
import { StorageService } from "./storage.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SharedHttpService {
  private _tokenData: any = "";
  authToken: any = "";
  headers = {};
  private _headers: HttpHeaders;
  private _options: any = { "Content-Type": "application/json" };
  private _apiUrl = CONST["apiURL"];


  constructor(
    private _http: HttpClient,
    // private cookieService: CookieService,
    private router: Router,
    private _storageService: StorageService
  ) {
    // console.log(environment);
  }

  postLogin(url, endpoint, data, params = null, headers = false) {
    const that = this;
    let headerss;
    if (headers) {
      headerss = new HttpHeaders({})
    } else {
      headerss = new HttpHeaders({
        "Content-Type": "application/json"
      });
    }
    that.setApiUrl(url);
    const queryString = params ? that._objectToQueryString(params) : "";
    endpoint = endpoint + (queryString ? "?" + queryString : "");
    return that._http.post(that._apiUrl + endpoint, data, {
      headers: headerss,
      // withCredentials: true
    });
  }

  getNoCred(url, endpoint, params = null) {
    const that = this;
    that.setApiUrl(url);
    const queryString = params;
    endpoint =
      CONST["apiEndPoint"][endpoint] + (queryString ? "?" + queryString : "");
    return that._http.get(that._apiUrl + endpoint, that._options);
  }

  getJson(url) {
    return this._http.get(url);
  }


  getToken(url, endpoint) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      applicationCode: "CORP~INT_PORTAL"
    });
    const that = this;
    that.setApiUrl(url);
    return that._http.get(that._apiUrl + endpoint, {
      headers: headers,
      withCredentials: true
    });
  }

  getRefreshToken() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      applicationCode: "CORP~INT_PORTAL",
      "refresh-token": this.getRefreshWso2Token()
    });
    this.setApiUrl("loginUrl");
    return this._http
      .get<any>(this._apiUrl + CONST["apiEndPoint"]["getrefreshtoken"], {
        headers: headers,
        withCredentials: true
      })
      .pipe(
        tap((response: any) => {
          this._storageService.setSessionData("Wso2Token", response);
        })
      );
  }

  getWSO2(url, endpoint, params) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.getWso2Token()
    });
    const that = this;
    that.setApiUrl(url);
    const queryString = params ? that._objectToQueryString(params) : "";
    endpoint = endpoint + (queryString ? "?" + queryString : "");
    return that._http.get(that._apiUrl + endpoint, {
      headers: headers,
      // withCredentials: true
    });
  }

  postWSO2(url, endpoint, data, params = null, headers = false) {
    const that = this;
    if (headers) {
      this._options.append("Content-Type", "multipart/form-data");
    }
    let headerss = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.getWso2Token()
    });

    that.setApiUrl(url);
    const queryString = params ? that._objectToQueryString(params) : "";
    endpoint = endpoint + (queryString ? "?" + queryString : "");
    return that._http.post(that._apiUrl + endpoint, data, {
      headers: headerss,
      // withCredentials: true
    });
  }

  // postWSO2Cred(url, endpoint, data, params = null, headers = false) {
  //   const that = this;
  //   if (headers) {
  //     this._options.append("Content-Type", "multipart/form-data");
  //   }
  //   let headerss = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + this.getWso2Token()
  //   });

  //   that.setApiUrl(url);
  //   const queryString = params ? that._objectToQueryString(params) : "";
  //   endpoint = endpoint + (queryString ? "?" + queryString : "");
  //   return that._http.post(that._apiUrl + endpoint, data, {
  //     headers: headerss,
  //     withCredentials: true
  //   });
  // }

  setApiUrl(url) {
    const that = this;
    // that._apiUrl = CONST[url];
    that._apiUrl = environment[url];
  }

  private _objectToQueryString(object) {
    return Object.keys(object)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
      )
      .join("&");
  }

  getWso2Token() {
    let tokens = this._storageService.getSessionData("Wso2Token");
    if (tokens) {
      return tokens.access_token;
    } else {
      return null;
    }
  }
  getRefreshWso2Token() {
    let tokens = this._storageService.getSessionData("Wso2Token");
    if (tokens) {
      return tokens.refresh_token;
    } else {
      return null;
    }
  }
}
