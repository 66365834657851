<section class="getting_wrapper">

  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-5">
        <div class="section_heading " [attr.style]="('--data-titleColor:#212529;--data-lineColor:#C92C99') | safe: 'style'">
          <div class="half_block" *ngIf="gettingHereData">
            <h2 class="section_heading--title">{{gettingHereData?.ComponentHeaderTitle?.data}}</h2>
            <p class="section_heading--text">{{gettingHereData?.ComponentHeaderSubTitle?.data}}</p>
          </div>
        </div>
        <div class="getting_wrapper--btns" data-aos="fade-up">
          <a (click)="_sharedService.checkCTALink($event, gettingHereData?.GetDirectionsCTARedirectionLink?.data, gettingHereData?.WindowPreference?.data, gettingHereData?.TypeOfGetDirectionsCTALink?.data)"
            *ngIf="gettingHereData?.HideGetDirectionsCTAText.data == 'false'" class="cta-prim color-pink" href="{{gettingHereData?.GetDirectionsCTARedirectionLink?.data}}">
            <span class="cta-prim-txt">{{gettingHereData?.GetDirectionsCTAText?.data}}</span>
          </a>
          <a (click)="_sharedService.checkCTALink($event, gettingHereData?.BookParkingCTARedirectionLink?.data, gettingHereData?.bookParkingCtaLinkWindowPreference?.data, gettingHereData?.TypeOfBookParkingCTALink?.data)"
            *ngIf=false class="cta-sec color-pink" href="{{gettingHereData?.BookParkingCTARedirectionLink?.data}}">
            <!-- "gettingHereData?.HideBookParkingCTAText.data == 'false'" -->
            <span class="cta-sec-txt">{{gettingHereData?.BookParkingCTAText?.data}}</span>
          </a>
        </div>
      </div>
      <div class="col-lg-8 col-md-7">
        <div class="bg_before_right">
          <div *ngIf="gettingHereData">
            <a href="{{gettingHereData?.MapRedirectionLink?.data}}"
            (click)="_sharedService.checkCTALink($event, gettingHereData?.MapRedirectionLink?.data, gettingHereData?.mapLinkWindowPreference?.data, gettingHereData?.TypeOfMapRedirectionLink?.data)">
              <img src="./assets/images/map.jpg" class="img-fluid" alt="map">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>