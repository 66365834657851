<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <app-banner [landingBannerData]="safetyBannerData" [sectionClass]="'screen_height'" [bannerPage]="'landing'"
    [blackTint]="true" *ngIf="safetyBannerData" [fragmentId]="'section_2'">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <ng-container *ngIf="pageName !=='new-das'; else newDasTempalte">
          <a routerLink="/">Jio World Centre</a> / <strong>Visit Safely</strong>
        </ng-container>
      </div>
      <ng-template #newDasTempalte>
        <a routerLink="/das">Dhirubhai Ambani Square</a> / <strong>Visit Safely</strong>
      </ng-template>
    </div>
  </app-banner>
  <section class="pb-0 safety" id="section_2" *ngIf="staySafeData">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <img src="{{ staticUrlForImgNew + staySafeData?.ComponentBannerImage?.image?.contentUrl }}" class="img-fluid" alt="{{staySafeData?.ImageAltText?.data}}">
          <div class="safety_content">
            <h4 [innerHTML]="staySafeData?.ComponentHeaderTitle?.data"></h4>
            <p class="content3" [innerHTML]="staySafeData?.ComponentSubHeader?.data"
              *ngIf="staySafeData?.ComponentSubHeader?.data"></p>

            <div class="safety_list mt-5">
              <div class="row">
                <div class="col-lg-12">
                  <ul class="icon_list">
                    <ng-container *ngFor="let item of staySafeData?.cards">
                      <li *ngIf="item?.LeftIconHeader?.data">
                        <div class="icon">
                          <img src="{{ staticUrlForImgNew + item?.LeftIconImage?.image?.contentUrl }}" alt="{{item?.ImageAltText?.data}}"
                            class="img-fluid" />
                        </div>
                        <div class="text" [innerHTML]="item?.LeftIconHeader?.data"></div>
                      </li>
                    </ng-container>
                    <ng-container *ngFor="let item of staySafeData?.cards">
                      <li *ngIf="item?.RightIconHeader?.data">
                        <div class="icon">
                          <img src="{{ staticUrlForImgNew + item?.RightIconImage?.image?.contentUrl }}" alt="{{item?.ImageAltText?.data}}"
                            class="img-fluid" />
                        </div>
                        <div class="text" [innerHTML]="item?.RightIconHeader?.data"></div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
                <!-- <div class="col-lg-6">
                  <ul class="icon_list">
                    <ng-container *ngFor="let item of staySafeData?.cards">
                      <li *ngIf="item?.RightIconHeader?.data">
                        <div class="icon">
                          <img src="{{ staticUrlForImgNew + item?.RightIconImage?.image?.contentUrl }}" alt=""
                            class="img-fluid" />
                        </div>
                        <div class="text" [innerHTML]="item?.RightIconHeader?.data"></div>
                      </li>
                    </ng-container>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  </section>

  <section class="safety_download_pdf pb-0" *ngIf="keyHighlightsData">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text" [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
        <h2 [innerHTML]="keyHighlightsData?.ComponentHeaderTitle?.data"></h2>
        <p [innerHTML]="keyHighlightsData?.ComponentSubHeader?.data"></p>
      </div>
      <!-- <div class="row justify-content-between safety_download_pdf_list">
        <div class="col-lg-6 col-md-6">
          <img src="./assets/images/pdf-icon.svg" class="img-fluid content1 safety_download_pdf_list--icon" alt="" />
          <p class="content1 safety_download_pdf_list--title">Jio World Garden</p>
        </div>
        <div class="col-lg-2 col-md-12 safety_download_pdf_list--button">
          <a routerLink="/jio-world-garden/visit-safely" class="cta-sec color-gold"><span class="cta-sec-txt">LEARN
              MORE</span></a>
        </div>
      </div> -->
      <ng-container *ngFor="let item of keyHighlightsData?.cards">
        <div class="row justify-content-between safety_download_pdf_list" *ngIf="item?.HideRow?.data === 'false'">
          <div class="col-lg-6 col-md-6">
            <a href="{{item?.UploadKMAImageVideo?.document?.title}}" class="watchVideoClick" data-target="#video_box" [attr.data-url]="staticUrlForImgNew + item?.UploadKMAImageVideo?.document?.contentUrl" data-toggle="modal">
              <img src="{{ staticUrlForImgNew + item?.UploadIcon?.document?.contentUrl }}"
                class="img-fluid content1 safety_download_pdf_list--icon" alt="{{item?.ImageAltText?.data}}"/>
            </a>
            <p class="content1 safety_download_pdf_list--title">{{item?.HeaderText?.data}}</p>
          </div>
          <div class="col-lg-6 col-md-12 safety_download_pdf_list--button">
            <a href="{{item?.UploadKMAImageVideo?.document?.title}}" class="cta-sec color-gold watchVideoClick"
              data-target="#video_box"  [attr.data-url]="staticUrlForImgNew + item?.UploadKMAImageVideo?.document?.contentUrl"
              data-toggle="modal"><span class="cta-sec-txt">{{item?.DownloadCTAText?.data}}</span></a>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
  <section style="padding-top: 0">
    <div class="container">
      <app-safety-card [cardData]="kHCard1" [colorList]="covidPageColorList" *ngIf="kHCard1?.HideComponent?.data=='false'" [mainClass]="'mt-0'">
      </app-safety-card>
      <app-safety-card [cardData]="kHCard2" [colorList]="covidPageColorList" *ngIf="kHCard2?.HideComponent?.data=='false'"></app-safety-card>
      <app-safety-card [cardData]="kHCard3" [colorList]="covidPageColorList" *ngIf="kHCard3?.HideComponent?.data=='false'"></app-safety-card>
      <app-safety-card [cardData]="kHCard4" [colorList]="covidPageColorList" *ngIf="kHCard4?.HideComponent?.data=='false'"></app-safety-card>
    </div>
  </section>

  <!-- <section class="news_letter_4 small_space"
    style="--data-newsletter4-bgColor:#F5F2ED; --data-titleColor:#4B4847; --data-lineColor:#A67D54">

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-7 col-md-6">
          <h4 class="section_heading--title ">The Best Of Our World, Right In Your Inbox</h4>
        </div>
        <div class="col-lg-5 col-md-6">

          <div class="input-box">
            <form action="" method="post">
              <label for="subscribe-field" class="sr-only"> aa aa aaEnter Email Address</label>
              <input type="text" id="subscribe-field" placeholder="Enter your email address"
                class="input-field gold_color">
              <button class="cta-sec color-gold emp-btn"><span class="cta-sec-txt">SIGN UP</span></button>
              <span class="error-txt" style="display: none;">Error Message text</span>
            </form>
          </div>
          <div class="newsletter_captcha d-none">
            <img src="./assets/images/captcha.jpg" class="img-fluid" alt="captcha" />
          </div>
        </div>



      </div>
    </div>

  </section> -->
  <app-video-popup></app-video-popup>
</main>
<!-- <app-footer></app-footer> -->