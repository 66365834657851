<section class="organiserspeak2_wrapper plr-0"
  [attr.style]="('--data-speaker2-itemColor: ' + colorList?.dataSpeakerItemColor + '; --data-speaker2-bgColor: ' + organiserSpeakData?.ComponentBackgroundColor?.data ) | safe: 'style'"
  *ngIf="organiserSpeakData">
  <div class="container">
    <div class="section_heading text-center text-white section_heading--title"
      [attr.style]="('--data-titleColor: ' + colorList?.organiserTitleColor + '; --data-lineColor: ' + colorList?.organiserLineColor ) | safe: 'style'">
      <h2 [innerHTML]="organiserSpeakData?.ComponentHeaderTitle?.data"></h2>
    </div>
  </div>

  <div class="organiserspeak2_slider jwc_swiper_normal" data-interval="15" data-space="120" data-slides="1.5"  [attr.data-drag]="organiserSpeakData.cards?.length > 1"
    data-ipad-slides="1.25" data-ipad-space="10" data-mobile-slides="1.15" data-mobile-space="20" [attr.data-loop]="organiserSpeakData.cards?.length > 1">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of organiserSpeakData.cards">
          <div class="organiserspeak2_slider_each">
            <div class="organiserspeak2_slider_each--img">
              <img *ngIf="item?.ImageVideo?.data==='image'; else videoTemplate"
                src="{{staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}" class="img-fluid" alt="{{item?.UploadImageVideo?.document?.title}}">
              <ng-template #videoTemplate>
                <a href="{{item?.UploadKMAImageVideo?.document?.title}}" class="watchVideoClick"
                  data-target="#video_box"
                  [attr.data-url]="staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl"
                  data-toggle="modal">
                  <img src="{{staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl}}" class="img-fluid"
                    alt="{{staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.title}}">
                  <div class="play_href default"><img src="./assets/images/playbutton.svg" class="img-fluid play_btn"
                      alt="play button"></div>
                </a>
                <!-- data-url="./assets/videos/jio-world-convention-centre-exhibition-halls-pavilions.mp4" -->
              </ng-template>
            </div>
            <div class="organiserspeak2_slider_each_copy">
              <p class="content3" [innerHTML]="item?.QuoteText?.data" [ngStyle]="{color: colorList?.textContentColor? colorList?.textContentColor: ''}"></p>
              <p class="organiserspeak2_slider_each_copy--name" [ngStyle]="{color: colorList?.textContentColor? colorList?.textContentColor: ''}">
                - {{item?.NameOfRepresentative?.data}}
              </p>
              <p class="content3" [ngStyle]="{color: colorList?.textContentColor? colorList?.textContentColor: ''}">{{item?.DesignationOrganizationOfRepresentative?.data}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Arrows -->
    <div class="swiper-arrows" *ngIf="organiserSpeakData.cards?.length > 1">
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</section>
<app-video-popup></app-video-popup>