import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-get-direction-page',
  templateUrl: './get-direction-page.component.html',
  styleUrls: ['./get-direction-page.component.css']
})
export class GetDirectionPageComponent implements OnInit {
  getDirectionColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor: "#976841"
  };
  previousRouteData;
  locationData;
  getDirectionBannerData;
  mapData;
  subscription;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this.previousRouteData = this._sharedService.getPreviousRouteData();
    this.locationData = (this.previousRouteData == 'home')?'Jio World Centre': (this.previousRouteData == 'cec')?
     'Jio World Convention Centre':(this.previousRouteData == 'garden')?'Jio World Garden' : (this.previousRouteData == 'new-das')? 'Dhirubhai Ambani Square' : 'Jio World Centre';
     this.getAboutPageContent();
  }
  getAboutPageContent() {
    let keyList = [
      'GET-DIRECTION-BANNER', // Hero Banner
      37845
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
      (data: any) => {
        this.getDirectionBannerData = data[0] ? data[0].data : data[0];
        this.mapData = data[1] ? data[1].data : data[1];
        this._sharedService.initializeCustomJs();
        this._sharedService.callGoogleMaps();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  callGetDirectionCTEvent() {
    this._sharedService.callCTEvent('Section_Primary_Links', '', '', this.mapData.GetDirectionsCTARedirectionLink.data);
  }

}
