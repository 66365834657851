import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-benefits-privileges-section',
  templateUrl: './benefits-privileges-section.component.html',
  styleUrls: ['./benefits-privileges-section.component.css']
})
export class BenefitsPrivilegesSectionComponent implements OnInit {
  @Input() benefitsData;
  @Input() colorList;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  colorClass: Map<string, string>;
  constructor(public _sharedService: SharedService) {
    this.colorClass = new Map([
      ["#C69E67", "color-gold"],
      ["#C92C99", "color-pink"]
    ]);
  }

  ngOnInit() { }

}
