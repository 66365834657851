import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-venues-wrapper',
  templateUrl: './venues-wrapper.component.html',
  styleUrls: ['./venues-wrapper.component.css']
})
export class VenuesWrapperComponent implements OnInit {
  @Input() enquireData;
  @Input() exploreVenuesData;
  @Input() planId;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  pdfName;

  constructor(public _sharedService: SharedService, private titleService: Title) {
    this._sharedService.callVenueList();
   }

  ngOnInit() {
  }
  callCecHomeCTEvent(type, tabName?, string?) {
    switch (type){
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', tabName);
      break;
      case 'sec':
        this._sharedService.callCTEvent('Section_Secondary_Links', this.exploreVenuesData.ComponentHeaderTitle.data);
      break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(string);
        this._sharedService.callCTEvent('Download_Links', tabName, this.pdfName);
      break;
    }
  }
}
