<section class="plan-your-next-big-event"
  [attr.style]="'--data-plan-bgColor: ' + ((venueDetailsEventsData?.ComponentBackgroundColor?.data)?(venueDetailsEventsData?.ComponentBackgroundColor?.data):colorList?.dataPlanBgColor) + '; --data-newsletter2-linecolor: ' + colorList?.dataNewsletter2LineColor | safe: 'style'">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div data-aos="fade-up">
          <div class="section_heading section_heading--title"
            [attr.style]="'--data-lineColor: ' + colorList?.dataPlanLineColor | safe: 'style'">
              <h2 [innerHTML]="venueDetailsEventsData?.ComponentHeaderTitle?.data"></h2>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="accordian_with_slider">
          <div id="events-garden">
            <div class="card" *ngFor="let item of venueDetailsEventsData?.cards; let i = index">
              <div class="card-header" id="heading{{i}}">
                <h5 class="mb-0">
                  <button class="btn btn-link " [ngClass]="{'collapsed': i!=0}" data-toggle="collapse"
                    [attr.data-target]="'#eve-collapse'+i" [attr.aria-expanded]="i===0 ? true : false"
                    [attr.aria-controls]="'eve-collapse'+i">{{item?.TabHeaderName?.data}}<span
                      class="icon-arrow-down"></span>
                  </button>
                </h5>
              </div>

              <div id="eve-collapse{{i}}" class="collapse" [attr.aria-labelledby]="'heading'+i"
                data-parent="#events-garden" *ngIf="item.SliderImageDetails">
                <div class="card-slider">
                  <div class="accordian_slider" data-interval="4" [ngClass]="{'single_image': item.SliderImageDetails.length == 1}">
                    <div class="swiper-container">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let swiperItem of item.SliderImageDetails">
                          <div class="accordian_slider_each">
                            <img src="{{ staticUrlForImgNew + swiperItem?.SliderImage?.image?.contentUrl}}" class="img-fluid"
                              alt="{{swiperItem?.ImageAltText?.data}}">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Add Pagination -->
                    <div class="swiper-pagination"></div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="card-body_copy">
                    <p class="content3">{{item?.AccordianShortText?.data}}</p>
                    <a href="{{item?.CTALink?.data}}" *ngIf="item?.HideCTAText?.data =='false'" class="cta-prim emp-btn"
                      [ngClass]="buttonColorClass.get(pageName)"
                      (click)="callPlanEventCTEvent(item?.TabHeaderName?.data); _sharedService.checkCTALink($event, item?.CTALink?.data, item?.TypeOfCTALink?.data, item?.CTALinkWindowPreference?.data)">
                      <span class="cta-prim-txt">{{item?.CTAText?.data}}</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>