import { Component, OnInit, Input} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() colorList?;
  isSticky: boolean;
  pageName;
  @Input() set sticky(val) {
    this.isSticky = val;
  }
  @Input() set page(val) {
    this.pageName = val;
  }
  // showLoginBtn = false;
  logoName: Map<string, string>;
  routePath: Map<string, string>;
  imgAltTxt: Map<string, string>;
  loginScreen;
  isUserLoggedIn;
  exploreImgArr = ['jioworld-convention-centre', 'jioworld-garden', 'the-cultural-centre-header-image','dhirubhai-ambani-square-header-image', 'JioWorld-Drive​','Bay-Club', 'JioWorld-Plaza','JioWorld-Residences​','The-Clubhouse','jioworld-offices'];
  userDetails;
  showExhibitorLogin:boolean = true;
  tccUrl = environment.tccUrl;
  constructor(public _sharedService: SharedService, private titleService: Title, private activatedRoute: ActivatedRoute) {
    this.logoName = new Map([
      ["home", "logo-navbar-jwc"],
      ["cec", "logo-navbar-cec"],
      // ["tcc", "logo-navbar-tcc"],
      ["tcc", "logo-navbar-new-nmacc"],
      ["garden", "logo-navbar-garden"],
      ["das", "logo-navbar-das"]
    ]);
    this.routePath = new Map([
      ["home", "/"],
      ["cec", "/jio-world-convention-centre"],
      ["garden", "/jio-world-garden"],
      // ["tcc", "/the-cultural-centre"],
      ["tcc", "/nita-mukesh-ambani-cultural-centre"],
      ["das", "/dhirubhai-ambani-square"]
    ]);
    this.imgAltTxt = new Map([
      ["home", "Jio World centre Logo"],
      ["cec", "Jio World convention centre Logo"],
      ["tcc", "The uultural centre Logo"],
      ["garden", "Jio World garden Logo"],
      ["das", "Dhirubhai Ambani Square Logo"]
    ]);
    this._sharedService.getStorageService().userDetails.subscribe(
      (data: any)=>{
        if(data){
          this.isUserLoggedIn = this._sharedService.getStorageService().getSessionData('isLoggedIn');
          this.userDetails = this._sharedService.getStorageService().getSessionData('userDetails') ? JSON.parse(this._sharedService.getStorageService().getSessionData('userDetails')) : '';
        }
      }
    )
  }
  ngOnInit() {
    this.isUserLoggedIn = this._sharedService.getStorageService().getSessionData('isLoggedIn');
    this.userDetails = this._sharedService.getStorageService().getSessionData('userDetails') ? JSON.parse(this._sharedService.getStorageService().getSessionData('userDetails')) : '';
    // this.showExhibitorLogin = (environment.env == 'prod')? false : true;
    this._sharedService.callNavFunction();
    this._sharedService.callNavigationScroll();
      // if (environment.env == 'dev') {
        // this.showLoginBtn = true;
        // this.isUserLoggedIn = this._sharedService.getStorageService().getSessionData('isLoggedIn');
        // this.userDetails = this._sharedService.getStorageService().setSessionData('userDetails', '');
      // }
  } 
  callHeaderCTEvent(link?) {
    link ? this._sharedService.callCTEvent('Header_Primary_Links', '', '', link) : this._sharedService.callCTEvent('Header_Primary_Links');
  } 
  closeHamMenu() {
    jQuery("#jwc_mob_hamburger").trigger('click');
  }
  logout() {
    this._sharedService.getStorageService().setSessionData('isLoggedIn', false);
    this._sharedService.getStorageService().setSessionData('userDetails', '');
    this.isUserLoggedIn = false;
    this.loginScreen = 'sendOtp';
  }
  userLoggedIn(val) {
    if (val) this.isUserLoggedIn = this._sharedService.getStorageService().getSessionData('isLoggedIn');
  }
}
