<div class="loader">
  <div class="loader_spin"></div>
</div>
<section class="cookie_policy position-fixed fixed-bottom">
  <div class="container">
    <div class="section_heading">
      <div class="row">
        <div class="col-md-12">
          <p class="section_heading--text content3">
            By continuing, you agree that you are happy for us to use these cookies, and you have read and understood
            the
            <a href="{{'/legal?tab=privacy'}}"
              (click)="_sharedService.checkCTALink($event, '/legal?tab=privacy')">Privacy Policy</a>
            and
            <a href="{{'/legal?tab=cookie'}}" (click)="_sharedService.checkCTALink($event, '/legal?tab=cookie')"> Cookie
              Policy </a>
          </p>
          <div class="buttons"><a href="javascript:void(0)" (click)="acceptAll();hideCookieConsent()"
              class="cta-prim color-gold emp-btn" role="button">
              <span class="cta-prim-txt">yes, accept cookies</span>
            </a>
            <a href="javascript:void(0)" (click)="rejectAll();hideCookieConsent()" class="cta-prim color-gold"
              role="button">
              <span class="cta-prim-txt">no, reject cookies</span>
            </a>
            <a href="javascript:void(0)" class="cta-sec color-gold emp-btn" role="button" data-toggle="modal"
              data-target="#exampleModalCenter">
              <span class="cta-sec-txt">customize cookies</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered mx-auto w-100" role="document">
      <div class="modal-content">
        <div class="p-3">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <p class="text-center">Customize my preference</p>
        </div>
        <div class="modal-body">
          <label class="toggle mb-3">
            <div class="w-75">
              <p class="section_heading--text content3 line-height-0">Necessary</p>
              <p class="section_heading--text content3">
                (Necessary cookies help make a website usable by enabling basic functions like page navigation and
                access
                to secure areas of the website. The website cannot function properly without these cookies.)
              </p>
            </div>
            <input class="toggle-checkbox" type="checkbox" [(ngModel)]="preferences.necessary" disabled checked>
            <div class="toggle-switch"></div>
          </label>
          <label class="toggle mb-3">
            <div class="w-75">
              <p class="section_heading--text content3 line-height-0">Statistics</p>
              <p class="section_heading--text content3">
                (Statistic cookies help website owners to understand how visitors interact with websites by collecting
                and reporting information anonymously.)
              </p>
            </div>
            <input class="toggle-checkbox" type="checkbox" [(ngModel)]="preferences.statistics">
            <div class="toggle-switch"></div>
          </label>
          <label class="toggle mb-0">
            <div class="w-75">
              <p class="section_heading--text content3 line-height-0">Marketing</p>
              <p class="section_heading--text content3">
                (Marketing cookies are used to track visitors across websites. The intention is to display ads that are
                relevant
                and engaging for the individual user and thereby more valuable for publishers and third-party
                advertisers.)
              </p>
            </div>
            <input class="toggle-checkbox" type="checkbox" [(ngModel)]="preferences.marketing">
            <div class="toggle-switch"></div>
          </label>
        </div>
        <div class="text-right px-3 pb-3">
          <a href="javascript:void(0)" (click)="savePreferences();hideCookieConsent()"
            class="cta-prim color-gold emp-btn" role="button" data-dismiss="modal">
            <span class="cta-prim-txt">Accept Cookies</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>