import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../../shared/services/shared.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-explore-venues-wrapper',
  templateUrl: './explore-venues-wrapper.component.html',
  styleUrls: ['./explore-venues-wrapper.component.css']
})
export class ExploreVenuesWrapperComponent implements OnInit {
  cecVenueDetailsExploreData;
  @Input() set exploreData(value) {
    this.flag = false;
    this.cecVenueDetailsExploreData = '';
    this.modalObj = {};
    this.roomsObj = {};
    this.roomsId = '';
    this.isMeetingRoom = false;
    this.hideLoadMore = false;
    this.hallExploreData = [];
    this.meetingRoomExploreData = [];
    this.cecVenueDetailsExploreData = value;
    this.setHallsData();
  };
  flag = false;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  modalId;
  roomsObj = {};
  modalObj;
  isMeetingRoom: boolean = false;
  hideLoadMore: boolean = false;
  meetingRoomExploreData: any = [];
  hallExploreData: any = [];
  roomsId: any;
  pdfName: any;
 
  constructor(public _sharedService: SharedService, private titleService: Title) { }

  ngOnInit() {
  }

  setHallsData() {
    this._sharedService.callSwipertab();
    this._sharedService.callNormalSlider3();
    this._sharedService.callExpandcollapse();
    setTimeout(() => {
      this.flag = true;
    }, 3000);
    this.cecVenueDetailsExploreData.forEach(element => {
      if(element){
        if (element.HallsHeaderText.data == 'Meeting Rooms') {
          this.isMeetingRoom = true;
          this.meetingRoomExploreData.push(element);
        } else {
          this.hallExploreData.push(element);
        }  
      }  
    });
  }

  loadMore(){
    let arr = document.querySelectorAll('.load-more-data-hide')
    arr[0].classList.remove('load-more-data-hide');
    this.hideLoadMore = true;
  }

  getObjAndIndex(obj, id) {
    this.roomsObj = obj;
    this.modalObj = obj;
    this.modalId = 'gallery' + id;
    this.roomsId = id;
  }
  
  callCecVDExploreSectionCTEvent(btn, sectionString, pdfString?) {
    switch (btn){
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionString);
        break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(pdfString);
        this._sharedService.callCTEvent('Download_Links', sectionString, this.pdfName);
        break;
    }
  }

}
