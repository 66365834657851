import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { schemaJson } from '../../shared/schema.constant';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-convention-center-landing',
  templateUrl: './convention-center-landing.component.html',
  styleUrls: ['./convention-center-landing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConventionCenterLandingComponent implements OnInit, OnDestroy {
  private subscription;
  cecBannerData;
  eventsData;
  infographicsData;
  beyondConventionalData;
  discoverMumbaiData;
  organiserData;
  enquireData;
  buildItineraryData;
  inNewsData;
  momentsData: any;
  accessWithEaseData;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  faqData: any;
  subscribeData;
  cecColorList = { newsDataTitleColor: "#4B4847", newsDataLineColor: "#004EA8", newsDateColor: "#004EA8", newsCopyColor: "#FFFFFF", newsViewAllLineColor: "#004EA8", primaryColor: '#1E478E', experiencesDataLineColor: '#1E478E', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', subscribeDataBgColor: "#FFFFFF", subscribeDataTitleColor: "#4B4847", subscribeDataLineColor: "#004EA8", subscribeDotColor: "#004EA8", subscribeLineColor: "#004EA8", subscribeTextColor: "#4B4847", momentsIconColor: "#004EA8", dataSpeakerItemColor: "#004EA8", organiserTitleColor: "#4B4847", organiserLineColor: "#004EA8", headerDataHoverColor:"#1E478E", dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#004EA8' };
  cecExpSwiperData = { experiencesSliderName: 'cec_fraction_1' };
  cecExpSwiperData2 = { experiencesSliderName: 'cec_fraction_2' };
  cecSubHeaderList;
  exploreVenuesData;
  exploreId;
  planId;
  successfulSubscription: boolean = false;
  covidKeyHighlightsData: any;
  cecInstagramData: any;
  // cecInstaData: any = [];  
  jsonLDforCecGeneral: SafeHtml;
  jsonLDforCecBooking: SafeHtml;
  jsonLDforCecAas: SafeHtml;
  jsonLDforCecPlanAVisit: SafeHtml;
  cecUpcomingEventData: any;

  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router: Router) { 
    let showFlag = true
    this.cecSubHeaderList = (window.innerWidth > 991)?["Explore Our Venues", "Plan Your Event", "PLAN YOUR WEDDING", "Getting Here"] :
    ["About", "Explore", "Plan", 'Getting Here', "Going Beyond", "Testimonials", "PLAN YOUR WEDDING", "FAQs"];
    this.exploreId = (window.innerWidth > 991) ? 'explore-our-venues' :  'explore';
    this.planId = (window.innerWidth > 991) ? 'plan-your-event' :  'plan';
    this._sharedService.getCommonService().successfulSubscription.subscribe(
      (data) => {
        this.successfulSubscription = data;
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          const routeFragment: string = this.activatedRoute.snapshot.fragment;
          if (routeFragment) showFlag = false;
          this._sharedService.callWindowNotificationClose();
          if(showFlag) {
            this._sharedService.callWindowNotification();
          } else {
            this._sharedService.callWindowNotificationClose();
          }
        }
    })
  }

  ngOnInit() {
    this.getCecPageContent();
    this.callCecCTEvent("Page_Load_JWCC", "", "Jio World Convention Centre");
    this._sharedService.getCommonService().onSubsciptionPageLoad();
    this.jsonLDforCecGeneral = this._sharedService.getSafeHTML(schemaJson.faqCecGeneralSchema);
    this.jsonLDforCecBooking = this._sharedService.getSafeHTML(schemaJson.faqCecServicesSchema);
    this.jsonLDforCecAas = this._sharedService.getSafeHTML(schemaJson.faqCecAroundSchema);
    this.jsonLDforCecPlanAVisit = this._sharedService.getSafeHTML(schemaJson.faqCecAroundSchema);
   // this._sharedService.callFractionSlider();
  }

  getCecPageContent() {
    let keyList = [
      39281, // Hero Banner
      39488, // Where Events Come to Life
      40140, // Infographic
      40047, // Plan Your Event
      40389, // Getting Here
      39794, // Our Service
      43211, // Organisers Speak
      39686, // Subscribe To Our World
      39995, // Discover Mumbai
      40527, // Customize Your Visit
      40583, // FAQs
      40152, // In The News
      'CEC-EXPLORE-OUR-VENUES',
      'SAFETY-KEY-HIGHLIGHTS', // Covid Key Highlights
      'CEC', // das-insta
      'CEC-UPCOMING-EVENT'
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
      (data: any) => {
        this.cecBannerData = data[0] ? data[0].data : data[0];
        this.eventsData = data[1] ? data[1].data : data[1];
        this.infographicsData = data[2] ? data[2].data : data[2];
        this.enquireData = data[3] ? data[3].data : data[3];
        this.accessWithEaseData = data[4] ? data[4].data : data[4];
        this.beyondConventionalData = data[5] ? data[5].data : data[5];
        this.organiserData = data[6] ? data[6].data : data[6];
        this.subscribeData = data[7] ? data[7].data : data[7];
        this.discoverMumbaiData = data[8] ? data[8].data : data[8];
        this.buildItineraryData = data[9] ? data[9].data : data[9];
        this.faqData = data[10] ? data[10].data : data[10];
        this.inNewsData = data[11] ? data[11].data : data[11];
        this.exploreVenuesData = data[12] ? data[12].data : data[12];
        this.covidKeyHighlightsData = data[13] ? data[13].data : data[13];
        this.cecInstagramData = data[14] ? data[14].data : data[14];
        this.cecUpcomingEventData = data[15] ? data[15].data : data[15];
        let sliderArray = [];
        data[15].data.cards.forEach(key => {
          if(key.HideEvent.data == "false"){
            sliderArray.push(key);
          }
        });
        this.cecUpcomingEventData.cards = sliderArray;
        // if (cecInstagramData) this.getTopFourInstaPosts(cecInstagramData);    
        this._sharedService.initializeCustomJs();
        this.cecSubHeaderList = this._sharedService.subHeaderItems(this.cecSubHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  // getTopFourInstaPosts(data) {
  //   if (data.length > 3) {
  //     data.forEach((element, i) => {
  //       if (i < 4) this.cecInstaData.push(element)
  //     })
  //   } else {
  //     this.cecInstaData = data;
  //   }
  // }

  onClickExplore(){
    this.router.navigate([
      '/jio-world-convention-centre/events/indian-gem-and-jewellery-show'
    ]);
  }

  getAutoSliderTime(input: any){
    if (input && input.data)
      return input.data.match(/(\d+)/)[0] * 1000 || 4000;
    else 
      return 4000;  
  }

  callCecCTEvent(eveName, secName, linkClicked) {
    this._sharedService.callCTEvent(eveName, secName, '', linkClicked);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
