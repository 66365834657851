import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
import { SafeHtml } from '@angular/platform-browser';
import { schemaJson } from '../../shared/schema.constant';
@Component({
  selector: 'app-das',
  templateUrl: './das.component.html',
  styleUrls: ['./das.component.css']
})
export class DasComponent implements OnInit {
  dasKeyArray: any[];
  subscription: any;
  topNotification: any;
  dasBannerData: any;
  bookFreePassesData: any;
  dasVisitSafelyData: any;
  dasGalleryData: any;
  dasInfographicsData: any;
  thingsToKnowData: any;
  dasGalleryShowcaseData: any;
  reviewsData: any;
  dasFaqData: any;
  dasSignUpData: any;
  schedulePlaylistData: any;
  dasSuccessfulSubscription: boolean = false;
  dasSubscriptionFlag: boolean = false;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  dasColorList = { primaryColor: '#A67D54', subscribeLineColor: "#A67D54", subscribeTextColor: "#4B4847", headerDataHoverColor: "#A67D54" };
  dasSubHeaderList;
  pageName: any;
  dasInstaData: any;
  jsonLDforDasGeneral: SafeHtml;
  jsonLDforDasBooking: SafeHtml;
  jsonLDforDasAas: SafeHtml;
  jsonLDforDasPlanAVisit: SafeHtml;

  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router: Router) {
    let flag = true
    this.pageName = this.activatedRoute.snapshot.data['page'];
    this.dasSubHeaderList = ["Schedule", "Things To Know", "Gallery", "FAQs", "Subscribe"]
    this._sharedService.getCommonService().successfulSubscription.subscribe(
      (data) => {
        this.dasSuccessfulSubscription = data;
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          const fragment: string = this.activatedRoute.snapshot.fragment;
          if (fragment) flag = false;
          if(flag) {
            this._sharedService.callWindowNotification();
          } else {
            this._sharedService.callWindowNotificationClose();
          }
          this._sharedService.callWindowNotificationClose();
        }
    })
  }

  ngOnInit() {
    this.callDasCTEvent("Page_Load_DAS", "", "Dhirubhai Ambani Square");
    this._sharedService.getCommonService().onSubsciptionPageLoad();
    this._sharedService.getCommonService().subscriptionFlag.subscribe(
      (data) => {
        this.dasSubscriptionFlag = data;
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
    this._sharedService.getCommonService().subscriptionDetails.subscribe(
      (data) => {
        this._sharedService.updateSubscriptionCTInfo(data, this.dasSubscriptionFlag, 'DAS_Newsletter subscription');
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
    this.getDasPageContent();
    this.jsonLDforDasGeneral = this._sharedService.getSafeHTML(schemaJson.faqDasGeneralSchema);
    this.jsonLDforDasBooking = this._sharedService.getSafeHTML(schemaJson.faqDasScheduleSchema);
    this.jsonLDforDasAas = this._sharedService.getSafeHTML(schemaJson.faqDasBookingSchema);
    this.jsonLDforDasPlanAVisit = this._sharedService.getSafeHTML(schemaJson.faqDasBookingSchema);
  }

  getDasPageContent() {
    this.dasKeyArray = [
      199325, // Top Notification
      199353, // Banner
      199378, // Book Free Passes
      199396, // Visit Safely
      199731, // Gallery
      199485, // Infographics
      199575, // Things to know
      199769, // Gallery Showcase
      199628, // Reviews
      199959, // FAQs
      199914, // Sign up
      203291,  // Schedule and Playlist
      'DAS' // instagram
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.dasKeyArray).subscribe(
      (data: any) => {
        this.topNotification = data[0] ? data[0].data : data[0];
        this.dasBannerData = data[1] ? data[1].data : data[1];
        this.bookFreePassesData = data[2] ? data[2].data : data[2];
        this.dasVisitSafelyData = data[3] ? data[3].data : data[3];
        this.dasGalleryData = data[4] ? data[4].data : data[4];
        this.dasInfographicsData = data[5] ? data[5].data : data[5];
        this.thingsToKnowData = data[6] ? data[6].data : data[6];
        this.dasGalleryShowcaseData = data[7] ? data[7].data : data[7];
        this.reviewsData = data[8] ? data[8].data : data[8];
        this.dasFaqData = data[9] ? data[9].data : data[9];
        this.dasSignUpData = data[10] ? data[10].data : data[10];
        this.schedulePlaylistData = data[11] ? data[11].data : data[11];
        this.dasInstaData = data[12] ? data[12].data : data[12];
          this._sharedService.initializeCustomJs();
          this._sharedService.load360Function();
        this.dasSubHeaderList = this._sharedService.subHeaderItems(this.dasSubHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  callDasCTEvents(eveName, secName?, contName?, statLink?, statLinkType?) {
    let link;
    if (statLinkType) {
      link = (statLinkType === 'video') ? 'vid-' + statLink : (statLinkType === '360 video') ? '360vid-' + statLink : (statLinkType === '360 Image') ? '360img-' + statLink : 'img-' + statLink
    } else {
      link = statLink
    }
    this._sharedService.callCTEvent(eveName, secName, contName, link)
  }

  callDasCTEvent(eveName, secName, linkClicked) {
    this._sharedService.callCTEvent(eveName, secName, '', linkClicked);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openBookNow(event, ctaLink, target, type, eventId) {
    this._sharedService.openBookNowAsGuest(event, ctaLink, target, type, eventId);
  }
}
