import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class StorageService {
  userDetails: Subject<any> = new BehaviorSubject<any>(null);
  constructor(
    // private cookieService: CookieService
  ) { }

  setSessionData(key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
      this.userDetails.next(true);
  }

  getSessionData(key: string) {
      let value = sessionStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      } else {
        return null;
      }
  }

  clearSessionData() {
      sessionStorage.clear();
  }

  getUserCookie() {
    // if (this.checkUserCookie()) {
    //   return JSON.parse(this.cookieService.get("USER"));
    // } else {
    //   return null;
    // }
  }

  deleteCookie() {}

  checkUserCookie() {
    // return this.cookieService.check("USER");
  }
}
