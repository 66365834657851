import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-highlights-section',
  templateUrl: './highlights-section.component.html',
  styleUrls: ['./highlights-section.component.css']
})
export class HighlightsSectionComponent implements OnInit {
  @Input() highlightsData;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  borderBottom: Map<number, string>;

  constructor(public _sharedService: SharedService) { 
    this.borderBottom = new Map([
      [0, "border_btm_blue"],
      [1, "border_btm_red"],
      [2, "border_btm_pink"]
    ]);
  }
  

  ngOnInit() { 
    this._sharedService.callNormalSlider();
  }

}
