import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MetaService } from 'src/app/shared/services/meta.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-cec-event-details',
  templateUrl: './cec-event-details.component.html',
  styleUrls: ['./cec-event-details.component.css']
})
export class CecEventDetailsComponent implements OnInit {
  cecSubHeaderList=[]
  cecColorList = { newsDataTitleColor: "#4B4847", newsDataLineColor: "#004EA8", newsDateColor: "#004EA8", newsCopyColor: "#FFFFFF", newsViewAllLineColor: "#004EA8", primaryColor: '#1E478E', experiencesDataLineColor: '#1E478E', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', subscribeDataBgColor: "#FFFFFF", subscribeDataTitleColor: "#4B4847", subscribeDataLineColor: "#004EA8", subscribeDotColor: "#004EA8", subscribeLineColor: "#004EA8", subscribeTextColor: "#4B4847", momentsIconColor: "#004EA8", dataSpeakerItemColor: "#004EA8", organiserTitleColor: "#4B4847", organiserLineColor: "#004EA8", headerDataHoverColor:"#1E478E", dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#004EA8' };
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  currentEvent: any;
  jsonLDForEvent: any;
  pdfName: any;
  constructor(public _sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private metaService: MetaService) { }

  ngOnInit(): void {
    // this._sharedService.callLoader()

    this.route.data.subscribe((data: any)=>{
      console.log(data);
      if(!data?.currentEvent){
        this.router.navigate(['/'])
      }
      this.currentEvent = data?.currentEvent;
      const metaTitle = this.currentEvent.EventMetaTitle? this.currentEvent.EventMetaTitle.data : this.currentEvent.EventTitle.data;
      const metaDesc = this.currentEvent.EventMetaDescription? this.currentEvent.EventMetaDescription.data : '';
      const script = this.currentEvent.EventSchemaScript ? this.currentEvent.EventSchemaScript.data : '';
      this.metaService.updatePageTag('title', metaTitle);
      this.metaService.updatePageTag('description', metaDesc);
      this.metaService.updatePageTitle(metaTitle);
      this.jsonLDForEvent =  this._sharedService.getSafeHTML(script, false);
    });

    this._sharedService.initializeCustomJs();
  }

  downloadFile(btn, sectionTitle, pdfValue?, linkClicked?) {
    switch (btn) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle, '', linkClicked);
        break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(pdfValue);
        this._sharedService.callCTEvent('Download_Links', sectionTitle, this.pdfName);
        break;
      case 'sticky':
        this._sharedService.callCTEvent('Sticky_Menu_Links', '', '', linkClicked);
    }
  }

}
