<div id="loginModal" class="modal fade" role="dialog">
  <div class="modal-dialog" [ngClass]="{'modal-lg':screen == 'registration'}">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div class="col-md-12">
          <h4 class="modal-title text-center"><b>Hello! {{(userDetails?.firstName)?userDetails?.firstName:'Welcome'}}</b></h4>
        </div>
        <form [formGroup]="loginForm" *ngIf="screen == 'sendOtp'">
          <!-- Phone No -->
          <div class="col-md-12">
            <div class="jwc_form_group">
              <input type="text" name="" value="" placeholder="Phone Number" class="form-control jwc_input" id=""
                formControlName="phoneNoInput" maxlength="10" appAllowedInput [numbersOnly]="true">
            </div>
            <div class="error-text"
              *ngIf="(loginFormControls.phoneNoInput.touched || submitted) && loginFormControls.phoneNoInput.errors">
              Enter 10 digit Mobile Number</div>
              <div class="error-text" *ngIf="showLoginErr">Server Error. Try again after some time..</div>
          </div>
          <div class="col-md-12">
            <div class="jwc_form_group--btn text-center">
              <button class="cta-prim color-gold otp-cta" (click)="loginSubmit(loginForm)">
                <span class="cta-prim-txt">Send OTP</span>
              </button>
            </div>
          </div>
        </form>
        <form [formGroup]="otpForm" *ngIf="screen == 'verifyOtp'">
          <div class="col-md-12 text-center">
            <ng-otp-input  (onInputChange)="onOtpChange($event)" #ngOtpInput [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
            <div class="error-text" style="margin-top: 4px;" *ngIf="showOtpErr">{{showOtpErr}}</div>
          </div>
          <div class="col-md-12">
            <div class="jwc_form_group--btn text-center">
              <button [ngClass]="{'disabled':disableOtpVerBtn}" class="cta-prim color-gold otp-cta" (click)="verifyOtp()">
                <span class="cta-prim-txt">Verify OTP</span>
              </button>
            </div>
          </div>
          <div class="col-md-12 justify-content-between otp-options">
            <a (click)="resendOtp()" *ngIf="!otpResent"><u>Resend OTP</u></a>
            <p *ngIf="otpResent" style="font-size: 14px; line-height: 16px">OTP resent</p>
            <div class="otp-valid-text">Otp is valid for {{min}}:{{sec}} min</div>
          </div>
        </form>
        <app-registration *ngIf="screen == 'registration'"></app-registration>
      </div>
    </div>

  </div>
</div>