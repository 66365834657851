<!-- <app-header [colorList]="newsroomColorList"></app-header> -->
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <!-- <div class="banner_small black_tint" [attr.style]="('--data-lineColor:#FFFFFF; --data-titleColor:#FFFFFF') | safe: 'style'"> -->
  <!-- <picture>
      <source media="(min-width: 767px)" srcset="./assets/images/newsroom/newsroom-banner.jpg">
      <source media="(max-width: 767px)" srcset="./assets/images/newsroom/newsroom-banner-mobile.jpg">
      <img src="./assets/images/newsroom/newsroom-banner.jpg" class="img-fluid" alt="Banner">
    </picture>
    <h1>Newsroom</h1> -->
  <!-- </div> -->
  <app-banner-new *ngIf="newsroomHeroBanner" [newBannerData]="newsroomHeroBanner"></app-banner-new>

  <div class="sub_page_content newsroom">
    <div class="faqs_top_links sticky-top">
      <div class="container-fluid">
        <div class="row sidebarlist">
          <div class="col-sm-12 list-btn "> <a href="javascript:;"><span class="nav-link-txt">PRESS RELEASES</span>
            </a> </div>
          <ul id="faqs-top-data" class="navbar-nav sidebarnav">
            <li class="nav-item"><a class="sidebarnav_link active" [routerLink]="'./'" [fragment]="'press-releases'">PRESS RELEASES</a></li>
            <li class="nav-item"><a class="sidebarnav_link" [routerLink]="'./'" [fragment]="'in-the-news'">IN THE NEWS</a></li>
            <!-- <li class="nav-item"><a class="sidebarnav_link" [routerLink]="'./'" [fragment]="'press-kit'">PRESS KIT</a></li> -->
            <li class="nav-item"><a class="sidebarnav_link" [routerLink]="'./'" [fragment]="'certificate'">CERTIFICATION</a></li>
            <!-- <li class="nav-item"><a class="sidebarnav_link" [fragment]="#section-4">ARCHIVE</a></li> -->
            <!-- <li class="nav-item"><a class="sidebarnav_link" [routerLink]="'./'" [fragment]="'enquire-now'">Enquire Now</a></li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="faqs_top_links sticky-top">
      <div class="container-fluid">
        <div class="row sidebarlist">
          <div class="col-sm-12 list-btn "> <a href="javascript:;"><span class="nav-link-txt">PRESS RELEASES</span>
            </a> </div>
          <ul id="faqs-top-data" class="navbar-nav sidebarnav">
            <li class="nav-item" *ngFor="let item of newsroomSubHeaderList; let i = index"><a class="sidebarnav_link" routerLink="./" [fragment]="getNewsroomFragmentId(item)" [ngClass]="{'active': i==0}">{{item}}</a></li>
          </ul>
        </div>
      </div>
    </div> -->
    <div id="press-releases" *ngIf="newsroomPressReleasesData && newsroomPressImagesData"
      [hidden]="newsroomPressImagesData?.HideComponentOnSite?.data=='true' && newsroomPressReleasesData?.HideComponentOnSite?.data=='true'">
      <section class="press-release prs-rleas" data-aos="fade-up"
        [attr.style]="('--data-experiences-titleColor:#4B4847;') | safe: 'style'">
        <div class="container" [hidden]="newsroomPressReleasesData?.HideComponentOnSite?.data=='true'">
          <div class="section_heading section_heading--title section_heading--text"
            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
            <h2 [innerHTML]="newsroomPressReleasesData?.ComponentHeaderTitle?.data"></h2>
            <p [innerHTML]="newsroomPressReleasesData?.ComponentHeaderSubTitle?.data"></p>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="filter">
              <div class="filter-mobile">
                <div class="filter-mobile-txt icon-filter">Filters</div> <span class="icon-arrow-down"></span>
              </div>
              <div class="filter-wrapper">
                <div class="press-release-tab" [ngStyle]="{width: newsroomPressImagesData?.HideComponentOnSite?.data==='true' || newsroomPressReleasesData?.HideComponentOnSite?.data==='true' ? '23%' : '35%'}">
                  <ul id="tabs-nav">
                    <li *ngIf="newsroomPressReleasesData?.HideComponentOnSite?.data!=='true'" [ngStyle]="{width: newsroomPressImagesData?.HideComponentOnSite?.data==='true' || newsroomPressReleasesData?.HideComponentOnSite?.data==='true' ? '100%' : '50%'}"><a href="#tab1" (click)="getTabName('pr')" >Press Releases</a></li>
                    <li *ngIf="newsroomPressImagesData?.HideComponentOnSite?.data!=='true'" [ngStyle]="{width: newsroomPressImagesData?.HideComponentOnSite?.data==='true' || newsroomPressReleasesData?.HideComponentOnSite?.data==='true' ? '100%' : '50%'}"><a href="#tab2" (click)="getTabName('pi')">Press Images</a></li>
                  </ul>
                </div>
                <div class="filter-dropdown">
                  <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                    <div class="dropdown--select">BRAND</div>
                    <div class="dropdown--list" aria-expanded="false" role="list">
                      <ul>
                        <li tabindex="0" *ngFor="let item of brandList" (click)="getBrandName(item?.id)">{{item?.key}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="filter-dropdown">
                  <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                    <div class="dropdown--select sort-dropdown">SORT BY</div>
                    <div class="dropdown--list" aria-expanded="false" role="list">
                      <ul>
                        <li tabindex="0" (click)="sortBy('new')">Sort By</li>
                        <li tabindex="0" (click)="sortBy('new')">Newest</li>
                        <li tabindex="0" (click)="sortBy('old')">Oldest</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="pressrelease-search">
                  <input type="text" placeholder="Search by Event, DATE or Keyword" [(ngModel)]="searchModel">
                  <button class="search-button icon icon-search"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tabs-content">
          <div id="tab1" class="tab-content">
            <div class="container">
              <div class="press-release">
                <div class="press-release-list"
                  *ngFor="let item of filteredPressReleaseData | multiListFilter : searchModel | slice:0:[max]; let i=index">
                  <h4>{{item?.MainHeader?.data}} <span>{{item?.ReleaseDate?.data | date: 'dd/MM/yy'}}</span></h4>
                  <p>{{item?.SubHeader?.data}}</p>
                  <a href="{{item?.CTALink?.data}}" *ngIf="item?.HideCTAText?.data !== 'true'"
                    (click)="callNewsroomCTEvent('sec', newsroomPressReleasesData?.ComponentHeaderTitle?.data); _sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTALinkWindowPreference?.data, 'Internal')"
                    class="cta-sec color-gold"><span class="cta-sec-txt">{{item?.CTAText?.data}}</span></a>
                  <span *ngIf="item?.HideMediaKitCTAText?.data!=='true'" class="download-icon">
                    <a class="cta-sec color-gold" href="{{item?.MediaKitCTALink?.data}}"
                      (click)="downloadFile('download', '', item?.MediaKitCTALink?.data, ''); _sharedService.checkCTALink($event, item?.MediaKitCTALink?.data, '_self', 'PDF', pdfName)">
                      <span class="cta-sec-txt">{{item?.MediaKitCTAText?.data}}</span>
                    </a>
                  </span>
                </div>
              </div>
              <div
                *ngIf="filteredPressReleaseData.length===0 || (filteredPressReleaseData | multiListFilter : searchModel).length === 0">
                No Result Found</div>
              <div
                *ngIf="((filteredPressReleaseData.length!==0 && !hideLoadMoreCTA) && (filteredPressReleaseData | multiListFilter : searchModel).length !== 0)"
                class="cta-holder"><a href="javascript:void(0)" (click)="loadMoreClicked()"
                  class="cta-sec color-gold"><span class="cta-sec-txt">Load
                    more</span></a></div>
            </div>
          </div>

          <div id="tab2" class="tab-content" *ngIf="newsroomPressImagesData?.HideComponentOnSite?.data!=='true'">
            <div class="container">
              <div class="press-images">

                <div class="press-images_list">
                  <div class="row">
                    <div class="col-lg-4 col-md-6"
                      *ngFor="let item of filteredPressImagesData | multiListFilter : searchModel | slice:0:[pimax]; let i=index">
                      <div class="press-images_list_each" data-aos="fade-up">
                        <div class="press-images_list_each--video"
                          *ngIf="item?.ImageVideo?.document?.fileExtension =='mp4'; else imgTemplate">
                          <video autoplay muted loop playsInline>
                            <source src="{{staticUrlForImgNew + item?.ImageVideo?.document?.contentUrl}}"
                              type="video/mp4">
                            Your browser does not support HTML5 video.
                          </video>
                        </div>
                        <ng-template #imgTemplate>
                          <div class="press-images_list_each--img">
                            <img src="{{staticUrlForImgNew + item?.ImageVideo?.document?.contentUrl}}" class="img-fluid"
                              alt="">
                          </div>
                        </ng-template>
                        <div class="press-images_list_each_copy">
                          <div class="press-images_list_each_copy--date">{{item?.MainHeader?.data}}
                            <span>{{item?.ReleaseDate?.data | date: 'dd/MM/yy'}}</span></div>
                          <h4 class="press-images_list_each_copy--title">{{item?.SubHeader?.data}}
                          </h4>
                          <a href="{{item?.CTALink?.data}}" *ngIf="item?.HideCTAText?.data !== 'true'"
                            (click)="callNewsroomCTEvent('sec', newsroomPressReleasesData?.ComponentHeaderTitle?.data); _sharedService.checkCTALink($event, item?.CTALink?.data, '_self', 'PDF', _sharedService.getPdfName(item?.CTALink?.data))"
                            class="cta-sec icon rh icon-download-icon color-gold"><span
                              class="cta-sec-txt">{{item?.CTAText?.data}}</span></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="filteredPressImagesData.length===0 || (filteredPressImagesData | multiListFilter : searchModel).length === 0">
                No Result Found</div>
              <div
                *ngIf="filteredPressImagesData.length!==0 && !hidePiLoadMoreCTA && (filteredPressImagesData | multiListFilter : searchModel).length !== 0"
                class="cta-holder"><a href="javascript:void(0)" (click)="loadMorePiClicked()"
                  class="cta-sec color-gold"><span class="cta-sec-txt">Load
                    more</span></a></div>

            </div>
          </div>
        </div>
      </section>
    </div>
    <div id="in-the-news">
      <section class="news_wrapper plr-0 "
        [attr.style]="('--data-news-bgColor1:#F5F2ED;--data-news-bgColor2:#F5F2ED;--data-news-dateColor:#AF8F63;--data-news-copyColor:#FFFFFF') | safe: 'style'">
        <div class="container">
          <div class="section_heading section_heading--title section_heading--text text-center   "
            [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor:#C69E67') | safe: 'style'">
            <h2>{{newsroomInTheNews?.ComponentHeaderTitle?.data}}</h2>
          </div>

        </div>


        <div class="container">

          <div class="news_slider jwc_swiper_normal4 jwc_swiper_normal4_0" data-space="24" data-interval="5"
            data-slides="4" data-ipad-slides="3.2" data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
            <div class="swiper-container swiper-container-initialized swiper-container-horizontal scrollabe-cls">
              <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
                <div class="swiper-slide swiper-slide-active" style="width: 214px; margin-right: 24px;"
                  *ngFor="let item of newsroomInTheNews?.cards">
                  <div class="news_wrapper_each">
                    <div class="news_wrapper_each--img" style="background-color: #ffffff;">
                      <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                        alt="{{item?.ImageAltText?.data}}" style="height: 25vh;"/>
                    </div>
                    <div class="news_wrapper_each_copy">
                      <span class="news_wrapper_each_copy--date">{{item?.Date?.data | date: 'dd/MM/yy'}}</span>
                      <h5 class="news_wrapper_each_copy--text" [innerHtml]="item?.CardShortText?.data">
                      </h5>
                      <div class="news_wrapper_each_copy--link">
                        <!-- <a class="cta-sec color-gold" href="{{item?.CTALink?.data}}"
                          *ngIf="item?.HideCTAText?.data =='false'"
                          (click)="callNewsroomCTEvent('download', item?.CardShortText?.data, item?.CTALink?.data); _sharedService.checkCTALink($event, item?.CTALink?.data, '_self', 'PDF', pdfName)"><span
                            class="cta-sec-txt">{{item?.CTAText?.data}}</span></a> -->
                            <a *ngIf="item?.HideCTAText?.data =='false'"
                    class="cta-prim color-gold" href="{{item?.CTALink?.data}}"
                    (click)="_sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTALinkWindowPreference?.data, item?.TypeOfCTALink?.data)">
                    <span class="cta-prim-txt">{{item?.CTAText?.data}}</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
            </div>
          </div>

          <div class="news_wrapper--all">
            <a class="cta-sec color-gold" href="{{newsroomInTheNews?.ViewAllCTALink?.data}}"
              *ngIf="newsroomInTheNews?.HideViewAllCTAText?.data =='false'"
              (click)="callNewsroomCTEvent('download', newsroomInTheNews?.ComponentHeaderTitle?.data, newsroomInTheNews?.ViewAllCTALink?.data); _sharedService.checkCTALink($event, newsroomInTheNews?.ViewAllCTALink?.data, '_self', 'PDF', pdfName)"><span
                class="cta-sec-txt">{{newsroomInTheNews?.ViewAllCTAText?.data}}</span></a>
          </div>
        </div>

      </section>
    </div>
    <!-- <div id="in-the-news"> -->
    <!-- <section class="news_wrapper plr-0 "
        style="--data-news-bgColor1:#F5F2ED;--data-news-bgColor2:#F5F2ED;--data-news-dateColor:#AF8F63;--data-news-copyColor:#FFFFFF">
        <div class="container">
          <div class="section_heading section_heading--title text-center" style="--data-titleColor:#2C2C2C;--data-lineColor:#C69E67">
              <h2>In The News</h2>
          </div>
        </div>


        <div class="container">

          <div class="news_slider jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="4"
            data-ipad-slides="3.2" data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="news_wrapper_each">
                    <div class="news_wrapper_each--img">
                      <img src="./assets/images/news/9.jpg" class="img-fluid" alt="highlight 1">
                    </div>
                    <div class="news_wrapper_each_copy">
                      <span class="news_wrapper_each_copy--date">04/06/20</span>
                      <h5 class="news_wrapper_each_copy--text">The ACC brings iconic
                        Broadway musical "The Lion
                      </h5>
                      <div class="news_wrapper_each_copy--link">
                        <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">READ
                            MORE</span></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="news_wrapper_each">
                    <div class="news_wrapper_each--img">
                      <img src="./assets/images/news/10.jpg" class="img-fluid" alt="highlight 2">
                    </div>
                    <div class="news_wrapper_each_copy">
                      <span class="news_wrapper_each_copy--date">04/06/20</span>
                      <h5 class="news_wrapper_each_copy--text">Jio World Plaza opens its doors to the luxury
                        brands that you have</h5>
                      <div class="news_wrapper_each_copy--link">
                        <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">READ
                            MORE</span></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="news_wrapper_each">
                    <div class="news_wrapper_each--img">
                      <img src="./assets/images/news/11.jpg" class="img-fluid" alt="highlight 3">
                    </div>
                    <div class="news_wrapper_each_copy">
                      <span class="news_wrapper_each_copy--date">04/06/20</span>
                      <h5 class="news_wrapper_each_copy--text">Jio World Convention Centre all set to welcome
                        young</h5>
                      <div class="news_wrapper_each_copy--link">
                        <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">READ
                            MORE</span></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="news_wrapper_each">
                    <div class="news_wrapper_each--img">
                      <img src="./assets/images/news/12.jpg" class="img-fluid" alt="highlight 3">
                    </div>
                    <div class="news_wrapper_each_copy">
                      <span class="news_wrapper_each_copy--date">04/06/20</span>
                      <h5 class="news_wrapper_each_copy--text">"Bloom", acclaimed international human
                      </h5>
                      <div class="news_wrapper_each_copy--link">
                        <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">READ
                            MORE</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="news_wrapper--all">
            <a href="all-news.html" class="cta-sec color-gold"><span class="cta-sec-txt">View All</span></a>
          </div>
        </div>

      </section> -->
    <!-- </div> -->
    <div id="certificate">
      <section class="plr-0 "
      [attr.style]="('--data-news-bgColor1:#F5F2ED;--data-news-bgColor2:#F5F2ED;--data-news-dateColor:#AF8F63;--data-news-copyColor:#FFFFFF') | safe: 'style'">
      <div class="container">
        <div class="section_heading--title section_heading--text"
          [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
          <h2>Certificate</h2>
        </div>
      </div>
        <div class="tabs-content">
          <div id="tab1" class="tab-content">
            <div class="container">
              <div class="new-room">
                <div class="new-room-css mb-4"
                  *ngFor="let item of certificatedata">
                  <h4>{{item?.MainHeader?.data}} <span>{{item?.ReleaseDate?.data | date: 'dd/MM/yy'}}</span></h4>
                  <p>{{item?.SubHeader?.data}}</p>
                  <span *ngIf="item?.HideMediaKitCTAText?.data!=='true'">
                    <span class="cta-sec color-gold" 
                      (click)="downloadFileCerticate('download', '', item?.MediaKitCTALink?.data, '')">
                      <span class="cta-sec-txt">{{item?.MediaKitCTAText?.data}}</span>
                  </span>
                  </span>
                </div>
              </div>
              <div
                *ngIf="certificatedata.length == 0">
                No Result Found</div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div id="press-kit">
    <section *ngIf="newsroomPressKitData" class="press-kit pb-0" data-aos="fade-up"
      [attr.style]="('--data-experiences-titleColor:#4B4847;--data-underline-textColor: #a67d54;--data-underline-color: #a67d54')"
      [hidden]="newsroomPressKitData?.HideComponentOnSite?.data=='true'">
      <div class="">
        <div class="container">
          <div class="section_heading section_heading--title section_heading--text"
            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54')">
            <h2 [innerHTML]="newsroomPressKitData?.ComponentSubHeaderTitle?.data"></h2>
            <p [innerHTML]="newsroomPressKitData?.ComponentHeaderSubTitle?.data"></p>
          </div>
          <div class="container press-kit_txt">
            <h4>{{newsroomPressKitData?.ComponentHeaderTitle?.data}}</h4>
            <p [innerHtml]="newsroomPressKitData?.ComponentSubHeaderContent?.data"></p>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="filter">
              <div class="filter-mobile">
                <div class="filter-mobile-txt icon-filter">Filters</div> <span class="icon-arrow-down"></span>
              </div>
              <div class="filter-wrapper">
                <div class="filter-dropdown">
                  <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                    <div class="dropdown--select">BRAND</div>
                    <div class="dropdown--list" aria-expanded="false" role="list">
                      <ul>
                        <li tabindex="0" *ngFor="let item of brandList" (click)="getFilteredAssets(item?.id)">{{item?.key}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="filter-check">
                  <div class="filter-check-title">SELECT ASSESTS</div>
                  <div class="check-box icon-tick active"
                  (click)="isAllSelected = !isAllSelected;selectedBox('all',isAllSelected, isAllSelected)">ALL</div>
                  <div class="check-box icon-tick active"
                    (click)="isLogoSelected = !isLogoSelected;selectedBox('logo',isLogoSelected, isLogoSelected)" [ngClass]="{'active': isAllSelected}">LOGO</div>
                  <div class="check-box icon-tick active"
                    (click)="isColorSelected=!isColorSelected;selectedBox('color',isColorSelected, isColorSelected)"[ngClass]="{'active': isAllSelected}">COLOR</div>
                  <div class="check-box icon-tick active"
                    (click)="isImgSelected = !isImgSelected;selectedBox('image',isImgSelected,isImgSelected)"[ngClass]="{'active': isAllSelected}">IMAGES</div>
                  <div class="check-box icon-tick active"
                    (click)="isVidSelected = !isVidSelected;selectedBox('video',isVidSelected, isVidSelected)"[ngClass]="{'active': isAllSelected}">Video</div>
                </div>
                <div *ngIf="isBrowser" class="filter-cta"><a
                    (click)="_sharedService.downloadAllDocuments(newsroomPressKitDownloadDocs, 'Press-Kit-All-Docs')"
                    class="cta-sec icon rh icon-download-icon color-gold"><span class="cta-sec-txt">Download
                      all</span></a></div>
              </div>
            </div>
          </div>
        </div>
        <div class="container" style="min-height: 30vh;">
          <div class="press-kit">

            <div class="press-kit_list">
              <div class="row">
                <div class="col-lg-4 col-md-6" *ngFor="let item of filteredPressKitData; let i=index">
                  <div class="press-kit_list_each aos-init aos-animate" data-aos="fade-up">
                    <div class="press-kit_list_each--img">
                      <img src="{{staticUrlForImgNew+ item?.ImageVideo?.document?.contentUrl}}" class="img-fluid"
                        alt="">
                    </div>
                    <div class="press-kit_list_each_copy">
                      <div class="press-kit_list_each_copy--tag">{{item?.PressImageMainHeader?.data}}</div>
                      <h5 class="press-kit_list_each_copy--title">{{item?.PressImageSubHeader?.data}}</h5>
                      <a class="cta-sec icon rh icon-download-icon color-gold" href="{{item?.PressImageCTALink?.data}}"
                        *ngIf="item?.PressImageHideCTAText?.data =='false'"
                        (click)="callNewsroomCTEvent('download', newsroomPressKitData?.ComponentSubHeaderTitle?.data, item?.PressImageCTALink?.data); _sharedService.checkCTALink($event, item?.PressImageCTALink?.data, '_self', 'PDF', pdfName)"><span
                          class="cta-sec-txt">{{item?.PressImageCTAText?.data}}</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    <div id="archive" [hidden]="newsroomArchiveData?.HideComponentOnSite?.data=='true'">
      <app-get-in-touch *ngIf="newsroomArchiveData" [getInTouchData]="newsroomArchiveData"
        [colorList]="newsroomColorList" [pageName]="'homeWithoutEmpClass'" [sectionClass]="'pb-0'"></app-get-in-touch>
    </div>
    <div id="enquire-now" [hidden]="newsroomEnquiryData?.HideComponentOnSite?.data=='true'">
      <app-get-in-touch *ngIf="newsroomEnquiryData" [getInTouchData]="newsroomEnquiryData"
        [colorList]="newsroomColorList" [pageName]="'homeWithoutEmpClass'">
      </app-get-in-touch>
    </div>
  </div>
</main>
<div class="overlay"></div>