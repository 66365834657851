import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
    subscription;
    aboutBannerData;
    globalDestinationData;
    ourStoryData;
    ourWorldData;
    aboutAwardsData;
    platinumData;
    carersData;
    aboutColorList = { subscribeLineColor: "#A67D54", subscribeTextColor: "#4B4847", dataNewsletter2LineColor: '#1E478E', dataPlanLineColor: '#A67D54' }
    staticUrlForImgNew: string = environment.staticUrlForImgNew;
    constructor(public _sharedService: SharedService, private titleService: Title) { }
    ngOnInit() {
        this.getAboutPageContent();
    }

    getAboutPageContent() {
        let keyList = [
            'ABOUT-BANNER', // Hero Banner
            'ABOUT-A-GLOBAL-DESTINATION', // Global Destination
            'ABOUT-OUR-STORY', // Our Story
            36007, // our world
            'ABOUT-AWARDS', // About Awards
            'ABOUT-PLATINUM-PRE-CERTIFIED', // Platinum pre certified
            'ABOUT-CAREERS-AT-JIOWORLD-CENTRE' // About carers
        ];
        this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
            (data: any) => {
                this.aboutBannerData = data[0] ? data[0].data : data[0];
                this.globalDestinationData = data[1] ? data[1].data : data[1];
                this.ourStoryData = data[2] ? data[2].data : data[2];
                this.ourWorldData = data[3] ? data[3].data : data[3];
                this.aboutAwardsData = data[4] ? data[4].data : data[4];
                this.platinumData = data[5] ? data[5].data : data[5];
                this.carersData = data[6] ? data[6].data : data[6];
                this._sharedService.initializeCustomJs();
                this._sharedService.callFractionSlider();
                setTimeout(() => {
                    this._sharedService.callAccordianWithSlider();
                }, 800);
            },
            (error) => {
                console.log("Try after some time..");
            }
        )
    }

    callCtaPrimaryLinkEvent() {
        this._sharedService.callCTEvent('Section_Primary_Links', this.carersData.ComponentHeaderTitle.data);
    }

}
