import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-banner-new',
  templateUrl: './banner-new.component.html',
  styleUrls: ['./banner-new.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BannerNewComponent implements OnInit {
  @Input() newBannerData;
  AutomaticSlideshowInterval: number = 4000;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  screenWidth = 0;
  screenHeight = 0;
  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;  
    this.screenHeight = window.innerHeight;
    console.log(this.newBannerData);
    if (this.newBannerData?.AutomaticSlideshowInterval?.data) 
      this.AutomaticSlideshowInterval = this.newBannerData.AutomaticSlideshowInterval.data.match(/(\d+)/)?.[0] * 1000 || 4000;
    this._sharedService.initializeCustomJs();
    this._sharedService.load360Function();
  }
  callNewBannerCTEvent(eveName, linkClicked) {
    this._sharedService.callCTEvent(eveName, 'Banner', '', linkClicked);
  }

  getPlaceHolderImageForVideo(item) {
    return this.staticUrlForImgNew + (item?.PlaceholderImageForVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForVideoUploaded?.image?.contentUrl);
  }

  getImageVideoUrl(item){
    return this.staticUrlForImgNew + (item?.UploadImageVideo?.document?.contentUrl || item?.UploadImageVideoForBanner?.document?.contentUrl);
  }

  getMobileImageVideoUrl(item){
    return this.staticUrlForImgNew + (item?.UploadMobileKMAImageVideo?.document?.contentUrl || item?.UploadMobileKMAImageVideoForBanner?.document?.contentUrl);
  }

  @HostListener('window:resize', ['$event'])  
  onResize(event) {  
   
    this.screenWidth = window.innerWidth;  
    this.screenHeight = window.innerHeight;  
    // console.log({
    //   resizeSreenWidth: this.screenWidth,
    //   resizeScreenHeight: this.screenHeight
    // })
  } 

}
