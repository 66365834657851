import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { constants } from 'src/app/shared/shared.constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logistics-cec',
  templateUrl: './logistics-cec.component.html',
  styleUrls: ['./logistics-cec.component.css']
})
export class LogisticsCecComponent implements OnInit {

  logisticsInquiryForm: FormGroup;
  inquiryType = 'Logistics Services';
  selectedBrand = 'Jio World Convention Centre';
  // @Input() selectedBrand;
  errorText = 'Field is required';
  isCaptchaSelected;
  submitted = false;
  venueCecData;
  venueCecArrData;
  @Output() inquiryTypeValue = new EventEmitter();
  @Output() brandValue = new EventEmitter();
  @Input() inquiryData;
  @Input() captchaSiteKey;
  disableBtn;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  @Input() set disableSubmitBtn(value) {
    this.disableBtn = value;
  }
  apiErrorMsg="";
  grcToken =  "XXXXXXXXXXX";

  constructor(public _sharedService: SharedService, private formBuilder: FormBuilder, private router: Router) { 
    this._sharedService.inquiryApiErrorMsg.subscribe(
      (data) => {
        if (data['error'].message) {
          this.apiErrorMsg = "Server error. Please try after some time...";
          // this.apiErrorMsg = data['error'].message;
        }
      }
    );
  }

  ngOnInit() {
    let prefilledData = this._sharedService.getInquiryFormValues();
    this.venueCecData = this.inquiryData.cards[1];
    this.venueCecArrData = this.venueCecData.cec;
    this.logisticsInquiryForm = this.formBuilder.group({
      inquiryType: ['Logistics Services'],
      brand: ['CEC'],
      nameInput: [(prefilledData.name)?prefilledData.name:'', Validators.required],
      eventNameInput: [(prefilledData.company)?prefilledData.company:''],
      emailInput: [(prefilledData.email)?prefilledData.email:'', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      countryCode: ['+91'],
      phoneNoInput: [(prefilledData.phoneNo) ? prefilledData.phoneNo : '', [Validators.required, Validators.minLength(10)]],
      messageInput: [(prefilledData.message)?prefilledData.message:'', Validators.required],
      userType: this.formBuilder.array([]),
      firstConsent: [false, Validators.requiredTrue],
      secondConsent : [false]
    });
    this._sharedService.callCustomDropdown()
  }
  setDropdownValue(formControl, value) {
    this.logisticsInquiryForm.get(formControl).setValue(value);
  }
  get logisticsInquiryFormControls() { return this.logisticsInquiryForm.controls; }
  
  onUserTypeCheckboxClick(formArrName, event) {
    let tempUserTypeArray: FormArray = this.logisticsInquiryForm.get(formArrName) as FormArray;
    if (event.target.checked) {
      tempUserTypeArray.push(new FormControl(event.target.value));
    } 
    else {
      let i: number = 0;
      tempUserTypeArray.controls.forEach((item: FormControl) => {
        if (item.value == event.target.value) {
          tempUserTypeArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  sendGjRouteObj(name) {
    this.router.navigate([], {
      state: { tabName: name }
    }).then(result => {  window.open(this.staticUrlForImgNew + '/legal', '_blank'); });;
    return false;
  }

  logisticsCecSubmit(form) {
    this.submitted = true;
    // if(!this.isCaptchaSelected) return;
    let payload = form.value;
    payload.grcToken = this.grcToken;
    // console.log("form values:", form.value);
    if (form.invalid || !this.isCaptchaSelected) {
      return;
    }
    this.disableBtn = true;
    let logisticsCecEveObj = {
      Enquiry_Type: payload.inquiryType,
      Brand_Name: payload.brand,
      Organiser_Name: payload.nameInput,
      Organiser_Email: payload.emailInput,
      Organiser_Phone: payload.countryCode + payload.phoneNoInput,
      Organiser_Profile: payload.userType,
      // Event_Name: 'Enquiry_Forms'
    };
    let logisticsCecCTProfile = {
      Organiser_Name: payload.nameInput,
      Organiser_Email: payload.emailInput,
      Organiser_Phone: payload.countryCode + payload.phoneNoInput
    };
    this._sharedService.submitInquiryForm(payload, logisticsCecEveObj, logisticsCecCTProfile);
  }

  captchaRes($event, flag?) {
    if(!$event) return this.isCaptchaSelected = false;
    if(flag) return console.log('Something went long when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true
    this.grcToken = $event;
  }

  ngOnDestroy() {
    let obj = {
      name: (this.logisticsInquiryFormControls.nameInput.value)?this.logisticsInquiryFormControls.nameInput.value : '',
      email: (this.logisticsInquiryFormControls.emailInput.value)?this.logisticsInquiryFormControls.emailInput.value: '',
      company: '',
      message: (this.logisticsInquiryFormControls.messageInput.value)?this.logisticsInquiryFormControls.messageInput.value : ''
    }
    this._sharedService.setInquiryFormValues(obj.name, obj.email, obj.company, obj.message)
  }
}
