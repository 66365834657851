import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-our-world',
  templateUrl: './our-world.component.html',
  styleUrls: ['./our-world.component.css']
})
export class OurWorldComponent implements OnInit {
  @Input() ourWorldData;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(private titleService: Title, public _sharedService: SharedService) { }

  ngOnInit() {
  }
  callCTOurWorldEvent(val) {
    this._sharedService.callCTEvent('Our_World_Slider', this.ourWorldData.ComponentHeaderTitle.data, '', val);
  }

}
