<section class="experiences_slider full_width mob_no_space moments">
    <!-- [ngClass]="{'mob_no_space': (colorList?.primaryColor == '#43B02A' || colorList?.primaryColor == '#C69E67')}" -->
    <div class="container"></div>
    <div class="section_heading section_heading--title section_heading--text text-center text_bold" [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor: ' + colorList?.primaryColor) | safe: 'style'">
            <h2>Jio World Centre Moments</h2>
            <p> <span class="icon icon-instagram" [attr.style]="('--data-icon-color: ' + colorList?.momentsIconColor) | safe: 'style'">@{{handleName}}</span>
            </p>
    </div>
    <div class="moments_slider swiper_fraction jwc_swiper_fraction" data-interval="5" data-space="40" data-slides="4.35"  data-ipad-slides="3.5">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of instaFeedData; let i=index">
                    <div class="moments_slider_each">
                        <div class="moments_slider_each--img">
                            <a href="{{instaFeedUrl + item?.node?.shortcode}}" target="_blank">
                                <img loading="lazy" src="{{item?.node?.display_url}}" class="img-fluid"
                                    alt="slider {{i+1}}">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-arrows">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </div>
    </div>
    <div class="moments_slider--follow text-center">
        <a (click)="_sharedService.checkCTALink($event)" class="cta-sec"
            [ngClass]="colorClass.get(colorList?.primaryColor)">
            <span class="cta-sec-txt">FOLLOW US</span>
        </a>
    </div>
</section>