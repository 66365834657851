import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { constants } from '../../../shared/shared.constant'

@Component({
  selector: 'app-general-jwc',
  templateUrl: './general-jwc.component.html',
  styleUrls: ['./general-jwc.component.css'],
})
export class GeneralJwcComponent implements OnInit, OnDestroy {
  inquiryForm: FormGroup;
  inquiryType = 'General';
  // selectedBrand = 'Jio World Centre';
  @Input() selectedBrand;
  errorText = 'Field is required';
  isCaptchaSelected;
  submitted = false;
  generalJWCData;
  @Output() inquiryTypeValue = new EventEmitter();
  @Output() brandValue = new EventEmitter();
  @Input() inquiryData;
  @Input() captchaSiteKey;
  disableBtn;
  @Input() set disableSubmitBtn(value) {
    this.disableBtn = value;
  }
  staticUrlForImgNew: string = environment.staticUrlForImgNew;  
  apiErrorMsg="";
  
  grcToken =  "XXXXXXXXXXX";

  constructor(public _sharedService: SharedService, private formBuilder: FormBuilder, private router: Router) { 
    this._sharedService.inquiryApiErrorMsg.subscribe(
      (data) => {
        if (data['error'].message) {
          this.apiErrorMsg = "Server error. Please try after some time...";
          // this.apiErrorMsg = data['error'].message;
        }
      }
    );
  }

  ngOnInit() {
    let prefilledData = this._sharedService.getInquiryFormValues();
    this.generalJWCData = this.inquiryData.cards[0];
    this.inquiryForm = this.formBuilder.group({
      inquiryType: ['General'],
      brand: [(this.selectedBrand == 'The Cultural Centre') ? 'TCC' : (this.selectedBrand == 'Fountain of Joy - Dhirubhai Ambani Square')?'DAS':'JWC'],
      nameInput: [(prefilledData.name)?prefilledData.name:'', Validators.required],
      companyInput: [(prefilledData.company)?prefilledData.company:''],
      emailInput: [(prefilledData.email)?prefilledData.email:'', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      messageInput: [(prefilledData.message)?prefilledData.message:'', Validators.required],
      firstConsent: [false, Validators.requiredTrue],
      secondConsent: [false]
    });
    this._sharedService.callCustomDropdown()
  }
  setDropdownValue(formControl, value) {
    this.inquiryForm.get(formControl).setValue(value);
  }
  get inquiryFormControls() { return this.inquiryForm.controls; }

  sendGjRouteObj(name) {
    this.router.navigate(['/legal'], {
      state: { tabName: name }
    });
    return false;
  }

  generalHomeSubmit(form) {
    this.submitted = true;
    // if(!this.isCaptchaSelected) return;
    let payload = form.value;
    payload.grcToken = this.grcToken;
    // console.log("form values:", form.value);
    if (form.invalid || !this.isCaptchaSelected) {
      return;
    }
    this.disableBtn = true;
    let generalJwcEveObj = {
      Enquiry_Type: payload.inquiryType,
      Brand_Name: payload.brand,
      Organiser_Name: payload.nameInput,
      Organiser_Email: payload.emailInput,
      Organiser_Company_Name:payload.companyInput
      // Event_Name: 'Enquiry_Forms'
    };
    let generalJwcCTProfile = {
      Organiser_Name: payload.nameInput,
      Organiser_Email: payload.emailInput,
      Organiser_Company_Name:payload.companyInput
    };
    this._sharedService.submitInquiryForm(payload, generalJwcEveObj, generalJwcCTProfile);
  }

  captchaRes($event, flag?) {
    if(!$event) return this.isCaptchaSelected = false;
    if(flag) return console.log('Something went long when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true
    this.grcToken = $event;
  }

  ngOnDestroy() {
    let obj = {
      name: (this.inquiryFormControls.nameInput.value)?this.inquiryFormControls.nameInput.value : '',
      email: (this.inquiryFormControls.emailInput.value)?this.inquiryFormControls.emailInput.value: '',
      company: (this.inquiryFormControls.companyInput.value)?this.inquiryFormControls.companyInput.value : '',
      message: (this.inquiryFormControls.messageInput.value)?this.inquiryFormControls.messageInput.value : ''
    }
    this._sharedService.setInquiryFormValues(obj.name, obj.email, obj.company, obj.message)
  }
}
