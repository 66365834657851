<!-- <app-sub-header [colorList]="landingColorList" [subHeaderList]="landingHeaderList" [pageName]="'home'"
*ngIf="showSubHeaderList"></app-sub-header> -->
<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <div id="backToTop">
        <img src="./assets/images/backToTop.svg" class="img-fluid" alt="back to top" />
        <p>BACK TO TOP</p>
    </div>
    <app-thank-you-page [brandName]="'Jio World Centre'" [subscriptionFlag]="subscriptionFlag"
        *ngIf="successfulSubscription"></app-thank-you-page>
    <!-- <app-banner [landingBannerData]="landingBannerData" [bannerPage]="'landing'" [fragmentId]="'our-world'"
        *ngIf="landingBannerData" [sectionClass]="'screen_height'" [blackTint]="true"></app-banner> -->
    <app-banner-new *ngIf="landingNewBannerData" [newBannerData]="landingNewBannerData"></app-banner-new>
    <app-covid2-section *ngIf="landingBannerData"></app-covid2-section>
    <section class="benefits_wrapper pb-0" *ngIf="landingEventsData && landingEventsData?.HideComponent?.data == 'false'">
        <div class="container">
            <div class="row align-items-center flex-row-reverse">
                <div class="col-lg-5 col-md-5">
                    <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-titleColor:#212529;--data-lineColor:#C69E67') | safe: 'style'">
                        <h2 class="different-fs" [innerHTML]="landingEventsData?.ComponentHeaderTitle?.data"></h2>
                        <p [innerHTML]="landingEventsData?.ComponentHeaderSubTitle?.data"></p>
                        <ng-container *ngIf="landingEventsData?.HideCTAText?.data =='false'">
                            <a *ngIf="landingEventsData?.CTALink?.data.includes('.mp4'); else otherCtaLink" [href]="landingEventsData?.CTALink?.data" class="watchVideoClick cta-prim color-gold" data-target="#video_box" [attr.data-url]="staticUrlForImgNew + landingEventsData?.CTALink?.data" data-toggle="modal" (click)="callLandingCTEvent('Media_Links', landingEventsData?.ComponentHeaderTitle?.data, landingEventsData?.CTAText?.data)"><span class="cta-prim-txt">{{landingEventsData?.CTAText?.data}}</span></a>
                            <ng-template #otherCtaLink>
                                <a (click)="callLandingCTEvent('Section_Primary_Links', landingEventsData?.ComponentHeaderTitle?.data, landingEventsData?.CTAText?.data); _sharedService.checkCTALink($event, landingEventsData?.CTALink?.data, landingEventsData?.CTALinkWindowPreference?.data, landingEventsData?.TypeOfCTALink?.data);"
                                class="cta-prim color-gold" href="{{landingEventsData?.CTALink?.data}}"
                                *ngIf="landingEventsData?.HideCTAText.data == 'false'">
                                    <span class="cta-prim-txt">{{landingEventsData?.CTAText?.data}}</span>
                                </a>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-7 col-md-7">
                    <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#C69E67') | safe: 'style'">
                        <div class="benefits_slider jwc_swiper_benefits" data-interval="4" [ngClass]="{'single_image': landingEventsData?.cards?.length == 1}">
                            <div class="swiper-container">
                              <div class="swiper-wrapper">
                                <div class="swiper-slide" *ngFor="let item of landingEventsData?.cards; let i=index">
                                  <div class="benefits_slider_each">
                                    <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                                      alt="{{item?.ImageAltText?.data}}">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="swiper-pagination" *ngIf="landingEventsData.cards.length > 1"></div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div *ngIf="ourWorldData" data-mobile-header="Our World" id="our-world">
        <app-our-world [ourWorldData]="ourWorldData"></app-our-world>
    </div>
    <div data-mobile-header="Highlights" id="highlights">
        <app-highlights-section *ngIf="highlightsData" [highlightsData]="highlightsData"></app-highlights-section>
    </div>  
    <section class="events_wrapper plr-0 pb-0"
        [attr.style]="('--data-events-bgColor1:#FFFFFF;--data-events-lineColor:#004EA8;--data-event-arrowColor:#4B4847') | safe: 'style'" *ngIf="landingUpcomingEventData && (!landingUpcomingEventData?.HideComponentOnSite || landingUpcomingEventData?.HideComponentOnSite?.data !== 'true')">
        <div class="container">
            <div class="section_heading section_heading--title section_heading--text text-center "
                [attr.style]="('--data-lineColor:#004EA8') | safe: 'style'">
                <h2 [innerHTML]="landingUpcomingEventData?.ComponentHeaderTitle?.data"></h2>
                <p *ngIf="landingUpcomingEventData?.ComponentHeaderSubTitle?.data && landingUpcomingEventData?.ComponentHeaderSubTitle?.data!=''">{{landingUpcomingEventData.data?.ComponentHeaderSubTitle?.data}}</p>
            </div>

        </div>
        <div class="jwc_swiper_normal events_slider" data-interval="8" data-space="0" data-slides="2" data-ipad-slides="1.65" data-ipad-space="20" data-mobile-slides="1.2" data-mobile-space="20" [attr.data-loop]="landingUpcomingEventData?.cards?.length > 1" [attr.data-drag]="landingUpcomingEventData?.cards?.length > 1">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" [attr.data-swiper-autoplay]="getAutoSliderTime(landingUpcomingEventData?.AutomaticSlideshowInterval)" *ngFor="let eventItem of landingUpcomingEventData?.cards">
                        <div class="events_slider_each">
                            <div class="events_slider_each--img">
                                <img src="{{ staticUrlForImgNew + eventItem?.CardImage?.image?.contentUrl}}" class="img-fluid" alt="{{eventItem?.ImageAltTextFirst?.data}}">
                            </div>
                            <div class="events_slider_each_copy border_color border_btm_blue" [attr.style]="('--data-line-bgColor:' + eventItem?.SlideTransitionColor?.data) | safe: 'style'">
                                <div class="events_slider_each_copy--box">
                                    <span class="events_slider_each_copy--category">{{eventItem?.EventType?.data}}</span>
                                    <h4 class="events_slider_each_copy--title" [innerHtml]="eventItem?.EventTitle?.data"></h4>
                                    <p class="events_slider_each_copy--date content3" [innerHtml]="eventItem?.EventDate?.data" *ngIf="eventItem?.EventDate?.data"></p>
                                    <p class="events_slider_each_copy--time content3" [innerHtml]="eventItem?.EventTime?.data" *ngIf="eventItem?.EventTime?.data"></p>
                                    <div class="events_slider_each_copy--btns">
                                        <a *ngIf="eventItem?.HideCTAText?.data =='false'" class="cta-prim color-blue" href="{{eventItem?.CTALink?.data}}"
                                        (click)="_sharedService.checkCTALink($event, eventItem?.CTALink?.data, eventItem?.CTALinkWindowPreference?.data)">
                                            <span class="cta-prim-txt">{{eventItem?.CTAText?.data}}</span>
                                        </a>        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-arrows" *ngIf="landingUpcomingEventData?.cards?.length>1">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </div>
    </section>
    <!-- <section class="events_wrapper plr-0 pattern"
        style="--data-events-bgColor1:#AF8F63;--data-events-bgColor2:#AF8F63;--data-events-lineColor:#FFFFFF;--data-event-arrowColor:#FFFFFF">
        <div class="container">
            <div class="section_heading text-center text-white" style="--data-titleColor:#FFFFFF;--data-lineColor:#FFFFFF">
                <div class="">
                    <h2 class="section_heading--title">What’s On</h2>
                </div>
            </div>
        </div>
    
        <div class="events_slider jwc_swiper_normal" data-interval="5" data-space="120" data-slides="2"
            data-ipad-slides="1.65" data-ipad-space="20" data-mobile-slides="1.2" data-mobile-space="20">
            <div class="events_slider_each events_types">
                <div class="">
                    <select name="" id="">
                        <option value="">EVENT TYPE</option>
                        <option value="">EVENT TYPE</option>
                        <option value="">EVENT TYPE</option>
                        <option value="">EVENT TYPE</option>
                    </select>
                </div>
                <div class="">
                    <a (click)="_sharedService.checkCTALink($event)" class="cta-sec on-bg"><span class="cta-sec-txt">VIEW CALENDAR</span></a>
                </div>
            </div>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="events_slider_each">
                            <div class="events_slider_each--img">
                                <img src="./assets/images/events/1.jpg" class="img-fluid" alt="slider 1">
                            </div>
                            <div class="events_slider_each_copy border_btm_red">
                                <div class="events_slider_each_copy--box">
                                    <span class="events_slider_each_copy--category">Performing Arts</span>
                                    <h4 class="events_slider_each_copy--title">Kalasri - Bharatnatyam Lorem ipsum</h4>
                                    <p class="events_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                    <p class="events_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="events_slider_each_copy--label">The Arts Centre</span>
                                    <div class="events_slider_each_copy--btns">
                                        <a href="" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-gold icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                        <a href="" (click)="_sharedService.checkCTALink($event)" class="cta-sec color-gold"><span class="cta-sec-txt">KNOW MORE
                                            </span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="events_slider_each">
                            <div class="events_slider_each--img">
                                <img src="./assets/images/events/2.jpg" class="img-fluid" alt="slider 1">
                            </div>
                            <div class="events_slider_each_copy border_btm_blue">
                                <div class="events_slider_each_copy--box">
                                    <span class="events_slider_each_copy--category">Performing Arts</span>
                                    <h4 class="events_slider_each_copy--title">TEDx - Amit Karla (How to avoid food waste)
                                    </h4>
                                    <p class="events_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                    <p class="events_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="events_slider_each_copy--label">The Arts Centre</span>
                                    <div class="events_slider_each_copy--btns">
                                        <a href="" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-gold icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                        <a href="" (click)="_sharedService.checkCTALink($event)" class="cta-sec color-gold"><span class="cta-sec-txt">KNOW MORE
                                            </span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="events_slider_each">
                            <div class="events_slider_each--img">
                                <img src="./assets/images/events/3.jpg" class="img-fluid" alt="slider 1">
                            </div>
                            <div class="events_slider_each_copy border_btm_pink">
                                <div class="events_slider_each_copy--box">
                                    <span class="events_slider_each_copy--category">Performing Arts</span>
                                    <h4 class="events_slider_each_copy--title">The Russian Ballet</h4>
                                    <p class="events_slider_each_copy--date content3">24th April - 29th April 2020</p>
                                    <p class="events_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="events_slider_each_copy--label">The Arts Centre</span>
                                    <div class="events_slider_each_copy--btns">
                                        <a href="" (click)="_sharedService.checkCTALink($event)" class="cta-prim color-gold icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                        <a href="" (click)="_sharedService.checkCTALink($event)" class="cta-sec color-gold"><span class="cta-sec-txt">KNOW MORE
                                            </span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
            <div class="swiper-arrows">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </div>
    </section> -->
    <div data-mobile-header="Getting Here" id="getting-here">
        <app-map *ngIf="accessWithEaseData" [accessWithEaseData]="accessWithEaseData" [colorList]="landingColorList"
            [sectionClass]="'pb-0'"></app-map>
    </div>
    <div data-mobile-header="Upcoming" id="upcoming">
        <app-experiences-section *ngIf="experiencesData" [experiencesData]="experiencesData"
            [colorList]="landingColorList" [experiencesSwiperData]='landingExpSwiperData'></app-experiences-section>
    </div>
    <!-- <app-benefits-privileges-section *ngIf="benefitsData" [benefitsData]="benefitsData" [colorList]="landingColorList"></app-benefits-privileges-section> -->
    <!-- <app-news-section *ngIf="newsData" [newsData]="newsData" [colorList]="landingColorList"></app-news-section> -->
    <!-- <app-jwc-moments-section *ngIf="momentsData" [momentsData]="momentsData" [colorList]="landingColorList"></app-jwc-moments-section> -->
    <app-instagram2-section *ngIf="momentsData" [instagramData]="momentsData" [instagramHandleName]="'jioworldcentre'" [followUsLink]="'jioworldcentre'"></app-instagram2-section>
    <app-subscribe-section *ngIf="subscribeData" [subscribeData]="subscribeData" [colorList]="landingColorList"
        [pageName]="'home'" [sectionClass]="'small_space'"></app-subscribe-section>
    <!-- <section class="news_letter_4 small_space exhibitor-login"
        style="--data-newsletter4-bgColor:#F5F2ED; --data-titleColor:#4B4847; --data-lineColor:#A67D54">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-5 col-md-6">
                    <h4 class="section_heading--title ">Plan your event logistics</h4>
                </div>
                <div class="col-lg-7 col-md-6 txt-aln">
                    <a href="{{exhibitorLogin}}" (click)="_sharedService.checkCTALink($event, exhibitorLogin, '_blank', 'External')" class="cta-prim color-gold" style="text-transform: capitalize;"><span class="cta-prim-txt">Open Bay Logistics Management System</span></a>
                </div>
            </div>
        </div>
    </section> -->
</main>
<!-- <app-footer [colorList]="landingColorList"></app-footer> -->
<div class="overlay"></div>
<app-video-popup></app-video-popup>