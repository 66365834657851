import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/services/shared.service';
declare var grecaptcha: any;
@Component({
  selector: 'app-subscribe-section',
  templateUrl: './subscribe-section.component.html',
  styleUrls: ['./subscribe-section.component.css']
})
export class SubscribeSectionComponent implements OnInit {

  @Input() subscribeData;
  @Input() pageName;
  subSuccessMessage: string = "";
  subErrorMessage: string = "";
  subApiErrorMessage: string = "";
  @Input() colorList;
  @Input() isNmacc: boolean;
  @Input() sectionClass?;
  inputColorClass: Map<string, string>;
  buttonColorClass: Map<string, string>;
  showSubMsg: boolean = false;
  showCaptcha: boolean = false;
  showInputBox: boolean = true;
  captchaError = '';
  emailId = "";
  captchaSiteKey: string;
  consentFlag = false;
  constructor(private _sharedService: SharedService) {
    this.inputColorClass = new Map([
      ["home", "grey_color"],
      ["about-us", "grey_color"],
      ["cec", "blue_color"],
      ["tcc", "gold_color"],
      ["nmacc", "color_pink"]
    ]);
    this.buttonColorClass = new Map([
      ["home", "color-gold"],
      ["about-us", "color-gold"],
      ["cec", "color-blue"],
      ["tcc", "color-pink"],
      ["das", "color-gold"],
      ["nmacc", "color-pink"]
    ]);
    this._sharedService.getCommonService().showSubMsg.subscribe(
      (data) => {
        this.showSubMsg = data;
      }
    );
  }

  ngOnInit() {
    this.captchaSiteKey = environment.recaptchaSiteKey;
  }

  callFn(form, $event, flag?) {
    if (!$event) return;
    if (flag) return this.captchaError = $event;
    this.captchaError = '';
    this.showCaptcha = false;
    this.showInputBox = true;
    this._sharedService.getCommonService().getSubscriptionDetails(this.emailId, this.pageName, this.consentFlag).subscribe(
      (data) => {
        this.subErrorMessage = "";
        this.subSuccessMessage = data['message'];
        let activationLink = data['data'];
        this._sharedService.getCommonService().setSubscriptionTimer();
        if (activationLink) {
          let profile = {
            Email: this.emailId,
            "MSG-email": true
          };
          this._sharedService.getCleverTapService().createCTUserLogin(profile);
          // console.log(profile);
          let eventObj = {
            Page_Source: this.pageName,
            Platform: "Web",
            Opt_in: "Y",
            Act: "N",
            Email_address: this.emailId,
            Activation_link: activationLink
          };
          let eventName = (this.pageName == 'tcc') ? 'TCC_Newsletter subscription' : (this.pageName == 'das') ? 'DAS_Newsletter subscription' : 'Newsletter subscription'
          this._sharedService.getCleverTapService().recordSubscriptionEvent(eventName, eventObj);
          // console.log("Clevertap: ", (<any>window).clevertap.event);
          // console.log(eventObj);
        }
        form.resetForm();
        grecaptcha.reset();
      },
      (error) => {
        grecaptcha.reset();
        this.subErrorMessage = "";
        this.subApiErrorMessage = "Try after some time..";
        this._sharedService.getCommonService().setSubscriptionTimer();
      }
    )
  }

  onSubscribeToWorld(form) {
    this.emailId = form.value.email;
    // console.log("emailId", this.emailId);
    if (!this.emailId) {
      this.subSuccessMessage = "";
      this.subErrorMessage = "Email is required";
    } else if (!form.valid) {
      this.subSuccessMessage = "";
      this.subErrorMessage = "Email must be a valid email address";
    } else if (!this.consentFlag) {
    this.subSuccessMessage = "";
    this.subErrorMessage = "Please accept the privacy policy before signing up for the newsletter.";
    } else {
      this.showCaptcha = true;
      this.showInputBox = false;
    }
  }
}
