<!-- <app-header [colorList]="naccColorList"></app-header> -->
<app-sub-header [colorList]="nmaccColorList" [subHeaderList]="nmaccSubHeaderList" [pageName]="'nmacc'"
  *ngIf="nmaccSubHeaderList"></app-sub-header>

<div class="loader">
  <div class="loader_spin"></div>
</div>

<main role="main">
  <div id="backToTop">
    <img src="./assets/images/backToTop.svg" class="img-fluid" alt="" />
    <p>BACK TO TOP</p>
  </div>
  <div id="backToHome">
    <a href="index.html"><img src="./assets/images/backTohome.svg" class="img-fluid" alt="" />
      <p>HOME</p>
    </a>
  </div>
  <!-- <div class="sticky_menu_wrapper" [attr.style]="('--data-icon-color:#C5299B') | safe: 'style'">
    <a href="#" class="sticky_menu_wrapper_each">
      <span class="text">Loyalty sign up</span>
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.88 32.92">
          <path
            d="M19.28,25.08a1.8,1.8,0,0,1-1-.27l-3.47-2.08a.2.2,0,0,0-.09,0l-3.5,2.1a1.9,1.9,0,0,1-2.82-2.06l1-3.93L6.34,16.1A1.9,1.9,0,0,1,5.76,14a1.83,1.83,0,0,1,1.66-1.25l4-.35L13,8.68a1.89,1.89,0,0,1,1.75-1.15h0a1.88,1.88,0,0,1,1.74,1.14l1.54,3.73a.46.46,0,0,0,.14,0l4,.34h0a1.89,1.89,0,0,1,1.59,1.33,1.83,1.83,0,0,1-.49,2L20.2,18.73s0,.06,0,.08l.95,3.93a1.9,1.9,0,0,1-.74,2A2,2,0,0,1,19.28,25.08Zm-4.53-4.17a2.06,2.06,0,0,1,1,.27l3.48,2.09a.1.1,0,0,0,.15-.12l-.94-3.9A1.94,1.94,0,0,1,19,17.41l3.13-2.67s0-.05,0-.1l0-1-.08.89-4-.34a2,2,0,0,1-1.59-1.14L14.86,9.37s-.16,0-.17,0l-1.53,3.7a1.83,1.83,0,0,1-1.68,1.15l-3.93.34c-.11.09-.07.13,0,.16l3.07,2.62a1.82,1.82,0,0,1,.59,1.9l-1,3.91h0s0,0,.05.08.08,0,.1,0l3.47-2.08A1.66,1.66,0,0,1,14.75,20.91Z" />
          <path
            d="M43.27,32.92H5.61A5.62,5.62,0,0,1,0,27.31V5.61A5.62,5.62,0,0,1,5.61,0H43.27a5.64,5.64,0,0,1,5.61,5.66V27.31A5.62,5.62,0,0,1,43.27,32.92ZM5.61,1.8A3.82,3.82,0,0,0,1.8,5.61v21.7a3.82,3.82,0,0,0,3.81,3.81H43.27a3.82,3.82,0,0,0,3.81-3.81V5.66A3.84,3.84,0,0,0,43.27,1.8Z" />
          <path d="M42,10.55H29.05a.9.9,0,0,1,0-1.8H42a.9.9,0,0,1,0,1.8Z" />
          <path d="M42,17.57H29.05a.9.9,0,1,1,0-1.8H42a.9.9,0,1,1,0,1.8Z" />
          <path d="M42,24.17H29.05a.9.9,0,0,1,0-1.8H42a.9.9,0,0,1,0,1.8Z" />
        </svg>
      </span>
    </a>
    <a href="#" class="sticky_menu_wrapper_each">
      <span class="text">Booking</span>
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.02 40.13">
          <path
            d="M37.22,40.13A10.79,10.79,0,0,1,28.75,36H2.55a2.43,2.43,0,0,1-1.8-.74A2.53,2.53,0,0,1,0,33.49V5.25A2.56,2.56,0,0,1,2.55,2.7h9.11V.9a.9.9,0,0,1,1.8,0V2.7H32.29a.72.72,0,0,1,.33.07.86.86,0,0,1,.23-.06V.9a.9.9,0,0,1,1.8,0V2.7h8.54a2.56,2.56,0,0,1,2.55,2.56v7.45h0v10a10.8,10.8,0,0,1-8.52,17.44ZM36.3,20.36l-.47.07-.35.06a8.67,8.67,0,0,0-3,1.19,8.49,8.49,0,0,0-1.3,1A9.56,9.56,0,0,0,30,23.87a9.12,9.12,0,0,0-1.78,4.66c0,.08,0,.14,0,.2s0,.36,0,.58A9,9,0,1,0,39,20.5l-.16,0a8.49,8.49,0,0,0-1.66-.16v0a8.44,8.44,0,0,0-.87,0ZM1.8,13.62V33.49A.73.73,0,0,0,2,34a.74.74,0,0,0,.52.22h25a10.85,10.85,0,0,1-1.18-4.92c0-.27,0-.54,0-.8,0,0,0-.13,0-.14a10.8,10.8,0,0,1,2.12-5.56,11.1,11.1,0,0,1,1.3-1.43,10.41,10.41,0,0,1,1.56-1.19,10.2,10.2,0,0,1,1.76-.91,10.93,10.93,0,0,1,1.93-.56l.33,0c.27-.05.48-.08.69-.1h0c.33,0,.67-.05,1-.06h0a11,11,0,0,1,2,.2h.1a11,11,0,0,1,4.63,2.15V13.62Zm0-1.8H43.94V5.25a.75.75,0,0,0-.75-.75H34.65V6.3a.9.9,0,0,1-1.8,0V4.49l-.23,0a.88.88,0,0,1-.33.06H13.46V6.3a.9.9,0,0,1-1.8,0V4.5H2.55a.75.75,0,0,0-.75.74ZM35.43,33.33a.91.91,0,0,1-.68-.3l-2.38-2.68a.9.9,0,0,1,1.35-1.2l1.84,2.07,5.29-3.88a.9.9,0,0,1,1.07,1.45l-6,4.37A.88.88,0,0,1,35.43,33.33Z" />
        </svg>
      </span>
    </a>
    <a href="#" class="sticky_menu_wrapper_each">
      <span class="text">Dining</span>
      <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.79 48.75">
          <path
            d="M27.8,48.68a3.77,3.77,0,0,1-2.51-.92A3.57,3.57,0,0,1,24.08,45V21.21a7.5,7.5,0,0,1-3.5-1.78A6.15,6.15,0,0,1,18.88,14h.9l-.89-.09,1.5-12.51A1.45,1.45,0,0,1,21.78.12L33.67.05h0a1.47,1.47,0,0,1,1.44,1.26l1.63,12.55s0,.07,0,.11,0,.11,0,.17A6.36,6.36,0,0,1,35,19.25a7.91,7.91,0,0,1-3.47,1.9V45a3.56,3.56,0,0,1-1.19,2.75A3.73,3.73,0,0,1,27.8,48.68ZM20.69,14c0,.06,0,.11,0,.17a4.56,4.56,0,0,0,1.15,4,6.24,6.24,0,0,0,3.32,1.46.9.9,0,0,1,.74.89V45a1.81,1.81,0,0,0,.6,1.4,2.15,2.15,0,0,0,2.65,0,1.83,1.83,0,0,0,.58-1.4V20.44a.9.9,0,0,1,.71-.88A6.56,6.56,0,0,0,33.71,18,4.69,4.69,0,0,0,35,14.2c0-.06,0-.11,0-.15L33.37,1.85h-2.5V12.47a.9.9,0,0,1-1.8,0V1.87l-2.62,0V12.47a.9.9,0,0,1-1.8,0V1.9H22.14Zm13-12.14Zm-.35-.31ZM33.68,1h0Z" />
          <path
            d="M8.09,48.75a3.75,3.75,0,0,1-2.51-.92,3.64,3.64,0,0,1-1.2-2.76V21.39C1.71,19.46,0,15.55,0,11.25A13.48,13.48,0,0,1,2.37,3,7.26,7.26,0,0,1,8.09,0a7.25,7.25,0,0,1,5.72,3,13.48,13.48,0,0,1,2.37,8.23c0,4.38-1.66,8.21-4.38,10.14V45.07a3.64,3.64,0,0,1-1.2,2.76A3.73,3.73,0,0,1,8.09,48.75Zm0-46.95A5.48,5.48,0,0,0,3.8,4.11a11.72,11.72,0,0,0-2,7.14c0,3.92,1.55,7.41,4,8.89a.9.9,0,0,1,.43.77V45.07a1.82,1.82,0,0,0,.59,1.41,2.13,2.13,0,0,0,2.64,0A1.82,1.82,0,0,0,10,45.07V20.91a.89.89,0,0,1,.43-.77c2.4-1.47,4-5,4-8.89a11.72,11.72,0,0,0-2-7.14A5.46,5.46,0,0,0,8.09,1.8Z" />
        </svg>
      </span>
    </a>

  </div> -->

  <div class="kma picture screen_height_inside">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a href='#'>JioWorld Centre</a> / <strong>Nita Mukesh Ambani Cultural Centre</strong>
      </div>
    </div>
    <div class="kma_picture_wrapper black_tint">
      <picture>
        <source media="(min-width: 768px)" srcset="./assets/images/performing-arts/nacc-banner.jpg">
        <source media="(max-width: 767px)" srcset="./assets/images/performing-arts/nacc-banner-mobile.jpg">
        <img src="./assets/images/performing-arts/nacc-banner.jpg" class="img-fluid kma_picture_wrapper--img"
          alt="Banner">
      </picture>
    </div>



    <div class="kma_video_wrapper">
      <video muted loop playsInline class="kma_video_wrapper--video">
        <source src="./assets/videos/" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>


    <div class="kma_text_wrapper ">
      <div data-aos="fade-up">
        <h1>Lorem Ipsum Dolor</h1>
        <p>Lorem ipsum dolor sit amet, consetetur. m ipsum dolor sit amet, consetetur.</p>
      </div>
    </div>
    <div class="kma_arrow">
      <a href="#section_2" class="kma_arrow_link"><span class="icon-rh-arrow"></span></a>
    </div>
  </div>
  <app-covid2-section [page]="'nmacc'"></app-covid2-section>
  <!-- <section class="feature-shows-wrapper plr-0 "
    [attr.style]="('--data-events-bgColor1:#F5F2ED;--data-events-bgColor2:#F5F2ED;--data-events-lineColor:#C92C99;--data-event-arrowColor:#C63493;--data-lineColor:#C92C99') | safe: 'style'">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text text-left text-white   "
        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C92C99') | safe: 'style'">
        <h2>Featured Shows</h2>
      </div>

    </div>

    <div class="feature_shows_slider jwc_swiper_normal" data-interval="100" data-space="150" data-slides="1.85"
      data-ipad-slides="1.4" data-ipad-space="20" data-mobile-slides="1.2" data-mobile-space="20">

      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="feature_shows_slider_each">
              <div class="feature_shows_slider_each--img ">
                <img src="./assets/images/performing-arts/show1.jpg" class="img-fluid thumb_img" alt="slider 1">
                <div class="favt-icon">
                  <a href="javascript:;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36.001" height="31.501" viewBox="0 0 36.001 31.501">
                      <path
                        d="M32.505,4.4a9.615,9.615,0,0,0-13.12.956L18,6.785,16.615,5.358A9.615,9.615,0,0,0,3.495,4.4,10.1,10.1,0,0,0,2.8,19.02L16.4,33.068a2.2,2.2,0,0,0,3.185,0L33.195,19.02A10.09,10.09,0,0,0,32.505,4.4Z"
                        transform="translate(0.001 -2.248)" fill="rgba(252,252,252,0.76)" />
                    </svg>
                  </a>
                </div>
                <div class="status-label">Online</div>
              </div>
              <div class="feature_shows_slider_each_copy">
                <div class="feature_shows_slider_each_copy--box">
                  <h4>The Shape of a Circle in the Mind of a
                    Fish: The Understory of the Understory</h4>

                  <div class="feature_shows_slider_each_copy--content content3">
                    <p>Lorem ipsum dolor sit amet, conset etur adips cing
                      elitr, sed diam nonumy eirmod dolor sit. Lorem </p>

                    <div class="date-time-container">
                      <p class="date-time content3">24th - 29th April 2021 | 6 – 9PM</p>
                      <div class="features-icon content3">
                        <p class="label">Grand Theatre</p>
                        <div class="icon">
                          <span class="icon--each">
                            <img src="./assets/images/performing-arts/family-icon.svg" class="img-fluid" alt="icon1">
                            <div class="tool-tip">Family-friendly</div>
                          </span>
                          <span class="icon--each">
                            <img src="./assets/images/performing-arts/food-icon.svg" class="img-fluid" alt="icon1">
                            <div class="tool-tip">Dining</div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="feature_shows_slider_each_copy--btns">
                    <a href="#" class="cta-prim color-pink icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div class="cta-holder text-right">
        <a href="#" class="cta-sec color-pink"><span class="cta-sec-txt">VIEW CALENDAR</span></a>
      </div>
    </div>


  </section> -->
  <!-- <section class="view-art-wrapper pb-0"
    [attr.style]="('--data-events-bgColor:#FFF;--data-events-lineColor:#FFF;--data-event-arrowColor:#C63493') | safe: 'style'">
    <div class="container">
      <div class="section_heading section_heading--text text-center text_bold"
        [attr.style]="('--data-titleColor:#4B4847;') | safe: 'style'">
        <h2>View By Arts</h2>
      </div>
      <div class="cta-container">
        <ul>
          <li><a href="#">Performing arts</a></li>
          <li><a href="#">Costume arts</a></li>
          <li><a href="#">Visual arts</a></li>
        </ul>
      </div>
    </div>
  </section>


  <section class="whats_on_categories plr-0"
    [attr.style]="('--data-gcategory-bgColor:#FFFFFF;--data-arrow-color:#C92C99;--data-lineColor:#C92C99') | safe: 'style'">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text text-left text_bold   "
        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C92C99') | safe: 'style'">
        <h2>What’s On</h2>
      </div>

    </div>


    <div class="cta-holder">
      <div class="cta-holder--wrapper">
        <a href="#" class="cta-sec icon rh icon-download-icon color-pink"><span class="cta-sec-txt">Download
            calendar</span></a>
      </div>
    </div>

    <div class="whats_on_categories_wrapper filter_view">
      <div class="container-fluid">
        <div class="row filter_view_tab">
          <div class="whats_on_categories_wrapper_list listFilter">

            <div class="col-sm-12 filters">
              <a href="javascript:;" class="filter">Filters</a>
            </div>


            <div class="whats_on_categories_wrapper_list_each">
              <div class="dropdown dropdown-multifilter" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">DATE & TIME</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <div class="multfilter-wrapper">
                    <div class="date_time_filter">
                      <div class="jwc_date_time">
                        <input type="text" name="" value="Date" class="form-control jwc_input_date" id=""
                          data-toggle="datepicker">
                        <input type="text" class="form-control jwc_input_time timepicker" value="2:30 AM">
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="whats_on_categories_wrapper_list_each">
              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">EVENT TYPE</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">all EVENT TYPE</li>
                    <li tabindex="0">Performing Arts</li>
                    <li tabindex="0">Visual Arts</li>
                    <li tabindex="0">Costume Arts</li>
                    <li tabindex="0">Digital Events</li>
                    <li tabindex="0">Workshop</li>
                    <li tabindex="0">Activities</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="whats_on_categories_wrapper_list_each">
              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">Venue</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">all Venue</li>
                    <li tabindex="0">The Grand Theatre</li>
                    <li tabindex="0">The Studio Theatre</li>
                    <li tabindex="0">Art House</li>
                    <li tabindex="0">The Cube</li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="whats_on_categories_wrapper_list_each">
              <div class="dropdown dropdown-multifilter" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">BROWSE BY</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <div class="multfilter-wrapper">
                    <a href="javascript:;" class="tags pink_color">
                      <p>Family-friendly</p>
                    </a>
                    <a href="javascript:;" class="tags pink_color">
                      <p>VIP Experience</p>
                    </a>
                    <a href="javascript:;" class="tags pink_color">
                      <p>Musical</p>
                    </a>
                    <a href="javascript:;" class="tags pink_color">
                      <p>Modern</p>
                    </a>
                    <a href="javascript:;" class="tags pink_color">
                      <p>Opera</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div class="container pl-0 pr-0">
          <div class="row">
            <div class="col-md-6">
              <div class="item_list">
                <div class="whats_on_categories_wrapper_item">
                  <div class="whats_on_categories_wrapper_item--img">
                    <img src="./assets/images/performing-arts/event1.jpg" class="img-fluid" alt="">
                    <div class="favt-icon">
                      <a href="javascript:;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36.001" height="31.501"
                          viewBox="0 0 36.001 31.501">
                          <path
                            d="M32.505,4.4a9.615,9.615,0,0,0-13.12.956L18,6.785,16.615,5.358A9.615,9.615,0,0,0,3.495,4.4,10.1,10.1,0,0,0,2.8,19.02L16.4,33.068a2.2,2.2,0,0,0,3.185,0L33.195,19.02A10.09,10.09,0,0,0,32.505,4.4Z"
                            transform="translate(0.001 -2.248)" fill="rgba(252,252,252,0.76)" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div class="copy-container">
                    <div class="whats_on_categories_wrapper_item_copy content3">
                      <p>Presented by Lorem Ispum</p>
                      <h4>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</h4>
                      <p>Aug 10 - 22 | 12:30–15:00 | 15:30–18:00 | 18:30–21:00</p>
                      <div class="features-icon">
                        <p class="label">Grand Theatre</p>
                        <div class="icon">
                          <span class="icon--each">
                            <img src="./assets/images/performing-arts/family-icon.svg" class="img-fluid" alt="icon1">
                            <div class="tool-tip">Family-friendly</div>
                          </span>
                          <span class="icon--each">
                            <img src="./assets/images/performing-arts/food-icon.svg" class="img-fluid" alt="icon1">
                            <div class="tool-tip">Dining</div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="btn_holder"><a href="#" class="cta-prim color-pink icon icon-ticket emp-btn "><span
                          class="cta-prim-txt">BUY TICKETS</span></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center whats_on_categories_wrapper--btn">
            <a href="#" class="cta-sec color-pink emp-btn"><span class="cta-sec-txt">VIEW MORE</span></a>
          </div>
        </div>


      </div>
    </div>
  </section>
  <app-subscribe-section *ngIf="subscribeData" [subscribeData]="subscribeData.data" [colorList]="nmaccColorList"
    [pageName]="'nmacc'" [sectionClass]="'section_heading--title small_space'"></app-subscribe-section>
  <section class="our_theatre_wrapper plr-0 pb-0"
    [attr.style]="('--data-events-bgColor1:#FFFFFF;--data-events-bgColor2:#FFFFFF;--data-events-lineColor:#C92C99;--data-event-arrowColor:#C63493;--data-event-bulletColor:#FCEDF4;--data-event-bulletActiveColor:#C63493') | safe: 'style'">
    <div class="container pl-0 pr-0">
      <div class="section_heading section_heading--title section_heading--text text-left text-white   "
        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C92C99') | safe: 'style'">
        <h2>Experiences</h2>
      </div>

    </div>

    <div class="our_theatre_slider jwc_swiper_normal" data-interval="20" data-space="200" data-slides="1.5"
      data-ipad-slides="1.5" data-ipad-space="100" data-mobile-slides="1.2" data-mobile-space="20">

      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="our_theatre_slider_each">
              <div class="our_theatre_slider_each--img">
                <img src="./assets/images/performing-arts/benefits-privileges.jpg" class="img-fluid" alt="slider 1">
              </div>
              <div class="our_theatre_slider_each_copy">
                <div class="our_theatre_slider_each_copy--box content3">
                  <h4>Benefits & Privileges</h4>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos eaccusam et justo duo dolores
                    et rebum.</p>
                  <div class="our_theatre_slider_each_copy--btns">
                    <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">Learn More</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>

      <div class="swiper-pagination"></div>
    </div>
  </section> -->

  <section class="art-wrapper pb-0"
    [attr.style]="('--data-events-bgColor:#F4F4F4;--lineColor:#C63493') | safe: 'style'">

    <div class="art-list">
      <div class="container">
        <div class="art-list--each img-left">
          <div class="row mr-0 ml-0">
            <div class="col-lg-6 col-md-6 p-0">
              <div class="img-container">
                <div class="benefits_slider jwc_swiper_benefits" data-interval="4" data-aos="fade-up">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art1.jpg" class="img-fluid" alt="slider 1">
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art2.jpg" class="img-fluid" alt="slider 2">
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art3.jpg" class="img-fluid" alt="slider 2">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Add Pagination -->
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
              <div class="text-container content2">
                <h2>Performing Arts</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore magna aliquyam erat. invidunt ut labore et dolore magna aliquyam erat.</p>
                <!-- <div class="centerbtn">
                  <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">LEARN MORE</span></a>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="art-list--each img-right">
          <div class="row mr-0 ml-0">
            <div class="col-lg-6 col-md-6 p-0">
              <div class="text-container content2">
                <h2>Visual Arts</h2>
                <p>Lorem ipsum dolore magna aliquyam  invidunt ut labore et dolore magna aliquyam erat,  sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore magna dolor sit amet, aliquyam erat.consetetur sadipscing ellitr.</p>
                <!-- <div class="centerbtn">
                  <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">LEARN MORE</span></a>
                </div> -->
              </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
              <div class="img-container">
                <div class="benefits_slider jwc_swiper_benefits" data-interval="4" data-aos="fade-up">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art2.jpg" class="img-fluid" alt="slider 2">
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art1.jpg" class="img-fluid" alt="slider 1">
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art3.jpg" class="img-fluid" alt="slider 2">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Add Pagination -->
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="art-list--each img-left">
          <div class="row mr-0 ml-0">
            <div class="col-lg-6 col-md-6 p-0">
              <div class="img-container">
                <div class="benefits_slider jwc_swiper_benefits" data-interval="4" data-aos="fade-up">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art3.jpg" class="img-fluid" alt="slider 2">
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art1.jpg" class="img-fluid" alt="slider 1">
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art2.jpg" class="img-fluid" alt="slider 2">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Add Pagination -->
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
              <div class="text-container content2">
                <h2>Costume Arts</h2>
                <p>Lorem invidunt ut ipsum dolor sit amet, consetetur  dolore magna aliquyam sadipscing elitr, sed aliquyam erat diam nonumy  invidunt ut
                  labore et erat.  labore et dolore magna eirmod tempor.</p>
                <!-- <div class="centerbtn">
                  <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">LEARN MORE</span></a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="art-list--each img-right">
          <div class="row mr-0 ml-0">
            <div class="col-lg-6 col-md-6 p-0">
              <div class="text-container content2">
                <h2>Public Arts</h2>
                <p>Lorem ipsum dolore magna aliquyam  invidunt ut labore et dolore magna aliquyam erat,  sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore magna dolor sit amet, aliquyam erat.consetetur sadipscing elitr.</p>
                <!-- <div class="centerbtn">
                  <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">LEARN MORE</span></a>
                </div> -->
              </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
              <div class="img-container">
                <div class="benefits_slider jwc_swiper_benefits" data-interval="4" data-aos="fade-up">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art2.jpg" class="img-fluid" alt="slider 2">
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art1.jpg" class="img-fluid" alt="slider 1">
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="benefits_slider_each">
                          <img src="./assets/images/performing-arts/art3.jpg" class="img-fluid" alt="slider 2">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Add Pagination -->
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  

  <section class="our_theatre_wrapper plr-0 pb-0"
  [attr.style]="('--data-events-bgColor1:#FFFFFF;--data-events-bgColor2:#FFFFFF;--data-events-lineColor:#C92C99;--data-event-arrowColor:#C63493;--data-event-bulletColor:#FCEDF4;--data-event-bulletActiveColor:#C63493;') | safe: 'style'" >
    <div class="container pl-0 pr-0">
      <div class="section_heading section_heading--title section_heading--text   content2 "
        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C92C99;') | safe: 'style'">
        <h2>Spaces</h2>
        <div class="our_theatre_slider jwc_swiper_normal" data-interval="20" data-space="200" data-slides="1.5"
                data-ipad-slides="1.5" data-ipad-space="100" data-mobile-slides="1.2" data-mobile-space="20">
        
                <div  class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="our_theatre_slider_each">
                                <div class="our_theatre_slider_each--img">
                                    <img src="./assets/images/performing-arts/theartr.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="our_theatre_slider_each_copy">
                                    <div class="our_theatre_slider_each_copy--box content2">
                                        <h4>The Cube</h4>
                                        <p>Lorem  At vero jussto duo dolorses eos eaccsusam ipsum dsolor sit amet,  elistr, sed diam
                                            nonumy eisrmod temspor invsidunt uts labore et  dolsore magna aliqsuyam erat, sed diam
                                            voluptua. et conssetetur sadipscsing  et rsebum.</p>
                                        <!-- <div class="our_theatre_slider_each_copy--btns">
                                            <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">view
                                                    shows</span></a>
                                            <a href="#" class="cta-sec  color-pink"><span class="cta-sec-txt">HIRE
                                                    VENUE</span></a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="our_theatre_slider_each">
                                <div class="our_theatre_slider_each--img">
                                    <img src="./assets/images/performing-arts/theartr.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="our_theatre_slider_each_copy">
                                    <div class="our_theatre_slider_each_copy--box content2">
                                        <h4>The Cube</h4>
                                        <p>Lorrem ipsrsum dolorr sit armet, conssetetur sadipsscing aelitr, sed diwam
                                            nonumry eirmod temepor invridunt ut lrsabore et dolrre margna aliquyam erat, sedr diam
                                            voluprtua. At verro eos eaccursam et jursto duo dorlores et rrebum.</p>
                                        <!-- <div class="our_theatre_slider_each_copy--btns">
                                            <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">view
                                                    shows</span></a>
                                            <a href="#" class="cta-sec  color-pink"><span class="cta-sec-txt">HIRE
                                                    VENUE</span></a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="our_theatre_slider_each">
                                <div class="our_theatre_slider_each--img">
                                    <img src="./assets/images/performing-arts/theartr.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="our_theatre_slider_each_copy">
                                    <div class="our_theatre_slider_each_copy--box content2">
                                        <h4>The Cube</h4>
                                        <p>Lorehm ipshum dolhor siht amet, consethetur sadipsching elitr, sehd diam
                                            nohnumy ehirmod htempor inviduhnht ut laboreh et dolore hmagna aliquyam ehrat, sed diam
                                            voluhptua. At vehro eos ehaccusam et jhusto duo dolohres et rehbum.</p>
                                        <!-- <div class="our_theatre_slider_each_copy--btns">
                                            <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">view
                                                    shows</span></a>
                                            <a href="#" class="cta-sec  color-pink"><span class="cta-sec-txt">HIRE
                                                    VENUE</span></a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Add Arrows -->
                <div class="swiper-arrows">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
        
                <div class="swiper-pagination"></div>
        
                <!-- <div class="cta-holder text-right">
                    <a href="#" class="cta-sec color-pink"><span class="cta-sec-txt">EXPLORE VENUES</span></a>
                </div> -->
            </div>
      </div>
    </div>
    <!-- <div class="swiper-arrows"></div>==$0
    <div class="swiper-button-next" tabindex="0" role="button" aria-level="Next slide"> flex= $0
      ::after
    </div>
    <div class="swiper-button-prev" tabindex="0" role="button" aria-level="Previous slide"> flex= $0
      ::after
    </div>
      <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
        <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"><i>1</i>
        </span>
        <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"><i>2</i>
        </span>
        <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"><i>3</i>
        </span>
      </div>
      <div class="cta-holder text-right"></div> -->
    
      <!-- <div class="our_theatre_slider jwc_swiper_normal" data-interval="20" data-space="200" data-slides="1.5"
      data-ipad-slides="1.5" data-ipad-space="100" data-mobile-slides="1.2" data-mobile-space="20"
      [attr.data-loop]="false" [attr.data-drag]="false">

      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="our_theatre_slider_each">
              <div class="our_theatre_slider_each--img">
                <img src="./assets/images/performing-arts/theartr.jpg" class="img-fluid" alt="slider 1">
              </div>
              <div class="our_theatre_slider_each_copy">
                <div class="our_theatre_slider_each_copy--box content2">
                  <h4>The Cube</h4>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                    voluptua. At vero eos eaccusam et justo duo dolores et rebum.</p>
                  <div class="our_theatre_slider_each_copy--btns">
                    <a href="#" class="cta-prim color-pink"><span class="cta-prim-txt">view
                        shows</span></a>
                    <a href="#" class="cta-sec  color-pink"><span class="cta-sec-txt">HIRE
                        VENUE</span></a>
                  </div>
                  <div class="swiper-arrows">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      Add Arrows
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>

      <div class="swiper-pagination"></div>

      <div class="cta-holder text-right">
        <a href="#" class="cta-sec color-pink"><span class="cta-sec-txt">EXPLORE VENUES</span></a>
      </div>
    </div> -->
  </section>

  <section class="benefits_wrapper " [attr.style]="('--data-background-color:#F4F4F4') | safe: 'style'">

    <div class="container">
      <div class="row align-items-center ">
        <div class="col-lg-5 col-md-5">
          <div class="section_heading section_heading--title section_heading--text   content2 "
            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C92C99') | safe: 'style'">
            <h2>Dhirubhai Ambani Square</h2>
            <p> Lorem ivpsum dvolor sivt amevt, convsetetur svadipscing elivtr, sevd divam nonumvy eivrmod tevmpor invvidunt ut
              labvore evt dolovre magnav aliquvyam verat, sed diam
            </p>
          </div>

          <div class="benefits_buttons">
            <a href='#' class='cta-prim color-pink emp-btn'><span class='cta-prim-txt'>BOOK TICKETS</span></a>
            <div class="clearfix"></div>
            <a href='#' class='cta-sec color-pink mt-5'><span class='cta-prim-txt'>Know more</span></a>
          </div>
        </div>
        <div class="col-lg-7 col-md-7">
          <div class="bg_before_right" [attr.style]="('--data-bglayer-color:#C92C99') | safe: 'style'">
            <img src="./assets/images/performing-arts/ambani-square.jpg" class="img-fluid" alt="slider 1">
          </div>
        </div>
      </div>



      <div class="show-time mt-5 content2">
        <div class="show-time--each">
          <p><strong>Show Schedule</strong></p>
        </div>
        <div class="show-time--each">
          <p>Saturday to Thursday<br> 1:00 PM and 1:30 PM</p>
        </div>
        <div class="show-time--each">
          <p>Friday Afternoon 13:30 and 14:00 <br>Evenings 18:00–23:00 every 30 minutes</p>
        </div>
      </div>
    </div>



  </section>
  <!-- <section class="community-wrapper pb-0"
    [attr.style]="('--data-events-bgColor1:#FFF;--data-events-bgColor2:#FFF;--data-events-lineColor:#C92C99;--data-event-arrowColor:#C63493') | safe: 'style'">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text text-left text-white   "
        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C92C99') | safe: 'style'">
        <h2>Community</h2>
      </div>

      <div class="community-grid">
        <div class="row">
          <div class="col-lg-7 col-sm-12">
            <div class="community-grid--each">
              <img src="./assets/images/performing-arts/community1.jpg" class="img-fluid" alt="">
              <div class="text content2">
                <h4>Fireside Chats</h4>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-sm-12">
            <div class="community-grid--each">
              <img src="./assets/images/performing-arts/community2.jpg" class="img-fluid" alt="">
              <div class="text content2">
                <h4>Workshops</h4>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin-top">
          <div class="col-lg-5 col-sm-12">
            <div class="community-grid--each">
              <img src="./assets/images/performing-arts/community3.jpg" class="img-fluid" alt="">
              <div class="text content2">
                <h4>Fireside Chats</h4>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-sm-12">
            <div class="community-grid--each">
              <img src="./assets/images/performing-arts/community4.jpg" class="img-fluid" alt="">
              <div class="text content2">
                <h4>Education Programs</h4>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="full_width experiences_wrapper  "
    style="--data-experiences-bgColor1:#FFFFFF;--data-experiences-bgColor2:#FFFFFF;--data-experiences-titleColor:#2C2C2C; --data-mobileLineBg:#1E478E; --data-pagination-current-color:#4B4847"
    data-mobile-header="Discover Mumbai">
    <div class="container"></div>
    <div class="section_heading section_heading--title section_heading--text    "
      [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor:#1E478E') | safe: 'style'">
      <h2>Plan Your Visit</h2>
      <p> The cultural centre is located in the heart of Mumbei - Bandra Kurla Complex (BKC). The area's cosmopolitan
        outlook and strategic connectivity to the rest of the city makes it one of the mose accesible cultural hubs in
        the country.
      </p>
    </div>



    <div class="swiper_fraction cec_fraction_2 experiences_slider jwc_swiper_fraction" data-interval="5" data-space="80"
      data-slides="1.8" data-ipad-slides="1.2">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="experiences_slider_each" style="--data-opacity: 0.5;">
              <div class="experiences_slider_each--img">
                <img src="./assets/images/experiences/8.jpg" class="img-fluid" alt="slider 1">
              </div>
              <div class="experiences_slider_each_copy">
                <div class="experiences_slider_each_copy--center">
                  <h4>Gateway of India</h4>
                  <p class="content3">Apart from being a fantastic spot to imbibe some culture, you can
                    also head out to
                    sea in a chartered yacht, and enjoy an undeniable Mumbai experience at Gateway of
                    India.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="experiences_slider_each" style="--data-opacity: 0.5;">
              <div class="experiences_slider_each--img">
                <img src="./assets/images/experiences/9.jpg" class="img-fluid" alt="slider 2">
              </div>
              <div class="experiences_slider_each_copy">
                <div class="experiences_slider_each_copy--center">
                  <h4>Dr. Bhau Daji Lad Mumbai City Museum</h4>
                  <p class="content3">The Dr. Bhau Daji Lad Mumbai City Museum at Byculla documents
                    the history of the
                    city, and includes in
                    its archives rare manuscripts and maps of Mumbai along with a stunning
                    collection of
                    contemporary art
                    and other artefacts.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
      <!-- Add Arrows -->
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </section>

  <!-- <section class="moments pb-0">

    <div class="container">
      <div class="row">

        <div class="col-lg-12">

          <div class="section_heading text-center"
            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#C92C99') | safe: 'style'">
            <p class="section_heading--text"><span class="icon"
                [attr.style]="('--data-icon-color:#C92C99;') | safe: 'style'">@NMACulturalCentre</span></p>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 px-2 px-md-3">
          <a href="javascript:;">
            <img src="./assets/images/das-insta-1.jpg" class="img-fluid" alt="" />
          </a>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 px-2 px-md-3">
          <a href="javascript:;">
            <img src="./assets/images/das-insta-2.jpg" class="img-fluid" alt="" />
          </a>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 px-2 px-md-3">
          <a href="javascript:;">
            <img src="./assets/images/das-insta-3.jpg" class="img-fluid" alt="" />
          </a>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 px-2 px-md-3">
          <a href="javascript:;">
            <img src="./assets/images/das-insta-1.jpg" class="img-fluid" alt="" />
          </a>
        </div>

        <div class="clearfix"></div>




      </div>
    </div>


  </section> -->

  <section class="faqs_wrapper ">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text text-center text_bold   "
        [attr.style]="('--data-lineColor:#C63493') | safe: 'style'">
        <h2>FAQs</h2>
      </div>


      <div id="faq_accordion" class="cus_accordion">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link content1" data-toggle="collapse" data-target="#faq_collapseOne"
                aria-expanded="true" aria-controls="faq_collapseOne">
                When does JioWorld Convention Centre open?
                <span class="icon-arrow-down"></span>
              </button>
            </h5>
          </div>

          <div id="faq_collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#faq_accordion">
            <div class="card-body">
              <div class="card-body_copy content3">
                <p>Our regular business hours are 8:30 am to 6:00 pm, Monday through Friday. If you require
                  access to the building outside of these hours, please contact Guest Services for
                  assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-link content1 collapsed" data-toggle="collapse" data-target="#faq_collapseTwo"
                aria-expanded="false" aria-controls="faq_collapseTwo">
                What are the transport options to get to the JioWorld Convention Centre?
                <span class="icon-arrow-down"></span>
              </button>
            </h5>
          </div>
          <div id="faq_collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#faq_accordion">
            <div class="card-body">
              <div class="card-body_copy content3">
                <p>Our regular business hours are 8:30 am to 6:00 pm, Monday through Friday. If you require
                  access to the building outside of these hours, please contact Guest Services for
                  assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button class="btn btn-link content1 collapsed" data-toggle="collapse" data-target="#faq_collapseThree"
                aria-expanded="false" aria-controls="faq_collapseThree">
                Is there a dress code to be followed to enter?
                <span class="icon-arrow-down"></span>
              </button>
            </h5>
          </div>
          <div id="faq_collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#faq_accordion">
            <div class="card-body">
              <div class="card-body_copy content3">
                <p>Our regular business hours are 8:30 am to 6:00 pm, Monday through Friday. If you require
                  access to the building outside of these hours, please contact Guest Services for
                  assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0">
              <button class="btn btn-link content1 collapsed" data-toggle="collapse" data-target="#faq_collapseFour"
                aria-expanded="false" aria-controls="faq_collapseFour">
                How can I pre-book a venue?
                <span class="icon-arrow-down"></span>
              </button>
            </h5>
          </div>
          <div id="faq_collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#faq_accordion">
            <div class="card-body content3">
              <p>Our regular business hours are 8:30 am to 6:00 pm, Monday through Friday. If you require
                access to the building outside of these hours, please contact Guest Services for assistance.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center load-more">
        <a href="#" class="cta-sec color-pink"><span class="cta-sec-txt">LOAD MORE</span></a>
      </div>

    </div>
  </section>
  <app-subscribe-section *ngIf="subscribeData" [subscribeData]="subscribeData.data" [colorList]="nmaccColorList"
    [pageName]="'nmacc'" [sectionClass]="'section_heading--title small_space'"></app-subscribe-section>
</main>
<div class="overlay"></div>