import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { constants } from '../../../shared/shared.constant';

@Component({
  selector: 'app-general-garden',
  templateUrl: './general-garden.component.html',
  styleUrls: ['./general-garden.component.css']
})
export class GeneralGardenComponent implements OnInit, OnDestroy {
  generalGardenInquiryForm: FormGroup;
  inquiryType = 'General';
  brand = 'Jio World Garden';
  fromValue = 300;
  toValue = 2500;
  typeOfEvent;
  countryCode;
  errorText = 'Field is required';
  submitted = false;
  disableBtn;
  @Input() set disableSubmitBtn(value) {
    this.disableBtn = value;
  }
  @Output() inquiryTypeValue = new EventEmitter();
  @Output() brandValue = new EventEmitter();
  @Input() inquiryData;
  @Input() captchaSiteKey;
  generalGardenData;
  generalGardenArrData;
  isCaptchaSelected = false;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;  
  apiErrorMsg = "";

  grcToken =  "XXXXXXXXXXX";

  constructor(private formBuilder: FormBuilder, public _sharedService: SharedService, private router: Router) {
    this._sharedService.inquiryApiErrorMsg.subscribe(
      (data) => {
        if (data['error'].message) {
          this.apiErrorMsg = "Server error. Please try after some time...";
          // this.apiErrorMsg = data['error'].message;
        }
      }
    );
   }

  ngOnInit() {
    let prefilledData = this._sharedService.getInquiryFormValues();
    this.generalGardenData = this.inquiryData.cards[0];
    this.generalGardenArrData = this.generalGardenData.garden;
    this._sharedService.callCustomDateTimePicker();
    this._sharedService.callInquiryPeopleRangeSlider();
    this.generalGardenInquiryForm = this.formBuilder.group({
      inquiryType: ['General'],
      brand: ['JWG'],
      nameInput: [(prefilledData.name)?prefilledData.name:'', Validators.required],
      companyInput: [(prefilledData.company)?prefilledData.company:''],
      emailInput: [(prefilledData.email)?prefilledData.email:'', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      messageInput: [(prefilledData.message)?prefilledData.message:'', Validators.required],
      countryCode: ['+91'],
      phoneNoInput: [(prefilledData.phoneNo)?prefilledData.phoneNo:'', Validators.minLength(10)],
      typeOfEvent: [this._sharedService.configData.default_enquiries_dropdown_value || 'Did not say'],
      numberOfPeopleInput: [''],
      firstConsent: [false, Validators.requiredTrue],
      secondConsent: [false]
    });
    this._sharedService.callCustomDropdown()

  }

  get generalGardenFormControls() { return this.generalGardenInquiryForm.controls; }

  setDropdownValue(formControlname, value) {
    this.generalGardenInquiryForm.get(formControlname).setValue(value);
  }

  sendGgRouteObj(name) {
    this.router.navigate(['/legal'], {
      state: { tabName: name }
    });
    return false;
  }

  generalGardenSubmit(form) {
    this.submitted = true;
    // if(!this.isCaptchaSelected) return;
    if (form.invalid || !this.isCaptchaSelected) {
      return;
    }
    this.disableBtn = true;
    let body = form.value;
    body.grcToken = this.grcToken;
    let generalGardenEveObj = {
      Enquiry_Type: body.inquiryType,
      Brand_Name: body.brand,
      Event_Type: body.typeOfEvent,
      Guests_Count: body.numberOfPeopleInput,
      Organiser_Company_Name:body.companyInput,
      Organiser_Phone: body.countryCode + body.phoneNoInput,
      Organiser_Name: body.nameInput,
      Organiser_Email: body.emailInput
      // Event_Name: 'Enquiry_Forms'
    };
    let generalGardenCTProfile = {
      Organiser_Company_Name:body.companyInput,
      Organiser_Phone: body.countryCode + body.phoneNoInput,
      Organiser_Name: body.nameInput,
      Organiser_Email: body.emailInput
    };
    this._sharedService.submitInquiryForm(body, generalGardenEveObj, generalGardenCTProfile);
    // console.log("form values:", form.value);
  }

  
  gardenCaptchaRes($event, flag?) {
    if(!$event) return this.isCaptchaSelected = false;
    if(flag) return console.log('Something went long when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true
    this.grcToken = $event;
  }

  ngOnDestroy() {
    let obj = {
      name: (this.generalGardenFormControls.nameInput.value)?this.generalGardenFormControls.nameInput.value : '',
      email: (this.generalGardenFormControls.emailInput.value)?this.generalGardenFormControls.emailInput.value : '',
      company: (this.generalGardenFormControls.companyInput.value)?this.generalGardenFormControls.companyInput.value : '',
      message: (this.generalGardenFormControls.messageInput.value)?this.generalGardenFormControls.messageInput.value : '',
      phoneNo: (this.generalGardenFormControls.phoneNoInput.value)?this.generalGardenFormControls.phoneNoInput.value : '',
    }
    this._sharedService.setInquiryFormValues(obj.name, obj.email, obj.company, obj.message, obj.phoneNo);
  }

}
