<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>

    <div class="kma_with_thumbnails  ">
        <div class="breadcrumbs mb-0 kma_breadcrumbs">
            <div class="breadcrumbs_container">
                <a href='/'>Jio World Centre </a> / <a href='/jio-world-convention-centre'>Jio World Convention
                    Centre</a> / <a href='/jio-world-convention-centre/weddings'>Weddings</a> /
                <strong>{{currentEvent?.EventTitle?.data}}</strong>
            </div>
        </div> <!-- Swiper -->
        <div class="swiper-container kma_with_dots_big_img">
            <div class="swiper-wrapper">
                <ng-container *ngFor="let eventItem of currentEvent?.banner; let i=index;">
                    <div class="swiper-slide" *ngIf="eventItem?.BackgroundImageVideo?.data=='image'">
                        <img src="{{ staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.UploadMobileKMAImageVideo?.document?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--mobile" />
                    </div>
                    <div class="swiper-slide" *ngIf="eventItem?.BackgroundImageVideo?.data=='video'">
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--mobile" />
                        <a href="javascript:;" class="play_href watchVideoClick blue"
                            [attr.data-url]=" staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl"
                            data-target="#video_box" data-toggle="modal">
                            <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
                        </a>
                    </div>
                    <div class="swiper-slide"
                        *ngIf="eventItem?.BackgroundImageVideo?.data=='360 video' || eventItem?.BackgroundImageVideo?.data=='360 image'">
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--mobile" />
                        <a *ngIf="eventItem?.BackgroundImageVideo?.data=='360 video'" href="javascript:;"
                            class="jwc_360tour_btn play360"
                            [attr.videosrc]="staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl">
                            <div class="jwc_360tour_btn--icon mb-0">
                                <img src="./assets/images/360/360.svg" class="img-fluid" alt="360 icon">
                            </div>
                        </a>
                        <a *ngIf="eventItem?.BackgroundImageVideo?.data=='360 image'" href="javascript:;"
                            class="jwc_360tour_btn play360"
                            [attr.imgsrc]="staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl">
                            <div class="jwc_360tour_btn--icon mb-0">
                                <img src="./assets/images/360/360.svg" class="img-fluid" alt="360 icon">
                            </div>
                        </a>
                    </div>
                </ng-container>
            </div>
            <div class="swiper-pagination d-block" *ngIf="currentEvent?.banner?.length > 1"></div>
        </div>

    </div>

    <app-video-popup></app-video-popup>

    <section class="event_short_details" [attr.style]="('--data-details-bgColor:#004EA8') | safe: 'style'">
        <div class="container">
            <div class="event_short_details_content">
                <div class="event_short_details_title">
                    <h4>{{currentEvent?.EventTitle?.data}}</h4>
                </div>
                <div class="event_short_details_link" *ngIf="currentEvent?.HideCTAText?.data !== 'true'">
                    <a href="{{currentEvent?.CTALink?.data}}" class="cta-prim color-white emp-btn mb-0"
                        (click)="_sharedService.checkCTALink($event, currentEvent?.CTALink?.data, currentEvent?.CTALinkWindowPreference?.data)">
                        <span class="cta-prim-txt">{{currentEvent?.CTAText?.data}}</span>
                    </a>
                </div>
            </div>
        </div>
    </section>

    <section class="cec_event_details">
        <div class="container">
            <div class="row justify-content-between ">
                <div class="cec_event_details_title col-lg-7 col-md-7"
                    [ngClass]="{'col-lg-12 col-md-12':currentEvent?.HideKeyOfferingSection?.data === 'tue'}">

                    <div class="section_heading section_heading--title section_heading--text    "
                        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
                        <h2>{{currentEvent?.EventDetailsHeader?.data}}</h2>
                    </div>

                </div>
                <div class="clear"></div>
                <div class="col-lg-7 col-md-7 cec_event_details_text"
                    [ngClass]="{'col-lg-12 col-md-12':currentEvent?.HideKeyOfferingSection?.data === 'tue'}">
                    <p [innerHtml]="currentEvent?.EventDetailsContent?.data"></p>
                </div>
                <div class="col-lg-4 col-md-4 cec_event_details_data"
                    *ngIf="currentEvent?.HideKeyOfferingSection?.data === 'false'">
                    <div class="cec_event_details_data_each">
                        <h4>{{currentEvent?.KeyOfferingsLabel?.data}}</h4>
                        <ng-container *ngFor="let item of currentEvent?.keyOfferings">
                            <div class="d-flex mb-3" *ngIf="item?.HideKeyOffering?.data === 'false'">
                                <img class="img-fluid wedding-icon"
                                    [src]="staticUrlForImgNew + item?.KeyOfferingImage?.document?.contentUrl"
                                    alt="WEDDING-ICONS-02.svg">
                                <p class="content3 m-0">{{item?.KeyOfferingText?.data}}</p>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="life_jwc_slider pt-0" *ngIf="currentEvent?.HideGallerySection?.data === 'false'">
        <div class="jwc_image_slider jwc_swiper_normal" data-aos="fade-up" data-interval="0" data-space="0"
            data-slides="1" data-ipad-slides="1" data-ipad-space="0" data-mobile-slides="1" data-mobile-space="0"
            [attr.data-loop]="currentEvent?.gallery?.length > 1" [attr.data-drag]="currentEvent?.gallery?.length > 1">
            <div class="container">
                <div class="section_heading section_heading--title section_heading--text text-center "
                    [attr.style]="('--data-lineColor:#004EA8') | safe: 'style'">
                    <h2>{{currentEvent?.GalleryLabel?.data}}</h2>
                </div>

            </div>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let item of currentEvent?.gallery">

                        <div class="jwc_image_slider_each" *ngIf="item?.BackgroundImageVideo?.data=='image'">
                            <img src="{{staticUrlForImgNew + (item?.UploadImageVideo?.document?.contentUrl)}}"
                                class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                        </div>
                        <div class="jwc_image_slider_each" *ngIf="item?.BackgroundImageVideo?.data=='video'">
                            <a href="javascript:;" class="watchVideoClick" data-target="#video_box"
                                attr.data-url="{{staticUrlForImgNew + (item?.UploadImageVideo?.document?.contentUrl)}}"
                                data-toggle="modal">

                                <img src="{{staticUrlForImgNew + (item?.PlaceholderImageForVideoUploaded?.image?.contentUrl)}}"
                                    class="img-fluid" alt="{{item?.ImageAltText?.data}}">

                                <div class="play_href default"><img src="./assets/images/playbutton.svg"
                                        class="img-fluid play_btn" alt="play button"></div>
                            </a>
                        </div>
                        <div class="jwc_image_slider_each" *ngIf="item?.BackgroundImageVideo?.data=='360 image'">
                            <a href="javascript:;" class="play360"
                                attr.imgsrc="{{staticUrlForImgNew + (item?.UploadImageVideo?.document?.contentUrl)}}">
                                <img src="{{staticUrlForImgNew + (item?.UploadImageVideo?.document?.contentUrl)}}"
                                    class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                                <div class="icon360"></div>
                            </a>
                        </div>
                        <div class="jwc_image_slider_each" *ngIf="item?.BackgroundImageVideo?.data=='360 video'">
                            <a href="javascript:;" class="play360"
                                attr.videosrc="{{staticUrlForImgNew + (item?.UploadImageVideo?.document?.contentUrl)}}">
                                <img src="{{staticUrlForImgNew + (item?.PlaceholderImageForVideoUploaded?.image?.contentUrl)}}"
                                    class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                                <div class="icon360"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <!-- Add pagination -->

                <div class="swiper-arrows" *ngIf="currentEvent?.gallery?.length > 1">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>

                <!-- <div class="swiper-pagination" *ngIf="currentEvent?.gallery?.length > 1"></div> -->
            </div>
        </div>
    </section>
</main>