<div class="jwc_signin_wrap_reg" *ngIf="isMainRegDiv">
    <h4>Register Now</h4>
    <div class="jwc_gap_10"></div>
    <div class="jwc_signin_wrap_reg_main" *ngIf="isFirstRegScreen">
        <form [formGroup]="registrationForm">
            <ng-container *ngIf="isRegEmail">
                <ng-container [ngTemplateOutlet]="titleFirstLastNameTemplate"></ng-container>
                <div class="form-group" id="register--email">
                    <!-- [ngStyle]="!isRegEmail ? {'visibility': 'hidden', 'max-height':'0', 'margin-bottom': '0'} : {'visibility': 'visible'}" -->
                    <input formControlName="emailInput" type="text" class="input-field" placeholder="Email">
                    <div *ngIf="(registrationFormControls.emailInput.touched || submitted)">
                        <div *ngIf="registrationFormControls.emailInput.errors?.required" class="error-txt">Field is
                            required </div>
                        <div *ngIf="registrationFormControls.emailInput.errors?.pattern" class="error-txt">Email must be
                            a
                            valid email address
                        </div>
                    </div>
                    <div class="error-txt" *ngIf="showRegErr">{{showRegErrMessage}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="isRegMobile">
                <ng-container [ngTemplateOutlet]="titleFirstLastNameTemplate"></ng-container>
                <div class="form-group" id="register--number">
                    <!-- [ngStyle]="!isRegMobile ? {'visibility': 'hidden', 'max-height':'0', 'margin-bottom': '0'} : {'visibility': 'visible'}" -->
                    <div class="form-row">
                        <div class="col-3 pr-0">
                            <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                                <div class="dropdown--select">+91</div>
                                <div class="dropdown--list" aria-expanded="false" role="list">
                                    <ul>
                                        <li tabindex="0" (click)="setDropdownValue('countryCode', item)"
                                            *ngFor="let item of countryCode">
                                            {{item}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-9 pl-0 input-box">
                            <div class="form-group mb-0">
                                <input formControlName="mobileInput" type="tel" class="input-field" placeholder="Mobile"
                                    maxlength="10" appAllowedInput [numbersOnly]="true">
                                <div *ngIf="(registrationFormControls.mobileInput.touched || submitted)">
                                    <div *ngIf="registrationFormControls.mobileInput.errors?.minlength"
                                        class="error-txt">
                                        Enter 10 digit
                                        Mobile Number</div>
                                    <div *ngIf="registrationFormControls.mobileInput.errors?.required"
                                        class="error-txt">
                                        Field is
                                        required</div>
                                </div>
                                <div class="error-txt" *ngIf="showRegErr">{{showRegErrMessage}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="jwc_signin_btns jwc_signin_btns_btwn">
                <div class="custom-control custom-checkbox checkbox">
                    <!--  <input formControlName="loyaltyProgram" type="checkbox" class="custom-control-input" id="dontmiss"
                        name="dontmiss" (click)="onLoyaltyChecked($event)">
                    <label class="custom-control-label" for="dontmiss">Join Loyalty
                        Program</label> -->
                </div>
                <a *ngIf="isRegMobile; else useRegMobTemp" href="javascript:;" (click)="showRegEmailInput()"
                    class="cta-sec color-gold" id="register_email_phone"><span class="cta-sec-txt">
                        use email</span></a>

                <ng-template #useRegMobTemp>
                    <a href="javascript:;" (click)="showRegMobileInput()" class="cta-sec color-gold"
                        id="register_email_phone"><span class="cta-sec-txt">
                            use mobile</span></a>
                </ng-template>
            </div>
            <div class="jwc_gap_20"></div>
            <div class="jwc_signin_btns text-center">
                <a href="javascritp:;" (click)="requestRegOtp(registrationForm, 'regForm')"
                    class="cta-prim color-gold use_phone mr-0" id="register_otp"><span class="cta-prim-txt">REQUEST
                        OTP</span></a>
                <div class="jwc_gap_20"></div>
                <a href="javascritp:;" class="cta-sec color-gold" id="already_member" (click)="onSignInClick()"><span
                        class="cta-sec-txt">ALREADY A
                        MEMBER?
                        SIGN IN</span></a>
            </div>
        </form>
    </div>
    <form [formGroup]="confirmRegistrationForm" *ngIf="isRegMobilePwd || isRegEmailPwd">
        <div class="jwc_reg_mobile_pwd_wrap" *ngIf="isRegMobilePwd && isRegMobile">
            <div class="form-group">
                <div class="form-row">
                    <div class="col-3 pr-0">
                        <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                            <div class="dropdown--select">+91</div>
                            <div class="dropdown--list" aria-expanded="false" role="list">
                                <ul>
                                    <li tabindex="0" (click)="setDropdownValue('countryCode', item)"
                                        *ngFor="let item of countryCode">
                                        {{item}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-9 pl-0 input-box">
                        <div class="form-group mb-0">
                            <input formControlName="mobileInput" type="text" class="input-field" placeholder="Mobile"
                                maxlength="10" appAllowedInput [numbersOnly]="true">
                            <div class="error-txt"
                                *ngIf="(confirmRegistrationFormControls.mobileInput.touched || submitted) && confirmRegistrationFormControls.mobileInput.errors">
                                Enter 10 digit Mobile Number</div>
                            <!-- <div class="error-txt" *ngIf="showRegErr">{{showRegErrMessage}}</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="otpTemplate"></ng-container>
            <div class="jwc_signin_agree">
                By signing in you agree to our <a href="{{'/legal?tab=terms'}}"
                    (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=terms', '_blank', 'External')">terms and conditions</a>
            </div>
            <div class="custom-control custom-checkbox checkbox">
                <input type="checkbox" class="custom-control-input" id="dontmiss2" name="dontmiss2"
                    formControlName="optMarketingComm">
                <label class="custom-control-label" for="dontmiss2">I would like to opt in for
                    marketing communication from
                    JioWorld Centre</label>
            </div>
            <div class="jwc_gap_10"></div>
            <div class="jwc_signin_btns">
                <a class="cta-prim color-gold emp-btn" id="go_to_loyalty"><span class="cta-prim-txt"
                        (click)="onRegisterClick(confirmRegistrationForm)">Register</span></a>
                <a href="javascript:;" class="cta-sec color-gold" id="already_member" (click)="onSignInClick()"><span
                        class="cta-sec-txt">
                        ALREADY A MEMBER? SIGN IN</span></a>
                <div class="error-txt" *ngIf="showRegErr">{{showRegErrMessage}}</div>
            </div>
        </div>

        <div class="jwc_reg_email_pwd_wrap" *ngIf="isRegEmailPwd && isRegEmail">
            <div class="form-group">
                <input formControlName="emailInput" type="email" class="input-field" placeholder="Email">
                <div
                    *ngIf="(confirmRegistrationFormControls.emailInput.touched || submitted) && confirmRegistrationFormControls.emailInput.errors">
                    <div *ngIf="confirmRegistrationFormControls.emailInput.errors.pattern" class="error-txt">Email must
                        be a
                        valid email address
                    </div>
                </div>
                <!-- <div class="error-txt" *ngIf="showRegErr">{{showRegErrMessage}}</div> -->
            </div>
            <ng-container [ngTemplateOutlet]="otpTemplate"></ng-container>
            <div class="jwc_signin_agree">
                By signing in you agree to our <a href="{{'/legal?tab=terms'}}"
                    (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=terms', '_blank', 'External')">terms and conditions</a>
            </div>
            <div class="custom-control custom-checkbox checkbox">
                <input type="checkbox" class="custom-control-input" id="dontmiss3" name="dontmiss3"
                    formControlName="optMarketingComm">
                <label class="custom-control-label" for="dontmiss3">I would like to opt in for
                    marketing communication from
                    JioWorld Centre</label>
            </div>
            <div class="jwc_gap_10"></div>
            <div class="jwc_signin_btns">
                <a class="cta-prim color-gold emp-btn" id="go_to_loyalty"><span class="cta-prim-txt"
                        (click)="onRegisterClick(confirmRegistrationForm)">Register</span></a>
                <a href="javascript:;" class="cta-sec color-gold" id="already_member" (click)="onSignInClick()"><span
                        class="cta-sec-txt">
                        ALREADY A MEMBER? SIGN IN</span></a>
                <div class="error-txt" *ngIf="showRegErr">{{showRegErrMessage}}</div>
            </div>
        </div>
    </form>
</div>

<app-loyalty-program *ngIf="showLoyaltyComp" (loyaltyEnrolCompleted)="loyaltyEnrolCompleted($event)">
</app-loyalty-program>

<div class="jwc_signin_wrap_thankyou_enroll" *ngIf="showThankYouComp">
    <h4>Thank You for Registering</h4>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.</p>
    <div class="jwc_gap_10"></div>
    <div class="fluid" id="">
        <a href="javascript:;" class="cta-prim color-gold emp-btn" id="continue_sign_2" (click)="onSussessfulRegister()"><span
                class="cta-prim-txt">Continue</span></a>
    </div>
</div>

<ng-template #otpTemplate>
    <div [formGroup]="confirmRegistrationForm">
        <div class="form-group">
            <input formControlName="otpInput" type="text" class="input-field" placeholder="OTP" maxlength="6"
                appAllowedInput [numbersOnly]="true">
            <div
                *ngIf="(confirmRegistrationFormControls.otpInput.touched || submitted) && confirmRegistrationFormControls.otpInput.errors">
                <div *ngIf="confirmRegistrationFormControls.otpInput.errors?.minlength" class="error-txt">OTP must
                    be a 6 digit number
                </div>
                <div *ngIf="confirmRegistrationFormControls.otpInput.errors?.required" class="error-txt">
                    Field is required
                </div>
            </div>
            <div class="jwc_resend_otp clickable_otp" (click)="resendOtp()" *ngIf="!otpResent">RESEND OTP</div>
            <div *ngIf="otpResent" class="jwc_resend_otp">RESEND OTP <span>in
                    <span>{{this.loginTimerData?.min ? this.loginTimerData?.min : '00'}}:{{this.loginTimerData?.sec
                        ? this.loginTimerData?.sec : '00'}}</span>s</span></div>
        </div>
    </div>
</ng-template>

<ng-template #titleFirstLastNameTemplate>
    <div [formGroup]="registrationForm">
        <div class="form-group">
            <div class="row">
                <div class="col-4 pr-0">
                    <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                        <div class="dropdown--select" id="title-dropdown">{{this.registrationForm.get('title').value ? this.registrationForm.get('title').value : 'Title'}}</div>
                        <div class="dropdown--list" aria-expanded="false" role="list">
                            <ul>
                                <li tabindex="0" (click)="setDropdownValue('title', 'Mr.')">Mr.</li>
                                <li tabindex="0" (click)="setDropdownValue('title', 'Mrs.')">Mrs.</li>
                            </ul>
                        </div>
                    </div>
                    <div
                        *ngIf="(registrationFormControls.title.touched || submitted) && registrationFormControls.title.errors">
                        <div *ngIf="registrationFormControls.title.errors?.required" class="error-txt">Field
                            is required </div>
                    </div>
                </div>
                <div class="col-8">
                    <input formControlName="firstName" type="tel" class="input-field" placeholder="First Name"
                        appAllowedInput [alphabetsOnly]="true">
                    <div
                        *ngIf="(registrationFormControls.firstName.touched || submitted) && registrationFormControls.firstName.errors">
                        <div *ngIf="registrationFormControls.firstName.errors?.required" class="error-txt">Field
                            is required </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <input formControlName="lastName" type="text" class="input-field" placeholder="Last Name"
                appAllowedInput [alphabetsOnly]="true">
            <div
                *ngIf="(registrationFormControls.lastName.touched || submitted) && registrationFormControls.lastName.errors">
                <div *ngIf="registrationFormControls.lastName.errors?.required" class="error-txt">Field is
                    required </div>
            </div>
        </div>
    </div>
</ng-template>