import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { schemaJson } from '../../shared/schema.constant';
import { SharedService } from '../../shared/services/shared.service';
@Component({
  selector: 'app-footer-faqs',
  templateUrl: './footer-faqs.component.html',
  styleUrls: ['./footer-faqs.component.css']
})
export class FooterFaqsComponent implements OnInit {

  footerFaqsColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF',  headerDataHoverColor:"#976841"
  }
  previousRouteData;
  faqKeyArray = [
    67080, // jwc general category
    67190, // Booking Category 
    67319, // Amenities & Services Category
    67292, // Plan a Visit Category
    //'JWG-FAQS-CONTACT-US'
  ];
  subscription;
  generalCategoryData;
  bookingCategoryData;
  aasCategoryData;
  planAVisitData;
  generalFaqData;
  contactFaqData;
  activeIndex;
  mobBrandSelected;
  mobIndex;
  menuBgcolor;
  dropdownFlag: boolean = true;
  selectedMobileTitle: any;
  page: any;
  queryParam;
  jsonLDforGeneral: SafeHtml;
  jsonLDforBooking: SafeHtml;
  jsonLDforAas: SafeHtml;
  jsonLDforPlanAVisit: SafeHtml;
  constructor(private activatedRoute: ActivatedRoute, public router: Router, public _sharedService: SharedService) {       
      this.page = this.activatedRoute.snapshot.data['page']
      this.queryParam = this.activatedRoute.snapshot.queryParams['tab']    
      }

  ngOnInit() {
    this.previousRouteData = this._sharedService.getPreviousRouteData();
    this.defaultBrandData();
  }

  getFAQPageContent(keyArray, flag?, index?) {
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyArray).subscribe(
      (data: any) => {
        this.generalCategoryData = data[0]? data[0].data : data[0];
        this.bookingCategoryData = data[1]? data[1].data : data[1];
        this.aasCategoryData = data[2]? data[2].data : data[2];
        this.planAVisitData = data[3]? data[3].data : data[3];
        // this.contactFaqData = data[4]? data[4].data : data[4]; //Contact us is hidden
        // console.log(this.generalCategoryData,  'general category data');
        // , {fragment: this.getFragmentId(this.generalCategoryData.ComponentHeaderTitle.data)}
        // change navigate url to /frequently-asked-questions after jwc is live
        if(flag){
          if(this.page==='new-das')this.router.navigate(['das/frequently-asked-questions']);
          else {this.router.navigate(['/frequently-asked-questions'],{
            relativeTo: this.activatedRoute, queryParams: { tab: this.getParam(index) }, 
          })};
        };
        
        this._sharedService.initializeCustomJs();
        if(this.dropdownFlag){this._sharedService.callsidebarDrop();   
          this._sharedService.calldropdownActiveClass();   }
        this.dropdownFlag = false;
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  callFaqCTEvent(type, clickedLink, sectionName?) {
    switch (type) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', 'FAQs Side Navbar','', clickedLink);
        break;
      case 'sec':
        this._sharedService.callCTEvent('Header_Secondary_Links', this.mobBrandSelected, '', clickedLink);
        break;
      case 'cont':
        this._sharedService.callCTEvent('Section_Content_Links', this.mobBrandSelected, sectionName, clickedLink);
        break;
    }
  }

  defaultBrandData() {
    // if(this.previousRouteData == 'cec') {
      if(this.queryParam == 'jwcc' && this.page=='faq') {
      this.getBrandData(1);
      this.mobIndex = this.activeIndex = 1;
    // }else if(this.previousRouteData == 'garden') {
    }else if(this.queryParam == 'jwg' && this.page=='faq') {
      this.getBrandData(2);
      this.mobIndex = this.activeIndex = 2;
    // }else if(this.previousRouteData == 'new-das') {
    }else if(this.queryParam == 'das' && this.page=='faq') {
      this.getBrandData(3);
      this.mobIndex = this.activeIndex = 3;
    }else {
      // this.getBrandData(1);
      // this.mobBrandSelected = "Jio World Convention Centre";
      // this.mobIndex = this.activeIndex = 1;
      // this.getBrandData(0);
      // this.mobBrandSelected = "Jio World Centre​";
      // this.mobIndex = this.activeIndex = 0;
      this.getBrandData(3,true); // make das default tab
      this.mobIndex = this.activeIndex = 3;
    }
  }

  getBrandData(index, flag=false) {    
    this.mobBrandSelected = (index == 3) ? 'Dhirubhai Ambani Square​​':(index == 2)?'Jio World Garden​​' : (index == 1)? 'Jio World Convention Centre​' : 'Dhirubhai Ambani Square';
    this.menuBgcolor = (index == 3 || index == 0)? '#A67D54': (index == 2)? '#6CC24A': '#1E478E'; // #A67D54
    this.faqKeyArray = (index == 0)? [67080, 67190, 67319, 67292]: (index==1)?
    [67116, 67172, 67328, 67301] : (index==2)? [67144, 67181, 67337, 67310] : [225613, 225655, 225634, 225676];
    this.activeIndex = index;
    this.getFAQPageContent(this.faqKeyArray, flag , index);
    if(this.generalCategoryData) this.selectedMobileTitle = this.generalCategoryData.ComponentHeaderTitle.data;
    
    let generalSchema;
    let bookingSchema;
    let aasSchema;
    let planAVisitSchema;
    generalSchema = (index===3) ? schemaJson.faqDasGeneralSchema : (index===1) ? schemaJson.faqCecGeneralSchema : '';
    bookingSchema = (index===3) ? schemaJson.faqDasScheduleSchema : (index===1) ? schemaJson.faqCecServicesSchema : '';
    aasSchema = (index===3) ? schemaJson.faqDasBookingSchema : (index===1) ? schemaJson.faqCecAroundSchema : '';
    planAVisitSchema = (index===3) ? schemaJson.faqDasBookingSchema : (index===1) ? schemaJson.faqCecAroundSchema : '';
    this.jsonLDforGeneral = this._sharedService.getSafeHTML(generalSchema);
    this.jsonLDforBooking = this._sharedService.getSafeHTML(bookingSchema);
    this.jsonLDforAas = this._sharedService.getSafeHTML(aasSchema);
    this.jsonLDforPlanAVisit = this._sharedService.getSafeHTML(planAVisitSchema);
  }

  getParam(index){
    let obj={
      1:'jwcc',
      2:'jwg',
      3:'das'
    }
    return obj[index]
  }

  checkExpandFlag(index) {
    return (index==0)?true: false;
  }
  getFragmentId(val){
    return val.replace(/\s/g, '-').toLowerCase();
  }
  setMobHeaderTitle(title){
    this.selectedMobileTitle = title;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
