<section class="benefits_wrapper multilineText content2" [attr.style]="('--data-background-color:#FFF') | safe: 'style'">
  <div class="container" data-aos="fade-up">
    <div class="row justify-content-between">
      <div class="col-lg-7 col-md-7">
        <div class="section_heading section_heading--title section_heading--text pb-0" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
            <h1 class="custom-seo-heading" [innerHTML]="cecVenueDetailsHallsData?.ComponentHeaderTitle?.data"></h1>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-lg-7 col-md-7">
        <p [innerHTML]="cecVenueDetailsHallsData?.ComponentHeaderSubTitle?.data"></p>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="venue_details" *ngFor="let item of cecVenueDetailsHallsData.cards">
          <div class="venue_details--icon">
            <img src="{{staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl}}" alt="{{item?.ImageAltText?.data}}">
          </div>
          <div class="venue_details--txt">
            <h4>{{item?.InfographicTitle?.data}}</h4>
            <p class="content2" [innerHTML]="item?.InfographicSubTitle?.data"></p>
          </div>
        </div>
        <div class="cta-holder btn-cta-holder">
          <!-- cecVenueDetailsHallsData?.CTA2Link?.data
            cecVenueDetailsHallsData?.TypeOfCTA2Link?.data
            cecVenueDetailsHallsData?.CTA2LinkWindowPreference?.data -->
          <a href="{{cecVenueDetailsHallsData?.CTA2Link?.data}}" *ngIf="cecVenueDetailsHallsData?.HideCTA2Text?.data =='false'"
            class="cta-sec icon rh icon-download-icon color-blue"
            (click)="callPackageCTEventForCTA(cecVenueDetailsHallsData, 'cta2'); callCecVenueDetailsCTEvent('download', cecVenueDetailsHallsData?.CTA2Link?.data); 
            _sharedService.checkCTALink($event, cecVenueDetailsHallsData?.CTA2Link?.data, '_self', 'PDF', this.downloadedFileName)">
            <span class="cta-sec-txt">{{cecVenueDetailsHallsData?.ComponentCTA2Text?.data}}</span>
          </a>
          <a href="{{cecVenueDetailsHallsData?.CTA4Link?.data}}" *ngIf="cecVenueDetailsHallsData?.HideCTA4Text?.data =='false'"
            class="cta-sec icon rh icon-download-icon color-blue"
            (click)="callPackageCTEventForCTA(cecVenueDetailsHallsData, 'cta4'); callCecVenueDetailsCTEvent('download', cecVenueDetailsHallsData?.CTA4Link?.data); 
            _sharedService.checkCTALink($event, cecVenueDetailsHallsData?.CTA4Link?.data, '_self', 'PDF', this.downloadedFileName)">
            <span class="cta-sec-txt">{{cecVenueDetailsHallsData?.ComponentCTA4Text?.data}}</span>
          </a>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-lg-12">
        <div class="buttons mt-sm-1 mt-5">
          <a href="{{cecVenueDetailsHallsData?.CTA1Link?.data}}" *ngIf="cecVenueDetailsHallsData?.HideCTA1Text?.data =='false'"
              class="cta-prim color-blue"
              (click)="callPackageCTEventForCTA(cecVenueDetailsHallsData, 'cta1'); _sharedService.checkCTALink($event, cecVenueDetailsHallsData?.CTA1Link?.data, cecVenueDetailsHallsData?.CTA1LinkWindowPreference?.data, cecVenueDetailsHallsData?.TypeOfCTA1Link?.data)">
              <span class="cta-prim-txt">{{cecVenueDetailsHallsData?.CTAText?.data}}</span></a>

              <a href="{{cecVenueDetailsHallsData?.CTA3Link?.data}}" *ngIf="cecVenueDetailsHallsData?.HideCTA3Text?.data =='false'"
              class="cta-prim color-blue"
              (click)="callPackageCTEventForCTA(cecVenueDetailsHallsData, 'cta3'); _sharedService.checkCTALink($event, cecVenueDetailsHallsData?.CTA3Link?.data, '_self', 'PDF', this.downloadedFileName)" style="margin-left: 20px;">
              <span class="cta-prim-txt">{{cecVenueDetailsHallsData?.ComponentCTA3Text?.data}}</span></a>
            <!-- <a href='#' class='cta-prim color-pink mr-sm-5 mb-4 emp-btn'><span class='cta-prim-txt'>VIEW EVENTS AT GRAND THEATRE</span></a>
            <a href='#' class='cta-sec color-pink'><span class='cta-sec-txt'>HIRE VENUE</span></a> -->
        </div>
    </div>
    </div>
  </div>
</section>