<div class="loader">
    <div class="loader_spin"></div>
</div>


<app-sub-header [colorList]="cecColorList" [subHeaderList]="cecSubHeaderList" [pageName]="'cec'"
    *ngIf="cecSubHeaderList"></app-sub-header>


<main role="main">

    <div class="kma_with_thumbnails  ">
        <div class="breadcrumbs mb-0 kma_breadcrumbs">
            <div class="breadcrumbs_container">
                <a href='/'>Jio World Centre</a> / <a href='/jio-world-convention-centre'>Convention
                    Centre</a> / <a href='/jio-world-convention-centre/venues'>Venues</a> / <strong>Organise
                    Exhibitions</strong>
            </div>
        </div> <!-- Swiper -->
        <app-banner-new *ngIf="bannerData" [newBannerData]="bannerData"></app-banner-new>

        <!-- <div class="swiper-container kma_with_thumbnails_big_img">


            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img src="./assets/images/events/organise-exhibitions-banner.jpg" alt="" class="big_img--deskop" />
                    <img src="./assets/images/events/organise-exhibitions-banner.jpg" alt=""
                        class="big_img--mobile" />

                    <div class="section_heading section_heading--title section_heading--text text-center kma_text_wrapper "
                        [attr.style]="('--data-titleColor:;--data-lineColor:') | safe: 'style'">
                        <h2>Organise Events at <br>
                            Jio World Convention Centre</h2>
                        <p> Lorem ipsum dolor sit amet, consetetur.
                        </p>
                    </div>

                </div>
                <div class="swiper-slide">
                    <img src="./assets/images/events/organise-exhibitions-banner.jpg" alt="" class="big_img--deskop" />
                    <img src="./assets/images/events/organise-exhibitions-banner-mobile.jpg" alt=""
                        class="big_img--mobile" />
                    <a href="javascript:;" class="play_href watchVideoClick blue" data-url="./assets/videos/sample2.mp4"
                        data-target="#video_box" data-toggle="modal"><img src="./assets/images/playbutton.svg"
                            class="img-fluid play_btn" alt="play button"></a>


                </div>
                <div class="swiper-slide">
                    <img src="./assets/images/events/organise-exhibitions-banner.jpg" alt="" class="big_img--deskop" />
                    <img src="./assets/images/events/organise-exhibitions-banner-mobile.jpg" alt=""
                        class="big_img--mobile" />
                    <a href="javascript:;" class="jwc_360tour_btn play360" videosrc="./assets/videos/360.mp4">
                        <div class="jwc_360tour_btn--icon mb-0">
                            <img src="./assets/images/360/360.svg" class="img-fluid" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div class="swiper-container kma_with_thumbnails_thumb_img">
            <div class="swiper-wrapper">
                <div class="swiper-slide"><img src="./assets/images/events/organise-exhibitions-banner.jpg" alt="" />
                </div>
                <div class="swiper-slide"><img src="./assets/images/events/organise-exhibitions-banner.jpg" alt="" />
                </div>
                <div class="swiper-slide"><img src="./assets/images/events/organise-exhibitions-banner.jpg" alt="" />
                </div>
            </div>
        </div> -->

    </div>

    <!-- <div class="video_popup modal fade" id="video_box" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="wrapper modal-dialog" role="document">
            <div class="modal-content">
                <a href="javascript:;" class="close" data-dismiss="modal"></a>
                <video controls controlsList="nodownload" class="">
                    <source src="" type="video/mp4">
                    Your browser does not support HTML5 video.
                </video>
            </div>
        </div>
    </div> -->
    <section class="find_tags " [attr.style]="('--data-bgColor:#F5F2ED;') | safe: 'style'">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="section_heading section_heading--title section_heading--text    "
                        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
                        <h2>{{selectEventType?.ComponentTitle?.data}}</h2>
                        <p class='section_heading--text content2'>{{selectEventType?.ComponentShortText?.data}}
                        </p>
                    </div>


                    <div class="find_tags_list">
                        <a *ngFor="let item of selectEventType?.cards; let i=index"
                            [ngClass]="item?.selected==true? 'custume' : ''" (click)="itemSelected(item, i)">
                            <span>{{item?.EventType?.data}}</span>
                        </a>

                        <!-- <a href="#"><span>EXHIBITIONS</span></a>
                        <a href="#"><span>DIGITAL</span></a>
                        <a href="#"><span>EVENT TYPE</span></a>
                        <a href="#"><span>DIGITAL</span></a>
                        <a href="#"><span>EVENT TYPE</span></a>

                        <a href="#"><span>EXHIBITIONS</span></a>
                        <a href="#"><span>EXPO</span></a>
                        <a href="#"><span>EXHIBITIONS</span></a>
                        <a href="#"><span>DIGITAL</span></a>
                        <a href="#"><span>EXPO</span></a>
                        <a href="#"><span>EXHIBITIONS</span></a> -->

                    </div>
                </div>
            </div>

        </div>

    </section>


    <section class="eventtype_wrapper pb-0 " id="event-type" data-mobile-header="Event Type"
        [attr.style]="('--data-explore-bgColor1:;--data-explore-bgColor2:;--data-iconColor:#004EA8;') | safe: 'style'"
        *ngIf="eventTypes?.HideComponentOnSite?.data=='false' && filterdTypes?.length>0">
        <!-- <div class="container">
            <div class="row">
                <div class="section_heading section_heading--title section_heading--text text-center m-auto   "
                    [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
                    <h2>{{eventTypes?.ComponentTitle?.data}}</h2>
                </div>
            </div>
            <div class="row eventtype_wrapper_each" [attr.style]="('--data-explore-boxbgColor:') | safe: 'style'"
                *ngFor="let item of filterdTypes">
                <div class="col-lg-5 col-md-5 p-0" *ngIf="item?.BackgroundImageVideo?.data=='video'">
                    <div class="eventtype_wrapper_each--img">
                        <img src="{{staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl}}"
                            class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                        <a href="javascript:;" class="play_href watchVideoClick blue"
                            attr.data-url="{{ staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}"
                            data-target="#video_box" data-toggle="modal"><img src="./assets/images/playbutton.svg"
                                class="img-fluid play_btn" alt="play button"></a>
                    </div>
                </div>

                <div class="col-lg-5 col-md-5 p-0" *ngIf="item?.BackgroundImageVideo?.data=='360 video' || item?.BackgroundImageVideo?.data=='360 image'">
                    <div class="eventtype_wrapper_each--img">
                        <img src="{{staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl}}"
                            class="img-fluid" alt="{{item?.ImageAltText?.data}}">

                        <a href="javascript:;" class="jwc_360tour_btn play360"
                            attr.videosrc="{{staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}">
                            <div class="jwc_360tour_btn--icon mb-0">
                                <img src="./assets/images/360/360.svg" class="img-fluid" alt="">
                            </div>
                        </a>
                    </div>
                </div>

                <div class="col-lg-5 col-md-5 p-0" *ngIf="item?.BackgroundImageVideo?.data=='image'">
                    <div class="eventtype_wrapper_each--img">
                        <img src="{{staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}"
                            class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                    </div>
                </div>

                <div class="col-lg-7 col-md-7 eventtype_wrapper_each_copy">

                    <h4>{{item?.ComponentHeaderTitle?.data}}</h4>
                    <p class="eventtype_wrapper_each_copy--text content3">{{item?.ComponentHeaderSubTitle?.data}}</p>
                    <div class="icons_block">
                        <div class="icons_block_wrapper">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-4 pl-0" *ngFor="let icon of item?.AddIcons">
                                        <div class="icons_block_wrapper_each">
                                            <div class="icons_block_wrapper_each--icon">
                                                <img src="{{staticUrlForImgNew + icon?.Icon?.image?.contentUrl}}" alt=""
                                                    width="48" height="48">
                                            </div>
                                            <div class="icons_block_wrapper_each_copy">
                                                <h4 class="icons_block_wrapper_each_copy--number">
                                                    {{icon?.IconText?.data}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="spaces content3">
                        <span class="font-weight-bold">{{item?.SpacesTitle?.data}}:</span>
                        <a href="{{cta?.FirstCTALink?.data}}" class="spacetype" *ngFor="let cta of item?.AddCTA"
                            [hidden]="cta?.HideFirstCTA?.data=='true'"
                            (click)="_sharedService.checkCTALink($event, cta?.FirstCTALink?.data?.data, cta?.FirstCTALinkWindowPreference?.data)">
                            {{cta?.FirstCTAText?.data}}</a>
                    <div class="eventtype_wrapper_each--btns" *ngIf="item?.HideSecondCTA?.data=='false'">
                        <a href="{{item?.SecondCTALink?.data}}" class="cta-prim color-blue"
                            (click)="_sharedService.checkCTALink($event, item?.SecondCTALink?.data, item?.SecondCTALinkWindowPreference?.data)">
                            <span class="cta-prim-txt">{{item?.SecondCTAText?.data}}</span></a>
                    </div>

                </div>

            </div>

        </div> -->

        <ng-container *ngTemplateOutlet="eventCards; context:{array:filterdTypes} "></ng-container>
        <app-video-popup></app-video-popup>
    </section>




    <section class="eventtype_wrapper pb-0 " id="event-type" data-mobile-header="Event Type"
        [attr.style]="('--data-explore-bgColor1:;--data-explore-bgColor2:;--data-iconColor:#004EA8;') | safe: 'style'"
        *ngIf="eventTypes?.HideComponentOnSite?.data=='false' &&  filterdTypes?.length==0">
        
        <ng-container *ngTemplateOutlet="eventCards; context:{array:eventTypes?.cards} "></ng-container>
        <app-video-popup></app-video-popup>
    </section>

    <!-- <div class="video_popup modal fade" id="video_box" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="wrapper modal-dialog" role="document">
            <div class="modal-content">
                <a href="javascript:;" class="close" data-dismiss="modal"></a>
                <video controls controlsList="nodownload" class="">
                    <source src="" type="video/mp4">
                    Your browser does not support HTML5 video.
                </video>
            </div>
        </div>
    </div> -->


    <section class="getintouch " data-aos="fade-up" id="ask-us" data-mobile-header="Ask Us"
        [attr.style]="('--data-getintouch-bgColor:#ffffff') | safe: 'style'"
        *ngIf="askUsAnything?.HideComponentOnSite?.data=='false'">
        <div class="section_heading section_heading--title section_heading--text text-center   "
            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
            <h2>{{askUsAnything?.ComponentTitle?.data}}</h2>
            <p> {{askUsAnything?.ComponentShortText?.data}}
            </p>
        </div>


        <div class="text-center">
            <a *ngIf="askUsAnything?.HideFirstCTA?.data=='false'" href="{{askUsAnything?.FirstCTALink?.data}}"
                class="cta-prim color-blue emp-btn"
                (click)="_sharedService.checkCTALink($event, askUsAnything?.FirstCTALink?.data, askUsAnything?.FirstCTALinkWindowPreference?.data)">
                <span class="cta-prim-txt">{{askUsAnything?.FirstCTAText?.data}}</span>
            </a>
            <a *ngIf="askUsAnything?.HideSecondCTA?.data=='false'" href="{{askUsAnything?.SecondCTALink?.data}}"
                class="cta-sec color-blue emp-btn"
                (click)="_sharedService.checkCTALink($event, askUsAnything?.SecondCTALink?.data, askUsAnything?.SecondCTALinkWindowPreference?.data)">
                <span class="cta-sec-txt">{{askUsAnything?.SecondCTAText?.data}}</span>
            </a>
        </div>
    </section>
</main>

<ng-template #eventCards let-array="array">
    <div class="container">
        <div class="row">
            <div class="section_heading section_heading--title section_heading--text text-center m-auto   "
                [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
                <h2>{{eventTypes?.ComponentTitle?.data}}</h2>
            </div>
        </div>
        <div class="row eventtype_wrapper_each" [attr.style]="('--data-explore-boxbgColor:') | safe: 'style'"
            *ngFor="let item of array">
            <div class="col-lg-5 col-md-5 p-0" *ngIf="item?.BackgroundImageVideo?.data=='video'">
                <div class="eventtype_wrapper_each--img">
                    <img src="{{staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl}}"
                        class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                    <a href="javascript:;" class="play_href watchVideoClick blue"
                        attr.data-url="{{ staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}"
                        data-target="#video_box" data-toggle="modal"><img src="./assets/images/playbutton.svg"
                            class="img-fluid play_btn" alt="play button"></a>
                </div>
            </div>

            <div class="col-lg-5 col-md-5 p-0"
                *ngIf="item?.BackgroundImageVideo?.data=='360 video' || item?.BackgroundImageVideo?.data=='360 image'">
                <div class="eventtype_wrapper_each--img">
                    <img src="{{staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl}}"
                        class="img-fluid" alt="{{item?.ImageAltText?.data}}">

                    <a href="javascript:;" class="jwc_360tour_btn play360"
                        attr.videosrc="{{staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}">
                        <div class="jwc_360tour_btn--icon mb-0">
                            <img src="./assets/images/360/360.svg" class="img-fluid" alt="">
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-5 col-md-5 p-0" *ngIf="item?.BackgroundImageVideo?.data=='image'">
                <div class="eventtype_wrapper_each--img">
                    <img src="{{staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}" class="img-fluid"
                        alt="{{item?.ImageAltText?.data}}">
                </div>
            </div>

            <div class="col-lg-7 col-md-7 eventtype_wrapper_each_copy">

                <h4>{{item?.ComponentHeaderTitle?.data}}</h4>
                <p class="eventtype_wrapper_each_copy--text content3">{{item?.ComponentHeaderSubTitle?.data}}</p>
                <div class="icons_block">
                    <div class="icons_block_wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 pl-0" *ngFor="let icon of item?.AddIcons">
                                    <div class="icons_block_wrapper_each">
                                        <div class="icons_block_wrapper_each--icon">
                                            <img src="{{staticUrlForImgNew + icon?.Icon?.image?.contentUrl}}" alt=""
                                                width="48" height="48">
                                        </div>
                                        <div class="icons_block_wrapper_each_copy">
                                            <h4 class="icons_block_wrapper_each_copy--number">
                                                {{icon?.IconText?.data}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="spaces content3">
                    <span class="font-weight-bold">{{item?.SpacesTitle?.data}}:</span>
                    <a href="{{cta?.FirstCTALink?.data}}" class="spacetype" *ngFor="let cta of item?.AddCTA"
                        [hidden]="cta?.HideFirstCTA?.data=='true'"
                        (click)="_sharedService.checkCTALink($event, cta?.FirstCTALink?.data, cta?.FirstCTALinkWindowPreference?.data)">
                        {{cta?.FirstCTAText?.data}}</a>
                <div class="eventtype_wrapper_each--btns" *ngIf="item?.HideSecondCTA?.data=='false'">
                    <a href="{{item?.SecondCTALink?.data}}" class="cta-prim color-blue"
                        (click)="_sharedService.checkCTALink($event, item?.SecondCTALink?.data, item?.SecondCTALinkWindowPreference?.data)">
                        <span class="cta-prim-txt">{{item?.SecondCTAText?.data}}</span></a>
                </div>

            </div>

        </div>

    </div>
</ng-template>