import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cec-service-page',
  templateUrl: './cec-service-page.component.html',
  styleUrls: ['./cec-service-page.component.css']
})
export class CecServicePageComponent implements OnInit {
  cecServiceSubHeaderList;
  subscription;
  cecServicebannerData: any;
  cecServiceDiningData: any;
  cecServiceTechnologyData: any;
  cecServiceContactData: any;
  cecServiceExperiencesData: any;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  cecServiceColorList = { headerDataHoverColor:"#1E478E", egDataTitleColor:"#4B4847", egDataLineColor:"#004EA8", egDataGardenBgColor:"#FFFFFF", egDataCopyBgColor:"#F5F2ED", dataGetintouchTitleColor:"#4B4847", dataGetintouchLineColor:"#004EA8" };
  cecServiceKeyArray = [
    'CEC-SERVICE-BANNER',
    'CEC-SERVICE-DINING',
    'CEC-SERVICE-TECHNOLOGY',
    'CEC-SERVICE-ASK-US-ANYTHING',
    'CEC-SERVICE-SIGNATURE-EXPERIENCES'
  ];

  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this.cecServiceSubHeaderList = this._sharedService.subHeaderItems(["Dining", "Technology", "Signature Experiences"]);
    this.getCecServicePageContent();
  }
  getCecServicePageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.cecServiceKeyArray).subscribe(
      (data: any) => {
        this.cecServicebannerData = data[0]? data[0].data : data[0];
        this.cecServiceDiningData = data[1]? data[1].data : data[1];
        this.cecServiceTechnologyData = data[2]? data[2].data : data[2];
        this.cecServiceContactData = data[3]? data[3].data : data[3];
        this.cecServiceExperiencesData = data[4]? data[4].data : data[4];
        this._sharedService.initializeCustomJs();
        this._sharedService.callFractionSlider();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
