import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-loyalty-program',
  templateUrl: './loyalty-program.component.html',
  styleUrls: ['./loyalty-program.component.css']
})
export class LoyaltyProgramComponent implements OnInit {
  @Output() loyaltyEnrolCompleted = new EventEmitter();
  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
    this._sharedService.callCustomDropdown();
  }
  onLoyaltyEnrolComplete() {
    this.loyaltyEnrolCompleted.emit(true);
  }

}
