<form [formGroup]="registrationForm">
  <div class="row">
  <!-- First Name -->
  <div class="col-md-6">
    <div class="jwc_form_group">
      <input type="text" name="" value="" placeholder="First Name" class="form-control jwc_input uppercase" id=""
        formControlName="firstNameInput" appAllowedInput [alphabetsOnly]="true">
    </div>
    <div *ngIf="(registrationFormControls.firstNameInput.touched || submitted) && registrationFormControls.firstNameInput.errors">
      <div *ngIf="registrationFormControls.firstNameInput.errors.required" class="error-text">{{errorText}}</div>
    </div>
  </div>

  <!-- Last Name -->
  <div class="col-md-6">
    <div class="jwc_form_group">
      <input type="text" name="" value="" placeholder="Last Name" class="form-control jwc_input uppercase" id=""
        formControlName="lastNameInput" appAllowedInput [alphabetsOnly]="true">
    </div>
    <div *ngIf="(registrationFormControls.lastNameInput.touched || submitted) && registrationFormControls.lastNameInput.errors">
      <div *ngIf="registrationFormControls.lastNameInput.errors.required" class="error-text">{{errorText}}</div>
    </div>
  </div>

  <!-- Phone No -->
  <div class="col-md-6">
    <div class="jwc_form_group">
      <input type="text" name="" value="" placeholder="Phone Number" class="form-control jwc_input" id=""
        formControlName="phoneNoInput" maxlength="10" appAllowedInput [numbersOnly]="true">
    </div>
    <div class="error-text"
      *ngIf="(registrationFormControls.phoneNoInput.touched || submitted) && registrationFormControls.phoneNoInput.errors">
      Enter 10 digit Mobile Number</div>
  </div>

  <!-- Email -->
  <div class="col-md-6">
    <div class="jwc_form_group">
      <input type="email" name="" value="Juj@gmail.com" placeholder="Email" class="form-control jwc_input" id=""
        formControlName="emailInput">
    </div>
    <div *ngIf="(registrationFormControls.emailInput.touched || submitted) && registrationFormControls.emailInput.errors">
      <div *ngIf="registrationFormControls.emailInput.errors.required" class="error-text">{{errorText}}</div>
      <div *ngIf="registrationFormControls.emailInput.errors.pattern" class="error-text">Email must be a valid email
        address
      </div>
    </div>
  </div>

<div class="col-md-4">
  <div>
    <label class="radio-inline">
      <input type="radio" formControlName="vehicleInfo" value="Car" name="vehicleInfo" class="radio"  checked>4-Wheeler
    </label>
    <label class="radio-inline">
      <input type="radio" formControlName="vehicleInfo" value="Bike" name="vehicleInfo" class="radio">2-Wheeler
    </label>
    <!-- <input type="checkbox" class="custom-control-input" id="dontmiss" name="dontmiss" formControlName="acceptTerms">
    <label class="custom-control-label" for="dontmiss"></label> -->
  </div>
</div>
<div class="col-md-5">
  <div class="jwc_form_group">
    <input type="text" name="" value="" (click)="vehicleDetailsErr = ''" placeholder="Registration Number" class="form-control uppercase" id=""
      formControlName="registrationNumber" appAllowedInput [alphabetsOnly]="false">
      <div *ngIf="vehicleDetailsErr" atyle='margin-top: 4px' class="error-text">{{vehicleDetailsErr}}</div>
  </div>
</div>
<div class="col-md-3">
  <button style="margin-bottom: 16px;" class="cta-prim color-gold" (click)="addRegistrationNumber()">
    <span class="cta-prim-txt">Add New</span>
  </button>
</div>
<div class="col-md-6" >
  <div class="panel-group" *ngIf="registeredNumbers.length > 0">
    <div class="panel panel-primary" *ngFor="let item of registeredNumbers; let i = index">
      <div class="panel-heading ">{{item.type}}</div>
      <div class="panel-heading ">{{item.regn_no}}</div>
      <div class="close" (click)="removeRegistrationNumber(i)"></div>
    </div>
  </div>
</div>
<div class="col-md-12" *ngIf="regErr">{{regErr}}</div>
<div class="col-md-12">
  <button style="display: none" data-dismiss="modal" #closebutton></button>
<button class="cta-prim color-gold otp-cta" (click)="registrationSubmit(registrationForm)">
  <span class="cta-prim-txt">Save</span>
</button>
<button class="cta-prim color-gold otp-cta" style="margin-left: 16px;" (click)="skipRegForm()">
  <span class="cta-prim-txt">Cancel</span>
</button>
</div>
</div>
</form>