import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { constants } from '../../shared/shared.constant';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  subscription;
  contactUsColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor:"#976841"
  }
  contactUsbannerData;
  contatUsCardData;
  contactUsAccordianData;
  submitted;
  disableBtn;
  topicArr = ['Topic 1', 'Topic 2', 'Topic 3'];
  venueArr = [{key:'JWC', value:'Jio World Centre'}, {key:'CEC', value:'Jio World Convention Centre'}, {key:'JWG', value:'Jio World Garden'}, {key:'DAS', value:'Dhirubhai Ambani Square'}];
  //DAS live changes
  contactQueryArr = [{key:'general', value:'General'}, {key:'lostFound', value:'Lost & Found'},
  // {key:'marketing', value:'Marketing'}, 
  {key:'media', value:'Media Enquiries'}, {key:'careers', value:'Careers'}];
  countryCode = constants.countryCodes;
  errorText = 'Field is required';
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  isCaptchaSelected = false;
  captchaSiteKey;
  page: any;
  contactKeyArray = [];
  venueKey: any;
  fileSizeLimitInBytes = 5242880;
  fileExtensions = ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png', '.xlsx'];
  fileTypeError = "";
  fileSizeError = "";
  contactQueryKey: any;
  apiErrorMsg="";

  grcToken =  "XXXXXXXXXXX";


  constructor(public _sharedService: SharedService, private formBuilder: FormBuilder, private router: Router, private titleService: Title, private activatedRoute: ActivatedRoute) {
    this.page = this.activatedRoute.snapshot.data['page']
    this.getContactKeys();
   }
  contactUsForm: FormGroup;
  ngOnInit() {
    this.captchaSiteKey = environment.recaptchaSiteKey;
    this.getContactUsPageContent();
    this.buildForm();
  }

  getContactKeys(){
    switch (this.page) {
      case 'new-das':
        return this.contactKeyArray = [
          245221, // DAS - Contact - Banner
          245249, // DAS - Contact - Card Section
          245200 // DAS - Contact - Accordian Section
        ];
      default:
        return this.contactKeyArray = [
          'CONTACT-US-BANNER',
          'CONTACT-US-CARD-SECTION',
          'CONTACT-US-ACCORDIAN-SECTION'
        ];
      }
  }

  buildForm() {
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      countryCode: ['+91'],
      phoneNo: ['', [Validators.required, Validators.minLength(10)]],
      venue: ['', (this.page!=='new-das') ? Validators.required : ''],
      contactQuery: [''],
      // topic: [this.topicArr[0]],
      message: [''],
      fileInput: [''],
      file: [''],
      firstConsent: [false, Validators.requiredTrue],
      secondConsent: [false]
    });
  }

  get contactUsFormControls() { return this.contactUsForm.controls; }

  getContactUsPageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.contactKeyArray).subscribe(
      (data: any) => {
        this.contactUsbannerData = data[0]? data[0].data : data[0];
        this.contatUsCardData = data[1]? data[1].data : data[1];
        this.contactUsAccordianData = data[2]? data[2].data : data[2];
        this._sharedService.initializeCustomJs();
        this._sharedService.callCustomDropdown();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  setDropdownValue(formControlname, value, key) {
    this.contactUsForm.get(formControlname).setValue(value);
    if (formControlname === 'contactQuery') {
      this.contactQueryKey = key;
    } else if (formControlname === 'venue'){      
      this.venueKey = key;
    }
  }

  // sendRouteObj(tab) {
  //   this.router.navigate(['/legal'], {
  //     state: { tabName: tab }
  //   });
  //   return false;
  // }

  onFileUpload(files){
    this.contactUsForm = this._sharedService.handleFileInput(files, this.contactUsForm, this.fileSizeLimitInBytes, this.fileExtensions)
    this.fileSizeError = this._sharedService.isFileSizeValid(files[0], this.fileSizeLimitInBytes)
    this.fileTypeError = this._sharedService.isFileTypeValid(files[0], this.fileExtensions)
  }

  contactUsSubmit(form) {
    // console.log("form values:", form.value);
    this.submitted = true;
    // if(!this.isCaptchaSelected) return;
    if (form.invalid || !this.isCaptchaSelected) {
      return;
    }
    this.disableBtn = true;
    let contactUsPayload = form.value;
    let formData = new FormData();
    formData.append('requestFrom', 'website');
    formData.append('grcToken', this.grcToken);
    for (const field in this.contactUsFormControls) {
      if(field==='venue') this.venueKey ? formData.append('venue',this.venueKey) : formData.append('venue', 'DAS');
      else if (field==='contactQuery') {
        if (this.venueKey ==='DAS'){
          this.contactQueryKey ? formData.append('contactQuery',this.contactQueryKey) : formData.append('contactQuery', 'general');
        } else {
          formData.append('contactQuery', '');
        }
      } 
      else {formData.append(field, this.contactUsForm.controls[field].value);}
    }
    let contactUsCTProfile = {
      Organiser_Phone: contactUsPayload.countryCode + contactUsPayload.phoneNo,
      Organiser_Email: contactUsPayload.email,
      Organiser_Name: contactUsPayload.name
    };    
    let contactUsFormCTEventObj = {
      Organiser_Phone: contactUsPayload.countryCode + contactUsPayload.phoneNo,
      Organiser_Email: contactUsPayload.email,
      Organiser_Name: contactUsPayload.name,
      //DAS live changes
      Brand_Name: this.venueKey ? this.venueKey : 'das',
      Query_Type: this.venueKey,
      // Event_Name: 'Contact_Form'
    };
    // console.log("contactUsFormCTEventObj: ", contactUsFormCTEventObj);
    // console.log("contactUsCTProfile: ", contactUsCTProfile);
    this._sharedService.getCommonService().submitContactUsDetails(formData).subscribe(
      (data: any) => {
        this._sharedService.getCleverTapService().createCTUserLogin(contactUsCTProfile);
        this._sharedService.getCleverTapService().recordSubscriptionEvent('Contact_Form', contactUsFormCTEventObj);
        let navigateUrl = this.page === 'new-das' ? '/das/thank-you' : '/thank-you'
        this.router.navigate([navigateUrl], {
          state: { inqId: data.data, page: this.page }
        });
      },
      (error) => {
        this.disableBtn = false;
        this.apiErrorMsg = "Server busy, Please try after some time.";
        console.log("Try after some time..");
      }
    )
  }
    contactCaptchaRes($event, flag?) {
    if(!$event) return this.isCaptchaSelected = false;
    if(flag) return console.log('Something went long when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true
    this.grcToken = $event;
  }
  callContactUsCTEvent(sectionName) {
    this._sharedService.callCTEvent('Section_Secondary_Links', sectionName);
  }

}
