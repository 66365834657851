import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.css']
})
export class NewsArticleComponent implements OnInit {
  subscription: any;
  articleData: any;
  currentUrl: string;  
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  navigationData;
  newsid;
  pageName: any;
  constructor(public _sharedService: SharedService, public meta: Meta, public title: Title, public activatedRoute: ActivatedRoute, public router: Router, @Inject(DOCUMENT) private document: any) {
    this.pageName = this.activatedRoute.snapshot.data['page']
    this.newsid = this.activatedRoute.snapshot.queryParamMap.get('newsId');
    this.currentUrl = this.document.location.href
    // this.currentUrl = window.location.href;
    // console.log("currentUrl: ", this.currentUrl)
    let id;
    try {
        id = atob(this.newsid)
        this.getArticlePageContent(id)
    } catch (error) {
      this.router.navigate(['/not-found'])
    }
    this.router.events.subscribe(event => {
      // getCurrentNavigatio().extras.staten is commented as state is removed from das-footer
      // if (event instanceof NavigationEnd) {
      //   if (this.router.getCurrentNavigation().extras.state) {
      //     this.navigationData = this.router.getCurrentNavigation().extras.state;
      //     if (this.navigationData) {
      //       this.articleData = this.navigationData.articleJson ? this.navigationData.articleJson : '';
      //       // console.log("articleData: ", this.articleData);
      //       if(this.articleData) this.getArticlePageContent()
      //     }
      //   }
      // }
    })
   }

  ngOnInit() {
  }
  getArticlePageContent(nid) {
    this.subscription = this._sharedService.getCommonService().getSingleNewsArticlesData(nid).subscribe(
      (data: any) => {
        // console.log("data: ", data)
        if (data.status=='Published') {
          
          this.articleData = data ? data : [];
          this.meta.updateTag({ property: 'og:url', content: this.currentUrl }); 
          this.meta.updateTag({ property: 'og:image', content: this.staticUrlForImgNew + '/assets/images/logo-navbar-das.svg' }); 
          this.meta.updateTag({ property: 'og:title', content: data.newsTile}); 
          this.meta.updateTag({ property: 'og:description', content: data.newsDescription });
          this._sharedService.initializeCustomJs();
          this._sharedService.load360Function();
          setTimeout(() => document.getElementById("customfb-icon").addEventListener('click', this.fbShareClicked.bind(this)))
          setTimeout(() => document.getElementById("customtw-icon").addEventListener('click', this.twShareClicked.bind(this)))   
        }else{
          this.router.navigate(['/newsroom'])
        }
        },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  fbShareClicked(){
    document.getElementById("customfb-icon").setAttribute('target', '_blank')
    document.getElementById("customfb-icon").setAttribute('href', 'https://www.facebook.com/sharer/sharer.php?u='  + encodeURIComponent(this.currentUrl))
    //'https://jwcuat.ril.com' + this.router.url
  }
  twShareClicked(){
    // console.log("tw clicked")
    document.getElementById("customtw-icon").setAttribute('target', '_blank')
    document.getElementById("customtw-icon").setAttribute('href', 'https://twitter.com/share?url='  + encodeURIComponent(document.URL) + '&t=' + encodeURIComponent(document.title) + '&image=' + encodeURIComponent(this.staticUrlForImgNew + '/assets/images/logo-navbar-das.svg'))
    return false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
