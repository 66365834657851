import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upgrade-browser',
  templateUrl: './upgrade-browser.component.html',
  styleUrls: ['./upgrade-browser.component.css']
})
export class UpgradeBrowserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
