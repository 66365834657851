<section class="venues_wrapper"
    [attr.style]="('--data-venues-bgColor1: ' + enquireData?.ComponentBackgroundColor?.data + ';--data-tab-txt-Color:#4B4847; --data-tab-txt-hover-Color:#004EA8;') | safe: 'style'">
    <div class="container">

        <div class="section_heading text-center section_heading--title" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'"
            *ngIf="exploreVenuesData">
                <h2>{{exploreVenuesData?.ComponentHeaderTitle?.data}}</h2>
        </div>
        <div class="container venues_lists pb-0" data-aos="fade-up" *ngIf="exploreVenuesData">
            <div class="tabs">
                <!-- <div class="tab_mob icon-arrow-down">Exhibition Halls</div> -->

                <ul class="nav nav-tabs">
                    <li *ngFor="let item of exploreVenuesData?.cards; let i=index"><a href="{{'#tab'+(i+1)}}"
                            [ngClass]="{'active': i==0}" data-toggle="tab">{{item?.TabHeaderName?.data}}</a></li>
                </ul>
            </div>
            <div class="tab-content">
                <div class="tab-pane fade" [ngClass]="{'active show': i==0}" id="{{'tab'+(i+1)}}"
                    *ngFor="let item of exploreVenuesData?.cards; let i=index">
                    <div class="venue">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5 col-md-5 p-0">
                                    <div class="venue_img">
                                        <img src="{{ staticUrlForImgNew + item?.Image?.image?.contentUrl }}"
                                            class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7 venue_details">
                                    <h4>{{item?.TabHeaderName?.data}}</h4>
                                    <p class="content3">{{item?.TabShortText?.data}}</p>
                                    <div class="venue_details_size">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-12">
                                                <div class="venue_details_size--icon">
                                                    <img src="{{ staticUrlForImgNew + item?.FirstInfographicImage?.image?.contentUrl }}"
                                                        class="img-fluid" alt="{{item?.ImageAltText?.data}}"
                                                        *ngIf="item?.FirstInfographicImage?.image?.contentUrl">
                                                </div>
                                                <div class="venue_details_size_icon_txt">
                                                    <h5>{{item?.FirstInfographicHeaderText?.data}}</h5>
                                                    <p [innerHTML]="item?.FirstInfographicShortText?.data"></p>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-12">
                                                <div class="venue_details_size--icon">
                                                    <img src="{{ staticUrlForImgNew + item?.SecondInfographicImage?.image?.contentUrl }}"
                                                        class="img-fluid" alt="{{item?.ImageAltText?.data}}"
                                                        *ngIf="item?.SecondInfographicImage?.image?.contentUrl">
                                                </div>
                                                <div class="venue_details_size_icon_txt">
                                                    <h5>{{item?.SecondInfographicHeaderText?.data}}</h5>
                                                    <p [innerHTML]="item?.SecondInfographicShortText?.data"></p>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-12">
                                                <div class="venue_details_size--icon">
                                                    <img src="{{ staticUrlForImgNew + item?.ThirdInfographicImage?.image?.contentUrl }}"
                                                        class="img-fluid" alt="{{item?.ImageAltText?.data}}"
                                                        *ngIf="item?.ThirdInfographicImage?.image?.contentUrl">
                                                </div>
                                                <div class="venue_details_size_icon_txt">
                                                    <h5>{{item?.ThirdInfographicHeaderText?.data}}</h5>
                                                    <p [innerHTML]="item?.ThirdInfographicShortText?.data"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="cta-holder">
                                        <a href="{{item?.FirstCTALink?.data}}"
                                            *ngIf="item?.HideFirstCTAText?.data =='false'" class="cta-prim color-blue"
                                            (click)="callCecHomeCTEvent('pri', item?.TabHeaderName?.data); _sharedService.checkCTALink($event, item?.FirstCTALink?.data, item?.FirstCTALinkWindowPreference?.data, item?.TypeOfFirstCTALink?.data)">
                                            <span class="cta-prim-txt">{{item?.FirstCTAText?.data}}</span></a>
                                        <a href="javascript:void(0)" *ngIf="item?.HideSecondCTAText?.data =='false'"
                                            class="cta-sec icon rh icon-download-icon color-blue"
                                            (click)="callCecHomeCTEvent('download', item?.TabHeaderName?.data, item?.SecondCTALink?.data); _sharedService.checkCTALink($event, item?.SecondCTALink?.data, '_self', 'PDF', pdfName)">
                                            <span class="cta-sec-txt">{{item?.SecondCTAText?.data}}</span></a><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button_links">
                <a (click)="callCecHomeCTEvent('sec'); _sharedService.checkCTALink($event, exploreVenuesData?.ViewAllCTALink?.data, exploreVenuesData?.ViewAllCTALinkWindowPreference?.data, exploreVenuesData?.TypeOfViewAllCTALink?.data)"
                    class="cta-sec color-blue" *ngIf="exploreVenuesData?.HideViewAllCTAText?.data == 'false'"
                    href="{{exploreVenuesData?.ViewAllCTALink?.data}}">
                    <span class="cta-prim-txt">{{exploreVenuesData?.ViewAllCTAText?.data}}</span>
                </a>
            </div>
        </div>
    </div>
    <div class="section_heading section_heading--title section_heading--text text-center pt-100" [id]="planId" data-mobile-header="Plan" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'"*ngIf="enquireData">
            <h2 [innerHTML]="enquireData?.ComponentTitle?.data"></h2>
            <p [innerHTML]="enquireData?.ComponentShortText?.data"></p>
    </div>
    <div class="getintouch text-center" *ngIf="enquireData">
        <a (click)="callCecHomeCTEvent('pri', enquireData?.ComponentTitle?.data); _sharedService.checkCTALink($event, enquireData?.ContactUsCTALink?.data, enquireData?.CTAWindowPreference?.data, enquireData?.TypeOfCTALink?.data)"
            href="{{enquireData?.ContactUsCTALink?.data}}" class="cta-prim color-blue emp-btn"
            [hidden]="enquireData.HideContactUsCTA.data == 'true'">
            <span class="cta-prim-txt">{{enquireData?.CTAText?.data}}</span>
        </a>
        <a *ngIf="enquireData?.HideSecondCTA?.data =='false'" href="{{enquireData?.SecondCTALink?.data}}" (click)="callCecHomeCTEvent('sec', enquireData?.ComponentTitle?.data); _sharedService.checkCTALink($event, enquireData?.SecondCTALink?.data, enquireData?.SecondCTAWindowPreference?.data, enquireData?.TypeOfCTALink?.data)"
            class="cta-sec color-blue emp-btn"><span class="cta-sec-txt">{{enquireData?.SecondCTAText?.data}}</span></a>
    </div>

</section>