<app-sub-header [colorList]="dasColorList" [subHeaderList]="dasSubHeaderList" *ngIf="dasSubHeaderList"
  [pageName]="pageName">
</app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <app-thank-you-page [brandName]="'Jio World Centre'" [subscriptionFlag]="dasSubscriptionFlag"
    *ngIf="dasSuccessfulSubscription"></app-thank-you-page>
  <!--Add [autoplay]="false" to app-banner tag to stop autoplay, instead ask user to click button for video play-->
  <app-banner [landingBannerData]="dasBannerData" [bannerPage]="'das'" *ngIf="dasBannerData?.HideComponentOnSite?.data==='false'" [sectionClass]="'kma picture screen_height_Three_Quarters'" [blackTint]="true"></app-banner> 
  <!-- data-mobile-header="Book Passes" -->
  <section id="book-passes" class="event_short_details_2" *ngIf="bookFreePassesData?.HideComponentOnSite?.data==='false'"
    [attr.style]="('--data-details-bgColor:#F2f2ed; --event-heading-color:#4B4847; --event-text-color:#4B4847;') | safe: 'style'">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="event_short_details_2_title">
            <h4 [innerHTML]="bookFreePassesData?.Heading1?.data"></h4>
          </div>
          <div class="event_short_details_2_text content2">
            <p [innerHTML]="bookFreePassesData?.Heading2?.data"></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="event_short_details_2_link">
            <!-- <a *ngIf="bookFreePassesData?.HideCTA?.data==='false'" class="cta-prim color-pink emp-btn" href="{{bookFreePassesData?.CTALink?.data}}" (click)="callDasCTEvents('Section_Primary_Links', 'Book Passes', '', 'DAS-BE'); _sharedService.checkCTALink($event, '/tickets/dhirubhai-ambani-square', '', '')">
              <span class="cta-prim-txt">{{bookFreePassesData?.CTAText?.data}}</span>
            </a> -->
            <a *ngIf="bookFreePassesData?.HideCTA?.data==='false'" class="cta-prim color-pink emp-btn" href="javascript:void(0);" (click)="callDasCTEvents('Section_Primary_Links', 'Book Passes', '', 'DAS-BE'); openBookNow($event, bookFreePassesData?.CTALink?.data, '_self', 'External', bookFreePassesData?.EventID?.data);">
              <span class="cta-prim-txt">{{bookFreePassesData?.CTAText?.data}}</span>
            </a>
            <a *ngIf="bookFreePassesData?.HideSecondCTA?.data==='false'" class="cta-sec color-gold ml-5" href="{{bookFreePassesData?.SecondCTALink?.data}}" (click)="callDasCTEvents('Section_Secondary_Links', 'Book Passes', '', bookFreePassesData?.SecondCTAText?.data); _sharedService.checkCTALink($event, bookFreePassesData?.SecondCTALink?.data, bookFreePassesData?.SecondCTAWindowPreference?.data)">
              <span class="cta-sec-txt">{{bookFreePassesData?.SecondCTAText?.data}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-covid2-section *ngIf="dasVisitSafelyData?.HideComponentOnSite?.data==='false'" [page]="pageName" [covidPageData]="dasVisitSafelyData"></app-covid2-section>
  <section class="gallery pb-0 d-none d-lg-block" *ngIf="dasGalleryData?.HideComponentOnSite?.data==='false'">
    <div class="container gallery_content">
      <div class="row justify-content-between">
        <div class="col-lg-6 col-md-6 gallery_left">
          <div class="gallery_img_1">
            <ng-container [ngTemplateOutlet]="commonTemplate"
              [ngTemplateOutletContext]="{item:dasGalleryData?.cards[0]}"></ng-container>
          </div>
          <div class="gallery_img_2">
            <ng-container [ngTemplateOutlet]="commonTemplate"
              [ngTemplateOutletContext]="{item:dasGalleryData?.cards[1]}"></ng-container>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 gallery_right">
          <ng-container [ngTemplateOutlet]="commonTemplate" [ngTemplateOutletContext]="{item:dasGalleryData?.cards[2]}">
          </ng-container>
        </div>

      </div>
    </div>
  </section>
  <section id="schedule" data-mobile-header="Schedule" class="das-content content2 pb-0">
    <ng-container *ngIf="dasInfographicsData?.HideComponentOnSite?.data==='false'">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section_heading section_heading--title section_heading--text multilineText   "
              [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
              <h2>{{dasInfographicsData?.Heading?.data}}</h2>
              <p [innerHTML]="dasInfographicsData?.SubHeading?.data"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="container icons_wrapper">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-6" *ngFor="let item of dasInfographicsData?.cards">
            <div class="icons_items">
              <div class="icons_items--each">
                <img src="{{ staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl }}" class="img-fluid"
                  alt="{{item?.ImageAltText?.data}}" />
              </div>
              <div class="icons_items--text content2">
                <h5>{{item?.InfographicTitle?.data}}</h5>
                <p [innerHTML]="item?.InfographicSubTitle?.data"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="container schedule mt-5" *ngIf="schedulePlaylistData?.HideComponentOnSite?.data==='false'">
      <div class="row">
        <div [ngClass]="schedulePlaylistData?.MediaTypeToBeUploaded?.data==='none' ? 'col-lg-6' : 'col-lg-8'">
          <div class="section_heading section_heading--title" [attr.style]="('--data-titleColor:#4B4847;') | safe: 'style'">
            <h2><img src="{{staticUrlForImgNew + schedulePlaylistData?.UploadScheduleIcon?.document?.contentUrl}}"
                class="img-fluid heading-icon" alt="{{schedulePlaylistData?.ImageAltText?.data}}"/>
              {{schedulePlaylistData?.ScheduleComponentName?.data}}</h2>
          </div>
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-lg-5 col-md-6 " *ngIf="schedulePlaylistData?.MediaTypeToBeUploaded?.data!=='none'">
              <ng-container *ngIf="schedulePlaylistData?.MediaTypeToBeUploaded?.data=='image'; else videoTemplate">
                <img src="{{staticUrlForImgNew + schedulePlaylistData?.UploadPlaceholderImage?.document?.contentUrl}}" class="img-fluid w-100"  alt="{{schedulePlaylistData?.ImageAltText?.data}}">
              </ng-container>
              <ng-template #videoTemplate>
                <a href="javascript:void(0);" (click)="callDasCTEvents('Media_Links', schedulePlaylistData?.ScheduleComponentName?.data, '', 'schedule-video');" class="video  watchVideoClick w-100" data-target="#video_box" data-toggle="modal" [attr.data-url]="staticUrlForImgNew + schedulePlaylistData?.UploadMedia?.document?.contentUrl">
                  <img src="{{staticUrlForImgNew + schedulePlaylistData?.UploadPlaceholderImage?.document?.contentUrl}}" class="img-fluid w-100"  alt="{{schedulePlaylistData?.ImageAltText?.data}}">
                  <div class="play_href white">
                    <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
                  </div>
                </a>
              </ng-template>
            </div>
            <div class="content3" [ngClass]="schedulePlaylistData?.MediaTypeToBeUploaded?.data==='none' ? 'col-lg-12' : 'col-lg-7 col-md-6'">
              <div class="dates" *ngIf="schedulePlaylistData?.HideScheduleInfoText1?.data ==='false'"
                [innerHTML]="schedulePlaylistData?.ScheduleInfoText1?.data">
              </div>
              <div class="dates" *ngIf="schedulePlaylistData?.HideScheduleInfoText2?.data ==='false'"
                [innerHTML]="schedulePlaylistData?.ScheduleInfoText2?.data">
              </div>
            </div>
          </div>
        </div>
        <div class="content3 songs_list" *ngIf="schedulePlaylistData?.PlaylistComponentName?.data" [ngClass]="schedulePlaylistData?.MediaTypeToBeUploaded?.data==='none' ? 'col-lg-6' : 'col-lg-4'">
          <div class="section_heading section_heading--title" [attr.style]="('--data-titleColor:#4B4847;') | safe: 'style'">
            <h2 *ngIf="schedulePlaylistData?.PlaylistComponentName?.data"><img src="{{staticUrlForImgNew + schedulePlaylistData?.UploadPlaylistIcon?.document?.contentUrl}}"
                class="img-fluid heading-icon"  alt="{{schedulePlaylistData?.ImageAltText?.data}}" />
              {{schedulePlaylistData?.PlaylistComponentName?.data}}
            </h2>
          </div>
          <div class="clearfix"></div>
          <div class="songs" [ngStyle]="{'overflow-y': (myIdentifier.offsetHeight >= 244) ? 'scroll' : 'hidden' }">
            <ul #myIdentifier>
              <li *ngFor="let item of schedulePlaylistData?.cards; let i=index">
                <p><strong>{{item?.PlaylistName?.data}}</strong></p>
                <p *ngIf="item?.Duration?.data">Duration - {{item?.Duration?.data}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="things-to-know" data-mobile-header="Things To Know" class="things_know"
    [attr.style]="('--data-things-know-bgColor: ' + thingsToKnowData?.ComponentBackgroundColor?.data) | safe: 'style'" *ngIf="thingsToKnowData?.HideComponentOnSite?.data==='false'">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section_heading section_heading--title section_heading--text    "
            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
            <h2>{{thingsToKnowData?.ComponentTitle?.data}}</h2>
          </div>
        </div>
        <div class="col-lg-6 things pr-md-4 content2" *ngFor="let item of thingsToKnowData?.cards">
          <div class="things_know_title">
            <h4> <img src="{{ staticUrlForImgNew + item?.UploadIcon?.document?.contentUrl }}"
              alt="{{item?.ImageAltText?.data}}"
                class="img-fluid things_know_title--icon" />{{item?.Title?.data}}</h4>
          </div>
          <p [innerHTML]="item?.Description?.data"></p>
          <a href="{{item?.CTALink?.data}}" *ngIf="item?.HideCTA?.data==='false'" class="cta-sec color-gold" (click)="callDasCTEvents('Section_Secondary_Links', item?.Title?.data, '', item?.CTAText?.data); _sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTAWindowPreference?.data)"><span class="cta-sec-txt">{{item?.CTAText?.data}}</span></a>
        </div>
      </div>
    </div>
  </section>
  <section id="gallery" data-mobile-header="Gallery" class="gallery2 pb-0" *ngIf="dasGalleryShowcaseData?.HideComponentOnSite?.data==='false'">
    <div class="container ">
      <div class="row">
        <div class="col-lg-12">
          <div class="section_heading section_heading--title section_heading--text text-center   "
             [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
            <h2>The Gallery</h2>
          </div>
          <div class="jwc_gallery_section" data-thumb-count="5">
            <div class="swiper-container gallery_big">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of dasGalleryShowcaseData?.cards; let i=index">
                  <div class="showcase_img">
                    <ng-container [ngTemplateOutlet]="commonTemplate" [ngTemplateOutletContext]="{item:item}">
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-container gallery_thumbs">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of dasGalleryShowcaseData?.cards; let i=index" (click)="callDasCTEvents('Media_Links', 'The Gallery', '', item?.UploadMediaThumbnail?.document?.title, item?.MediaType?.data);">
                  <img src="{{ staticUrlForImgNew + item?.UploadMediaThumbnail?.document?.contentUrl }}"
                    class="img-fluid"  alt="{{item?.ImageAltText?.data}}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="review pb-0" *ngIf="reviewsData?.HideComponentOnSite?.data==='false'">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section_heading section_heading--title section_heading--text    "
            [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
            <h2>{{reviewsData?.ComponentTitle?.data}}</h2>
          </div>
          <div class="review_section jwc_review_section">
            <div class="swiper-container review_slider">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of reviewsData?.cards; let i=index">
                  <div class="review_slider_each">
                    <div class="review_slider_each_publisher">
                      <img src="{{ staticUrlForImgNew + item?.UploadIcon?.document?.contentUrl }}"  alt="{{item?.ImageAltText?.data}}"
                        class="review_slider_each_publisher--logo" />
                      <div class="review_slider_each_publisher--details content3">
                        <h4>{{item?.Title?.data}}</h4>
                        <p>{{item?.Datew60p?.data | date: 'MMMM yyyy'}}</p>
                      </div>
                    </div>
                    <div class="review_slider_each--data content2">
                      <p [innerHTML]="item?.Description?.data"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  </section>  
  <app-instagram2-section *ngIf="dasInstaData" [instagramData]="dasInstaData" [instagramHandleName]="'dhirubhai_ambani_square'" [followUsLink]="'dhirubhai_ambani_square'" [sectionClass]="'pb-0'"></app-instagram2-section>
  <div id="faqs" data-mobile-header="FAQs">
    <app-faq-section *ngIf="dasFaqData?.HideComponentOnSite?.data==='false'" [faqData]="dasFaqData"
    [colorList]="dasColorList"></app-faq-section>
    <div [innerHTML]="jsonLDforDasGeneral"></div>
    <div [innerHTML]="jsonLDforDasBooking"></div>
    <!-- <div [innerHTML]="jsonLDforDasAas"></div> -->
    <div [innerHTML]="jsonLDforDasPlanAVisit"></div>
  </div>
  <div id="subscribe" data-mobile-header="Subscribe">
    <app-subscribe-section *ngIf="dasSignUpData?.HideComponentOnSite?.data==='false'" [subscribeData]="dasSignUpData" [colorList]="dasColorList"
    [pageName]="'das'" [sectionClass]="'section_heading--title small_space'"></app-subscribe-section>
  </div>
</main>
<!--Image, Video, 360 conditional rendering template-->
<ng-template let-item="item" #commonTemplate>
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="item?.MediaType?.data === 'video'">
      <a href="javascript:;" class="video watchVideoClick" data-target="#video_box"
        [attr.data-url]="staticUrlForImgNew + item?.UploadMedia?.document?.contentUrl" data-toggle="modal">
        <img src="{{ staticUrlForImgNew + item?.placeholderImage?.document?.contentUrl }}" class="img-fluid"
        alt="{{item?.ImageAltText?.data}}">
        <div class="play_href white">
          <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
        </div>
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="item?.MediaType?.data==='360 Image' || item?.MediaType?.data==='360 video'">
      <a href="javascript:;" class="play360box play360"
        [attr.imgsrc]="staticUrlForImgNew + item?.UploadMedia?.document?.contentUrl">
        <img src="{{ staticUrlForImgNew + item?.placeholderImage?.document?.contentUrl }}" class="img-fluid"
        alt="{{item?.ImageAltText?.data}}">
        <div class="icon360"></div>
      </a>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <img src="{{ staticUrlForImgNew + item?.UploadMedia?.document?.contentUrl }}"
      alt="{{item?.ImageAltText?.data}}" class="img-fluid" />
    </ng-container>
  </ng-container>
</ng-template>
<!--Video popup template-->
<app-video-popup></app-video-popup>
<!--360 player placeholder-->
<div id="player360">
  <div id="content360"></div>
  <div id="close360"></div>
</div>