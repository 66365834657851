<ng-container>

    <ng-container *ngIf="notification$ | async as data">
        <ng-container *ngIf="data?.data?.HideComponentOnSite?.data ==='false'">
            <app-website-notification *ngIf="!closeExpiredNotificationDiv" [notificationData]="data"
                (closeNotification)="closeNotificationEvent($event)"
                (closeExpiredNotification)="closeExpiredNotificationEvent($event)"></app-website-notification>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="pageName">
        <app-das-header [dasSticky]="isSticky" [dasPage]="pageName" [newHeaderDesktop]="isNewHeaderDesk"
            *ngIf="isNewHF; else otherHeader"></app-das-header>
        <ng-template #otherHeader>
            <app-header [sticky]="isSticky" [page]="pageName"></app-header>
        </ng-template>
    </ng-container>
    <router-outlet></router-outlet>
    <div [innerHTML]="customMetaService.schemaScript" *ngIf="customMetaService.schemaScript!=''"></div>
    <!-- <ng-container *ngIf="isBrowser()"> -->
    <app-cookie-policy (hideConsent)="consentFlag = $event" *ngIf="consentFlag == 'false' && !isGuestGuidePage">
    </app-cookie-policy>
    <!-- </ng-container> -->
    <ng-container *ngIf="pageName">
        <app-das-footer *ngIf="isNewHF; else otherHeader"></app-das-footer>
        <ng-template #otherHeader>
            <app-footer [islandingPage]="islandingPage"></app-footer>
        </ng-template>
    </ng-container>
<!-- </ng-container> -->
<!-- <div class="overlay"></div> -->
</ng-container>
<!-- <app-hello-world></app-hello-world> -->