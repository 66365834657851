<section [ngClass]="isIconsBlockClass ? 'icons_block' : 'pt-0 mt-55 pb-0'">
  <div [ngClass]="{'icons_block_wrapper': isIconsBlockClass}">
      <div class="container">
          <div class="row">

              <div class="col-md-4" data-aos="fade-up" [attr.data-aos-delay]="200*i" *ngFor="let item of infographicsData.cards; let i = index">
                  <div class="icons_block_wrapper_each">
                      <div class="icons_block_wrapper_each--icon">
                          <img src="{{staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl}}"
                              class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                      </div>
                      <div class="icons_block_wrapper_each_copy">
                          <h2 class="icons_block_wrapper_each_copy--number">{{item?.InfographicTitle?.data}}</h2>
                          <p class="icons_block_wrapper_each_copy--name content2">{{item?.InfographicSubTitle?.data}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>