<section class="faqs_wrapper" [ngClass]="sectionClass" *ngIf="faqData">
  <div class="container">
    <div class="section_heading section_heading--title text-center text_bold" [attr.style]="('--data-lineColor: ' + colorList?.primaryColor) | safe: 'style'">
        <h2 [innerHTML]="faqData?.ComponentHeaderTitle?.data"></h2>
    </div>
    <div id="accordion" class="cus_accordion">
      <!-- style="--data-faqs-arrowColor: #4B4847" -->
      <div class="card" *ngFor="let item of faqData?.cards; let i=index;">
        <div class="card-header" id="heading{{i}}">
          <h5 class="mb-0">
            <button class="btn btn-link content1" (click)="callFaqSectionCTEvent('cont', item?.Question?.data);" data-toggle="collapse" [attr.data-target]="'#collapse' + i" [attr.aria-expanded]="i===0 ? true : false" [attr.aria-controls]="'collapse' + i" [ngClass]="{'collapsed': i !== 0}">
              {{item?.Question?.data}}
              <span class="icon-arrow-down"></span>
            </button>
          </h5>
        </div>
        <div id="collapse{{i}}" class="collapse" [attr.aria-labelledby]="'heading'+i" data-parent="#accordion" [ngClass]="{'show': i === 0}">
          <div class="card-body">
            <div class="card-body_copy">
              <p [innerHTML]="(item?.Answer?.data) | safe: 'html'" class="content3"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center faqMore load-more" *ngIf="faqData?.HideCTA?.data =='false'">
        <a href="{{faqData?.LoadMoreCTATextLink?.data}}" (click)="callFaqSectionCTEvent('sec'); _sharedService.checkCTALink($event, faqData?.LoadMoreCTATextLink?.data, '_self', 'Internal')" 
         class="cta-sec" [ngClass]="colorClass.get(colorList?.primaryColor)">
          <span class="cta-sec-txt">{{faqData?.LoadMoreCTAText?.data}}</span>
        </a>
      </div>
    </div>
  </div>
</section>