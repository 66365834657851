import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  templateUrl: '404.component.html'
})
export class P404Component implements OnInit{
  notFoundColorList = {
    primaryColor: '#C69E67', footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor:"#976841"
  };

  constructor(public _sharedService: SharedService, private router: Router) { }

  ngOnInit() {
    this._sharedService.initializeCustomJs();
  }

}
