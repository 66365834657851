export class schemaJson {
  static faqDasGeneralSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How do I reach Dhirubhai Ambani Square?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We are located in Jio World Centre, Bandra Kurla Complex which is well-connected to all the major landmarks in Mumbai. You can also park in Jio World Centre for a fee. You can drive in from Gate 20. If you are simply walking in, enter through Gate 18. Get more details and the Google Map route here."
      }
    }, {
      "@type": "Question",
      "name": "Is there a charge to attend the performance?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The Fountain of Joy show at Dhirubhai Ambani Square is free for all. However, you must book your e-pass."
      }
    }, {
      "@type": "Question",
      "name": "is bringing food or beverages into Dhirubhai Ambani Square allowed?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Food and beverages are not allowed inside the venue. If required, you may bring a small bottle of water. Water and refreshments are available at the venue."
      }
    }, {
      "@type": "Question",
      "name": "Is smoking inside Dhirubhai Ambani Square allowed?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Dhirubhai Ambani Square is a smoke-free zone."
      }
    }, {
      "@type": "Question",
      "name": "Is parking available at the venue?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, self-parking is available at Jio World Centre for the duration of the show. You can drive in from Gate 20 to park your vehicle. To enter, vehicles must pass a security check."
      }
    }, {
      "@type": "Question",
      "name": "what are the covid-19 safety measures being implemented?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Dhirubhai Ambani Square and Jio World Centre requires the Guests (18 Y and older) to be fully vaccinated in order to access its premises. Face masks are required for all Guests, including before and after the show except for when consuming food or beverage, regardless of vaccination status. To learn about all the measures we are taking to ensure your safety, please visit our Covid-19 safety page."
      }
    }, {
      "@type": "Question",
      "name": "Can I watch the show if I am unvaccinated or not fully vaccinated?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "For the safety of all our guests, adults are required to be fully vaccinated and present their government-issued vaccination certificate (2 doses + 14 days) at the entry gates in order to enter the Square."
      }
    }, {
      "@type": "Question",
      "name": "Are wheelchairs available for guests with limited mobility?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Please notify our stall at the entrance or call us prior to the show on 022 3570 8999 between 11 am and 9 pm. We will be happy to provide a wheelchair. The Square is fully accessible by the wheelchair."
      }
    }, {
      "@type": "Question",
      "name": "Are you present on Instagram or Facebook?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "General Yes, we are. Please follow us on @dhirubhai_ambani_square on Instagram and Dhirubhai Ambani Square on Facebook."
      }
    }]
  };
  static faqDasScheduleSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What time does Dhirubhai Ambani Square open?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To view our show schedule, please visit our Schedule section. Our gates open 60 minutes prior to the show."
      }
    }, {
      "@type": "Question",
      "name": "What should I do if I arrive late?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Late entry is not permitted. Entry will be at managements discretion."
      }
    }, {
      "@type": "Question",
      "name": "what is the best way to find out when a fountain show is taking place?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Please visit our website or BookMyShow to see the current schedule and show timings."
      }
    }, {
      "@type": "Question",
      "name": "When should I arrive at the show?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We recommend that you arrive at least 20 minutes prior to the show, to allow for the security and safety checks at the entrance. Our entry gates open 60 minutes before the start of the show."
      }
    }, {
      "@type": "Question",
      "name": "What is the duration of the show?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The show lasts approximately 30 minutes."
      }
    }, {
      "@type": "Question",
      "name": "What do I need to do if my show is cancelled? What if there is unexpected heavy rainfall on the day of the show?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We do our best to ensure that the show is operational and enjoyed. Occasionally, however, there could be days technical issues make it impossible for us to host a show. In such situations, you will be notified as soon as possible on your registered email or phone number. We also publish all show cancellations on our website."
      }
    }, {
      "@type": "Question",
      "name": "Are kids allowed inside?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely! We can guarantee both adults and kids will love our spectacular Fountain of Joy."
      }
    }, {
      "@type": "Question",
      "name": "Are pets allowed inside?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "While we love animals, for the preservation of our venue and safety of our other visitors, pets are not allowed inside the Square."
      }
    }, {
      "@type": "Question",
      "name": "Can I transfer my passes to someone else?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, the passes are transferable."
      }
    }]
  };
  static faqDasBookingSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How do I book passes for the fountain of Joy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can book free admission passes on our website. We update the show schedule on a weekly basis."
      }
    }, {
      "@type": "Question",
      "name": "Can I book more than one pass for a show?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can book up to 8 passes per person per day on our website."
      }
    }, {
      "@type": "Question",
      "name": "How can I book an admission pass if I do not have an Indian phone number?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To book your admission pass online, you will need an Indian phone number as well as a valid email address."
      }
    }, {
      "@type": "Question",
      "name": "Can I get an admission pass at the Square just before the show?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We do not distribute physical admission passes at the venue. Please log on to our website to book your free pass online."
      }
    }, {
      "@type": "Question",
      "name": "How much in advance can I purchase a ticket for a show?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can book your pass up to a week in advance."
      }
    }, {
      "@type": "Question",
      "name": "Is seating available during the show? Is it possible to reserve a good viewing seat(s)?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The Fountain of Joy show is a free-seating show. Arriving early will almost certainly guarantee you a good viewing spot."
      }
    }]
  };
  static faqCecGeneralSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is the address of Jio Word Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Convention Centre is located at G Block, Bandra Kurla Complex, Mumbai 400 051, Maharashtra, India."
      }
    }, {
      "@type": "Question",
      "name": "What is Jio World Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "With an area totalling 32,163 sq. m., Jio World Convention Centre is the finest convention and exhibition centre in India. It has 3 Exhibition Halls, 2 Convention Halls, 1 Ballroom, 25 Meeting Rooms and Suites, 2 Business Lounges, and multi-storey concourses. It also features a dedicated Registration Hall with an attached Business Centre. Jio World Convention Centre is part of Jio World Centre, a multifaceted, mixed-use development in BKC, Mumbai, which also houses The Cultural Centre, Jio World Plaza, Jio World Residences and The Clubhouse, and an office complex. It also includes Jio World Garden, Jio World Drive and Bay Club, which are part of the Jio World Centre network in BKC, but located outside the precinct."
      }
    }, {
      "@type": "Question",
      "name": "How do I reach Jio World Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Convention Centre is located in Mumbai, India and is well connected by multiple daily international and domestic flights to and from the G20 nations. The Chhatrapati Shivaji Maharaj International Airport is 9 kms away, and the Domestic Airport terminal is 6 kms away. There is local transportation including car hailing services that are available to and from airports, railway stations and other key locations in the city. The upcoming metro station will be 2 kms away from the Centre."
      }
    }, {
      "@type": "Question",
      "name": "What are the different type of events Jio World Convention Centre can host?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Convention Centre is designed to offer the utmost flexibility of hosting various types of events including industry trade shows, consumer shows, product launches, conventions, conferences and corporate events, annual general meetings, seminars, board meetings, press conferences, indoor sporting events, public concerts, weddings and other social events. You can fill our Enquiry Form for assistance with venue details and hires."
      }
    }, {
      "@type": "Question",
      "name": "Do you offer shuttles to the nearest airport or railway station?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There are no prescheduled shuttle services to or from the airport. Our concierge will be happy to assist you with arranging a vehicle if you let us know in advance."
      }
    }, {
      "@type": "Question",
      "name": "What are the sustainability features of Jio World Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Centre is LEED Platinum compliant and is part of a socially responsible and environmentally sensitive convention, exhibition and entertainment precinct. We have taken several steps towards a sustainable approach, which include landscaping 20% of the total building area with over 700 native trees, water recycling, electric charging points for vehicles, on-site waste management and 75% daylight for all regularly occupied areas, allowing natural light to animate many of the spaces throughout the day."
      }
    }, {
      "@type": "Question",
      "name": "Is Jio World Convention Centre accessible to those with special needs?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, it is completely accessible to people with special needs. We also offer assisted golf cart and wheelchair services on request to enhance the mobility of our guests with special needs."
      }
    }, {
      "@type": "Question",
      "name": "How do I reserve venue for an event?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Contact us and our team will get you started with the booking process."
      }
    }, {
      "@type": "Question",
      "name": "Is smoking permitted at Jio World Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Smoking is not allowed inside the Centre, in line with the local laws and regulations."
      }
    }, {
      "@type": "Question",
      "name": "How do I share feedback about the venue or facilities?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Suggestions and feedback about our services are welcome as they encourage us to constantly grow and improve. Please write to us at here."
      }
    }, {
      "@type": "Question",
      "name": "Do you allow pets?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Suggestions and feedback about our services are welcome as they encourage us to constantly grow and improve. Please write to us at here."
      }
    }, {
      "@type": "Question",
      "name": "What are the modes of payment accepted at Jio World Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Convention Centre accepts payments in the form of bank cheque, cash, demand draft, electronic funds transfer or credit card (as per RBI Guidelines). Bank details for transfer of funds shall be provided on request by our sales/event planning team."
      }
    }]
  };
  static faqCecServicesSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is unique about Jio World Convention Centre's Kitchens?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Convention Centre features a HACCP compliant international kitchen facility with capabilities to craft over 18,000 gourmet meals a day. The Centre is also equipped with a physically separated ‘Pure Vegetarian Kitchen’, and a dedicated kitchen for regional Indian cuisines. Our kitchens also hold the ISO 22000:2018 certification."
      }
    }, {
      "@type": "Question",
      "name": "Does Jio World Convention Centre provide audiovisual technology services for the events?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Convention Centre is a next-gen venue and features the latest technology and audiovisual solutions for events. We offer a wide range of services including the most advanced digital assistant app, 5G enabled gigabit framework, digital signage for navigation and the option to host virtual and hybrid events."
      }
    }, {
      "@type": "Question",
      "name": "How can Jio World Convention Centre help me plan my event?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We have a dedicated event planning team who are equipped with the most advanced virtual event planning tools. This enables our planners to conduct virtual meetings and tours of the Convention Centre for event hosts, to help them visualise their hall set-ups and seating plans through rich 3D views. Our team partners with you through the planning process that includes recommending experts for décor, stand construction, freight and logistics."
      }
    }, {
      "@type": "Question",
      "name": "Do you have a Business Centre and what are the services it offers?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our Business Centre located at Level P1 is operational from 7AM - 7PM. The Business Centre offers various secretarial services for an enhanced experience for all our guests. These include meeting rooms and work desks on hire, printing, lamination, visiting card printing and binding services, among others."
      }
    }, {
      "@type": "Question",
      "name": "Is Wi-Fi available at the Jio World Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Convention Centre offers high-speed Wi-Fi across the Centre. All registered guests gain 2 hours of free high-speed internet usage for web browsing and accessing the Centre’s digital services. Additional hours of internet usage and higher bandwidth plans are available at a fee."
      }
    }, {
      "@type": "Question",
      "name": "How many vehicles can be parked at Jio World Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Approximately 5,000 cars can be parked in the basement car parking facility including electric cars with provisions for charging. The Centre can accommodate more than 1,350 two wheelers as well."
      }
    }, {
      "@type": "Question",
      "name": "Do you have car valet service?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we do offer car valet services at a fee."
      }
    }, {
      "@type": "Question",
      "name": "Do you have Lost & Found facility?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we do. To report any lost items, please contact our Lost & Found desk that is managed by our security department."
      }
    }, {
      "@type": "Question",
      "name": "Does the venue have any ATM facility?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, the venue has an ATM facility."
      }
    }, {
      "@type": "Question",
      "name": "Is there a driver's lounge or cafeteria",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Drivers' lounges are in the basement parking area and are equipped to offer refreshments, meals and soft beverages at nominal charges. To book a group meal service for chauffeurs in advance, you can write to us at bookings@jioworldconventioncentre.com."
      }
    }, {
      "@type": "Question",
      "name": "Do you have a clockroom?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we do have a cloakroom or baggage facility for delegates."
      }
    }, {
      "@type": "Question",
      "name": "What emergency medical assistance is available at Jio World Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A fully equipped first aid medical room is located at the ground level of the Convention Centre, which is always manned during event operational hours, and we have a 24*7 on-site ambulance, and life-saving equipment. Our professionally certified and highly skilled medical team provides rapid response, and contact with external agencies, as required in case of an emergency."
      }
    }, {
      "@type": "Question",
      "name": "What are the security features available at the Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Convention Centre is secured entirely through a multi-layered security set-up. Modern security equipment such as bollards, explosive tracer detectors, X-ray baggage and cargo scanners, automatic number plate recognition, facial recognition systems, CCTV cameras and access control are deployed across the venue."
      }
    }, {
      "@type": "Question",
      "name": "Is there a safety locker to keep valuables?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes. There are two vaults available for usage by event organizers at Level 1.2 of the Centre."
      }
    }, {
      "@type": "Question",
      "name": "Do you provide fire extinguisher/fire marshals during the show?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The venue is equipped with fire response and management systems and fire extinguishers. However, it is mandatory for event organisers to deploy additional fire marshals for their individual exhibitions."
      }
    }]
  };
  static faqCecAroundSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Do you have restaurants near Jio World centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The precinct will house various multi-cuisine and specialty restaurants, cafés and bars.  Additionally, there are several great dining options close by."
      }
    }, {
      "@type": "Question",
      "name": "What are accommodation options available near Jio World Convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "More than 5,000 hotel rooms in various price ranges are available within a 5 km radius of Jio World Convention Centre. These include two luxury hotel accommodations within walking distance. 1. Trident, Bandra Kurla, with 436 rooms, managed by Oberoi Hotels & Resorts (150 metres away) 2. Sofitel, Mumbai BKC, with 302 rooms, managed by Accor hotels (300 metres away) A list of hotel accommodation options is available on request"
      }
    }, {
      "@type": "Question",
      "name": "Which are the closest hospitals to Jio World convention Centre?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Asian Heart Institute (Multi-speciality Hospital) Contact number: 022 6698 6666 Lilavati Hospital & Research Centre (Multispeciality Hospital) Contact number: 022 2675 1000 Guru Nanak Hospital & Research Centre (Multispeciality Hospital) Contact Number: 022-42227777"
      }
    }, {
      "@type": "Question",
      "name": "What are the various sightseeing options in Mumbai?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The central location of Jio World Convention Centre allows one to experience the city’s major attractions with ease.Some of the major sightseeing options are:  1: Bandra Known for its endless energy, diverse people, bustling streets, vigorous nightlife and eclectic cafes and stores, Bandra is one of the most alluring neighbourhoods in Mumbai. 2: Dr. Bhau Daji Lad Mumbai City Museum.  This iconic museum at Byculla documents the history of the city and has archives with rare manuscripts and maps of Mumbai, along with a stunning collection of contemporary art and other artefacts. 3: Gateway of India Apart from being a fantastic spot to imbibe some culture, you can also head out to sea in a chartered yacht and enjoy an undeniable Mumbai experience at Gateway of India. 4: Juhu Beach If you want to watch the sun set over Mumbai, head to Juhu Chowpatty. While you’re there, chat with some locals and enjoy some of the chaat that the city is so famous for. 5: Kala Ghoda With its art festivals, quirky boutiques and charming restaurants, this historic South Mumbai district is a must-visit cultural destination. A few other options include Kanheri Caves, Elephanta Caves, Global Vipassana Pagoda (near Gorai), Shree Siddhivinayak Temple, Haji Ali Shrine and Mani Bhavan Gandhi Museum."
      }
    }, {
      "@type": "Question",
      "name": "What is Mumbai's weather like?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Mumbai is an all-season destination that experiences tropical climate. Its coastal nature and location ensure that temperatures do not fluctuate much throughout the year. Mumbai experiences three distinct seasons:  1. A moderate winter (December to February): Average temperature is at 15 to 20 °C. 2. Summer (March to May): Average temperature 27 to 30°C with peak summer months at 35–40°C. 3. Monsoon: (June to November): Average temperature is at 24–29°C."
      }
    }]
  }
  static faqJwgGeneralSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Garden is a premium, open-air turfed venue located in Bandra Kurla Complex, and has been operational since 2015. It features an extensive public car parking facility and is a hub for cultural, community and sporting events."
      }
    },{
      "@type": "Question",
      "name": "What makes it an ideal venue for cultural, community and sporting events?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Garden is a multipurpose and child-friendly venue, with high standards of safety, provision for ample parking and easy accessibility. The turf is eco-friendly and is accessible for our visitors with special needs. It has a stunning landscape, and features soothing water fountains, seating areas, canopies, pavilions and eight high mast light towers."
      }
    },{
      "@type": "Question",
      "name": "How do I reach Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There are multiple modes of transportation available to get to Jio World Garden: -Car or Cab – Venue is easily locatable on maps  -Bus – BEST Buses going to Bandra Kurla Complex  -Local train - Closest stations are Bandra Station, Kurla Station and Santacruz Station  -Metro – The upcoming metro stations, lines 2 and 3, are 7 - 10 minutes away"
      }
    },{
      "@type": "Question",
      "name": "What are the Jio World Garden timings? How do I know when Jio World Garden is open?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Garden is open to the public and can be accessed on non-event days at the following timings: -Morning – 5.30 AM to 10 AM-Afternoon – 12 Noon to 2 PM-Evening – 4 PM to 7 PMWe may choose to revise the hours of operations from time to time. We recommend you stay tuned to our website prior to visiting for updates and notifications."
      }
    },{
      "@type": "Question",
      "name": "What is the entry fee for Jio World Garden as a public space?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The entry fee is as follows:-Person above 10 years – INR 10 -Children below 10 years and Senior Citizens – INR 5 -Infants and visitors with special needs – Free   -Government officials with valid photo identity may contact our office for free entry We may revise the entry fee from time to time, and entry is subject to terms & conditions.   You can read more about them here."
      }
    },{
      "@type": "Question",
      "name": "Why am I paying an entry fee if this is a public garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Garden is privately maintained, and hence, we charge a nominal fee for its upkeep."
      }
    },{
      "@type": "Question",
      "name": "What are the entry and exit points to enter Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can enter or exit the Garden via Gate No. 10 during public hours. Our conservancy staff will guide you at the gates."
      }
    },{
      "@type": "Question",
      "name": "Am I allowed to enter Jio World Garden on the days when there is no even happening?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you are allowed to enter Jio World Garden except on days when it is occupied for an event set-up or dismantling to ensure your safety. You may check with our office to get updates and notifications about the operational days, prior to your visit."
      }
    },{
      "@type": "Question",
      "name": "Are eatables allowed at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Eatables are not permitted inside unless there are private events."
      }
    },{
      "@type": "Question",
      "name": "Can I take photographs or videos inside Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Photography and/or videography, with either professional cameras or mobile phones is not permitted inside."
      }
    },{
      "@type": "Question",
      "name": "Are pets allowed?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pets are not allowed inside."
      }
    },{
      "@type": "Question",
      "name": "What are the facilities for those with special needs?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We offer facilities such as wheelchair assistance, access to separate restrooms, reserved parking, staff assistance and free entry during operational hours."
      }
    },{
      "@type": "Question",
      "name": "Do you have adequate restroom facilities and children care ares?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Well-designed and well-maintained public conveniences, separate for men and women are available including in the parking level. All ladies’ rooms at ground level are equipped with a dedicated childcare area. We also have separate, easily accessible restrooms for visitors with special needs."
      }
    },{
      "@type": "Question",
      "name": "Is drinking water available?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, free drinking water is available from multiple water fountains installed on all four sides of Jio World Garden."
      }
    },{
      "@type": "Question",
      "name": "Can I bring my sports/fitness equipment inside Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You may bring your yoga mats. However, usage of football, tennis balls, heavy weights and other fitness equipment is restricted during public hours. For any community or sports event enquiry, kindly fill our Enquiry Form, or write to us at info@jioworldgarden.com."
      }
    },{
      "@type": "Question",
      "name": "Is ATM facility at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We do not have an ATM facility inside Jio World Garden. However, you can find several ATMs around the vicinity."
      }
    },{
      "@type": "Question",
      "name": "Can i use JioMoney to pay parking fees?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We do not have an ATM facility inside Jio World Garden. However, you can find several ATMs around the vicinity."
      }
    },{
      "@type": "Question",
      "name": "Does Jio World Garden have CCTV surveillance?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes. Jio World Garden including its underground parking facility and entry and exit points has 24*7 CCTV surveillance."
      }
    },{
      "@type": "Question",
      "name": "What do I do in case of emergency?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In case of an emergency, we request you to reach out to our facility staff for guidance. If you notice any suspicious activity or object, in or around Jio World Garden, please inform our staff immediately."
      }
    },{
      "@type": "Question",
      "name": "Is there a lost and found section?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Items found at the venue are kept under the custody of our Security Team. Please contact our staff to retrieve them."
      }
    },{
      "@type": "Question",
      "name": "Why is there no actual grass but synthetic truff?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Sustainability is important to us. Our venue has approximately 13,000 sq. m of synthetic turf installed as opposed to natural grass to conserve water."
      }
    },{
      "@type": "Question",
      "name": "Is there seating at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The best way to enjoy Jio World Garden is by spending leisure time on the green turf. You can also sit on the bleachers across the Garden."
      }
    },{
      "@type": "Question",
      "name": "What is an air tree?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Air trees are steel structures with potted plants and creepers, which produce an oxygen-rich environment and naturally cool the surroundings."
      }
    },{
      "@type": "Question",
      "name": "is there access to first aid?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "First aid kits are available at various locations at Jio World Garden for minor emergencies. You can get in touch with the security staff to access them."
      }
    },{
      "@type": "Question",
      "name": "Is there a defined smoking area at the venue?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Smoking (including E-cigarettes, imitation or simulation devices) within the Jio World Garden premises is prohibited. Use or consumption of any tobacco products, liquor, narcotics, or any other contraband substance is also prohibited."
      }
    },{
      "@type": "Question",
      "name": "Are there venue lights?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes. All the areas of Jio World Garden including the basement parking are well-lit."
      }
    },{
      "@type": "Question",
      "name": "Can we get a group/school picnic?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes. However, this is subject to availability and terms & conditions. For more information, you can write to us at info@jioworldgarden.com."
      }
    },{
      "@type": "Question",
      "name": "Is there Wi-Fi available?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Free Jio-enabled 4G Wi-Fi is available at Jio World Garden for limited time periods. For events and special requirements or any other Wi-Fi related queries, please reach out to our sales team."
      }
    },{
      "@type": "Question",
      "name": "How can I give feedback?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We welcome your feedback. You can email us about your experience at info@jioworldgarden.com."
      }
    }]
  }
  
  static faqJwgParkingSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What are the parking timings?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Cars can access the parking between 5:30 AM and 11:59 PM. However, cars can be retrieved any time. Please note, our parking administration may revise the timings periodically."
      }
    },{
      "@type": "Question",
      "name": "What vehicles can I park here?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "As per the MMRDA guidelines, we only provide car parking."
      }
    },{
      "@type": "Question",
      "name": "What are the charges for parking? Is there a seasonal pass available?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The current parking charges are displayed at multiple locations. We also offer the option of a seasonal pass at our parking facility. For more information, write to us at info@jioworldgarden.com"
      }
    },{
      "@type": "Question",
      "name": "Is overnight parking available?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Overnight car parking is not available. However, in case of an emergency, you can reach out to our parking office for assistance."
      }
    },{
      "@type": "Question",
      "name": "I am going out of station. Can I park my vehicle at Jio World Garden for some days?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We do not offer overnight car parking. In case you do need to park your car for more than 24 hours, you can approach our parking office staff."
      }
    },{
      "@type": "Question",
      "name": "How safe is Jio World Garden's car park?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We have a spacious and covered parking facility that can accommodate 2,000 cars. Some of the features include: -Multiple entry and exit points for congestion-free circulation -Anti-skid driving surface and sensor-based parking lights for safety -Wide parking bays built for ease of manoeuvring for large cars and SUVs   -Ample ventilation, fire detection and security systems -There are also alarm buttons on alternate columns, 24-hour CCTV surveillance and control room, and a well-trained, round the clock security team To know more about our parking, click here or reach out to our parking office."
      }
    },{
      "@type": "Question",
      "name": "What facilities are available for drivers?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A driver’s lounge along with restrooms is available in the parking area."
      }
    },{
      "@type": "Question",
      "name": "What are the parking traffics for 10,12 and 24 hours of parking for seasonal parkers?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "For details on this, please reach out to our parking office. Alternatively, you can write to us with your queries at info@jioworldgarden.com."
      }
    },{
      "@type": "Question",
      "name": "I have and event planned in out office premise/ground near Jio World Garden. Will I be allowed to park my car at the Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our parking is open to the public from 5:30 AM to 11:59 PM. You can park your car here during these hours, subject to space availability."
      }
    },{
      "@type": "Question",
      "name": "I have an event scheduled for 6 AM and guests arrival is scheduled for 4 AM. Can my guests park at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our parking facility is open for use only between 5:30 AM and 11:59 PM. However, if you’re looking to book parking slots beyond operational hours, you can reach out to our parking office or write to us at info@jioworldgarden.com."
      }
    }]
  }
  
  static faqJwgEventsSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What kind of events happen at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Jio World Garden is a one-of-a-kind venue that offers a unique experience to its visitors and guests. It can host a diverse range of world-class events such as concerts, fashion and food events, wellness and sporting events and literary festivals among others. To know all about our past events, you can visit the Archives page. If you have a unique concept for an event in your mind, feel free to share your ideas with our team here."
      }
    },{
      "@type": "Question",
      "name": "How can I stay updated on all the events happening at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Follow our social pages to stay updated on all the events happening at Jio World Garden: Facebook Twitter Instagram YouTube You can also sign up to receive emails on events and stories from Jio World Garden."
      }
    },{
      "@type": "Question",
      "name": "How do I organise an event/wedding at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Reach out to us at info@jioworldgarden.com. Our team will help you get started."
      }
    },{
      "@type": "Question",
      "name": "Can I host a community event here?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can host community events. In case you have any queries or are looking to hire the venue, write to us at info@jioworldgarden.com."
      }
    },{
      "@type": "Question",
      "name": "Who can i connect with for information on booking an event at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "For information related to venue bookings, please fill up our Enquiry Form"
      }
    },{
      "@type": "Question",
      "name": "Where can I buy passes for an event happening at Jio World Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can get in touch with the organisers directly via their website to book passes."
      }
    },{
      "@type": "Question",
      "name": "Can I hire your venue for shoots?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes. For detailed information, you can reach out to us on info@jioworldgarden.com."
      }
    },{
      "@type": "Question",
      "name": "Can I bring my own vendors/suppliers for an event?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, after obtaining prior consent from our team."
      }
    }]
  }
  
}
