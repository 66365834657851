import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-getting-here',
  templateUrl: './getting-here.component.html',
  styleUrls: ['./getting-here.component.css']
})
export class GettingHereComponent implements OnInit {
  @Input() gettingHereData;

  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
  }

}
