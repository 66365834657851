<section class="access_wrapper heading_align" [ngClass]="sectionClass">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="section_heading section_heading--title section_heading--text pbottom-0" [attr.style]="('--data-titleColor: ' + '#212529' + '; --data-lineColor: ' + colorList.primaryColor ) | safe: 'style'">
                      <h2 [innerHTML]="accessWithEaseData?.ComponentHeaderTitle?.data"></h2>
                      <p [innerHTML]="accessWithEaseData?.ComponentHeaderSubTitle?.data" role="heading"></p>
              </div>
          </div>
          <div class="col-md-7">
              <div class="bg_before_left" [attr.style]="('--data-bglayer-color: ' + colorList.primaryColor ) | safe: 'style'">
                  <!-- <div *ngIf="accessWithEaseData">
                      <a href="javascript:void(0)"
                      (click)="_sharedService.checkCTALink($event, accessWithEaseData?.MapRedirectionLink?.data, accessWithEaseData?.mapLinkWindowPreference?.data, accessWithEaseData?.TypeOfMapRedirectionLink?.data)">
                        <img src="./assets/images/map.jpg" class="img-fluid" alt="map">
                      </a>
                  </div> -->
                  <div id="map" [attr.data-location]="locationData"></div> 
              </div>
              <div class="access_wrapper_copy">
                  <div class="access_wrapper_copy--btns">
                      <a href="{{accessWithEaseData?.GetDirectionsCTARedirectionLink?.data}}" class="cta-prim" [ngClass]="colorClass.get(colorList.primaryColor)" *ngIf="accessWithEaseData?.HideGetDirectionsCTAText.data == 'false'"
                      (click)="callCTGetDirectionsEvent(); _sharedService.checkCTALink($event, accessWithEaseData?.GetDirectionsCTARedirectionLink?.data, accessWithEaseData?.getDirectionsCTALinkWindowPreference?.data, accessWithEaseData?.TypeOfGetDirectionsCTALink?.data)">
                          <span class="cta-prim-txt">{{accessWithEaseData?.GetDirectionsCTAText?.data}}</span>
                      </a>
                      <!-- <a href="javascript:void(0)" class="cta-sec" [ngClass]="colorClass.get(colorList.primaryColor)" *ngIf="accessWithEaseData?.HideBookParkingCTAText.data == 'false'"
                      (click)="_sharedService.checkCTALink($event, accessWithEaseData?.BookParkingCTARedirectionLink?.data, accessWithEaseData?.bookParkingCtaLinkWindowPreference?.data, accessWithEaseData?.TypeOfBookParkingCTALink?.data)">
                          <span class="cta-sec-txt">{{accessWithEaseData?.BookParkingCTAText?.data}}</span>
                      </a> -->
                  </div>
              </div>
          </div>
          <div class="col-md-5">
              <div class="heading_align_wrapper"  [attr.style]="('--data-access-textColor: ' + colorList.primaryColor) | safe: 'style'">
                <!-- <div class="access_wrapper_each parkinghrs" *ngIf="accessWithEaseData?.HideParkingHours?.data == 'false'">
                    <div class="access_wrapper_each_copy">
                        <h5 class="access_wrapper_each_copy--title">Parking Hours: <span>{{accessWithEaseData?.ParkingHours?.data}}</span></h5>
                    </div>
                </div>   -->
                <div class="access_wrapper_each" data-aos="fade-up" [attr.data-aos-delay]="200+i*50" *ngFor="let item of accessWithEaseData?.cards; let i = index">
                      <div class="access_wrapper_each--icon">
                        <img src="{{staticUrlForImgNew + item?.UploadIcon?.document?.contentUrl}}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                      </div>
                      <div class="access_wrapper_each_copy">
                          <h5 class="access_wrapper_each_copy--title">{{item?.ModeOfTransportName?.data}}</h5>
                          <div class="access_wrapper_each_copy--text">
                              <p class="content3">{{item?.Details1.data}}</p>
                              <p class="content3">{{item?.Details2.data}}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>