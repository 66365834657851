<footer>
  <div class="jwc_footer_top">
    <div class="jwc_footer_top_inner text-center">
      <ul class="jwc_footer_top_inner_links">
        <!-- <li><a href="{{'http://115.112.43.74:3200/tickets/dhirubhai-ambani-square'}}" (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, 'http://115.112.43.74:3200/tickets/dhirubhai-ambani-square','_blank', 'External')">Book Passes</a></li> -->
        <li><a href="{{'/das/get-directions'}}" (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, '/das/get-directions')">Get Directions</a></li>
        <!-- <li><a href="{{'/das/newsroom'}}" (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, '/das/newsroom')">Press Release</a></li> -->
        <!-- passArticleData('/das/press-releases/'+articleData?.brandName+'/'+articleData?.slugUrl); -->
        <li><a (click)="callCTFooterPrimEvent();" [routerLink]="'/das/press-releases/'+articleData?.brandName+'/'+articleData?.slugUrl" [queryParams]="{ newsId:encryptedId }">Press Release</a></li>
        <li><a href="{{'/das/visit-safely'}}" (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, '/das/visit-safely')">Safety</a></li>
        <li><a href="{{'/das/frequently-asked-questions'}}" (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, '/das/frequently-asked-questions')">FAQs</a></li>
        <li><a href="{{'/das/contact'}}" (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, '/das/contact')">Contact</a></li>
      </ul>
    </div>
  </div>
  <div class="jwc_footer_bar">
    <div class="jwc_footer_bar_inner">
      <div class="col-md-4">
        <p class="desktop_copy_text">© Copyright {{currentYear}} Jio World Centre. All rights reserved</p>
      </div>
      <div class="col-md-4">
        <ul class="links">
          <li><a href="{{'https://www.ril.com/'}}"
            (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, 'https://www.ril.com/', '_blank', 'External')">Visit
            Reliance</a></li>
        <li><a href="{{'/das/legal?tab=terms'}}" (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, '/das/legal?tab=terms')">Terms &amp; Conditions</a></li>
        <li><a href="{{'/das/legal?tab=privacy'}}" (click)="callCTFooterPrimEvent(); _sharedService.checkCTALink($event, '/das/legal?tab=privacy')">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="col-md-4 social justify-content-end">
        <!-- <p>Follow Us</p> -->
        <ul class="social--UL">
          <li><a href="{{'https://www.facebook.com/dhirubhaiambanisquare'}}" (click)="callCTFooterPrimEvent('https://www.facebook.com/dhirubhaiambanisquare'); _sharedService.checkCTALink($event, 'https://www.facebook.com/dhirubhaiambanisquare', '_blank', 'External')" class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.963 20.993">
              <g id="Facebook" transform="translate(-5.254)">
                <path id="Path_1" data-name="Path 1" d="M87.114,20.993V11.429H90.38L90.846,7.7H87.114V5.365c0-1.05.35-1.866,1.866-1.866h1.983V.117C90.5.117,89.33,0,88.047,0a4.5,4.5,0,0,0-4.782,4.9V7.7H80v3.732h3.266v9.563Z" transform="translate(-74.746 0)" fill="#4b4847" fill-rule="evenodd"></path>
              </g>
            </svg>
          </a></li>
          <li><a href="{{'https://www.instagram.com/dhirubhai_ambani_square'}}" (click)="callCTFooterPrimEvent('https://www.instagram.com/dhirubhai_ambani_square'); _sharedService.checkCTALink($event, 'https://www.instagram.com/dhirubhai_ambani_square', '_blank', 'External')" class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.186 21.186">
              <path id="Linkedin" d="M16.8,21.186V14.307c0-1.644-.025-3.755-2.279-3.755-2.287,0-2.629,1.792-2.629,3.632v7H7.506V7.034h4.2V8.973h.057a4.612,4.612,0,0,1,4.157-2.282c4.443,0,5.268,2.928,5.268,6.74v7.754Zm-12.064,0H.343V7.034H4.737V21.185h0ZM7.5,7.034h.008ZM0,2.552A2.548,2.548,0,1,1,2.548,5.1,2.553,2.553,0,0,1,0,2.552Z" fill="#4b4847"></path>
            </svg>
          </a></li>
          <li><a href="{{'https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg'}}" (click)="callCTFooterPrimEvent('https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg'); _sharedService.checkCTALink($event, 'https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.676 22.162">
            <path id="Youtube" d="M13.338,21.162h0c-.069,0-6.97-.051-9.061-.288-.112-.027-.249-.047-.408-.07a3.709,3.709,0,0,1-2.536-1.228A7.333,7.333,0,0,1,.276,16.593c0-.024-.276-2.435-.276-4.857V9.474c0-2.42.273-4.881.276-4.906A6.667,6.667,0,0,1,1.334,1.587,3.6,3.6,0,0,1,3.921.344h.005L4,.337C7.683.052,13.282,0,13.338,0s5.655.052,9.337.337L22.8.347a3.367,3.367,0,0,1,2.541,1.239A7.333,7.333,0,0,1,26.4,4.569c0,.025.276,2.484.276,4.906v2.261c0,2.373-.273,4.833-.276,4.857a7.335,7.335,0,0,1-1.058,2.982,3.6,3.6,0,0,1-2.588,1.243h-.005l-.074.007C18.992,21.158,13.394,21.162,13.338,21.162ZM10.118,5.723V16.16l8.739-5.1Z" transform="translate(0.5 0.5)" fill="#4b4847" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"></path>
          </svg>
        </a></li>
        </ul>
      </div>
      <div class="social col-12 justify-content-end mobile_copy_text">
        <p>© Copyright {{currentYear}} Jio World Centre. All rights reserved</p>
      </div>
    </div>
  </div>
</footer>