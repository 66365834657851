<section class="getintouch" [ngClass]="sectionClass" *ngIf="getInTouchData" data-aos="fade-up"
[attr.style]="'--data-getintouch-bgColor:'+ getInTouchData?.ComponentBackgroundColor?.data | safe: 'style'">
  <div class="section_heading section_heading--title section_heading--text text-center" [attr.style]="'--data-titleColor: ' + colorList?.dataGetintouchTitleColor + '; --data-lineColor: ' + colorList?.dataGetintouchLineColor | safe: 'style'">
      <h2 [innerHTML]="getInTouchData?.ComponentTitle?.data"></h2>
      <p [innerHTML]="getInTouchData?.ComponentShortText?.data"></p>
      <p [innerHTML]="getInTouchData?.AdditionalContent?.data"></p>
  </div>
  <div class="text-center">
    <a *ngIf="getInTouchData?.HideContactUsCTA?.data =='false' && !downloadCta" class="cta-prim" [ngClass]="buttonColorClass.get(pageName)" href="{{getInTouchData?.ContactUsCTALink?.data}}"
    (click)="callGetInTouchCTEvent('pri'); _sharedService.checkCTALink($event, getInTouchData?.ContactUsCTALink?.data, getInTouchData?.CTAWindowPreference?.data, getInTouchData?.TypeOfCTALink?.data)">
      <span class="cta-prim-txt">{{getInTouchData?.CTAText?.data}}</span>
    </a>
    <a *ngIf="getInTouchData?.HideSecondCTA?.data =='false' && !downloadCta" href="{{getInTouchData?.SecondCTALink?.data}}" class="cta-sec" [ngClass]="buttonColorClass.get(pageName)" (click)="callGetInTouchCTEvent('sec'); _sharedService.checkCTALink($event, getInTouchData?.SecondCTALink?.data, getInTouchData?.SecondCTAWindowPreference?.data, getInTouchData?.TypeOfCTALink?.data)"><span class="cta-sec-txt">{{getInTouchData?.SecondCTAText?.data}}</span></a>
    <a *ngIf="getInTouchData?.HideContactUsCTA?.data =='false' && downloadCta" class="cta-prim" [ngClass]="buttonColorClass.get(pageName)" href="{{getInTouchData?.ContactUsCTALink?.data}}"
    (click)="callGetInTouchCTEvent('download'); _sharedService.checkCTALink($event, getInTouchData?.ContactUsCTALink?.data, '_self', 'PDF', callPdfName(getInTouchData?.ContactUsCTALink?.data))">
      <span class="cta-prim-txt">{{getInTouchData?.CTAText?.data}}</span>
    </a>
  </div>
</section>