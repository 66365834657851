<div class="maindiv">
    <header>
        <div class="logo"><img src="./assets/images/guest-guide/Logo.svg" alt="logo"></div>
    </header>

    <div class="contentdiv">
        <div class="txt1">
            India’s New Epicentre for
        </div>
        <div class="txt2">
            IDEAS, INNOVATION <br> AND CULTURE
        </div>
        <div class="borderline">

        </div>
        <div class="para">
            To know more about our unique spaces, please download our brochure and maps from the link below.
        </div>
        <div class="maps">
            <a class="cta-sec" href="{{'./assets/pdfs/jio-world-convention-centre-pavilions-map.pdf'}}" (click)="_sharedService.checkCTALink($event, './assets/pdfs/jio-world-convention-centre-pavilions-map.pdf', '_self', 'PDF', 'jio-world-convention-centre-pavilions-map')">
                <img src="./assets/images/guest-guide/map.svg" alt="map">
                <p class="cross-line-right">
                    <span>VENUE MAPS</span>
                </p>
            </a>
        </div>
        <div class="download">
            <a class="cta-sec" href="{{'./assets/pdfs/jio-world-convention-centre-brochure.pdf'}}"(click)="_sharedService.checkCTALink($event, './assets/pdfs/jio-world-convention-centre-brochure.pdf', '_self', 'PDF', 'jio-world-convention-centre-brochure')">
                <img src="./assets/images/guest-guide/Download.svg" alt="download">
                <p class="cross-line-right">
                    <span>DOWNLOAD BROCHURE</span>
                </p>
            </a>
        </div>
    </div>
</div>