import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../../../shared/services/shared.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-cec-venue-details-halls',
  templateUrl: './cec-venue-details-halls.component.html',
  styleUrls: ['./cec-venue-details-halls.component.css']
})
export class CecVenueDetailsHallsComponent implements OnInit {
  @Input() cecVenueDetailsHallsData;
  // @Input() pdfName;
  downloadedFileName;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService, private titleService: Title) {  }

  ngOnInit() {
  }
  callCecVenueDetailsCTEvent(btn, fileName?) {
    switch (btn) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', this.cecVenueDetailsHallsData.ComponentHeaderTitle.data);
        break;
      case 'download':
        this.downloadedFileName = this._sharedService.getPdfName(fileName);
        this._sharedService.callCTEvent('Download_Links', this.cecVenueDetailsHallsData.ComponentHeaderTitle.data, this.downloadedFileName);
        break;
    }
  }

  callPackageCTEventForCTA(event, type='cta') {
    const { eventObj } = this._sharedService.getCleverTapKeysAndObject(event, type);
    const eventName = eventObj.eventName || 'Section_Secondary_Links';
    this._sharedService.callCTEventNew(eventName, eventObj);
  }

}
