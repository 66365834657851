import { Component, OnInit } from '@angular/core';
import { SafeHtml, Title } from '@angular/platform-browser';
import { schemaJson } from '../../shared/schema.constant';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-garden-page',
  templateUrl: './garden-page.component.html',
  styleUrls: ['./garden-page.component.css']
})
export class GardenPageComponent implements OnInit {
  private subscription;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  gardenKeyArray;
  gardenBannerData;
  gardenExperiencesData;
  gardenNewsData;
  gardenMomentsData;
  gardenFaqData;
  gardenSubscribe2Data;
  gardenAccessData;
  gardenJWGData;
  gardenInfographicsData;
  gardenGetInTouchData;
  gardenOrganiserData;
  gardenAboutData;
  gardenItineraryData;
  gardenSubHeaderList;
  gardenShowcaseData;
  isBtnPresent = false;
  gardenColorList = { newsDataTitleColor: "#4B4847", newsDataLineColor: "#43B02A", newsDateColor: "#6CC24A", newsCopyColor: "#F5F2ED", newsViewAllLineColor: "#43B02A", primaryColor: '#43B02A', experiencesDataLineColor: '#6CC24A', experiencesDataTitleColor: '#4B4847', footerIconColor: '#43B02A', footerHoverColor: '#EDFDE8', footerFillColor: '#FFFFFF', momentsIconColor: "#43B02A", subscribe2DotColor: "#43B02A", subscribe2BorderColor: "#6CC24A", subscribe2TitleColor: "#4B4847", subscribe2LineColor: "#43B02A", dataSpeakerItemColor: "#62B947", organiserTitleColor: "#4B4847", organiserLineColor: "#6CC24A", headerDataHoverColor: "#43B02A", dataGetintouchTitleColor: "#4B4847", dataGetintouchLineColor: "#6CC24A" };
  gardenExpSwiperData = { experiencesSliderName: 'experiences_fraction' }
  successfulSubscription: boolean = false;
  aboutId;
  eventsId;
  highlightsId;
  planId;
  jsonLDforJwgGeneral: SafeHtml;
  jsonLDforJwgBooking: SafeHtml;
  jsonLDforJwgAas: SafeHtml;
  jsonLDforJwgPlanAVisit: SafeHtml;

  constructor(public _sharedService: SharedService, private titleService: Title) {
    this.gardenSubHeaderList = (window.innerWidth > 991) ? ["Events showcase", "Plan your event", "Venue Showcase", "About"] :
      ["About", "Events", "Testimonials", "Venue Showcase", "Highlights", "Contact", "Getting Here", "FAQs"];
    this.eventsId = (window.innerWidth > 991) ? 'events-showcase' : 'events';
    this.aboutId = (window.innerWidth > 991) ? 'section-2' : 'about';
    this.highlightsId = (window.innerWidth > 991) ? 'about' : 'highlights';
    this.planId = (window.innerWidth > 991) ? 'plan-your-event' : 'contact';
    this._sharedService.getCommonService().successfulSubscription.subscribe(
      (data) => {
        this.successfulSubscription = data;
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
  }
  ngOnInit() {
    this.callJwgCTEvent("Page_Load_JWG", "", "Jio World Garden");
    this.getGardenPageContent();
    this._sharedService.getCommonService().onSubsciptionPageLoad();
    this.jsonLDforJwgGeneral = this._sharedService.getSafeHTML(schemaJson.faqJwgGeneralSchema);
    this.jsonLDforJwgBooking = this._sharedService.getSafeHTML(schemaJson.faqJwgParkingSchema);  
     this.jsonLDforJwgAas = this._sharedService.getSafeHTML(schemaJson.faqJwgEventsSchema);
    this.jsonLDforJwgPlanAVisit = this._sharedService.getSafeHTML(schemaJson.faqJwgEventsSchema);
  }
  getGardenPageContent() {
    this.gardenKeyArray = [
      39432, // Hero Banner
      39507, // JioWorld Garden
      40698, // Infographics
      'JWG-EVENTS-AT-THE-JIOWORLD-GARDEN', // Events at the JioWorld Garden
      40717, // Organisers’ Speak
      'JWG-ABOUT-JIOWORLD-GARDEN', // About JioWorld Garden
      40659, // Get in touch
      40462, // Access it with ease
      40174, // News and Media
      40679, // FAQs
      39706, // Subscribe to our World
      46242,  // Build an Itinerary
      'JWG-HOME-VENUE-SHOWCASE'
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.gardenKeyArray).subscribe(
      (data: any) => {
        this.gardenBannerData = data[0] ? data[0].data : data[0];
        this.gardenJWGData = data[1] ? data[1].data : data[1];
        this.gardenInfographicsData = data[2] ? data[2].data : data[2];
        this.gardenExperiencesData = data[3] ? data[3].data : data[3];
        this.gardenOrganiserData = data[4] ? data[4].data : data[4];
        this.gardenAboutData = data[5] ? data[5].data : data[5];
        this.gardenGetInTouchData = data[6] ? data[6].data : data[6];
        this.gardenAccessData = data[7] ? data[7].data : data[7];
        this.gardenNewsData = data[8] ? data[8].data : data[8];
        this.gardenFaqData = data[9] ? data[9].data : data[9];
        this.gardenSubscribe2Data = data[10] ? data[10].data : data[10];
        this.gardenItineraryData = data[11] ? data[11].data : data[11];
        this.gardenShowcaseData = data[12] ? data[12].data : data[12];
        this.changeGardenAboutFlag();
        this._sharedService.initializeCustomJs();
        this.gardenSubHeaderList = this._sharedService.subHeaderItems(this.gardenSubHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  changeGardenAboutFlag() {
    this.gardenAboutData.cards.forEach(element => {
      if (element.HideCTAText.data == 'false') {
        this.isBtnPresent = true;
        return;
      }
    });
  }

  callJwgHomeCTEvent(btn, sectionName) {
    // this._sharedService.callCTEvent('Section_Secondary_Links', sectionName);
    switch (btn) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionName);
        break;
      case 'sec':
        this._sharedService.callCTEvent('Section_Secondary_Links', sectionName);
        break;
    }
  }

  callJwgCTEvent(eveName, secName, linkClicked) {
    this._sharedService.callCTEvent(eveName, secName, '', linkClicked);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
