import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-safety-card',
  templateUrl: './safety-card.component.html',
  styleUrls: ['./safety-card.component.css']
})
export class SafetyCardComponent implements OnInit {
  @Input() cardData;
  @Input() colorList;
  @Input() mainClass?
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor() { }

  ngOnInit() {
  }

}
