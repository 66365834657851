import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cultural-centre-page',
  templateUrl: './cultural-centre-page.component.html',
  styleUrls: ['./cultural-centre-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CulturalCentrePageComponent implements OnInit {
  culturalCentreColorList = {
    footerIconColor: '#C92C99', footerHoverColor: '#FEF9FC', footerFillColor: '', headerDataHoverColor: "#1E478E", subscribeLineColor: "#C92C99", subscribeTextColor: "#C92C99", benefitsLineColor: "#C5299B"
  };
  subscribeData = { "data": { "HideCTAText": { "data": "false" }, "cards": [{ "BulletPoint": { "data": "Stay updated with the latest news and updates about our launch." } }], "ComponentHeaderTitle": { "data": "Subscribe to Our Mailing List to Know More" }, "HideEmailInputField": { "data": "false" }, "ComponentBgColor": { "data": "#FFFFFF" }, "CTAText": { "data": "SIGN UP" }, "TypeOfCTALink": { "data": "" }, "CTALink": { "data": "#" }, "CTALinkWindowPreference": { "data": "[\"_self\"]" }, "EmailInputField": { "data": "Email address" } } };
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  culturalCentreSubHeaderList;
  tccSubSuccessMessage: string = "";
  tccSubErrorMessage: string = "";
  tccSubApiErrorMessage: string = "";
  showSubMsg: boolean = false;
  successfulSubscription: boolean = false;
  subscriptionFlag: boolean = false;
  tccBannerData;
  performingArtsData;
  visualArtsData;
  ourVisionData;
  dasData;
  diningTccData
  subscription;
  dasId;
  
  constructor(public _sharedService: SharedService) {
    // this.culturalCentreSubHeaderList = (window.innerWidth > 991)?["Performing Arts", "Art House", "Dhirubhai Ambani Square", "Culinary Delights"] :
    // ["Performing Arts", "Art House", "Our Vision", "The Fountain", "Culinary Delights"];
    // this.dasId = (window.innerWidth > 991) ? 'dhirubhai-ambani-square' :  'the-fountain';
    this.culturalCentreSubHeaderList = [];
    this._sharedService.getCommonService().showSubMsg.subscribe(
      (data) => {
        this.showSubMsg = data;
      }
    );
    this._sharedService.getCommonService().successfulSubscription.subscribe(
      (data) => {
        this.successfulSubscription = data;
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
  }

  ngOnInit() {
    this.getTccPageContent();
    this._sharedService.getCommonService().onSubsciptionPageLoad();
    this._sharedService.getCommonService().subscriptionFlag.subscribe(
      (data) => {
        this.subscriptionFlag = data;
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
    this._sharedService.getCommonService().subscriptionDetails.subscribe(
      (data) => {
        this.updateTccCTInfo(data);
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
  }

  getTccPageContent() {
    let keyList = [
      'CULTURAL-CENTER-BANNER', // Hero Banner
      'CULTURAL-CENTER-PERFORMING-ARTS', // Performance Arts
      'CULTURAL-CENTER-VISUAL-ARTS', // Visual Arts
      'CULTURAL-CENTER-OUR-VISION', // our vision
      'CULTURAL-CENTER-DHIRUBHAI-AMBANI-SQUARE', // das
      'CULTURAL-CENTER-DINING-AT-THE-CULTURAL-CENTRE' // dining at tcc
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(keyList).subscribe(
      (data: any) => {
        this.tccBannerData = data[0] ? data[0].data : data[0];
        this.performingArtsData = data[1] ? data[1].data : data[1];
        this.visualArtsData = data[2] ? data[2].data : data[2];
        this.ourVisionData = data[3] ? data[3].data : data[3];
        this.dasData = data[4] ? data[4].data : data[4];
        this.diningTccData = data[5] ? data[5].data : data[5];
        this._sharedService.initializeCustomJs();
        this.culturalCentreSubHeaderList = this._sharedService.subHeaderItems(this.culturalCentreSubHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  updateTccCTInfo(res) {
    if (res.data) {
      let email = res.data.mailId;
      let newProfile = {
        Email: email
      };
      this._sharedService.getCleverTapService().createCTUserLogin(newProfile);
      // console.log(newProfile);
      let tccEventObj = {};
      if (this.subscriptionFlag) {
        let unsubscriptionLink = res.data.unsubscriptionUrl;
        tccEventObj = {
          Act: "Y",
          Unsubscribe_link: unsubscriptionLink
        }
      } else {
        tccEventObj = {
          Act: "N",
          Unsubscribe_link: ""
        }
      }
      this._sharedService.getCleverTapService().recordSubscriptionEvent("TCC_Newsletter subscription", tccEventObj);
      // console.log(tccEventObj);
    }
  }

}
