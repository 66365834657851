export const environment = {
    production: false,
    env:"qa",
    getComponentUrl: "https://jwcuat.ril.com/fetchinforedis/",
    staticUrlForImgNew: "https://jwcuat.ril.com",
    getSubscriptionUrl: "https://jwcuat.ril.com/fetchinfo/",
    getOtpUrl: "https://jwcuat.ril.com/userprofile/",
    instaFeedUrl: "https://www.instagram.com/p/",
    exhibitorLogin: "https://scmpfqa.ril.com/eventexhibitorv2/login?siteCode=EC00000001",
    recaptchaSiteKey: "6LcBTMseAAAAAGU0I2ohJ2ER4U4UJ_yRFQ2N_P_S",
    tccUrl: "http://tccuat.ril.com",
    checkEnv: "TS-QA"
  };
  