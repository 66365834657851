import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logistics-redirect',
  templateUrl: './logistics-redirect.component.html',
  styleUrls: ['./logistics-redirect.component.css']
})
export class LogisticsRedirectComponent implements OnInit {
  subscription;
  constructor(public _sharedService: SharedService, private router: Router) { }

  ngOnInit(): void {
    this._sharedService.callLoader();
    this.subscription = setTimeout(() => {
      window.open(environment.exhibitorLogin, "_self");
    }, 5000);
  }
  logisticsRedirectCTEvent() {
    this._sharedService.callCTEvent('Section_Primary_Links', 'Exhibitor Logistics Redirect');
  }

  ngOnDestroy() {
    window.clearTimeout(this.subscription);
  }

}
