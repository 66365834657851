import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common'

@Pipe({
  name: 'multiListFilter'
})
export class MultiListFilterPipe implements PipeTransform {
  constructor(public datepipe: DatePipe){}
  transform(list: any[], filterText: string): any {
    if(filterText) {
      return list ? list.filter(item => 
        (this.datepipe.transform(item.ReleaseDate.data, 'dd/MM/yy').search(new RegExp(filterText, 'i')) > -1)  || (item.SubHeader.data.search(new RegExp(filterText, 'i')) > -1)
      ) : [];
    }
    return list;
  }
}
