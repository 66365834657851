import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { constants } from 'src/app/shared/shared.constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  @Input() mobileInput?;
  @Input() emailInput?;
  @Input() isMobileScreen;
  @Input() prevUrl;
  isRegEmail: boolean;
  isRegMobile: boolean;
  isFirstRegScreen: boolean = true;
  isMainRegDiv: boolean = true;
  isRegMobilePwd: boolean = false;
  isRegEmailPwd: boolean = false;
  isRegistrationComplete: boolean = false;
  isLoyaltyProgmChecked: boolean = false;
  showLoyaltyComp: boolean = false;
  showThankYouComp: boolean = false;
  registrationForm: FormGroup;
  countryCode = constants.countryCodes;

  // flag: boolean = true;
  @Output() signInClicked = new EventEmitter();
  showRegErr: boolean;
  showRegErrMessage: any;
  confirmRegistrationForm: FormGroup;
  submitted: boolean = false;
  loginTimerData: any = { 'min': '00', 'sec': '00' };
  otpResent: boolean = true;
  createConfirmFormFlag: boolean = true;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService, private fb: FormBuilder, private router: Router) {
    this._sharedService.getCommonService().loginTimerData.subscribe(
      (data) => {
        this.loginTimerData = data;
        if (this.loginTimerData.min === '00' && this.loginTimerData.sec === '00') this.otpResent = false;
      }
    );
  }

  ngOnInit(): void {
    this.isRegEmail = this.isMobileScreen ? false : true;
    this.isRegMobile = this.isMobileScreen ? true : false;
    this._sharedService.callCustomDropdown();
    this.createRegForm();
  }
  createRegForm() {
    this.registrationForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      title: ['', Validators.required],
      emailInput: [this.emailInput ? this.emailInput :'', Validators.pattern(constants.EMAIL_REGEX)],
      mobileInput: [this.mobileInput ? this.mobileInput : '', [Validators.required, Validators.minLength(10)]],
      countryCode: ['+91']
      // loyaltyProgram: [false]
    })
  }
  showRegEmailInput() {
    this.isRegEmail = true;
    this.isRegMobile = false;
    this.showRegErr = false;
    // this.loginTimerData.min = this.loginTimerData.sec = '00';
    this._sharedService.callCustomDropdown();
    let val = this.registrationForm.get('title').value ? this.registrationForm.get('title').value : '';
    this.registrationForm.get('title').setValue(val);
    this.resetRegValidatorForEmail(this.registrationForm);
    this.registrationForm.updateValueAndValidity();//update validation
  }
  showRegMobileInput() {
    this.isRegEmail = false;
    this.isRegMobile = true;
    this.showRegErr = false;
    // this.loginTimerData.min = this.loginTimerData.sec = '00';
    this._sharedService.callCustomDropdown();
    let val = this.registrationForm.get('title').value ? this.registrationForm.get('title').value : '';
    this.registrationForm.get('title').setValue(val);
    this.resetRegValidatorForMob(this.registrationForm);
    this.registrationForm.updateValueAndValidity();//update validation
  }
  onSignInClick() {
    this.signInClicked.emit(true)
  }
  onSussessfulRegister(){
    this.router.navigate([this.prevUrl]);
  }
  createConfirmRegistrationForm() {
    this.confirmRegistrationForm = this.fb.group({
      emailInput: [this.registrationFormControls.emailInput.value ? this.registrationFormControls.emailInput.value : '', Validators.pattern(constants.EMAIL_REGEX)],
      mobileInput: [this.registrationFormControls.mobileInput.value ? this.registrationFormControls.mobileInput.value : '', Validators.minLength(10)],
      countryCode: ['+91'],
      otpInput: ['', [Validators.required, Validators.minLength(6)]],
      optMarketingComm: [false]
    })
  }
  get registrationFormControls() { return this.registrationForm.controls; }
  get confirmRegistrationFormControls() { return this.confirmRegistrationForm.controls; }
  setDropdownValue(formControlname, value) {
    this.registrationForm.get(formControlname).setValue(value);
  }
  requestRegOtp(registrationForm, fName) {
    this.submitted = true;
    this.showRegErr = false;
    this.otpResent = true;
    // console.log("registrationForm: ", registrationForm);
    if (fName === 'regForm') {
      if (registrationForm.invalid) return;
      else {
        this.callRegisterUser(registrationForm);
      }
    } else {
      if (this.isRegMobilePwd && this.isRegMobile) {
        this.resetRegValidatorForMob(this.confirmRegistrationForm);
      } else {
        this.resetRegValidatorForEmail(this.confirmRegistrationForm);
      }
      this.confirmRegistrationForm.get("otpInput").setValidators(Validators.minLength(6));//remove required validation
      this.confirmRegistrationForm.get("otpInput").setErrors(null);//updating error message
      this.confirmRegistrationForm.updateValueAndValidity();//update validation
      if (registrationForm.invalid) return;
      else {
        this.callRegisterUser(registrationForm);
      }
    }
  }
  callRegisterUser(form){
    if (this._sharedService.getCommonService().timerSub) this._sharedService.getCommonService().timerSub.unsubscribe();
    let obj = {
      "countryCode": form.controls.countryCode.value,
      "emilId": (this.isRegEmail) ? form.controls.emailInput.value : '',
      "firstName": this.registrationFormControls.firstName.value,
      "isLoyalty": false,
      "lastName": this.registrationFormControls.lastName.value,
      "loyalty": false,
      "mobileNumber": (this.isRegMobile) ? form.controls.mobileInput.value : '',
      "title": this.registrationFormControls.title.value
    }
    this._sharedService.getCommonService().registerUser(obj).subscribe((data: any) => {
      this.isRegMobilePwd = true;
      this.isRegEmailPwd = true;
      this.isFirstRegScreen = false;
      this._sharedService.getCommonService().startTimer();
      this._sharedService.callCustomDropdown();
      if (this.createConfirmFormFlag)this.createConfirmRegistrationForm();
      this.createConfirmFormFlag =false;
      // this.startTimer()
    },
      (error) => {
        this.otpResent = false;
        this.showRegErr = true;
        this.showRegErrMessage = error.error.message ? error.error.message : `Server Error. Try again after some time..`;
        console.log('Try after some time');
      }
    )
  }
  onRegisterClick(confirmRegForm) {
    // if (this.isLoyaltyProgmChecked) {
    //   this.showLoyaltyComp = true;
    // } else {
    //   this.showThankYouComp = true;
    // }
    this.showRegErr = false;
    this.submitted = true; 
    if (this.isRegMobilePwd && this.isRegMobile) {
      this.resetRegValidatorForMob(confirmRegForm);
    } else {
      this.resetRegValidatorForEmail(confirmRegForm);
    }
    this.confirmRegistrationForm.get("otpInput").setValidators([Validators.required, Validators.minLength(6)]);//remove required validation
    if( this.confirmRegistrationForm.get("otpInput").value.length==0){
      this.confirmRegistrationForm.get("otpInput").setErrors({ required: true });//updating error message
    } else if( this.confirmRegistrationForm.get("otpInput").value.length > 0 && this.confirmRegistrationForm.get("otpInput").value.length < 6){
      this.confirmRegistrationForm.get("otpInput").setErrors({ minlength: true });//updating error message
    } else {
      this.confirmRegistrationForm.get("otpInput").setErrors(null);//updating error message
    }
    this.confirmRegistrationForm.updateValueAndValidity();//update validation
    if (confirmRegForm.invalid) {
      return;
    } else {
      let obj = {
        // "countryCode": this.confirmRegistrationFormControls.countryCode.value,
        "emailId": (this.isRegEmail) ? this.confirmRegistrationFormControls.emailInput.value : '',
        "mobileNumber": (this.isRegMobile) ? this.confirmRegistrationFormControls.mobileInput.value : '',
        "otp": +this.confirmRegistrationFormControls.otpInput.value
      }
      this._sharedService.getCommonService().validateRegistrationOtp(obj).subscribe((data: any) => {
        this.isRegMobilePwd = false;
        this.isRegEmailPwd = false;
        this.isMainRegDiv = false;
        this.showThankYouComp = true;
        let userDetails = {
          firstName: this.registrationFormControls.firstName.value,
          lastName: this.registrationFormControls.lastName.value,
          email: (this.isRegEmail) ? this.confirmRegistrationFormControls.emailInput.value : '',
          mobileNo: (this.isRegMobile) ? this.confirmRegistrationFormControls.mobileInput.value : ''
        }
        this._sharedService.getStorageService().setSessionData('isLoggedIn', true);
        this._sharedService.getStorageService().setSessionData('userDetails', JSON.stringify(userDetails));
        // this.startTimer()
      },
        (error) => {
          this.showRegErr = true;
          this.showRegErrMessage = error.error.message ? error.error.message : `Server Error. Try again after some time..`;
          console.log('Try after some time');
        }
      )
    }
  }
  resendOtp() {
    // call otp api
    this.otpResent = true;
    this.showRegErr = false;
    if (this.confirmRegistrationFormControls.mobileInput.value || this.confirmRegistrationFormControls.emailInput.value) this.requestRegOtp(this.confirmRegistrationForm, 'confirmRegForm');
  }
  onLoyaltyChecked(ev) {
    this.isLoyaltyProgmChecked = ev.target.checked;
  }
  loyaltyEnrolCompleted() {
    this.showLoyaltyComp = false;
    this.showThankYouComp = true;
  }
  resetRegValidatorForMob(formName) {
    formName.get("mobileInput").setValidators([Validators.required, Validators.minLength(10)]);
    formName.get("countryCode").setValue("+91");
    formName.get("emailInput").setValidators(null);//remove required validation
    formName.get("emailInput").setErrors(null);//updating error message
  }
  resetRegValidatorForEmail(formName) {
    formName.get("emailInput").setValidators([Validators.required, Validators.pattern(constants.EMAIL_REGEX)]);
    formName.get("mobileInput").setValidators(null);//remove required validation
    formName.get("mobileInput").setErrors(null);//updating error message
    formName.get("countryCode").setValue("");
  }
  ngOnDestroy() {
    // removeSecnavbarClass();
    if (this._sharedService.getCommonService().timerSub) this._sharedService.getCommonService().timerSub.unsubscribe();
    this.loginTimerData.min = this.loginTimerData.sec = '00';
  }
}
