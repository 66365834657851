<section class="moments" [ngClass]="sectionClass" *ngIf="instagramData && instagramData.hideInstagramWidgetOnSite!==true && incomingInstaData.length!=0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section_heading text-center" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
          <p class="section_heading--text"><span class="icon icon-instagram" [attr.style]="('--data-icon-color:#A67D54;') | safe: 'style'">@{{instagramData?.handleName}}</span></p>
        </div>
      </div>

        <div class="clearfix"></div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6 mb-4 px-2 px-md-3" *ngFor="let item of incomingInstaData; let i=index">
          <a href="{{item?.instagramLink}}" (click)="_sharedService.checkCTALink($event, item?.instagramLink, '_blank', 'External')">
            <img src="{{staticUrlForImgNew + item?.mediaLink}}" class="img-fluid child" alt="{{item?.imageAltText}}" />
          </a>
        </div>
        <div class="clearfix"></div>
        <div class="col-lg-12">
          <div class="moments_slider--follow text-center">
            <a href="{{instagramData?.followUsLink}}" (click)=" _sharedService.checkCTALink($event, instagramData?.followUsLink, '_blank', 'External')" class="cta-sec"><span class="cta-sec-txt">FOLLOW US</span></a>
          </div>
        </div>
    </div>
  </div>
</section>