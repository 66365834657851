<!-- <app-header [colorList]="gardenVenueDetailsColorList" [pageName]="'garden'"></app-header> -->
<app-sub-header [colorList]="gardenVenueDetailsColorList" [subHeaderList]="gardenVenueDetailsHeaderList"
  [pageName]="'garden'"></app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <div class="jwc_360tour screen_height_inside" *ngIf="jwgVenueBannerData">
    <div class="breadcrumbs kma_breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-garden">Jio World Garden</a> /
        <strong>About</strong>
      </div>
    </div>
    <div class="jwc_360tour--banner">
      <picture>
        <source media="(min-width: 768px)"
          srcset="{{ staticUrlForImgNew + jwgVenueBannerData?.UploadImageVideo?.document?.contentUrl }}">
        <source media="(max-width: 767px)" *ngIf="jwgVenueBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl"
          srcset="{{ staticUrlForImgNew + jwgVenueBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl }}">
        <img src="{{ staticUrlForImgNew + jwgVenueBannerData?.UploadImageVideo?.document?.contentUrl }}"
          class="img-fluid kma_picture_wrapper--img" alt="{{jwgVenueBannerData?.ImageAltText?.data}}">
      </picture>
    </div>
    <!-- <div class="jwc_360tour_btn">
      <div class="jwc_360tour_btn--icon">
        <img src="./assets/images/360/360.svg" class="img-fluid" alt="">
      </div>
      <button class="cta-prim color-white emp-btn"><span class="cta-prim-txt">START TOUR</span></button>
    </div> -->
  </div>
  <section class="benefits_wrapper multilineText pb-0" *ngIf="nextGreatEventData" data-mobile-header="About" id="about">

    <div class="container" data-aos="fade-up">
      <div class="row justify-content-between">
        <div class="col-lg-7 col-md-7">
          <div class="section_heading section_heading--title section_heading--text multilineText pb-0" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#66C553') | safe: 'style'">
              <h2 [innerHTML]="nextGreatEventData?.ComponentHeading?.data"></h2>
              <p  [innerHTML]="nextGreatEventData?.ComponentSubContent1?.data"></p>
              <p  [innerHTML]="nextGreatEventData?.ComponentSubContent2?.data"></p>
              <p  [innerHTML]="nextGreatEventData?.ComponentSubContent3?.data"></p>
              <a href="{{nextGreatEventData?.CTALink?.data}}" class="cta-prim color-green"
                (click)="callCTPrimaryLinks(nextGreatEventData?.ComponentHeading?.data); _sharedService.checkCTALink($event, nextGreatEventData?.CTALink?.data, nextGreatEventData?.CTALinkWindowPreference?.data, nextGreatEventData?.TypeOfCTALink?.data)">
                <span class="cta-prim-txt">{{nextGreatEventData?.CTAText?.data}}</span></a>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="venue_details" *ngFor="let item of nextGreatEventData?.cards">
            <div class="venue_details--icon">
              <img src="{{ staticUrlForImgNew + item?.UploadIcon?.document?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
            </div>
            <div class="venue_details--txt">
              <h4>{{item?.VenueDetailText1?.data}}</h4>
              <p class="content2" [innerHTML]="item?.VenueDetailText2?.data"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="jwg_know_your_venue_wrapper pb-0" [id]="ourVenueId" *ngIf="knowYourVenueData" data-mobile-header="Know Your Venue" [attr.style]="('--data-venues-bgColor:#F5F2ED; --data-tab-txt-Color:#4B4847; --data-tab-txt-highlight-Color:#6CC24A;') | safe: 'style'">

    <div class="container" data-aos="fade-up">

      <div class="section_heading section_heading--title text-center" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#6CC24A') | safe: 'style'">
          <h2 [innerHTML]="knowYourVenueData?.ComponentHeading?.data"></h2>
      </div>
      <div class="venue_img_wrapper">
        <picture>
          <source media="(max-width: 767px)"
            srcset="{{ staticUrlForImgNew + knowYourVenueData?.cards[activeTab]?.UploadVenueImage?.document?.contentUrl }}">
          <img
            src="{{ staticUrlForImgNew + knowYourVenueData?.cards[activeTab]?.UploadVenueImage?.document?.contentUrl }}"
            class="img-fluid" alt="{{knowYourVenueData?.cards[activeTab]?.ImageAltText?.data}}">
        </picture>
      </div>

      <div class="container venue_tab_section">
        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item" *ngFor="let item of knowYourVenueData?.cards; let i = index" (click)="activeTab = i">
            <a id="{{'tab-'+(i+1)}}" href="{{'#pane-'+(i+1)}}" class="nav-link" [ngClass]="{'active' : i==0}"
              data-toggle="tab" role="tab"
              [attr.data-img]="staticUrlForImgNew + item?.UploadVenueImage?.document?.contentUrl">{{item?.TabName?.data}}</a>
          </li>
        </ul>
        <div id="content" class="tab-content" role="tablist">
          <div id="{{'pane-'+(i+1)}}" class="card tab-pane fade" role="tabpanel" [attr.aria-labelledby]="'tab-'+(i+1)"
            [ngClass]="{'show active' : i == activeTab}" *ngFor="let item of knowYourVenueData?.cards; let i = index">
            <div class="card-header" role="tab" id="{{'heading-'+(i+1)}}">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="{{'#collapse-'+(i+1)}}" (click)="activeTab = i"
                  [attr.aria-expanded]="i==0?true : false" [attr.aria-controls]="'collapse-'+(i+1)"
                  [ngClass]="{'collapsed': i != 0}"
                  [attr.data-img]="staticUrlForImgNew + item?.UploadVenueImage?.document?.contentUrl">
                  <span>{{item?.TabName?.data}}</span>
                </a>
              </h5>
            </div>
            <div id="{{'collapse-'+(i+1)}}" class="collapse" [ngClass]="{'show' : i==0}" data-parent="#content"
              role="tabpanel" [attr.aria-labelledby]="'heading-'+(i+1)">
              <p class="content3">{{item?.TabHeadingText?.data}}</p>
              <div class="cta-holder">
                <a *ngIf="item?.HideCTA1?.data =='false'"
                  (click)="callCTPDFDownloadEvent(item?.CTALink1?.data, item?.TabName?.data); _sharedService.checkCTALink($event, item?.CTALink1?.data, '_self', 'PDF', pdfName)"
                  class="cta-sec icon rh icon-download-icon color-green "><span
                    class="cta-sec-txt">{{item?.CTAText1?.data}}</span></a>
                <a *ngIf="item?.HideCTA2?.data =='false'"
                  (click)="callCTPDFDownloadEvent(item?.CTALink2?.data, item?.TabName?.data); _sharedService.checkCTALink($event, item?.CTALink2?.data, '_self', 'PDF', pdfName)"
                  class="cta-sec icon rh icon-download-icon color-green "><span
                    class="cta-sec-txt">{{item?.CTAText2?.data}}</span></a>
                <a *ngIf="item?.HideCTA3?.data =='false'"
                  (click)="callCTPDFDownloadEvent(item?.CTALink3?.data, item?.TabName?.data); _sharedService.checkCTALink($event, item?.CTALink3?.data, '_self', 'PDF', pdfName)"
                  class="cta-sec icon rh icon-download-icon color-green "><span
                    class="cta-sec-txt">{{item?.CTAText3?.data}}</span></a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="event_services jwg_event_services" data-aos="fade-up" *ngIf="eventServicesData" data-mobile-header="Facilities" id="facilities"
    [attr.style]="('--data-experiences-titleColor:#4B4847; --data-mobileLineBg: transparent;') | safe: 'style'">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#6CC24A') | safe: 'style'">
          <h2 [innerHTML]="eventServicesData?.ComponentHeading?.data"></h2>
          <p [innerHTML]="eventServicesData?.ComponentSubHeading?.data"></p>
        </div>
      <div class="row services">
        <div class="col-md-3 col-6 services_block" *ngFor="let item of eventServicesData?.cards">
          <div class="service_icon">
            <img src="{{ staticUrlForImgNew + item?.UploadIcon?.document?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
          </div>
          <p class="service_txt">{{item?.ServiceName?.data}}</p>
        </div>
      </div>
      <div class="cta-holder">
        <a *ngIf="eventServicesData?.HideCTA?.data =='false'" href="{{eventServicesData?.CTALink?.data}}"
          (click)="callCTPrimaryLinks(eventServicesData?.ComponentHeading?.data); _sharedService.checkCTALink($event, eventServicesData?.CTALink?.data, eventServicesData?.CTALinkWindowPreference?.data, eventServicesData?.TypeOfCTALink?.data)"
          class="cta-prim color-green "><span class="cta-prim-txt">{{eventServicesData?.CTAText?.data}}</span></a>
      </div>
    </div>
  </section>
  <!-- <section class="planning_tools_wrapper plr-0" style="--data-tab-txt-Color:#4B4847;--data-tab-txt-hover-Color:#6CC24A; 
        --data-swiper-slide-Background:#F5F2ED;--data-swiper-slide-heading-color:#6CC24A">
    <div class="container" data-aos="fade-up">
      <div class="section_heading " style="--data-titleColor:#4B4847;--data-lineColor:#6CC24A">
        <div class="">
          <h2 class="section_heading--title">Planning Tools</h2>
          <p class="section_heading--text"> Whether it’s an award show, art exhibition, wedding or corporate talk show -
            this premium, outdoor venue can be a seamless fit for your event. Learn all about its facilities to plan
            your event better.
          </p>
        </div>
      </div>
      <div class="container planning_tools_lists" data-aos="fade-up">
        <div class="tabs">
          <div class="tab_mob icon-arrow-down">Category 01</div>
          <ul class="nav nav-tabs">
            <li><a href="#tab1" class="active" data-toggle="tab">Category 01</a></li>
            <li><a href="#tab2" data-toggle="tab">Category 02</a></li>
            <li><a href="#tab3" data-toggle="tab">Category 03</a></li>
            <li><a href="#tab4" data-toggle="tab">Category 04</a></li>
          </ul>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade active show" id="tab1">
            <div class="jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="3" data-ipad-slides="2.2"
              data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tab2">
            <div class="jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="3" data-ipad-slides="2.2"
              data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tab3">
            <div class="jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="3" data-ipad-slides="2.2"
              data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tab4">
            <div class="jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="3" data-ipad-slides="2.2"
              data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve1.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve2.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="planning_tools_card">
                      <div class="planning_tools_img"><img src="./assets/images/jwg-venue/planning_tools/eve3.jpg"
                          class="img-fluid" alt=""></div>
                      <div class="planning_tools_txt">
                        <h5>Subheading</h5>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="cta-holder">
          <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/inquiry')" class="cta-sec color-green"><span
              class="cta-sec-txt">Enquire now</span></a>
        </div>
      </div>
    </div>
  </section> -->

  <div [id]="eventsId" data-mobile-header="Events We Host">
    <app-plan-big-event *ngIf="eventsAtTheJwgData" [venueDetailsEventsData]="eventsAtTheJwgData"
      [colorList]="gardenVenueDetailsColorList" [pageName]="'garden'"></app-plan-big-event>
  </div>
  <div id="venue-showcase" data-mobile-header="Venue Showcase">
    <app-venue-details-showcase *ngIf="jwgVenueShowcaseData" [venueDetailsShowcaseData]="jwgVenueShowcaseData"
      [colorList]="gardenVenueDetailsColorList">
    </app-venue-details-showcase>
  </div>

  <!-- <section class="archive filter_view archive2" style="--data-box-textColor:#6CC24A">
    <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#6CC24A">
      <div class="">
        <h2 class="section_heading--title">Archive</h2>
      </div>
    </div>
    <div class="archive_filters filter_view_tab">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="archive_filters_icon">
              <a href="javascript:;" data-target="calendarView" class="archive_filters_icon--calendar active"></a>
              <a href="javascript:;" data-target="gridView" class="archive_filters_icon--grid"></a>
            </div>
            <div class="archive_filters_type">
              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">EVENT</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Popularity</li>
                    <li tabindex="0">Price - Low to High</li>
                    <li tabindex="0">Price - High to Low</li>
                    <li tabindex="0">User Rating - High to Low</li>
                  </ul>
                </div>
              </div>
              <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                <div class="dropdown--select">Sort By</div>
                <div class="dropdown--list" aria-expanded="false" role="list">
                  <ul>
                    <li tabindex="0">Popularity</li>
                    <li tabindex="0">Price - Low to High</li>
                    <li tabindex="0">Price - High to Low</li>
                    <li tabindex="0">User Rating - High to Low</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="archive_filters_search">
              <div class="input-box">
                <input type="text" placeholder="SEARCH BY EVENT" class="input-field">
                <button class="search-button icon icon-search"></button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="archive_tabs  filter_view_tab_details">
      <div class="archive_tabs_calendar views" id="calendarView">
        <div id="calendar"></div>
      </div>

      <div class="archive_tabs_grid views" id="gridView">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-6 archive_tabs_grid_each">
              <a href="javascript:void(0) data-target="#gallery_popup" data-toggle="modal" data-des="Revel Presents: Beyoncé Live Concert">
                <div class="archive_tabs_grid_each--img"><img src="./assets/images/garden/archive/garden-archive-1.jpg"
                    class="img-fluid" alt="">
                  <div class="icon tour"><strong>New!</strong> Virtual Tour</div>
                </div>
                <h4>Revel Presents: Beyoncé Live Concert</h4>
                <p class="content3">24th April - 29th April 2020</p>
              </a>
            </div>
            <div class="col-md-4 col-sm-6 archive_tabs_grid_each">
              <a href="javascript:void(0) data-target="#gallery_popup" data-toggle="modal" data-des="Sunburn Arena">
                <div class="archive_tabs_grid_each--img"><img src="./assets/images/garden/archive/garden-archive-2.jpg"
                    class="img-fluid" alt="">
                </div>
                <h4>Sunburn Arena</h4>
                <p class="content3">24th April - 29th April 2020</p>
              </a>
            </div>

            <div class="col-md-4 col-sm-6 archive_tabs_grid_each">
              <a href="javascript:void(0) data-target="#gallery_popup" data-toggle="modal" data-des="Wiz Khalifa LIVE Concert">
                <div class="archive_tabs_grid_each--img"><img src="./assets/images/garden/archive/garden-archive-5.jpg"
                    class="img-fluid" alt="">
                </div>
                <h4>Wiz Khalifa LIVE Concert</h4>
                <p class="content3">24th April - 29th April 2020</p>
              </a>
            </div>
            <div class="col-md-4 col-sm-6 archive_tabs_grid_each">
              <a href="javascript:void(0) data-target="#gallery_popup" data-toggle="modal" data-des="Indie Rockers Of Mumbai">
                <div class="archive_tabs_grid_each--img"><img src="./assets/images/garden/archive/garden-archive-6.jpg"
                    class="img-fluid" alt="">
                  <div class="icon tour"><strong>New!</strong> Virtual Tour</div>
                </div>
                <h4>Indie Rockers Of Mumbai</h4>
                <p class="content3">24th April - 29th April 2020</p>
              </a>
            </div>
            <div class="col-md-4 col-sm-6 archive_tabs_grid_each">
              <a href="javascript:void(0) data-target="#gallery_popup" data-toggle="modal" data-des="Revel Presents: Beyoncé Live Concert">
                <div class="archive_tabs_grid_each--img"><img src="./assets/images/garden/archive/garden-archive-1.jpg"
                    class="img-fluid" alt="">
                </div>
                <h4>Revel Presents: Beyoncé Live Concert</h4>
                <p class="content3">24th April - 29th April 2020</p>
              </a>
            </div>
            <div class="col-md-4 col-sm-6 archive_tabs_grid_each">
              <a href="javascript:;" data-target="#gallery_popup" data-toggle="modal">
                <div class="archive_tabs_grid_each--img"><img src="./assets/images/garden/archive/garden-archive-2.jpg" class="img-fluid" alt="">
                  <div class="icon tour"><strong>New!</strong> Virtual Tour</div>
                </div>
                <h4>Sunburn Arena</h4>
                <p class="content3">24th April - 29th April 2020</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <div class="modal fade image_gallery_popup" id="gallery_popup" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="wrapper modal-dialog" role="document">
      <div class="modal-content">
        <a href="javascript:;" class="close" data-dismiss="modal"></a>
        <div class="jwc_image_slider gallery_popup_slider popup_slider" data-space="0" data-slides="1">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="jwc_image_slider_each">
                  <img src="./assets/images/cec-walkthrough/slider.jpg" class="img-fluid" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-arrows">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="jwc_image_slider_text content3"></div>
  </div> -->
  <div [id]="planId" data-mobile-header="Contact">
    <app-get-in-touch *ngIf="jwgAskUsData" [getInTouchData]="jwgAskUsData" [colorList]="gardenVenueDetailsColorList"
      [pageName]="'garden'" [sectionClass]="''">
    </app-get-in-touch>
  </div>
  <!-- <section class="more_centre_wrapper plr-0" style="--data-more-centre-bgColor1:#FFFFFF;--data-more-centre-bgColor2::#FFFFFF;--data-more-centre-titleColor:#4B4847;
      --data-more_centre_txt_bgColor:#F5F2ED;">
    <div class="container">
      <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#43B02A">
        <div class="">
          <h2 class="section_heading--title">More at Jio World Centre</h2>
        </div>
      </div>
      <div class="more_centre_slider jwc_swiper_normal" data-interval="5" data-space="24" data-slides="3"
        data-mob="false" data-ipad-slides="2.2" data-ipad-space="20" data-mobile-slides="1.15" data-mobile-space="20">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                  <span class="more_centre_slider_each_copy--category">Food Festival</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="cta-prim color-green icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                  <span class="more_centre_slider_each_copy--category">Food Festival</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="cta-prim color-green icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="more_centre_slider_each">
                <div class="more_centre_slider_each--img">
                  <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                  <span class="more_centre_slider_each_copy--category">Food Festival</span>
                  <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                  <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                  <div class="more_centre_slider_each_copy--btns">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                      class="cta-prim color-green icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </section> -->
</main>
<!-- <app-footer [colorList]="gardenVenueDetailsColorList"></app-footer> -->
<div class="overlay"></div>