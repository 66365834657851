<section class="experiences_slider garden_wrapper full_width pb-0" [attr.style]="'--data-garden-bgColor: ' + colorList?.egDataGardenBgColor + '; --data-copy-bgColor: ' + colorList?.egDataCopyBgColor | safe: 'style'">
  <div class="container"></div>
  <div class="section_heading section_heading--title section_heading--text pb-0" [attr.style]="'--data-titleColor: ' + colorList?.egDataTitleColor + '; --data-lineColor: ' + colorList?.egDataLineColor | safe: 'style'">
      <h2 [innerHTML]="experiencesGardenData?.ComponentHeaderTitle?.data"></h2>
      <p [innerHTML]="experiencesGardenData?.ComponentHeaderSubTitle?.data"></p>
  </div>
  <div class="garden_slider swiper_fraction jwc_swiper_fraction services_slider extra_height" data-interval="7"
    data-space="40" data-slides="4" data-ipad-slides="2.5">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of experiencesGardenData.cards; let i = index">
          <div class="garden_slider_each">
            <div class="garden_slider_each--img">
              <img src="{{ staticUrlForImgNew + item?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
            </div>
            <div class="garden_slider_each_copy">
              <h4 class="garden_slider_each_copy--title">{{item?.CardHLText?.data}}</h4>
              <p class="content3 garden_slider_each_copy--text">{{item?.CardShortText?.data}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
    <!-- Add Arrows -->
    <div class="swiper-arrows">
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</section>