import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-garden-archives',
  templateUrl: './garden-archives.component.html',
  styleUrls: ['./garden-archives.component.css']
})
export class GardenArchivesComponent implements OnInit, OnDestroy {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  gardenArchiveColorList = {
    footerIconColor: '#43B02A', footerHoverColor: '#EDFDE8', footerFillColor: '#FFFFFF', headerDataHoverColor: "#43B02A",
    dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#6CC24A'
  }
  searchModel;
  archiveBannerData;
  archiveSubscription;
  archiveInquireData;
  archiveEventsData;
  galleryTitle;
  galleryImages;
  galleryDesc;
  modalId = 'gallery_popup_0';
  gardenArchiveSubHeaderList;
  gardenArchiveKeyArray = [
    'GARDEN-ARCHIVES-HERO-BANNER',
    'GARDEN-ARCHIVES-INQUIRE-NOW',
    'GARDEN-ARCHIVES-EVENTS'
  ];

  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this.getArchivePageContent();
  }

  getArchivePageContent() {
    this.archiveSubscription = this._sharedService.getCommonService().getlandingUrl(this.gardenArchiveKeyArray).subscribe(
      (data: any) => {
        // console.log("All Data: ",data)
        this.archiveBannerData = data[0] ? data[0].data : data[0];
        this.archiveInquireData = data[1] ? data[1].data : data[1];
        this.archiveEventsData = data[2] ? data[2].data.cards : data[2];
        this.archiveEventsData.sort((val1, val2) => {
          return <any>new Date(val2.EventStartDate.data) - <any>new Date(val1.EventStartDate.data)
        })
        this._sharedService.initializeCustomJs();
        this.gardenArchiveSubHeaderList = this._sharedService.subHeaderItems(["Plan your event"]);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )

  }

  openModal(index) {
    this.modalId = 'gallery_popup_' + index;
    this.galleryImages = this.archiveEventsData[index].AddEventImages;
    // console.log("Gallery Images: ",this.galleryImages)
    this.galleryTitle = this.archiveEventsData[index].EventName.data;
    this.galleryDesc = this.archiveEventsData[index].EventDescription.data;
    this._sharedService.callGalleryPopupSlider();
  }

  ngOnDestroy() {
    this.archiveSubscription.unsubscribe();
  }

}
