<div class="subheader jwc_sticky_navbar">
  <!-- desktop navbar starts -->
  <div class="jwc_navbar jwc_navbar_brand d-none d-lg-block">
    <div class="jwc_navbar_inner">
      <ul class="jwc_navbar_list">
        <li class="nav-item" *ngFor="let item of subHeaderList1">
          <a class="nav-link" routerLink="{{routingURL?.get(item?.title)}}"  *ngIf="routingURL?.get(item?.title); else fragment" 
            [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'"
            (click)="callCTSecondaryNavEvent(item?.title); customScroll(item?.id)">{{item?.title}}</a>
          <ng-template #fragment>
            <a class="nav-link" routerLink="./" fragment="{{item?.id}}" (click)="callCTSecondaryNavEvent(item?.title); customScroll(item?.id)" 
            [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'">{{item?.title}}</a>
          </ng-template>
        </li>
      </ul>
      <div class="jwc_navbar--logo">
        <a routerLink="{{routeUrl.get(pageName)}}"><img src="./assets/images/{{logoName.get(pageName)}}.svg"
            class="img-fluid" alt="{{(imgAltTxt.get(pageName))?imgAltTxt.get(pageName): 'Jio World centre Logo'}}"></a>
      </div>
      <ul class="jwc_navbar_list justify-content-end">
        <ng-container *ngIf="subHeaderList2">
          <li class="nav-item" *ngFor="let item of subHeaderList2">
            <a class="nav-link" routerLink="{{routingURL?.get(item?.title)}}" *ngIf="routingURL?.get(item?.title); else fragment"
              [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'"
              (click)="callCTSecondaryNavEvent(item?.title)">{{item?.title}}</a>
            <ng-template #fragment>
              <a class="nav-link" routerLink="./" fragment="{{item?.id}}" (click)="callCTSecondaryNavEvent(item?.title)"
              [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'">{{item?.title}}</a>
            </ng-template>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <!-- desktop navbar ends -->
  <!-- mobile navbar starts -->
  <div class="jwc_navbar jwc_navbar_brand d-block d-lg-none" *ngIf="pageName!=='tcc'">
    <div class="dropdown jwc_dropdown_menu">
      <a routerLink="{{routeUrl.get(pageName)}}" class="jwc_dropdown_menu--logo">
        <img src="./assets/images/{{logoName.get(pageName)}}.svg" [ngClass]="pageName === 'tcc' ? 'height-30' : 'img-fluid'" alt="{{logoName.get(pageName)}}">
      </a>
      <a aria-expanded="false" aria-haspopup="true" role="button" data-toggle="dropdown" data-display="static"
        class="dropdown-toggle" href="#">
        <span id="selected">{{selectedTitle}}</span></a>
      <ul class="dropdown-menu jwc_dropdown_menu_list">
        <li *ngFor="let item of subHeaderListArr" (click)="getSelectedTitle($event)">
          <a routerLink="{{routingURL?.get(item?.title)}}" *ngIf="routingURL?.get(item?.title); else fragment"
            (click)="callCTSecondaryNavEvent(item?.title)">
            {{item?.title}}
          </a>
          <ng-template #fragment>
            <a routerLink="./" fragment="{{item?.id}}" (click)="callCTSecondaryNavEvent(item?.title); customScroll(item?.id)">
              {{item?.title}}
            </a>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
  <!-- mobile navbar ends -->
</div>