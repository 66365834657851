<section class="upgrade_browser" [attr.style]="('--data-upgrade_browser-bg-color:#F5F2ED') | safe: 'style'">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="section_heading text-center" [attr.style]="('--data-titleColor:#4B4847;') | safe: 'style'">
                  <h1 class="section_heading--title mw-100">Upgrade your browser for a better Jio World Centre
                      experience.</h1>
                  <p class="section_heading--text mw-100">Looks like our website does not support the web browser
                      you're using. We recommend upgrading to the most recent version of your browser or
                      downloading any one of these, to ensure a seamless browsing experience.</p>
              </div>

              <div class="upgrade_browser_list">
                  <ul>
                      <li>
                          <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
                              <img src="./assets/images/upgrade/firefox.png" class="img-fluid" />
                              <p class="content2">Firefox</p>
                          </a>
                      </li>
                      <li>
                          <a href="https://www.google.com/intl/en_in/chrome/" target="_blank">
                              <img src="./assets/images/upgrade/chrome.png" class="img-fluid" />
                              <p class="content2">Chrome</p>
                          </a>
                      </li>
                      <li>
                          <a href="https://www.microsoft.com/en-us/edge" target="_blank">
                              <img src="./assets/images/upgrade/edge.png" class="img-fluid" />
                              <p class="content2">Edge</p>
                          </a>
                      </li>
                      <li>
                          <a href="https://support.apple.com/downloads/safari" target="_blank">
                              <img src="./assets/images/upgrade/safari.png" class="img-fluid" />
                              <p class="content2">Safari</p>
                          </a>
                      </li>
                  </ul>
              </div>


              <hr />

              <p class="text-center">Already using one of these browsers? <a href="/" class="link">continue
                      anyway.</a></p>

          </div>
      </div>
  </div>
</section>