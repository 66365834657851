import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAllowedInput]'
})
export class AllowedInputDirective {
  @Input() alphabetsOnly = false;
  @Input() numbersOnly = false;

  constructor(private elementRef: ElementRef) { }
  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.elementRef.nativeElement.value;
    if (this.numbersOnly) {
      const newValue = initalValue.replace(/[^0-9]*/g, '');
      this.elementRef.nativeElement.value = newValue;
      if (initalValue !== this.elementRef.nativeElement.value) {
        event.stopPropagation();
      }
    } else if (this.alphabetsOnly) {
      const newValue = initalValue.replace(/[^a-zA-Z ]/g, '');
      this.elementRef.nativeElement.value = newValue;
      if (initalValue !== this.elementRef.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }

}
