import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-infographics-section',
  templateUrl: './infographics-section.component.html',
  styleUrls: ['./infographics-section.component.css']
})
export class InfographicsSectionComponent implements OnInit {
  @Input() infographicsData;
  @Input() isIconsBlockClass?: boolean;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor() { }

  ngOnInit() {
  }

}
