import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList, ContentChildren, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  private subscription;
  private subscriptionOpenings;
  @ViewChildren('viewMore') viewMore: QueryList<ElementRef>;
  @ViewChildren('descriptionText') descriptionText: QueryList<ElementRef>;
  @ViewChildren('viewMoreCST') viewMoreCST: QueryList<ElementRef>;
  @ViewChildren('cardShortText') cardShortText: QueryList<ElementRef>;
  openingsData;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  bannerData;
  benefitsData;
  nmaData;
  lifeAtJWCData;
  awardsAndAccoladesData;
  hearFromOurPeopleData;
  diversityAndInclusionData;
  getInTouchData;
  landingKeyArray;
  
  openingsData1 = [{
    applyLink: "abc@gmail.com",
    brand: "jio-world-garden",
    careerOpeningId: "210",
    department: "IT",
    description: "Food&Beverage",
    displayDate: "04-05-2022",
    expiryDate: "20-08-2022",
    jobId: "135",
    mailTo: "abc@gmail.com",
    position: "Food&Beverage",
    status: "Published"
  },
  {
    applyLink: "abc@gmail.com",
    brand: "jio-world-Center",
    careerOpeningId: "211",
    department: "Admin",
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodtempor invidunt ut labore et dolore magna aliquyam era.condimentum nibh, ut fermentum massa justosit amet risus. Etiam porta sem malesuada magna mollis euismod. Donec sedodiodui.",
    displayDate: "03-06-2022",
    expiryDate: "10-10-2022",
    jobId: "135",
    mailTo: "Franz.Dias@ril.com",
    position: "General Manager",
    status: "Published"
  },
  {
    applyLink: "abc@gmail.com",
    brand: "jio-world-center",
    careerOpeningId: "212",
    department: "HR",
    description: "sed diam nonumy eirmodtempor invidunt ut labore et dolore magna aliquyam era.condimentum nibh, ut fermentum massa justosit amet risus. Etiam porta sem malesuada magna mollis euismod",
    displayDate: "03-07-2022",
    expiryDate: "08-25-2022",
    jobId: "135",
    mailTo: "Amol3.Meshram@ril.com",
    position: "Manager",
    status: "Published"
  }];


// diversityAndInclusionData1= {
//     HideComponentOnSite: {
//       data: "false"
//     },
//     cards: [
//       {
//         CardImage: {
//           image: {
//             contentUrl: "/documents/35271/0/careers-diversity-inclusion.jpg/a96deddc-8294-ea88-b816-98f1ac42a255?t=1655805738970",
//             sizeInBytes: 44688,
//             fileExtension: "jpg",
//             description: "",
//             encodingFormat: "image/jpeg",
//             id: 460337,
//             title: "careers-diversity-inclusion.jpg",
//             contentType: "Document"
//           }
//         },
//         CardShortText: {
//           data: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
//         },
//         ImageAltText: {
//           data: ""
//         },
//         CardHLText: {
//           data: "Lorem Ipsum"
//         }
//       },
//       {
//         CardImage: {
//           image: {
//             contentUrl: "/documents/35271/0/careers-diversity-inclusion.jpg/a96deddc-8294-ea88-b816-98f1ac42a255?t=1655805738970",
//             sizeInBytes: 44688,
//             fileExtension: "jpg",
//             description: "",
//             encodingFormat: "image/jpeg",
//             id: 460337,
//             title: "careers-diversity-inclusion.jpg",
//             contentType: "Document"
//           }
//         },
//         CardShortText: {
//           data: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
//         },
//         ImageAltText: {
//           data: ""
//         },
//         CardHLText: {
//           data: "Lorem Ipsum"
//         }
//       },
//       {
//         CardImage: {
//           image: {
//             contentUrl: "/documents/35271/0/careers-diversity-inclusion.jpg/a96deddc-8294-ea88-b816-98f1ac42a255?t=1655805738970",
//             sizeInBytes: 44688,
//             fileExtension: "jpg",
//             description: "",
//             encodingFormat: "image/jpeg",
//             id: 460337,
//             title: "careers-diversity-inclusion.jpg",
//             contentType: "Document"
//           }
//         },
//         CardShortText: {
//           data: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
//         },
//         ImageAltText: {
//           data: ""
//         },
//         CardHLText: {
//           data: "Lorem Ipsum"
//         }
//       },
//       {
//         CardImage: {
//           image: {
//             contentUrl: "/documents/35271/0/careers-diversity-inclusion.jpg/a96deddc-8294-ea88-b816-98f1ac42a255?t=1655805738970",
//             sizeInBytes: 44688,
//             fileExtension: "jpg",
//             description: "",
//             encodingFormat: "image/jpeg",
//             id: 460337,
//             title: "careers-diversity-inclusion.jpg",
//             contentType: "Document"
//           }
//         },
//         CardShortText: {
//           data: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
//         },
//         ImageAltText: {
//           data: ""
//         },
//         CardHLText: {
//           data: "Lorem Ipsum"
//         }
//       }],
//       ComponentHeaderTitle:{data:"Diversity & Inclusion"},
//       ComponentHeaderSubTitle:
//       {data:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun"},
//       ComponentBackgroundColor:{data:""}
//     };



  constructor(public _sharedService: SharedService, private titleService: Title, private router: Router) { }
  


  ngOnInit(): void {
    this.getCareersPageContent();
    this.getOpeningsData();
  }

  getCareersPageContent() {
    this.landingKeyArray = [
      "CAREERS-HERO-BANNER", //  Careers - Hero Banner                 
      "CAREERS-BENEFITS-WRAPPER", //Careers - Benefits Wrapper
      "CAREERS-NMA",// Careers - NMA
      "CAREERS-LIFE-AT-JWC", //Careers - Life At JWC
      "CAREERS-AWARDS-ACCOLADES",//Careers - Awards & Accolades
      "CAREERS-HEAR-FROM-OUR-PEOPLE",//Careers - Hear From Our People
      "CAREERS-DIVERSITY-INCLUSION",//Careers - Diversity & Inclusion
      // "CAREERS-OPENINGS",//Careers - Openings
      "CAREERS-GET-IN-TOUCH-CONTENT",//Careers - Get In Touch Content
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.landingKeyArray).subscribe(
      (data: any) => {
        this.bannerData = data[0] ? data[0].data : data[0];

        this.benefitsData = data[1] ? data[1].data : data[1];

        this.nmaData = data[2] ? data[2].data : data[2];

        this.lifeAtJWCData = data[3] ? data[3].data : data[3];

        this.awardsAndAccoladesData = data[4] ? data[4].data : data[4];

        this.hearFromOurPeopleData = data[5] ? data[5].data : data[5];


        this.diversityAndInclusionData = data[6] ? data[6].data : data[6];

        //this.openingsData= data[7] ? data[7].data : data[7];


        this.getInTouchData = data[7] ? data[7].data : data[7];



        this._sharedService.initializeCustomJs();

      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  getOpeningsData() {
    
    this.subscriptionOpenings = this._sharedService.getCommonService().getAllCareersOpeningData().subscribe((data: any) => {
       this.openingsData = data.filter((item) => this.isOpeingTodisplay(item.displayDate,item.expiryDate));
     })
  }

 
  getDescriptionText(str: string): string {
    return (str.length > 100) ? str.slice(0, 100) + '..' : str;
  }
  getCardShortText(str: string): string {
    return (str.length > 75) ? str.slice(0, 75) + '..' : str;
  }
  
  showHideViewMore(str: string, id: any): void {

    
    this.descriptionText.forEach((desctext: ElementRef) => {
      this.viewMore.forEach((viewMoreButton: ElementRef) => {
        if (desctext.nativeElement.id == id) {
          if (viewMoreButton.nativeElement.id == 'ViewMoreId' + id) {
            if (viewMoreButton.nativeElement.innerText == 'VIEW MORE') {
              desctext.nativeElement.innerText = str;
              viewMoreButton.nativeElement.innerText = 'VIEW LESS';
            }
            else {
              desctext.nativeElement.innerText = str.slice(0, 100) + '..';
              viewMoreButton.nativeElement.innerText = 'VIEW MORE';
            }
          }
        }
      })
    })

  }

  showHideCSTViewMore(str: string, id: any): void {

    
    this.cardShortText.forEach((shorttext: ElementRef) => {
      this.viewMoreCST.forEach((viewMoreCSTButton: ElementRef) => {
        if (shorttext.nativeElement.id == id) {
          if (viewMoreCSTButton.nativeElement.id == 'ViewMoreCSTId' + id) {
            if (viewMoreCSTButton.nativeElement.innerText == 'READ MORE') {
              shorttext.nativeElement.innerText = str;
              viewMoreCSTButton.nativeElement.innerText = 'READ LESS';
            }
            else {
              shorttext.nativeElement.innerText = str.slice(0, 75) + '..';
              viewMoreCSTButton.nativeElement.innerText = 'READ MORE';
            }
          }
        }
      })
    })

  }


  getNumberOfDays(displayDate: string): number {
    let date1: Date = new Date();
    if(displayDate){
    const [day, month, year] = displayDate.split("-").map(x => +x);

    let date2: Date = new Date(year, month - 1, day)
    let timeInMilisec: number = date1.getTime() - date2.getTime();
    let daysBetweenDates: number = Math.floor(timeInMilisec / (1000 * 60 * 60 * 24));
    return daysBetweenDates;
  }else return 0;
}

  isOpeingTodisplay(displayDate: string, expiryDate: string): boolean {
    const [day, month, year] = displayDate.split("-").map(x => +x);
    let date1: Date = new Date(year, month - 1, day);

    let currentDate: Date = new Date();
    if (expiryDate) {
      const [day1, month1, year1] = expiryDate.split("-").map(x => +x);
      let date2: Date = new Date(year1, month1 - 1, day1);
      return (currentDate <= date2)
    }
    else return true;
  }

  checkCTALink($event: any, item: any) {
        let brand
        switch (item?.brand) {
          case 'Fountain of Joy - Dhirubhai Ambani Square':
            brand = 'DAS';
            break;
          case 'Jio World Convention Centre':
            brand = 'CEC';
            break;
          case 'Jio World Garden':
            brand = 'JWG';
            break;
          case 'The Cultural Centre':
            brand = 'TCC';
            break;
          default:
            brand = 'JWC';
        }

        let jobId=item?.jobId
        this.router.navigate(
          ['/enquiries'],
          { queryParams: { type: 'ca',brand:brand,jobId:jobId } }
        );
      }

}
