import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-legal-policy',
  templateUrl: './legal-policy.component.html',
  styleUrls: ['./legal-policy.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LegalPolicyComponent implements OnInit {

  legalPolicyColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor: "#976841"
  }

  tabName: any;
  routeState: any;
  subscription: any;
  legalPageData: any;
  isActive: boolean = false;
  page: any;
  mobileTabname: any;
  tabDisplayName: string;
  currentTabDetails: any;

  constructor(private router: Router, public _sharedService: SharedService, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      this.page = this.route.snapshot.data['page']
      if (event instanceof NavigationEnd) {
        if (this.route.snapshot.queryParams['tab']) {
          this.route.queryParams.subscribe((queryParams: any) => {
            this.tabName = this.route.snapshot.queryParams['tab']
            // if(this.tabName == 'privacy'){
            //   this.mobileTabname = this.tabDisplayName = 'PRIVACY POLICY'
            //   this.route.snapshot.data["CTLinkClicked"] = 'Legal Privacy Policy';
            // } else if(this.tabName == 'terms') {
            //   this.mobileTabname = this.tabDisplayName = 'TERMS & CONDITIONS'
            //   this.route.snapshot.data["CTLinkClicked"] = 'Legal Terms & Conditions';
            // } else {
            //   // this.route.snapshot.data["CTLinkClicked"] = 'Legal';
            //   this.tabName = 'terms';
            //   this.mobileTabname = this.tabDisplayName = 'TERMS & CONDITIONS'
            //   this.route.snapshot.data["CTLinkClicked"] = 'Legal Terms & Conditions';
            // }
          });
        } else {
          // this.tabName = 'terms';
          // this.mobileTabname = this.tabDisplayName = 'TERMS & CONDITIONS'
          // this.route.snapshot.data["CTLinkClicked"] = 'Legal Terms & Conditions';
        }
      }
    })
  }

  ngOnInit() {
    this.getLegalPageContent();
    
  }


  getLegalPageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingData(170901).subscribe(
      (data: any) => {
        this.legalPageData = data ? data.data.cards : data;
        this._sharedService.initializeCustomJs();
        this._sharedService.callsidebarDrop();
        // console.log("this.legalPageData: ", this.legalPageData);
        this.currentTabDetails = this.tabName ? this.legalPageData.find((item)=> item.TabIdentifier.data === this.tabName) : (this.legalPageData[0] || undefined);

        if(this.currentTabDetails){
          this.mobileTabname = this.tabDisplayName = this.currentTabDetails.LegalTabName.data || '';
        }

        //this.changeTabName(this.tabName, this.tabDisplayName)

      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  changeTabName(tabId, tabname) {
    this.mobileTabname = tabname;
    this.router.navigate([], {
      relativeTo: this.route, queryParams: { tab: tabId }, // queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  callLegalCTEvent(tabName) {
    this._sharedService.callCTEvent('Section_Primary_Links','', '', tabName);
  } 

}
