<main role="main">
    <div class="jwc_signin contactus_form">
        <div class="container">
            <div class="row jwc_signin_inner">
                
                <div class="col-lg-3 p-0"></div>
                <div class="col-lg-6 p-0">
                    <div class="jwc_signin_inner_right content3">
                        <div class="jwc_signin_wrap">

                            <div class="jwc_signin_wrap_login in">
                                <h4 class="headtext">Jio World Centre</h4>
                                <div class="tab-content">
                                    <div class="tab-pane fade active show" id="jwc_signin_login">
                                        <form [formGroup]="notificationSignInForm">
                                            <p>UserName</p>
                                            <div class="input-box">
                                                <input formControlName="userName" type="text"
                                                    class="input-field" placeholder="UserName"
                                                    >
                                            </div>
                                            <div class="error-txt"
                                *ngIf="(notificationSignInFormControls.userName.touched || submitted) && notificationSignInFormControls.userName.errors">
                                {{errorText}}
                            </div>
                                            <!-- <div  *ngIf="(notificationSignInForm?.username?.touched || submitted) && notificationSignInForm?.username?.errors">
                                                <div *ngIf="notificationSignInForm?.username?.errors?.required"  class="error-text">Username is required</div>
                                              </div> -->
                                            <p class="mt-2">Password</p>
                                            <div class="input-box">
                                                <input formControlName="password" type="password"
                                                    class="input-field" placeholder="Password"
                                                    >
                                            </div>
                                            <!-- <div *ngIf="(notificationSignInForm?.password?.touched || submitted) && notificationSignInForm?.password?.errors">
                                                <div *ngIf="notificationSignInForm?.password?.errors?.required" class="error-text">Password is required</div>
                                              </div> -->
                                              <div class="error-txt"
                                *ngIf="(notificationSignInFormControls.password.touched || submitted) && notificationSignInFormControls.password.errors">
                                {{errorText}}
                            </div>
                                            <div class="col-md-12 captcha">
                                                <re-captcha (resolved)="captchaRes2($event)" [siteKey]="captchaSiteKey2"
                                                    (error)="captchaRes2($event, true)" errorMode="handled"></re-captcha>
                                                <div class="error-text" style="margin-top:5px" *ngIf="submitted && !isCaptchaSelected">{{errorText}}</div>
                                              </div>
                                              <div class="form-row">
                                                <button [attr.style]="'position:relative; left: 195px;' | safe: 'style'" type="submit" (click)="notificationSubmit(notificationSignInForm)"
                                                    class="cta-prim color-gold">
                                                    <span class="cta-prim-txt">Login</span>
                                            </button>
                                            </div>
                                            <div class="error-txt" *ngIf="showLoginErr">
                                                {{showLoginErr}}</div>
                                            <!-- <div  [attr.style]="'position:relative; left:130px' | safe: 'style'" class="jwc_signin_otp"><a href="javascript:;" id="request_otp" class="cta-prim color-gold use_phone"><span class="cta-prim-txt" type="submit">Login</span></a></div> -->
                                       
                                     </form>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div class="col-lg-3 p-0"></div>
            </div>
        </div>
    </div>
</main>
                                    <!-- <div class="tab-content">
                                        <div class="tab-pane fade active show" id="jwc_signin_login">
                                            <form [formGroup]="signinForm">
                                                <div class="jwc_signin_wrap_mobile">
                                                    <div class="form-group" id="jwc_signin_wrap_use--number"
                                                        *ngIf="isMobile">
                                                        <div class="form-row">
                                                            <div class="col-3 pr-0">
                                                                <div class="dropdown" role="button"
                                                                    aria-labelledby="dropdown-label" tabindex="0">
                                                                    <div class="dropdown--select">+91</div>
                                                                    <div class="dropdown--list" aria-expanded="false"
                                                                        role="list">
                                                                        <ul>
                                                                            <li tabindex="0"
                                                                                (click)="setDropdownValue('countryCode', item)"
                                                                                *ngFor="let item of countryCode">
                                                                                {{item}}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-9 pl-0 input-box">
                                                                <input formControlName="mobileInput" type="text"
                                                                    class="input-field" placeholder="Mobile"
                                                                    maxlength="10" appAllowedInput [numbersOnly]="true">
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="(signinFormControls.mobileInput.touched || submitted)">
                                                            <div *ngIf="signinFormControls.mobileInput.errors?.minlength"
                                                                class="error-txt">Enter 10 digit Mobile Number</div>
                                                            <div *ngIf="signinFormControls.mobileInput.errors?.required"
                                                                class="error-txt">{{errorText}}
                                                            </div>
                                                        </div>
                                                        <div class="error-txt" *ngIf="showLoginErr">
                                                            {{showLoginErrMessage}}</div>
                                                    </div>
                                                    <div class="form-group" id="jwc_signin_wrap_use--email"
                                                        *ngIf="isEmail">
                                                        <input formControlName="emailInput" type="email"
                                                            class="input-field" placeholder="Email">
                                                        <div
                                                            *ngIf="(signinFormControls.emailInput.touched || submitted) && signinFormControls.emailInput.errors">
                                                            <div *ngIf="signinFormControls.emailInput.errors?.required"
                                                                class="error-txt">{{errorText}}
                                                            </div>
                                                            <div *ngIf="signinFormControls.emailInput.errors?.pattern"
                                                                class="error-txt">Email must be a
                                                                valid email address
                                                            </div>
                                                        </div>
                                                        <div class="error-txt" *ngIf="showLoginErr">
                                                            {{showLoginErrMessage}}</div>
                                                    </div>

                                                    <div class="text-center" *ngIf="isMobile; else useMobTemp">
                                                        <a href="javascript:;" (click)="showEmailInput()"
                                                            class="cta-sec color-gold" id="use-email-phone"><span
                                                                class="cta-sec-txt">
                                                                use email</span></a>
                                                    </div>
                                                    <ng-template #useMobTemp>
                                                        <div class="text-center">
                                                            <a href="javascript:;" (click)="showMobileInput()"
                                                                class="cta-sec color-gold" id="use-email-phone"><span
                                                                    class="cta-sec-txt">
                                                                    use mobile</span></a>
                                                        </div>
                                                    </ng-template>

                                                    <div class="jwc_signin_otp">
                                                        <a href="javascript:;"
                                                            (click)="showOtpScreen(signinForm, 'signinForm')"
                                                            class="cta-prim color-gold use_phone" id="request_otp"><span
                                                                class="cta-prim-txt">REQUEST
                                                                OTP</span></a>
                                                        <a href="javascript:;" class="cta-sec color-gold use_phone"
                                                            id="use_password"><span class="cta-sec-txt">Use
                                                                Password</span></a>
                                                    </div>
                                                    <div class="jwc_signin_register">
                                                        <a class="cta-sec color-gold " id="register_now"
                                                            (click)="showRegisterScreen()"><span
                                                                class="cta-sec-txt">Don’t
                                                                have
                                                                an
                                                                account? Register Now</span></a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="tab-pane fade" id="jwc_signin_reg">
                                            <div class="jwc_signin_wrap_social">
                                                <div class="jwc_signin_wrap_social_links">
                                                    <a href="javascript:void(0)"
                                                        class="cta-prim color-gold btn-block"><span
                                                            class="cta-prim-txt">Google</span></a>
                                                    <a href="javascript:void(0)"
                                                        class="cta-prim color-gold btn-block"><span
                                                            class="cta-prim-txt">Facebook</span></a>
                                                </div>

                                                <div class="text-center">
                                                    <a href="javascript:void(0)" class="cta-sec color-gold jwc_reg_btn"
                                                        id="register_now" (click)="showRegisterScreen()"><span
                                                            class="cta-sec-txt">Don’t
                                                            have
                                                            an
                                                            account? Register Now</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form [formGroup]="confirmSigninForm" *ngIf="isMobileOtp || isEmailOtp">
                                <div class="jwc_signin_mobile_otp_wrap" *ngIf="isMobileOtp && isMobile">
                                    <h4 class="headtext">Sign In - Phone number</h4>
                                    <p class="subtext">If you are a registered user, please enter your mobile number.
                                    </p>
                                    <div class="form-group">
                                        <div class="form-row">
                                            <div class="col-3 pr-0">
                                                <div class="dropdown" role="button" aria-labelledby="dropdown-label"
                                                    tabindex="0">
                                                    <div class="dropdown--select">+91</div>
                                                    <div class="dropdown--list" aria-expanded="false" role="list">
                                                        <ul>
                                                            <li tabindex="0"
                                                                (click)="setDropdownValue('countryCode', item)"
                                                                *ngFor="let item of countryCode">
                                                                {{item}}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-9 pl-0 input-box">
                                                <div class="form-group mb-0">
                                                    <div class="jwc_field_edit" (click)="onInputEdit()"><img
                                                            src="./assets/images/edit.svg" alt="">
                                                    </div>
                                                    <input formControlName="mobileInput" type="tel" class="input-field" placeholder="Mobile"
                                                        maxlength="10" appAllowedInput [numbersOnly]="true">
                                                         [ngStyle]="isEditable ? {'pointer-events':'auto'} : {'pointer-events':'none'}"
                                                    <div
                                                        *ngIf="(confirmSigninFormControls.mobileInput.touched || submitted)">
                                                        <div *ngIf="confirmSigninFormControls.mobileInput.errors?.minlength"
                                                            class="error-txt">Enter 10 digit Mobile Number</div>
                                                        <div *ngIf="confirmSigninFormControls.mobileInput.errors?.required"
                                                            class="error-txt">{{errorText}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container [ngTemplateOutlet]="confirmSigninOtpTemplate"></ng-container>
                                    <div class="jwc_signin_agree">
                                        By signing in you agree to our <a href="{{'/legal?tab=terms'}}"
                                            (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=terms', '_blank', 'External')">terms and
                                            conditions</a>
                                    </div>
                                    <div class="jwc_signin_btns">
                                        <a href="javascript:void(0)" (click)="onSignIn()"
                                            class="cta-prim color-gold emp-btn"><span class="cta-prim-txt">SIGN
                                                IN</span></a>
                                        <a href="javascript:;" class="cta-sec color-gold jwc_reg_btn" id="register_now"
                                            (click)="showRegisterScreen()"><span class="cta-sec-txt">Register
                                                Now</span></a>
                                        <div class="error-txt" *ngIf="showLoginErr">
                                            {{showLoginErrMessage}}</div>
                                    </div>
                                </div>

                                <div class="jwc_signin_email_otp_wrap" *ngIf="isEmailOtp && isEmail">
                                    <h4 class="headtext">Sign In - E-mail</h4>
                                    <p class="subtext">If you are a registered user, please enter your email ID.</p>

                                    <div class="form-group">
                                        <div class="jwc_field_edit" (click)="onInputEdit()"><img
                                                src="./assets/images/edit.svg" alt=""></div>
                                        <input formControlName="emailInput" type="email" class="input-field" placeholder="Email">
                                             [ngStyle]="isEditable ? {'pointer-events':'auto'} : {'pointer-events':'none'}" 
                                        <div
                                            *ngIf="(confirmSigninFormControls.emailInput.touched || submitted) && confirmSigninFormControls.emailInput.errors">
                                            <div *ngIf="confirmSigninFormControls.emailInput.errors?.required"
                                                class="error-txt">{{errorText}}
                                            </div>
                                            <div *ngIf="confirmSigninFormControls.emailInput.errors?.pattern"
                                                class="error-txt">Email must be a
                                                valid email address
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container [ngTemplateOutlet]="confirmSigninOtpTemplate"></ng-container>
                                    <div class="jwc_signin_agree">
                                        By signing in you agree to our <a href="{{'/legal?tab=terms'}}"
                                            (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=terms', '_blank', 'External')">terms and
                                            conditions</a>
                                    </div>
                                    <div class="jwc_signin_btns">
                                        <a href="javascript:void(0)" (click)="onSignIn()"
                                            class="cta-prim color-gold emp-btn"><span class="cta-prim-txt">SIGN
                                                IN</span></a>
                                        <a href="javascript:void(0)" (click)="showRegisterScreen()"
                                            class="cta-sec color-gold jwc_reg_btn" id="register_now"><span
                                                class="cta-sec-txt">Register
                                                Now</span></a>
                                        <div class="error-txt" *ngIf="showLoginErr">
                                            {{showLoginErrMessage}}</div>
                                    </div>
                                </div>
                            </form>
                            <div class="jwc_signin_mobile_pwd_wrap" *ngIf="isMobilePassword">
                                <h4 class="headtext">Sign In - Registered mobile number with password</h4>
                                <p class="subtext">If you are a registered user, please enter your mobile number and
                                    password.</p>
                                <div class="form-group">
                                    <div class="form-row">
                                        <div class="col-3 pr-0">
                                            <div class="dropdown" role="button" aria-labelledby="dropdown-label"
                                                tabindex="0">
                                                <div class="dropdown--select">+91</div>
                                                <div class="dropdown--list" aria-expanded="false" role="list">
                                                    <ul>
                                                        <li tabindex="0" (click)="setDropdownValue('countryCode', item)"
                                                            *ngFor="let item of countryCode">
                                                            {{item}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-9 pl-0 input-box">
                                            <div class="form-group mb-0">
                                                <input type="tel" class="input-field" placeholder="Mobile">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="input-field" placeholder="Password">
                                </div>
                                <div class="jwc_signin_btns jwc_signin_btns_btwn">
                                    <a href="javascript:;" class="cta-sec color-gold use_phone" id="request_otp"><span
                                            class="cta-sec-txt">Use
                                            OTP</span></a>
                                    <a href="javascript:;" class="cta-sec color-gold" id="forgot_password"><span
                                            class="cta-sec-txt">Forgot
                                            Password</span></a>
                                </div>
                                <div class="jwc_gap_50"></div>
                                <div class="jwc_signin_btns">
                                    <a href="javascript:;" class="cta-prim color-gold emp-btn"><span
                                            class="cta-prim-txt">SIGN
                                            IN</span></a>
                                    <a href="javascript:;" class="cta-sec color-gold" id="register_now"
                                        (click)="showRegisterScreen()"><span class="cta-sec-txt">Register
                                            Now</span></a>
                                </div>
                            </div>

                            <div class="jwc_signin_email_pwd_wrap" *ngIf="isEmailPassword">
                                <h4 class="headtext">Sign In - Registered User with password</h4>
                                <p class="subtext">If you are a registered user, please enter your email ID and
                                    password.</p>
                                <div class="form-group">
                                    <input type="email" class="input-field" placeholder="Email">
                                </div>
                                <div class="form-group">
                                    <input type="password" class="input-field" placeholder="Password">
                                </div>
                                <div class="jwc_signin_btns jwc_signin_btns_btwn">
                                    <a href="javascript:;" class="cta-sec color-gold use_email" id="request_otp"><span
                                            class="cta-sec-txt">Use
                                            OTP</span></a>
                                    <a href="javascript:;" class="cta-sec color-gold" id="forgot_password"><span
                                            class="cta-sec-txt">Forgot
                                            Password</span></a>
                                </div>
                                <div class="jwc_gap_50"></div>
                                <div class="jwc_signin_btns">
                                    <a href="javascript:;" class="cta-prim color-gold emp-btn"><span
                                            class="cta-prim-txt">SIGN
                                            IN</span></a>
                                    <a href="javascript:;" class="cta-sec color-gold" id="register_now"
                                        (click)="showRegisterScreen()"><span class="cta-sec-txt">Register
                                            Now</span></a>
                                </div>
                            </div>

                            <div class="jwc_signin_wrap_forgot" *ngIf="isMobilePassword">
                                <h4>Forgot Password</h4>
                                <p>Please enter your registered email ID or phone number to reset your password.</p>
                                <form action="">
                                    <div class="form-group" id="">
                                        <input type="email" class="input-field" placeholder="Email">
                                    </div>
                                </form>
                                <div class=" fluid" id="">
                                    <a href="javascript:;" class="cta-prim color-gold emp-btn" id="reset_password"><span
                                            class="cta-prim-txt">Send a
                                            link</span></a>
                                </div>
                                <div class="jwc_gap_10"></div>
                                <p>A link has been sent to your registered email ID or phone number with instructions to
                                    reset your password. </p>

                            </div>

                            <div class="jwc_signin_wrap_reset" *ngIf="isMobilePassword">
                                <h4>Reset Password</h4>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.</p>
                                <form action="">
                                    <div class="form-group" id="">
                                        <input type="email" class="input-field" placeholder="New password">
                                    </div>
                                    <div class="form-group" id="">
                                        <input type="email" class="input-field" placeholder="Confirm new password">
                                    </div>
                                    <div class="fluid" id="">
                                        <a href="javascript:;" class="cta-prim color-gold emp-btn"
                                            id="reset_password_done"><span class="cta-prim-txt">Reset
                                                Password</span></a>
                                    </div>
                                </form>
                            </div>

                            <div class="jwc_signin_wrap_reset_thankyou" *ngIf="isMobilePassword">
                                <h4>Reset Password done</h4>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.</p>
                                <div class="fluid" id="">
                                    <a href="javascript:;" class="cta-prim color-gold emp-btn" id="continue_sign"><span
                                            class="cta-prim-txt">CONTINUE TO SIGN
                                            IN</span></a>
                                </div>
                            </div>

                            <app-sign-up *ngIf="isRegisterScreen" [isMobileScreen]="isMobile" [mobileInput]="signinFormControls.mobileInput.value" [emailInput]="signinFormControls.emailInput.value" [prevUrl]="prevUrl" (signInClicked)="signInClicked($event)"></app-sign-up>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</main>
<ng-template #confirmSigninOtpTemplate>
    <div [formGroup]="confirmSigninForm">
        <div class="form-group">
            <input formControlName="otpInput" type="text" class="input-field" placeholder="OTP" maxlength="6"
                appAllowedInput [numbersOnly]="true">
            <div
                *ngIf="(confirmSigninFormControls.otpInput.touched || submitted) && confirmSigninFormControls.otpInput.errors">
                <div *ngIf="confirmSigninFormControls.otpInput.errors?.required || confirmSigninFormControls.otpInput.errors?.incorrect"
                    class="error-txt">{{errorText}}
                </div>
                <div *ngIf="confirmSigninFormControls.otpInput.errors?.minlength" class="error-txt">OTP must be a 6
                    digit number
                </div>
            </div>
            <div class="jwc_resend_otp clickable_otp" (click)="resendOtp()" *ngIf="!otpResent">RESEND OTP</div>
            <div *ngIf="otpResent" class="jwc_resend_otp">RESEND OTP <span>in
                    <span>{{this.loginTimerData?.min ? this.loginTimerData?.min :
                        '00'}}:{{this.loginTimerData?.sec ? this.loginTimerData?.sec :
                        '00'}}</span>s</span></div>
        </div>
    </div>
</ng-template> -->
