import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../../shared/services/shared.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-garden-facilities',
  templateUrl: './garden-facilities.component.html',
  styleUrls: ['./garden-facilities.component.css']
})
export class GardenFacilitiesComponent implements OnInit, OnDestroy {

  gardenFacilitiesColorList = {
    footerIconColor: '#43B02A', footerHoverColor: '#EDFDE8', footerFillColor: '#FFFFFF', headerDataHoverColor:"#43B02A", primaryColor: "#43B02A",
    dataPlanBgColor: '#F5F2ED', dataNewsletter2LineColor: '#6CC24A', dataPlanLineColor: '#6CC24A', dataGetintouchTitleColor: '#4B4847', 
    dataGetintouchLineColor: '#6CC24A', subscribe2TitleColor: "#4B4847", subscribe2LineColor: "#43B02A", subscribe2DotColor: "#43B02A", subscribe2BorderColor: "#6CC24A"
  };
  gardenFacilitiesHeaderList;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  facilitiesSubscription;
  facilitiesBannerData;
  gardenFacilitiesSAUData;
  facilitiesInfographicsData;
  facilitiesSecurityData;
  facilitiesExploreSpaceData;
  parkingManagementData;
  facilitiesFaqsData;
  facilitiesSubscribeData;
  facilitiesPublicSafetyData;
  facilitiesOurSpaceData;
  activeTab = 0;
  securityId;
  parkingId;
  pdfName;
  infoLoop = ['FirstImage', 'SecondImage', 'ThirdImage'];
  gardenFacilitiesKeyArray = [
    'JWG-FACILITIES',
    'JWG-FACILITIES-SERVICES-AND-UTILITIES',
    'JWG-FACILITIES-INFOGRAPHICS',
    'JWG-FACILITIES-PUBLIC-SAFETY',
    'JWG-FACILITIES-SECURITY',
    'JWG-FACILITIES-EXPLORE-THE-SPACE',
    'JWG-FACILITIES-PARKING-MANAGEMENT',
    'JWG-FACILITIES-FAQs',
    'JWG-FACILITIES-SUBSCRIBE',
    'JWG-FACILITIES-OUR-SPACE'
  ];

constructor(public _sharedService: SharedService, private titleService: Title) {
  this.gardenFacilitiesHeaderList = (window.innerWidth > 991)?["Facilities", "Our Spaces", "Our Services", "Public Safety"] :
  ["Facilities", "Our Spaces", "Our Services", "Public Safety", "Security", "Explore", "Parking", "FAQs"];
  this.securityId = (window.innerWidth > 991) ? 'garden-facility-security' :  'security' ;
  this.parkingId = (window.innerWidth > 991) ? 'parking-management' :  'parking' ;
 }

  ngOnInit() {
    this.getFacilitiesPageContent();
  }

  getFacilitiesPageContent() {
    this.facilitiesSubscription = this._sharedService.getCommonService().getlandingUrl(this.gardenFacilitiesKeyArray).subscribe(
      (data: any) => {
        // console.log("All Data: ",data)
        this.facilitiesBannerData = data[0] ? data[0].data : data[0];
        this.gardenFacilitiesSAUData = data[1] ? data[1].data : data[1];
        this.facilitiesInfographicsData = data[2] ? data[2].data : data[2];
        this.facilitiesPublicSafetyData = data[3] ? data[3].data : data[3];
        this.facilitiesSecurityData = data[4] ? data[4].data : data[4];
        this.facilitiesExploreSpaceData = data[5] ? data[5].data : data[5];
        this.parkingManagementData = data[6] ? data[6].data : data[6];
        this.facilitiesFaqsData = data[7] ? data[7].data : data[7];
        this.facilitiesSubscribeData = data[8] ? data[8].data : data[8];
        this.facilitiesOurSpaceData = data[9] ? data[9].data : data[9];
        this.gardenFacilitiesHeaderList = this._sharedService.subHeaderItems(this.gardenFacilitiesHeaderList);
        this._sharedService.initializeCustomJs();
        this._sharedService.callFractionSlider();        
        setTimeout(() => {
          this._sharedService.callAccordianWithSlider();
        }, 800);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
      
  }

  callPdfName(string){
    this.pdfName = this._sharedService.getPdfName(string);
    return this.pdfName;
  }

  callCTDownloadFSEvent() {
    this.pdfName = this._sharedService.getPdfName(this.parkingManagementData.CTALink.data);
    this._sharedService.callCTEvent('Section_Primary_Links', this.parkingManagementData.ComponentHeaderTitle.data, this.pdfName);
  }

  ngOnDestroy() {
    this.facilitiesSubscription.unsubscribe();
  }

}
