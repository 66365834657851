import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { SharedService } from "../services/shared.service";
import { CONST } from "../shared.constant";

@Injectable()
export class SharedInterceptor implements HttpInterceptor {
  constructor(private _sharedService: SharedService) {}

  applyCredentials(req) {
    return req.clone({
      headers: req.headers.set(
        "Authorization",
        "Bearer " + this._sharedService.getHttpService().getWso2Token()
      )
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (request.url.match(CONST["apiEndPoint"]["getrefreshtoken"])) {
          this._sharedService.logout();
          throw err;
        }
        if (err.status === 401) {
          return this._sharedService
            .getHttpService()
            .getRefreshToken()
            .pipe(
              switchMap((token: any) => {
                return next.handle(this.applyCredentials(request));
              })
            );
        }
        throw err
      })
    );
  }
}
