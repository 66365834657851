<footer>
  <div id="backToTopMo">
    <img src="./assets/images/backToTopMo.svg" class="img-fluid" alt="" /> 
  </div>
  <div *ngIf="!islandingPage" id="backToHomeMo">
    <a href="/"><img src="./assets/images/backToHomeMo.svg" class="img-fluid" alt="" /> </a> 
  </div>
  <div class="jwc_footer_top">
    <div class="jwc_footer_top_inner text-center">
      <ul class="jwc_footer_top_inner_links">
        <!-- <li><a href="{{'/'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/')">Jio World Centre​</a></li> -->
        <li><a href="{{'/jio-world-convention-centre'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/jio-world-convention-centre')">Jio World Convention Centre</a>
        </li>
        <li><a href="{{'/dhirubhai-ambani-square'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/dhirubhai-ambani-square')">Dhirubhai Ambani Square</a></li>
        <li><a href="{{tccUrl}}" target="_blank" aria-label="NMACC (opens a new window)" (click)="callCTFooterEvent();">Nita Mukesh Ambani Cultural Centre</a></li>
        <li><a href="{{'/jio-world-garden'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/jio-world-garden')">Jio World Garden</a></li>
        <li><a href="{{'/about'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/about')">About us​​</a></li>
        <li><a href="{{'/frequently-asked-questions'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/frequently-asked-questions')">FAQs</a></li>
        <li><a href="{{'/get-directions'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/get-directions')">Get Directions</a></li>
        <!-- <li><a href="{{exhibitorLogin}}"
          (click)="_sharedService.checkCTALink($event, exhibitorLogin, '_blank', 'External')">Logistics Login</a></li> -->
          <li *ngIf="showExhibitorLogin"><a href="{{'/newsroom'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/newsroom')">Media Room</a></li>  
          <li *ngIf="showExhibitorLogin"><a href="{{'/logistics-services'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/logistics-services')">Logistics Services</a></li>
          <!-- <li><a href="{{'/health-and-covid-safety-measures'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/health-and-covid-safety-measures')">Safety​</a></li> -->
          <!-- <li><a (click)="callCTFooterEvent();" [routerLink]="'/press-releases/'+articleData?.brandName+'/'+articleData?.slugUrl" [queryParams]="{ newsId:encryptedId }">Press & Media</a></li> -->
          <!-- <li><a href="javascript:void(0)">Careers</a></li> -->
          <li><a href="javascript:void(0)" routerLink="/careers" (click)="callCTFooterEvent();_sharedService.checkCTALink($event, '/careers')">Careers</a></li>
        <li><a href="{{'/contact-us'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/contact-us')">Contact us​​</a></li>
      </ul>
    </div>
  </div>
  <!-- <div class="jwc_footer_links"> -->
  <!-- <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 col-lg-4">
          <div id="accordion" role="tablist" aria-multiselectable="true" class="widget hidden-sm-up">
            <div role="tab" id="headingOne">
              <h6 class="card-header"> <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                  aria-expanded="false" aria-controls="collapseOne"> Jio World CENTRE </a> </h6>
            </div>
            <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne">
              <div class="card-block">
                <ul class="widget-list col-lg-7">
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">About Jio World Centre</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">The Cultural Centre</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Dhirubhai Ambani Square</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/convention-center')">Jio World Convention Centre</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/jioworld-garden')">Jio World Garden</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/press-and-media')">Press & media</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Jio World Plaza</a></li>
                </ul>
                <ul class="widget-list col-lg-5">
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Jio World Drive</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Jio World Offices</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Jio World Residences</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/press-and-media')">Press & media</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Careers</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/downloadable-tech-specs')">Downloadable Tech Specs</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/jioworld-garden-facilities')">Garden Facilities</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/legal')">Legal Policy</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/cookie-policy')">Cookie Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div id="accordion" role="tablist" aria-multiselectable="true" class="widget hidden-sm-up">
            <div role="tab" id="headingTwo">
              <h6 class="card-header"> <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo"> EXPERIENCE </a> </h6>
            </div>
            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo">
              <div class="card-block">
                <ul class="widget-list col-lg-6">
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">What’s on</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Calendar</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Visit Jio World Centre</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Plan a visit</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Parking</a></li>
                </ul>
                <ul class="widget-list col-lg-6">
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"> Jio World Centre map</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Amenities & services</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Around Jio World Centre</a></li>
                  <li><a href="javascript:void(0)" routerLink="/convention-center" fragment="discoverMumbai">Discover Mumbai</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-2">
          <div id="accordion" role="tablist" aria-multiselectable="true" class="widget hidden-sm-up">
            <div role="tab" id="headingThree">
              <h6 class="card-header"> <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree"> CONTACT & SUPPORT </a> </h6>
            </div>
            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree">
              <div class="card-block">
                <ul class="widget-list">
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/contact-us')">Contact us</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Support</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/frequently-asked-questions')">FAQs</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Sitemap</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Getting here</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Lost & found</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event, '/covid-19')">Safety</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-2">
          <div id="accordion" role="tablist" aria-multiselectable="true" class="widget hidden-sm-up">
            <div role="tab" id="headingFour">
              <h6 class="card-header"> <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                  aria-expanded="false" aria-controls="collapseFour"> MEMBERS </a> </h6>
            </div>
            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour">
              <div class="card-block">
                <ul class="widget-list">
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Dashboard</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Redemption</a></li>
                  <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)">Membership</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  <!-- </div> -->
  <div class="jwc_footer_bar">
    <div class="jwc_footer_bar_inner">
      <div class="col-md-3 col-xl-4">
      <p class="desktop_copy_text">© Copyright Jio World Centre {{currentYear}}</p>
      </div>
      <div class="col-md-4 col-xl-5">
      <ul class="links">
        <li><a href="{{'/'}}" (click)="callCTFooterEvent(); _sharedService.checkCTALink($event, '/')">Jio World Centre​</a></li>
        <li><a href="{{'https://www.ril.com/'}}"
            (click)="_sharedService.checkCTALink($event, 'https://www.ril.com/', '_blank', 'External')">Visit
            Reliance</a></li>
        <li><a href="{{'/legal?tab=privacy'}}" (click)="_sharedService.checkCTALink($event, '/legal?tab=privacy')">Privacy</a></li>
        <li><a href="{{'/legal?tab=terms'}}" (click)="_sharedService.checkCTALink($event, '/legal?tab=terms')">Terms of Use</a></li>
        <!-- <li><a href="{{exhibitorLogin}}"
            (click)="_sharedService.checkCTALink($event, exhibitorLogin, '_blank', 'External')">Exhibitors Login</a>
        </li> -->
        <!-- <li><a href="{{'/campaign-chatbot'}}">Campaign Chatbot</a></li> -->
      </ul>
      </div>
      <div class="col-md-5 col-xl-3 social justify-content-end align-items-center">
        <!-- <p>Follow Us</p> -->
        <ul class="social--UL">
          <li><a href="{{'https://www.instagram.com/jioworldcentre/'}}" aria-label="Social icons" (click)="callCTFooterEvent('https://www.instagram.com/jioworldcentre/'); _sharedService.checkCTALink($event, 'https://www.instagram.com/jioworldcentre/', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.081 21.116">
                <g id="bxl-instagram-alt" transform="translate(-2.997 -2.981)">
                  <path id="Path_11873" data-name="Path 11873" d="M24.01,9.214a7.644,7.644,0,0,0-.491-2.594,5.4,5.4,0,0,0-3.082-3.082,7.733,7.733,0,0,0-2.559-.492C16.752,3,16.4,2.981,13.536,2.981s-3.225,0-4.343.064a7.733,7.733,0,0,0-2.558.492A5.393,5.393,0,0,0,3.553,6.619a7.672,7.672,0,0,0-.491,2.558C3.012,10.3,3,10.662,3,13.52s0,3.224.066,4.343a7.721,7.721,0,0,0,.491,2.56A5.4,5.4,0,0,0,6.637,23.5a7.708,7.708,0,0,0,2.558.527c1.127.05,1.484.066,4.343.066s3.225,0,4.343-.066a7.715,7.715,0,0,0,2.559-.491,5.4,5.4,0,0,0,3.082-3.082,7.71,7.71,0,0,0,.491-2.56c.05-1.126.066-1.483.066-4.343S24.076,10.334,24.01,9.214ZM13.529,18.926a5.412,5.412,0,1,1,5.412-5.412A5.411,5.411,0,0,1,13.529,18.926Zm5.627-9.762A1.262,1.262,0,1,1,20.417,7.9,1.261,1.261,0,0,1,19.157,9.164Z" fill="#4b4847"></path>
                  <circle id="Ellipse_4224" data-name="Ellipse 4224" cx="3.515" cy="3.515" r="3.515" transform="translate(10.014 9.999)" fill="#4b4847"></circle>
                </g>
              </svg>
            </a>
          </li>
          <li><a href="{{'https://www.facebook.com/JioWorldCentreMumbai'}}" aria-label="Social icons" (click)="callCTFooterEvent('https://www.facebook.com/JioWorldCentreMumbai'); _sharedService.checkCTALink($event, 'https://www.facebook.com/JioWorldCentreMumbai', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.963 20.993">
                <g id="Facebook" transform="translate(-5.254)">
                  <path id="Path_1" data-name="Path 1" d="M87.114,20.993V11.429H90.38L90.846,7.7H87.114V5.365c0-1.05.35-1.866,1.866-1.866h1.983V.117C90.5.117,89.33,0,88.047,0a4.5,4.5,0,0,0-4.782,4.9V7.7H80v3.732h3.266v9.563Z" transform="translate(-74.746 0)" fill="#4b4847" fill-rule="evenodd"></path>
                </g>
              </svg>
            </a></li>         
            <li><a href="{{'https://www.linkedin.com/company/jioworldcentre/'}}" aria-label="Social icons" (click)="callCTFooterEvent('https://www.linkedin.com/company/jioworldcentre/'); _sharedService.checkCTALink($event, 'https://www.linkedin.com/company/jioworldcentre/', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.186 21.186">
                  <path id="Linkedin" d="M16.8,21.186V14.307c0-1.644-.025-3.755-2.279-3.755-2.287,0-2.629,1.792-2.629,3.632v7H7.506V7.034h4.2V8.973h.057a4.612,4.612,0,0,1,4.157-2.282c4.443,0,5.268,2.928,5.268,6.74v7.754Zm-12.064,0H.343V7.034H4.737V21.185h0ZM7.5,7.034h.008ZM0,2.552A2.548,2.548,0,1,1,2.548,5.1,2.553,2.553,0,0,1,0,2.552Z" fill="#4b4847"></path>
                </svg>
              </a>
            </li>
          <!-- <li><a href="{{'https://www.linkedin.com/company/jioworldcentre/'}}" a(click)="callCTFooterEvent('https://www.linkedin.com/company/jioworldcentre/'); _sharedService.checkCTALink($event, 'https://www.linkedin.com/company/jioworldcentre/', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.991 19.459">
                <g id="Twitter" transform="translate(0 -3.002)">
                  <path id="Path_2" data-name="Path 2" d="M45.6,21.46A13.87,13.87,0,0,0,59.592,7.465V6.8a10.834,10.834,0,0,0,2.4-2.532,11.064,11.064,0,0,1-2.8.8A5.186,5.186,0,0,0,61.325,2.4a12.225,12.225,0,0,1-3.066,1.2,4.76,4.76,0,0,0-3.6-1.6,5.008,5.008,0,0,0-4.932,4.932A2.6,2.6,0,0,0,49.862,8a13.78,13.78,0,0,1-10.13-5.2,5.1,5.1,0,0,0-.666,2.532A5.3,5.3,0,0,0,41.2,9.464,4.493,4.493,0,0,1,38.933,8.8h0a4.871,4.871,0,0,0,4,4.8,4.109,4.109,0,0,1-1.333.133,2.268,2.268,0,0,1-.933-.133,5.049,5.049,0,0,0,4.665,3.465A10.063,10.063,0,0,1,39.2,19.194,3.69,3.69,0,0,1,38,19.06a12.581,12.581,0,0,0,7.6,2.4" transform="translate(-38 1.003)" fill="#4b4847" fill-rule="evenodd"></path>
                </g>
              </svg>
            </a></li> -->
          <li><a href="{{'https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg'}}" aria-label="Social icons" (click)="callCTFooterEvent('https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg'); _sharedService.checkCTALink($event, 'https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.676 22.162">
                <path id="Youtube" d="M13.338,21.162h0c-.069,0-6.97-.051-9.061-.288-.112-.027-.249-.047-.408-.07a3.709,3.709,0,0,1-2.536-1.228A7.333,7.333,0,0,1,.276,16.593c0-.024-.276-2.435-.276-4.857V9.474c0-2.42.273-4.881.276-4.906A6.667,6.667,0,0,1,1.334,1.587,3.6,3.6,0,0,1,3.921.344h.005L4,.337C7.683.052,13.282,0,13.338,0s5.655.052,9.337.337L22.8.347a3.367,3.367,0,0,1,2.541,1.239A7.333,7.333,0,0,1,26.4,4.569c0,.025.276,2.484.276,4.906v2.261c0,2.373-.273,4.833-.276,4.857a7.335,7.335,0,0,1-1.058,2.982,3.6,3.6,0,0,1-2.588,1.243h-.005l-.074.007C18.992,21.158,13.394,21.162,13.338,21.162ZM10.118,5.723V16.16l8.739-5.1Z" transform="translate(0.5 0.5)" fill="#4b4847" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"></path>
              </svg>
            </a></li>   
        </ul>
      </div>
      <div class="social col-12 justify-content-end mobile_copy_text">
        <p>© Copyright Jio World Centre {{currentYear}}</p>
      </div>
    </div>
    <!-- <div class="col-md-3 social">
          <p class="desktop_copy_text">Follow Us</p>
          <ul class="social--UL">
            <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="icon icon-facebook"></a></li>
            <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="icon icon-twitter"></a></li>
            <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="icon icon-instagram"></a></li>
            <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="icon icon-youtube"></a></li>
            <li><a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)" class="icon icon-linkedin"></a></li>
          </ul>
          <p class="mobile_copy_text">© Copyright {{currentYear}} Jio World Centre. All rights reserved</p>
        </div> -->
  </div>
</footer>