

<div class="jwc_signin_wrap_loyalty">
    <h4>Loyalty Program</h4>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.</p>

    <div class="jwc_signin_wrap_loyalty_inner">
        <div class="row">
            <div class="col-md-6">
                <div class="jwc_form_group">
                    <input type="text" name="" value="DOB - DD/MM/YYYY" class="input-field"
                        id="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <div class="dropdown" role="button" aria-labelledby="dropdown-label"
                        tabindex="0">
                        <div class="dropdown--select">Country</div>
                        <div class="dropdown--list" aria-expanded="false" role="list">
                            <ul>
                                <li tabindex="0">Country</li>
                                <li tabindex="0">Country 1</li>
                                <li tabindex="0">Country 2</li>
                                <li tabindex="0">Country 3</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <div class="dropdown" role="button" aria-labelledby="dropdown-label"
                        tabindex="0">
                        <div class="dropdown--select">City</div>
                        <div class="dropdown--list" aria-expanded="false" role="list">
                            <ul>
                                <li tabindex="0">City</li>
                                <li tabindex="0">City 1</li>
                                <li tabindex="0">City 2</li>
                                <li tabindex="0">City 3</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" class="input-field" placeholder="Pin Code">
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="form-row">
                        <div class="col-3 pr-0">
                            <div class="dropdown" role="button"
                                aria-labelledby="dropdown-label" tabindex="0">
                                <div class="dropdown--select">+01</div>
                                <div class="dropdown--list" aria-expanded="false"
                                    role="list">
                                    <ul>
                                        <li tabindex="0">+01</li>
                                        <li tabindex="0">+91</li>
                                        <li tabindex="0">+221</li>
                                        <li tabindex="0">+022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-9 pl-0 input-box">
                            <div class="form-group mb-0">
                                <input type="tel" class="input-field" placeholder="Mobile">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <input type="email" class="input-field" placeholder="Email">
            </div>
        </div>
        <div class="jwc_gap_10"></div>
        <div class="jwc_signin_btns">
            <a href="javascript:;" class="cta-prim color-gold emp-btn"
                id="complete_loyalty" (click)="onLoyaltyEnrolComplete()"><span class="cta-prim-txt">Complete
                    LOYALTY Enrolment</span></a>
        </div>
    </div>
</div>