<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>
    <div class="sticky_menu_wrapper" [attr.style]="('--data-icon-color:#A67D54') | safe: 'style'">
        <a href="{{'/logistics-services-redirect'}}" class="sticky_menu_wrapper_each"
            (click)="logisticsServicesCTEvent('sticky', '', '', 'Login'); _sharedService.checkCTALink($event, '/logistics-services-redirect');">
            <span class="text">Login</span>
            <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="21.35" height="30.5"
                    viewBox="0 0 21.35 30.5">
                    <path id="Path_12045" data-name="Path 12045"
                        d="M41.35,11.169V36.331A2.669,2.669,0,0,1,38.681,39H22.669A2.669,2.669,0,0,1,20,36.331V28.325h1.083v8.006c0,1.053.533,1.417,1.586,1.417H38.681c1.053,0,1.365-.364,1.365-1.417V11.169c0-1.053-.313-1.384-1.365-1.384H22.669c-1.053,0-1.586.332-1.586,1.384V21.462c0,1.053.533,2.017,1.586,2.017h9.566l-3.34-3.392L29.8,18.9l4.923,5.145c.072.072,0-.1,0,0s.072-.072,0,0L29.8,28.6l-.907-.867,3.34-3.17-9.566.283c-1.474,0-2.669-1.905-2.669-3.379V11.169A2.669,2.669,0,0,1,22.669,8.5H38.681A2.669,2.669,0,0,1,41.35,11.169Z"
                        transform="translate(-20 -8.5)" />
                </svg>
            </span>
        </a>
        <a href="{{'./assets/pdfs/logistics/jwc-tariff-chart-and-fees.pdf'}}" class="sticky_menu_wrapper_each"
            (click)="logisticsServicesCTEvent('sticky', '', '', 'Tariff Chart'); _sharedService.checkCTALink($event, './assets/pdfs/logistics/jwc-tariff-chart-and-fees.pdf', '_self', 'PDF', 'jwc-tariff-chart-and-fees')">
            <span class="text">Tariff Chart</span>
            <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24.491" height="30.395"
                    viewBox="0 0 24.491 30.395">
                    <g id="Group_4310" data-name="Group 4310" transform="translate(-18.15 -23.033)">
                        <g id="Group_4311" data-name="Group 4311" transform="translate(18.15 23.033)">
                            <g id="Group_4310-2" data-name="Group 4310" transform="translate(0 0)">
                                <path id="Path_12039" data-name="Path 12039"
                                    d="M40.056,53.428H20.77a2.587,2.587,0,0,1-2.584-2.584V30.277L18.15,28.73V25.617a2.587,2.587,0,0,1,2.584-2.584H40.056a2.533,2.533,0,0,1,1.814.764,2.681,2.681,0,0,1,.77,1.8v25.25A2.587,2.587,0,0,1,40.056,53.428ZM19.526,28.722l.036,1.547V50.843a1.225,1.225,0,0,0,1.208,1.208H40.056a1.225,1.225,0,0,0,1.208-1.208V25.63a1.238,1.238,0,0,0-1.208-1.221H20.734a1.225,1.225,0,0,0-1.208,1.208Z"
                                    transform="translate(-18.15 -23.033)" />
                            </g>
                        </g>
                        <path id="Line_432" data-name="Line 432"
                            d="M11.44.75H-.11A.7.7,0,0,1-.75,0,.7.7,0,0,1-.11-.75H11.44a.7.7,0,0,1,.64.75A.7.7,0,0,1,11.44.75Z"
                            transform="translate(24.711 42.797)" />
                        <path id="Line_433" data-name="Line 433"
                            d="M11.44.75H-.11A.7.7,0,0,1-.75,0,.7.7,0,0,1-.11-.75H11.44a.7.7,0,0,1,.64.75A.7.7,0,0,1,11.44.75Z"
                            transform="translate(24.711 47.429)" />
                        <path id="Ellipse_4229" data-name="Ellipse 4229"
                            d="M1.75-.75a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,1.75-.75Zm0,3.759A1.259,1.259,0,1,0,.491,1.75,1.261,1.261,0,0,0,1.75,3.009Z"
                            transform="translate(24.678 28.07)" />
                        <path id="Ellipse_4230" data-name="Ellipse 4230"
                            d="M1.75-.75a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,1.75-.75Zm0,3.759A1.259,1.259,0,1,0,.491,1.75,1.261,1.261,0,0,0,1.75,3.009Z"
                            transform="translate(32.541 34.665)" />
                        <path id="Line_434" data-name="Line 434"
                            d="M0,10.264a.748.748,0,0,1-.532-.221.75.75,0,0,1,0-1.061L9.044-.532a.75.75,0,0,1,1.061,0,.75.75,0,0,1,0,1.061L.529,10.046A.748.748,0,0,1,0,10.264Z"
                            transform="translate(25.701 28.364)" />
                    </g>
                </svg>
            </span>
        </a>
        <!-- <a href="javascript:void(0);" class="sticky_menu_wrapper_each"> -->
        <!-- <span class="text">FAQs</span>
            <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="23.067" height="30.396"
                    viewBox="0 0 23.067 30.396">
                    <g transform="translate(-4987.897 -1085.217)">
                        <path
                            d="M4992.966,1085.517c-2.965,0-4.769,2.79-4.769,5.411v12.624a4.647,4.647,0,0,0,4.769,4.9h11.629l5,6.583a.723.723,0,0,0,.57.278c.074,0,.043,0,.117-.029a.634.634,0,0,0,.38-.672v-23.683a5.387,5.387,0,0,0-5.076-5.411Zm16.4,5.32v21.83l-3.9-5.262a.527.527,0,0,0-.459-.28H4992.9a3.285,3.285,0,0,1-3.4-3.533v-12.755c0-2.011,1.3-4.051,3.4-4.051h12.755A4.026,4.026,0,0,1,5009.364,1090.837Z"
                            transform="translate(0)" />
                        <path
                            d="M4992.966,1085.217h12.623a5.162,5.162,0,0,1,3.793,1.741,5.925,5.925,0,0,1,1.583,3.971v23.683a.9.9,0,0,1-.584.957l-.014.006a.407.407,0,0,1-.2.039,1.019,1.019,0,0,1-.81-.4l-4.913-6.464h-11.48a5.034,5.034,0,0,1-3.621-1.441,5.273,5.273,0,0,1-1.447-3.759v-12.624a6.3,6.3,0,0,1,1.338-3.876A4.719,4.719,0,0,1,4992.966,1085.217Zm17.188,29.8.033-.012c.046-.016.179-.06.179-.389v-23.683a5.082,5.082,0,0,0-4.776-5.111h-12.623c-2.76,0-4.469,2.653-4.469,5.111v12.624a4.34,4.34,0,0,0,4.469,4.6h11.778l.09.119,5,6.583A.421.421,0,0,0,5010.153,1115.012Zm-17.256-28.526h12.755a3.867,3.867,0,0,1,2.833,1.345,4.566,4.566,0,0,1,1.179,3.006v22.738l-4.443-5.991c-.121-.159-.147-.159-.218-.159H4992.9a3.674,3.674,0,0,1-2.651-1.04,3.868,3.868,0,0,1-1.048-2.793v-12.755a4.926,4.926,0,0,1,.976-2.963A3.4,3.4,0,0,1,4992.9,1086.486Zm16.167,25.273v-20.922a3.736,3.736,0,0,0-3.412-3.751H4992.9c-1.94,0-3.1,1.907-3.1,3.751v12.755a3,3,0,0,0,3.1,3.233H5005a.816.816,0,0,1,.7.4Z"
                            transform="translate(0)" />
                        <g transform="translate(4996.95 1093.367)">
                            <path
                                d="M4998.509,1092.487a2.769,2.769,0,0,0-1.352.318,2.352,2.352,0,0,0-.894.8,1.756,1.756,0,0,0-.294.952.643.643,0,0,0,.659.624c.314,0,.521-.19.635-.564a2.178,2.178,0,0,1,.446-.812,1.041,1.041,0,0,1,.8-.27,1.078,1.078,0,0,1,.764.27.874.874,0,0,1,.294.67.746.746,0,0,1-.095.376,1.4,1.4,0,0,1-.234.318c-.095.095-.247.238-.46.424-.241.212-.445.4-.588.553a1.778,1.778,0,0,0-.34.529,1.864,1.864,0,0,0-.13.729.643.643,0,0,0,.176.493.607.607,0,0,0,.436.177.56.56,0,0,0,.6-.517c.037-.162.064-.277.082-.341a1.058,1.058,0,0,1,.071-.2,1.123,1.123,0,0,1,.154-.212,3.424,3.424,0,0,1,.27-.27q.612-.546.846-.776a2.287,2.287,0,0,0,.412-.552,1.547,1.547,0,0,0,.165-.741,1.745,1.745,0,0,0-.294-.988,2.079,2.079,0,0,0-.857-.729A2.879,2.879,0,0,0,4998.509,1092.487Z"
                                transform="translate(-4995.969 -1092.487)" />
                            <path
                                d="M4998.509,1092.187a3.158,3.158,0,0,1,1.4.289,2.371,2.371,0,0,1,.978.834,2.036,2.036,0,0,1,.343,1.152,1.834,1.834,0,0,1-.2.883,2.588,2.588,0,0,1-.468.626c-.154.152-.435.409-.855.785a3.057,3.057,0,0,0-.243.243.859.859,0,0,0-.114.153.823.823,0,0,0-.048.143c-.015.055-.04.157-.077.322a.856.856,0,0,1-.893.756.911.911,0,0,1-.644-.261.936.936,0,0,1-.268-.709,2.157,2.157,0,0,1,.154-.846,2.064,2.064,0,0,1,.4-.615c.134-.144.316-.316.61-.574.243-.212.384-.348.446-.411a1.106,1.106,0,0,0,.183-.25.45.45,0,0,0,.058-.231.574.574,0,0,0-.2-.449.784.784,0,0,0-.561-.191.768.768,0,0,0-.58.175,1.9,1.9,0,0,0-.379.7.934.934,0,0,1-.921.773.942.942,0,0,1-.958-.924,2.068,2.068,0,0,1,.34-1.112,2.652,2.652,0,0,1,1-.9A3.083,3.083,0,0,1,4998.509,1092.187Zm-.176,5.585c.161,0,.263-.044.3-.271l0-.013c.041-.179.067-.288.085-.354a1.336,1.336,0,0,1,.093-.257,1.383,1.383,0,0,1,.2-.273,3.763,3.763,0,0,1,.293-.294c.417-.371.689-.621.838-.768a1.983,1.983,0,0,0,.359-.481,1.251,1.251,0,0,0,.128-.6,1.456,1.456,0,0,0-.245-.823,1.78,1.78,0,0,0-.737-.623,2.577,2.577,0,0,0-1.141-.229,2.456,2.456,0,0,0-1.208.281,2.063,2.063,0,0,0-.784.7,1.459,1.459,0,0,0-.249.793.344.344,0,0,0,.359.324c.084,0,.241,0,.348-.352l0-.007a2.464,2.464,0,0,1,.512-.923,1.317,1.317,0,0,1,1.018-.365,1.374,1.374,0,0,1,.968.35,1.168,1.168,0,0,1,.39.89,1.053,1.053,0,0,1-.133.524,1.688,1.688,0,0,1-.283.382c-.083.083-.232.226-.475.438-.184.162-.415.369-.565.531a1.471,1.471,0,0,0-.283.441,1.56,1.56,0,0,0-.107.613.345.345,0,0,0,.083.276A.309.309,0,0,0,4998.333,1097.772Z"
                                transform="translate(-4995.969 -1092.487)" />
                            <path
                                d="M4998.2,1097.843a.742.742,0,0,0-.54.212.781.781,0,0,0,.011,1.081.788.788,0,0,0,.529.2.757.757,0,0,0,.518-.2.7.7,0,0,0,.224-.552.724.724,0,0,0-.741-.741Z"
                                transform="translate(-4995.782 -1090.811)" />
                            <path
                                d="M4998.2,1097.543a1.023,1.023,0,0,1,1.041,1.041,1,1,0,0,1-.325.777,1.05,1.05,0,0,1-.716.276,1.084,1.084,0,0,1-.726-.273,1.08,1.08,0,0,1-.022-1.524A1.041,1.041,0,0,1,4998.2,1097.543Zm0,1.493a.456.456,0,0,0,.318-.125.41.41,0,0,0,.123-.328.425.425,0,0,0-.441-.441.445.445,0,0,0-.329.125.4.4,0,0,0-.123.316.383.383,0,0,0,.12.325A.485.485,0,0,0,4998.2,1099.036Z"
                                transform="translate(-4995.782 -1090.811)" />
                        </g>
                    </g>
                </svg></span> -->
        <!-- </a> -->
        <a href="{{'#contact'}}" routerLink="./" fragment="contact" class="sticky_menu_wrapper_each"
            (click)="logisticsServicesCTEvent('sticky', '', '', 'Contact Us')">
            <span class="text">Contact Us</span>
            <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="31.266" height="31.268"
                    viewBox="0 0 31.266 31.268">
                    <g id="Group_4313" data-name="Group 4313" transform="translate(0.757 0.75)">
                        <g id="Group_4312" data-name="Group 4312">
                            <path id="Path_12040" data-name="Path 12040"
                                d="M1262.2,166.072h0a8.743,8.743,0,0,1-2.467-.34c-1.4-.419-4.211-2.88-4.764-4.9a1.835,1.835,0,0,1,.346-1.835c.365-.395.748-.845,1.155-1.33,1.506-1.793,3.214-3.828,5.128-3.828a3.049,3.049,0,0,1,1.993.808,13.784,13.784,0,0,1,1.228,1.233,12.209,12.209,0,0,0,1.045,1.052.377.377,0,0,0,.255.073c1.914,0,7.978-5.761,9.272-8.8.225-.529.274-.936.128-1.088-.231-.237-.517-.5-.827-.772-1.318-1.191-3.129-2.813-2.1-4.6a.3.3,0,0,1,.055-.073q2.169-2.278,4.351-4.563l.03-.03a3.039,3.039,0,0,1,1.92-.778c1.537,0,2.807,1.482,3.828,2.679,1.908,2.23,2.133,2.692,1.811,5.657-.492,4.545-3.585,9.916-8.269,14.37C1271.66,163.434,1266.38,166.072,1262.2,166.072Z"
                                transform="translate(-1254.873 -136.304)" stroke-miterlimit="10" stroke-width="1.5" />
                        </g>
                    </g>
                </svg>
            </span>
        </a>
    </div>
    <div *ngIf="logisticsBannerData" class="downloadable_techspecs mobileExtraSpace"
        [attr.style]="('--data-techspecs-textColor:#ffffff;  --data-downloadable-desktop-img:url('+ staticUrlForImgNew + logisticsBannerData?.UploadImage?.document?.contentUrl +'); --data-downloadable-mobile-img:url('+ staticUrlForImgNew + logisticsBannerData?.UploadMobileKMAImage?.document?.contentUrl + ')') | safe: 'style'">
        <div class="breadcrumbs kma_breadcrumbs">
            <div class="breadcrumbs_container">
                <a routerLink='/'>Jio World Centre </a> / <strong>Logistics Services</strong>
            </div>
        </div>
        <section class="downloadable_techspecs_head" data-aos="fade-up">
            <div class="container">
                <div class="section_heading section_heading--title section_heading--text text-center fullwidth">
                    <h2 [innerHTML]="logisticsBannerData?.Headline?.data"></h2>
                    <p [innerHTML]="logisticsBannerData?.SubTitle?.data"></p>
                </div>
                <div class="text-center kmabtns">
                    <a href="{{logisticsBannerData?.FirstCTALink?.data}}" class="cta-prim color-white text-white" *ngIf="logisticsBannerData?.FirstHideCTA?.data === 'false'"
                    (click)="logisticsServicesCTEvent('pri', 'Exhibitor Logistics Services', '', logisticsBannerData?.FirstCTAText?.data);_sharedService.checkCTALink($event, logisticsBannerData?.FirstCTALink?.data);">
                        <span class="cta-prim-txt">{{logisticsBannerData?.FirstCTAText?.data}}</span>
                    </a>
                    <a href="{{logisticsBannerData?.SecondCTALink?.data}}" routerLink="./" [fragment]="logisticsBannerData?.SecondCTALink?.data"
                        class="cta-prim color-white text-white" *ngIf="logisticsBannerData?.SecondHideCTA?.data === 'false'"
                        (click)="logisticsServicesCTEvent('pri', 'Exhibitor Logistics Services', '', logisticsBannerData?.SecondCTAText?.data)"><span
                            class="cta-prim-txt">
                            {{logisticsBannerData?.SecondCTAText?.data}}
                        </span></a>
                </div>
            </div>
        </section>
    </div>
    <app-benefits-wrapper *ngIf="logisticsGettingStartedData" [benefitsWrapperData]="logisticsGettingStartedData"
        [sectionClass]="'pb-0'" [colorList]="logisticsColorList"></app-benefits-wrapper>
    <app-get-in-touch *ngIf="logisticsGetInTouchData" [getInTouchData]="logisticsGetInTouchData" id="order-forms"
        data-mobile-header="Order Forms" [colorList]="logisticsColorList" [pageName]="'homeWithoutEmpClass'"
        [downloadCta]="true" [sectionClass]="'pb-0'"></app-get-in-touch>
    <section class="explorevenues_wrapper pb-0" *ngIf="logisticsServicesData">
        <div class="container">
            <div class="section_heading section_heading--title section_heading--text text-center"
                [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                <h2>{{logisticsServicesData?.ComponentTitle?.data}}</h2>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="container">
            <app-safety-card [cardData]="logisticsServicesData" [mainClass]="'mt-0'" [colorList]="logisticsColorList">
            </app-safety-card>
        </div>
    </section>
    <section class="pb-0 public-arts-detail" id="contact" *ngIf="logisticsServicesContactData">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="details_piece content2">
                        <div class="section_heading section_heading--title section_heading--text multilineText mb-30 pb-0 "
                            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                            <h2>{{logisticsServicesContactData?.ComponentHeaderTitle?.data}}</h2>
                        </div>
                        <div [innerHTML]="logisticsServicesContactData?.Content?.data"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="download_services" id="downloads" *ngIf="logisticsServicesDownloadsList">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="content2">
                        <div class="section_heading section_heading--title section_heading--text multilineText mb-30 pb-0 text-center"
                            [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                            <h2>Downloads</h2>
                        </div>
                        <div class="download_services_list">
                            <div class="container">
                                <div class="download_services_list_box">
                                    <div class="row">
                                        <ng-container *ngFor="let item of logisticsServicesDownloadsList?.cards; let i=index">
                                            <div *ngIf="item?.HideCTA?.data==='false'" class="download_services_list_box_each">
                                                <a href="{{item?.CTALink?.data}}" (click)="logisticsServicesCTEvent('download', '', item?.CTALink?.data, ''); _sharedService.checkCTALink($event, item?.CTALink?.data, '_self', 'PDF', pdfName)"><span class="content2">{{item?.HeaderText?.data}}</span> <img
                                                        src="./assets/images/download_icon.svg" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                                                </a>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<div class="overlay"></div>