<app-sub-header [colorList]="downloadableSpecsColorList" [subHeaderList]="downloadableSpecsSubHeaderList" [pageName]="pageName" *ngIf="downloadableSpecsSubHeaderList"></app-sub-header>
<div class="loader">
    <div class="loader_spin"></div>
</div>

<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>
    <div class="downloadable_techspecs black_tint" *ngIf="techSpecEventPlanningData"
        [attr.style]="('--data-techspecs-textColor:#ffffff; --data-downloadable-desktop-img:url(' + (staticUrlForImgNew + techSpecEventPlanningData?.UploadBackgroundImageVideo?.document?.contentUrl) + '); --data-downloadable-mobile-img:url('+ (staticUrlForImgNew + techSpecEventPlanningData?.UploadMobileKMAImageVideo?.document?.contentUrl) +')') | safe: 'style'">
        <section class="downloadable_techspecs_head" data-aos="fade-up">
            <div class="container">
                <div class="section_heading section_heading--title section_heading--text text-center fullwidth" [attr.style]="('--data-lineColor:#FFFFFF') | safe: 'style'">
                        <h2 [innerHTML]="techSpecEventPlanningData?.ComponentTitle?.data"></h2>
                        <p [innerHTML]="techSpecEventPlanningData?.ComponentShortText?.data"></p>
                </div>
                <div class="text-center" *ngIf="techSpecEventPlanningData?.HideCTA?.data == 'false'">
                    <a href="javascript:void(0)" routerLink="./" fragment="downloads"
                        class="cta-prim color-gold on-bg" (click)="downloadTechCTEvent('pri', techSpecEventPlanningData?.ComponentTitle?.data, '', 'downloads')">
                        <span class="cta-prim-txt">{{techSpecEventPlanningData?.CTAText?.data}}</span>
                    </a>
                </div>
            </div>
        </section>
    </div>
    <section class="downloadable_techspecs_categories plr-0 pt-0 " id="downloads"
        [attr.style]="('--data-downloadable-techspecs-bgColor:#F5F2ED; --data-dts-result-resultbgColor:#FFFFFF') | safe: 'style'"
        *ngIf="techSpecDocsData">
        <!-- TODO Filter NA for phase1-->
        <!-- <div class="downloadable_techspecs_categories_wrapper"> -->
        <!-- <div class="container-fluid">
                <div class="row">
                    <div class="downloadable_techspecs_categories_wrapper_list listFilter">
                        <div class="col-sm-12 filters"> <a href="javascript:;" class="filter">Filters</a> </div>
                        <div class="container">
                            <div class="row">
                                <div class="downloadable_techspecs_categories_wrapper_list_each">
                                    <div class="custom-control custom-checkbox checkbox">
                                        <input type="checkbox" class="custom-control-input" id="visitors"
                                            name="visitors">
                                        <label class="custom-control-label" for="visitors">VISITORS</label>
                                    </div>
                                </div>
                                <div class="downloadable_techspecs_categories_wrapper_list_each">
                                    <div class="custom-control custom-checkbox checkbox">
                                        <input type="checkbox" class="custom-control-input" id="organisers"
                                            name="organisers">
                                        <label class="custom-control-label" for="organisers">Organisers</label>
                                    </div>
                                </div>
                                <div class="downloadable_techspecs_categories_wrapper_list_each">
                                    <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                                        <div class="dropdown--select">Spaces</div>
                                        <div class="dropdown--list" aria-expanded="false" role="list">
                                            <ul>
                                                <li tabindex="0">Exhibition Halls</li>
                                                <li tabindex="0">Convention Halls</li>
                                                <li tabindex="0">Meeting Rooms</li>
                                                <li tabindex="0">Banquet Hall</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="downloadable_techspecs_categories_wrapper_list_each">
                                    <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
                                        <div class="dropdown--select">Sort by</div>
                                        <div class="dropdown--list" aria-expanded="false" role="list">
                                            <ul>
                                                <li tabindex="0">Sort by</li>
                                                <li tabindex="0">Type</li>
                                                <li tabindex="0">Size</li>
                                                <li tabindex="0">Capacity</li>
                                                <li tabindex="0">Seating</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

        <!-- </div> -->
        <div class="downloadable_techspecs_result">
            <div class="container">
                <div class="downloadable_techspecs_result_list"
                    *ngFor="let item of techSpecDocsData.cards; let i = index">
                    <div class="row">
                        <div class="downloadable_techspecs_result_list_each">
                            <div class="downloadable_techspecs_result_list_each--img"> <img
                                    src="./assets/images/pdf-icon.svg" class="img-fluid" alt="pdf-icon"> </div>
                            <div class="downloadable_techspecs_result_list_each--data">
                                <p class="content1">{{item?.HeaderText?.data}}</p>
                                <!-- <p class="content3">{{item?.Date?.data | date: 'dd/MM/yyyy'}}</p> -->
                            </div>
                            <div class="downloadable_techspecs_result_list_each--btn"
                                *ngIf="item?.HideCTA?.data == 'false'">
                                <a (click)="downloadTechCTEvent('download', '', item?.CTALink?.data); _sharedService.checkCTALink($event, item?.CTALink?.data, '_self', 'PDF', pdfName)"
                                    class="cta-sec icon rh icon-download-icon color-blue ">
                                    <span class="cta-sec-txt">{{item?.CTAText?.data}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TODO Load more NA for phase1-->
                <!-- <div class="text-center downloadable_techspecs_categories_wrapper--btn">
                    <a href="javascript:void(0)" (click)="_sharedService.checkCTALink($event)"
                        class="cta-sec color-blue">
                        <span class="cta-sec-txt">LOAD more</span>
                    </a>
                </div> -->
            </div>
        </div>
    </section>
    <section class="faqs_wrapper planevent" [attr.style]="('--data-dts-planevent-bgColor:#F5F2ED') | safe: 'style'" id="unique-features" *ngIf="techSpecPlanEventData?.HideComponent?.data == 'false'">
        <div class="container">
            <div class="section_heading section_heading--title section_heading--text" [attr.style]="('--data-lineColor:#1E478E') | safe: 'style'">
                    <h2 [innerHTML]="techSpecPlanEventData?.ComponentHeaderTitle?.data"></h2>
                    <p [innerHTML]="techSpecPlanEventData?.ComponentShortText?.data"></p>
            </div>
            <!-- <h4 class="border-bottom pb-3">{{techSpecPlanEventData?.ServiceTitle?.data}}</h4> -->
            <div id="accordion4" class="cus_accordion" [attr.style]="('--data-faqs-arrowColor:#1E478E') | safe: 'style'">
                <div class="card" *ngFor="let item of techSpecPlanEventData.cards; let i = index">
                    <div class="card-header" id="planevent{{i+1}}">
                        <h5 class="mb-0">
                            <button class="btn btn-link content2" data-toggle="collapse"
                                [attr.data-target]="'#faq_plan_collapse'+(i+1)"
                                [attr.aria-expanded]="i===0 ? true : false"
                                [attr.aria-controls]="'faq_plan_collapse'+(i+1)">{{item?.AddServiceHeader?.data}}<span
                                    class="icon-arrow-down"></span> </button>
                        </h5>
                    </div>
                    <div id="faq_plan_collapse{{i+1}}" class="collapse" [ngClass]="{'show': i === 0}"
                        [attr.aria-labelledby]="'planevent'+(i+1)" data-parent="#accordion4">
                        <div class="card-body">
                            <div class="card-body_copy">
                                <ul class="bullets_list mb-4">
                                    <li *ngFor="let list of item?.AddServiceDescription" [innerHTML]="list?.Description?.data"></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5" *ngIf="techSpecPlanEventData?.HideCTA?.data =='false'">
                <a href="{{techSpecPlanEventData?.CTATextLink?.data}}"
                    (click)="downloadTechCTEvent('pri', techSpecPlanEventData?.ComponentHeaderTitle?.data);_sharedService.checkCTALink($event, techSpecPlanEventData?.CTATextLink?.data, techSpecPlanEventData?.CTAWindowPreference?.data, techSpecPlanEventData?.TypeOfFirstCTALink?.data)"
                    class="cta-prim color-blue emp-btn"><span
                        class="cta-prim-txt">{{techSpecPlanEventData?.CTAText?.data}}</span></a>
            </div>
        </div>
    </section>
</main>
<!-- <app-footer [colorList]="downloadableSpecsColorList"></app-footer> -->
<div class="overlay"></div>