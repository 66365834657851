<section class="plan_your_itinerary bg_white_light" [style.background-color]="planItinerary?.ComponentBackgroundColor?.data" *ngIf="planItinerary">
  <div class="container">
    <div class="section_heading text-center" style="--data-titleColor:#2C2C2C;--data-lineColor:#C63493">
      <div data-aos="fade-up" class="">
        <h2 class="section_heading--title">{{planItinerary?.ComponentHeaderTitle?.data}}</h2>
      </div>
    </div>
  </div>

  <div class="container plan_your_itinerary_wrapper" data-aos="fade-up">
    <div class="plan_your_itinerary_slider">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of planItinerary?.cards; let i = index">
            <div class="card_5">
              <div class="card_5--img">
                <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid" alt="Itinerary {{i+1}}">
              </div>
              <div class="card_5_copy">
                <h4 class="card_5_copy--title">{{item?.CardHLText?.data}}</h4>
                <p class="card_5_copy--text content3">{{item?.CardShortText?.data}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center button">
        <a href="{{planItinerary?.ViewAllCTALink?.data}}" *ngIf="planItinerary?.HideViewAllCTAText?.data =='false'"  class="cta-prim color-pink"
         (click)="_sharedService.checkCTALink($event, planItinerary?.ViewAllCTALink?.data, planItinerary?.ViewAllWindowPreference?.data, planItinerary?.TypeOfViewAllCTALink?.data)">
          <span class="cta-prim-txt">{{planItinerary?.ViewAllCTAText?.data}}</span>
        </a>
      </div>
    </div>
  </div>

</section>