<div class="jwc_signin contactus_form">
    <div class="container">
        <div class="jwc_signin_wrap">
            <form [formGroup]="NotificationForm">
                <div *ngIf="notification" class="alert alert-success text-center" role="alert">
                    {{notification}}
                </div>
                <!-- <textarea rows="10" formControlName="notificationDesc" autofocus="autofocus"></textarea> -->
                <ng-container *ngIf="ckEditorLoaded">
                    <ckeditor [editor]="Editor" (ready)="onReady($event)" formControlName="notificationDesc"></ckeditor>
                </ng-container>
                <div class="d-flex justify-content-end mt-3">
                    <button class="cta-prim color-gold emp-btn mx-3" href="javascript:;"
                        [disabled]="!NotificationForm.valid" (click)="activate()">
                        <span class="cta-prim-txt">
                            Activate
                        </span>
                    </button>
                    <button class="cta-prim color-gold" href="javascript:;" [disabled]="!NotificationForm.valid"
                        (click)="deactivate()">
                        <span class="cta-prim-txt">
                            Deactivate
                        </span>
                    </button>
                </div>
                <select formControlName="componentId" (change)="getCurrentNotification(NotificationForm.value.componentId)" hidden>
                    <option value="{{item?.value}}" *ngFor="let item of componentArr">{{item?.type}}</option>
                </select>
            </form>
        </div>
    </div>
</div>