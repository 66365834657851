import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-instagram2-section',
  templateUrl: './instagram2-section.component.html',
  styleUrls: ['./instagram2-section.component.css']
})
export class Instagram2SectionComponent implements OnInit {
  @Input() instagramData;
  @Input() instagramHandleName;
  @Input() followUsLink;
  @Input() sectionClass?;
  incomingInstaData = [];
  staticUrlForImgNew = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
    if(this.instagramData.instaUrls) this.getTopInstaPosts(this.instagramData.instaUrls);
  }

  ngAfterViewInit() {
    // var cw = $('.child').width();
    // $('.child').height(cw+'px')
    
    // $( window ).resize(function() {
    //   var cw = $('.child').width();
    //   $('.child').height(cw+'px')
    // });
    
  }

  getTopInstaPosts(data) {
    if (data.length > 3) {
      data.forEach((element, i) => {
        if (i < 4) this.incomingInstaData.push(element)
      })
    } else {
      this.incomingInstaData = data;
    }
  }
  callInstaCTEvents(eveName, secName?, contName?, statLink?) {
    this._sharedService.callCTEvent(eveName, secName, contName, statLink)
  }
}
