<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>
    <section class="sub_page_header" [attr.style]="('--data-subheader-bgColor:#F5F2ED;--data-subheader-lineColor:#A67D54') | safe: 'style'">
        <div class="breadcrumbs ">
            <div class="breadcrumbs_container">
                <a routerLink="/">Jio World Centre</a> / <strong>Press and Media</strong>
            </div>
        </div>
        <div class="container" *ngIf="pressMediaBannerData">
            <div class="section_heading section_heading--title section_heading--text content2 text-center" [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
                    <h2 [innerHTML]="pressMediaBannerData?.ComponentTitle?.data"></h2>
                    <p [innerHtml]="pressMediaBannerData?.ComponentShortText?.data"></p>
                    <div class='buttons_links'>
                        <a *ngIf="pressMediaBannerData?.HideContactUsCTA?.data == 'false'" href="/newsroom"
                            class='cta-prim color-gold'
                            (click)="callPMCTEvent('pri', pressMediaBannerData?.ComponentTitle?.data); _sharedService.checkCTALink($event, '/newsroom', pressMediaBannerData?.CTAWindowPreference?.data, 'Internal')">
                            <!-- href='newsroom.html#section-3' -->
                            <span class='cta-prim-txt'>{{pressMediaBannerData?.CTAText?.data}}</span>
                        </a>
                        <a *ngIf="pressMediaBannerData?.SecondHideCTA?.data == 'false'" class='cta-sec color-gold'
                            href="/newsroom"
                            (click)="callPMCTEvent('sec', pressMediaBannerData?.ComponentTitle?.data); _sharedService.checkCTALink($event, '/newsroom', pressMediaBannerData?.SecondCTALinkWindowPreference?.data, 'Internal')">
                            <span class='cta-sec-txt'>{{pressMediaBannerData?.SecondCTAText?.data}}</span>
                        </a>
                    </div>
            </div>
        </div>
    </section>
    <section class="news_wrapper plr-0 "
        [attr.style]="('--data-news-bgColor1:#ffffff;--data-news-bgColor2:#ffffff;--data-news-dateColor:#A67D54;--data-news-copyColor:#F5F2ED') | safe: 'style'">
        <div class="container">
            <div class="section_heading section_heading--title text-center" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                    <h2>News and Media</h2>
            </div>
        </div>
        <div class="container">
            <div class="news_slider jwc_swiper_normal4" data-space="24" data-interval="5" data-slides="4"
                data-ipad-slides="3.2" data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="news_wrapper_each">
                                <div class="news_wrapper_each--img">
                                    <img src="./assets/images/news/9.jpg" class="img-fluid" alt="highlight 1">
                                </div>
                                <div class="news_wrapper_each_copy">
                                    <span class="news_wrapper_each_copy--date">04/06/20</span>
                                    <h5 class="news_wrapper_each_copy--text">The ACC brings iconic
                                        Broadway musical "The Lion
                                    </h5>
                                    <div class="news_wrapper_each_copy--link">
                                        <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">READ
                                                MORE</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="news_wrapper_each">
                                <div class="news_wrapper_each--img">
                                    <img src="./assets/images/news/10.jpg" class="img-fluid" alt="highlight 2">
                                </div>
                                <div class="news_wrapper_each_copy">
                                    <span class="news_wrapper_each_copy--date">04/06/20</span>
                                    <h5 class="news_wrapper_each_copy--text">Jio World Plaza opens its doors to the
                                        luxury
                                        brands that you have</h5>
                                    <div class="news_wrapper_each_copy--link">
                                        <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">READ
                                                MORE</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="news_wrapper_each">
                                <div class="news_wrapper_each--img">
                                    <img src="./assets/images/news/11.jpg" class="img-fluid" alt="highlight 3">
                                </div>
                                <div class="news_wrapper_each_copy">
                                    <span class="news_wrapper_each_copy--date">04/06/20</span>
                                    <h5 class="news_wrapper_each_copy--text">Jio World Convention Centre all set to
                                        welcome
                                        young</h5>
                                    <div class="news_wrapper_each_copy--link">
                                        <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">READ
                                                MORE</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="news_wrapper_each">
                                <div class="news_wrapper_each--img">
                                    <img src="./assets/images/news/12.jpg" class="img-fluid" alt="highlight 3">
                                </div>
                                <div class="news_wrapper_each_copy">
                                    <span class="news_wrapper_each_copy--date">04/06/20</span>
                                    <h5 class="news_wrapper_each_copy--text">"Bloom", acclaimed international human
                                    </h5>
                                    <div class="news_wrapper_each_copy--link">
                                        <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">READ
                                                MORE</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="news_wrapper--all">
                <a href="all-news.html" class="cta-sec color-gold"><span class="cta-sec-txt">View All</span></a>
            </div>
        </div>
    </section>
    <section class="our_story"
        [attr.style]="('--data-story-bgColor:#F5F2ED;--data-story-fisrtColor:#A67D54;--data-story-secondColor:#FFFFFF;') | safe: 'style'"
        *ngIf="pressMediaShowcaseData">
        <div class="container">
            <div class="section_heading section_heading--title text-center" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                    <h2 [innerHTML]="pressMediaShowcaseData?.ComponentHeaderTitle?.data"></h2>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-5 offset-md-7 offset-lg-9">
                    <div class="dropdown category_dropdown"
                        [attr.style]="('--data-dropdown-background:#F5F2ED;--data-dropdown-hover-border-color:#A67D54') | safe: 'style'">
                        <div class="dropdown--select">Select</div>
                        <div class="dropdown--list" aria-expanded="false" role="list">
                            <ul>
                                <li tabindex="0">Select</li>
                                <li tabindex="0">Nita Mukesh Ambani Cultural Centre</li>
                                <li tabindex="0">Jio World Convention Centre</li>
                                <li tabindex="0">Dhirubhai Ambani Square</li>
                                <li tabindex="0">Jio World Plaza</li>
                                <li tabindex="0">Jio World Garden</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="our_story_wrapper">
                <div class="our_story_wrapper_col_1">
                    <div class="our_story_wrapper_img_box">
                        <div class="our_story_wrapper_img_box--bg"
                            [attr.style]="('background-image: url(' +staticUrlForImgNew+ pressMediaShowcaseData?.ShowcaseImage1?.image?.contentUrl +')' ) | safe: 'style'">
                        </div>
                        <!-- <div class="our_story_wrapper_video_box--360Icon"></div> -->
                    </div>
                </div>
                <div class="our_story_wrapper_col_2 our_story_wrapper_verticle_box">
                    <div class="our_story_wrapper_img_box">
                        <div class="our_story_wrapper_img_box--bg"
                            [attr.style]="('background-image: url(' +staticUrlForImgNew+ pressMediaShowcaseData?.ShowcaseImage2?.image?.contentUrl +')' ) | safe: 'style'">
                        </div>
                    </div>
                    <div class="our_story_wrapper_verticle_box_copy">
                        <div class="our_story_wrapper_verticle_box_copy--text">
                            <p class="content3" [innerHTML]="pressMediaShowcaseData?.ShowcaseText2?.data"></p>
                        </div>
                    </div>
                </div>
                <div class="our_story_wrapper_col_3">
                    <div class="our_story_wrapper_img_box">
                        <div class="our_story_wrapper_img_box--bg"
                            [attr.style]="('background-image: url(' +staticUrlForImgNew+ pressMediaShowcaseData?.ShowcaseImage3?.image?.contentUrl +')' ) | safe: 'style'">
                        </div>
                    </div>
                </div>
                <div class="our_story_wrapper_col_4 our_story_wrapper_horizontal_box">
                    <div class="our_story_wrapper_horizontal_box_copy">
                        <div class="our_story_wrapper_horizontal_box_copy--text">
                            <p [innerHTML]="pressMediaShowcaseData?.ShowcaseText3?.data"></p>
                        </div>
                    </div>
                    <div class="our_story_wrapper_img_box">
                        <div class="our_story_wrapper_img_box--bg"
                            [attr.style]="('background-image: url(' +staticUrlForImgNew+ pressMediaShowcaseData?.ShowcaseImage4?.image?.contentUrl +')' ) | safe: 'style'">
                        </div>
                    </div>
                </div>
                <!-- <div class="our_story_wrapper_col_5">
                    <div class="our_story_wrapper_video_box">
                        <a href="javascript:;">
                            <div class="our_story_wrapper_video_box--bg"
                                style="background-image: url(./assets/images/our-story/our-story-img3.jpg);"></div>
                            <div class="our_story_wrapper_video_box--playicon"></div>
                        </a>
                    </div>
                </div> -->
                <div class="our_story_wrapper_col_5">
                    <div class="our_story_wrapper_img_box">
                        <div class="our_story_wrapper_img_box--bg"
                            [attr.style]="('background-image: url(' +staticUrlForImgNew+ pressMediaShowcaseData?.ShowcaseImage5?.image?.contentUrl +')' ) | safe: 'style'">
                        </div>
                    </div>
                </div>
                <div class="our_story_wrapper_col_6">
                    <div class="our_story_wrapper_img_box--bg"
                        [attr.style]="('background-image: url(' +staticUrlForImgNew+ pressMediaShowcaseData?.ShowcaseImage6?.image?.contentUrl +')' ) | safe: 'style'">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-get-in-touch *ngIf="pressMediaFaqData" [getInTouchData]="pressMediaFaqData" [colorList]="pressMediaColorList" [pageName]="'cec'" [sectionClass]="'pb-0'">
    </app-get-in-touch>
    <app-plan-big-event *ngIf="pressMediaJwcFactsData" [venueDetailsEventsData]="pressMediaJwcFactsData"
        [colorList]="pressMediaColorList" [pageName]="'home'"></app-plan-big-event>
    <app-get-in-touch *ngIf="pressMediaEnquiryData" [getInTouchData]="pressMediaEnquiryData" [colorList]="pressMediaEnquiryColorList" [pageName]="'home'"></app-get-in-touch>
    <!-- <section class="experiences_slider full_width moments"> -->
    <!-- <div class="container"></div>
        <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#A67D54">
            <p class="section_heading--text"><span class="icon icon-instagram" style="--data-icon-color:#A67D54;">@
                    Jio WorldCenter</span></p>
        </div>
        <div class="moments_slider swiper_fraction jwc_swiper_fraction" data-interval="15" data-space="40"
            data-slides="4.35" data-ipad-slides="3.5">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="moments_slider_each">
                            <div class="moments_slider_each--img">
                                <img src="./assets/images/instagram/1.jpg" class="img-fluid" alt="slider 1">
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="moments_slider_each">
                            <div class="moments_slider_each--img">
                                <img src="./assets/images/experiences/3.jpg" class="img-fluid" alt="slider 2">
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="moments_slider_each">
                            <div class="moments_slider_each--img">
                                <img src="./assets/images/events/1.jpg" class="img-fluid" alt="slider 3">
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="moments_slider_each">
                            <div class="moments_slider_each--img">
                                <img src="./assets/images/verticals/1.jpg" class="img-fluid" alt="slider 4">
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="moments_slider_each">
                            <div class="moments_slider_each--img">
                                <img src="./assets/images/heighlights/3.jpg" class="img-fluid" alt="slider 5">
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="moments_slider_each">
                            <div class="moments_slider_each--img">
                                <img src="./assets/images/instagram/2.jpg" class="img-fluid" alt="slider 6">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-arrows">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </div>
        <div class="moments_slider--follow text-center">
            <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">FOLLOW US</span></a>
        </div> -->
    <!-- </section> -->
    <!-- <section class="more_centre_wrapper plr-0" style="--data-more-centre-bgColor1:#F5F2ED;--data-more-centre-titleColor:#4B4847; --data-more_centre_txt_bgColor:#FFFFFF;"> -->
    <!-- <div class="container">
            <div class="section_heading text-center" style="--data-titleColor:#4B4847;--data-lineColor:#A67D54">
                <div class="">
                    <h2 class="section_heading--title ">More At Jio World Centre</h2>


                </div>
            </div>
            <div class="more_centre_slider jwc_swiper_normal" data-interval="8" data-space="24" data-slides="3"
                data-mob="false" data-ipad-slides="2.2" data-ipad-space="20" data-mobile-slides="1.15"
                data-mobile-space="20">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="more_centre_slider_each">
                                <div class="more_centre_slider_each--img">
                                    <img src="./assets/images/more/1.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="more_centre_slider_each_copy" style="--data-coloCode:#24A324">
                                    <span class="more_centre_slider_each_copy--category">Food Festival</span>
                                    <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                                    <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020
                                    </p>
                                    <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="more_centre_slider_each_copy--label">Jio World Plaza</span>
                                    <div class="more_centre_slider_each_copy--btns">
                                        <a href="#" class="cta-prim color-blue icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="more_centre_slider_each">
                                <div class="more_centre_slider_each--img">
                                    <img src="./assets/images/more/2.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                                    <span class="more_centre_slider_each_copy--category">Performing Arts</span>
                                    <h4 class="more_centre_slider_each_copy--title">Kalasri - Bharatnatyam</h4>
                                    <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020
                                    </p>
                                    <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="more_centre_slider_each_copy--label">Nita Mukesh Ambani Arts
                                        Centre</span>
                                    <div class="more_centre_slider_each_copy--btns">
                                        <a href="#" class="cta-prim color-blue icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="more_centre_slider_each">
                                <div class="more_centre_slider_each--img">
                                    <img src="./assets/images/more/3.jpg" class="img-fluid" alt="slider 1">
                                </div>
                                <div class="more_centre_slider_each_copy" style="--data-coloCode:#C92C99">
                                    <span class="more_centre_slider_each_copy--category">Visual Arts</span>
                                    <h4 class="more_centre_slider_each_copy--title">Japanese Food Festival - Nobu</h4>
                                    <p class="more_centre_slider_each_copy--date content3">24th April - 29th April 2020
                                    </p>
                                    <p class="more_centre_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                                    <span class="more_centre_slider_each_copy--label">Nita Mukesh Ambani Arts
                                        Centre</span>
                                    <div class="more_centre_slider_each_copy--btns">
                                        <a href="#" class="cta-prim color-blue icon icon-ticket"><span
                                                class="cta-prim-txt">BUY TICKETS</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-arrows">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
            </div>
        </div> -->
    <!-- </section> -->
</main>
<div class="overlay"></div>