<!-- <app-header [colorList]="cecVLColorList" [pageName]="'cec'"></app-header> -->
<app-sub-header [colorList]="cecVLColorList" [subHeaderList]="cecVlHeaderList" [pageName]="'cec'"></app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <app-sticky-menu></app-sticky-menu>
  <section class="kma_textual" [attr.style]="('--data-kmatextual-bglayer:#F5F2ED; --data-kmatextual-lineColor:#1E478E;') | safe: 'style'">
      <div class="breadcrumbs">
        <div class="breadcrumbs_container">
          <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-convention-centre">Jio World Convention Centre</a> /
          <strong>Venues</strong>
        </div>
      </div>
      <div class="container">
      <div data-mobile-header="About" id="about" class="row align-items-center flex-row-reverse kma_textual_content" *ngIf="ourVenueData">
        <div class="col-lg-5 col-md-5">
          <div class="section_heading blue_arrow section_heading--title section_heading--text content2" [attr.style]="('--data-lineColor:#1E478E') | safe: 'style'">
              <h1 class="custom-seo-heading" [innerHTML]="ourVenueData?.ComponentHeaderTitle?.data"></h1>
              <p [innerHTML]="ourVenueData?.ComponentHeaderSubTitle?.data"></p>
          </div>
          <div class="buttonLink centerbtn" *ngIf="ourVenueData?.HideCTAText?.data == 'false'">
            <a href="javascript:void(0)" routerLink="./" [fragment]="exploreVenId" (click)="callCecVenueCTEvent('pri', ourVenueData?.ComponentHeaderTitle?.data, '', 'explore-our-venues');" class="cta-prim color-blue"><span
                class="cta-prim-txt">{{ourVenueData?.CTAText?.data}}</span></a>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 kma_textual_image">

          <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#004EA8') | safe: 'style'">

            <div class="benefits_slider jwc_swiper_benefits" data-interval="4" data-aos="fade-up" [ngClass]="{'single_image': ourVenueData.cards.length == 1}">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" *ngFor="let item of ourVenueData.cards; let i=index">
                    <div class="benefits_slider_each">
                      <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                        alt="{{item?.ImageAltText?.data}}">
                    </div>
                  </div>
                </div>
              </div>
              <!-- Add Pagination -->
              <div class="swiper-pagination" *ngIf="ourVenueData.cards.length > 1"></div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
  <section data-mobile-header="Explore" class="explorevenues_wrapper plr-0 " [id]="exploreVenId"
     [attr.style]="('--data-explore-bgColor1:#ffffff;--data-explore-bgColor2:#ffffff') | safe: 'style'">

    <div class="container">
      <div class="section_heading text-center section_heading--title section_heading--text content2" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
        <h2>Explore Our Venues</h2>
        <p>Hosting an event? Explore our range of versatile spaces.</p>
      </div>
    </div>
    <div class="container eventFiler" [attr.style]="('--data-eventFiler-bgColor:#F5F2ED') | safe: 'style'">
      <div class="row">
        <div class="filterBy">
          <a href="javascript:void(0);" class="filter">Filters</a>
        </div>

        <div class="sortby">
          <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
            <div class="dropdown--select sort-dropdown">Sort Type</div>
            <div class="dropdown--list" aria-expanded="false" role="list">
              <ul>
                <li tabindex="0" (click)="filterList('sortType')">Sort Type</li>
                <!-- <li tabindex="0">Type</li> -->
                <li tabindex="0" (click)="filterList('size')">Size</li>
                <li tabindex="0" (click)="filterList('capacity')">Capacity</li>
                <!-- <li tabindex="0">Seating</li> -->
              </ul>
            </div>
          </div>
        </div>

        <div class="text-center alignVM peopleBy">
          <label>People</label>
          <input id="peopleRangeSlider" data-slider-id='peopleRangeSlider' type="text" data-slider-min="10"
            data-slider-max="16500" data-slider-step="100" data-slider-value="[10,16500]" data-slider-tooltip="always"
            data-slider-tooltip-split="true" data-slider-ticks-position="bottom" data-slider-ticks-labels="[10,16500]"
            data-slider-ticks-tooltip="true" />
        </div>

        <div class="spaceby">
          <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
            <div class="dropdown--select spaces-dropdown">Spaces</div>
            <div class="dropdown--list" aria-expanded="false" role="list">
              <ul>
                <li tabindex="0" *ngFor="let item of spacesList" (click)="getValue(item)">{{item}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="container" *ngIf="filteredHallsRoomsData">
      <div class="row explorevenues_wrapper_each" [attr.style]="('--data-explore-boxbgColor:#F5F2ED') | safe: 'style'"
        *ngFor="let item of filteredHallsRoomsData; let i = index; let last=last">
        <ng-container
          *ngIf="item && item.HallsHeaderText.data =='Meeting Rooms'; then showMeetingRoomTemplate; else showAllHallTemplate">
        </ng-container>
        <ng-template #showAllHallTemplate>
          <ng-container *ngIf="item.HallsHeaderText.data =='Concourses'; then showConcoursesTemplate; else showCurrentTemplate"></ng-container>
          <ng-template #showCurrentTemplate>
            <div class="col-lg-5 col-md-5 p-0">
              <div class="explorevenues_wrapper_each--img">
                <img src="{{staticUrlForImgNew + item?.HallsImage?.image?.contentUrl}}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                <!-- <a href="javascript:void(0);" class="galleryIcon" (click)="getObjAndIndex(item, (i+1))"
                  [attr.data-target]="'#gallery'+(i+1)" data-toggle="modal"></a> -->
              </div>
            </div>

            <div class="col-lg-7 col-md-7">
              <div class="explorevenues_wrapper_each_copy">
                <h4>{{item?.HallsHeaderText?.data}}</h4>
                <p class="explorevenues_wrapper_each_copy--text content3">{{item?.HallsSubHeaderText?.data}}</p>
                <div class="icons_block">
                  <div class="icons_block_wrapper">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-4 pl-0" *ngIf="item?.FirstInfographicHeaderText?.data">
                          <div class="icons_block_wrapper_each">
                            <div class="icons_block_wrapper_each--icon"> <img
                                src="{{staticUrlForImgNew + item?.FirstInfographicImage?.image?.contentUrl}}"
                                alt="icon1">
                            </div>
                            <div class="icons_block_wrapper_each_copy">
                              <h4 class="icons_block_wrapper_each_copy--number">
                                {{item?.FirstInfographicHeaderText?.data}}
                              </h4>
                              <span class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.FirstInfographicShortText?.data"></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 pl-0" *ngIf="item?.SecondInfographicHeaderText?.data">
                          <div class="icons_block_wrapper_each">
                            <div class="icons_block_wrapper_each--icon"> <img
                                src="{{staticUrlForImgNew + item?.SecondInfographicImage?.image?.contentUrl}}"
                                alt="{{item?.ImageAltText?.data}}">
                            </div>
                            <div class="icons_block_wrapper_each_copy">
                              <h4 class="icons_block_wrapper_each_copy--number">
                                {{item?.SecondInfographicHeaderText?.data}}
                              </h4>
                              <span
                                class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.SecondInfographicShortText?.data"></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4  pl-0" *ngIf="item?.ThirdInfographicHeaderText?.data">
                          <div class="icons_block_wrapper_each">
                            <div class="icons_block_wrapper_each--icon"> <img
                                src="{{staticUrlForImgNew + item?.ThirdInfographicImage?.image?.contentUrl}}"
                                alt="{{item?.ImageAltText?.data}}">
                            </div>
                            <div class="icons_block_wrapper_each_copy">
                              <h4 class="icons_block_wrapper_each_copy--number">
                                {{item?.ThirdInfographicHeaderText?.data}}
                              </h4>
                              <span
                                class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.ThirdInfographicShortText?.data"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="explorevenues_wrapper_each--btns">
                  <a *ngIf="item?.HideFirstCTAText?.data =='false'"
                    class="cta-prim color-blue" href="{{item?.FirstCTALink?.data}}"
                    (click)="callCecVenueCTEvent('pri', item?.HallsHeaderText?.data); _sharedService.checkCTALink($event, item?.FirstCTALink?.data, item?.FirstCTALinkWindowPreference?.data, item?.TypeOfFirstCTALink?.data)">
                    <span class="cta-prim-txt">{{item?.FirstCTAText?.data}}</span></a>
                  <a href="{{item?.SecondCTALink?.data}}" *ngIf="item?.HideSecondCTAText?.data =='false'"
                    class="cta-sec icon rh icon-download-icon color-blue"
                    (click)="callCecVenueCTEvent('download', item?.HallsHeaderText?.data, item?.SecondCTALink?.data); _sharedService.checkCTALink($event, item?.SecondCTALink?.data, '_self', 'PDF', pdfName)">
                    <span class="cta-prim-txt">{{item?.SecondCTAText?.data}}</span></a>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-container swiper-tab"> -->
              <!-- <div class="explorevenues_expand">
                <div class="container">
                  <div class="row">
                    <h4 class="explorevenues_expand--title">{{item?.SubHallsMainHeader?.data}}</h4>
                    <div class="swiper-pagination"></div>
                    <a href="javascript:;" class="cta-sec icon icon-expand icon-arrow-down" data-toggle="collapse"
                      [attr.data-target]="'#collapseBox_'+(i+1)"><span class="cta-prim-txt">Expand</span></a>
                  </div>
                </div>
              </div>
              <div class="swiper-arrows">
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
              <div id="collapseBox_{{i+1}}" class="swiper-wrapper expandcard collapse">
                <div class="swiper-slide" [attr.data-title]="innerItem?.TabHeaderName?.data"
                  *ngFor="let innerItem of item?.cards; let j = index">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 p-0">
                        <div class="explorevenues_wrapper_each--img"> <img
                            src="{{staticUrlForImgNew + innerItem?.SubHallsImage?.image?.contentUrl}}"
                            class="img-fluid" alt="Venue 1"> </div>
                      </div>
                      <div class="col-lg-6 col-md-6 p-0">
                        <div class="explorevenues_wrapper_each_copy">
                          <h4>{{innerItem?.TabHeaderName?.data}}</h4>
                          <div class="icons_block">
                            <div class="icons_block_wrapper">
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-12 col-lg-6 pl-0">
                                    <div class="icons_block_wrapper_each">
                                      <div class="icons_block_wrapper_each--icon"> <img
                                          src="{{staticUrlForImgNew + innerItem?.SubHallsFirstInfographicImage?.image?.contentUrl}}"
                                          alt="icon1"> </div>
                                      <div class="icons_block_wrapper_each_copy">
                                        <h4 class="icons_block_wrapper_each_copy--number">
                                          {{innerItem?.SubHallsFirstInfographicHeaderText?.data}}</h4>
                                        <span
                                          class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.SubHallsFirstInfographicShortText?.data"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12 col-lg-6 pl-0">
                                    <div class="icons_block_wrapper_each">
                                      <div class="icons_block_wrapper_each--icon"> <img
                                          src="{{staticUrlForImgNew + innerItem?.SubHallsSecondInfographicImage?.image?.contentUrl}}"
                                          alt="icon2"> </div>
                                      <div class="icons_block_wrapper_each_copy">
                                        <h4 class="icons_block_wrapper_each_copy--number">
                                          {{innerItem?.SubHallsSecondInfographicHeaderText?.data}}</h4>
                                        <span
                                          class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.SubHallsSecondInfographicShortText?.data"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="cta-holder">
                            <a href="{{innerItem?.SubHallsFirstCTALink?.data}}" *ngIf="innerItem?.SubHallsHideFirstCTAText?.data =='false'"
                              class="cta-prim color-blue emp-btn"
                              (click)="_sharedService.checkCTALink($event, innerItem?.SubHallsFirstCTALink?.data, innerItem?.SubHallsFirstCTALinkWindowPreference?.data, innerItem?.SubHallsTypeOfFirstCTALink?.data)">
                              <span class="cta-prim-txt">{{innerItem?.SubHallsFirstCTAText?.data}}</span>
                            </a>
                            <a href="{{innerItem?.SubHallsSecondCTALink?.data}}" *ngIf="innerItem?.SubHallsHideSecondCTAText?.data =='false'"
                              class="cta-sec color-blue"
                              (click)="_sharedService.checkCTALink($event, innerItem?.SubHallsSecondCTALink?.data, innerItem?.SubHallsSecondCTALinkWindowPreference?.data, innerItem?.SubHallsTypeOfSecondCTALink?.data)">
                              <span class="cta-sec-txt">{{innerItem?.SubHallsSecondCTAText?.data}}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            <!-- </div> -->
          </ng-template>
        </ng-template>
        <ng-template #showMeetingRoomTemplate>
          <!-- <div class="row explorevenues_wrapper_each" style="--data-explore-boxbgColor:#F5F2ED"> -->
          <div class="col-lg-5 col-md-5 p-0">
            <div class="explorevenues_wrapper_each--img"> <img
                src="{{staticUrlForImgNew + item?.HallsImage?.image?.contentUrl}}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
            </div>
          </div>
          <div class="col-lg-7 col-md-7">
            <div class="explorevenues_wrapper_each_copy">
              <h4>{{item?.HallsHeaderText?.data}}</h4>
              <p class="explorevenues_wrapper_each_copy--text content3">
                {{item?.HallsSubHeaderText?.data}}</p>
              <div class="icons_block">
                <div class="icons_block_wrapper">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-4 pl-0">
                        <div class="icons_block_wrapper_each">
                          <div class="icons_block_wrapper_each--icon"> <img
                              src="{{staticUrlForImgNew + item?.FirstInfographicImage?.image?.contentUrl}}" alt="{{item?.ImageAltText?.data}}">
                          </div>
                          <div class="icons_block_wrapper_each_copy">
                            <h4 class="icons_block_wrapper_each_copy--number">
                              {{item?.FirstInfographicHeaderText?.data}}</h4>
                            <span
                              class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.FirstInfographicShortText?.data"></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 pl-0">
                        <div class="icons_block_wrapper_each">
                          <div class="icons_block_wrapper_each--icon"> <img
                              src="{{staticUrlForImgNew + item?.SecondInfographicImage?.image?.contentUrl}}"
                              alt="icon2"> </div>
                          <div class="icons_block_wrapper_each_copy">
                            <h4 class="icons_block_wrapper_each_copy--number">
                              {{item?.SecondInfographicHeaderText?.data}}</h4>
                            <span
                              class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.SecondInfographicShortText?.data"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="explorevenues_wrapper_each--btns">

                <a href="{{item?.FirstCTALink?.data}}" *ngIf="item?.HideFirstCTAText?.data =='false'" class="cta-prim color-blue"
                  (click)="callCecVenueCTEvent('pri', item?.HallsHeaderText?.data); _sharedService.checkCTALink($event, item?.FirstCTALink?.data, item?.FirstCTALinkWindowPreference?.data, item?.TypeOfFirstCTALink?.data)">
                  <span class="cta-prim-txt">{{item?.FirstCTAText?.data}}</span></a>
                <a href="javascript:void(0)" *ngIf="item?.HideSecondCTAText?.data =='false'"
                  class="cta-sec icon rh icon-download-icon color-blue"
                  (click)="callCecVenueCTEvent('download', item?.HallsHeaderText?.data, item?.SecondCTALink?.data); _sharedService.checkCTALink($event, item?.SecondCTALink?.data, '_self', 'PDF', pdfName)">
                  <span class="cta-prim-txt">{{item?.SecondCTAText?.data}}</span></a>
              </div>
            </div>
          </div>
          <!-- <div class="explorevenues_expand"> -->
            <!-- <div class="container">
              <div class="row">
                <h4 class="explorevenues_expand--title">{{item?.SubHallsMainHeader?.data}}</h4>
                <ul class="nav nav-tabs explore_expand_list" role="tablist">
                  <li class="nav-item" *ngFor="let item of item.cards; let i = index"> <a class="nav-link"
                       data-toggle="tab" [attr.href]="'#'+ (i+1)" role="tab"
                      (click)="getObjAndIndex(item, (i+1))">{{item?.TabHeaderText?.data}}</a> </li>
                </ul>
                <a (click)="getObjAndIndex(item.cards[0], 1)" class="cta-sec icon icon-expand icon-arrow-down"
                  data-toggle="collapse" data-target="#collapseBox_fouth"><span class="cta-prim-txt">Expand</span></a>
              </div>
            </div> -->
          <!-- </div> -->
          <!-- <div id="collapseBox_fouth" class="collapse expandcard"> -->
            <!-- <div class="tab-content">
              <div class="tab-pane active" [id]="clickedId" role="tabpanel">
                <div class="tabslider" data-space="30" data-mobile-space="20" data-slides="2.7"
                  data-mobile-slides="1.1">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let innerItem of roomsObj.AddCardsDetails; let k = index">
                        <div class="explorevenues_wrapper_each_copy cardSlider_card">
                          <h4>{{innerItem?.CardHeaderText?.data}}</h4>
                          <div class="icons_block">
                            <div class="icons_block_wrapper">
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-12 col-lg-6 pl-0">
                                    <div class="icons_block_wrapper_each">
                                      <div class="icons_block_wrapper_each--icon"> <img
                                          src="{{staticUrlForImgNew + innerItem?.SubHallsFirstInfographicImage?.image?.contentUrl}}"
                                          alt="icon1"> </div>
                                      <div class="icons_block_wrapper_each_copy">
                                        <h4 class="icons_block_wrapper_each_copy--number">
                                          {{innerItem?.SubHallsFirstInfographicHeaderText?.data}}</h4>
                                        <span
                                          class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.SubHallsFirstInfographicShortText?.data"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12 col-lg-6 pl-0">
                                    <div class="icons_block_wrapper_each">
                                      <div class="icons_block_wrapper_each--icon"> <img
                                          src="{{staticUrlForImgNew + innerItem?.SubHallsSecondInfographicImage?.image?.contentUrl}}"
                                          alt="icon2"> </div>
                                      <div class="icons_block_wrapper_each_copy">
                                        <h4 class="icons_block_wrapper_each_copy--number">
                                          {{innerItem?.SubHallsSecondInfographicHeaderText?.data}}</h4>
                                        <span
                                          class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.SubHallsSecondInfographicShortText?.data"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="cta-holder">
                            <a href="{{innerItem?.SubHallsFirstCTALink?.data}}" *ngIf="innerItem?.SubHallsHideFirstCTAText?.data =='false'"
                              class="cta-prim color-blue emp-btn"
                              (click)="_sharedService.checkCTALink($event, innerItem?.SubHallsFirstCTALink?.data, innerItem?.SubHallsFirstCTALinkWindowPreference?.data, innerItem?.SubHallsTypeOfFirstCTALink?.data)">
                              <span class="cta-prim-txt">{{innerItem?.SubHallsFirstCTAText?.data}}</span>
                            </a>
                            <a href="{{innerItem?.SubHallsSecondCTAText?.data}}" *ngIf="innerItem?.SubHallsHideSecondCTAText?.data =='false'"
                              class="cta-sec color-blue"
                              (click)="_sharedService.checkCTALink($event, innerItem?.SubHallsSecondCTALink?.data, innerItem?.SubHallsSecondCTALinkWindowPreference?.data, innerItem?.SubHallsTypeOfSecondCTALink?.data)">
                              <span class="cta-sec-txt">{{innerItem?.SubHallsSecondCTAText?.data}}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-arrows">
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                  </div>
                </div>

              </div>
            </div> -->
          <!-- </div> -->
          <!-- </div> -->
        </ng-template>
        <ng-template #showConcoursesTemplate>
          <div class="col-lg-5 col-md-5 p-0">
            <div class="explorevenues_wrapper_each--img"> <img src="{{staticUrlForImgNew + item?.HallsImage?.image?.contentUrl}}" class="img-fluid"
                alt="{{item?.ImageAltText?.data}}"> </div>
          </div>
          <div class="col-lg-7 col-md-7">
            <div class="explorevenues_wrapper_each_copy">
              <h4>{{item?.HallsHeaderText?.data}}</h4>
              <p class="explorevenues_wrapper_each_copy--text content3">{{item?.HallsSubHeaderText?.data}}</p>
              <div class="icons_block">
                <div class="icons_block_wrapper">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-4 pl-0">
                        <div class="icons_block_wrapper_each">
                          <div class="icons_block_wrapper_each--icon"> <img src="{{staticUrlForImgNew + item?.FirstInfographicImage?.image?.contentUrl}}"
                              alt="icon2"> </div>
                          <div class="icons_block_wrapper_each_copy">
                            <h4 class="icons_block_wrapper_each_copy--number">
                              {{item?.FirstInfographicHeaderText?.data}}
                            </h4>
                            <span
                              class="icons_block_wrapper_each_copy--name" [innerHTML]="item?.FirstInfographicShortText?.data"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="explorevenues_wrapper_each--btns"> 
                <a href="{{item?.FirstCTALink?.data}}" *ngIf="item?.HideFirstCTAText?.data =='false'"
                    class="cta-prim color-blue"
                    (click)="callCecVenueCTEvent('pri', item?.HallsHeaderText?.data); _sharedService.checkCTALink($event, item?.FirstCTALink?.data, item?.FirstCTALinkWindowPreference?.data, item?.TypeOfFirstCTALink?.data)">
                    <span class="cta-prim-txt">{{item?.FirstCTAText?.data}}</span></a>
                </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </section>
  <div class="modal fade image_gallery_popup" [id]="modalId" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="modalObj">
    <div class="wrapper modal-dialog" role="document">
      <div class="modal-content">
        <a href="javascript:void(0);" class="close" data-dismiss="modal"></a>
        <div class="jwc_image_slider gallery_popup_slider popup_slider single_image" data-space="0" data-slides="1">

          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="jwc_image_slider_each">
                  <img src="{{staticUrlForImgNew + modalObj.PopupImage?.image?.contentUrl}}" class="img-fluid" alt="{{modalObj.PopupImage?.ImageAltText?.data}}">
                </div>
              </div>
            </div>
          </div>
          <!-- Add Arrows -->
          <div class="swiper-arrows">
            <div class="swiper-button-next swiper-button-disabled"></div>
            <div class="swiper-button-prev swiper-button-disabled"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div data-mobile-header="Plan" [id]="planYourEveId">
    <app-plan-big-event *ngIf="planNextEventData" [venueDetailsEventsData]="planNextEventData"
      [colorList]="cecVLColorList" [pageName]="'cec'"></app-plan-big-event>
  </div>
  <!-- <section class="events_wrapper plr-0 pb-0" id="whats-on"
    style="--data-events-bgColor1:#ffffff;--data-events-bgColor2:#ffffff;--data-events-lineColor:#1E478E;--data-event-arrowColor:#070707">
    <div class="container">
      <div class="section_heading text-center text-white" style="--data-titleColor:#4B4847;--data-lineColor:#1E478E">
        <div class="">
          <h2 class="section_heading--title">What’s On</h2>
        </div>
      </div>
    </div>

    <div class="events_slider jwc_swiper_normal" data-interval="8" data-space="120" data-slides="2"
      data-ipad-slides="1.65" data-ipad-space="20" data-mobile-slides="1.2" data-mobile-space="20">
      <div class="events_slider_each events_types">
        <div class="">
        </div>
        <div class="">
          <a href="javascript:void(0)" class="cta-sec color-blue"><span class="cta-sec-txt ">VIEW CALENDAR</span></a>
        </div>
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="events_slider_each">
              <div class="events_slider_each--img">
                <img src="./assets/images/events/1.jpg" class="img-fluid" alt="slider 1">
              </div>
              <div class="events_slider_each_copy border_btm_red">
                <div class="events_slider_each_copy--box">
                  <span class="events_slider_each_copy--category">Performing Arts</span>
                  <h4 class="events_slider_each_copy--title">Kalasri - Bharatnatyam Lorem ipsum</h4>
                  <p class="events_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="events_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="events_slider_each_copy--label">The Arts Centre</span>
                  <div class="events_slider_each_copy--btns">
                    <a href="javascript:void(0)" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                    <a href="javascript:void(0)" class="cta-sec color-blue"><span class="cta-sec-txt">KNOW MORE
                      </span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="events_slider_each">
              <div class="events_slider_each--img">
                <img src="./assets/images/events/2.jpg" class="img-fluid" alt="slider 1">
              </div>
              <div class="events_slider_each_copy border_btm_blue">
                <div class="events_slider_each_copy--box">
                  <span class="events_slider_each_copy--category">Performing Arts</span>
                  <h4 class="events_slider_each_copy--title">TEDx - Amit Karla (How to avoid food waste)
                  </h4>
                  <p class="events_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="events_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="events_slider_each_copy--label">The Arts Centre</span>
                  <div class="events_slider_each_copy--btns">
                    <a href="javascript:void(0)" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                    <a href="javascript:void(0)" class="cta-sec color-blue"><span class="cta-sec-txt">KNOW MORE
                      </span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="events_slider_each">
              <div class="events_slider_each--img">
                <img src="./assets/images/events/3.jpg" class="img-fluid" alt="slider 1">
              </div>
              <div class="events_slider_each_copy border_btm_pink">
                <div class="events_slider_each_copy--box">
                  <span class="events_slider_each_copy--category">Performing Arts</span>
                  <h4 class="events_slider_each_copy--title">The Russian Ballet</h4>
                  <p class="events_slider_each_copy--date content3">24th April - 29th April 2020</p>
                  <p class="events_slider_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <span class="events_slider_each_copy--label">The Arts Centre</span>
                  <div class="events_slider_each_copy--btns">
                    <a href="javascript:void(0)" class="cta-prim color-blue icon icon-ticket"><span class="cta-prim-txt">BUY
                        TICKETS</span></a>
                    <a href="javascript:void(0)" class="cta-sec color-blue"><span class="cta-sec-txt">KNOW MORE
                      </span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </section> -->
  <div data-mobile-header="FAQs" id="faqs">
    <app-faq-section *ngIf="faqData" [faqData]="faqData" [colorList]="cecVLColorList"></app-faq-section>
  </div>
</main>
<!-- <app-footer [colorList]="cecVLColorList"></app-footer> -->
<div class="overlay"></div>