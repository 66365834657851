import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-cec-walkthrough',
  templateUrl: './cec-walkthrough.component.html',
  styleUrls: ['./cec-walkthrough.component.css']
})
export class CecWalkthroughComponent implements OnInit, OnDestroy {
  subscription;
  walkthroughBannerData;
  walkthroughPavilionData;
  walkthroughInfographicsData;
  walkthroughSliderData;
  walkthroughHighlightsData;
  walkthroughExploreData;
  walkthroughAskUsData;
  pdfName;
  breadcrumbName;
  urlForHall;
  cecWalkthroughHeaderList;
  bannerImgSrc;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  cecWalkthroughColorList = {
    footerIconColor: '#1E478E', footerHoverColor: '#F2F5FA', footerFillColor: '#F2F5FA', headerDataHoverColor: "#1E478E", dataGetintouchTitleColor: "#4B4847", dataGetintouchLineColor: "#004EA8"
  };
  walkthroughKeyArray = [
    'CEC-WALKTHROUGH-BANNER',
    'CEC-WALKTHROUGH-OUR-PAVILION',
    'CEC-WALKTHROUGH-INFOGRAPHICS',
    'CEC-WALKTHROUGH-SLIDER',
    'CEC-WALKTHROUGH-HIGHLIGHTS-INFOGRAPHICS',
    'CEC-WALKTHROUGH-EXPLORE_MORE_VENUES',
    'CEC-WALKTHROUGH-ASK-US-ANYTHING'
  ];
  exploreVenuesId: string;
  planEveId: string;
  exploreHallsId: any;
  currentUrl;
  previousUrl;
  pageSource: string;
  walkthruPagevideoUrl: any;


  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router: Router,
    private titleService: Title) {
    let flag = true;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const fragment: string = this.activatedRoute.snapshot.fragment;
        if (!fragment) { jQuery('.loader').show(); flag = true; }
        if (flag) {
          this.walkthroughBannerData='';
          this.getKeys();
          this.cecWalkthroughHeaderList = (window.innerWidth > 991) ? ["Plan your event", "Explore More Venues"] : [this.breadcrumbName, "Event Services", "More Venues", 'Contact'];
          this.exploreVenuesId = (window.innerWidth > 991) ? 'explore-more-venues' : 'more-venues';
          this.planEveId = (window.innerWidth > 991) ? 'plan-your-event' : 'contact';
          this.exploreHallsId = this.breadcrumbName.replace(/\s/g, '-').toLowerCase();
          this.getWalkthroughPageContent();
        }
        flag = false;
      }

    });
  }

  ngOnInit() {
  }

  getKeys() {
    switch (this.activatedRoute.snapshot.params['room']) {
      case 'convention-halls':
        this.pdfName = 'jio-world-convention-centre-convention-halls-factsheet';
        this.breadcrumbName = 'Convention Halls';
        this.urlForHall = 'convention-halls';
        this.bannerImgSrc = './assets/images/cec-walkthrough/exhibition-hall-walkthrough-kma.jpg';
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'Convention Halls Walkthrough';
        return this.walkthroughKeyArray = [
          'CEC-WALKTHROUGH-BANNER-CONVENTION-HALLS', 'CEC-WALKTHROUGH-OUR-PAVILION-CONVENTION-HALLS', 'CEC-WALKTHROUGH-INFOGRAPHICS-CONVENTION-HALLS', 'CEC-WALKTHROUGH-SLIDER-CONVENTION-HALL',
          'CEC-WALKTHROUGH-HIGHLIGHTS-INFOGRAPHICS', 'CEC-WALKTHROUGH-EXPLORE-MORE-VENUES-CONVENTION-HALLS', 'CEC-WALKTHROUGH-ASK-US-ANYTHING'
        ];
      case 'banquet-halls':
      case 'banquet-hall':
        this.pdfName = 'jio-world-convention-centre-ballroom-factsheet';
        this.breadcrumbName = 'Banquet Halls';
        this.urlForHall = 'banquet-halls';
        this.bannerImgSrc = './assets/images/cec-walkthrough/banquet-hall-walkthrough-kma.jpg';
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'Banquet Halls Walkthrough';
        return this.walkthroughKeyArray = [
          'CEC-WALKTHROUGH-BANNER-BANQUET-HALLS', 'CEC-WALKTHROUGH-OUR-PAVILION-BANQUET-HALLS', 'CEC-WALKTHROUGH-INFOGRAPHICS-BANQUET-HALLS', 'CEC-WALKTHROUGH-SLIDER-BANQUET-HALLS',
          'CEC-WALKTHROUGH-HIGHLIGHTS-INFOGRAPHICS', 'CEC-WALKTHROUGH-EXPLORE-MORE-VENUES-BANQUET-HALLS', 'CEC-WALKTHROUGH-ASK-US-ANYTHING'
        ];
      case 'meeting-rooms':
        this.pdfName = 'jio-world-convention-centre-meeting-rooms-factsheet';
        this.breadcrumbName = 'Meeting Rooms';
        this.urlForHall = 'meeting-rooms';
        this.bannerImgSrc = './assets/images/cec-walkthrough/exhibition-hall-walkthrough-kma.jpg';
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'Meeting Rooms Walkthrough';
        return this.walkthroughKeyArray = [
          'CEC-WALKTHROUGH-BANNER-MEETING-ROOMS', 'CEC-WALKTHROUGH-OUR-PAVILION-MEETING-ROOMS', 'CEC-WALKTHROUGH-INFOGRAPHICS-MEETING-ROOMS', 'CEC-WALKTHROUGH-SLIDER-MEETING-ROOMS',
          'CEC-WALKTHROUGH-HIGHLIGHTS-INFOGRAPHICS', 'CEC-WALKTHROUGH-EXPLORE-MORE-VENUES-MEETING-ROOMS', 'CEC-WALKTHROUGH-ASK-US-ANYTHING'
        ];
      default:
        this.pdfName = 'jio-world-convention-centre-exhibition-halls-factsheet';
        this.breadcrumbName = 'Exhibition Halls';
        this.urlForHall = 'exhibit-halls';
        this.bannerImgSrc = './assets/images/cec-walkthrough/exhibition-hall-walkthrough-kma.jpg';
        this.activatedRoute.snapshot.data["CTLinkClicked"] = 'Exhibition Halls Walkthrough';
        return this.walkthroughKeyArray = [
          'CEC-WALKTHROUGH-BANNER', 'CEC-WALKTHROUGH-OUR-PAVILION', 'CEC-WALKTHROUGH-INFOGRAPHICS', 'CEC-WALKTHROUGH-SLIDER',
          'CEC-WALKTHROUGH-HIGHLIGHTS-INFOGRAPHICS', 'CEC-WALKTHROUGH-EXPLORE_MORE_VENUES', 'CEC-WALKTHROUGH-ASK-US-ANYTHING'
        ];
    }
  }
  getWalkthroughPageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.walkthroughKeyArray).subscribe(
      (data: any) => {
        this.walkthroughBannerData = data[0] ? data[0].data : data[0];
        this.walkthroughPavilionData = data[1] ? data[1].data : data[1];
        this.walkthroughInfographicsData = data[2] ? data[2].data : data[2];
        this.walkthroughSliderData = data[3] ? data[3].data : data[3];
        this.walkthroughHighlightsData = data[4] ? data[4].data : data[4];
        this.walkthroughExploreData = data[5] ? data[5].data : data[5];
        this.walkthroughAskUsData = data[6] ? data[6].data : data[6];
        this._sharedService.initializeCustomJs();
        this._sharedService.callNormalSlider();
        this._sharedService.load360Function();
        this.cecWalkthroughHeaderList = this._sharedService.subHeaderItems(this.cecWalkthroughHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  callCecWlkthruExploreSectionCTEvent(value, sectionTitle) {
    switch (value) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle);
        break;
      case 'download':
        this._sharedService.callCTEvent('Download_Links', sectionTitle, this.pdfName);
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
