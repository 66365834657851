import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../../../shared/services/shared.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-cec-venue-details-floor-plan',
  templateUrl: './cec-venue-details-floor-plan.component.html',
  styleUrls: ['./cec-venue-details-floor-plan.component.css']
})
export class CecVenueDetailsFloorPlanComponent implements OnInit {
  @Input() cecVenueDetailsFloorPlansData;
  // @Input() pdfName;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  downloadedDocName: any;
  constructor(public _sharedService: SharedService, private titleService: Title) { }

  ngOnInit() {
    this._sharedService.callVenueList();
  }

  callCecFloorPlanCTEvent(val, sectionName, docName?) {
    switch (val){
      case 'sec':
        this._sharedService.callCTEvent('Section_Secondary_Links', sectionName);
      break;
      case 'download':
        this.downloadedDocName = this._sharedService.getPdfName(docName);
        this._sharedService.callCTEvent('Download_Links', sectionName, this.downloadedDocName);
        break;
    }
  }

}
