import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { MetaService } from "../meta.service";
import { SharedService } from "../shared.service";


@Injectable({
    providedIn: 'root'
})
export class cecEventResolver implements Resolve<any> {
    constructor(private _sharedService: SharedService, private customMetaService: MetaService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        //console.log("#####", route.params);
        let slugId = '';
        let keyList;
        if (route.params.cecId && route.params.cecId != '') {
            slugId = route.params.cecId;
            keyList = ['CEC-UPCOMING-EVENT'];
        } else if (route.params.jioId && route.params.jioId != '') {
            slugId = route.params.jioId;
            keyList = ['JWC-UPCOMING-EVENT'];
        }

        if(!keyList) return of([]);
        return this._sharedService.getCommonService().getlandingUrl(keyList)
            .pipe(
                map((res: Array<any>) => {
                    if (Array.isArray(res) && res.length > 0 && res[0].data) {
                        const currentEvent = res[0].data.cards.find((item) => {
                            return item.SlugUrl.data === slugId
                        });
                        const input = {
                            metaTitle: currentEvent?.EventMetaTitle?.data,
                            metaDesc:  currentEvent?.EventMetaDescription?.data,
                            metaLogo: currentEvent?.EventMetaLogo?.image?.contentUrl,
                            srcipt: currentEvent?.EventSchemaScript?.data,
                            canonicalUrl: currentEvent?.CanonicalURL?.data
                        }
                        this.customMetaService.updatePageMeta(input); 
                        return currentEvent;
                    }  
                    else
                        return res;
                })
            )
    }
}