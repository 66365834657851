import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timer } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  otpForm: FormGroup;
  submitted = false;
  screen;
  timeLeft: number = 300;
  min;
  sec;
  disableOtpVerBtn = false;
  timerSubscribtion;
  otpValue;
  mobileNo;
  showLoginErr;
  showOtpErr = '';
  otpResent = false;
  userDetails;
  @Output() isOtpVerified = new EventEmitter();
  @ViewChild('ngOtpInput') ngOtpInputRef:any;
  @Input() set selectedScreen(value) {
    if(value == 'sendOtp') {this.userDetails = {};}else {this.userDetails = JSON.parse(this._sharedService.getStorageService().getSessionData('userDetails'));}
    if(value) this.screen = value;
  };

  constructor(private _sharedService: SharedService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.screen = 'sendOtp';
    this.loginForm = this.formBuilder.group({
      phoneNoInput: ['', [Validators.minLength(10), Validators.required]]
    });
    this.otpForm = this.formBuilder.group({
      otpInput: ['', [Validators.minLength(6), Validators.required]]
    });
  }

  get loginFormControls() { return this.loginForm.controls; }
  sendOtp() {
    let obj = {
      "mobile_number": this.mobileNo
    }
    this._sharedService.getCommonService().submitMobileDetails(obj).subscribe((data: any) => {
        this.screen = 'verifyOtp';
        this.disableOtpVerBtn = false;
        this.startTimer()
      },
      (error) => {
        this.showLoginErr = true;
        console.log("Try after some time..");
      }
    )
  }

  loginSubmit(form) {
    this.showLoginErr = false;
    this.submitted = true;
    if (form.invalid) {
      return;
    }
    this.mobileNo = this.loginFormControls.phoneNoInput.value;
    this.sendOtp();
  }

  startTimer() {
    const source = timer(1000, 1000);
    let timerVal;
    this.timerSubscribtion = source.subscribe(val => {
      timerVal = this.timeLeft - val;
      if(val < 301) {
        this.min = (Math.floor(timerVal/60) >= 10)? Math.floor(timerVal/60) : '0'+ Math.floor(timerVal/60);
        this.sec = (timerVal%60 >= 10)?timerVal%60 : '0'+timerVal%60; 
      }else {
        this.disableOtpVerBtn = true;
      }
    });
  }

  onOtpChange($event) {
    this.otpValue = '';
    if($event.length == 6)this.otpValue = $event;
  }

  verifyOtp() {
    if(!this.otpValue) return;
    this.otpForm.patchValue({
        otpInput : this.otpValue
    });
    let obj = {
      "mobile_number": this.mobileNo,
      "otp": +this.otpValue
    }
    this._sharedService.getCommonService().validateOtp(obj).subscribe((data: any) => {
      if(data.message == 'otp verified successfully') {
        this.screen = 'registration';
        this.userDetails = {
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email_id,
          mobileNo: this.mobileNo,
          vehicleDetails : data.vehicle_details
        }
        this.loginForm.reset();
        this.mobileNo = '';
        this.submitted = false;
        this._sharedService.getStorageService().setSessionData('isLoggedIn' , true);
        this._sharedService.getStorageService().setSessionData('userDetails', JSON.stringify(this.userDetails));
        this.isOtpVerified.emit(true);
        this.timerSubscribtion.unsubscribe();
        this.timeLeft = 300;
      }else {
        this.showOtpErr = 'Please Enter correct OTP';
      }
    },
    (error) => {
      this.showOtpErr = 'Server Error. Try after some time..';
      console.log("Try after some time..");
    }
  )
    
  }
  resendOtp() {
    // call otp api
    if(this.mobileNo) this.sendOtp();
    this.otpResent = true;
    if(this.min !== '00') {
      setTimeout(()=> {
        this.otpResent = false;
      }, 60000)
    }
  }

  ngOnDestroy() {
    if(this.timerSubscribtion) this.timerSubscribtion.unsubscribe();
  }

}
