<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <div class="jwc_404 ">
    <section class="jwc_404_head" data-aos="fade-up">
      <div class="container">
        <div class="section_heading section_heading--title section_heading--text text-center fullwidth" [attr.style]="('--data-lineColor:#C69E67') | safe: 'style'">
            <h2>Sorry, we couldn’t find what you’re looking for.</h2>
            <p> You may have clicked on a broken link, 
              or the page you’re looking for may have expired. Refresh the page or try again later.
            </p>
        </div>
        <div class="jwc_404_links">
          <a href="javascript:void(0);" routerLink="/" class="cta-prim color-gold"><span
              class="cta-prim-txt">Back to home</span></a>
          <!-- <a href="javascript:void(0);" class="cta-prim color-gold"><span class="cta-prim-txt">Start a search</span></a> -->
        </div>
        <!-- <div class="jwc_404_other_links">
          <p class="content2">If you followed a link that's not working, please let us know </p>
          <a href="javascript:void(0);" (click)="backToHome()" class="cta-sec color-gold"><span
              class="cta-prim-txt">Back to home</span></a>
        </div> -->
      </div>
    </section>
    <!-- <section class="jwc_404_helpful">
      <div class="container">
        <div class="section_heading " style="--data-lineColor:#C69E67">
          <div class="">
            <h2 class="section_heading--title">Helpful Links</h2>
          </div>
        </div>
        <div class="jwc_404_helpful_list">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="jwc_404_helpful_list_each" data-aos="fade-up">
                <div class="jwc_404_helpful_list_each--img">
                  <img src="./assets/images/404/1.jpg" class="img-fluid" alt="">
                </div>
                <div class="jwc_404_helpful_list_each_copy">
                  <h4 class="jwc_404_helpful_list_each_copy--title">Brands</h4>
                  <p class="jwc_404_helpful_list_each_copy--text content3">Lorem ipsum dolor sitamet,
                    consetetur
                    sadipscing elitr, sed diam.</p>
                  <a href="javascript:void(0);" class="cta-sec color-gold"><span class="cta-sec-txt">Read
                      More</span></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="jwc_404_helpful_list_each" data-aos="fade-up">
                <div class="jwc_404_helpful_list_each--img">
                  <img src="./assets/images/404/1.jpg" class="img-fluid" alt="">
                </div>
                <div class="jwc_404_helpful_list_each_copy">
                  <h4 class="jwc_404_helpful_list_each_copy--title">About</h4>
                  <p class="jwc_404_helpful_list_each_copy--text content3">Lorem ipsum dolor sitamet,
                    consetetur
                    sadipscing elitr, sed diam.</p>
                  <a href="javascript:void(0);" class="cta-sec color-gold"><span class="cta-sec-txt">Read
                      More</span></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="jwc_404_helpful_list_each" data-aos="fade-up">
                <div class="jwc_404_helpful_list_each--img">
                  <img src="./assets/images/404/1.jpg" class="img-fluid" alt="">
                </div>
                <div class="jwc_404_helpful_list_each_copy">
                  <h4 class="jwc_404_helpful_list_each_copy--title">Plan a Visit</h4>
                  <p class="jwc_404_helpful_list_each_copy--text content3">Lorem ipsum dolor sitamet,
                    consetetur
                    sadipscing elitr, sed diam.</p>
                  <a href="javascript:void(0);" class="cta-sec color-gold"><span class="cta-sec-txt">Read
                      More</span></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="jwc_404_helpful_list_each" data-aos="fade-up">
                <div class="jwc_404_helpful_list_each--img">
                  <img src="./assets/images/404/1.jpg" class="img-fluid" alt="">
                </div>
                <div class="jwc_404_helpful_list_each_copy">
                  <h4 class="jwc_404_helpful_list_each_copy--title">What’s On</h4>
                  <p class="jwc_404_helpful_list_each_copy--text content3">Lorem ipsum dolor sitamet,
                    consetetur
                    sadipscing elitr, sed diam.</p>
                  <a href="javascript:void(0);" class="cta-sec color-gold"><span class="cta-sec-txt">Read
                      More</span></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="jwc_404_helpful_list_each" data-aos="fade-up">
                <div class="jwc_404_helpful_list_each--img">
                  <img src="./assets/images/404/1.jpg" class="img-fluid" alt="">
                </div>
                <div class="jwc_404_helpful_list_each_copy">
                  <h4 class="jwc_404_helpful_list_each_copy--title">Shopping</h4>
                  <p class="jwc_404_helpful_list_each_copy--text content3">Lorem ipsum dolor sitamet,
                    consetetur
                    sadipscing elitr, sed diam.</p>
                  <a href="javascript:void(0);" class="cta-sec color-gold"><span class="cta-sec-txt">Read
                      More</span></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="jwc_404_helpful_list_each" data-aos="fade-up">
                <div class="jwc_404_helpful_list_each--img">
                  <img src="./assets/images/404/1.jpg" class="img-fluid" alt="">
                </div>
                <div class="jwc_404_helpful_list_each_copy">
                  <h4 class="jwc_404_helpful_list_each_copy--title">Dining</h4>
                  <p class="jwc_404_helpful_list_each_copy--text content3">Lorem ipsum dolor
                    sitamet, consetetur
                    sadipscing elitr, sed diam.</p>
                  <a href="javascript:void(0);" class="cta-sec color-gold"><span class="cta-sec-txt">Read
                      More</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</main>
<!-- <app-footer [colorList]="notFoundColorList"></app-footer> -->
<div class="overlay"></div>