import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logistics-services',
  templateUrl: './logistics-services.component.html',
  styleUrls: ['./logistics-services.component.css']
})
export class LogisticsServicesComponent implements OnInit {
  logisticsColorList = { dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#A67D54', benefitsLineColor: "#A67D54", dataExploreBoxBgColor: '#ffffff' };
  pdfName;
  logisticsKeyArray;
  subscription;
  logisticsBannerData;
  logisticsGetInTouchData;
  logisticsGettingStartedData;
  logisticsServicesData;
  logisticsServicesDownloadsList;
  logisticsServicesContactData;
  staticUrlForImgNew = environment.staticUrlForImgNew
  constructor(public _sharedService: SharedService) { }

  ngOnInit(): void {
    this.getLandingPageContent();
  }

  getLandingPageContent() {
    this.logisticsKeyArray = [
      315953, // Banner
      316043,  // Getting Started
      316067, // Pricing Information
      316019, // Services
      316055, // Downloads
      315965 // Contact Us
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.logisticsKeyArray).subscribe(
      (data: any) => {
        this.logisticsBannerData = data[0] ? data[0].data : data[0];
        this.logisticsGettingStartedData = data[1] ? data[1].data : data[1];
        this.logisticsGetInTouchData = data[2] ? data[2].data : data[2];
        this.logisticsServicesData = data[3] ? data[3].data : data[3];
        this.logisticsServicesDownloadsList = data[4] ? data[4].data : data[4];
        this.logisticsServicesContactData = data[5] ? data[5].data : data[5];
        this._sharedService.initializeCustomJs();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  logisticsServicesCTEvent(btn, sectionTitle, pdfValue?, linkClicked?) {
    switch (btn) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle, '', linkClicked);
        break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(pdfValue);
        this._sharedService.callCTEvent('Download_Links', sectionTitle, this.pdfName);
        break;
      case 'sticky':
        this._sharedService.callCTEvent('Sticky_Menu_Links', '', '', linkClicked);
    }
  }

}
