import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../../shared/services/shared.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-garden-venue-details',
  templateUrl: './garden-venue-details.component.html',
  styleUrls: ['./garden-venue-details.component.css']
})
export class GardenVenueDetailsComponent implements OnInit, OnDestroy {
  gardenVenueDetailsHeaderList;
  subscription;
  jwgVenueBannerData;
  nextGreatEventData;
  knowYourVenueData;
  eventServicesData;
  eventsAtTheJwgData;
  jwgVenueShowcaseData;
  jwgAskUsData;
  activeTab = 0;
  ourVenueId;
  eventsId;
  planId;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  gardenVenueDetailsColorList = {
    footerIconColor: '#43B02A', footerHoverColor: '#EDFDE8', footerFillColor: '#FFFFFF', headerDataHoverColor:"#43B02A", dataVenuesLineColor: '#6CC24A', dataVenuesTitleColor: '#4B4847', dataTabTxtColor: '#4B4847', dataTabTxtHighlightColor: '#6CC24A',
    dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#6CC24A',
    dataPlanBgColor: '#F5F2ED', dataNewsletter2LineColor: '#6CC24A', dataPlanLineColor: '#6CC24A'
  };
  pdfName: any;

  constructor(public _sharedService: SharedService, private titleService: Title) { 
    this.gardenVenueDetailsHeaderList = (window.innerWidth > 991)?["Plan your event", "Our Venue", "Events", "Venue Showcase"] :
    ["About", "Know Your Venue", "Facilities", "Events We Host", "Venue Showcase", "Contact"];
    this.ourVenueId = (window.innerWidth > 991) ? 'our-venue' :  'know-your-venue' ;
    this.eventsId=(window.innerWidth > 991) ? 'events' :  'events-we-host' ;
    this.planId=(window.innerWidth > 991) ? 'plan-your-event' :  'contact' ;
  }

  ngOnInit() {
    this.getGardenVenuePageContent();
    setTimeout(() => {
      this._sharedService.callAccordianWithSlider();
    }, 800);
    this.gardenVenueDetailsHeaderList = this._sharedService.subHeaderItems(this.gardenVenueDetailsHeaderList);
  }

  getGardenVenuePageContent() {
    let gardenVenueKeyArray = [
      'JWG-VENUE-HERO-BANNER', 'JWG-VENUE-HOME-TO-YOUR-NEXT-GREAT-EVENT', 'JWG-VENUE-KNOW-YOUR-VENUE',
      'JWG-VENUE-EVENT-SERVICES', 'JWG-VENUE-EVENTS-AT-THE-JWG', 'JWG-VENUE-SHOWCASE', 'JWG-VENUE-ASK-US-ANYTHING'
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(gardenVenueKeyArray).subscribe(
      (data: any) => {
        this.jwgVenueBannerData = data[0] ? data[0].data : data[0];
        this.nextGreatEventData = data[1] ? data[1].data : data[1];
        this.knowYourVenueData = data[2] ? data[2].data : data[2];
        this.eventServicesData = data[3] ? data[3].data : data[3];
        this.eventsAtTheJwgData = data[4] ? data[4].data : data[4];
        this.jwgVenueShowcaseData = data[5] ? data[5].data : data[5];
        this.jwgAskUsData = data[6] ? data[6].data : data[6];
        this._sharedService.initializeCustomJs();
        this._sharedService.callSwipertab();
      },
      (error) => {
        console.log("Try after some time..");
      })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  callCTPDFDownloadEvent(pdfValue, sectionValue) {
    this.pdfName = this._sharedService.getPdfName(pdfValue);
    this._sharedService.callCTEvent('Download_Links', sectionValue, this.pdfName);
  }

  callCTPrimaryLinks(componentTitle) {
    this._sharedService.callCTEvent('Section_Primary_Links', componentTitle);
  }

}
