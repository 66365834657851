<div class="header jwc_navbar_brand" [ngClass]="(isSticky)? 'jwc_sticky_navbar' : ''">
  <!-- desktop navbar starts -->
  <div class="jwc_navbar d-none d-lg-block" *ngIf="isNewHeaderDesk">
    <div class="jwc_navbar_inner">
      <ul class="jwc_navbar_list">
        <li class="nav-item">
          <!-- <ng-container *ngIf="!pageTitle.includes('Visit Safely'); else otherTemplate">
            <a class="nav-link" routerLink="/das/visit-safely" (click)="callCTPrimaryEvent()">
              COVID Safety Guide
            </a>
          </ng-container> -->
          <ng-template #otherTemplate>
            <a class="nav-link" routerLink="/das/frequently-asked-questions"
              (click)="callCTPrimaryEvent()">
              FAQs
            </a>
          </ng-template>
      </ul>

      <div class="jwc_navbar--logo" *ngIf="isSticky">
        <a routerLink="/das"><img src="./assets/images/logo-navbar-das.svg" class="img-fluid"
            alt=""></a>
      </div>
      <div class="jwc_navbar_jwc--logo" *ngIf="!isSticky">
        <a routerLink="/das"><img src="./assets/images/logo-navbar-das.svg" class="img-fluid"
            alt=""></a>
      </div>

      <ul class="jwc_navbar_list justify-content-end">
        <li class="nav-item">
          <a *ngIf="!pageTitle.includes('Visit Safely');" class="nav-link" routerLink="/das/frequently-asked-questions" (click)="callCTPrimaryEvent()">
            FAQs
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/das/get-directions" (click)="callCTPrimaryEvent()">
            VISIT US
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- desktop navbar ends -->
  <!-- mobile navbar starts -->
  <!-- [ngClass]="{'jwc_navbar_brand': !isSticky}" -->
  <div class="jwc_navbar d-block d-lg-none">
    <div class="jwc_navbar_mobile">
      <button class="navbar-toggler jwc_hamburger" id="jwc_mob_hamburger" type="button" data-toggle="collapse"
        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div class="jwc_navbar--logo text-center" *ngIf="pageName">
        <a routerLink="/das"><img src="./assets/images/logo-navbar-das.svg" class="img-fluid"
            alt=""></a>
      </div>

      <ul class="jwc_navbar_list justify-content-end">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/das/get-directions"><span
              class="icon-location"></span></a>
        </li>
      </ul>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="jwc_mobile_home" style="justify-content: center;">
          <a routerLink="/das" (click)="callCTPrimaryEvent();closeHamMenu()"><img
              style="height: 30px;" src="./assets/images/logo-navbar-das.svg" class="img-fluid" alt=""></a>
        </div>

        <div class="jwc_mobile_menu_scroll">
          <ul class="jwc_navbar_dropdown_list no_column font-light">
            <!-- <li><a href="http://115.112.43.74:3200/tickets/dhirubhai-ambani-square" target="_blank"
                (click)="callCTPrimaryEvent('http://115.112.43.74:3200/tickets/dhirubhai-ambani-square');closeHamMenu()">Book
                Passes</a>
            </li> -->
            <li><a href="javascript:void(0)" routerLink="/das/visit-safely"
                (click)="callCTPrimaryEvent();closeHamMenu()">COVID Safety Guide</a></li>
            <li><a href="javascript:void(0)" routerLink="/das/get-directions"
                (click)="callCTPrimaryEvent();closeHamMenu()">Get Directions</a></li>
            <li><a href="javascript:void(0)" routerLink="/das/frequently-asked-questions"
                (click)="callCTPrimaryEvent();closeHamMenu()">FAQS</a></li>
            <li><a href="javascript:void(0)" routerLink="/das/contact"
                (click)="callCTPrimaryEvent();closeHamMenu()">Contact</a></li>
            <li class="jwc_social_links">
              <a href="https://www.facebook.com/dhirubhaiambanisquare" target="_blank"
                (click)="callCTPrimaryEvent('https://www.facebook.com/dhirubhaiambanisquare');closeHamMenu()"><span
                  class="icon-facebook"></span></a>
              <a href="https://www.instagram.com/dhirubhai_ambani_square" target="_blank"
                (click)="callCTPrimaryEvent('https://www.instagram.com/dhirubhai_ambani_square');closeHamMenu()"><span
                  class="icon-instagram"></span></a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
  <!-- mobile navbar ends -->
  <!-- <app-login [selectedScreen]="loginScreen" (isOtpVerified)="userLoggedIn($event)"></app-login> -->
</div>