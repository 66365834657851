<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <div class="breadcrumbs kma_breadcrumbs">
        <div class="breadcrumbs_container">
            <a href='/'>Jio World Centre </a> / <strong>Exhibitors Logistic Services</strong>
        </div>
    </div>
    <section class="thankyou " [attr.style]="('--data-getintouch-bgColor:#F5F2ED') | safe: 'style'" data-aos="fade-up">
        <div class="container">
            <div class="section_heading section_heading--title section_heading--text"
                [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#AF8F63') | safe: 'style'">
                <h2>You're being redirected.</h2>
                <p class='section_heading--text content2'>You are leaving the Exhibitors Logistics Services page and being redirected to a partner page of Reliance Industries Limited. Kindly wait a few moments and do not close or leave this page. 
                </p>
            </div>


            <div>
                <a href="{{'/logistics-services'}}" class="cta-prim color-gold"><span class="cta-prim-txt" (click)="logisticsRedirectCTEvent(); _sharedService.checkCTALink($event, '/logistics-services')">BACK TO
                        EXHIBITORS LOGISTICS SERVICES</span></a>
            </div>
        </div>
    </section>
</main>
<div class="overlay"></div>