import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-organisers-speak',
  templateUrl: './organisers-speak.component.html',
  styleUrls: ['./organisers-speak.component.css']
})
export class OrganisersSpeakComponent implements OnInit {
  organiserSpeakData;
  @Input() set organiserData(value) {
    this.organiserSpeakData = value;
    this._sharedService.callNormalSlider();
  }
  @Input() colorList;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this._sharedService.callVideoPopup();
  }  

}
