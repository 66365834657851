<!-- <app-header [colorList]="getDirectionColorList"></app-header> -->
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <div class="banner_small black_tint" [attr.style]="('--data-lineColor:#FFFFFF; --data-titleColor:#FFFFFF') | safe: 'style'" *ngIf="getDirectionBannerData">
    <picture>
      <source media="(max-width: 767px)" srcset="{{staticUrlForImgNew + getDirectionBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl}}">
      <source media="(min-width: 767px)" srcset="{{staticUrlForImgNew + getDirectionBannerData?.UploadImageVideo?.document?.contentUrl}}">
      <img src="{{staticUrlForImgNew + getDirectionBannerData?.UploadImageVideo?.document?.contentUrl}}" class="img-fluid" alt="{{getDirectionBannerData?.ImageAltText?.data}}">
  </picture>
    <h1 [innerHTML]="getDirectionBannerData?.Headline?.data"></h1>
  </div>
  <div class="sub_page_content get-direction" *ngIf="mapData">
    <section class="access_wrapper heading_align ">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section_heading section_heading--title section_heading--text" [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
                <h2 [innerHTML]="mapData?.ComponentHeaderTitle?.data"></h2>
                <p [innerHTML]="mapData?.ComponentHeaderSubTitle?.data"></p>
            </div>
          </div>
          <div class="col-md-7">
            <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#A67D54') | safe: 'style'">
              <div id="map" [attr.data-location]="locationData"></div>
            </div>
            <div class="access_wrapper_copy">
              <div class="access_wrapper_copy--btns">
                <a href="{{mapData?.GetDirectionsCTARedirectionLink?.data}}" class="cta-prim color-gold" *ngIf="mapData?.HideGetDirectionsCTAText.data == 'false'"
                (click)="callGetDirectionCTEvent(); _sharedService.checkCTALink($event, mapData?.GetDirectionsCTARedirectionLink?.data, mapData?.getDirectionsCTALinkWindowPreference?.data, mapData?.TypeOfGetDirectionsCTALink?.data)">
                    <span class="cta-prim-txt">{{mapData?.GetDirectionsCTAText?.data}}</span>
                </a>
                <!-- <a href="{{mapData?.BookParkingCTARedirectionLink?.data}}" class="cta-sec color-gold" *ngIf="mapData?.HideBookParkingCTAText.data == 'false'"
                (click)="_sharedService.checkCTALink($event, mapData?.BookParkingCTARedirectionLink?.data, mapData?.bookParkingCtaLinkWindowPreference?.data, mapData?.TypeOfBookParkingCTALink?.data)">
                    <span class="cta-sec-txt">{{mapData?.BookParkingCTAText?.data}}</span>
                </a> -->
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="heading_align_wrapper"  [attr.style]="('--data-access-textColor: #A67D54') | safe: 'style'">
              <div class="access_wrapper_each" data-aos="fade-up" [attr.data-aos-delay]="200+i*50" *ngFor="let item of mapData?.cards; let i = index">
                    <div class="access_wrapper_each--icon">
                      <img src="{{staticUrlForImgNew + item?.UploadIcon?.document?.contentUrl}}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                    </div>
                    <div class="access_wrapper_each_copy">
                        <h5 class="access_wrapper_each_copy--title">{{item?.ModeOfTransportName?.data}}</h5>
                        <div class="access_wrapper_each_copy--text">
                            <p class="content3">{{item?.Details1.data}}</p>
                            <p class="content3">{{item?.Details2.data}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </section>
  </div>

</main>
<!-- <app-footer [colorList]="getDirectionColorList"></app-footer> -->
<div class="overlay"></div>