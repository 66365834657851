import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-experiences-garden-slider',
  templateUrl: './experiences-garden-slider.component.html',
  styleUrls: ['./experiences-garden-slider.component.css']
})
export class ExperiencesGardenSliderComponent implements OnInit {
  @Input() experiencesGardenData;
  @Input() colorList;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor() { }

  ngOnInit() {
    // console.log("experiencesGardenData", this.experiencesGardenData);
  }

}
