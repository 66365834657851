<!-- <app-header [colorList]="gardenFacilitiesColorList" [pageName]="'garden'"></app-header> -->
<app-sub-header [colorList]="gardenFacilitiesColorList" [subHeaderList]="gardenFacilitiesHeaderList"
  [pageName]="'garden'"></app-sub-header>
<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <section class="kma_textual" id="facilities" data-mobile-header="Facilities"
   [attr.style]="('--data-kmatextual-bglayer:#F5F2ED; --data-kmatextual-lineColor:#6CC24A;') | safe: 'style'">
    <div class="breadcrumbs">
      <div class="breadcrumbs_container">
        <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-garden">Jio World Garden</a> /
        <strong>Facilties</strong>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center flex-row-reverse kma_textual_content" *ngIf="facilitiesBannerData">
        <div class="col-lg-4 col-md-5">
          <div class="section_heading section_heading--title section_heading--text content2 green_arrow"  [attr.style]="('--data-lineColor:#6CC24A') | safe: 'style'">
            <h2 [innerHTML]="facilitiesBannerData?.ComponentHeaderTitle?.data"></h2>
            <p [innerHTML]="facilitiesBannerData?.ComponentHeaderSubTitle?.data"></p>
          </div>
        </div>
        <div class="col-lg-8 col-md-7 kma_textual_image">

          <div class="bg_before_left" [attr.style]="('--data-bglayer-color:#6CC24A') | safe: 'style'">
            <img src="{{staticUrlForImgNew + facilitiesBannerData?.cards[0]?.CardImage?.image?.contentUrl}}"
              class="img-fluid" alt="{{facilitiesBannerData?.cards[0]?.ImageAltText.data}}">
          </div>

        </div>

      </div>
    </div>
  </section>
  <app-covid2-section [page]="'garden'" *ngIf="facilitiesBannerData"></app-covid2-section>
  <section class="our_space_wrapper " id="our-spaces" data-mobile-header="Our Spaces"
    [attr.style]="('--data-our-space-bgColor: ' + facilitiesOurSpaceData?.ComponentBackgroundColor?.data + ';--data-our-space-lineColor:#6CC24A') | safe: 'style'">
    <div class="container">
      <div class="section_heading section_heading--title" [attr.style]="('--data-lineColor:#6CC24A') | safe: 'style'">
          <h2 [innerHTML]="facilitiesOurSpaceData?.ComponentTitle?.data"></h2>
      </div>
    </div>
    <div class="container our_space_lists" data-aos="fade-up">
      <div class="tab-content">
        <div class="container">
          <div class="row">
            <div class="tabs col-lg-7 col-md-12">
              <div class="container">
                <div class="row">
                  <ul class="nav nav-tabs">
                    <li *ngFor="let item of facilitiesOurSpaceData?.cards; let i = index" (click)="activeTab = i">
                      <a href="#ourspace-tab1" href="{{'#ourspace-tab'+(i+1)}}" [ngClass]="{'active' : i==activeTab}"
                        data-toggle="tab">{{item?.Heading?.data}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let item of facilitiesOurSpaceData?.cards; let i = index" class="tab-pane fade"
          [hidden]="i !== activeTab" [ngClass]="{'show active' : i==activeTab}" id="{{'ourspace-tab'+(i+1)}}">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-5">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="row">
                        <h5>{{item?.Heading?.data}}</h5>
                        <p class="content3">{{item?.SubHeading?.data}}</p>
                        <div class="our_space_icons">
                          <div class="our_space_block" *ngIf="item?.SpaceIcon1?.document?.contentUrl">
                            <div class="our_space_icon">
                              <img src="{{staticUrlForImgNew + item?.SpaceIcon1?.document?.contentUrl}}"
                                class="img-fluid" [alt]="item?.ImageAltText?.data">
                            </div>
                            <div class="our_space_txt">
                              <h4>{{item?.SpaceIcon1Text1?.data}}</h4>
                              <p>{{item?.SpaceIcon1Text2?.data}}</p>
                            </div>
                          </div>
                          <div class="our_space_block" *ngIf="item?.SpaceIcon2?.document?.contentUrl">
                            <div class="our_space_icon">
                              <img src="{{staticUrlForImgNew + item?.SpaceIcon2?.document?.contentUrl}}"
                                class="img-fluid" [alt]="item?.ImageAltText?.data">
                            </div>
                            <div class="our_space_txt">
                              <h4>{{item?.SpaceIcon2Text1?.data}}</h4>
                              <p>{{item?.SpaceIcon2Text2?.data}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-md-7">
                <div class="container">
                  <div class="row">
                    <img src="{{staticUrlForImgNew + item?.UploadImage?.document?.contentUrl}}" class="img-fluid"
                    [alt]="item?.ImageAltText?.data">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div id="our-services" data-mobile-header="Our Services">
    <app-plan-big-event *ngIf="gardenFacilitiesSAUData" [venueDetailsEventsData]="gardenFacilitiesSAUData"
      [colorList]="gardenFacilitiesColorList" [pageName]="'garden'"></app-plan-big-event>
  </div>
  <div class="clearfix"></div>
  <section class="pt-0" style="background:#F5F2ED;" *ngIf="facilitiesInfographicsData">
    <div class="icons_block_wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-3" data-aos="fade-up" [attr.data-aos-delay]="200*i"
            *ngFor="let item of facilitiesInfographicsData?.cards; let i = index">
            <div class="icons_block_wrapper_each">
              <div class="icons_block_wrapper_each--icon">
                <img src="{{staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl}}" class="img-fluid"
                [alt]="item?.ImageAltText?.data">
              </div>
              <div class="icons_block_wrapper_each_copy">
                <h2 class="icons_block_wrapper_each_copy--number">{{item?.InfographicTitle1?.data}}</h2>
                <p class="icons_block_wrapper_each_copy--name content2">{{item?.InfographicTitle2?.data}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="text-center" data-aos="fade-up" data-aos-delay="400">
      <a href="javascript:void(0)" *ngIf="item?.HideCTAText?.data =='false'" class="cta-prim color-green emp-btn"
        (click)="_sharedService.checkCTALink($event, './assets/pdfs/dummy_pdf.pdf', '_self', 'PDF', 'JWG Facilities PDF')">
        <span class="cta-prim-txt">{{facilitiesInfographicsData?.CTAText?.data}}</span></a>
    </div>
  </section>

  <section class="full_width experiences_wrapper publicsafety_wrapper " id="public-safety" data-mobile-header="Public Safety"
    *ngIf="facilitiesPublicSafetyData"
    [attr.style]="('--data-publicsafety-bgColor1: ' + facilitiesPublicSafetyData?.ComponentBackgroundColor?.data + ';--data-publicsafety-titleColor:transparent; ') | safe: 'style'">
    <div class="container"></div>
    <div class="section_heading section_heading--title section_heading--text content2" [attr.style]="('--data-lineColor:#6CC24A') | safe: 'style'">
        <h2 [innerHTML]="facilitiesPublicSafetyData?.ComponentHeaderTitle?.data"></h2>
        <p [innerHTML]="facilitiesPublicSafetyData?.ComponentShortText?.data"></p>
    </div>
    <div class="swiper_fraction cec_fraction_1 publicsafety_slider jwc_swiper_fraction" data-interval="5"
      data-space="40" data-slides="3.9" data-ipad-slides="2.8">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of facilitiesPublicSafetyData?.cards; let i = index">
            <div class="publicsafety_slider_each">
              <div class="publicsafety_slider_each--img">
                <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                [alt]="item?.ImageAltText?.data">
              </div>
              <div class="publicsafety_slider_each_copy">
                <div class="publicsafety_slider_each_copy--center">
                  <h4>{{item?.CardHLText?.data}} </h4>
                  <p class="content3">{{item?.CardShortText?.data}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
      <!-- Add Arrows -->
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </section>

  <section class="highlights_wrapper" [id]="securityId" data-aos="fade-up" data-mobile-header="Security"
   [attr.style]="('--data-highlights-bgColor1:#F5F2ED;--data-highlights-bgColor2:#F5F2ED') | safe: 'style'" *ngIf="facilitiesSecurityData">
    <div class="container">
      <div class="">
        <div class="col-lg-12">
          <div class="section_heading section_heading--title section_heading--text content2 text-center" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#6CC24A') | safe: 'style'">
              <h2 [innerHTML]="facilitiesSecurityData?.ComponentHeaderTitle?.data"></h2>
              <p [innerHTML]="facilitiesSecurityData?.ComponentHeaderSubTitle?.data"></p>
          </div>
          <div class="highlights_facilities_icons_list pb-0">
            <div class="highlights_facilities_icons_list_each" *ngFor="let item of facilitiesSecurityData?.cards">
              <div class="highlights_facilities_icons_list_each--img">
                <img src="{{staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl}}" class="img-fluid" [alt]="item?.ImageAltText?.data">
              </div>
              <div class="highlights_facilities_icons_list_each--title content3">{{item?.InfographicTitle?.data}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
  <app-get-in-touch *ngIf="facilitiesExploreSpaceData" [getInTouchData]="facilitiesExploreSpaceData" id="explore" data-mobile-header="Explore"
    [colorList]="gardenFacilitiesColorList" [pageName]="'garden'" [downloadCta]="true">
  </app-get-in-touch>
  <section class="parking_management " [id]="parkingId" data-mobile-header="Parking"
   [attr.style]="('--data-pm-bgColor:#F5F2ED;--data-pm-lineColor:#6CC24A') | safe: 'style'" *ngIf="parkingManagementData">
    <div class="container">
      <div class="section_heading section_heading--title section_heading--text content1 text-center" [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor:#6CC24A') | safe: 'style'">
          <h2 [innerHTML]="parkingManagementData?.ComponentHeaderTitle?.data"></h2>
          <p [innerHTML]="parkingManagementData?.ComponentHeaderShortText?.data"></p>
      </div>

    </div>

    <div class="container parking_management_wrapper" data-aos="fade-up">
      <div class="plan_your_itinerary_slider jwc_swiper_normal2">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let item of parkingManagementData?.cards">
              <div class="card_5">
                <div class="card_5--img">
                  <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                    [alt]="item?.ImageAltText?.data">
                </div>
                <div class="card_5_copy">
                  <h4 class="card_5_copy--title">{{item?.CardHLText?.data}}</h4>
                  <p class="card_5_copy--text content3">{{item?.CardShortText?.data}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
        <div class="text-center button">
          <a *ngIf="parkingManagementData?.HideCTAText?.data == 'false'" href="javascript:void(0)"
            class="cta-prim color-green"
            (click)="callCTDownloadFSEvent(); _sharedService.checkCTALink($event, parkingManagementData?.CTALink?.data, '_self', 'PDF', pdfName)">
            <span class="cta-prim-txt">{{parkingManagementData?.CTAText?.data}}</span>
          </a>
        </div>
      </div>
    </div>

  </section>
  <app-faq-section *ngIf="facilitiesFaqsData" [faqData]="facilitiesFaqsData" [colorList]="gardenFacilitiesColorList"
    [sectionClass]="''" data-mobile-header="FAQs" id="faqs"></app-faq-section>

  <!-- <section class="experiences_slider full_width moments">
    <div class="container"></div>
    <div class="section_heading text-center text_bold" style="--data-titleColor:#4B4847;--data-lineColor:#43B02A">
      <div class="">
        <h2 class="section_heading--title">Moments at Jio World Garden</h2>
        <p class="section_heading--text"> <span class="icon icon-instagram" style="--data-icon-color:#43B02A;">@
            Jio World Garden</span>
        </p>
      </div>
    </div>
    <div class="moments_slider swiper_fraction jwc_swiper_fraction" data-interval="5" data-space="40" data-slides="4.35"
      data-ipad-slides="3.5">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="moments_slider_each">
              <div class="moments_slider_each--img">
                <img src="./assets/images/instagram/1.jpg" class="img-fluid" alt="slider 1">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="moments_slider_each">
              <div class="moments_slider_each--img">
                <img src="./assets/images/instagram/2.jpg" class="img-fluid" alt="slider 2">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="moments_slider_each">
              <div class="moments_slider_each--img">
                <img src="./assets/images/instagram/3.jpg" class="img-fluid" alt="slider 3">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="moments_slider_each">
              <div class="moments_slider_each--img">
                <img src="./assets/images/instagram/4.jpg" class="img-fluid" alt="slider 4">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="moments_slider_each">
              <div class="moments_slider_each--img">
                <img src="./assets/images/instagram/1.jpg" class="img-fluid" alt="slider 5">
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="moments_slider_each">
              <div class="moments_slider_each--img">
                <img src="./assets/images/instagram/2.jpg" class="img-fluid" alt="slider 6">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <div class="moments_slider--follow text-center">
      <a href="#" class="cta-sec color-green"><span class="cta-sec-txt">FOLLOW US</span></a>
    </div>
  </section> -->
  <!-- <app-subscribe-section [subscribeData]="facilitiesSubscribeData" *ngIf="facilitiesSubscribeData"
    [colorList]="gardenFacilitiesColorList" [pageName]="'garden'" [sectionClass]="'small_space'"></app-subscribe-section> -->
</main>
<!-- <app-footer [colorList]="gardenFacilitiesColorList"></app-footer> -->
<div class="overlay"></div>