import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-jioworld-nita-ambani',
  templateUrl: './jioworld-nita-ambani.component.html',
  styleUrls: ['./jioworld-nita-ambani.component.css']
})
export class JioworldNitaAmbaniComponent implements OnInit, OnDestroy {  
  private subscription;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  naccBannerData: any;
  dasData;
  subscribeData;
  // naccBgImage: boolean = true;
  planItinerary;
  momentsData: any;
  benefitsData: any;
  joinCommunityData;
  gettingHereData;
  subscribe2Data;
  faqData: any;
  artStoreData: any;
  naccColorList = {
    primaryColor: '#C92C99', experiencesDataLineColor: '#C92C99', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#C92C99', footerHoverColor: '#FEF9FC', footerFillColor: '#FFFFFF', subscribeDataBgColor: "#2B193A", subscribeDataTitleColor: "#ffffff", subscribeDataLineColor: "#C92C99", subscribeDotColor: "#C92C99", subscribeLineColor: "#C92C99", subscribeTextColor: "#ffffff", momentsIconColor: "#C92C99", subscribe2DotColor: "#ffffff", subscribe2BorderColor: "#fff6", subscribe2TitleColor: "#ffffff", subscribe2LineColor: "#ffffff", headerDataHoverColor:"#BA3F96"
  }
  naccExpSwiperData={ experiencesSliderName: 'nita_fraction_1' };
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this.getNaccPageContent();
  }

  getNaccPageContent() {
    let momentsId = (environment.env == 'dev')?85472 : 48579;
    let nMACCKeyArray = [
      39422, // Hero Banner
      40555, // Plan your Itinerary
      39813, // Join the community
      39548, // Dhirubhai Ambani Square
      39696, // Subscribe to our World
      39529, // Benefits & Privileges
      40399, // Getting here
      40946, // The Art Store
      40603, // FAQs
      39716, // Subscribe to our World 2
      momentsId // Moments
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(nMACCKeyArray).subscribe(
      (data: any) => {
        this.naccBannerData = data[0].data;
        this.planItinerary = data[1].data;
        this.joinCommunityData = data[2].data;
        this.dasData = data[3].data;
        this.subscribeData = data[4].data;
        this.benefitsData = data[5].data;
        this.gettingHereData = data[6].data;
        this.artStoreData = data[7].data;
        this.faqData = data[8].data;
        this.subscribe2Data = data[9].data;
        this.momentsData = data[10].data;
        this._sharedService.initializeCustomJs();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
 