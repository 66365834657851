<div class="row explorevenues_wrapper_each" [ngClass]="mainClass" [attr.style]="('--data-explore-boxbgColor:' + colorList?.dataExploreBoxBgColor) | safe: 'style'">
  <div class="col-lg-5 col-md-5 p-0">
    <div class="explorevenues_wrapper_each--img">
      <img src="{{ staticUrlForImgNew + cardData?.CardImage?.image?.contentUrl }}" class="img-fluid" alt="{{cardData?.ImageAltText?.data}}">
    </div>
  </div>
  <div class="col-lg-7 col-md-7">
    <div class="explorevenues_wrapper_each_copy">
      <h4 [innerHTML]="cardData?.ComponentHeaderTitle?.data"></h4>
      <p class="explorevenues_wrapper_each_copy--text content3" [innerHTML]="cardData?.ComponentSubHeader?.data"></p>
      <div class="row mt-sm-5 safety_icons text-center">
        <div class="col-md-4 col-sm-6 col-6" *ngFor="let item of cardData?.cards">
          <div class="safety_icons_each">
            <div class="safety_icons_each--icon">
              <img src="{{ staticUrlForImgNew + item?.IconImage?.image?.contentUrl }}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
            </div>
            <p class="safety_icons_each--text" [innerHTML]="item?.IconHeader?.data"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>