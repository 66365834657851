<section class="floorplan plr-0 pb-0" [attr.style]="('--data-tab-txt-Color:#4B4847;--data-tab-txt-hover-Color:#004EA8; --data-swiper-slide-Background:#F5F2ED;--data-swiper-slide-heading-color:#004EA8') | safe: 'style'">
  <div class="container" data-aos="fade-up">
    <div class="section_heading section_heading--title text-center text-white" [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
        <h2 [innerHTML]="cecVenueDetailsFloorPlansData?.ComponentHeaderTitle?.data"></h2>
    </div>
    <div class="container planning_tools_lists" data-aos="fade-up">
      <div class="tabs">
        <!-- <div class="tab_mob icon-arrow-down">{{cecVenueDetailsFloorPlansData?.cards[0].TabHeaderName?.data}}</div> -->
        <ul class="nav nav-tabs">
          <li *ngFor="let item of cecVenueDetailsFloorPlansData.cards; let i = index">
            <a href="#tab-{{i+1}}" data-toggle="tab" [ngClass]="{'active': i==0}">{{item?.TabHeaderName?.data}}</a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div class="tab-pane fade" id="tab-{{i+1}}" [ngClass]="{'active show':i==0}"
          *ngFor="let item of cecVenueDetailsFloorPlansData.cards; let i = index">
          <div class="floorplan-img">
            <img src="{{staticUrlForImgNew + item?.LargeImage?.image?.contentUrl}}" class="img-fluid" alt="{{item?.ImageAltText?.data}}">
          </div>
          <div class="floorplan-details">
            <h4>{{item?.TabHeaderName?.data}}</h4>
            <div class="floorplan-details-wrapper">
              <div class="container">
                <div class="row">
                  <div class="col-md-4 pl-0" *ngIf="item?.FirstInfographicHeaderText?.data">
                    <div class="floorplan-details-info">
                      <div class="floorplan-details-icon"><img
                          src="{{staticUrlForImgNew + item?.FirstInfographicImage?.image?.contentUrl}}" alt="">
                      </div>
                      <div class="floorplan-details-txt">
                        <h6>{{item?.FirstInfographicHeaderText?.data}}</h6>
                        <h5 [innerHTML]="item?.FirstInfographicSubHeaderText?.data"></h5>
                        <p [innerHTML]="item?.FirstInfographicShortText?.data"></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0" *ngIf="item?.SecondInfographicHeaderText?.data">
                    <div class="floorplan-details-info">
                      <div class="floorplan-details-icon"><img
                          src="{{staticUrlForImgNew + item?.SecondInfographicImage?.image?.contentUrl}}" alt=""></div>
                      <div class="floorplan-details-txt">
                        <h6>{{item?.SecondInfographicHeaderText?.data}}</h6>
                        <h5 [innerHTML]="item?.SecondInfographicSubHeaderText?.data"></h5>
                        <p [innerHTML]="item?.SecondInfographicShortText?.data"></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4  pl-0" *ngIf="item?.ThirdInfographicHeaderText?.data">
                    <div class="floorplan-details-info">
                      <div class="floorplan-details-icon"><img
                          src="{{staticUrlForImgNew + item?.ThirdInfographicImage?.image?.contentUrl}}" alt=""></div>
                      <div class="floorplan-details-txt">
                        <h6>{{item?.ThirdInfographicHeaderText?.data}}</h6>
                        <h5 [innerHTML]="item?.ThirdInfographicSubHeaderText?.data"></h5>
                        <p [innerHTML]="item?.ThirdInfographicShortText?.data"></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4  pl-0" *ngIf="item?.FourthInfographicHeaderText?.data">
                    <div class="floorplan-details-info">
                      <div class="floorplan-details-icon"><img
                          src="{{staticUrlForImgNew + item?.FourthInfographicImage?.image?.contentUrl}}" alt=""></div>
                      <div class="floorplan-details-txt">
                        <h6>{{item?.FourthInfographicHeaderText?.data}}</h6>
                        <h5 [innerHTML]="item?.FourthInfographicSubHeaderText?.data"></h5>
                        <p [innerHTML]="item?.FourthInfographicShortText?.data"></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4  pl-0" *ngIf="item?.FifthInfographicHeaderText?.data">
                    <div class="floorplan-details-info">
                      <div class="floorplan-details-icon"><img
                          src="{{staticUrlForImgNew + item?.FifthInfographicImage?.image?.contentUrl}}" alt=""></div>
                      <div class="floorplan-details-txt">
                        <h6>{{item?.FifthInfographicHeaderText?.data}}</h6>
                        <h5 [innerHTML]="item?.FifthInfographicSubHeaderText?.data"></h5>
                        <p [innerHTML]="item?.FifthInfographicShortText?.data"></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4  pl-0" *ngIf="item?.SixthInfographicHeaderText?.data">
                    <div class="floorplan-details-info">
                      <div class="floorplan-details-icon"><img
                          src="{{staticUrlForImgNew + item?.SixthInfographicImage?.image?.contentUrl}}" alt=""></div>
                      <div class="floorplan-details-txt">
                        <h6>{{item?.SixthInfographicHeaderText?.data}}</h6>
                        <h5 [innerHTML]="item?.SixthInfographicSubHeaderText?.data"></h5>
                        <p [innerHTML]="item?.SixthInfographicShortText?.data"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="floorplan-details-buttons">
              <a href="{{item?.FirstCTALink?.data}}" *ngIf="item?.HideFirstCTAText?.data =='false'"
                class="cta-sec icon rh icon-download-icon color-blue"
                (click)="callCecFloorPlanCTEvent('download', item?.TabHeaderName?.data, item?.FirstCTALink?.data); _sharedService.checkCTALink($event, item?.FirstCTALink?.data, '_self', 'PDF', downloadedDocName)">
                <span class="cta-sec-txt">{{item?.FirstCTAText?.data}}</span>
              </a>
              <a href="{{item?.SecondCTALink?.data}}" *ngIf="item?.HideSecondCTAText?.data =='false'"              
                (click)="callCecFloorPlanCTEvent('sec', item?.TabHeaderName?.data); _sharedService.checkCTALink($event, item?.SecondCTALink?.data, item?.SecondCTALinkWindowPreference?.data, item?.TypeOfSecondCTALink?.data)" class="cta-sec color-blue">
                <span class="cta-sec-txt">{{item?.SecondCTAText?.data}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>