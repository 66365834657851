<div class="loader">
    <div class="loader_spin"></div>
</div>

<main class="jwc-lportal" role="main">
    <app-back-to-wrapper></app-back-to-wrapper>

    <app-banner-new *ngIf="bannerData" [newBannerData]="bannerData"></app-banner-new>

    <section class="benefits_wrapper_2 pb-0" style="--data-background-color:#ffffff">

        <div class="container" [hidden]="benefitsData?.HideComponent?.data=='true'">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-7">
                    <div class="bg_before_left" *ngFor="let item of benefitsData?.cards"
                        style="--data-bglayer-color:#A67D54">
                        <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                            alt="{{item?.ImageAltText?.data}}" />

                    </div>
                </div>
                <div class="col-lg-5 col-md-5">
                    <div class="section_heading section_heading--title section_heading--text pb-0  content2 "
                        [attr.style]="('--data-lineColor:#A67D54') | safe: 'style'">
                        <h2>{{benefitsData?.ComponentHeaderTitle?.data}}</h2>
                        <p [innerHtml]="benefitsData?.ComponentHeaderSubTitle?.data"></p>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <div class="pt-150" *ngIf="nmaData?.HideComponent?.data=='true'"></div>
    <section class="nma_careers_wrapper"[hidden]="nmaData?.HideComponent?.data=='true'">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 nma_box">

                    <div class="row nma_careers_wrapper_each">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="nma_careers_wrapper_each--img" *ngFor="let item of nmaData?.cards">
                                <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                                    alt="{{item?.ImageAltText?.data}}" />
                            </div>

                        </div>
                        <div class="col-lg-7 col-md-7 fullwidth_bg">
                            <div class="nma_careers_wrapper_each_copy content2">

                                <div class="section_heading section_heading--title section_heading--text multilineText mb-30 pb-0"
                                    [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                                    <h2>{{nmaData?.ComponentHeaderTitle?.data}}</h2>
                                </div>

                                <p class="nma_careers_wrapper_each_copy--text">
                                    {{nmaData?.ComponentHeaderSubTitle?.data}}
                                </p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>


    <section class="life_jwc_wrapper p-0">
        <div class="container" [hidden]="lifeAtJWCData?.HideComponentOnSite?.data=='true'">
            <div class="row">
                <div class="col-lg-10 content2 text-center m-auto">
                    <div class="section_heading section_heading--title section_heading--text multilineText mb-30 pb-0 text-center"
                        [attr.style]="('--data-titleColor: #4B4847;
    --data-lineColor: #A67D54;') | safe: 'style'">
                        <h2>{{lifeAtJWCData?.Headline?.data}}</h2>
                    </div>

                    <p>{{lifeAtJWCData?.SubTitle?.data}}</p>
                </div>

            </div>
        </div>

        <div class="life_jwc_slider p-0 "  [attr.data-loop]="lifeAtJWCData?.cards?.length > 1">
            <!-- Swiper -->
            <div class="swiper-container kma_slider_img" [attr.data-loop]="lifeAtJWCData?.cards?.length > 1" data-interval="50">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let item of lifeAtJWCData?.cards">

                        <img src="{{staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}"
                            class="img--deskop" alt="{{item?.ImageAltText?.data}}" />
                        <img src="{{staticUrlForImgNew + item?.UploadMobileKMAImageVideo?.document?.contentUrl}}"
                            class="img--mobile extra-wdth" alt="{{item?.ImageAltText?.data}}" />



                        <a href="javascript:;" class="play_href watchVideoClick pink" data-url=""
                            *ngIf="item?.BackgroundImageVideo?.data!='image'"
                            data-target="#video_box" data-toggle="modal"><img src="./assets/images/playbutton.svg"
                                class="img-fluid play_btn" alt="play button"></a>


                    </div>
                </div>
                <!-- Add pagination -->

                <div class="swiper-arrows" *ngIf="lifeAtJWCData?.cards?.length > 1">

                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>


                </div>

                <div class="swiper-pagination" [hidden]="lifeAtJWCData?.cards?.length < 2"></div>
            </div>


        </div>

        <div class="video_popup modal fade" id="video_box" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="wrapper modal-dialog" role="document">
                <div class="modal-content">
                    <a href="javascript:;" class="close" data-dismiss="modal"></a>
                    <video controls controlsList="nodownload" class="">
                        <source src="" type="video/mp4">
                        Your browser does not support HTML5 video.
                    </video>
                </div>
            </div>
        </div>
    </section>

    <section class="awards_accolades_wrapper pb-0"
    [hidden]="awardsAndAccoladesData?.HideComponentOnSite?.data=='true'">
        <div class="container">
            <div class="row">
                <div class="col-md-12 m-auto content3">
                    <div class="section_heading section_heading--title section_heading--text text-center mb-30 pb-0"
                        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'">
                        <h2>{{awardsAndAccoladesData?.ComponentHeaderTitle?.data}}</h2>
                    </div>


                    <div class="awardsBox" *ngFor="let item of awardsAndAccoladesData?.cards">
                        <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}" class="img-fluid"
                            alt="{{item?.ImageAltText?.data}}" />

                        <p>{{item?.CardShortText?.data}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="participate hear_from_ourpeople_section plr-0 pb-0"
        [attr.style]="('--data-card-bgColor:#F5F2ED;') | safe: 'style'"
        [hidden]="hearFromOurPeopleData?.HideComponentOnSite?.data=='true'">


        <div class="container">


            <div class="section_heading section_heading--title section_heading--text   content2 text-center "
                [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor:#A67D54;') | safe: 'style'">
                <h2>{{hearFromOurPeopleData?.ComponentHeaderTitle?.data}}</h2>
                <p>{{hearFromOurPeopleData?.ComponentHeaderSubTitle?.data}}
                </p>
            </div>



            <div class="participate_slider jwc_swiper_normal4  content2" data-space="24" data-interval="5"
                data-slides="3" data-ipad-slides="2.2" data-ipad-space="20" data-mob-slides="1.15" data-mob-space="20">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of hearFromOurPeopleData?.cards">
                            <div class="participate_slider_each">
                                <div class="participate_slider_each--img">
                                    <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}"
                                        class="img-fluid" alt="{{item?.ImageAltText?.data}}" />

                                </div>
                                <div class="participate_slider_each_copy text-center content3">
                                    <h5>{{item?.CardHLText?.data}}</h5>
                                    <p>{{item?.CardShortText?.data}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </section>

    <section class="advisoryBoard diversity_inclusion " *ngIf="diversityAndInclusionData?.HideComponentOnSite?.data!=='true'">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 diversity_inclusion-mob">
                    <div class="section_heading section_heading--title section_heading--text content2 text-center  content2 "
                        [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor:#C69E67;') | safe: 'style'">
                        <h2>{{diversityAndInclusionData?.ComponentHeaderTitle?.data}}</h2>
                        <p>{{diversityAndInclusionData?.ComponentHeaderSubTitle?.data}}
                        </p>
                    </div>
                    <div class="advisoryBoard_section jwc_advisoryBoard_section diversity_inclusion_section">
                        <div class="swiper-container advisoryBoard_slider">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" *ngFor="let item of diversityAndInclusionData?.cards;let i=index;">
                                    <div class="diversity_inclusion-box">
                                        <div class="diversity_inclusion-box--img">
                                            <img src="{{staticUrlForImgNew + item?.CardImage?.image?.contentUrl}}"
                                                class="img-fluid" alt="{{item?.ImageAltText?.data}}" />
                                        </div>

                                        <div class="diversity_inclusion-box--copy content3">
                                            <h4>{{item?.CardHLText?.data}}</h4>
                                            <p><span #cardShortText id="{{i}}">{{getCardShortText(item?.CardShortText?.data)}}</span>
                                                <a *ngIf="item?.CardShortText?.data.length>75" #viewMoreCST id="ViewMoreCSTId{{i}}" class="color-gold viewMoreCST ml-2" (click)="showHideCSTViewMore(item?.CardShortText?.data,i)">READ MORE</a>
                                            </p> 
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </section>


    <section class="careers_openings_section pt-50"
    [attr.style]="('--data-careers_openings-bgColor:#F5F2ED;') | safe: 'style'" *ngIf="openingsData?.length > 0">

    <div class="careers_openings">
        <div class="container">

            <div class="section_heading section_heading--title section_heading--text content2 text-center   "
                [attr.style]="('--data-titleColor:;--data-lineColor:#C69E67') | safe: 'style'">
                <h2>Openings</h2>
                <p> </p>
            </div>


            <div class="careers_openings_list content2">

                <div *ngFor="let item of openingsData;let i=index;">
                    <div class="careers_openings_list_each">
                        <div class="row">
                            <div class="col-lg-10">
                                <div class="careers_openings_list_each--tags content2">
                                    <span>{{item?.brand}}</span> | <span>{{item?.department}}</span>
                                </div>
                                <div class="careers_openings_list_each--title">
                                    <h4>{{item?.position}}</h4>

                                </div>

                                <div  class="careers_openings_list_each--data content2">

                                    <!-- <p>{{ (item.description.length>100)? (item.description | slice:0:100)+'..':(item.description) }}
                                        <span class="collapse"
                                            id="viewmore1">{{ (item.description.length>100)? (item.description | slice:100:item.description.length):'' }}.</span>
                                        <a *ngIf="(item.description.length>100)" class="cta-sec color-gold viewmore"
                                            data-toggle="collapse" data-target="#viewmore1">VIEW MORE</a>
                                    </p> -->


                                    <!-- <app-show-more-show-less>
                                        <p><span #descriptionText id="{{item?.careerOpeningId}}">{{getText(item?.description)}}</span>
                                            <a *ngIf="item?.description.length>100" #viewMore class="cta-sec color-gold viewmore" (click)="showHideViewMore(item?.description,item?.careerOpeningId)">VIEW MORE</a>
                                        </p>  
                                    </app-show-more-show-less> -->

                                      <p><span #descriptionText id="{{item?.careerOpeningId}}">{{getDescriptionText(item?.description)}}</span>
                                        <a *ngIf="item?.description.length>100" #viewMore id="ViewMoreId{{item?.careerOpeningId}}" class="color-gold ctaViewMore ml-2" (click)="showHideViewMore(item?.description,item?.careerOpeningId)">VIEW MORE</a>
                                    </p> 

                                </div>
                            </div>
                            <div class="col-lg-2">

                                <div class="careers_openings_list_each--btn"><a (click)="checkCTALink($event, item)"
                                        class="cta-prim color-gold emp-btn"><span class="cta-prim-txt">APPLY</span></a>
                                </div>
                                <p class="posted" *ngIf="item?.isPostedTextHidden === false">Posted {{getNumberOfDays(item?.displayDate)}} days ago</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="text-center load-more">
                <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">LOAD MORE</span></a>
            </div> -->
        </div>

    </div>

    </section>

    <section class="getintouch  content2 "
        [attr.style]="('--data-getintouch-bgColor:#FFFFFF;--data-titleColor:#4B4847;--data-lineColor:#A67D54') | safe: 'style'"
        data-aos="fade-up" [hidden]="getInTouchData?.HideComponentOnSite?.data=='true'">
        <div class="section_heading section_heading--title section_heading--text text-center">
            <h2>{{getInTouchData?.Heading1?.data}}</h2>
            <p> {{getInTouchData?.Heading2?.data}}
            </p>
        </div>


        <div class="text-center">
            <a *ngIf="getInTouchData?.HideCTA?.data =='false'" class="cta-prim color-gold" href="{{getInTouchData?.CTALink?.data}}"
            (click)="_sharedService.checkCTALink($event, getInTouchData?.CTALink?.data, getInTouchData?.CTALinkWindowPreference?.data)">
              <span class="cta-prim-txt">{{getInTouchData?.CTAText?.data}}</span>
            </a>
        </div>
    </section>
</main>