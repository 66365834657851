<!-- <app-header [colorList]="naccColorList"></app-header> -->
<div class="loader">
  <div class="loader_spin"></div>
</div>

  <main role="main">
    <app-banner [landingBannerData]="naccBannerData" [sectionClass]="'notification nitaAmbaniBanner'" [bannerPage]="'nmaac'" *ngIf="naccBannerData"></app-banner>
    <section class="performing_visual_arts text-center">
      <div class="swiper-container performing_visual_arts_slider" data-interval="4">
        <div class="swiper-wrapper main-swiper-wrapper">
          <div class="swiper-slide performing-swiper-slide">
            <div class="performing">
              <div class="container">
                <div class="section_heading text-center" style="--data-titleColor:;--data-lineColor:">
                  <h2 class="section_heading--title">Performing Arts</h2>
                </div>
              </div>
              <div class="performing_slider">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div class="swiper-slide--img">
                        <img src="./assets/images/arts/p-arts-img1.jpg" class="img-fluid" alt="performing slider 1">
                      </div>
                      <div class="swiper-slide-copy">
                        <div class="swiper-slide-copy-middle">
                          <h4 class="swiper-slide-copy--title">Dance Performances</h4>
                          <p class="swiper-slide-copy--text">Classic, contemporary and everything in- between – let your body flow with every move and feel
                            the beat like never before.</p>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="swiper-slide--img">
                        <img src="./assets/images/arts/p-arts-img2.jpg" class="img-fluid" alt="performing slider 2">
                      </div>
                      <div class="swiper-slide-copy">
                        <div class="swiper-slide-copy-middle">
                          <h4 class="swiper-slide-copy--title">Experimental Theatre</h4>
                          <p class="swiper-slide-copy--text">Experience theatre that pushes the boundaries. And challenges yours.</p>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="swiper-slide--img">
                        <img src="./assets/images/arts/p-arts-img3.jpg" class="img-fluid" alt="performing slider 3">
                      </div>
                      <div class="swiper-slide-copy">
                        <div class="swiper-slide-copy-middle">
                          <h4 class="swiper-slide-copy--title">Workshops</h4>
                          <p class="swiper-slide-copy--text">Don’t just appreciate from afar, come unlock the performer within you</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink">
                <span class="cta-prim-txt">EXPLORE</span>
              </a>
            </div>
          </div>
          <div class="swiper-slide visual-swiper-slide">
            <div class="visual">
              <div class="container">
                <div class="section_heading text-center" style="--data-titleColor:;--data-lineColor:">
                  <h2 class="section_heading--title">Visual Arts</h2>
                </div>
              </div>

              <div class="visual_slider">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div class="swiper-slide--img">
                        <img src="./assets/images/arts/v-arts-img1.jpg" class="img-fluid" alt="visual slider 1">
                      </div>
                      <div class="swiper-slide-copy">
                        <div class="swiper-slide-copy-middle">
                          <h4 class="swiper-slide-copy--title">Workshops</h4>
                          <p class="swiper-slide-copy--text">Whether you’re an aficionado or just testing the waters – we have a workshop that will call out
                            to you.</p>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="swiper-slide--img">
                        <img src="./assets/images/arts/v-arts-img2.jpg" class="img-fluid" alt="visual slider 2">
                      </div>
                      <div class="swiper-slide-copy">
                        <div class="swiper-slide-copy-middle">
                          <h4 class="swiper-slide-copy--title">Art Exhibits</h4>
                          <p class="swiper-slide-copy--text">Contemporary, classic, abstract, experimental and more – come for the art you like, stay for what
                            takes you by surprise..</p>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="swiper-slide--img">
                        <img src="./assets/images/arts/v-arts-img3.jpg" class="img-fluid" alt="visual slider 3">
                      </div>
                      <div class="swiper-slide-copy">
                        <div class="swiper-slide-copy-middle">
                          <h4 class="swiper-slide-copy--title">Walkthroughs</h4>
                          <p class="swiper-slide-copy--text">Take in the story behind every piece of art, with guided tours that will make sure your experience
                            is all the more fulfilling..</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>


              <a (click)="_sharedService.checkCTALink($event)" class="cta-prim">
                <span class="cta-prim-txt">EXPLORE</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="full_width featured_shows">
      <div class="container">
        <div class="section_heading " style="--data-titleColor:;--data-lineColor:">
          <div data-aos="fade-up" class="">
            <h2 class="section_heading--title">Featured Shows</h2>
          </div>
        </div>

      </div>

      <div class="featured_shows_wrapper" data-aos="fade-up">

        <div class="desktop_slide_content">
          <div class="swiper-slide">
            <div class="featured_shows_slider_each">
              <div class="featured_shows_slider_each--img">
                <a href="javascript:;">
                  <img src="./assets/images/featured-shows/featured-show-img1.jpg" class="img-fluid" alt="slider 1">
                  <div class="featured_shows_slider_each--playicon"></div>
                </a>
              </div>
              <div class="featured_shows_slider_each_copy">
                <span class="featured_shows_slider_each_copy--category">Classical Dance</span>
                <h4 class="featured_shows_slider_each_copy--title">Jazz Trio - The Comet is coming</h4>
                <p class="featured_shows_slider_each_copy--date">24th April - 29th April 2020</p>
                <p class="featured_shows_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                <p class="featured_shows_slider_each_copy--location">Studio Theatre</p>
                <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket  featured_shows_slider_each_copy--buy">
                  <span class="cta-prim-txt">BUY TICKETS</span>
                </a>
                <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink featured_shows_slider_each_copy--know">
                  <span class="cta-sec-txt">KNOW MORE
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="featured_shows_slider_each">
              <div class="featured_shows_slider_each--img">
                <a href="javascript:;">
                  <img src="./assets/images/featured-shows/featured-show-img2.jpg" class="img-fluid" alt="slider 1">
                  <div class="featured_shows_slider_each--playicon"></div>
                </a>
              </div>
              <div class="featured_shows_slider_each_copy">
                <span class="featured_shows_slider_each_copy--category">MUSIC</span>
                <h4 class="featured_shows_slider_each_copy--title">Anoushka Shankar - Live
                </h4>
                <p class="featured_shows_slider_each_copy--date">26th April - 30th April 2020</p>
                <p class="featured_shows_slider_each_copy--time">8:00 PM - 10:00 PM</p>
                <p class="featured_shows_slider_each_copy--location">Studio Theatre</p>
                <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket featured_shows_slider_each_copy--buy">
                  <span class="cta-prim-txt">BUY TICKETS</span>
                </a>
                <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink featured_shows_slider_each_copy--know">
                  <span class="cta-sec-txt">KNOW MORE
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="featured_shows_slider_each">
              <div class="featured_shows_slider_each--img">
                <a href="javascript:;">
                  <img src="./assets/images/featured-shows/featured-show-img3.jpg" class="img-fluid" alt="slider 1">
                  <div class="featured_shows_slider_each--playicon"></div>
                </a>
              </div>
              <div class="featured_shows_slider_each_copy">
                <span class="featured_shows_slider_each_copy--category">Musical</span>
                <h4 class="featured_shows_slider_each_copy--title">Lion King - Disney</h4>
                <p class="featured_shows_slider_each_copy--date">24th April - 29th April 2020</p>
                <p class="featured_shows_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                <p class="featured_shows_slider_each_copy--location">Grand Theatre</p>
                <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket featured_shows_slider_each_copy--buy">
                  <span class="cta-prim-txt">BUY TICKETS</span>
                </a>
                <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink featured_shows_slider_each_copy--know">
                  <span class="cta-sec-txt">KNOW MORE
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="featured_shows_slider" data-interval="3">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="featured_shows_slider_each">
                  <div class="featured_shows_slider_each--img">
                    <a href="javascript:;">
                      <img src="./assets/images/featured-shows/featured-show-img1.jpg" class="img-fluid" alt="slider 1">
                      <div class="featured_shows_slider_each--playicon"></div>
                    </a>
                  </div>
                  <div class="featured_shows_slider_each_copy">
                    <span class="featured_shows_slider_each_copy--category">Classical Dance</span>
                    <h4 class="featured_shows_slider_each_copy--title">Jazz Trio - The Comet is coming</h4>
                    <p class="featured_shows_slider_each_copy--date">24th April - 29th April 2020</p>
                    <p class="featured_shows_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                    <p class="featured_shows_slider_each_copy--location">Studio Theatre</p>
                    <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket  featured_shows_slider_each_copy--buy">
                      <span class="cta-prim-txt">BUY TICKETS</span>
                    </a>
                    <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink featured_shows_slider_each_copy--know">
                      <span class="cta-sec-txt">KNOW MORE
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="featured_shows_slider_each">
                  <div class="featured_shows_slider_each--img">
                    <a href="javascript:;">
                      <img src="./assets/images/featured-shows/featured-show-img2.jpg" class="img-fluid" alt="slider 1">
                      <div class="featured_shows_slider_each--playicon"></div>
                    </a>
                  </div>
                  <div class="featured_shows_slider_each_copy">
                    <span class="featured_shows_slider_each_copy--category">MUSIC</span>
                    <h4 class="featured_shows_slider_each_copy--title">Anoushka Shankar - Live
                    </h4>
                    <p class="featured_shows_slider_each_copy--date">26th April - 30th April 2020</p>
                    <p class="featured_shows_slider_each_copy--time">8:00 PM - 10:00 PM</p>
                    <p class="featured_shows_slider_each_copy--location">Studio Theatre</p>
                    <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket featured_shows_slider_each_copy--buy">
                      <span class="cta-prim-txt">BUY TICKETS</span>
                    </a>
                    <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink featured_shows_slider_each_copy--know">
                      <span class="cta-sec-txt">KNOW MORE
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="featured_shows_slider_each">
                  <div class="featured_shows_slider_each--img">
                    <a href="javascript:;">
                      <img src="./assets/images/featured-shows/featured-show-img3.jpg" class="img-fluid" alt="slider 1">
                      <div class="featured_shows_slider_each--playicon"></div>
                    </a>
                  </div>
                  <div class="featured_shows_slider_each_copy">
                    <span class="featured_shows_slider_each_copy--category">Musical</span>
                    <h4 class="featured_shows_slider_each_copy--title">Lion King - Disney</h4>
                    <p class="featured_shows_slider_each_copy--date">24th April - 29th April 2020</p>
                    <p class="featured_shows_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                    <p class="featured_shows_slider_each_copy--location">Grand Theatre</p>
                    <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket featured_shows_slider_each_copy--buy">
                      <span class="cta-prim-txt">BUY TICKETS</span>
                    </a>
                    <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink featured_shows_slider_each_copy--know">
                      <span class="cta-sec-txt">KNOW MORE
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
          <!-- Add Arrows -->
          <div class="swiper-arrows">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>

        <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink featured_shows--view" data-aos="fade-up">
          <span class="cta-sec-txt">VIEW PERFORMANCE CALENDAR</span>
        </a>

      </div>
    </section>
    <section class="featured_art yellow_color">
      <div class="container" data-aos="fade-up">
        <div class="section_heading " style="--data-titleColor:;--data-lineColor:">
          <div data-aos="fade-up" class="">
            <h2 class="section_heading--title">Featured Art</h2>
          </div>
        </div>

      </div>

      <div class="featured_art_wrapper" data-aos="fade-up">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="featured_art_wrapper_each">
                <div class="featured_art_wrapper_each--img">
                  <img src="./assets/images/featured/featured-img-1.jpg" class="img-fluid" alt=Freedom and Change By Lubiana Hamid>
                </div>
                <div class="featured_art_wrapper_each_copy">
                  <h4 class="featured_art_wrapper_each_copy--title">Freedom and Change By Lubiana Hamid</h4>
                  <p class="featured_art_wrapper_each_copy--date content3">24th May - 29th May 2020</p>
                  <p class="featured_art_wrapper_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <p class="featured_art_wrapper_each_copy--location content3">Art House</p>
                  <a href=# class="cta-prim color-pink icon icon-ticket">
                    <span class="cta-prim-txt">BUY TICKETS</span>
                  </a>
                  <a href=# class="cta-sec color-pink">
                    <span class="cta-sec-txt">KNOW MORE</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="featured_art_wrapper_each">
                <div class="featured_art_wrapper_each--img">
                  <img src="./assets/images/featured/featured-img-2.jpg" class="img-fluid" alt=Freedom and Change By Lubiana Hamid>
                </div>
                <div class="featured_art_wrapper_each_copy">
                  <h4 class="featured_art_wrapper_each_copy--title">Freedom and Change By Lubiana Hamid</h4>
                  <p class="featured_art_wrapper_each_copy--date content3">24th May - 29th May 2020</p>
                  <p class="featured_art_wrapper_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <p class="featured_art_wrapper_each_copy--location content3">Art House</p>
                  <a href=# class="cta-prim color-pink icon icon-ticket">
                    <span class="cta-prim-txt">BUY TICKETS</span>
                  </a>
                  <a href=# class="cta-sec color-pink">
                    <span class="cta-sec-txt">KNOW MORE</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="featured_art_wrapper_each">
                <div class="featured_art_wrapper_each--img">
                  <img src="./assets/images/featured/featured-img-3.jpg" class="img-fluid" alt=Freedom and Change By Lubiana Hamid>
                </div>
                <div class="featured_art_wrapper_each_copy">
                  <h4 class="featured_art_wrapper_each_copy--title">Freedom and Change By Lubiana Hamid</h4>
                  <p class="featured_art_wrapper_each_copy--date content3">24th May - 29th May 2020</p>
                  <p class="featured_art_wrapper_each_copy--time content3">6:00 PM - 9:00 PM</p>
                  <p class="featured_art_wrapper_each_copy--location content3">Art House</p>
                  <a href=# class="cta-prim color-pink icon icon-ticket">
                    <span class="cta-prim-txt">BUY TICKETS</span>
                  </a>
                  <a href=# class="cta-sec color-pink">
                    <span class="cta-sec-txt">KNOW MORE</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink featured_art--view">
          <span class="cta-sec-txt">VIEW ALL COLLECTIONS</span>
        </a>
      </div>
    </section>
    <section class="nita_whats_on_wrapper " style="--data-nita-bgColor:">
      <div class="container">
        <div class="section_heading text-center text-white" style="--data-titleColor:#2C2C2C;--data-lineColor:#C63493">
          <div data-aos="fade-up" class="">
            <h2 class="section_heading--title">What’s On</h2>
          </div>
        </div>
      </div>

      <div class="nita_whats_on_slider" data-interval="4" data-aos="fade-up" data-space="120" data-slides="2">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" data-bg-color="#C8D2F2">
              <div class="nita_whats_on_slider_each">
                <div class="nita_whats_on_slider_each--img">
                  <img src="./assets/images/events/nita-whats-1.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="nita_whats_on_slider_each_copy border_btm_pink">
                  <div class="nita_whats_on_slider_each_copy--box">
                    <span class="nita_whats_on_slider_each_copy--category">Musical</span>
                    <h4 class="nita_whats_on_slider_each_copy--title">Alice in Wonderland</h4>
                    <p class="nita_whats_on_slider_each_copy--date">24th April - 29th April 2020</p>
                    <p class="nita_whats_on_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                    <span class="nita_whats_on_slider_each_copy--label">Grand Theatre</span>
                    <div class="nita_whats_on_slider_each_copy--btns">
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket">
                        <span class="cta-prim-txt">BUY TICKETS</span>
                      </a>
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink">
                        <span class="cta-sec-txt">KNOW MORE
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide" data-bg-color="#916c59">
              <div class="nita_whats_on_slider_each">
                <div class="nita_whats_on_slider_each--img">
                  <img src="./assets/images/events/nita-whats-2.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="nita_whats_on_slider_each_copy border_btm_pink">
                  <div class="nita_whats_on_slider_each_copy--box">
                    <span class="nita_whats_on_slider_each_copy--category">Performing Arts</span>
                    <h4 class="nita_whats_on_slider_each_copy--title">TEDx - Amit Karla (How to avoid food waste)
                    </h4>
                    <p class="nita_whats_on_slider_each_copy--date">24th April - 29th April 2020</p>
                    <p class="nita_whats_on_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                    <span class="nita_whats_on_slider_each_copy--label">Nita Ambani Arts Centre</span>
                    <div class="nita_whats_on_slider_each_copy--btns">
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket">
                        <span class="cta-prim-txt">BUY TICKETS</span>
                      </a>
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink">
                        <span class="cta-sec-txt">KNOW MORE
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide" data-bg-color="#6c7da9">
              <div class="nita_whats_on_slider_each">
                <div class="nita_whats_on_slider_each--img">
                  <img src="./assets/images/events/nita-whats-3.jpg" class="img-fluid" alt="slider 1">
                </div>
                <div class="nita_whats_on_slider_each_copy border_btm_pink">
                  <div class="nita_whats_on_slider_each_copy--box">
                    <span class="nita_whats_on_slider_each_copy--category">Performing Arts</span>
                    <h4 class="nita_whats_on_slider_each_copy--title">The Russian Ballet</h4>
                    <p class="nita_whats_on_slider_each_copy--date">24th April - 29th April 2020</p>
                    <p class="nita_whats_on_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                    <span class="nita_whats_on_slider_each_copy--label">Nita Ambani Arts Centre</span>
                    <div class="nita_whats_on_slider_each_copy--btns">
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket">
                        <span class="cta-prim-txt">BUY TICKETS</span>
                      </a>
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink">
                        <span class="cta-sec-txt">KNOW MORE
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide" data-bg-color="#031660">
              <div class="nita_whats_on_slider_each">
                <div class="nita_whats_on_slider_each--img">
                  <img src="./assets/images/events/nita-whats-4.jpg" class="img-fluid" alt="slider 4">
                </div>
                <div class="nita_whats_on_slider_each_copy border_btm_pink">
                  <div class="nita_whats_on_slider_each_copy--box">
                    <span class="nita_whats_on_slider_each_copy--category">Performing Arts</span>
                    <h4 class="nita_whats_on_slider_each_copy--title">The Russian Ballet</h4>
                    <p class="nita_whats_on_slider_each_copy--date">24th April - 29th April 2020</p>
                    <p class="nita_whats_on_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                    <span class="nita_whats_on_slider_each_copy--label">Nita Ambani Arts Centre</span>
                    <div class="nita_whats_on_slider_each_copy--btns">
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket">
                        <span class="cta-prim-txt">BUY TICKETS</span>
                      </a>
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink">
                        <span class="cta-sec-txt">KNOW MORE
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide" data-bg-color="#ecd4c7">
              <div class="nita_whats_on_slider_each">
                <div class="nita_whats_on_slider_each--img">
                  <img src="./assets/images/events/nita-whats-5.jpg" class="img-fluid" alt="slider 5">
                </div>
                <div class="nita_whats_on_slider_each_copy border_btm_pink">
                  <div class="nita_whats_on_slider_each_copy--box">
                    <span class="nita_whats_on_slider_each_copy--category">Performing Arts</span>
                    <h4 class="nita_whats_on_slider_each_copy--title">The Russian Ballet</h4>
                    <p class="nita_whats_on_slider_each_copy--date">24th April - 29th April 2020</p>
                    <p class="nita_whats_on_slider_each_copy--time">6:00 PM - 9:00 PM</p>
                    <span class="nita_whats_on_slider_each_copy--label">Nita Ambani Arts Centre</span>
                    <div class="nita_whats_on_slider_each_copy--btns">
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket">
                        <span class="cta-prim-txt">BUY TICKETS</span>
                      </a>
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-sec color-pink">
                        <span class="cta-sec-txt">KNOW MORE
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
      <div class="swiper-container gallery-thumbs">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="./assets/images/events/nita-whats-1.jpg" class="img-fluid" alt="slider 1">
          </div>
          <div class="swiper-slide">
            <img src="./assets/images/events/nita-whats-2.jpg" class="img-fluid" alt="slider 2">
          </div>
          <div class="swiper-slide">
            <img src="./assets/images/events/nita-whats-3.jpg" class="img-fluid" alt="slider 3">
          </div>
          <div class="swiper-slide">
            <img src="./assets/images/events/nita-whats-4.jpg" class="img-fluid" alt="slider 4">
          </div>
          <div class="swiper-slide">
            <img src="./assets/images/events/nita-whats-5.jpg" class="img-fluid" alt="slider 5">
          </div>
        </div>
      </div>
    </section>
    <app-nacc-plan-itinerary *ngIf="planItinerary" [planItinerary]="planItinerary"></app-nacc-plan-itinerary>
    <app-experiences-section *ngIf="joinCommunityData" [experiencesData]="joinCommunityData" [colorList]="naccColorList" [isNMACC]="true" [experiencesSwiperData]='naccExpSwiperData'></app-experiences-section>
    <app-da-square *ngIf="dasData" [dasData]="dasData"></app-da-square>
    <!-- <app-subscribe-section *ngIf="subscribeData" [subscribeData]="subscribeData" [colorList]="naccColorList" [pageName]="'nmacc'" [isNmacc]="true"></app-subscribe-section> -->
    <!-- <app-benefits-privileges-section *ngIf="benefitsData" [benefitsData]="benefitsData" [colorList]="naccColorList"></app-benefits-privileges-section> -->
    
    <section class="our_story" style="--data-story-bgColor:#F5F2ED;--data-story-fisrtColor:#bc0e87;--data-story-secondColor:#ffc84a;">
      <div class="container" data-aos="fade-up">
        <div class="section_heading " style="--data-titleColor:;--data-lineColor:#C92C99">
          <div class="">
            <h2 class="section_heading--title">Our Story</h2>
          </div>
        </div>
      </div>


      <div class="container our_story_wrapper" data-aos="fade-up">
        <div class="mobile_scroll">
          <div class="our_story_wrapper_col_1">
            <div class="our_story_wrapper_img_box">
              <div class="our_story_wrapper_img_box--bg" style="background-image: url(./assets/images/our-story/our-story-img1.jpg);"></div>
            </div>
          </div>
          <div class="our_story_wrapper_col_2 our_story_wrapper_verticle_box">
            <div class="our_story_wrapper_img_box">
              <div class="our_story_wrapper_img_box--bg" style="background-image: url(./assets/images/our-story/our-story-img2.jpg);"></div>
            </div>
            <div class="our_story_wrapper_verticle_box_copy">
              <div class="our_story_wrapper_verticle_box_copy--text">
                <p>“Our purpose is to pass ideas, melodies and experiences into the minds and bodies.”</p>
                <span class="author">NITA AMBANI</span>
              </div>
            </div>
          </div>
          <div class="our_story_wrapper_col_3">
            <div class="our_story_wrapper_img_box">
              <div class="our_story_wrapper_img_box--bg" style="background-image: url(./assets/images/our-story/our-story-img3.jpg);"></div>
            </div>
          </div>
          <div class="our_story_wrapper_col_4">
            <div class="our_story_wrapper_img_box">
              <div class="our_story_wrapper_img_box--bg" style="background-image: url(./assets/images/our-story/our-story-img4.jpg);"></div>
            </div>
          </div>
          <div class="our_story_wrapper_col_5 our_story_wrapper_horizontal_box">
            <div class="our_story_wrapper_horizontal_box_copy">
              <div class="our_story_wrapper_horizontal_box_copy--text">
                <p>Inspired by the Lotus – the national flower of India, every facet of the arts centre represents</p>
              </div>
            </div>

            <div class="our_story_wrapper_img_box">
              <div class="our_story_wrapper_img_box--bg" style="background-image: url(./assets/images/our-story/our-story-img5.jpg);"></div>
            </div>


          </div>
          <div class="our_story_wrapper_col_6">
            <div class="our_story_wrapper_img_box--bg" style="background-image: url(./assets/images/our-story/our-story-img6.jpg);"></div>
          </div>
        </div>
      </div>


    </section>
    <section class="more_at_jio" style="--data-more-at-jio-bgColor:#BC0E87;">
      <div class="container">
        <div class="section_heading text-center" style="--data-titleColor:#ffffff;--data-lineColor:#ffffff">
          <div data-aos="fade-up" class="">
            <h2 class="section_heading--title">More at Jio World Centre</h2>
          </div>
        </div>
      </div>

      <div class="container more_at_jio_wrapper" data-aos="fade-up">
        <div class="more_at_jio_slider">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="more_at_jio_wrapper_each">
                  <div class="more_at_jio_wrapper_each--img">
                    <img src="./assets/images/more-at-jio/more-at-jio-img1.jpg" class="img-fluid" alt="more at jio 1">
                  </div>
                  <div class="more_at_jio_wrapper_each_copy border_btm_red">
                    <span class="more_at_jio_wrapper_each_copy--category text-color-red">Food Festival</span>
                    <h4 class="more_at_jio_wrapper_each_copy--title">Japanese Food Festival - Nobu</h4>
                    <p class="more_at_jio_wrapper_each_copy--date">24th April - 29th April 2020</p>
                    <p class="more_at_jio_wrapper_each_copy--time">6:00 PM - 9:00 PM</p>
                    <p class="more_at_jio_wrapper_each_copy--location">Jio World Plaza</p>

                    <div class="more_at_jio_wrapper_each_copy--link">
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket">
                        <span class="cta-prim-txt">BUY TICKETS</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="more_at_jio_wrapper_each">
                  <div class="more_at_jio_wrapper_each--img">
                    <img src="./assets/images/more-at-jio/more-at-jio-img2.jpg" class="img-fluid" alt="more at jio 2">
                  </div>
                  <div class="more_at_jio_wrapper_each_copy border_btm_blue">
                    <span class="more_at_jio_wrapper_each_copy--category text-color-blue">Technology Exhibition</span>
                    <h4 class="more_at_jio_wrapper_each_copy--title">Tech Fest - 2020</h4>
                    <p class="more_at_jio_wrapper_each_copy--date">24th April - 29th April 2020</p>
                    <p class="more_at_jio_wrapper_each_copy--time">6:00 PM - 9:00 PM</p>
                    <p class="more_at_jio_wrapper_each_copy--location">Jio World Convention Centre</p>

                    <div class="more_at_jio_wrapper_each_copy--link">
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket">
                        <span class="cta-prim-txt">BUY TICKETS</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="more_at_jio_wrapper_each">
                  <div class="more_at_jio_wrapper_each--img">
                    <img src="./assets/images/more-at-jio/more-at-jio-img3.jpg" class="img-fluid" alt="more at jio 3">
                  </div>
                  <div class="more_at_jio_wrapper_each_copy border_btm_green">
                    <span class="more_at_jio_wrapper_each_copy--category text-color-green">Talks</span>
                    <h4 class="more_at_jio_wrapper_each_copy--title">Bina Venkatraman The power to think - Ted Talks</h4>
                    <p class="more_at_jio_wrapper_each_copy--date">24th April - 29th April 2020</p>
                    <p class="more_at_jio_wrapper_each_copy--time">6:00 PM - 9:00 PM</p>
                    <p class="more_at_jio_wrapper_each_copy--location">Jio World Convention Centre</p>

                    <div class="more_at_jio_wrapper_each_copy--link">
                      <a (click)="_sharedService.checkCTALink($event)" class="cta-prim color-pink icon icon-ticket">
                        <span class="cta-prim-txt">BUY TICKETS</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="button">
            <a (click)="_sharedService.checkCTALink($event)" class="cta-sec">
              <span class="cta-sec-txt">Load More</span>
            </a>
          </div>
        </div>
      </div>



    </section>
    <app-getting-here *ngIf="gettingHereData" [gettingHereData]="gettingHereData"></app-getting-here>
    <!-- <app-jwc-moments-section *ngIf="momentsData" [momentsData]="momentsData" [colorList]="naccColorList"></app-jwc-moments-section> -->
    <app-art-store-section *ngIf="artStoreData" [artStoreData]="artStoreData"></app-art-store-section>
    <app-faq-section *ngIf="faqData" [faqData]="faqData" [colorList]="naccColorList"></app-faq-section>
    <!-- <app-subscribe2-section [subscribeData]="subscribe2Data" *ngIf="subscribe2Data" [colorList]="naccColorList" [pageName]="'nmacc'"></app-subscribe2-section> -->

  </main>
  <app-footer [colorList]="naccColorList"></app-footer>
  <div class="overlay"></div>