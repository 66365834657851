<section class="full_width" *ngIf="ourWorldData">
  <div class="container"></div>
  <div class="section_heading section_heading--title section_heading--text" [attr.style]="('--data-titleColor:#2C2C2C;--data-lineColor:#C69E67') | safe: 'style'">
          <h2 [innerHTML]="ourWorldData?.ComponentHeaderTitle?.data"></h2>
          <p [innerHTML]="ourWorldData?.ComponentHeaderSubTitle?.data" role="heading"></p>
  </div>
  <div class="verticals_slider swiper_fraction jwc_swiper_fraction" data-interval="5" data-aos="fade-up"
      data-space="100" data-slides="1.6" data-ipad-slides="1.3">
      <div class="swiper-container">
          <div class="swiper-wrapper">
              <div class="swiper-slide" *ngFor="let item of ourWorldData.cards; let i=index"
                  [attr.style]="('--data-color: ' + item?.SlideTransitionColor?.data) | safe: 'style'">
                  <div class="verticals_slider_each">
                      <div class="verticals_slider_each--img">
                          <img src="{{ staticUrlForImgNew + item?.CardImage?.image?.contentUrl }}"
                              class="img-fluid" alt="{{item?.ImageAltText?.data}}">
                      </div>
                      <div class="verticals_slider_each_copy">
                          <div class="verticals_slider_each_copy--center">
                              <h2>{{ item?.CardHLText?.data }}</h2>
                              <p [innerHTML]="item?.CardShortText?.data"></p>
                              <a href="{{item?.CTALink?.data}}" *ngIf="item?.HideCTAText?.data =='false'"
                                  class="cta-sec color-gold"
                                  (click)="callCTOurWorldEvent(item?.CardHLText?.data); _sharedService.checkCTALink($event, item?.CTALink?.data, item?.CTALinkWindowPreference?.data, item?.TypeOfCTALink?.data)">
                                  <span class="cta-sec-txt">{{item?.CTAText?.data}}</span>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
      <!-- Add Arrows -->
      <div class="swiper-arrows">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
      </div>
  </div>

</section>