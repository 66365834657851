<section class="news_letter_4" [ngClass]="sectionClass"
  [attr.style]="('--data-newsletter4-bgColor: ' + subscribeData?.ComponentBgColor?.data + '; --data-lineColor: ' + colorList.subscribeLineColor +'; --data-titleColor: ' + colorList.subscribeTextColor) | safe: 'style'"
  *ngIf="subscribeData">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-lg-7 col-md-6">
        <h4 class="section_heading--title" [innerHTML]="subscribeData?.ComponentHeaderTitle?.data"></h4>
      </div>
      <div class="col-lg-5 col-md-6">
        <!-- <div class="cus_list">
          <ul class="cus_list_items">
            <li class="cus_list_items_each content1" *ngFor="let item of subscribeData?.cards">{{item?.BulletPoint?.data}}</li>
          </ul>
        </div> -->
        <div class="input-box" [ngClass]="(!showInputBox) ? 'd-none': ''">
          <form #subscriptionForm="ngForm" novalidate>
            <label for="subscribe-field" class="sr-only">{{subscribeData?.AltTextForInputField?.data}}</label>
            <input type="text" id="subscribe-field" placeholder="{{subscribeData?.EmailInputField?.data}}"
              class="input-field" [ngClass]="inputColorClass.get(pageName)" name="email" #email="ngModel" ngModel email
              required  aria-label="email" aria-required="true">
            <button class="cta-sec emp-btn" (click)="onSubscribeToWorld(subscriptionForm)"
              [ngClass]="buttonColorClass.get(pageName)">
              <span class="cta-sec-txt">{{subscribeData?.CTAText?.data}}</span>
            </button>
            <div [ngClass]="(!showSubMsg) ? 'd-none': ''" *ngIf="subSuccessMessage" class="error-txt success-txt">
              {{subSuccessMessage}}</div>
            <div [ngClass]="(!showSubMsg) ? 'd-none': ''" *ngIf="subApiErrorMessage" class="error-txt">
              {{subApiErrorMessage}}</div>
            <div *ngIf="subErrorMessage" class="error-txt">{{subErrorMessage}}</div>
          </form>
        </div>
        <div class="newsletter_captcha" [ngClass]="(!showCaptcha) ? 'd-none': ''">
          <re-captcha (resolved)="callFn(subscriptionForm, $event)" [siteKey]="captchaSiteKey"
          (error)="callFn(subscriptionForm, $event, true)" errorMode="handled"></re-captcha>
          <div *ngIf="captchaError" class="error-txt">{{captchaError}}</div>
        </div>
      </div>
      <div class="col-12 contactus_form">
        <div class="jwc_form_group--btn">
          <div class="form-row mx-0">
            <div class="form-group col-md-12 mb-0">
                <div class="form-check">
                    <div class="custom-control custom-checkbox checkbox pl-3 bg-transparent">
                        <input class="custom-control-input" type="checkbox" id="gridCheckForSubscription" [(ngModel)]="consentFlag">
                            <label class="custom-control-label consent" for="gridCheckForSubscription">
                              <span class="text-danger">* </span>
                              <span class="ml-1">
                                I have read, understood, and accept the Jio World Centre 
                                <a (click)="_sharedService.checkCTALink($event, '/legal?tab=privacy', '_blank', 'External')"
                                href="{{'/legal?tab=privacy'}}" class="color-pink">Privacy Policy</a>
                                and consent to the sharing of my personal data with Jio World Centre for the 
                                purpose of receiving promotions, other relevant information and personalized services.
                              </span>
                            </label>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>