import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
declare let fbq : Function;
// declare function gtag(a, b, c): any;

@Component({
  selector: 'app-inquiry-thank-you',
  templateUrl: './inquiry-thank-you.component.html',
  styleUrls: ['./inquiry-thank-you.component.css']
})
export class InquiryThankYouComponent implements OnInit {
  inquiryColorList = {
    footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', headerDataHoverColor: "#976841"
  };
  inqId = 'xxxxxxxx';
  previousPage;
  constructor(private router: Router, private _sharedService: SharedService) { 
    if (this.router.getCurrentNavigation().extras.state) {
      let routeState = this.router.getCurrentNavigation().extras.state;
      if (routeState) {
        this.inqId = routeState.inqId ? routeState.inqId : '';
        this.previousPage = routeState.page ? routeState.page : 'home';
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    // gtag('event', 'conversion', {'send_to': 'AW-662181025/FI8WCNyO1MgBEKGp4LsC'});
    this._sharedService.callLoader();
    const preferences = this._sharedService?.getCookieService()?.getPreferences();
    if (preferences?.statistics) {
      setTimeout(function () {
        fbq('track', 'Lead');
      }, 250);
    }
  }

}
