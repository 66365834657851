import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.css']
})
export class AddressCardComponent implements OnInit {
  @Input() addressData;
  pageInfo: Map<string, string>;
  previousRouteData;
  emailText = "General Enquires: <a href='mailto:information@jioworldconventioncentre.com'>information@jioworldconventioncentre.com</a> <br> Bookings: <a href='mailto:bookings@jioworldconventioncentre.com'>bookings@jioworldconventioncentre.com</a>"
  currentPage: any;

  constructor(public _sharedService: SharedService, private titleService: Title, private activatedRoute: ActivatedRoute) {
    this.currentPage = this.activatedRoute.snapshot.data['page']
    this.pageInfo = new Map([
      ["Jio World Centre", "home"],
      ["Jio World Convention Centre", "cec"],
      ["Jio world Garden", "garden"]
    ]);
  }

  ngOnInit() {
    this.previousRouteData = this._sharedService.getPreviousRouteData();
    let flag = false;
    this.addressData.cards.forEach(item => {
      item.AddEmail.forEach(element => {
        let index = element.Email.data.indexOf(":");
        if (index > -1) {
          element.Email.label = element.Email.data.substr(0, index);
          element.Email.value = element.Email.data.substr(index + 1);
        }
      });
      item.isExpanded = (this.previousRouteData == this.pageInfo.get(item.AccordianHeader.data)) ? true : false;
      if (item.isExpanded) {
        flag = true;
      }
    });
    if (!flag) this.addressData.cards[0].isExpanded = true;
  }
  callContactAddCardCTEvent(enquiryType, sectionName) {
    let pageTitle = this.titleService.getTitle();
    setTimeout(() => {
      let currentUrl = this._sharedService.getCurrentUrl();
      let contactAddCardEveObj = {
        Page_Source: pageTitle,
        Link_Clicked: currentUrl.state.root.firstChild.data.CTLinkClicked,
        Enquiry_Type: enquiryType,
        Section_Name: sectionName,
        // Event_Name: 'Section_Primary_Links'
      };
      this._sharedService.getCleverTapService().recordSubscriptionEvent('Section_Primary_Links', contactAddCardEveObj);
      // console.log(contactAddCardEveObj);
    }, 500)
  }


}
