import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  public transform(value: any, type: string): SafeStyle {
    switch (type) {
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
