<section class="full_width art_store" [attr.style]="('--data-art-store-background: ' + artStoreData?.ComponentBackgroundColor?.data) | safe: 'style'" *ngIf="artStoreData">
    <div class="container"></div>
    <div class="section_heading text_bold" style="--data-titleColor:#212529;--data-lineColor:#C92C99">
      <div data-aos="fade-up" class="">
        <h2 class="section_heading--title">{{artStoreData?.ComponentHeaderTitle?.data}}</h2>
      </div>
    </div>
    <div class="art_store_slider swiper_fraction" data-interval="4" data-aos="fade-up" data-space="40" data-slides="4.5" data-ipad-slides="3.5">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of artStoreData?.cards; let i = index;">
            <a (click)="_sharedService.checkCTALink($event)" class="art_store_slider_each">
              <div class="art_store_slider_each--img">
                <img src="{{staticUrlForImgNew + item?.ProductImage?.image?.contentUrl}}" class="img-fluid" alt="slider {{i}}">
              </div>
              <h4 class="art_store_slider_each--text">{{item?.ProductTitle?.data}}</h4>
            </a>
          </div>
        </div>
      </div>
      <!-- Add Arrows -->
      <div class="swiper-arrows">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <!-- <div class="art_store--follow" data-aos="fade-up">
      <a href="javascript:void(0)" *ngIf="artStoreData?.HideCTAText?.data =='false'" class="cta-sec color-pink"
       (click)="_sharedService.checkCTALink($event, artStoreData?.CTALink?.data, artStoreData?.WindowPreference?.data, artStoreData?.TypeOfCTALink?.data)">
        <span class="cta-sec-txt">{{artStoreData?.CTAText?.data}}</span>
      </a>
    </div> -->
  </section>