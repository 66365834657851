<div class="loader">
  <div class="loader_spin"></div>
</div>
<main role="main">
  <app-back-to-wrapper></app-back-to-wrapper>
  <div class="breadcrumbs ">
    <div class="breadcrumbs_container">
      <a routerLink="/">Jio World Centre</a> / <a routerLink="/jio-world-garden">Jio World Garden</a>/ <strong>Visit Safely</strong>
    </div>
  </div>
  <section class="pt-4" *ngIf="gardenSafetyData">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-8 col-md-8 pr-lg-5">
          <div class="section_heading section_heading--title section_heading--text multilineText mb-30 pb-0"
             [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#6CC24A') | safe: 'style'">
              <h2 [innerHTML]="gardenSafetyData?.ComponentHeaderTitle?.data"></h2>
              <p [innerHTML]="gardenSafetyData?.ComponentHeaderSubTitle?.data"></p>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="venue_details" *ngFor="let item of gardenSafetyData?.cards">
            <div class="venue_details--icon">
              <img src="{{ staticUrlForImgNew + item?.InfographicIcon?.image?.contentUrl }}" [alt]="item?.ImageAltText?.data" class="mw-100" />
            </div>
            <div class="venue_details--txt">
              <p class="content2">{{item.InfographicTitle.data}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



</main>
<!-- <app-footer></app-footer> -->