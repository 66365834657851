<section class="showcase plr-0"
  [attr.style]="'--data-venues-bgColor1: ' + venueDetailsShowcaseData?.ComponentBackgroundColor?.data + ';--data-tab-txt-Color: ' + colorList?.dataTabTxtColor + '; --data-tab-txt-highlight-Color: ' + colorList?.dataTabTxtHighlightColor | safe: 'style'">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section_heading section_heading--title text-center"
          [attr.style]="'--data-titleColor: ' + colorList?.dataVenuesTitleColor + '; --data-lineColor: ' + colorList?.dataVenuesLineColor | safe: 'style'">
          <h2 [innerHTML]="venueDetailsShowcaseData?.ComponentHeaderTitle?.data"></h2>
        </div>
        <div class="showcase_wrapper">
          <div class="jwc_showcase_slider">
            <div class="swiper-container slider-top">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of venueDetailsShowcaseData.cards; let i = index">
                  <ng-container [ngSwitch]="true">
                    <div class="showcase_img" *ngSwitchCase="item?.BackgroundImageVideo?.data==='image'">
                      <img src="{{ staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl}}" class="img-fluid"
                        alt="{{item?.ImageAltText?.data}}">
                    </div>
                    <!-- <div class="showcase_txt">
                    <h4>{{item?.CardHLText?.data}}</h4>
                    <p>{{item?.CardShortText?.data}}</p>
                  </div> -->
                    <div *ngSwitchCase="item?.BackgroundImageVideo?.data==='360 video'">
                      <div class="showcase_img">
                        <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl}}" class="img-fluid"
                          alt="{{item?.ImageAltText?.data}}">
                      </div>
                      <a href="javascript:;" class="jwc_360tour_btn play360"
                        [attr.videosrc]=" staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl">
                        <div class="jwc_360tour_btn--icon mb-0">
                          <img src="./assets/images/360/360.svg" class="img-fluid" alt="play360">
                        </div>
                      </a>
                    </div>
                    <div *ngSwitchCase="item?.BackgroundImageVideo?.data==='360 image'">
                      <div class="showcase_img">
                        <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl}}" class="img-fluid"
                          alt="{{item?.ImageAltText?.data}}">
                      </div>
                      <a href="javascript:;" class="jwc_360tour_btn play360"
                        [attr.imgsrc]=" staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl">
                        <div class="jwc_360tour_btn--icon mb-0">
                          <img src="./assets/images/360/360.svg" class="img-fluid" alt="play360">
                        </div>
                      </a>
                    </div>
                    <div *ngSwitchCase="item?.BackgroundImageVideo?.data==='video'">
                      <a href="javascript:;" class="video watchVideoClick" data-target="#video_box"
                        [attr.data-url]="staticUrlForImgNew + item?.UploadImageVideo?.document?.contentUrl"
                        data-toggle="modal">
                        <img src="{{ staticUrlForImgNew + item?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
                          class="img-fluid" alt="{{item?.PlaceholderImageForVideoUploaded?.image?.title}}">
                        <div class="play_href white">
                          <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
                        </div>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-container slider-thumbs">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of venueDetailsShowcaseData.cards; let i = index">
                  <img src="{{ staticUrlForImgNew + item?.ThumbnailImage?.image?.contentUrl}}" class="img-fluid"
                    alt="{{item?.ImageAltText?.data}}">
                </div>
              </div>
            </div>
          </div>
          <div class="showcase_box">
            <div class="showcase_box--text content3">
              <h4>{{venueDetailsShowcaseData?.HLText?.data}}</h4>
              <p>{{venueDetailsShowcaseData?.HLShortText?.data}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-video-popup></app-video-popup>